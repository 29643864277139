import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: string;
  Decimal: number;
  GenericScalar: any;
  JSONString: any;
  StringMoney: string;
  UUID: string;
  Upload: unknown;
};

export type AddAppraisalMediaInput = {
  appraisalId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
};

export type AddAppraisalMediaPayload = {
  __typename?: "AddAppraisalMediaPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AddAssetMediaInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
};

export type AddAssetMediaPayload = {
  __typename?: "AddAssetMediaPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type AddCollectionMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionId: Scalars["ID"];
  mediaData?: InputMaybe<MediaInput>;
};

export type AddCollectionMediaPayload = {
  __typename?: "AddCollectionMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type AddConditionStatusReportMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportId: Scalars["ID"];
  mediaData?: InputMaybe<MediaInput>;
};

export type AddConditionStatusReportMediaPayload = {
  __typename?: "AddConditionStatusReportMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type AddDepositationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaData: MediaInput;
};

export type AddDepositationMediaPayload = {
  __typename?: "AddDepositationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type AddExhibitionMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionId: Scalars["ID"];
  mediaData?: InputMaybe<MediaInput>;
};

export type AddExhibitionMediaPayload = {
  __typename?: "AddExhibitionMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type AddMultipleAssetMediaInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
};

export type AddMultipleAssetMediaPayload = {
  __typename?: "AddMultipleAssetMediaPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<Array<Maybe<MediaNode>>>;
};

export type AddMultipleCrsrMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  crsrId: Scalars["ID"];
  file?: InputMaybe<Scalars["Upload"]>;
};

export type AddMultipleCrsrMediaPayload = {
  __typename?: "AddMultipleCrsrMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
  media?: Maybe<Array<Maybe<MediaNode>>>;
};

export type AddMultipleRestorationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  restorationId: Scalars["ID"];
};

export type AddMultipleRestorationMediaPayload = {
  __typename?: "AddMultipleRestorationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<Array<Maybe<MediaNode>>>;
  restoration?: Maybe<RestorationNode>;
};

export type AddRestorationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
  restorationId: Scalars["ID"];
};

export type AddRestorationMediaPayload = {
  __typename?: "AddRestorationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type AddTransportationAssetsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  transportationAssets?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  transportationId: Scalars["ID"];
};

export type AddTransportationAssetsPayload = {
  __typename?: "AddTransportationAssetsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type AddTransportationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData: MediaInput;
  transportationId: Scalars["ID"];
};

export type AddTransportationMediaPayload = {
  __typename?: "AddTransportationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type AddUserObjectPermissionFromCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type AddUserObjectPermissionFromCollectionPayload = {
  __typename?: "AddUserObjectPermissionFromCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserObjectPermissionInput = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type AddUserObjectPermissionPayload = {
  __typename?: "AddUserObjectPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserPermissionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type AddUserPermissionPayload = {
  __typename?: "AddUserPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type AddUserSignaturePayload = {
  __typename?: "AddUserSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
  userSignature?: Maybe<UserSignatureType>;
};

export type Address = {
  __typename?: "Address";
  country?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  formatted?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["Float"]>;
  locality?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  postalCode?: Maybe<Scalars["String"]>;
  raw?: Maybe<Scalars["String"]>;
  route?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateCode?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  country?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  formatted?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  locality?: InputMaybe<Scalars["String"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["String"]>;
  route?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  stateCode?: InputMaybe<Scalars["String"]>;
  streetNumber?: InputMaybe<Scalars["String"]>;
};

export type AddressNode = Node & {
  __typename?: "AddressNode";
  appraisalAddress: AppraisalNodeConnection;
  assetLocation: AssetNodeConnection;
  assetNumber?: Maybe<Scalars["Int"]>;
  conditionstatusreportExhibitionAddress: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsDeliveryLocation: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsPickupLocation: ConditionStatusReportNodeConnection;
  country?: Maybe<CountryNode>;
  deliveryAddressHistoric: PolicyAssetBasketHistoryNodeConnection;
  eventlogNewAddress: EventLogNodeConnection;
  eventlogOldAddress: EventLogNodeConnection;
  formatted: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  latitude?: Maybe<Scalars["Float"]>;
  lenderAddressHistoric: PolicyAssetBasketHistoryNodeConnection;
  locality?: Maybe<LocalityNode>;
  longitude?: Maybe<Scalars["Float"]>;
  officeAddress: OfficeNodeConnection;
  pickupAddressHistoric: PolicyAssetBasketHistoryNodeConnection;
  raw: Scalars["String"];
  registryBillingAddress: RegistryNodeConnection;
  route: Scalars["String"];
  streetNumber: Scalars["String"];
};

export type AddressNodeAppraisalAddressArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeAssetLocationArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeConditionstatusreportExhibitionAddressArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeConditionstatusreportLogisticsDeliveryLocationArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeConditionstatusreportLogisticsPickupLocationArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeDeliveryAddressHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeEventlogNewAddressArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeEventlogOldAddressArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeLenderAddressHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeOfficeAddressArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodePickupAddressHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeRegistryBillingAddressArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AddressNodeConnection = {
  __typename?: "AddressNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AddressNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AddressNode` and its cursor. */
export type AddressNodeEdge = {
  __typename?: "AddressNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AddressNode>;
};

export type AppraisalInput = {
  appraisalAsset?: InputMaybe<Scalars["ID"]>;
  appraisalConservationStatus?: InputMaybe<Scalars["String"]>;
  appraisalDate?: InputMaybe<Scalars["DateTime"]>;
  appraisalEvaluator: Scalars["ID"];
  appraisalLocation?: InputMaybe<Scalars["String"]>;
  appraisalMotivation: Scalars["String"];
  appraisalStatus: Scalars["String"];
  location?: InputMaybe<AddressInput>;
  valueCarryingValue?: InputMaybe<MoneyInput>;
  valueEstimatedValue?: InputMaybe<MoneyInput>;
  valueInformationAuthorCoefficient?: InputMaybe<Scalars["String"]>;
  valueInformationInfo?: InputMaybe<Scalars["String"]>;
  valueInformationNote?: InputMaybe<Scalars["String"]>;
  valueInformationQuotesSimilarAssets?: InputMaybe<Scalars["String"]>;
};

export type AppraisalNode = Node & {
  __typename?: "AppraisalNode";
  actualMediaStatus?: Maybe<Scalars["String"]>;
  appraisalAsset: AssetNode;
  appraisalConservationStatus?: Maybe<Scalars["String"]>;
  appraisalDate?: Maybe<Scalars["DateTime"]>;
  appraisalEvaluator: RegistryNode;
  appraisalLocation?: Maybe<Scalars["String"]>;
  appraisalMotivation?: Maybe<Scalars["String"]>;
  appraisalStatus?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  exportedDocument?: Maybe<ExportDocumentsNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  isSigned: Scalars["Boolean"];
  location?: Maybe<Address>;
  media: MediaNodeConnection;
  mediaNumber?: Maybe<Scalars["Int"]>;
  signatureFile?: Maybe<Scalars["String"]>;
  signedBy?: Maybe<UserType>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  valueCarryingValue?: Maybe<Money>;
  valueCarryingValueCurrency: ManagementAppraisalValueCarryingValueCurrencyChoices;
  valueEstimatedValue?: Maybe<Money>;
  valueEstimatedValueCurrency: ManagementAppraisalValueEstimatedValueCurrencyChoices;
  valueInformationAuthorCoefficient?: Maybe<Scalars["String"]>;
  valueInformationInfo?: Maybe<Scalars["String"]>;
  valueInformationNote?: Maybe<Scalars["String"]>;
  valueInformationQuotesSimilarAssets?: Maybe<Scalars["String"]>;
};

export type AppraisalNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AppraisalNodeConnection = {
  __typename?: "AppraisalNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AppraisalNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `AppraisalNode` and its cursor. */
export type AppraisalNodeEdge = {
  __typename?: "AppraisalNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AppraisalNode>;
};

export type AssetDatingNode = Node & {
  __typename?: "AssetDatingNode";
  asset: AssetNode;
  centuryEndDate?: Maybe<Scalars["Int"]>;
  centuryEndDateWindow?: Maybe<ManagementAssetDatingCenturyEndDateWindowChoices>;
  centuryStartDate?: Maybe<Scalars["Int"]>;
  centuryStartDateWindow?: Maybe<ManagementAssetDatingCenturyStartDateWindowChoices>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  creationDate?: Maybe<Scalars["DateTime"]>;
  creationDateNotes?: Maybe<Scalars["String"]>;
  creationPeriod?: Maybe<Scalars["String"]>;
  dateEndDate?: Maybe<Scalars["Date"]>;
  dateStartDate?: Maybe<Scalars["Date"]>;
  datingDe?: Maybe<Scalars["String"]>;
  datingEn?: Maybe<Scalars["String"]>;
  datingEs?: Maybe<Scalars["String"]>;
  datingFr?: Maybe<Scalars["String"]>;
  datingIt?: Maybe<Scalars["String"]>;
  datingType?: Maybe<ManagementAssetDatingDatingTypeChoices>;
  hasFullDatetime: Scalars["Boolean"];
  hasPeriod: Scalars["Boolean"];
  hasYear: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  isCenturyApprox: Scalars["Boolean"];
  isCenturyEndDateBc: Scalars["Boolean"];
  isCenturyInterval: Scalars["Boolean"];
  isCenturyStartDateBc: Scalars["Boolean"];
  isDateApprox: Scalars["Boolean"];
  isDateEndDateBc: Scalars["Boolean"];
  isDateInterval: Scalars["Boolean"];
  isDateStartDateBc: Scalars["Boolean"];
  isYearApprox: Scalars["Boolean"];
  isYearEndDateBc: Scalars["Boolean"];
  isYearInterval: Scalars["Boolean"];
  isYearStartDateBc: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  yearEndDate?: Maybe<Scalars["Int"]>;
  yearStartDate?: Maybe<Scalars["Int"]>;
};

export type AssetDatingNodeConnection = {
  __typename?: "AssetDatingNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetDatingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetDatingNode` and its cursor. */
export type AssetDatingNodeEdge = {
  __typename?: "AssetDatingNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetDatingNode>;
};

export type AssetDocumentAttachmentInput = {
  assetDocumentId: Scalars["ID"];
  file?: InputMaybe<Scalars["Upload"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type AssetDocumentAttachmentNode = Node & {
  __typename?: "AssetDocumentAttachmentNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  document?: Maybe<AssetDocumentNode>;
  file: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type AssetDocumentAttachmentNodeConnection = {
  __typename?: "AssetDocumentAttachmentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetDocumentAttachmentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetDocumentAttachmentNode` and its cursor. */
export type AssetDocumentAttachmentNodeEdge = {
  __typename?: "AssetDocumentAttachmentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetDocumentAttachmentNode>;
};

export type AssetDocumentInput = {
  archiveNumber?: InputMaybe<Scalars["String"]>;
  assetId?: InputMaybe<Scalars["ID"]>;
  attachments?: InputMaybe<Array<InputMaybe<AssetDocumentAttachmentInput>>>;
  coaType?: InputMaybe<Scalars["String"]>;
  contractSubscriber?: InputMaybe<Scalars["String"]>;
  curator?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  documentType?: InputMaybe<Scalars["String"]>;
  editor?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["Date"]>;
  fineArtsIssueType?: InputMaybe<Scalars["String"]>;
  isClassified?: InputMaybe<Scalars["Boolean"]>;
  isCriticalCatalog?: InputMaybe<Scalars["Boolean"]>;
  isTemporaryExport?: InputMaybe<Scalars["Boolean"]>;
  isTemporaryImport?: InputMaybe<Scalars["Boolean"]>;
  issuer?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  otherDocumentType?: InputMaybe<Scalars["String"]>;
  publishingDate?: InputMaybe<Scalars["Date"]>;
  recipient?: InputMaybe<Scalars["String"]>;
  releaseDate?: InputMaybe<Scalars["Date"]>;
  subscriptionDate?: InputMaybe<Scalars["Date"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type AssetDocumentNode = Node & {
  __typename?: "AssetDocumentNode";
  archiveNumber?: Maybe<Scalars["String"]>;
  asset?: Maybe<AssetNode>;
  attachments: AssetDocumentAttachmentNodeConnection;
  coaType?: Maybe<Scalars["String"]>;
  contractSubscriber?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  curator?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  documentType?: Maybe<Scalars["String"]>;
  editor?: Maybe<Scalars["String"]>;
  expirationDate?: Maybe<Scalars["Date"]>;
  fineArtsIssueType?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isClassified?: Maybe<Scalars["Boolean"]>;
  isCriticalCatalog?: Maybe<Scalars["Boolean"]>;
  isTemporaryExport?: Maybe<Scalars["Boolean"]>;
  isTemporaryImport?: Maybe<Scalars["Boolean"]>;
  issuer?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  otherDocumentType?: Maybe<Scalars["String"]>;
  publishingDate?: Maybe<Scalars["Date"]>;
  recipient?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["Date"]>;
  subscriptionDate?: Maybe<Scalars["Date"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type AssetDocumentNodeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetDocumentNodeConnection = {
  __typename?: "AssetDocumentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetDocumentNode` and its cursor. */
export type AssetDocumentNodeEdge = {
  __typename?: "AssetDocumentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetDocumentNode>;
};

export type AssetElementInput = {
  assetId?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  materials?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type AssetElementNode = Node & {
  __typename?: "AssetElementNode";
  asset?: Maybe<AssetNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  description?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  materials?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type AssetElementNodeConnection = {
  __typename?: "AssetElementNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetElementNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetElementNode` and its cursor. */
export type AssetElementNodeEdge = {
  __typename?: "AssetElementNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetElementNode>;
};

export type AssetInput = {
  archiveNumber?: InputMaybe<Scalars["String"]>;
  artistProofNotes?: InputMaybe<Scalars["String"]>;
  assetLocation?: InputMaybe<AddressInput>;
  assetPlacement?: InputMaybe<Scalars["String"]>;
  assetPresentValues?: InputMaybe<Array<InputMaybe<AssetPresentValueInput>>>;
  audioVideoLength?: InputMaybe<Scalars["Float"]>;
  audioVideoLengthUnit?: InputMaybe<Scalars["String"]>;
  authorEntityId: Scalars["ID"];
  availability?: InputMaybe<Scalars["String"]>;
  banknotePrinter?: InputMaybe<Scalars["String"]>;
  barriersDetails?: InputMaybe<Scalars["String"]>;
  baseDetails?: InputMaybe<Scalars["String"]>;
  bibliographyNotes?: InputMaybe<Scalars["String"]>;
  bookContainer?: InputMaybe<Scalars["String"]>;
  bookbinding?: InputMaybe<Scalars["String"]>;
  carat?: InputMaybe<Scalars["String"]>;
  carryingValueAcquisition?: InputMaybe<Scalars["String"]>;
  carryingValueAmount?: InputMaybe<MoneyInput>;
  carryingValueFile?: InputMaybe<Scalars["Upload"]>;
  carryingValueFileTitle?: InputMaybe<Scalars["String"]>;
  carryingValueLastAcquisitionDate?: InputMaybe<Scalars["Date"]>;
  carryingValueNotes?: InputMaybe<Scalars["String"]>;
  carryingValueOrigin?: InputMaybe<Scalars["String"]>;
  catalogNumber?: InputMaybe<Scalars["String"]>;
  catalogingAuthor?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  centuryEndDate?: InputMaybe<Scalars["Int"]>;
  centuryEndDateWindow?: InputMaybe<Scalars["String"]>;
  centuryStartDate?: InputMaybe<Scalars["Int"]>;
  centuryStartDateWindow?: InputMaybe<Scalars["String"]>;
  circulation?: InputMaybe<Scalars["String"]>;
  classificationEng?: InputMaybe<Scalars["String"]>;
  classificationFra?: InputMaybe<Scalars["String"]>;
  classificationGer?: InputMaybe<Scalars["String"]>;
  classificationIta?: InputMaybe<Scalars["String"]>;
  classificationPhilatelic?: InputMaybe<Scalars["String"]>;
  classificationSpa?: InputMaybe<Scalars["String"]>;
  classificationUsa?: InputMaybe<Scalars["String"]>;
  clips?: InputMaybe<Scalars["String"]>;
  clothCovering?: InputMaybe<Scalars["String"]>;
  coinCirculationPeriod?: InputMaybe<Scalars["String"]>;
  coinComposition?: InputMaybe<Scalars["String"]>;
  coinIdentificationCode?: InputMaybe<Scalars["String"]>;
  coinIssueReason?: InputMaybe<Scalars["String"]>;
  coinMintIssuer?: InputMaybe<Scalars["String"]>;
  coinPeriod?: InputMaybe<Scalars["String"]>;
  coinPlacement?: InputMaybe<Scalars["String"]>;
  coinPlacementNotes?: InputMaybe<Scalars["String"]>;
  coinTitle?: InputMaybe<Scalars["String"]>;
  collectionName?: InputMaybe<Scalars["String"]>;
  color?: InputMaybe<Scalars["String"]>;
  coloring?: InputMaybe<Scalars["String"]>;
  commonName?: InputMaybe<Scalars["String"]>;
  compositionDescription?: InputMaybe<Scalars["String"]>;
  conservation?: InputMaybe<Scalars["String"]>;
  conservationMethod?: InputMaybe<Scalars["String"]>;
  conservationState?: InputMaybe<Scalars["String"]>;
  containerDetails?: InputMaybe<Scalars["String"]>;
  copyrightAndPropertyRestrictions?: InputMaybe<Scalars["String"]>;
  corner?: InputMaybe<Scalars["String"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  creationDateNotes?: InputMaybe<Scalars["String"]>;
  creationPeriod?: InputMaybe<Scalars["String"]>;
  criticalCatalogue?: InputMaybe<Scalars["Boolean"]>;
  currency?: InputMaybe<Scalars["String"]>;
  currentValue?: InputMaybe<Scalars["Float"]>;
  dateEndDate?: InputMaybe<Scalars["Date"]>;
  dateStartDate?: InputMaybe<Scalars["Date"]>;
  datedObjectNotes?: InputMaybe<Scalars["String"]>;
  datingType?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  discoveryAuthor?: InputMaybe<Scalars["String"]>;
  discoveryDate?: InputMaybe<Scalars["Date"]>;
  discoveryNotes?: InputMaybe<Scalars["String"]>;
  discoveryPlace?: InputMaybe<Scalars["String"]>;
  discoverySite?: InputMaybe<Scalars["String"]>;
  distribution?: InputMaybe<Scalars["String"]>;
  dustJacket?: InputMaybe<Scalars["String"]>;
  dynamicNotes?: InputMaybe<Scalars["String"]>;
  edge?: InputMaybe<Scalars["String"]>;
  editionNotes?: InputMaybe<Scalars["String"]>;
  enviromentalConditionsNotes?: InputMaybe<Scalars["String"]>;
  externalDecorations?: InputMaybe<Scalars["String"]>;
  fileSize?: InputMaybe<Scalars["Float"]>;
  fileSizeUnit?: InputMaybe<Scalars["String"]>;
  finishing?: InputMaybe<Scalars["String"]>;
  firstDraftNotes?: InputMaybe<Scalars["String"]>;
  fiscalValue?: InputMaybe<Scalars["String"]>;
  flyleaf?: InputMaybe<Scalars["String"]>;
  footEdge?: InputMaybe<Scalars["String"]>;
  foreEdge?: InputMaybe<Scalars["String"]>;
  frameDescription?: InputMaybe<Scalars["String"]>;
  frameManufacturer?: InputMaybe<Scalars["String"]>;
  frameMaterial?: InputMaybe<Scalars["String"]>;
  frontCover?: InputMaybe<Scalars["String"]>;
  glueing?: InputMaybe<Scalars["String"]>;
  grossDepth?: InputMaybe<Scalars["Float"]>;
  grossDiameter?: InputMaybe<Scalars["Float"]>;
  grossDimensionsAcquisition?: InputMaybe<Scalars["String"]>;
  grossHeight?: InputMaybe<Scalars["Float"]>;
  grossLengthUnit?: InputMaybe<Scalars["String"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnit?: InputMaybe<Scalars["String"]>;
  grossWidth?: InputMaybe<Scalars["Float"]>;
  handlingRequirements?: InputMaybe<Scalars["String"]>;
  hardwareToolsInstallation?: InputMaybe<Scalars["String"]>;
  hasBarriers?: InputMaybe<Scalars["Boolean"]>;
  hasBase?: InputMaybe<Scalars["Boolean"]>;
  hasContainer?: InputMaybe<Scalars["Boolean"]>;
  hasFrame?: InputMaybe<Scalars["Boolean"]>;
  hasFullDatetime?: InputMaybe<Scalars["Boolean"]>;
  hasHumanAnimalPresence?: InputMaybe<Scalars["Boolean"]>;
  hasLabelsStamps?: InputMaybe<Scalars["Boolean"]>;
  hasLoop?: InputMaybe<Scalars["Boolean"]>;
  hasOriginalFrame?: InputMaybe<Scalars["Boolean"]>;
  hasOtherAutographed?: InputMaybe<Scalars["Boolean"]>;
  hasPeriod?: InputMaybe<Scalars["Boolean"]>;
  hasWoodFrame?: InputMaybe<Scalars["Boolean"]>;
  hasYear?: InputMaybe<Scalars["Boolean"]>;
  headband?: InputMaybe<Scalars["String"]>;
  headcap?: InputMaybe<Scalars["String"]>;
  holographicStripe?: InputMaybe<Scalars["String"]>;
  humanAnimalPresenceNotes?: InputMaybe<Scalars["String"]>;
  infoNotes?: InputMaybe<Scalars["String"]>;
  injuryRisk?: InputMaybe<Scalars["String"]>;
  injuryRiskDescription?: InputMaybe<Scalars["String"]>;
  interiorDecorations?: InputMaybe<Scalars["String"]>;
  internationalCode?: InputMaybe<Scalars["String"]>;
  inventoryNumber?: InputMaybe<Scalars["String"]>;
  isArtMultiple?: InputMaybe<Scalars["Boolean"]>;
  isArtistProof?: InputMaybe<Scalars["Boolean"]>;
  isAudio?: InputMaybe<Scalars["Boolean"]>;
  isCenturyApprox?: InputMaybe<Scalars["Boolean"]>;
  isCenturyEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isCenturyInterval?: InputMaybe<Scalars["Boolean"]>;
  isCenturyStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  isComposed?: InputMaybe<Scalars["Boolean"]>;
  isDateApprox?: InputMaybe<Scalars["Boolean"]>;
  isDateEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isDateInterval?: InputMaybe<Scalars["Boolean"]>;
  isDateStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  isDatedObject?: InputMaybe<Scalars["Boolean"]>;
  isDynamic?: InputMaybe<Scalars["Boolean"]>;
  isEdition?: InputMaybe<Scalars["Boolean"]>;
  isFirstDraft?: InputMaybe<Scalars["Boolean"]>;
  isMaster?: InputMaybe<Scalars["Boolean"]>;
  isNotifiedCulturalProperty?: InputMaybe<Scalars["Boolean"]>;
  isSignedObject?: InputMaybe<Scalars["Boolean"]>;
  isTitledObject?: InputMaybe<Scalars["Boolean"]>;
  isValuableFrame?: InputMaybe<Scalars["Boolean"]>;
  isVideo?: InputMaybe<Scalars["Boolean"]>;
  isYearApprox?: InputMaybe<Scalars["Boolean"]>;
  isYearEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isYearInterval?: InputMaybe<Scalars["Boolean"]>;
  isYearStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  label?: InputMaybe<Scalars["String"]>;
  labelsStampsNotes?: InputMaybe<Scalars["String"]>;
  legalStatus?: InputMaybe<Scalars["String"]>;
  legend?: InputMaybe<Scalars["String"]>;
  lenderRequests?: InputMaybe<Scalars["String"]>;
  lightUnit?: InputMaybe<Scalars["String"]>;
  manufactoringTechniques?: InputMaybe<Scalars["String"]>;
  manufacturerId?: InputMaybe<Scalars["ID"]>;
  masterLocation?: InputMaybe<Scalars["String"]>;
  material?: InputMaybe<Scalars["String"]>;
  mechanism?: InputMaybe<Scalars["String"]>;
  methodOfUse?: InputMaybe<Scalars["String"]>;
  mintMadeErrors?: InputMaybe<Scalars["String"]>;
  mintingLastDate?: InputMaybe<Scalars["String"]>;
  mintingLastDateNotes?: InputMaybe<Scalars["String"]>;
  mouldingSupportBase?: InputMaybe<Scalars["String"]>;
  multipleNumber?: InputMaybe<Scalars["String"]>;
  netDepth?: InputMaybe<Scalars["Float"]>;
  netDiameter?: InputMaybe<Scalars["Float"]>;
  netDimensionsAcquisition?: InputMaybe<Scalars["String"]>;
  netHeight?: InputMaybe<Scalars["Float"]>;
  netLengthUnit?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnit?: InputMaybe<Scalars["String"]>;
  netWidth?: InputMaybe<Scalars["Float"]>;
  nft?: InputMaybe<Scalars["Boolean"]>;
  nftNotes?: InputMaybe<Scalars["String"]>;
  nominalValue?: InputMaybe<Scalars["String"]>;
  notifiedPropertyNote?: InputMaybe<Scalars["String"]>;
  numberOfElements?: InputMaybe<Scalars["Int"]>;
  objectSetupAuthor?: InputMaybe<Scalars["String"]>;
  optimalHumidity?: InputMaybe<Scalars["Int"]>;
  optimalLightLevelIntensity?: InputMaybe<Scalars["Int"]>;
  optimalTemperature?: InputMaybe<Scalars["Int"]>;
  orientation?: InputMaybe<Scalars["String"]>;
  originNotes?: InputMaybe<Scalars["String"]>;
  otherAutographedNotes?: InputMaybe<Scalars["String"]>;
  otherModel?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  paperType?: InputMaybe<Scalars["String"]>;
  physicalFeaturesElements?: InputMaybe<Array<InputMaybe<AssetElementInput>>>;
  placement?: InputMaybe<Scalars["String"]>;
  postageMark?: InputMaybe<Scalars["String"]>;
  printerId?: InputMaybe<Scalars["ID"]>;
  printingErrors?: InputMaybe<Scalars["String"]>;
  printingLastDate?: InputMaybe<Scalars["String"]>;
  printingLastDateNotes?: InputMaybe<Scalars["String"]>;
  printingType?: InputMaybe<Scalars["String"]>;
  producerId?: InputMaybe<Scalars["ID"]>;
  productionMode?: InputMaybe<Scalars["String"]>;
  raisedBands?: InputMaybe<Scalars["String"]>;
  rarityDegree?: InputMaybe<Scalars["String"]>;
  rarityDegreeNotes?: InputMaybe<Scalars["String"]>;
  rarityDegreePhilatelic?: InputMaybe<Scalars["String"]>;
  rearCover?: InputMaybe<Scalars["String"]>;
  relatedPhysicalWork?: InputMaybe<Scalars["String"]>;
  relevantAwards?: InputMaybe<Scalars["String"]>;
  relief?: InputMaybe<Scalars["String"]>;
  reminting?: InputMaybe<Scalars["String"]>;
  replacedParts?: InputMaybe<Scalars["String"]>;
  reprinting?: InputMaybe<Scalars["String"]>;
  resolution?: InputMaybe<Scalars["Float"]>;
  resolutionUnit?: InputMaybe<Scalars["String"]>;
  rim?: InputMaybe<Scalars["String"]>;
  rotationAxes?: InputMaybe<Scalars["String"]>;
  rubber?: InputMaybe<Scalars["String"]>;
  scientificName?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["String"]>;
  securityThread?: InputMaybe<Scalars["String"]>;
  series?: InputMaybe<Scalars["String"]>;
  seriesDetails?: InputMaybe<Scalars["String"]>;
  settingNotes?: InputMaybe<Scalars["String"]>;
  shape?: InputMaybe<Scalars["String"]>;
  signedObjectNotes?: InputMaybe<Scalars["String"]>;
  signings?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  slots?: InputMaybe<Scalars["String"]>;
  spine?: InputMaybe<Scalars["String"]>;
  square?: InputMaybe<Scalars["String"]>;
  stampCenteringAndMargins?: InputMaybe<Scalars["String"]>;
  stampFormat?: InputMaybe<Scalars["String"]>;
  stampIndentationType?: InputMaybe<Scalars["String"]>;
  stampPrintingTechnique?: InputMaybe<Scalars["String"]>;
  stampRealization?: InputMaybe<Scalars["String"]>;
  stateOfConservation?: InputMaybe<Scalars["String"]>;
  subcategory?: InputMaybe<Scalars["String"]>;
  surfaceCharacteristics?: InputMaybe<Scalars["String"]>;
  taxonomyClass?: InputMaybe<Scalars["String"]>;
  taxonomyDomain?: InputMaybe<Scalars["String"]>;
  taxonomyFamily?: InputMaybe<Scalars["String"]>;
  taxonomyGenus?: InputMaybe<Scalars["String"]>;
  taxonomyKingdom?: InputMaybe<Scalars["String"]>;
  taxonomyNotes?: InputMaybe<Scalars["String"]>;
  taxonomyOrder?: InputMaybe<Scalars["String"]>;
  taxonomyPhylum?: InputMaybe<Scalars["String"]>;
  taxonomyTribe?: InputMaybe<Scalars["String"]>;
  technique?: InputMaybe<Scalars["String"]>;
  temperatureUnit?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
  titledObjectNotes?: InputMaybe<Scalars["String"]>;
  topEdge?: InputMaybe<Scalars["String"]>;
  usage?: InputMaybe<Scalars["String"]>;
  validated: Scalars["Boolean"];
  variants?: InputMaybe<Scalars["String"]>;
  videomakerId?: InputMaybe<Scalars["ID"]>;
  watermark?: InputMaybe<Scalars["String"]>;
  woodFrameDetails?: InputMaybe<Scalars["String"]>;
  yearEndDate?: InputMaybe<Scalars["Int"]>;
  yearStartDate?: InputMaybe<Scalars["Int"]>;
};

export type AssetInputCsvImport = {
  asset: AssetInput;
  assetPresentValue?: InputMaybe<AssetPresentValueInput>;
  author?: InputMaybe<Scalars["String"]>;
  lender?: InputMaybe<Scalars["String"]>;
  parsedRowIndex?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["String"]>;
};

export type AssetInsuranceStatus = {
  __typename?: "AssetInsuranceStatus";
  color?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  percentage?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export type AssetNode = Node & {
  __typename?: "AssetNode";
  actualDateAndPeriod?: Maybe<Scalars["String"]>;
  actualValue?: Maybe<Money>;
  appraisalAsset: AppraisalNodeConnection;
  archiveNumber?: Maybe<Scalars["String"]>;
  artistProofNotes?: Maybe<Scalars["String"]>;
  assetCurrentValue?: Maybe<Scalars["String"]>;
  assetLocation?: Maybe<Address>;
  assetPlacement?: Maybe<Scalars["String"]>;
  assetPredictions?: Maybe<AssetPredictionNode>;
  assetdating?: Maybe<AssetDatingNode>;
  audioVideoLength?: Maybe<Scalars["Float"]>;
  audioVideoLengthUnit?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
  authorEntity?: Maybe<RegistryNode>;
  availability: Scalars["String"];
  banknotePrinter?: Maybe<Scalars["String"]>;
  barriersDetails?: Maybe<Scalars["String"]>;
  baseDetails?: Maybe<Scalars["String"]>;
  bibliographyNotes?: Maybe<Scalars["String"]>;
  bookContainer?: Maybe<Scalars["String"]>;
  bookbinding?: Maybe<Scalars["String"]>;
  carat?: Maybe<Scalars["String"]>;
  carryingValueAcquisition?: Maybe<Scalars["String"]>;
  carryingValueAmount?: Maybe<Money>;
  carryingValueAmountCurrency: Scalars["String"];
  carryingValueFile?: Maybe<Scalars["String"]>;
  carryingValueFileTitle?: Maybe<Scalars["String"]>;
  carryingValueLastAcquisitionDate?: Maybe<Scalars["Date"]>;
  carryingValueNotes?: Maybe<Scalars["String"]>;
  carryingValueOrigin?: Maybe<Scalars["String"]>;
  catalogNumber?: Maybe<Scalars["String"]>;
  catalogingAuthor?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  centuryEndDate?: Maybe<Scalars["Int"]>;
  centuryEndDateWindow?: Maybe<Scalars["String"]>;
  centuryStartDate?: Maybe<Scalars["Int"]>;
  centuryStartDateWindow?: Maybe<Scalars["String"]>;
  circulation?: Maybe<Scalars["String"]>;
  classificationEng?: Maybe<Scalars["String"]>;
  classificationFra?: Maybe<Scalars["String"]>;
  classificationGer?: Maybe<Scalars["String"]>;
  classificationIta?: Maybe<Scalars["String"]>;
  classificationPhilatelic?: Maybe<Scalars["String"]>;
  classificationSpa?: Maybe<Scalars["String"]>;
  classificationUsa?: Maybe<Scalars["String"]>;
  clips?: Maybe<Scalars["String"]>;
  clothCovering?: Maybe<Scalars["String"]>;
  coinCirculationPeriod?: Maybe<Scalars["String"]>;
  coinComposition?: Maybe<Scalars["String"]>;
  coinIdentificationCode?: Maybe<Scalars["String"]>;
  coinIssueReason?: Maybe<Scalars["String"]>;
  coinMintIssuer?: Maybe<Scalars["String"]>;
  coinPeriod?: Maybe<Scalars["String"]>;
  coinPlacement?: Maybe<Scalars["String"]>;
  coinPlacementNotes?: Maybe<Scalars["String"]>;
  coinTitle?: Maybe<Scalars["String"]>;
  collection: CollectionNodeConnection;
  collectionName?: Maybe<Scalars["String"]>;
  color?: Maybe<Scalars["String"]>;
  coloring?: Maybe<Scalars["String"]>;
  commonName?: Maybe<Scalars["String"]>;
  compositionDescription?: Maybe<Scalars["String"]>;
  conditionstatusreportReportAsset: ConditionStatusReportNodeConnection;
  conservation?: Maybe<Scalars["String"]>;
  conservationMethod?: Maybe<Scalars["String"]>;
  conservationState?: Maybe<Scalars["String"]>;
  containerDetails?: Maybe<Scalars["String"]>;
  copyrightAndPropertyRestrictions?: Maybe<Scalars["String"]>;
  corner?: Maybe<Scalars["String"]>;
  coverType?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  creationDate?: Maybe<Scalars["DateTime"]>;
  creationDateNotes?: Maybe<Scalars["String"]>;
  creationPeriod?: Maybe<Scalars["String"]>;
  criticalCatalogue?: Maybe<Scalars["Boolean"]>;
  currency?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["Decimal"]>;
  dateAndPeriod?: Maybe<Scalars["String"]>;
  dateEndDate?: Maybe<Scalars["Date"]>;
  dateStartDate?: Maybe<Scalars["Date"]>;
  datedObjectNotes?: Maybe<Scalars["String"]>;
  datingType?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  depositationassetsSet: DepositationAssetNodeConnection;
  description?: Maybe<Scalars["String"]>;
  dimensionsNotes?: Maybe<Scalars["String"]>;
  discoveryAuthor?: Maybe<Scalars["String"]>;
  discoveryDate?: Maybe<Scalars["Date"]>;
  discoveryNotes?: Maybe<Scalars["String"]>;
  discoveryPlace?: Maybe<Scalars["String"]>;
  discoverySite?: Maybe<Scalars["String"]>;
  distribution?: Maybe<Scalars["String"]>;
  documents: AssetDocumentNodeConnection;
  dustJacket?: Maybe<Scalars["String"]>;
  dynamicNotes?: Maybe<Scalars["String"]>;
  edge?: Maybe<Scalars["String"]>;
  editionNotes?: Maybe<Scalars["String"]>;
  enviromentalConditionsNotes?: Maybe<Scalars["String"]>;
  eventLogs: EventLogNodeConnection;
  exhibition: ExhibitionNodeConnection;
  exportedDocument?: Maybe<Scalars["String"]>;
  externalDecorations?: Maybe<Scalars["String"]>;
  fileSize?: Maybe<Scalars["Float"]>;
  fileSizeUnit?: Maybe<Scalars["String"]>;
  fineness?: Maybe<Scalars["String"]>;
  finishing?: Maybe<Scalars["String"]>;
  firstDraftNotes?: Maybe<Scalars["String"]>;
  fiscalValue?: Maybe<Scalars["String"]>;
  flyleaf?: Maybe<Scalars["String"]>;
  footEdge?: Maybe<Scalars["String"]>;
  foreEdge?: Maybe<Scalars["String"]>;
  frameDescription?: Maybe<Scalars["String"]>;
  frameManufacturer?: Maybe<Scalars["String"]>;
  frameMaterial?: Maybe<Scalars["String"]>;
  frontCover?: Maybe<Scalars["String"]>;
  genericDimensions?: Maybe<Scalars["String"]>;
  glueing?: Maybe<Scalars["String"]>;
  grossDepth?: Maybe<Scalars["Float"]>;
  grossDiameter?: Maybe<Scalars["Float"]>;
  grossDimensionsAcquisition?: Maybe<Scalars["String"]>;
  grossHeight?: Maybe<Scalars["Float"]>;
  grossLengthUnit?: Maybe<Scalars["String"]>;
  grossWeight?: Maybe<Scalars["Float"]>;
  grossWeightUnit?: Maybe<Scalars["String"]>;
  grossWidth?: Maybe<Scalars["Float"]>;
  handlingRequirements?: Maybe<Scalars["String"]>;
  hardwareToolsInstallation?: Maybe<Scalars["String"]>;
  hasBarriers?: Maybe<Scalars["Boolean"]>;
  hasBase?: Maybe<Scalars["Boolean"]>;
  hasContainer?: Maybe<Scalars["Boolean"]>;
  hasFrame?: Maybe<Scalars["Boolean"]>;
  hasFullDatetime?: Maybe<Scalars["Boolean"]>;
  hasHumanAnimalPresence?: Maybe<Scalars["Boolean"]>;
  hasLabelsStamps?: Maybe<Scalars["Boolean"]>;
  hasLoop?: Maybe<Scalars["Boolean"]>;
  hasOriginalFrame?: Maybe<Scalars["Boolean"]>;
  hasOtherAutographed?: Maybe<Scalars["Boolean"]>;
  hasPeriod?: Maybe<Scalars["Boolean"]>;
  hasWoodFrame?: Maybe<Scalars["Boolean"]>;
  hasYear?: Maybe<Scalars["Boolean"]>;
  headband?: Maybe<Scalars["String"]>;
  headcap?: Maybe<Scalars["String"]>;
  holographicStripe?: Maybe<Scalars["String"]>;
  humanAnimalPresenceNotes?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  infoNotes?: Maybe<Scalars["String"]>;
  injuryRisk?: Maybe<Scalars["String"]>;
  injuryRiskDescription?: Maybe<Scalars["String"]>;
  interiorDecorations?: Maybe<Scalars["String"]>;
  internationalCode?: Maybe<Scalars["String"]>;
  inventoryNumber?: Maybe<Scalars["String"]>;
  isArtMultiple?: Maybe<Scalars["Boolean"]>;
  isArtistProof?: Maybe<Scalars["Boolean"]>;
  isAudio?: Maybe<Scalars["Boolean"]>;
  isCenturyApprox?: Maybe<Scalars["Boolean"]>;
  isCenturyEndDateBc?: Maybe<Scalars["Boolean"]>;
  isCenturyInterval?: Maybe<Scalars["Boolean"]>;
  isCenturyStartDateBc?: Maybe<Scalars["Boolean"]>;
  isComposed?: Maybe<Scalars["Boolean"]>;
  isDateApprox?: Maybe<Scalars["Boolean"]>;
  isDateEndDateBc?: Maybe<Scalars["Boolean"]>;
  isDateInterval?: Maybe<Scalars["Boolean"]>;
  isDateStartDateBc?: Maybe<Scalars["Boolean"]>;
  isDatedObject?: Maybe<Scalars["Boolean"]>;
  isDeleted?: Maybe<Scalars["Boolean"]>;
  isDynamic?: Maybe<Scalars["Boolean"]>;
  isEdition?: Maybe<Scalars["Boolean"]>;
  isFirstDraft?: Maybe<Scalars["Boolean"]>;
  isMaster?: Maybe<Scalars["Boolean"]>;
  isNotifiedCulturalProperty?: Maybe<Scalars["Boolean"]>;
  isSignedObject?: Maybe<Scalars["Boolean"]>;
  isTitledObject?: Maybe<Scalars["Boolean"]>;
  isValuableFrame?: Maybe<Scalars["Boolean"]>;
  isVideo?: Maybe<Scalars["Boolean"]>;
  isYearApprox?: Maybe<Scalars["Boolean"]>;
  isYearEndDateBc?: Maybe<Scalars["Boolean"]>;
  isYearInterval?: Maybe<Scalars["Boolean"]>;
  isYearStartDateBc?: Maybe<Scalars["Boolean"]>;
  label?: Maybe<Scalars["String"]>;
  labelsStampsNotes?: Maybe<Scalars["String"]>;
  legalStatus?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  lenderRequests?: Maybe<Scalars["String"]>;
  lightUnit?: Maybe<Scalars["String"]>;
  linkedRegistries: RegistryNodeConnection;
  linkedassetregistriesSet: LinkedAssetRegistryNodeConnection;
  mainImage?: Maybe<Scalars["String"]>;
  manufactoringTechniques?: Maybe<Scalars["String"]>;
  manufacturer?: Maybe<RegistryNode>;
  masterLocation?: Maybe<Scalars["String"]>;
  material?: Maybe<Scalars["String"]>;
  mechanism?: Maybe<Scalars["String"]>;
  media: MediaNodeConnection;
  methodOfUse?: Maybe<Scalars["String"]>;
  mintMadeErrors?: Maybe<Scalars["String"]>;
  mintingLastDate?: Maybe<Scalars["String"]>;
  mintingLastDateNotes?: Maybe<Scalars["String"]>;
  mouldingSupportBase?: Maybe<Scalars["String"]>;
  multipleNumber?: Maybe<Scalars["String"]>;
  netDepth?: Maybe<Scalars["Float"]>;
  netDiameter?: Maybe<Scalars["Float"]>;
  netDimensionsAcquisition?: Maybe<Scalars["String"]>;
  netHeight?: Maybe<Scalars["Float"]>;
  netLengthUnit?: Maybe<Scalars["String"]>;
  netWeight?: Maybe<Scalars["Float"]>;
  netWeightUnit?: Maybe<Scalars["String"]>;
  netWidth?: Maybe<Scalars["Float"]>;
  nft?: Maybe<Scalars["Boolean"]>;
  nftNotes?: Maybe<Scalars["String"]>;
  nominalValue?: Maybe<Scalars["String"]>;
  notifiedPropertyNote?: Maybe<Scalars["String"]>;
  numberOfElements?: Maybe<Scalars["Int"]>;
  objectSetupAuthor?: Maybe<Scalars["String"]>;
  optimalHumidity?: Maybe<Scalars["Int"]>;
  optimalLightLevelIntensity?: Maybe<Scalars["Int"]>;
  optimalTemperature?: Maybe<Scalars["Int"]>;
  orientation?: Maybe<Scalars["String"]>;
  originNotes?: Maybe<Scalars["String"]>;
  otherAutographedNotes?: Maybe<Scalars["String"]>;
  otherModel?: Maybe<Scalars["String"]>;
  owner?: Maybe<RegistryNode>;
  paperType?: Maybe<Scalars["String"]>;
  physicalFeaturesElements: AssetElementNodeConnection;
  placement?: Maybe<Scalars["String"]>;
  policyAsset: PolicyAssetNodeConnection;
  policyAssetHistoric: PolicyAssetBasketHistoryNodeConnection;
  policySet: PolicyNodeConnection;
  postageMark?: Maybe<Scalars["String"]>;
  presentValues: AssetPresentValueNodeConnection;
  printer?: Maybe<RegistryNode>;
  printingErrors?: Maybe<Scalars["String"]>;
  printingLastDate?: Maybe<Scalars["String"]>;
  printingLastDateNotes?: Maybe<Scalars["String"]>;
  printingType?: Maybe<Scalars["String"]>;
  producer?: Maybe<RegistryNode>;
  productionMode?: Maybe<Scalars["String"]>;
  raisedBands?: Maybe<Scalars["String"]>;
  rarityDegree?: Maybe<Scalars["String"]>;
  rarityDegreeNotes?: Maybe<Scalars["String"]>;
  rarityDegreePhilatelic?: Maybe<Scalars["String"]>;
  rearCover?: Maybe<Scalars["String"]>;
  relatedPhysicalWork?: Maybe<Scalars["String"]>;
  relevantAwards?: Maybe<Scalars["String"]>;
  relief?: Maybe<Scalars["String"]>;
  reminting?: Maybe<Scalars["String"]>;
  replacedParts?: Maybe<Scalars["String"]>;
  reprinting?: Maybe<Scalars["String"]>;
  resolution?: Maybe<Scalars["Float"]>;
  resolutionUnit?: Maybe<Scalars["String"]>;
  restorationAsset: RestorationNodeConnection;
  rim?: Maybe<Scalars["String"]>;
  rotationAxes?: Maybe<Scalars["String"]>;
  rubber?: Maybe<Scalars["String"]>;
  scientificName?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["String"]>;
  searchString?: Maybe<Scalars["String"]>;
  securityThread?: Maybe<Scalars["String"]>;
  series?: Maybe<Scalars["String"]>;
  seriesDetails?: Maybe<Scalars["String"]>;
  settingNotes?: Maybe<Scalars["String"]>;
  shape?: Maybe<Scalars["String"]>;
  signedObjectNotes?: Maybe<Scalars["String"]>;
  signings?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  slots?: Maybe<Scalars["String"]>;
  spine?: Maybe<Scalars["String"]>;
  square?: Maybe<Scalars["String"]>;
  stampCenteringAndMargins?: Maybe<Scalars["String"]>;
  stampFormat?: Maybe<Scalars["String"]>;
  stampIndentationType?: Maybe<Scalars["String"]>;
  stampPrintingTechnique?: Maybe<Scalars["String"]>;
  stampRealization?: Maybe<Scalars["String"]>;
  stateOfConservation?: Maybe<Scalars["String"]>;
  subcategory?: Maybe<Scalars["String"]>;
  surfaceCharacteristics?: Maybe<Scalars["String"]>;
  taxonomyClass?: Maybe<Scalars["String"]>;
  taxonomyDomain?: Maybe<Scalars["String"]>;
  taxonomyFamily?: Maybe<Scalars["String"]>;
  taxonomyGenus?: Maybe<Scalars["String"]>;
  taxonomyKingdom?: Maybe<Scalars["String"]>;
  taxonomyNotes?: Maybe<Scalars["String"]>;
  taxonomyOrder?: Maybe<Scalars["String"]>;
  taxonomyPhylum?: Maybe<Scalars["String"]>;
  taxonomyTribe?: Maybe<Scalars["String"]>;
  technique?: Maybe<Scalars["String"]>;
  temperatureUnit?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  titledObjectNotes?: Maybe<Scalars["String"]>;
  topEdge?: Maybe<Scalars["String"]>;
  transportationassetsSet: TransportationAssetNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  usage?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  validated?: Maybe<Scalars["Boolean"]>;
  variants?: Maybe<Scalars["String"]>;
  videomaker?: Maybe<RegistryNode>;
  visibleBy?: Maybe<Array<Maybe<Scalars["String"]>>>;
  watermark?: Maybe<Scalars["String"]>;
  woodFrameDetails?: Maybe<Scalars["String"]>;
  yearEndDate?: Maybe<Scalars["Int"]>;
  yearStartDate?: Maybe<Scalars["Int"]>;
};

export type AssetNodeAppraisalAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeCollectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeConditionstatusreportReportAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeDepositationassetsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetNodeDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeEventLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeExhibitionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeLinkedRegistriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeLinkedassetregistriesSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePhysicalFeaturesElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicyAssetHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePresentValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeRestorationAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeTransportationassetsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type AssetNodeConnection = {
  __typename?: "AssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `AssetNode` and its cursor. */
export type AssetNodeEdge = {
  __typename?: "AssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetNode>;
};

export type AssetPredictionNode = Node & {
  __typename?: "AssetPredictionNode";
  aiIntrinsicValue?: Maybe<Scalars["Int"]>;
  aiLiquidityValue?: Maybe<Scalars["Int"]>;
  aiReplacmentValue?: Maybe<Scalars["Int"]>;
  asset?: Maybe<AssetNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  /** The ID of the object */
  id: Scalars["ID"];
  jobUuid?: Maybe<Scalars["String"]>;
  type?: Maybe<ManagementAssetPredictionTypeChoices>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type AssetPredictionNodeConnection = {
  __typename?: "AssetPredictionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetPredictionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetPredictionNode` and its cursor. */
export type AssetPredictionNodeEdge = {
  __typename?: "AssetPredictionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetPredictionNode>;
};

export type AssetPresentValueInput = {
  amount?: InputMaybe<MoneyInput>;
  assetId?: InputMaybe<Scalars["ID"]>;
  authorId?: InputMaybe<Scalars["ID"]>;
  estimateDate?: InputMaybe<Scalars["Date"]>;
  estimateReason: Scalars["String"];
  file?: InputMaybe<Scalars["Upload"]>;
  fileTitle?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type AssetPresentValueNode = Node & {
  __typename?: "AssetPresentValueNode";
  amount: Money;
  amountCurrency: ManagementAssetPresentValueAmountCurrencyChoices;
  appraisalId?: Maybe<Scalars["String"]>;
  asset?: Maybe<AssetNode>;
  author?: Maybe<RegistryNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  estimateDate?: Maybe<Scalars["Date"]>;
  estimateReason?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  fileTitle?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type AssetPresentValueNodeConnection = {
  __typename?: "AssetPresentValueNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetPresentValueNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetPresentValueNode` and its cursor. */
export type AssetPresentValueNodeEdge = {
  __typename?: "AssetPresentValueNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetPresentValueNode>;
};

export type AssetSlogStatus = {
  __typename?: "AssetSlogStatus";
  color?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  percentage?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
};

export enum BaseEventLogAuctionRightsCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum BaseEventLogExternalEntityChoices {
  /** Appraisal */
  Appraisal = "APPRAISAL",
  /** Collection */
  Collection = "COLLECTION",
  /** Status Report */
  CsReport = "CS_REPORT",
  /** Restoration */
  Depositation = "DEPOSITATION",
  /** Exhibition */
  Exhibition = "EXHIBITION",
  /** Logistics */
  Logistics = "LOGISTICS",
  /** no_entity */
  NoEntity = "NO_ENTITY",
  /** Policy */
  Policy = "POLICY",
  /** Restoration */
  Restoration = "RESTORATION",
  /** Transport */
  Transportation = "TRANSPORTATION",
}

export enum BaseEventLogMaximumPriceCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum BaseEventLogMinimumPriceCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum BaseEventLogReservePriceCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum BaseEventLogValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum BaseExportedDocumentsTypeChoices {
  /** bill */
  Bill = "BILL",
  /** depositation_inbound_bill */
  DepositationInboundBill = "DEPOSITATION_INBOUND_BILL",
  /** depositation_outbound_bill */
  DepositationOutboundBill = "DEPOSITATION_OUTBOUND_BILL",
  /** Document */
  DocumentExport = "DOCUMENT_EXPORT",
  /** label */
  Label = "LABEL",
  /** model_export */
  ModelExport = "MODEL_EXPORT",
  /** packing_list */
  PackingList = "PACKING_LIST",
}

export type BasicInfoNode = {
  __typename?: "BasicInfoNode";
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  username?: Maybe<Scalars["String"]>;
};

export type CertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateType?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  grouping?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  printTemplate?: InputMaybe<Scalars["String"]>;
};

export type CertificateNode = Node & {
  __typename?: "CertificateNode";
  cancelReplace?: Maybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: Maybe<Scalars["String"]>;
  certificateType: SpinCertificateCertificateTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  creationDate: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  emissionDate?: Maybe<Scalars["DateTime"]>;
  file?: Maybe<Scalars["String"]>;
  grouping?: Maybe<SpinCertificateGroupingChoices>;
  hasImages?: Maybe<Scalars["Boolean"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isExpired?: Maybe<Scalars["Boolean"]>;
  isSplitted?: Maybe<Scalars["Boolean"]>;
  isValid?: Maybe<Scalars["Boolean"]>;
  language: Scalars["String"];
  lender?: Maybe<Scalars["String"]>;
  lenderCountry?: Maybe<Scalars["String"]>;
  mainOfficeCountry?: Maybe<Scalars["String"]>;
  metafields?: Maybe<Scalars["GenericScalar"]>;
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  policyAsset: PolicyAssetNodeConnection;
  policyAssetsCount?: Maybe<Scalars["Int"]>;
  printTemplate: CertificateTemplateNode;
  progressiveNumber?: Maybe<Scalars["Int"]>;
  shipper?: Maybe<Scalars["String"]>;
  signed?: Maybe<Scalars["Boolean"]>;
  signedAt?: Maybe<Scalars["DateTime"]>;
  signedBy?: Maybe<UserType>;
  totalInsuredValue?: Maybe<Money>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type CertificateNodePolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CertificateNodeConnection = {
  __typename?: "CertificateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `CertificateNode` and its cursor. */
export type CertificateNodeEdge = {
  __typename?: "CertificateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CertificateNode>;
};

export type CertificateTemplateNode = Node & {
  __typename?: "CertificateTemplateNode";
  certificateSet: CertificateNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  isDefault?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  templateType?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type CertificateTemplateNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CertificateTemplateNodeConnection = {
  __typename?: "CertificateTemplateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CertificateTemplateNode` and its cursor. */
export type CertificateTemplateNodeEdge = {
  __typename?: "CertificateTemplateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CertificateTemplateNode>;
};

export type ChangeAssetCategoryInput = {
  authorEntityId?: InputMaybe<Scalars["String"]>;
  category: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  subcategory: Scalars["String"];
};

export type ChangeAssetCategoryPayload = {
  __typename?: "ChangeAssetCategoryPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ChangePasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  confirmPassword: Scalars["String"];
  email: Scalars["String"];
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangePasswordPayload = {
  __typename?: "ChangePasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ClientInput = {
  currency?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  paidUntil?: InputMaybe<Scalars["String"]>;
  tier?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type ClientNode = Node & {
  __typename?: "ClientNode";
  backgroundColor?: Maybe<Scalars["String"]>;
  createdOn: Scalars["Date"];
  currency: Scalars["String"];
  currentAssets?: Maybe<Scalars["Int"]>;
  currentInvites?: Maybe<Scalars["Int"]>;
  currentProjects?: Maybe<Scalars["Int"]>;
  currentStorage?: Maybe<Scalars["Float"]>;
  currentUsers?: Maybe<Scalars["Int"]>;
  customerType: Scalars["String"];
  dashboardBaseUrl: Scalars["String"];
  defaultBroker?: Maybe<RegistryNode>;
  defaultInsuranceCompany?: Maybe<RegistryNode>;
  depositationCompanyTenantDefault?: Maybe<RegistryNode>;
  displayName?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isInMaintenance?: Maybe<Scalars["Boolean"]>;
  language: Scalars["String"];
  location: Scalars["String"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  onTrial?: Maybe<Scalars["Boolean"]>;
  paidUntil: Scalars["Date"];
  schemaName: Scalars["String"];
  storageName: Scalars["String"];
  tier: Scalars["String"];
  tierSpecs?: Maybe<ClientTierNode>;
  timezone: Scalars["String"];
  usedInvites: Scalars["Int"];
};

export type ClientNodeConnection = {
  __typename?: "ClientNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ClientNode` and its cursor. */
export type ClientNodeEdge = {
  __typename?: "ClientNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ClientNode>;
};

export type ClientTierNode = {
  __typename?: "ClientTierNode";
  maxAssets?: Maybe<Scalars["Int"]>;
  maxInvites?: Maybe<Scalars["Int"]>;
  maxProjects?: Maybe<Scalars["Int"]>;
  maxStorage?: Maybe<Scalars["Int"]>;
  maxStoragePerProject?: Maybe<Scalars["Int"]>;
  maxStoragePerUpload?: Maybe<Scalars["Int"]>;
  maxStoragePerUser?: Maybe<Scalars["Int"]>;
  maxUploads?: Maybe<Scalars["Int"]>;
  maxUploadsPerProject?: Maybe<Scalars["Int"]>;
  maxUsers?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Int"]>;
};

export type CloseAppraisalInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CloseAppraisalPayload = {
  __typename?: "CloseAppraisalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  closeAppraisal?: Maybe<AppraisalNode>;
};

export type CloseConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CloseConditionStatusReportPayload = {
  __typename?: "CloseConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  closeConditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type CloseRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type CloseRestorationPayload = {
  __typename?: "CloseRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  closeRestoration?: Maybe<RestorationNode>;
};

export type CollectionInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type CollectionNode = Node & {
  __typename?: "CollectionNode";
  actualStatusObjects?: Maybe<Scalars["String"]>;
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  createdFrom?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  exportedDocument?: Maybe<ExportDocumentsNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  media: MediaNodeConnection;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  totalAssetsNumber?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  validatedAssetsNumber?: Maybe<Scalars["Int"]>;
};

export type CollectionNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CollectionNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CollectionNodeConnection = {
  __typename?: "CollectionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `CollectionNode` and its cursor. */
export type CollectionNodeEdge = {
  __typename?: "CollectionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CollectionNode>;
};

export type CompanyContactInput = {
  companyContact: Scalars["ID"];
  main: Scalars["Boolean"];
  relatedCompany: Scalars["ID"];
};

export type CompanyContactNode = Node & {
  __typename?: "CompanyContactNode";
  companyContact: RegistryNode;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  /** The ID of the object */
  id: Scalars["ID"];
  main?: Maybe<Scalars["Boolean"]>;
  relatedCompany: RegistryNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type CompanyContactNodeConnection = {
  __typename?: "CompanyContactNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyContactNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CompanyContactNode` and its cursor. */
export type CompanyContactNodeEdge = {
  __typename?: "CompanyContactNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CompanyContactNode>;
};

export type CompanyContactType = {
  __typename?: "CompanyContactType";
  companyContact: RegistryNode;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  id: Scalars["ID"];
  main?: Maybe<Scalars["Boolean"]>;
  relatedCompany: RegistryNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type ConditionStatusReportInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  collectionPublicName?: InputMaybe<Scalars["String"]>;
  environmentalConditionsAbnormalHumidityEvent?: InputMaybe<Scalars["String"]>;
  environmentalConditionsAbnormalLightEvent?: InputMaybe<Scalars["String"]>;
  environmentalConditionsAbnormalTemperatureEvent?: InputMaybe<Scalars["String"]>;
  environmentalConditionsLightUnit?: InputMaybe<Scalars["String"]>;
  environmentalConditionsOptimalHumidity?: InputMaybe<Scalars["Int"]>;
  environmentalConditionsOptimalLightLevelIntensity?: InputMaybe<Scalars["Int"]>;
  environmentalConditionsOptimalTemperature?: InputMaybe<Scalars["Int"]>;
  environmentalConditionsTemperatureUnit?: InputMaybe<Scalars["String"]>;
  exhibitionAddress?: InputMaybe<AddressInput>;
  exhibitionCurator?: InputMaybe<Scalars["ID"]>;
  exhibitionEventFrom?: InputMaybe<Scalars["DateTime"]>;
  exhibitionEventTo?: InputMaybe<Scalars["DateTime"]>;
  exhibitionHostEntity?: InputMaybe<Scalars["ID"]>;
  exhibitionLender?: InputMaybe<Scalars["ID"]>;
  exhibitionLoanFrom?: InputMaybe<Scalars["DateTime"]>;
  exhibitionLoanTo?: InputMaybe<Scalars["DateTime"]>;
  exhibitionLocation?: InputMaybe<Scalars["String"]>;
  exhibitionNotes?: InputMaybe<Scalars["String"]>;
  exhibitionPermanent?: InputMaybe<Scalars["Boolean"]>;
  exhibitionPlanner?: InputMaybe<Scalars["ID"]>;
  exhibitionTitle?: InputMaybe<Scalars["String"]>;
  logisticsAirConditioningTransportConditions?: InputMaybe<Scalars["String"]>;
  logisticsCarrier?: InputMaybe<Scalars["ID"]>;
  logisticsDeliveryAddress?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryCity?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryCountry?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryDate?: InputMaybe<Scalars["DateTime"]>;
  logisticsDeliveryLocation?: InputMaybe<AddressInput>;
  logisticsDeliveryState?: InputMaybe<Scalars["String"]>;
  logisticsDeliveryZip?: InputMaybe<Scalars["String"]>;
  logisticsId?: InputMaybe<Scalars["String"]>;
  logisticsNumberOfPackages?: InputMaybe<Scalars["Int"]>;
  logisticsPickupAddress?: InputMaybe<Scalars["String"]>;
  logisticsPickupCity?: InputMaybe<Scalars["String"]>;
  logisticsPickupCountry?: InputMaybe<Scalars["String"]>;
  logisticsPickupDate?: InputMaybe<Scalars["DateTime"]>;
  logisticsPickupLocation?: InputMaybe<AddressInput>;
  logisticsPickupState?: InputMaybe<Scalars["String"]>;
  logisticsPickupZip?: InputMaybe<Scalars["String"]>;
  logisticsRecipient?: InputMaybe<Scalars["ID"]>;
  logisticsSender?: InputMaybe<Scalars["ID"]>;
  reportAsset?: InputMaybe<Scalars["ID"]>;
  reportCompany?: InputMaybe<Scalars["ID"]>;
  reportDate?: InputMaybe<Scalars["DateTime"]>;
  reportDetailsAssetCondition?: InputMaybe<Scalars["String"]>;
  reportDetailsAssetDamageDescription?: InputMaybe<Scalars["String"]>;
  reportDetailsExamConditions?: InputMaybe<Scalars["String"]>;
  reportDetailsExamConditionsNote?: InputMaybe<Scalars["String"]>;
  reportDetailsExamLocation?: InputMaybe<Scalars["String"]>;
  reportDetailsExaminedPackages?: InputMaybe<Scalars["String"]>;
  reportDetailsExhaminationTimeTechnicalDescription?: InputMaybe<Scalars["String"]>;
  reportDetailsPackagingGeneralConditions?: InputMaybe<Scalars["String"]>;
  reportDetailsPackagingStatusDescription?: InputMaybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsCondition?: InputMaybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsDamageDescription?: InputMaybe<Scalars["String"]>;
  reportExaminationType: Scalars["String"];
  reportExaminer?: InputMaybe<Scalars["ID"]>;
  reportMotivation: Scalars["String"];
  reportMotivationDescription?: InputMaybe<Scalars["String"]>;
  reportStatus: Scalars["String"];
  reportType: Scalars["String"];
  settingsConservation?: InputMaybe<Scalars["String"]>;
  settingsExaminerComments?: InputMaybe<Scalars["String"]>;
  settingsHandlingRequirements?: InputMaybe<Scalars["String"]>;
  settingsHasBarriers?: InputMaybe<Scalars["Boolean"]>;
  settingsInjuryRisk?: InputMaybe<Scalars["String"]>;
  settingsLenderRequests?: InputMaybe<Scalars["String"]>;
  settingsNotes?: InputMaybe<Scalars["String"]>;
  valueEstimatedValue?: InputMaybe<MoneyInput>;
  valueEstimatedValueExaminationTime?: InputMaybe<MoneyInput>;
  valuePercentageDepreciation?: InputMaybe<Scalars["Float"]>;
};

export type ConditionStatusReportNode = Node & {
  __typename?: "ConditionStatusReportNode";
  collectionPublicName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  environmentalConditionsAbnormalHumidityEvent?: Maybe<Scalars["String"]>;
  environmentalConditionsAbnormalLightEvent?: Maybe<Scalars["String"]>;
  environmentalConditionsAbnormalTemperatureEvent?: Maybe<Scalars["String"]>;
  environmentalConditionsLightUnit?: Maybe<Scalars["String"]>;
  environmentalConditionsOptimalHumidity?: Maybe<Scalars["Int"]>;
  environmentalConditionsOptimalLightLevelIntensity?: Maybe<Scalars["Int"]>;
  environmentalConditionsOptimalTemperature?: Maybe<Scalars["Int"]>;
  environmentalConditionsTemperatureUnit?: Maybe<Scalars["String"]>;
  exhibitionAddress?: Maybe<Address>;
  exhibitionCurator?: Maybe<RegistryNode>;
  exhibitionEventFrom?: Maybe<Scalars["DateTime"]>;
  exhibitionEventTo?: Maybe<Scalars["DateTime"]>;
  exhibitionHostEntity?: Maybe<RegistryNode>;
  exhibitionLender?: Maybe<RegistryNode>;
  exhibitionLoanFrom?: Maybe<Scalars["DateTime"]>;
  exhibitionLoanTo?: Maybe<Scalars["DateTime"]>;
  exhibitionLocation?: Maybe<Scalars["String"]>;
  exhibitionNotes?: Maybe<Scalars["String"]>;
  exhibitionPermanent?: Maybe<Scalars["Boolean"]>;
  exhibitionPlanner?: Maybe<RegistryNode>;
  exhibitionTitle?: Maybe<Scalars["String"]>;
  exportedDocument?: Maybe<ExportDocumentsNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  isSigned?: Maybe<Scalars["Boolean"]>;
  logisticsAirConditioningTransportConditions?: Maybe<Scalars["String"]>;
  logisticsCarrier?: Maybe<RegistryNode>;
  logisticsDeliveryAddress?: Maybe<Scalars["String"]>;
  logisticsDeliveryCity?: Maybe<Scalars["String"]>;
  logisticsDeliveryCountry?: Maybe<Scalars["String"]>;
  logisticsDeliveryDate?: Maybe<Scalars["DateTime"]>;
  logisticsDeliveryLocation?: Maybe<Address>;
  logisticsDeliveryState?: Maybe<Scalars["String"]>;
  logisticsDeliveryZip?: Maybe<Scalars["String"]>;
  logisticsId?: Maybe<Scalars["String"]>;
  logisticsNumberOfPackages?: Maybe<Scalars["Int"]>;
  logisticsPickupAddress?: Maybe<Scalars["String"]>;
  logisticsPickupCity?: Maybe<Scalars["String"]>;
  logisticsPickupCountry?: Maybe<Scalars["String"]>;
  logisticsPickupDate?: Maybe<Scalars["DateTime"]>;
  logisticsPickupLocation?: Maybe<Address>;
  logisticsPickupState?: Maybe<Scalars["String"]>;
  logisticsPickupZip?: Maybe<Scalars["String"]>;
  logisticsRecipient?: Maybe<RegistryNode>;
  logisticsSender?: Maybe<RegistryNode>;
  media: MediaNodeConnection;
  reportAsset?: Maybe<AssetNode>;
  reportCompany?: Maybe<RegistryNode>;
  reportDate?: Maybe<Scalars["DateTime"]>;
  reportDetailsAssetCondition?: Maybe<Scalars["String"]>;
  reportDetailsAssetDamageDescription?: Maybe<Scalars["String"]>;
  reportDetailsExamConditions?: Maybe<Scalars["String"]>;
  reportDetailsExamConditionsNote?: Maybe<Scalars["String"]>;
  reportDetailsExamLocation?: Maybe<Scalars["String"]>;
  reportDetailsExaminedPackages?: Maybe<Scalars["String"]>;
  reportDetailsExhaminationTimeTechnicalDescription?: Maybe<Scalars["String"]>;
  reportDetailsPackagingGeneralConditions?: Maybe<Scalars["String"]>;
  reportDetailsPackagingStatusDescription?: Maybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsCondition?: Maybe<Scalars["String"]>;
  reportDetailsProtectionsSupportsDamageDescription?: Maybe<Scalars["String"]>;
  reportExaminationType?: Maybe<Scalars["String"]>;
  reportExaminer?: Maybe<RegistryNode>;
  reportMotivation?: Maybe<Scalars["String"]>;
  reportMotivationDescription?: Maybe<Scalars["String"]>;
  reportStatus?: Maybe<Scalars["String"]>;
  reportType?: Maybe<Scalars["String"]>;
  settingsConservation?: Maybe<Scalars["String"]>;
  settingsExaminerComments?: Maybe<Scalars["String"]>;
  settingsHandlingRequirements?: Maybe<Scalars["String"]>;
  settingsHasBarriers?: Maybe<Scalars["Boolean"]>;
  settingsInjuryRisk?: Maybe<Scalars["String"]>;
  settingsLenderRequests?: Maybe<Scalars["String"]>;
  settingsNotes?: Maybe<Scalars["String"]>;
  signatureFile?: Maybe<Scalars["String"]>;
  signedBy?: Maybe<UserType>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  valueEstimatedValue?: Maybe<Money>;
  valueEstimatedValueCurrency: ManagementConditionStatusReportValueEstimatedValueCurrencyChoices;
  valueEstimatedValueExaminationTime?: Maybe<Money>;
  valueEstimatedValueExaminationTimeCurrency: ManagementConditionStatusReportValueEstimatedValueExaminationTimeCurrencyChoices;
  valuePercentageDepreciation?: Maybe<Scalars["Float"]>;
};

export type ConditionStatusReportNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ConditionStatusReportNodeConnection = {
  __typename?: "ConditionStatusReportNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConditionStatusReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `ConditionStatusReportNode` and its cursor. */
export type ConditionStatusReportNodeEdge = {
  __typename?: "ConditionStatusReportNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ConditionStatusReportNode>;
};

export type ContentTypeNode = Node & {
  __typename?: "ContentTypeNode";
  appLabel: Scalars["String"];
  exchangeratesSet: ExchangeRatesNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  model: Scalars["String"];
  permissionSet: PermissionNodeConnection;
  useractionsSet: UserActionsNodeConnection;
};

export type ContentTypeNodeExchangeratesSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ContentTypeNodePermissionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ContentTypeNodeUseractionsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ContentTypeNodeConnection = {
  __typename?: "ContentTypeNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContentTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContentTypeNode` and its cursor. */
export type ContentTypeNodeEdge = {
  __typename?: "ContentTypeNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ContentTypeNode>;
};

export type Country = {
  __typename?: "Country";
  /** ISO 3166-1 three character country code */
  alpha3?: Maybe<Scalars["String"]>;
  /** ISO 3166-1 two character country code */
  code?: Maybe<Scalars["String"]>;
  /** International Olympic Committee country code */
  iocCode?: Maybe<Scalars["String"]>;
  /** Country name */
  name?: Maybe<Scalars["String"]>;
  /** ISO 3166-1 numeric country code */
  numeric?: Maybe<Scalars["Int"]>;
};

export type CountryNode = Node & {
  __typename?: "CountryNode";
  addresses: AddressNodeConnection;
  code: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  states: StateNodeConnection;
};

export type CountryNodeAddressesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  formatted?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  locality?: InputMaybe<Scalars["ID"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  raw?: InputMaybe<Scalars["String"]>;
  route?: InputMaybe<Scalars["String"]>;
  streetNumber?: InputMaybe<Scalars["String"]>;
};

export type CountryNodeStatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CreateAppraisalInput = {
  appraisalData?: InputMaybe<AppraisalInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAppraisalPayload = {
  __typename?: "CreateAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetDocumentAttachmentInput = {
  assetDocumentAttachmentData?: InputMaybe<AssetDocumentAttachmentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetDocumentAttachmentPayload = {
  __typename?: "CreateAssetDocumentAttachmentPayload";
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetDocumentInput = {
  assetDocumentData?: InputMaybe<AssetDocumentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetDocumentPayload = {
  __typename?: "CreateAssetDocumentPayload";
  assetDocument?: Maybe<AssetDocumentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetElementInput = {
  assetElementData?: InputMaybe<AssetElementInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetElementPayload = {
  __typename?: "CreateAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetInput = {
  assetData?: InputMaybe<AssetInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetPayload = {
  __typename?: "CreateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetPresentValueInput = {
  assetPresentValueData?: InputMaybe<AssetPresentValueInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetPresentValuePayload = {
  __typename?: "CreateAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateCertificateInput = {
  certificateData?: InputMaybe<CertificateInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateCertificatePayload = {
  __typename?: "CreateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateCertificateTemplateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
};

export type CreateCertificateTemplatePayload = {
  __typename?: "CreateCertificateTemplatePayload";
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CreateCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionData?: InputMaybe<CollectionInput>;
};

export type CreateCollectionPayload = {
  __typename?: "CreateCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type CreateCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyContactData?: InputMaybe<CompanyContactInput>;
};

export type CreateCompanyContactPayload = {
  __typename?: "CreateCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactNode>;
};

export type CreateConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportData?: InputMaybe<ConditionStatusReportInput>;
};

export type CreateConditionStatusReportPayload = {
  __typename?: "CreateConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type CreateDeliveryDetailsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  deliveryDetailsData?: InputMaybe<DeliveryDetailsInput>;
};

export type CreateDeliveryDetailsPayload = {
  __typename?: "CreateDeliveryDetailsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deliveryDetails?: Maybe<DeliveryDetailsNode>;
};

export type CreateDepositationAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateDepositationAssetPayload = {
  __typename?: "CreateDepositationAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type CreateDepositationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  depositationData: DepositationInput;
};

export type CreateDepositationPayload = {
  __typename?: "CreateDepositationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type CreateEventLogInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventLogData?: InputMaybe<EventLogInput>;
};

export type CreateEventLogPayload = {
  __typename?: "CreateEventLogPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventLog?: Maybe<EventLogNode>;
};

export type CreateExchangeRatesInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exchangeRatesInput: ExchangeRatesInput;
};

export type CreateExchangeRatesPayload = {
  __typename?: "CreateExchangeRatesPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exchangeRates?: Maybe<ExchangeRatesNode>;
};

export type CreateExhibitionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionData?: InputMaybe<ExhibitionInput>;
};

export type CreateExhibitionPayload = {
  __typename?: "CreateExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type CreateExitInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateExitPayload = {
  __typename?: "CreateExitPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exit?: Maybe<ExitNode>;
};

export type CreateInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  insuranceData?: InputMaybe<InsuranceInput>;
};

export type CreateInsurancePayload = {
  __typename?: "CreateInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type CreateMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  mediaData?: InputMaybe<MediaInput>;
};

export type CreateMediaPayload = {
  __typename?: "CreateMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type CreateOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  officeData?: InputMaybe<OfficeInput>;
};

export type CreateOfficePayload = {
  __typename?: "CreateOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeNode>;
};

export type CreatePickupDetailsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  pickupDetailsData?: InputMaybe<PickupDetailsInput>;
};

export type CreatePickupDetailsPayload = {
  __typename?: "CreatePickupDetailsPayload";
  PickupDetails?: Maybe<PickupDetailsNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreatePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policyAssetData?: InputMaybe<PolicyAssetInput>;
};

export type CreatePolicyAssetPayload = {
  __typename?: "CreatePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type CreatePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  policyId: Scalars["ID"];
};

export type CreatePolicyDocumentPayload = {
  __typename?: "CreatePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CreatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyData?: InputMaybe<PolicyInput>;
};

export type CreatePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyNoteData?: InputMaybe<PolicyNoteInput>;
};

export type CreatePolicyNotePayload = {
  __typename?: "CreatePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type CreatePolicyPayload = {
  __typename?: "CreatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type CreateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  registryData?: InputMaybe<RegistryInput>;
};

export type CreateRegistryPayload = {
  __typename?: "CreateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type CreateRegistryWithCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyContactData?: InputMaybe<CompanyContactInput>;
  registryData?: InputMaybe<RegistryInput>;
};

export type CreateRegistryWithCompanyContactPayload = {
  __typename?: "CreateRegistryWithCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactNode>;
  registry?: Maybe<RegistryNode>;
};

export type CreateRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  restorationData?: InputMaybe<RestorationInput>;
};

export type CreateRestorationPayload = {
  __typename?: "CreateRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type CreateTransportationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  transportationData?: InputMaybe<TransportationInput>;
};

export type CreateTransportationPayload = {
  __typename?: "CreateTransportationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  userData?: InputMaybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type CsvImportAssetNode = {
  __typename?: "CsvImportAssetNode";
  asset?: Maybe<AssetNode>;
  author?: Maybe<RegistryAssetCsvImportNode>;
  lender?: Maybe<RegistryAssetCsvImportNode>;
  parsedRowIndex?: Maybe<Scalars["String"]>;
  shipper?: Maybe<RegistryAssetCsvImportNode>;
};

export type CsvImportCreateAssetInput = {
  assetData?: InputMaybe<Array<InputMaybe<AssetInputCsvImport>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CsvImportCreateAssetPayload = {
  __typename?: "CsvImportCreateAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  importCsvResponse?: Maybe<Array<Maybe<CsvImportAssetNode>>>;
};

export type Currency = {
  __typename?: "Currency";
  /** A ISO-421 3-letter currency. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  code: Scalars["String"];
  /** A human readable name, e.g. US Dollar */
  name?: Maybe<Scalars["String"]>;
  /** A ISO-421 numeric code. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  numeric?: Maybe<Scalars["String"]>;
  /** The currency's prefix, e.g. $ for USD */
  prefix?: Maybe<Scalars["String"]>;
  /** The currency's symbol, e.g. € for EUR */
  suffix?: Maybe<Scalars["String"]>;
  /** The currency's symbol, e.g. $ for USD */
  symbol?: Maybe<Scalars["String"]>;
};

export type DeleteAppraisalInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAppraisalMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteAppraisalMediaPayload = {
  __typename?: "DeleteAppraisalMediaPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAppraisalPayload = {
  __typename?: "DeleteAppraisalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteAppraisal?: Maybe<AppraisalNode>;
};

export type DeleteAssetCarryingValueFileInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteAssetCarryingValueFilePayload = {
  __typename?: "DeleteAssetCarryingValueFilePayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetDocumentAttachmentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAssetDocumentAttachmentPayload = {
  __typename?: "DeleteAssetDocumentAttachmentPayload";
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetDocumentPayload = {
  __typename?: "DeleteAssetDocumentPayload";
  assetDocument?: Maybe<AssetDocumentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetElementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetElementPayload = {
  __typename?: "DeleteAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAssetMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteAssetMediaPayload = {
  __typename?: "DeleteAssetMediaPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetPayload = {
  __typename?: "DeleteAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetPresentValueInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetPresentValuePayload = {
  __typename?: "DeleteAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCertificateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteCertificatePayload = {
  __typename?: "DeleteCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCertificateTemplateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteCertificateTemplatePayload = {
  __typename?: "DeleteCertificateTemplatePayload";
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCollectionImageInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteCollectionImagePayload = {
  __typename?: "DeleteCollectionImagePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DeleteCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteCollectionMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteCollectionMediaPayload = {
  __typename?: "DeleteCollectionMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DeleteCollectionPayload = {
  __typename?: "DeleteCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DeleteCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteCompanyContactPayload = {
  __typename?: "DeleteCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactNode>;
};

export type DeleteConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteConditionStatusReportMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteConditionStatusReportMediaPayload = {
  __typename?: "DeleteConditionStatusReportMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type DeleteConditionStatusReportPayload = {
  __typename?: "DeleteConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteConditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type DeleteDeliveryDetailsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteDeliveryDetailsPayload = {
  __typename?: "DeleteDeliveryDetailsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deliveryDetails?: Maybe<DeliveryDetailsNode>;
};

export type DeleteDepositationAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  depositationAssetId: Scalars["ID"];
  id: Scalars["ID"];
};

export type DeleteDepositationAssetPayload = {
  __typename?: "DeleteDepositationAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type DeleteDepositationBillInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteDepositationBillPayload = {
  __typename?: "DeleteDepositationBillPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositationBill?: Maybe<ExitNode>;
};

export type DeleteDepositationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteDepositationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteDepositationMediaPayload = {
  __typename?: "DeleteDepositationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type DeleteDepositationPayload = {
  __typename?: "DeleteDepositationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type DeleteEventLogInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteEventLogPayload = {
  __typename?: "DeleteEventLogPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteEventLog?: Maybe<EventLogNode>;
};

export type DeleteExchangeRatesInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteExchangeRatesPayload = {
  __typename?: "DeleteExchangeRatesPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exchangeRates?: Maybe<ExchangeRatesNode>;
};

export type DeleteExhibitionImageInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteExhibitionImagePayload = {
  __typename?: "DeleteExhibitionImagePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type DeleteExhibitionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteExhibitionMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteExhibitionMediaPayload = {
  __typename?: "DeleteExhibitionMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type DeleteExhibitionPayload = {
  __typename?: "DeleteExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type DeleteExitInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteExitPayload = {
  __typename?: "DeleteExitPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exit?: Maybe<ExitNode>;
};

export type DeleteInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteInsurancePayload = {
  __typename?: "DeleteInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type DeleteMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteMediaPayload = {
  __typename?: "DeleteMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type DeleteOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteOfficePayload = {
  __typename?: "DeleteOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeNode>;
};

export type DeletePickupDetailsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePickupDetailsPayload = {
  __typename?: "DeletePickupDetailsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  pickupDetails?: Maybe<PickupDetailsNode>;
};

export type DeletePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
};

export type DeletePolicyAssetPayload = {
  __typename?: "DeletePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type DeletePolicyAssetsGroupedInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policyassetsIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type DeletePolicyAssetsGroupedPayload = {
  __typename?: "DeletePolicyAssetsGroupedPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type DeletePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeletePolicyDocumentPayload = {
  __typename?: "DeletePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
};

export type DeletePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyNotePayload = {
  __typename?: "DeletePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type DeletePolicyPayload = {
  __typename?: "DeletePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type DeleteRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteRegistryLogoInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteRegistryLogoPayload = {
  __typename?: "DeleteRegistryLogoPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type DeleteRegistryPayload = {
  __typename?: "DeleteRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type DeleteRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteRestorationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteRestorationMediaPayload = {
  __typename?: "DeleteRestorationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type DeleteRestorationPayload = {
  __typename?: "DeleteRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleteRestoration?: Maybe<RestorationNode>;
};

export type DeleteTransportationBillInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteTransportationBillPayload = {
  __typename?: "DeleteTransportationBillPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportationBill?: Maybe<ExportDocumentsNode>;
};

export type DeleteTransportationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteTransportationMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaId: Scalars["ID"];
};

export type DeleteTransportationMediaPayload = {
  __typename?: "DeleteTransportationMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type DeleteTransportationPayload = {
  __typename?: "DeleteTransportationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type DeleteUserSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteUserSignaturePayload = {
  __typename?: "DeleteUserSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSignature?: Maybe<UserSignatureNode>;
};

export type DeliveryDetailsInput = {
  cellularNumber?: InputMaybe<Scalars["String"]>;
  deliveryDatetime?: InputMaybe<Scalars["String"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
};

export type DeliveryDetailsNode = Node & {
  __typename?: "DeliveryDetailsNode";
  cellularNumber?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deliveryDatetime?: Maybe<Scalars["DateTime"]>;
  deliveryLocation?: Maybe<OfficeNode>;
  deliveryType?: Maybe<SlogDeliveryDetailsDeliveryTypeChoices>;
  email?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  recipient?: Maybe<RegistryNode>;
  telephoneNumber?: Maybe<Scalars["String"]>;
  transportationSet: TransportationNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type DeliveryDetailsNodeTransportationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type DeliveryDetailsNodeConnection = {
  __typename?: "DeliveryDetailsNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeliveryDetailsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DeliveryDetailsNode` and its cursor. */
export type DeliveryDetailsNodeEdge = {
  __typename?: "DeliveryDetailsNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<DeliveryDetailsNode>;
};

export type DepositationAssetInput = {
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  driver?: InputMaybe<Scalars["String"]>;
  exit?: InputMaybe<ExitInput>;
  height?: InputMaybe<Scalars["Float"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type DepositationAssetNode = Node & {
  __typename?: "DepositationAssetNode";
  appointee?: Maybe<RegistryNode>;
  asset: AssetNode;
  container?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  depositation?: Maybe<DepositationNode>;
  depth?: Maybe<Scalars["Float"]>;
  diameter?: Maybe<Scalars["Float"]>;
  endDate?: Maybe<Scalars["String"]>;
  exit?: Maybe<ExitNode>;
  exitAppointee?: Maybe<Scalars["String"]>;
  externalDepositationId?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Float"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  linearUnitOfMeasure?: Maybe<Scalars["String"]>;
  packageDimensionsInfo?: Maybe<Scalars["String"]>;
  packageWeight?: Maybe<Scalars["String"]>;
  packageWeightInfo?: Maybe<Scalars["String"]>;
  packagingType?: Maybe<Scalars["String"]>;
  parentType?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  weightUnitOfMeasure?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type DepositationAssetNodeConnection = {
  __typename?: "DepositationAssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DepositationAssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `DepositationAssetNode` and its cursor. */
export type DepositationAssetNodeEdge = {
  __typename?: "DepositationAssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<DepositationAssetNode>;
};

export type DepositationInput = {
  companyId?: InputMaybe<Scalars["String"]>;
  courierId?: InputMaybe<Scalars["String"]>;
  customerId?: InputMaybe<Scalars["String"]>;
  depositationAssetId?: InputMaybe<Scalars["ID"]>;
  depositationAssets?: InputMaybe<Array<InputMaybe<DepositationAssetInput>>>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  locationId?: InputMaybe<Scalars["String"]>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type DepositationNode = Node & {
  __typename?: "DepositationNode";
  areaDescription?: Maybe<Scalars["String"]>;
  areaNotes?: Maybe<Scalars["String"]>;
  areaType?: Maybe<SlogDepositationAreaTypeChoices>;
  areaTypeDescription?: Maybe<SlogDepositationAreaTypeDescriptionChoices>;
  broker?: Maybe<RegistryNode>;
  company?: Maybe<RegistryNode>;
  contractingParty?: Maybe<RegistryNode>;
  courier?: Maybe<RegistryNode>;
  coverType?: Maybe<SlogDepositationCoverTypeChoices>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  customer?: Maybe<RegistryNode>;
  depositationId?: Maybe<Scalars["String"]>;
  depositationassetsSet: DepositationAssetNodeConnection;
  description?: Maybe<Scalars["String"]>;
  driver?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  evaluationType?: Maybe<SlogDepositationEvaluationTypeChoices>;
  exportedDocument?: Maybe<ExportDocumentsNode>;
  filingNumber: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  info?: Maybe<Scalars["String"]>;
  instructions?: Maybe<Scalars["String"]>;
  insuranceCompany?: Maybe<RegistryNode>;
  insuranceNotes?: Maybe<Scalars["String"]>;
  insuranceStatus: SlogDepositationInsuranceStatusChoices;
  insuredFrom?: Maybe<Scalars["DateTime"]>;
  insuredTo?: Maybe<Scalars["DateTime"]>;
  isConfirmed: Scalars["Boolean"];
  isModifiable?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<OfficeNode>;
  media: MediaNodeConnection;
  methodAndAreaOfStorage?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  numberOfAssets?: Maybe<Scalars["Int"]>;
  numberOfMedia?: Maybe<Scalars["Int"]>;
  numberOfPackages?: Maybe<Scalars["Int"]>;
  riskType?: Maybe<SlogDepositationRiskTypeChoices>;
  searchString: Scalars["String"];
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<SlogDepositationStatusChoices>;
  transportationRequiredPersonnel?: Maybe<Scalars["String"]>;
  type?: Maybe<SlogDepositationTypeChoices>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type DepositationNodeDepositationassetsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type DepositationNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type DepositationNodeConnection = {
  __typename?: "DepositationNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DepositationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `DepositationNode` and its cursor. */
export type DepositationNodeEdge = {
  __typename?: "DepositationNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<DepositationNode>;
};

export type DetachAssetFromAppraisalInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromAppraisalPayload = {
  __typename?: "DetachAssetFromAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DetachAssetFromCollectionInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromCollectionPayload = {
  __typename?: "DetachAssetFromCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DetachAssetFromConditionStatusReportInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromConditionStatusReportPayload = {
  __typename?: "DetachAssetFromConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type DetachAssetFromExhibitionInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromExhibitionPayload = {
  __typename?: "DetachAssetFromExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type DetachAssetFromRestorationInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetFromRestorationPayload = {
  __typename?: "DetachAssetFromRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type DetachAssetsFromCollectionInput = {
  assetIds: Array<InputMaybe<Scalars["ID"]>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DetachAssetsFromCollectionPayload = {
  __typename?: "DetachAssetsFromCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type DiffChangesNode = {
  __typename?: "DiffChangesNode";
  changeReason?: Maybe<Scalars["String"]>;
  changedAt?: Maybe<Scalars["DateTime"]>;
  field?: Maybe<Scalars["String"]>;
  historyId?: Maybe<Scalars["String"]>;
  historyUser?: Maybe<UserNode>;
  new?: Maybe<Scalars["String"]>;
  old?: Maybe<Scalars["String"]>;
};

export type Distribution = {
  __typename?: "Distribution";
  category?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  percentage?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type DuplicateAppraisalInput = {
  appraisalId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DuplicateAppraisalPayload = {
  __typename?: "DuplicateAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DuplicateAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DuplicateAssetPayload = {
  __typename?: "DuplicateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DuplicateConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  crsrId?: InputMaybe<Scalars["ID"]>;
};

export type DuplicateConditionStatusReportPayload = {
  __typename?: "DuplicateConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  crsr?: Maybe<ConditionStatusReportNode>;
};

export type DuplicateDepositationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DuplicateDepositationPayload = {
  __typename?: "DuplicateDepositationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type DuplicatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type DuplicatePolicyPayload = {
  __typename?: "DuplicatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type DuplicateRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  restorationId?: InputMaybe<Scalars["ID"]>;
};

export type DuplicateRestorationPayload = {
  __typename?: "DuplicateRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type DuplicateTransportationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DuplicateTransportationPayload = {
  __typename?: "DuplicateTransportationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type EventLogInput = {
  acquisitionType?: InputMaybe<Scalars["String"]>;
  actionType?: InputMaybe<Scalars["String"]>;
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  auctionDescription?: InputMaybe<Scalars["String"]>;
  auctionRights?: InputMaybe<MoneyInput>;
  broker?: InputMaybe<Scalars["ID"]>;
  collectionPublicName?: InputMaybe<Scalars["String"]>;
  commerciallyAvailable?: InputMaybe<Scalars["Boolean"]>;
  commission?: InputMaybe<Scalars["String"]>;
  document?: InputMaybe<Scalars["Upload"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  eventType: Scalars["String"];
  externalEntity: Scalars["String"];
  externalId?: InputMaybe<Scalars["Int"]>;
  hideOwner?: InputMaybe<Scalars["Boolean"]>;
  host?: InputMaybe<Scalars["ID"]>;
  locationDescription?: InputMaybe<Scalars["String"]>;
  lotNumber?: InputMaybe<Scalars["String"]>;
  maximumPrice?: InputMaybe<MoneyInput>;
  minimumPrice?: InputMaybe<MoneyInput>;
  newAddress?: InputMaybe<AddressInput>;
  newCollectionPublicName?: InputMaybe<Scalars["String"]>;
  newOwner?: InputMaybe<Scalars["ID"]>;
  note?: InputMaybe<Scalars["String"]>;
  oldAddress?: InputMaybe<AddressInput>;
  origin?: InputMaybe<Scalars["ID"]>;
  owner?: InputMaybe<Scalars["ID"]>;
  reason?: InputMaybe<Scalars["String"]>;
  reservePrice?: InputMaybe<MoneyInput>;
  sellingLocation?: InputMaybe<Scalars["String"]>;
  sold?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  value?: InputMaybe<MoneyInput>;
};

export type EventLogNode = Node & {
  __typename?: "EventLogNode";
  acquisitionType?: Maybe<Scalars["String"]>;
  actionType?: Maybe<Scalars["String"]>;
  actualCity?: Maybe<Scalars["String"]>;
  actualLocation?: Maybe<Scalars["String"]>;
  assetSet: AssetNodeConnection;
  auctionDescription?: Maybe<Scalars["String"]>;
  auctionRights?: Maybe<Money>;
  auctionRightsCurrency: BaseEventLogAuctionRightsCurrencyChoices;
  broker?: Maybe<RegistryNode>;
  collection?: Maybe<CollectionNode>;
  collectionPublicName?: Maybe<Scalars["String"]>;
  commerciallyAvailable?: Maybe<Scalars["Boolean"]>;
  commission?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  document?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  entityCreatedAt?: Maybe<Scalars["DateTime"]>;
  entityDocumentsNumber: Scalars["Int"];
  eventName?: Maybe<Scalars["String"]>;
  eventType?: Maybe<Scalars["String"]>;
  exportedDocument?: Maybe<ExportDocumentsNode>;
  externalEntity: BaseEventLogExternalEntityChoices;
  externalEntityId?: Maybe<Scalars["ID"]>;
  externalId?: Maybe<Scalars["Int"]>;
  hideOwner?: Maybe<Scalars["Boolean"]>;
  host?: Maybe<RegistryNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  locationDescription?: Maybe<Scalars["String"]>;
  lotNumber?: Maybe<Scalars["String"]>;
  maximumPrice?: Maybe<Money>;
  maximumPriceCurrency: BaseEventLogMaximumPriceCurrencyChoices;
  minimumPrice?: Maybe<Money>;
  minimumPriceCurrency: BaseEventLogMinimumPriceCurrencyChoices;
  newAddress?: Maybe<Address>;
  newCollectionPublicName?: Maybe<Scalars["String"]>;
  newOwner?: Maybe<RegistryNode>;
  note?: Maybe<Scalars["String"]>;
  numberOfDocuments?: Maybe<Scalars["Int"]>;
  oldAddress?: Maybe<Address>;
  origin?: Maybe<RegistryNode>;
  owner?: Maybe<RegistryNode>;
  policy?: Maybe<PolicyNode>;
  reason?: Maybe<Scalars["String"]>;
  reservePrice?: Maybe<Money>;
  reservePriceCurrency: BaseEventLogReservePriceCurrencyChoices;
  sellingLocation?: Maybe<Scalars["String"]>;
  sold?: Maybe<Scalars["Boolean"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  translatedEventType?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  value?: Maybe<Money>;
  valueCurrency: BaseEventLogValueCurrencyChoices;
};

export type EventLogNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type EventLogNodeConnection = {
  __typename?: "EventLogNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EventLogNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EventLogNode` and its cursor. */
export type EventLogNodeEdge = {
  __typename?: "EventLogNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<EventLogNode>;
};

export type ExchangeRatesInput = {
  appLabel: Scalars["String"];
  baseCurrency: Scalars["String"];
  contentObject: Scalars["ID"];
  contentType: Scalars["String"];
  currency: Scalars["String"];
  value?: InputMaybe<Scalars["Float"]>;
};

export type ExchangeRatesNode = Node & {
  __typename?: "ExchangeRatesNode";
  baseCurrency: Scalars["String"];
  contentType?: Maybe<ContentTypeNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  currency: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  objectId?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  value: Scalars["Decimal"];
};

export type ExchangeRatesNodeConnection = {
  __typename?: "ExchangeRatesNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExchangeRatesNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExchangeRatesNode` and its cursor. */
export type ExchangeRatesNodeEdge = {
  __typename?: "ExchangeRatesNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ExchangeRatesNode>;
};

export type ExchangeRatesPartialInput = {
  baseCurrency: Scalars["String"];
  currency: Scalars["String"];
  value?: InputMaybe<Scalars["Float"]>;
};

export type ExhibitionInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  curator?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  eventFrom?: InputMaybe<Scalars["DateTime"]>;
  eventLocation?: InputMaybe<Scalars["ID"]>;
  eventLocationEntity?: InputMaybe<Scalars["ID"]>;
  eventTo?: InputMaybe<Scalars["DateTime"]>;
  hostEntity: Scalars["ID"];
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  organizer?: InputMaybe<Scalars["ID"]>;
  permanent?: InputMaybe<Scalars["Boolean"]>;
};

export type ExhibitionNode = Node & {
  __typename?: "ExhibitionNode";
  actualTimingStatus?: Maybe<Scalars["String"]>;
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  createdFrom?: Maybe<Scalars["String"]>;
  curator?: Maybe<RegistryNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventLocation?: Maybe<Address>;
  eventLocationEntity?: Maybe<OfficeNode>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  exportedDocument?: Maybe<ExportDocumentsNode>;
  hostEntity?: Maybe<RegistryNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  media: MediaNodeConnection;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  organizer?: Maybe<RegistryNode>;
  permanent?: Maybe<Scalars["Boolean"]>;
  policy?: Maybe<PolicyNode>;
  totalAssetsNumber?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type ExhibitionNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExhibitionNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExhibitionNodeConnection = {
  __typename?: "ExhibitionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExhibitionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `ExhibitionNode` and its cursor. */
export type ExhibitionNodeEdge = {
  __typename?: "ExhibitionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ExhibitionNode>;
};

export type ExitInput = {
  endDate?: InputMaybe<Scalars["String"]>;
  exitAppointee?: InputMaybe<Scalars["ID"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
};

export type ExitNode = Node & {
  __typename?: "ExitNode";
  appointee?: Maybe<RegistryNode>;
  company?: Maybe<RegistryNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  depositationData?: Maybe<Scalars["String"]>;
  depositationNumber?: Maybe<Scalars["String"]>;
  depositationassetsSet: DepositationAssetNodeConnection;
  endDate?: Maybe<Scalars["DateTime"]>;
  exitDriver?: Maybe<Scalars["String"]>;
  filingNumber?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  transportationRequiredPersonnel?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type ExitNodeDepositationassetsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type ExitNodeConnection = {
  __typename?: "ExitNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExitNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExitNode` and its cursor. */
export type ExitNodeEdge = {
  __typename?: "ExitNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ExitNode>;
};

export type ExportAppraisalPdfInput = {
  appraisalId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  forceRegeneration?: InputMaybe<Scalars["Boolean"]>;
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportAppraisalPdfPayload = {
  __typename?: "ExportAppraisalPDFPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportAssetPdfInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  forceRegeneration?: InputMaybe<Scalars["Boolean"]>;
  lang?: InputMaybe<Scalars["String"]>;
  reduced?: InputMaybe<Scalars["Boolean"]>;
};

export type ExportAssetPdfPayload = {
  __typename?: "ExportAssetPDFPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportCollectionPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionId: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportCollectionPdfPayload = {
  __typename?: "ExportCollectionPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportConditionStatusReportPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportId: Scalars["ID"];
  forceRegeneration?: InputMaybe<Scalars["Boolean"]>;
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportConditionStatusReportPdfPayload = {
  __typename?: "ExportConditionStatusReportPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
  lang?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationAssetsLabelsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  depositationAssetId: Array<InputMaybe<Scalars["ID"]>>;
  id: Scalars["ID"];
  lang: Scalars["String"];
};

export type ExportDepositationAssetsLabelsPayload = {
  __typename?: "ExportDepositationAssetsLabelsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationAssetsPackingListInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  depositationAssetId: Array<InputMaybe<Scalars["ID"]>>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportDepositationAssetsPackingListPayload = {
  __typename?: "ExportDepositationAssetsPackingListPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationInboundBillsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportDepositationInboundBillsPayload = {
  __typename?: "ExportDepositationInboundBillsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationLabelsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang: Scalars["String"];
};

export type ExportDepositationLabelsPayload = {
  __typename?: "ExportDepositationLabelsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationOutboundBillsInput = {
  assetsIds: Array<InputMaybe<Scalars["ID"]>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportDepositationOutboundBillsPayload = {
  __typename?: "ExportDepositationOutboundBillsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationPackingListInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportDepositationPackingListPayload = {
  __typename?: "ExportDepositationPackingListPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDepositationPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  forceRegeneration?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  lang: Scalars["String"];
};

export type ExportDepositationPdfPayload = {
  __typename?: "ExportDepositationPdfPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportDocumentsNode = Node & {
  __typename?: "ExportDocumentsNode";
  appraisalSet: AppraisalNodeConnection;
  assetSet: AssetNodeConnection;
  collectionSet: CollectionNodeConnection;
  conditionstatusreportSet: ConditionStatusReportNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  depositation?: Maybe<DepositationNode>;
  depositationSet: DepositationNodeConnection;
  eventlogSet: EventLogNodeConnection;
  exhibitionSet: ExhibitionNodeConnection;
  externalId?: Maybe<Scalars["Int"]>;
  file: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  isSigned?: Maybe<Scalars["Boolean"]>;
  modelName?: Maybe<Scalars["String"]>;
  modelUpdatedAt?: Maybe<Scalars["DateTime"]>;
  restorationSet: RestorationNodeConnection;
  transportation?: Maybe<TransportationNode>;
  transportationSet: TransportationNodeConnection;
  type: BaseExportedDocumentsTypeChoices;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type ExportDocumentsNodeAppraisalSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeCollectionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeConditionstatusreportSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeDepositationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type ExportDocumentsNodeEventlogSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeExhibitionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeRestorationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExportDocumentsNodeTransportationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type ExportDocumentsNodeConnection = {
  __typename?: "ExportDocumentsNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExportDocumentsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `ExportDocumentsNode` and its cursor. */
export type ExportDocumentsNodeEdge = {
  __typename?: "ExportDocumentsNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ExportDocumentsNode>;
};

export type ExportExhibitionPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionId: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportExhibitionPdfPayload = {
  __typename?: "ExportExhibitionPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportPolicyPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type ExportPolicyPdfPayload = {
  __typename?: "ExportPolicyPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ExportRestorationPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  forceRegeneration?: InputMaybe<Scalars["Boolean"]>;
  lang?: InputMaybe<Scalars["String"]>;
  restorationId: Scalars["ID"];
};

export type ExportRestorationPdfPayload = {
  __typename?: "ExportRestorationPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportTransportationAssetLabelInput = {
  assetIds: Array<InputMaybe<Scalars["ID"]>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang: Scalars["String"];
};

export type ExportTransportationAssetLabelPayload = {
  __typename?: "ExportTransportationAssetLabelPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportTransportationAssetPackingListInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang: Scalars["String"];
  transportationAssetId: Scalars["ID"];
};

export type ExportTransportationAssetPackingListPayload = {
  __typename?: "ExportTransportationAssetPackingListPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportTransportationBillsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportTransportationBillsPayload = {
  __typename?: "ExportTransportationBillsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportTransportationLabelsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang: Scalars["String"];
};

export type ExportTransportationLabelsPayload = {
  __typename?: "ExportTransportationLabelsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportTransportationPdfInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  forceRegeneration?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportTransportationPdfPayload = {
  __typename?: "ExportTransportationPDFPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExportTransportationPackingListInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lang?: InputMaybe<Scalars["String"]>;
};

export type ExportTransportationPackingListPayload = {
  __typename?: "ExportTransportationPackingListPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  url?: Maybe<Scalars["String"]>;
};

export type ExtendPolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
  totalInsuredPremium?: InputMaybe<MoneyInput>;
};

export type ExtendPolicyPayload = {
  __typename?: "ExtendPolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type FilteredInsuranceNode = Node & {
  __typename?: "FilteredInsuranceNode";
  catNat?: Maybe<Scalars["Boolean"]>;
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  /** The ID of the object */
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type FilteredInsuranceNodeConnection = {
  __typename?: "FilteredInsuranceNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FilteredInsuranceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FilteredInsuranceNode` and its cursor. */
export type FilteredInsuranceNodeEdge = {
  __typename?: "FilteredInsuranceNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<FilteredInsuranceNode>;
};

export type GenerateCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emissionDate?: InputMaybe<Scalars["Date"]>;
  hasImages?: InputMaybe<Scalars["Boolean"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  metafields?: InputMaybe<Scalars["GenericScalar"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type GenerateCertificatePayload = {
  __typename?: "GenerateCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

export type GenerateGroupedCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  customProgressiveNumber?: InputMaybe<Scalars["String"]>;
  hasImages?: InputMaybe<Scalars["Boolean"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  metafields?: InputMaybe<Scalars["GenericScalar"]>;
  notes?: InputMaybe<Scalars["String"]>;
  policyAssetsId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type GenerateGroupedCertificatePayload = {
  __typename?: "GenerateGroupedCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

export type GenerateSplitCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  hasImages?: InputMaybe<Scalars["Boolean"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  metafields?: InputMaybe<Scalars["GenericScalar"]>;
  notes?: InputMaybe<Scalars["String"]>;
  policyAssetsId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type GenerateSplitCertificatePayload = {
  __typename?: "GenerateSplitCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

/**  Auto generated Type for a model’s GenericForeignKey field  */
export type GenericForeignKeyType = {
  __typename?: "GenericForeignKeyType";
  appLabel?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  modelName?: Maybe<Scalars["String"]>;
};

export type GetAssetPredictionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  jobUuid: Scalars["String"];
};

export type GetAssetPredictionPayload = {
  __typename?: "GetAssetPredictionPayload";
  assetPrediction?: Maybe<AssetPredictionNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type GroupedPolicyAssetInput = {
  asset?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  deliveryAddress?: InputMaybe<AddressInput>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  insuredValue?: InputMaybe<MoneyInput>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  lenderAddress?: InputMaybe<AddressInput>;
  lenderId?: InputMaybe<Scalars["ID"]>;
  objectLocationEntityId?: InputMaybe<Scalars["ID"]>;
  objectLocationOfficeId?: InputMaybe<Scalars["ID"]>;
  pickupAddress?: InputMaybe<AddressInput>;
  policy?: InputMaybe<Scalars["ID"]>;
  shipperId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type GroupsNode = {
  __typename?: "GroupsNode";
  groups?: Maybe<Array<Maybe<Array<Maybe<PolicyAssetNode>>>>>;
  length?: Maybe<Scalars["Int"]>;
};

export type HardDeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type HardDeleteUserPayload = {
  __typename?: "HardDeleteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type HistoryListItemNode = {
  __typename?: "HistoryListItemNode";
  autoRenewal?: Maybe<Scalars["Boolean"]>;
  catNat?: Maybe<Scalars["Boolean"]>;
  coverTerrorism?: Maybe<Scalars["Boolean"]>;
  coverType?: Maybe<Scalars["String"]>;
  coveredRiskType?: Maybe<Scalars["String"]>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventName?: Maybe<Scalars["String"]>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  exemption?: Maybe<Scalars["Boolean"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  hasValidCertificate?: Maybe<Scalars["Boolean"]>;
  hasVariableBasket?: Maybe<Scalars["Boolean"]>;
  historyChangeReason?: Maybe<Scalars["String"]>;
  historyDate?: Maybe<Scalars["DateTime"]>;
  historyId?: Maybe<Scalars["ID"]>;
  historyType?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  issuedAt?: Maybe<Scalars["DateTime"]>;
  offerCode?: Maybe<Scalars["String"]>;
  policyCode?: Maybe<Scalars["String"]>;
  policyStatus?: Maybe<Scalars["String"]>;
  policyType?: Maybe<Scalars["String"]>;
  totalInsuredPremium?: Maybe<Scalars["Float"]>;
  totalInsuredPremiumCurrency?: Maybe<Scalars["String"]>;
  totalInsuredValue?: Maybe<Scalars["Float"]>;
  totalInsuredValueCurrency?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
};

export type InsuranceInput = {
  catNat: Scalars["Boolean"];
  contractType: Scalars["String"];
  coverageType: Scalars["String"];
  currencyInsurancePremium: Scalars["String"];
  currencyInsuredValue: Scalars["String"];
  evaluationType: Scalars["String"];
  insurancePremium: Scalars["String"];
  insuranceReason: Scalars["String"];
  insuredValue: Scalars["String"];
  note: Scalars["String"];
  policyNumber: Scalars["String"];
  riskCoveredType: Scalars["String"];
  terrorism: Scalars["Boolean"];
};

export type InsuranceNode = Node & {
  __typename?: "InsuranceNode";
  catNat?: Maybe<Scalars["Boolean"]>;
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  /** The ID of the object */
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type InsuranceNodeConnection = {
  __typename?: "InsuranceNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InsuranceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InsuranceNode` and its cursor. */
export type InsuranceNodeEdge = {
  __typename?: "InsuranceNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<InsuranceNode>;
};

export type InsuranceType = {
  __typename?: "InsuranceType";
  catNat?: Maybe<Scalars["Boolean"]>;
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type InvalidateCertificateInput = {
  certificateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type InvalidateCertificatePayload = {
  __typename?: "InvalidateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type InviteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  expiresAt: Scalars["DateTime"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type InviteUserPayload = {
  __typename?: "InviteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type LinkRegistryWithAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkedRegistryAssetData?: InputMaybe<LinkedAssetRegistryInput>;
};

export type LinkRegistryWithAssetPayload = {
  __typename?: "LinkRegistryWithAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type LinkedAssetRegistryInput = {
  assetId: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
  registryId: Scalars["ID"];
};

export type LinkedAssetRegistryNode = Node & {
  __typename?: "LinkedAssetRegistryNode";
  asset?: Maybe<AssetNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type LinkedAssetRegistryNodeConnection = {
  __typename?: "LinkedAssetRegistryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LinkedAssetRegistryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LinkedAssetRegistryNode` and its cursor. */
export type LinkedAssetRegistryNodeEdge = {
  __typename?: "LinkedAssetRegistryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<LinkedAssetRegistryNode>;
};

export type LocalityNode = Node & {
  __typename?: "LocalityNode";
  addresses: AddressNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  postalCode: Scalars["String"];
  state?: Maybe<StateNode>;
};

export type LocalityNodeAddressesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  formatted?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  locality?: InputMaybe<Scalars["ID"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  raw?: InputMaybe<Scalars["String"]>;
  route?: InputMaybe<Scalars["String"]>;
  streetNumber?: InputMaybe<Scalars["String"]>;
};

export type LocalityNodeConnection = {
  __typename?: "LocalityNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LocalityNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LocalityNode` and its cursor. */
export type LocalityNodeEdge = {
  __typename?: "LocalityNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<LocalityNode>;
};

export enum ManagementAppraisalValueCarryingValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum ManagementAppraisalValueEstimatedValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum ManagementAssetDatingCenturyEndDateWindowChoices {
  /** Beginning */
  Begin = "BEGIN",
  /** End */
  End = "END",
  /** First half */
  FirstHalf = "FIRST_HALF",
  /** Half */
  Half = "HALF",
  /** Second half */
  SecondHalf = "SECOND_HALF",
}

export enum ManagementAssetDatingCenturyStartDateWindowChoices {
  /** Beginning */
  Begin = "BEGIN",
  /** End */
  End = "END",
  /** First half */
  FirstHalf = "FIRST_HALF",
  /** Half */
  Half = "HALF",
  /** Second half */
  SecondHalf = "SECOND_HALF",
}

export enum ManagementAssetDatingDatingTypeChoices {
  /** Century */
  Century = "CENTURY",
  /** Date */
  Date = "DATE",
  /** Period */
  Period = "PERIOD",
  /** Year */
  Year = "YEAR",
}

export enum ManagementAssetPredictionTypeChoices {
  /** Batch */
  Batch = "BATCH",
  /** Manual */
  Manual = "MANUAL",
}

export enum ManagementAssetPresentValueAmountCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum ManagementConditionStatusReportValueEstimatedValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum ManagementConditionStatusReportValueEstimatedValueExaminationTimeCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum ManagementInsuranceContractTypeChoices {
  /** Basket */
  Basket = "BASKET",
  /** Punctual */
  Punctual = "PUNCTUAL",
}

export enum ManagementInsuranceCoverageTypeChoices {
  /** Nail to nail */
  NailToNail = "NAIL_TO_NAIL",
  /** Stay Risk */
  StayRisk = "STAY_RISK",
  /** Travel risk */
  TravelRisk = "TRAVEL_RISK",
}

export enum ManagementInsuranceEvaluationTypeChoices {
  /** All */
  All = "ALL",
  /** Declared value */
  DeclaredValue = "DECLARED_VALUE",
  /** Agreed value */
  EstimateAccepted = "ESTIMATE_ACCEPTED",
}

export enum ManagementInsuranceInsuranceReasonChoices {
  /** Basket */
  Exhibition = "EXHIBITION",
  /** Punctual */
  ExhibitionTransport = "EXHIBITION_TRANSPORT",
  /** Loan */
  Loan = "LOAN",
  /** Punctual */
  Restauration = "RESTAURATION",
  /** Punctual */
  RestaurationTransport = "RESTAURATION_TRANSPORT",
  /** Punctual */
  StayInPlace = "STAY_IN_PLACE",
  /** Punctual */
  Transport = "TRANSPORT",
}

export enum ManagementInsuranceRiskCoveredTypeChoices {
  /** All risk */
  AllRisk = "ALL_RISK",
  /** First risk */
  FirstRisk = "FIRST_RISK",
  /** None */
  None = "NONE",
}

export enum ManagementRestorationRestorationCostCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export type MediaInput = {
  audioVideoFileType?: InputMaybe<Scalars["String"]>;
  createdFrom?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["Date"]>;
  description?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  fileAuthor?: InputMaybe<Scalars["String"]>;
  fileSize?: InputMaybe<Scalars["String"]>;
  imageType?: InputMaybe<Scalars["String"]>;
  isGlassPlexyAcetate?: InputMaybe<Scalars["Boolean"]>;
  isMainImage?: InputMaybe<Scalars["Boolean"]>;
  matrixType?: InputMaybe<Scalars["String"]>;
  mediaType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  photographicEquipment?: InputMaybe<Scalars["String"]>;
};

export type MediaNode = Node & {
  __typename?: "MediaNode";
  actualImageDimensions?: Maybe<Scalars["String"]>;
  appraisalSet: AppraisalNodeConnection;
  assetSet: AssetNodeConnection;
  audioVideoFileType?: Maybe<Scalars["String"]>;
  collectionSet: CollectionNodeConnection;
  conditionstatusreportSet: ConditionStatusReportNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  createdFrom?: Maybe<Scalars["String"]>;
  creationDate?: Maybe<Scalars["Date"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  depositationSet: DepositationNodeConnection;
  description?: Maybe<Scalars["String"]>;
  exhibitionSet: ExhibitionNodeConnection;
  file: Scalars["String"];
  fileAuthor?: Maybe<Scalars["String"]>;
  fileDuration?: Maybe<Scalars["String"]>;
  fileHeight?: Maybe<Scalars["Float"]>;
  fileSize?: Maybe<Scalars["String"]>;
  fileWidth?: Maybe<Scalars["Float"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  imageType?: Maybe<Scalars["String"]>;
  isGlassPlexyAcetate?: Maybe<Scalars["Boolean"]>;
  isMainImage?: Maybe<Scalars["Boolean"]>;
  matrixType?: Maybe<Scalars["String"]>;
  mediaType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  photographicEquipment?: Maybe<Scalars["String"]>;
  restorationSet: RestorationNodeConnection;
  transportationSet: TransportationNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type MediaNodeAppraisalSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeCollectionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeConditionstatusreportSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeDepositationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type MediaNodeExhibitionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeRestorationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeTransportationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type MediaNodeConnection = {
  __typename?: "MediaNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MediaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MediaNode` and its cursor. */
export type MediaNodeEdge = {
  __typename?: "MediaNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<MediaNode>;
};

export type Money = {
  __typename?: "Money";
  /** The numerical amount. */
  amount?: Maybe<Scalars["Decimal"]>;
  /** The string version of the numerical amount. */
  amountStr?: Maybe<Scalars["String"]>;
  asString?: Maybe<Scalars["StringMoney"]>;
  currency?: Maybe<Currency>;
  formatAmount?: Maybe<Scalars["String"]>;
};

export type MoneyFormatAmountArgs = {
  decimals?: InputMaybe<Scalars["Int"]>;
};

export type MoneyInput = {
  /** The numerical amount. */
  amount?: InputMaybe<Scalars["Decimal"]>;
  /** The ISO-421 3-letter currency code. */
  currency: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAppraisalMedia?: Maybe<AddAppraisalMediaPayload>;
  addAssetMedia?: Maybe<AddAssetMediaPayload>;
  addCollectionMedia?: Maybe<AddCollectionMediaPayload>;
  addConditionStatusReportMedia?: Maybe<AddConditionStatusReportMediaPayload>;
  addDepositationMedia?: Maybe<AddDepositationMediaPayload>;
  addExhibitionMedia?: Maybe<AddExhibitionMediaPayload>;
  addMultipleAssetMedia?: Maybe<AddMultipleAssetMediaPayload>;
  addMultipleCrsrMedia?: Maybe<AddMultipleCrsrMediaPayload>;
  addMultipleRestorationMedia?: Maybe<AddMultipleRestorationMediaPayload>;
  addRestorationMedia?: Maybe<AddRestorationMediaPayload>;
  addTransportationAssets?: Maybe<AddTransportationAssetsPayload>;
  addTransportationMedia?: Maybe<AddTransportationMediaPayload>;
  addUserObjectPermission?: Maybe<AddUserObjectPermissionPayload>;
  addUserObjectPermissionFromCollection?: Maybe<AddUserObjectPermissionFromCollectionPayload>;
  addUserPermission?: Maybe<AddUserPermissionPayload>;
  addUserSignature?: Maybe<AddUserSignaturePayload>;
  changeAssetCategory?: Maybe<ChangeAssetCategoryPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  closeAppraisal?: Maybe<CloseAppraisalPayload>;
  closeConditionStatusReport?: Maybe<CloseConditionStatusReportPayload>;
  closeRestoration?: Maybe<CloseRestorationPayload>;
  createAppraisal?: Maybe<CreateAppraisalPayload>;
  createAsset?: Maybe<CreateAssetPayload>;
  createAssetDocument?: Maybe<CreateAssetDocumentPayload>;
  createAssetDocumentAttachment?: Maybe<CreateAssetDocumentAttachmentPayload>;
  createAssetElement?: Maybe<CreateAssetElementPayload>;
  createAssetPresentValue?: Maybe<CreateAssetPresentValuePayload>;
  createCertificate?: Maybe<CreateCertificatePayload>;
  createCertificateTemplate?: Maybe<CreateCertificateTemplatePayload>;
  createCollection?: Maybe<CreateCollectionPayload>;
  createCompanyContact?: Maybe<CreateCompanyContactPayload>;
  createConditionStatusReport?: Maybe<CreateConditionStatusReportPayload>;
  createDeliveryDetails?: Maybe<CreateDeliveryDetailsPayload>;
  createDepositation?: Maybe<CreateDepositationPayload>;
  createDepositationAsset?: Maybe<CreateDepositationAssetPayload>;
  createEventLog?: Maybe<CreateEventLogPayload>;
  createExchangeRates?: Maybe<CreateExchangeRatesPayload>;
  createExhibition?: Maybe<CreateExhibitionPayload>;
  createExit?: Maybe<CreateExitPayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createMedia?: Maybe<CreateMediaPayload>;
  createOffice?: Maybe<CreateOfficePayload>;
  createPickupDetails?: Maybe<CreatePickupDetailsPayload>;
  createPolicy?: Maybe<CreatePolicyPayload>;
  createPolicyAsset?: Maybe<CreatePolicyAssetPayload>;
  createPolicyDocument?: Maybe<CreatePolicyDocumentPayload>;
  createPolicyNote?: Maybe<CreatePolicyNotePayload>;
  createRegistry?: Maybe<CreateRegistryPayload>;
  createRegistryWithCompanyContact?: Maybe<CreateRegistryWithCompanyContactPayload>;
  createRestoration?: Maybe<CreateRestorationPayload>;
  createTransportation?: Maybe<CreateTransportationPayload>;
  createUser?: Maybe<CreateUserPayload>;
  csvImportCreateAsset?: Maybe<CsvImportCreateAssetPayload>;
  deleteAppraisal?: Maybe<DeleteAppraisalPayload>;
  deleteAppraisalMedia?: Maybe<DeleteAppraisalMediaPayload>;
  deleteAsset?: Maybe<DeleteAssetPayload>;
  deleteAssetCarryingValueFile?: Maybe<DeleteAssetCarryingValueFilePayload>;
  deleteAssetDocument?: Maybe<DeleteAssetDocumentPayload>;
  deleteAssetDocumentAttachment?: Maybe<DeleteAssetDocumentAttachmentPayload>;
  deleteAssetElement?: Maybe<DeleteAssetElementPayload>;
  deleteAssetMedia?: Maybe<DeleteAssetMediaPayload>;
  deleteAssetPresentValue?: Maybe<DeleteAssetPresentValuePayload>;
  deleteCertificate?: Maybe<DeleteCertificatePayload>;
  deleteCertificateTemplate?: Maybe<DeleteCertificateTemplatePayload>;
  deleteCollection?: Maybe<DeleteCollectionPayload>;
  deleteCollectionImage?: Maybe<DeleteCollectionImagePayload>;
  deleteCollectionMedia?: Maybe<DeleteCollectionMediaPayload>;
  deleteCompanyContact?: Maybe<DeleteCompanyContactPayload>;
  deleteConditionStatusReport?: Maybe<DeleteConditionStatusReportPayload>;
  deleteConditionStatusReportMedia?: Maybe<DeleteConditionStatusReportMediaPayload>;
  deleteDeliveryDetails?: Maybe<DeleteDeliveryDetailsPayload>;
  deleteDepositation?: Maybe<DeleteDepositationPayload>;
  deleteDepositationAsset?: Maybe<DeleteDepositationAssetPayload>;
  deleteDepositationBill?: Maybe<DeleteDepositationBillPayload>;
  deleteDepositationMedia?: Maybe<DeleteDepositationMediaPayload>;
  deleteEventLog?: Maybe<DeleteEventLogPayload>;
  deleteExchangeRates?: Maybe<DeleteExchangeRatesPayload>;
  deleteExhibition?: Maybe<DeleteExhibitionPayload>;
  deleteExhibitionImage?: Maybe<DeleteExhibitionImagePayload>;
  deleteExhibitionMedia?: Maybe<DeleteExhibitionMediaPayload>;
  deleteExit?: Maybe<DeleteExitPayload>;
  deleteInsurance?: Maybe<DeleteInsurancePayload>;
  deleteMedia?: Maybe<DeleteMediaPayload>;
  deleteOffice?: Maybe<DeleteOfficePayload>;
  deletePickupDetails?: Maybe<DeletePickupDetailsPayload>;
  deletePolicy?: Maybe<DeletePolicyPayload>;
  deletePolicyAsset?: Maybe<DeletePolicyAssetPayload>;
  deletePolicyAssetsGrouped?: Maybe<DeletePolicyAssetsGroupedPayload>;
  deletePolicyDocument?: Maybe<DeletePolicyDocumentPayload>;
  deletePolicyNote?: Maybe<DeletePolicyNotePayload>;
  deleteRegistry?: Maybe<DeleteRegistryPayload>;
  deleteRegistryLogo?: Maybe<DeleteRegistryLogoPayload>;
  deleteRestoration?: Maybe<DeleteRestorationPayload>;
  deleteRestorationMedia?: Maybe<DeleteRestorationMediaPayload>;
  deleteTransportation?: Maybe<DeleteTransportationPayload>;
  deleteTransportationBill?: Maybe<DeleteTransportationBillPayload>;
  deleteTransportationMedia?: Maybe<DeleteTransportationMediaPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteUserSignature?: Maybe<DeleteUserSignaturePayload>;
  detachAssetFromAppraisal?: Maybe<DetachAssetFromAppraisalPayload>;
  detachAssetFromCollection?: Maybe<DetachAssetFromCollectionPayload>;
  detachAssetFromConditionStatusReport?: Maybe<DetachAssetFromConditionStatusReportPayload>;
  detachAssetFromExhibition?: Maybe<DetachAssetFromExhibitionPayload>;
  detachAssetFromRestoration?: Maybe<DetachAssetFromRestorationPayload>;
  detachAssetsFromCollection?: Maybe<DetachAssetsFromCollectionPayload>;
  duplicateAppraisal?: Maybe<DuplicateAppraisalPayload>;
  duplicateAsset?: Maybe<DuplicateAssetPayload>;
  duplicateConditionStatusReport?: Maybe<DuplicateConditionStatusReportPayload>;
  duplicateDepositation?: Maybe<DuplicateDepositationPayload>;
  duplicatePolicy?: Maybe<DuplicatePolicyPayload>;
  duplicateRestoration?: Maybe<DuplicateRestorationPayload>;
  duplicateTransportation?: Maybe<DuplicateTransportationPayload>;
  exportAppraisalPdf?: Maybe<ExportAppraisalPdfPayload>;
  exportAssetPdf?: Maybe<ExportAssetPdfPayload>;
  exportCollectionPdf?: Maybe<ExportCollectionPdfPayload>;
  exportConditionStatusReportPdf?: Maybe<ExportConditionStatusReportPdfPayload>;
  exportDepositationAssetLabel?: Maybe<ExportDepositationAssetsLabelsPayload>;
  exportDepositationAssetPackingList?: Maybe<ExportDepositationAssetsPackingListPayload>;
  exportDepositationInboundBills?: Maybe<ExportDepositationInboundBillsPayload>;
  exportDepositationLabels?: Maybe<ExportDepositationLabelsPayload>;
  exportDepositationOutboundBills?: Maybe<ExportDepositationOutboundBillsPayload>;
  exportDepositationPackingList?: Maybe<ExportDepositationPackingListPayload>;
  exportDepositationPdf?: Maybe<ExportDepositationPdfPayload>;
  exportExhibitionPdf?: Maybe<ExportExhibitionPdfPayload>;
  exportPolicyPdf?: Maybe<ExportPolicyPdfPayload>;
  exportRestorationPdf?: Maybe<ExportRestorationPdfPayload>;
  exportTransportationAssetLabel?: Maybe<ExportTransportationAssetLabelPayload>;
  exportTransportationAssetPackingList?: Maybe<ExportTransportationAssetPackingListPayload>;
  exportTransportationBills?: Maybe<ExportTransportationBillsPayload>;
  exportTransportationLabels?: Maybe<ExportTransportationLabelsPayload>;
  exportTransportationPackingList?: Maybe<ExportTransportationPackingListPayload>;
  exportTransportationPdf?: Maybe<ExportTransportationPdfPayload>;
  extendPolicy?: Maybe<ExtendPolicyPayload>;
  generateCertificate?: Maybe<GenerateCertificatePayload>;
  generateGroupedCertificate?: Maybe<GenerateGroupedCertificatePayload>;
  generateSplitCertificate?: Maybe<GenerateSplitCertificatePayload>;
  getAssetPrediction?: Maybe<GetAssetPredictionPayload>;
  hardDeleteUser?: Maybe<HardDeleteUserPayload>;
  invalidateCertificate?: Maybe<InvalidateCertificatePayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  linkRegistryWithAsset?: Maybe<LinkRegistryWithAssetPayload>;
  refreshToken?: Maybe<Refresh>;
  removeTransportationAssets?: Maybe<RemoveTransportationAssetsPayload>;
  removeUserObjectPermission?: Maybe<RemoveUserObjectPermissionPayload>;
  renewPolicy?: Maybe<RenewPolicyPayload>;
  renewUser?: Maybe<RenewUserPayload>;
  requestUserResetPassword?: Maybe<RequestUserResetPasswordPayload>;
  resetClientLogin?: Maybe<ResetClientLoginPayload>;
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  revertPolicy?: Maybe<RevertPolicyPayload>;
  revokeToken?: Maybe<Revoke>;
  setMainSignature?: Maybe<SetMainSignaturePayload>;
  setPrivateRegistry?: Maybe<SetPrivateRegistryPayload>;
  setPublicRegistry?: Maybe<SetPublicRegistryPayload>;
  sharePolicyByEmail?: Maybe<SharePolicyByEmailPayload>;
  sharePolicyByUsers?: Maybe<SharePolicyByUsersPayload>;
  shareableToken?: Maybe<ObtainShareableTokenPayload>;
  signAppraisal?: Maybe<SignAppraisalPayload>;
  signCertificate?: Maybe<SignCertificatePayload>;
  signConditionStatusReport?: Maybe<SignConditionStatusReportPayload>;
  signRestoration?: Maybe<SignRestorationPayload>;
  signTransportationBill?: Maybe<SignTransportationBillPayload>;
  startAssetPrediction?: Maybe<StartAssetPredictionPayload>;
  terminatePolicy?: Maybe<TerminatePolicyPayload>;
  toggleUserIsactive?: Maybe<ToggleUserIsActivePayload>;
  toggleUserUserType?: Maybe<ToggleUserUserTypePayload>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  unlinkRegistryWithAsset?: Maybe<UnlinkRegistryWithAssetPayload>;
  updateAppraisal?: Maybe<UpdateAppraisalPayload>;
  updateAsset?: Maybe<UpdateAssetPayload>;
  updateAssetDocument?: Maybe<UpdateAssetDocumentPayload>;
  updateAssetDocumentAttachment?: Maybe<UpdateAssetDocumentAttachmentPayload>;
  updateAssetElement?: Maybe<UpdateAssetElementPayload>;
  updateAssetPresentValue?: Maybe<UpdateAssetPresentValuePayload>;
  updateCertificate?: Maybe<UpdateCertificatePayload>;
  updateClient?: Maybe<UpdateClientPayload>;
  updateClientBackgroundColor?: Maybe<UpdateClientBackgroundColorPayload>;
  updateClientLogo?: Maybe<UpdateClientLogoPayload>;
  updateCollection?: Maybe<UpdateCollectionPayload>;
  updateCompanyContact?: Maybe<UpdateCompanyContactPayload>;
  updateConditionStatusReport?: Maybe<UpdateConditionStatusReportPayload>;
  updateDeliveryDetails?: Maybe<UpdateDeliveryDetailsPayload>;
  updateDepositation?: Maybe<UpdateDepositationPayload>;
  updateDepositationStatus?: Maybe<UpdateDepositationStatusPayload>;
  updateEventLog?: Maybe<UpdateEventLogPayload>;
  updateExchangeRates?: Maybe<UpdateExchangeRatesPayload>;
  updateExhibition?: Maybe<UpdateExhibitionPayload>;
  updateExit?: Maybe<UpdateExitPayload>;
  updateInsurance?: Maybe<UpdateInsurancePayload>;
  updateLinkedRegistryAsset?: Maybe<UpdateLinkedRegistryAssetPayload>;
  updateMedia?: Maybe<UpdateMediaPayload>;
  updateOffice?: Maybe<UpdateOfficePayload>;
  updatePickupDetails?: Maybe<UpdatePickupDetailsPayload>;
  updatePolicy?: Maybe<UpdatePolicyPayload>;
  updatePolicyAsset?: Maybe<UpdatePolicyAssetPayload>;
  updatePolicyAssetGroup?: Maybe<UpdatePolicyAssetGroupPayload>;
  updatePolicyDocument?: Maybe<UpdatePolicyDocumentPayload>;
  updatePolicyNote?: Maybe<UpdatePolicyNotePayload>;
  updateRegistry?: Maybe<UpdateRegistryPayload>;
  updateRestoration?: Maybe<UpdateRestorationPayload>;
  updateTransportation?: Maybe<UpdateTransportationPayload>;
  updateTransportationStatus?: Maybe<UpdateTransportationStatusPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserPermissions?: Maybe<UpdateUserPermissionPayload>;
  verifyShareableToken?: Maybe<VerifyShareableTokenPayload>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAddAppraisalMediaArgs = {
  input: AddAppraisalMediaInput;
};

export type MutationAddAssetMediaArgs = {
  input: AddAssetMediaInput;
};

export type MutationAddCollectionMediaArgs = {
  input: AddCollectionMediaInput;
};

export type MutationAddConditionStatusReportMediaArgs = {
  input: AddConditionStatusReportMediaInput;
};

export type MutationAddDepositationMediaArgs = {
  input: AddDepositationMediaInput;
};

export type MutationAddExhibitionMediaArgs = {
  input: AddExhibitionMediaInput;
};

export type MutationAddMultipleAssetMediaArgs = {
  input: AddMultipleAssetMediaInput;
};

export type MutationAddMultipleCrsrMediaArgs = {
  input: AddMultipleCrsrMediaInput;
};

export type MutationAddMultipleRestorationMediaArgs = {
  input: AddMultipleRestorationMediaInput;
};

export type MutationAddRestorationMediaArgs = {
  input: AddRestorationMediaInput;
};

export type MutationAddTransportationAssetsArgs = {
  input: AddTransportationAssetsInput;
};

export type MutationAddTransportationMediaArgs = {
  input: AddTransportationMediaInput;
};

export type MutationAddUserObjectPermissionArgs = {
  input: AddUserObjectPermissionInput;
};

export type MutationAddUserObjectPermissionFromCollectionArgs = {
  input: AddUserObjectPermissionFromCollectionInput;
};

export type MutationAddUserPermissionArgs = {
  input: AddUserPermissionInput;
};

export type MutationAddUserSignatureArgs = {
  input: AddUserSignatureInput;
};

export type MutationChangeAssetCategoryArgs = {
  input: ChangeAssetCategoryInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCloseAppraisalArgs = {
  input: CloseAppraisalInput;
};

export type MutationCloseConditionStatusReportArgs = {
  input: CloseConditionStatusReportInput;
};

export type MutationCloseRestorationArgs = {
  input: CloseRestorationInput;
};

export type MutationCreateAppraisalArgs = {
  input: CreateAppraisalInput;
};

export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};

export type MutationCreateAssetDocumentArgs = {
  input: CreateAssetDocumentInput;
};

export type MutationCreateAssetDocumentAttachmentArgs = {
  input: CreateAssetDocumentAttachmentInput;
};

export type MutationCreateAssetElementArgs = {
  input: CreateAssetElementInput;
};

export type MutationCreateAssetPresentValueArgs = {
  input: CreateAssetPresentValueInput;
};

export type MutationCreateCertificateArgs = {
  input: CreateCertificateInput;
};

export type MutationCreateCertificateTemplateArgs = {
  input: CreateCertificateTemplateInput;
};

export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};

export type MutationCreateCompanyContactArgs = {
  input: CreateCompanyContactInput;
};

export type MutationCreateConditionStatusReportArgs = {
  input: CreateConditionStatusReportInput;
};

export type MutationCreateDeliveryDetailsArgs = {
  input: CreateDeliveryDetailsInput;
};

export type MutationCreateDepositationArgs = {
  input: CreateDepositationInput;
};

export type MutationCreateDepositationAssetArgs = {
  input: CreateDepositationAssetInput;
};

export type MutationCreateEventLogArgs = {
  input: CreateEventLogInput;
};

export type MutationCreateExchangeRatesArgs = {
  input: CreateExchangeRatesInput;
};

export type MutationCreateExhibitionArgs = {
  input: CreateExhibitionInput;
};

export type MutationCreateExitArgs = {
  input: CreateExitInput;
};

export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};

export type MutationCreateMediaArgs = {
  input: CreateMediaInput;
};

export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};

export type MutationCreatePickupDetailsArgs = {
  input: CreatePickupDetailsInput;
};

export type MutationCreatePolicyArgs = {
  input: CreatePolicyInput;
};

export type MutationCreatePolicyAssetArgs = {
  input: CreatePolicyAssetInput;
};

export type MutationCreatePolicyDocumentArgs = {
  input: CreatePolicyDocumentInput;
};

export type MutationCreatePolicyNoteArgs = {
  input: CreatePolicyNoteInput;
};

export type MutationCreateRegistryArgs = {
  input: CreateRegistryInput;
};

export type MutationCreateRegistryWithCompanyContactArgs = {
  input: CreateRegistryWithCompanyContactInput;
};

export type MutationCreateRestorationArgs = {
  input: CreateRestorationInput;
};

export type MutationCreateTransportationArgs = {
  input: CreateTransportationInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCsvImportCreateAssetArgs = {
  input: CsvImportCreateAssetInput;
};

export type MutationDeleteAppraisalArgs = {
  input: DeleteAppraisalInput;
};

export type MutationDeleteAppraisalMediaArgs = {
  input: DeleteAppraisalMediaInput;
};

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};

export type MutationDeleteAssetCarryingValueFileArgs = {
  input: DeleteAssetCarryingValueFileInput;
};

export type MutationDeleteAssetDocumentArgs = {
  input: DeleteAssetDocumentInput;
};

export type MutationDeleteAssetDocumentAttachmentArgs = {
  input: DeleteAssetDocumentAttachmentInput;
};

export type MutationDeleteAssetElementArgs = {
  input: DeleteAssetElementInput;
};

export type MutationDeleteAssetMediaArgs = {
  input: DeleteAssetMediaInput;
};

export type MutationDeleteAssetPresentValueArgs = {
  input: DeleteAssetPresentValueInput;
};

export type MutationDeleteCertificateArgs = {
  input: DeleteCertificateInput;
};

export type MutationDeleteCertificateTemplateArgs = {
  input: DeleteCertificateTemplateInput;
};

export type MutationDeleteCollectionArgs = {
  input: DeleteCollectionInput;
};

export type MutationDeleteCollectionImageArgs = {
  input: DeleteCollectionImageInput;
};

export type MutationDeleteCollectionMediaArgs = {
  input: DeleteCollectionMediaInput;
};

export type MutationDeleteCompanyContactArgs = {
  input: DeleteCompanyContactInput;
};

export type MutationDeleteConditionStatusReportArgs = {
  input: DeleteConditionStatusReportInput;
};

export type MutationDeleteConditionStatusReportMediaArgs = {
  input: DeleteConditionStatusReportMediaInput;
};

export type MutationDeleteDeliveryDetailsArgs = {
  input: DeleteDeliveryDetailsInput;
};

export type MutationDeleteDepositationArgs = {
  input: DeleteDepositationInput;
};

export type MutationDeleteDepositationAssetArgs = {
  input: DeleteDepositationAssetInput;
};

export type MutationDeleteDepositationBillArgs = {
  input: DeleteDepositationBillInput;
};

export type MutationDeleteDepositationMediaArgs = {
  input: DeleteDepositationMediaInput;
};

export type MutationDeleteEventLogArgs = {
  input: DeleteEventLogInput;
};

export type MutationDeleteExchangeRatesArgs = {
  input: DeleteExchangeRatesInput;
};

export type MutationDeleteExhibitionArgs = {
  input: DeleteExhibitionInput;
};

export type MutationDeleteExhibitionImageArgs = {
  input: DeleteExhibitionImageInput;
};

export type MutationDeleteExhibitionMediaArgs = {
  input: DeleteExhibitionMediaInput;
};

export type MutationDeleteExitArgs = {
  input: DeleteExitInput;
};

export type MutationDeleteInsuranceArgs = {
  input: DeleteInsuranceInput;
};

export type MutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};

export type MutationDeleteOfficeArgs = {
  input: DeleteOfficeInput;
};

export type MutationDeletePickupDetailsArgs = {
  input: DeletePickupDetailsInput;
};

export type MutationDeletePolicyArgs = {
  input: DeletePolicyInput;
};

export type MutationDeletePolicyAssetArgs = {
  input: DeletePolicyAssetInput;
};

export type MutationDeletePolicyAssetsGroupedArgs = {
  input: DeletePolicyAssetsGroupedInput;
};

export type MutationDeletePolicyDocumentArgs = {
  input: DeletePolicyDocumentInput;
};

export type MutationDeletePolicyNoteArgs = {
  input: DeletePolicyNoteInput;
};

export type MutationDeleteRegistryArgs = {
  input: DeleteRegistryInput;
};

export type MutationDeleteRegistryLogoArgs = {
  input: DeleteRegistryLogoInput;
};

export type MutationDeleteRestorationArgs = {
  input: DeleteRestorationInput;
};

export type MutationDeleteRestorationMediaArgs = {
  input: DeleteRestorationMediaInput;
};

export type MutationDeleteTransportationArgs = {
  input: DeleteTransportationInput;
};

export type MutationDeleteTransportationBillArgs = {
  input: DeleteTransportationBillInput;
};

export type MutationDeleteTransportationMediaArgs = {
  input: DeleteTransportationMediaInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationDeleteUserSignatureArgs = {
  input: DeleteUserSignatureInput;
};

export type MutationDetachAssetFromAppraisalArgs = {
  input: DetachAssetFromAppraisalInput;
};

export type MutationDetachAssetFromCollectionArgs = {
  input: DetachAssetFromCollectionInput;
};

export type MutationDetachAssetFromConditionStatusReportArgs = {
  input: DetachAssetFromConditionStatusReportInput;
};

export type MutationDetachAssetFromExhibitionArgs = {
  input: DetachAssetFromExhibitionInput;
};

export type MutationDetachAssetFromRestorationArgs = {
  input: DetachAssetFromRestorationInput;
};

export type MutationDetachAssetsFromCollectionArgs = {
  input: DetachAssetsFromCollectionInput;
};

export type MutationDuplicateAppraisalArgs = {
  input: DuplicateAppraisalInput;
};

export type MutationDuplicateAssetArgs = {
  input: DuplicateAssetInput;
};

export type MutationDuplicateConditionStatusReportArgs = {
  input: DuplicateConditionStatusReportInput;
};

export type MutationDuplicateDepositationArgs = {
  input: DuplicateDepositationInput;
};

export type MutationDuplicatePolicyArgs = {
  input: DuplicatePolicyInput;
};

export type MutationDuplicateRestorationArgs = {
  input: DuplicateRestorationInput;
};

export type MutationDuplicateTransportationArgs = {
  input: DuplicateTransportationInput;
};

export type MutationExportAppraisalPdfArgs = {
  input: ExportAppraisalPdfInput;
};

export type MutationExportAssetPdfArgs = {
  input: ExportAssetPdfInput;
};

export type MutationExportCollectionPdfArgs = {
  input: ExportCollectionPdfInput;
};

export type MutationExportConditionStatusReportPdfArgs = {
  input: ExportConditionStatusReportPdfInput;
};

export type MutationExportDepositationAssetLabelArgs = {
  input: ExportDepositationAssetsLabelsInput;
};

export type MutationExportDepositationAssetPackingListArgs = {
  input: ExportDepositationAssetsPackingListInput;
};

export type MutationExportDepositationInboundBillsArgs = {
  input: ExportDepositationInboundBillsInput;
};

export type MutationExportDepositationLabelsArgs = {
  input: ExportDepositationLabelsInput;
};

export type MutationExportDepositationOutboundBillsArgs = {
  input: ExportDepositationOutboundBillsInput;
};

export type MutationExportDepositationPackingListArgs = {
  input: ExportDepositationPackingListInput;
};

export type MutationExportDepositationPdfArgs = {
  input: ExportDepositationPdfInput;
};

export type MutationExportExhibitionPdfArgs = {
  input: ExportExhibitionPdfInput;
};

export type MutationExportPolicyPdfArgs = {
  input: ExportPolicyPdfInput;
};

export type MutationExportRestorationPdfArgs = {
  input: ExportRestorationPdfInput;
};

export type MutationExportTransportationAssetLabelArgs = {
  input: ExportTransportationAssetLabelInput;
};

export type MutationExportTransportationAssetPackingListArgs = {
  input: ExportTransportationAssetPackingListInput;
};

export type MutationExportTransportationBillsArgs = {
  input: ExportTransportationBillsInput;
};

export type MutationExportTransportationLabelsArgs = {
  input: ExportTransportationLabelsInput;
};

export type MutationExportTransportationPackingListArgs = {
  input: ExportTransportationPackingListInput;
};

export type MutationExportTransportationPdfArgs = {
  input: ExportTransportationPdfInput;
};

export type MutationExtendPolicyArgs = {
  input: ExtendPolicyInput;
};

export type MutationGenerateCertificateArgs = {
  input: GenerateCertificateInput;
};

export type MutationGenerateGroupedCertificateArgs = {
  input: GenerateGroupedCertificateInput;
};

export type MutationGenerateSplitCertificateArgs = {
  input: GenerateSplitCertificateInput;
};

export type MutationGetAssetPredictionArgs = {
  input: GetAssetPredictionInput;
};

export type MutationHardDeleteUserArgs = {
  input: HardDeleteUserInput;
};

export type MutationInvalidateCertificateArgs = {
  input: InvalidateCertificateInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationLinkRegistryWithAssetArgs = {
  input: LinkRegistryWithAssetInput;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRemoveTransportationAssetsArgs = {
  input: RemoveTransportationAssetsInput;
};

export type MutationRemoveUserObjectPermissionArgs = {
  input: RemoveUserObjectPermissionInput;
};

export type MutationRenewPolicyArgs = {
  input: RenewPolicyInput;
};

export type MutationRenewUserArgs = {
  input: RenewUserInput;
};

export type MutationRequestUserResetPasswordArgs = {
  input: RequestUserResetPasswordInput;
};

export type MutationResetClientLoginArgs = {
  input: ResetClientLoginInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationRevertPolicyArgs = {
  input: RevertPolicyInput;
};

export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationSetMainSignatureArgs = {
  input: SetMainSignatureInput;
};

export type MutationSetPrivateRegistryArgs = {
  input: SetPrivateRegistryInput;
};

export type MutationSetPublicRegistryArgs = {
  input: SetPublicRegistryInput;
};

export type MutationSharePolicyByEmailArgs = {
  input: SharePolicyByEmailInput;
};

export type MutationSharePolicyByUsersArgs = {
  input: SharePolicyByUsersInput;
};

export type MutationShareableTokenArgs = {
  input: ObtainShareableTokenInput;
};

export type MutationSignAppraisalArgs = {
  input: SignAppraisalInput;
};

export type MutationSignCertificateArgs = {
  input: SignCertificateInput;
};

export type MutationSignConditionStatusReportArgs = {
  input: SignConditionStatusReportInput;
};

export type MutationSignRestorationArgs = {
  input: SignRestorationInput;
};

export type MutationSignTransportationBillArgs = {
  input: SignTransportationBillInput;
};

export type MutationStartAssetPredictionArgs = {
  input: StartAssetPredictionInput;
};

export type MutationTerminatePolicyArgs = {
  input: TerminatePolicyInput;
};

export type MutationToggleUserIsactiveArgs = {
  input: ToggleUserIsActiveInput;
};

export type MutationToggleUserUserTypeArgs = {
  input: ToggleUserUserTypeInput;
};

export type MutationTokenAuthArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUnlinkRegistryWithAssetArgs = {
  input: UnlinkRegistryWithAssetInput;
};

export type MutationUpdateAppraisalArgs = {
  input: UpdateAppraisalInput;
};

export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};

export type MutationUpdateAssetDocumentArgs = {
  input: UpdateAssetDocumentInput;
};

export type MutationUpdateAssetDocumentAttachmentArgs = {
  input: UpdateAssetDocumentAttachmentInput;
};

export type MutationUpdateAssetElementArgs = {
  input: UpdateAssetElementInput;
};

export type MutationUpdateAssetPresentValueArgs = {
  input: UpdateAssetPresentValueInput;
};

export type MutationUpdateCertificateArgs = {
  input: UpdateCertificateInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateClientBackgroundColorArgs = {
  input: UpdateClientBackgroundColorInput;
};

export type MutationUpdateClientLogoArgs = {
  input: UpdateClientLogoInput;
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

export type MutationUpdateCompanyContactArgs = {
  input: UpdateCompanyContactInput;
};

export type MutationUpdateConditionStatusReportArgs = {
  input: UpdateConditionStatusReportInput;
};

export type MutationUpdateDeliveryDetailsArgs = {
  input: UpdateDeliveryDetailsInput;
};

export type MutationUpdateDepositationArgs = {
  input: UpdateDepositationInput;
};

export type MutationUpdateDepositationStatusArgs = {
  input: UpdateDepositationStatusInput;
};

export type MutationUpdateEventLogArgs = {
  input: UpdateEventLogInput;
};

export type MutationUpdateExchangeRatesArgs = {
  input: UpdateExchangeRatesInput;
};

export type MutationUpdateExhibitionArgs = {
  input: UpdateExhibitionInput;
};

export type MutationUpdateExitArgs = {
  input: UpdateExitInput;
};

export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};

export type MutationUpdateLinkedRegistryAssetArgs = {
  input: UpdateLinkedRegistryAssetInput;
};

export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};

export type MutationUpdatePickupDetailsArgs = {
  input: UpdatePickupDetailsInput;
};

export type MutationUpdatePolicyArgs = {
  input: UpdatePolicyInput;
};

export type MutationUpdatePolicyAssetArgs = {
  input: UpdatePolicyAssetInput;
};

export type MutationUpdatePolicyAssetGroupArgs = {
  input: UpdatePolicyAssetGroupInput;
};

export type MutationUpdatePolicyDocumentArgs = {
  input: UpdatePolicyDocumentInput;
};

export type MutationUpdatePolicyNoteArgs = {
  input: UpdatePolicyNoteInput;
};

export type MutationUpdateRegistryArgs = {
  input: UpdateRegistryInput;
};

export type MutationUpdateRestorationArgs = {
  input: UpdateRestorationInput;
};

export type MutationUpdateTransportationArgs = {
  input: UpdateTransportationInput;
};

export type MutationUpdateTransportationStatusArgs = {
  input: UpdateTransportationStatusInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserPermissionsArgs = {
  input: UpdateUserPermissionInput;
};

export type MutationVerifyShareableTokenArgs = {
  input: VerifyShareableTokenInput;
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars["String"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars["ID"];
};

export type ObtainJsonWebToken = {
  __typename?: "ObtainJSONWebToken";
  algoliaUserKey?: Maybe<Scalars["String"]>;
  payload: Scalars["GenericScalar"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  token: Scalars["String"];
  user?: Maybe<UserType>;
};

export type ObtainShareableTokenInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiration?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type ObtainShareableTokenPayload = {
  __typename?: "ObtainShareableTokenPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
};

export type OfficeInput = {
  active: Scalars["Boolean"];
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  main: Scalars["Boolean"];
  name?: InputMaybe<Scalars["String"]>;
  officeAddress?: InputMaybe<AddressInput>;
  phone?: InputMaybe<Scalars["String"]>;
  registry: Scalars["ID"];
  state?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type OfficeNode = Node & {
  __typename?: "OfficeNode";
  active?: Maybe<Scalars["Boolean"]>;
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deliverydetailsSet: DeliveryDetailsNodeConnection;
  depositationSet: DepositationNodeConnection;
  email?: Maybe<Scalars["String"]>;
  eventLocationEntity: ExhibitionNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  main?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  objectLocationOffice: PolicyAssetNodeConnection;
  objectLocationOfficeHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeAddress?: Maybe<Address>;
  phone?: Maybe<Scalars["String"]>;
  pickupdetailsSet: PickupDetailsNodeConnection;
  policyEventLocationEntity: PolicyNodeConnection;
  registry?: Maybe<RegistryNode>;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  zip?: Maybe<Scalars["String"]>;
};

export type OfficeNodeDeliverydetailsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type OfficeNodeDepositationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type OfficeNodeEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeObjectLocationOfficeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeObjectLocationOfficeHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodePickupdetailsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type OfficeNodePolicyEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeConnection = {
  __typename?: "OfficeNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OfficeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OfficeNode` and its cursor. */
export type OfficeNodeEdge = {
  __typename?: "OfficeNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<OfficeNode>;
};

export type OfficeType = {
  __typename?: "OfficeType";
  active?: Maybe<Scalars["Boolean"]>;
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deliverydetailsSet: DeliveryDetailsNodeConnection;
  depositationSet: DepositationNodeConnection;
  email?: Maybe<Scalars["String"]>;
  eventLocationEntity: ExhibitionNodeConnection;
  id: Scalars["ID"];
  main?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  objectLocationOffice: PolicyAssetNodeConnection;
  objectLocationOfficeHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeAddress?: Maybe<Address>;
  phone?: Maybe<Scalars["String"]>;
  pickupdetailsSet: PickupDetailsNodeConnection;
  policyEventLocationEntity: PolicyNodeConnection;
  registry?: Maybe<RegistryNode>;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  zip?: Maybe<Scalars["String"]>;
};

export type OfficeTypeDeliverydetailsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type OfficeTypeDepositationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type OfficeTypeEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypeObjectLocationOfficeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypeObjectLocationOfficeHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypePickupdetailsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type OfficeTypePolicyEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PackagesInput = {
  depth?: InputMaybe<Scalars["Float"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type PackagesNode = Node & {
  __typename?: "PackagesNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  depth?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  dimensionsNotes?: Maybe<Scalars["String"]>;
  grossWeight?: Maybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: Maybe<Scalars["String"]>;
  hardPackagingDescription?: Maybe<Scalars["String"]>;
  hardPackagingNotes?: Maybe<Scalars["String"]>;
  hardPackagingType?: Maybe<SlogPackagesHardPackagingTypeChoices>;
  height?: Maybe<Scalars["Float"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  linearUnitOfMeasure?: Maybe<Scalars["String"]>;
  netWeight?: Maybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  packageDimensionsInfo?: Maybe<Scalars["String"]>;
  packageGrossWeightInfo?: Maybe<Scalars["String"]>;
  packageNetWeightInfo?: Maybe<Scalars["String"]>;
  packageVolumeInfo?: Maybe<Scalars["String"]>;
  packaging?: Maybe<SlogPackagesPackagingChoices>;
  packagingNotes?: Maybe<Scalars["String"]>;
  packagingType?: Maybe<Scalars["String"]>;
  packingUnpackingInstructions?: Maybe<Scalars["String"]>;
  softPackagingDescription?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  transportationassetsSet: TransportationAssetNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  volume?: Maybe<Scalars["Float"]>;
  volumeUnitOfMeasure?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type PackagesNodeTransportationassetsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type PackagesNodeConnection = {
  __typename?: "PackagesNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PackagesNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PackagesNode` and its cursor. */
export type PackagesNodeEdge = {
  __typename?: "PackagesNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PackagesNode>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type PermissionNode = Node & {
  __typename?: "PermissionNode";
  codename: Scalars["String"];
  contentType: ContentTypeNode;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  /** Specific permissions for this user. */
  userSet: Array<UserType>;
};

export type PermissionNodeConnection = {
  __typename?: "PermissionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PermissionNode` and its cursor. */
export type PermissionNodeEdge = {
  __typename?: "PermissionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PermissionNode>;
};

export type PickupDetailsInput = {
  cellularNumber?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  pickupDatetime?: InputMaybe<Scalars["String"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
};

export type PickupDetailsNode = Node & {
  __typename?: "PickupDetailsNode";
  cellularNumber?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  email?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
  pickupDatetime?: Maybe<Scalars["DateTime"]>;
  pickupLocation?: Maybe<OfficeNode>;
  pickupType?: Maybe<SlogPickupDetailsPickupTypeChoices>;
  sender?: Maybe<RegistryNode>;
  telephoneNumber?: Maybe<Scalars["String"]>;
  transportationSet: TransportationNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type PickupDetailsNodeTransportationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type PickupDetailsNodeConnection = {
  __typename?: "PickupDetailsNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickupDetailsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PickupDetailsNode` and its cursor. */
export type PickupDetailsNodeEdge = {
  __typename?: "PickupDetailsNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PickupDetailsNode>;
};

export type PolicyAssetBasketHistoryNode = Node & {
  __typename?: "PolicyAssetBasketHistoryNode";
  asset: AssetNode;
  catNat?: Maybe<Scalars["Boolean"]>;
  coverTerrorism?: Maybe<Scalars["Boolean"]>;
  coverType: SpinPolicyAssetBasketHistoryCoverTypeChoices;
  coveredRiskType: SpinPolicyAssetBasketHistoryCoveredRiskTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliveryAddress?: Maybe<Address>;
  diff?: Maybe<Scalars["String"]>;
  evaluationType: SpinPolicyAssetBasketHistoryEvaluationTypeChoices;
  exemption?: Maybe<Scalars["Boolean"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  insuredValue?: Maybe<Money>;
  insuredValueCurrency: SpinPolicyAssetBasketHistoryInsuredValueCurrencyChoices;
  issuedAt?: Maybe<Scalars["DateTime"]>;
  lender?: Maybe<RegistryNode>;
  lenderAddress?: Maybe<Address>;
  objectLocationEntity?: Maybe<RegistryNode>;
  objectLocationOffice?: Maybe<OfficeNode>;
  pickupAddress?: Maybe<Address>;
  policy: PolicyNode;
  policyAsset: PolicyAssetNode;
  policyBasketHistory: PolicyBasketHistoryNode;
  shipper?: Maybe<RegistryNode>;
  status: SpinPolicyAssetBasketHistoryStatusChoices;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type PolicyAssetBasketHistoryNodeConnection = {
  __typename?: "PolicyAssetBasketHistoryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyAssetBasketHistoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyAssetBasketHistoryNode` and its cursor. */
export type PolicyAssetBasketHistoryNodeEdge = {
  __typename?: "PolicyAssetBasketHistoryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyAssetBasketHistoryNode>;
};

export type PolicyAssetInput = {
  asset?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryAddress?: InputMaybe<AddressInput>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  insuredValue?: InputMaybe<MoneyInput>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  lenderAddress?: InputMaybe<AddressInput>;
  lenderId?: InputMaybe<Scalars["ID"]>;
  objectLocationEntityId?: InputMaybe<Scalars["ID"]>;
  objectLocationOfficeId?: InputMaybe<Scalars["ID"]>;
  pickupAddress?: InputMaybe<AddressInput>;
  policy?: InputMaybe<Scalars["ID"]>;
  shipperId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type PolicyAssetNode = Node & {
  __typename?: "PolicyAssetNode";
  asset: AssetNode;
  catNat?: Maybe<Scalars["Boolean"]>;
  certificateProgressiveNumber?: Maybe<Scalars["Int"]>;
  certificateSet: CertificateNodeConnection;
  coverTerrorism?: Maybe<Scalars["Boolean"]>;
  coverType: Scalars["String"];
  coveredRiskType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliveryAddress?: Maybe<Address>;
  evaluationType: Scalars["String"];
  exemption?: Maybe<Scalars["Boolean"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  historicalPolicyAsset: PolicyAssetBasketHistoryNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  insuredValue?: Maybe<Money>;
  insuredValueCurrency: Scalars["String"];
  issuedAt?: Maybe<Scalars["DateTime"]>;
  lender?: Maybe<RegistryNode>;
  lenderAddress?: Maybe<Address>;
  objectLocationEntity?: Maybe<RegistryNode>;
  objectLocationOffice?: Maybe<OfficeNode>;
  pickupAddress?: Maybe<Address>;
  policy: PolicyNode;
  shipper?: Maybe<RegistryNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type PolicyAssetNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyAssetNodeHistoricalPolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyAssetNodeConnection = {
  __typename?: "PolicyAssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyAssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyAssetNode` and its cursor. */
export type PolicyAssetNodeEdge = {
  __typename?: "PolicyAssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyAssetNode>;
};

export type PolicyBasketHistoryNode = Node & {
  __typename?: "PolicyBasketHistoryNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  overrideChangeDate?: Maybe<Scalars["String"]>;
  policy: PolicyNode;
  policyassetbaskethistorySet: PolicyAssetBasketHistoryNodeConnection;
  reason: Scalars["String"];
  status: SpinPolicyBasketHistoryStatusChoices;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type PolicyBasketHistoryNodePolicyassetbaskethistorySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyBasketHistoryNodeConnection = {
  __typename?: "PolicyBasketHistoryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyBasketHistoryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyBasketHistoryNode` and its cursor. */
export type PolicyBasketHistoryNodeEdge = {
  __typename?: "PolicyBasketHistoryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyBasketHistoryNode>;
};

export type PolicyDocumentInput = {
  description?: InputMaybe<Scalars["String"]>;
  extension?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

export type PolicyDocumentNode = Node & {
  __typename?: "PolicyDocumentNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  extension?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  policy: PolicyNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  weight?: Maybe<Scalars["Float"]>;
};

export type PolicyDocumentNodeConnection = {
  __typename?: "PolicyDocumentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyDocumentNode` and its cursor. */
export type PolicyDocumentNodeEdge = {
  __typename?: "PolicyDocumentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyDocumentNode>;
};

export type PolicyEventsNode = Node & {
  __typename?: "PolicyEventsNode";
  action?: Maybe<SpinPolicyEventsActionChoices>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  /** The ID of the object */
  id: Scalars["ID"];
  policy: PolicyNode;
  progressiveNumber: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type PolicyEventsNodeConnection = {
  __typename?: "PolicyEventsNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyEventsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyEventsNode` and its cursor. */
export type PolicyEventsNodeEdge = {
  __typename?: "PolicyEventsNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyEventsNode>;
};

export type PolicyInput = {
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  brokerId?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  contractingPartyId: Scalars["ID"];
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntityId: Scalars["ID"];
  eventFrom?: InputMaybe<Scalars["String"]>;
  eventLocationEntityId: Scalars["ID"];
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["String"]>;
  exchangeRates?: InputMaybe<Array<InputMaybe<ExchangeRatesPartialInput>>>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt: Scalars["DateTime"];
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompanyId: Scalars["ID"];
  issuedAt: Scalars["DateTime"];
  offerCode: Scalars["String"];
  policyAssets?: InputMaybe<Array<InputMaybe<PolicyAssetInput>>>;
  policyCode: Scalars["String"];
  policyDocuments?: InputMaybe<Array<InputMaybe<PolicyDocumentInput>>>;
  policyNote?: InputMaybe<Array<InputMaybe<PolicyNoteInput>>>;
  policyStatus: Scalars["String"];
  policyType: Scalars["String"];
  totalInsuredPremium?: InputMaybe<MoneyInput>;
  totalInsuredValue?: InputMaybe<MoneyInput>;
};

export type PolicyNode = Node & {
  __typename?: "PolicyNode";
  assets: AssetNodeConnection;
  assetsCount?: Maybe<Scalars["Int"]>;
  autoRenewal?: Maybe<Scalars["Boolean"]>;
  basketChanges?: Maybe<Array<Maybe<PolicyAssetNode>>>;
  broker?: Maybe<RegistryNode>;
  catNat?: Maybe<Scalars["Boolean"]>;
  certificateSet: CertificateNodeConnection;
  collection?: Maybe<CollectionNode>;
  contractingParty: RegistryNode;
  coverTerrorism?: Maybe<Scalars["Boolean"]>;
  coverType: Scalars["String"];
  coveredRiskType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  diffChanges?: Maybe<Array<Maybe<DiffChangesNode>>>;
  evaluationType: Scalars["String"];
  eventEntity?: Maybe<RegistryNode>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventLocationEntity?: Maybe<OfficeNode>;
  eventName?: Maybe<Scalars["String"]>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  exchangeRates?: Maybe<Array<Maybe<ExchangeRatesNode>>>;
  exemption?: Maybe<Scalars["Boolean"]>;
  exhibition?: Maybe<ExhibitionNode>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  hasValidCertificate?: Maybe<Scalars["Boolean"]>;
  hasVariableBasket?: Maybe<Scalars["Boolean"]>;
  history?: Maybe<Array<Maybe<HistoryListItemNode>>>;
  /** The ID of the object */
  id: Scalars["ID"];
  insuranceCompany: RegistryNode;
  isExpired?: Maybe<Scalars["Boolean"]>;
  issuedAt?: Maybe<Scalars["DateTime"]>;
  modifiedStatus?: Maybe<Scalars["String"]>;
  offerCode: Scalars["String"];
  policyAssets: PolicyAssetNodeConnection;
  policyAssetsHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyCode: Scalars["String"];
  policyDocuments: PolicyDocumentNodeConnection;
  policyEvents: PolicyEventsNodeConnection;
  policyNotes: PolicyNoteNodeConnection;
  policyStatus: Scalars["String"];
  policyType: Scalars["String"];
  policybaskethistorySet: PolicyBasketHistoryNodeConnection;
  searchString: Scalars["String"];
  sharedWith?: Maybe<Array<Scalars["Int"]>>;
  sharedWithInfo?: Maybe<Array<Maybe<BasicInfoNode>>>;
  totalInsuredPremium?: Maybe<Money>;
  totalInsuredPremiumCurrency: Scalars["String"];
  totalInsuredValue?: Maybe<Money>;
  totalInsuredValueCurrency: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type PolicyNodeAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyAssetsHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyNotesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicybaskethistorySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodeConnection = {
  __typename?: "PolicyNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `PolicyNode` and its cursor. */
export type PolicyNodeEdge = {
  __typename?: "PolicyNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyNode>;
};

export type PolicyNoteInput = {
  content?: InputMaybe<Scalars["String"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type PolicyNoteNode = Node & {
  __typename?: "PolicyNoteNode";
  content?: Maybe<Scalars["String"]>;
  createdBy: UserType;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  policy: PolicyNode;
  uuid: Scalars["UUID"];
};

export type PolicyNoteNodeConnection = {
  __typename?: "PolicyNoteNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyNoteNode` and its cursor. */
export type PolicyNoteNodeEdge = {
  __typename?: "PolicyNoteNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyNoteNode>;
};

export type Query = {
  __typename?: "Query";
  address?: Maybe<AddressNode>;
  allAddresses?: Maybe<AddressNodeConnection>;
  allAddressesAssetLocation?: Maybe<AddressNodeConnection>;
  allAppraisals?: Maybe<AppraisalNodeConnection>;
  allAssetDocumentAttachments?: Maybe<AssetDocumentAttachmentNodeConnection>;
  allAssetDocuments?: Maybe<AssetDocumentNodeConnection>;
  allAssetElements?: Maybe<AssetElementNodeConnection>;
  allAssetPredictions?: Maybe<AssetPredictionNodeConnection>;
  allAssetPresentValues?: Maybe<AssetPresentValueNodeConnection>;
  allAssets?: Maybe<AssetNodeConnection>;
  allAssetsForUser?: Maybe<AssetNodeConnection>;
  allCertificateTemplates?: Maybe<CertificateTemplateNodeConnection>;
  allCertificates?: Maybe<CertificateNodeConnection>;
  allClient?: Maybe<ClientNodeConnection>;
  allCollections?: Maybe<CollectionNodeConnection>;
  allCompanyContacts?: Maybe<CompanyContactNodeConnection>;
  allConditionStatusReports?: Maybe<ConditionStatusReportNodeConnection>;
  allContenttypes?: Maybe<ContentTypeNodeConnection>;
  allDeliveryDetails?: Maybe<DeliveryDetailsNodeConnection>;
  allDepositationAssets?: Maybe<DepositationAssetNodeConnection>;
  allDepositations?: Maybe<DepositationNodeConnection>;
  allEventLogs?: Maybe<EventLogNodeConnection>;
  allExchangeRates?: Maybe<ExchangeRatesNodeConnection>;
  allExhibitions?: Maybe<ExhibitionNodeConnection>;
  allExits?: Maybe<ExitNodeConnection>;
  allExportedDocuments?: Maybe<ExportDocumentsNodeConnection>;
  allInsurances?: Maybe<InsuranceNodeConnection>;
  allMedias?: Maybe<MediaNodeConnection>;
  allOffices?: Maybe<OfficeNodeConnection>;
  allPermissions?: Maybe<PermissionNodeConnection>;
  allPickupDetails?: Maybe<PickupDetailsNodeConnection>;
  allPolicies?: Maybe<PolicyNodeConnection>;
  allPolicyAssetBasketHistories?: Maybe<PolicyAssetBasketHistoryNodeConnection>;
  allPolicyAssets?: Maybe<PolicyAssetNodeConnection>;
  allPolicyBasketHistories?: Maybe<PolicyBasketHistoryNodeConnection>;
  allPolicyDocuments?: Maybe<PolicyDocumentNodeConnection>;
  allPolicyNotes?: Maybe<PolicyNoteNodeConnection>;
  allRegistries?: Maybe<RegistryNodeConnection>;
  allRegistriesWithDeleted?: Maybe<RegistryNodeConnection>;
  allRestorations?: Maybe<RestorationNodeConnection>;
  allTransportationAssets?: Maybe<TransportationAssetNodeConnection>;
  allTransportations?: Maybe<TransportationNodeConnection>;
  allUsers?: Maybe<UserNodeConnection>;
  appraisal?: Maybe<AppraisalNode>;
  appraisals?: Maybe<Array<Maybe<AppraisalNode>>>;
  asset?: Maybe<AssetNode>;
  assetDocument?: Maybe<AssetDocumentNode>;
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  assetDocumentAttachments?: Maybe<Array<Maybe<AssetDocumentAttachmentNode>>>;
  assetElement?: Maybe<AssetElementNode>;
  assetPrediction?: Maybe<AssetPredictionNode>;
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  assetsInsuranceStatus?: Maybe<Array<Maybe<AssetInsuranceStatus>>>;
  assetsSlogStatus?: Maybe<Array<Maybe<AssetSlogStatus>>>;
  certificate?: Maybe<CertificateNode>;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  client?: Maybe<ClientNode>;
  collection?: Maybe<CollectionNode>;
  companyContact?: Maybe<CompanyContactNode>;
  companyContacts?: Maybe<Array<Maybe<CompanyContactNode>>>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
  conditionStatusReports?: Maybe<Array<Maybe<ConditionStatusReportNode>>>;
  contenttype?: Maybe<ContentTypeNode>;
  createAppraisal?: Maybe<CreateAppraisalPayload>;
  createConditionStatusReport?: Maybe<CreateConditionStatusReportPayload>;
  createRestoration?: Maybe<CreateRestorationPayload>;
  deliveryDetails?: Maybe<DeliveryDetailsNode>;
  depositation?: Maybe<DepositationNode>;
  depositationAsset?: Maybe<DepositationAssetNode>;
  eventLog?: Maybe<EventLogNode>;
  exchangeRate?: Maybe<ExchangeRatesNode>;
  exhibition?: Maybe<ExhibitionNode>;
  exit?: Maybe<ExitNode>;
  filteredInsurances?: Maybe<FilteredInsuranceNodeConnection>;
  insurance?: Maybe<InsuranceNode>;
  insurances?: Maybe<Array<Maybe<InsuranceNode>>>;
  latestDepositationDocumentsExports?: Maybe<Array<Maybe<ExportDocumentsNode>>>;
  latestTransportationDocumentsExports?: Maybe<Array<Maybe<ExportDocumentsNode>>>;
  linkedAssetRegistries?: Maybe<LinkedAssetRegistryNode>;
  media?: Maybe<MediaNode>;
  medias?: Maybe<Array<Maybe<MediaNode>>>;
  notUserAssets?: Maybe<AssetNodeConnection>;
  office?: Maybe<OfficeNode>;
  offices?: Maybe<Array<Maybe<OfficeNode>>>;
  permission?: Maybe<PermissionNode>;
  pickupDetails?: Maybe<PickupDetailsNode>;
  policy?: Maybe<PolicyNode>;
  policyAsset?: Maybe<PolicyAssetNode>;
  policyAssetBasketHistory?: Maybe<PolicyAssetBasketHistoryNode>;
  policyAssetGroups?: Maybe<GroupsNode>;
  policyBasketHistory?: Maybe<PolicyBasketHistoryNode>;
  policyDocument?: Maybe<PolicyDocumentNode>;
  policyNote?: Maybe<PolicyNoteNode>;
  portfolioDistribution?: Maybe<Array<Maybe<Distribution>>>;
  registries?: Maybe<Array<Maybe<RegistryNode>>>;
  registry?: Maybe<RegistryNode>;
  restoration?: Maybe<RestorationNode>;
  restorations?: Maybe<Array<Maybe<RestorationNode>>>;
  searchAssets?: Maybe<AssetNodeConnection>;
  stateTechniqueMatrix?: Maybe<Array<Maybe<StateTechniqueMatrixObject>>>;
  transportation?: Maybe<TransportationNode>;
  transportationAssets?: Maybe<TransportationAssetNode>;
  user?: Maybe<UserNode>;
};

export type QueryAddressArgs = {
  id: Scalars["ID"];
};

export type QueryAllAddressesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  raw_Icontains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAddressesAssetLocationArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  raw_Icontains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAppraisalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appraisalMotivation?: InputMaybe<Scalars["String"]>;
  appraisalMotivation_Contains?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetDocumentAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  document?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetPredictionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  jobUuid?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryAllAssetPresentValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  category_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  subcategory_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
  validated?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryAllAssetsForUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  category_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  subcategory_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
  validated?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryAllCertificateTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  templateType?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCertificatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  emissionDate?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grouping?: InputMaybe<Scalars["String"]>;
  hasImages?: InputMaybe<Scalars["Boolean"]>;
  isSplitted?: InputMaybe<Scalars["Boolean"]>;
  isValid?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policyAsset?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  printTemplate?: InputMaybe<Scalars["ID"]>;
  progressiveNumber?: InputMaybe<Scalars["Int"]>;
  signed?: InputMaybe<Scalars["Boolean"]>;
  signedAt?: InputMaybe<Scalars["DateTime"]>;
  signedBy?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllClientArgs = {
  after?: InputMaybe<Scalars["String"]>;
  backgroundColor?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdOn?: InputMaybe<Scalars["Date"]>;
  currency?: InputMaybe<Scalars["String"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  dashboardBaseUrl?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  isInMaintenance?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onTrial?: InputMaybe<Scalars["Boolean"]>;
  paidUntil?: InputMaybe<Scalars["Date"]>;
  schemaName?: InputMaybe<Scalars["String"]>;
  storageName?: InputMaybe<Scalars["String"]>;
  tier?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  usedInvites?: InputMaybe<Scalars["Int"]>;
};

export type QueryAllCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  createdBy_Id?: InputMaybe<Scalars["Float"]>;
  createdBy_Name?: InputMaybe<Scalars["String"]>;
  createdFrom?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCompanyContactsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  companyContact?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  main?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  relatedCompany?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllConditionStatusReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  reportMotivation?: InputMaybe<Scalars["String"]>;
  reportMotivation_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllContenttypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appLabel?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  model?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllDeliveryDetailsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllDepositationAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depositationType?: InputMaybe<Scalars["String"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  isDepositationAssetExpired?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  showDepositationInventory?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type QueryAllDepositationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  depositationType?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isDepositationExpired?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllEventLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  eventType?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllExchangeRatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  baseCurrency?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  currency?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  objectId?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  value?: InputMaybe<Scalars["Float"]>;
};

export type QueryAllExhibitionsArgs = {
  actualTimingStatus?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  createdBy_Name?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryAllExitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllExportedDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  isSigned?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  modelName?: InputMaybe<Scalars["String"]>;
  modelUpdatedAt?: InputMaybe<Scalars["DateTime"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  typeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllInsurancesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllMediasArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllOfficesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  registry?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  codename?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllPickupDetailsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPoliciesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  assets?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  collection?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntity?: InputMaybe<Scalars["ID"]>;
  eventFrom?: InputMaybe<Scalars["DateTime"]>;
  eventLocationEntity?: InputMaybe<Scalars["ID"]>;
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["DateTime"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  exhibition?: InputMaybe<Scalars["ID"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  expiresAt_Gt?: InputMaybe<Scalars["DateTime"]>;
  expiresAt_Lt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  hasValidCertificate?: InputMaybe<Scalars["Boolean"]>;
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  isExpired?: InputMaybe<Scalars["String"]>;
  isExpiredAfterDate?: InputMaybe<Scalars["Date"]>;
  isModifiedAfterDate?: InputMaybe<Scalars["String"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offerCode?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyCode?: InputMaybe<Scalars["String"]>;
  policyStatus?: InputMaybe<Scalars["String"]>;
  policyType?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  totalInsuredPremium?: InputMaybe<Scalars["String"]>;
  totalInsuredPremiumCurrency?: InputMaybe<Scalars["String"]>;
  totalInsuredValue?: InputMaybe<Scalars["String"]>;
  totalInsuredValueCurrency?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyAssetBasketHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  deliveryAddress?: InputMaybe<Scalars["ID"]>;
  diff?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  insuredValue?: InputMaybe<Scalars["String"]>;
  insuredValueCurrency?: InputMaybe<Scalars["String"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lender?: InputMaybe<Scalars["ID"]>;
  lenderAddress?: InputMaybe<Scalars["ID"]>;
  objectLocationEntity?: InputMaybe<Scalars["ID"]>;
  objectLocationOffice?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupAddress?: InputMaybe<Scalars["ID"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policyAsset?: InputMaybe<Scalars["ID"]>;
  policyBasketHistory?: InputMaybe<Scalars["ID"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  deliveryAddress?: InputMaybe<Scalars["ID"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  insuredValue?: InputMaybe<Scalars["String"]>;
  insuredValueCurrency?: InputMaybe<Scalars["String"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lender?: InputMaybe<Scalars["ID"]>;
  lenderAddress?: InputMaybe<Scalars["ID"]>;
  objectLocationEntity?: InputMaybe<Scalars["ID"]>;
  objectLocationOffice?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  pickupAddress?: InputMaybe<Scalars["ID"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policy_PolicyType?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyBasketHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  reason?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllPolicyNotesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllRegistriesArgs = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["String"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Scalars["String"]>;
  categories_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  deathDate?: InputMaybe<Scalars["String"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  invoiceAddress?: InputMaybe<Scalars["ID"]>;
  isAnonymousTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isDepositationCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isIrrelevantTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  secondaryEmail?: InputMaybe<Scalars["String"]>;
  secondaryMobilePhone?: InputMaybe<Scalars["String"]>;
  secondaryPhone?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  tertiaryEmail?: InputMaybe<Scalars["String"]>;
  tertiaryMobilePhone?: InputMaybe<Scalars["String"]>;
  tertiaryPhone?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type QueryAllRegistriesWithDeletedArgs = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["String"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Scalars["String"]>;
  categories_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  deathDate?: InputMaybe<Scalars["String"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  invoiceAddress?: InputMaybe<Scalars["ID"]>;
  isAnonymousTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isDepositationCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isIrrelevantTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  secondaryEmail?: InputMaybe<Scalars["String"]>;
  secondaryMobilePhone?: InputMaybe<Scalars["String"]>;
  secondaryPhone?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  tertiaryEmail?: InputMaybe<Scalars["String"]>;
  tertiaryMobilePhone?: InputMaybe<Scalars["String"]>;
  tertiaryPhone?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type QueryAllRestorationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  restorationRestoredBy?: InputMaybe<Scalars["String"]>;
  restorationRestoredBy_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllTransportationAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  isTransportationAssetExpired?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  package?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  showTransportationInventory?: InputMaybe<Scalars["Boolean"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllTransportationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  isTransportationExpired?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  currency?: InputMaybe<Scalars["String"]>;
  dateJoined?: InputMaybe<Scalars["DateTime"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  email?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groups?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isMaster?: InputMaybe<Scalars["Boolean"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lastLogin?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
  resetTokenExpiry?: InputMaybe<Scalars["DateTime"]>;
  search?: InputMaybe<Scalars["String"]>;
  timesRenewed?: InputMaybe<Scalars["Int"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  usedInvites?: InputMaybe<Scalars["Int"]>;
  userPermissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  userType?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type QueryAppraisalArgs = {
  id: Scalars["ID"];
};

export type QueryAppraisalsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryAssetArgs = {
  id: Scalars["ID"];
};

export type QueryAssetDocumentArgs = {
  id: Scalars["ID"];
};

export type QueryAssetDocumentAttachmentArgs = {
  id: Scalars["ID"];
};

export type QueryAssetDocumentAttachmentsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryAssetElementArgs = {
  id: Scalars["ID"];
};

export type QueryAssetPredictionArgs = {
  id: Scalars["ID"];
  jobUuid?: InputMaybe<Scalars["String"]>;
};

export type QueryAssetPresentValueArgs = {
  id: Scalars["ID"];
};

export type QueryCertificateArgs = {
  id: Scalars["ID"];
};

export type QueryCertificateTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryClientArgs = {
  id: Scalars["ID"];
};

export type QueryCollectionArgs = {
  id: Scalars["ID"];
  token?: InputMaybe<Scalars["String"]>;
};

export type QueryCompanyContactArgs = {
  id: Scalars["ID"];
};

export type QueryCompanyContactsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  registryId?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryConditionStatusReportArgs = {
  id: Scalars["ID"];
};

export type QueryConditionStatusReportsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryContenttypeArgs = {
  id: Scalars["ID"];
};

export type QueryCreateAppraisalArgs = {
  input: CreateAppraisalInput;
};

export type QueryCreateConditionStatusReportArgs = {
  input: CreateConditionStatusReportInput;
};

export type QueryCreateRestorationArgs = {
  input: CreateRestorationInput;
};

export type QueryDeliveryDetailsArgs = {
  id: Scalars["ID"];
};

export type QueryDepositationArgs = {
  id: Scalars["ID"];
};

export type QueryDepositationAssetArgs = {
  id: Scalars["ID"];
};

export type QueryEventLogArgs = {
  id: Scalars["ID"];
};

export type QueryExchangeRateArgs = {
  id: Scalars["ID"];
};

export type QueryExhibitionArgs = {
  id: Scalars["ID"];
};

export type QueryExitArgs = {
  id: Scalars["ID"];
};

export type QueryFilteredInsurancesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type QueryInsuranceArgs = {
  id: Scalars["ID"];
};

export type QueryInsurancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryLatestDepositationDocumentsExportsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryLatestTransportationDocumentsExportsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryLinkedAssetRegistriesArgs = {
  id: Scalars["ID"];
};

export type QueryMediaArgs = {
  id: Scalars["ID"];
};

export type QueryMediasArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryNotUserAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  category_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  subcategory_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
  validated?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryOfficeArgs = {
  id: Scalars["ID"];
};

export type QueryOfficesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  registryId?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryPermissionArgs = {
  id: Scalars["ID"];
};

export type QueryPickupDetailsArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetBasketHistoryArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetGroupsArgs = {
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type QueryPolicyBasketHistoryArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyDocumentArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyNoteArgs = {
  id: Scalars["ID"];
};

export type QueryRegistriesArgs = {
  exactSearch?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryRegistryArgs = {
  id: Scalars["ID"];
};

export type QueryRestorationArgs = {
  id: Scalars["ID"];
};

export type QueryRestorationsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QuerySearchAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  category_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  subcategory_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
  validated?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryStateTechniqueMatrixArgs = {
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type QueryTransportationArgs = {
  id: Scalars["ID"];
};

export type QueryTransportationAssetsArgs = {
  id: Scalars["ID"];
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type Refresh = {
  __typename?: "Refresh";
  payload: Scalars["GenericScalar"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  token: Scalars["String"];
};

export type RegistryAssetCsvImportNode = Node & {
  __typename?: "RegistryAssetCsvImportNode";
  activityPeriod?: Maybe<Scalars["String"]>;
  activityPlace?: Maybe<Scalars["String"]>;
  actualCompanyContactsNumber?: Maybe<Scalars["String"]>;
  alias?: Maybe<Scalars["String"]>;
  appraisalEvaluator: AppraisalNodeConnection;
  artisticMovement?: Maybe<Scalars["String"]>;
  assetAuthor: AssetNodeConnection;
  assetManufacturer: AssetNodeConnection;
  assetOwner: AssetNodeConnection;
  assetPresentValueAuthor: AssetPresentValueNodeConnection;
  assetPrinter: AssetNodeConnection;
  assetProducer: AssetNodeConnection;
  assetSet: AssetNodeConnection;
  assetVideomaker: AssetNodeConnection;
  authorType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  authoredAssetsCount?: Maybe<Scalars["Int"]>;
  billingAddress?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["String"]>;
  birthPlace?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  code?: Maybe<Scalars["String"]>;
  company?: Maybe<RegistryNode>;
  companyContact: CompanyContactNodeConnection;
  conditionstatusreportExhibitionCurator: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionHostEntity: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionLender: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionPlanner: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsCarrier: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsRecipient: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsSender: ConditionStatusReportNodeConnection;
  conditionstatusreportReportCompany: ConditionStatusReportNodeConnection;
  conditionstatusreportReportExaminer: ConditionStatusReportNodeConnection;
  confidentialNotes?: Maybe<Scalars["String"]>;
  contactDescription?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deathDate?: Maybe<Scalars["String"]>;
  deathPlace?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliverydetailsSet: DeliveryDetailsNodeConnection;
  depositationBroker: DepositationNodeConnection;
  depositationCompany: DepositationNodeConnection;
  depositationContractingParty: DepositationNodeConnection;
  depositationCourier: DepositationNodeConnection;
  depositationCustomer: DepositationNodeConnection;
  depositationInsuranceCompany: DepositationNodeConnection;
  depositationassetsAppointee: DepositationAssetNodeConnection;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  eventlogBroker: EventLogNodeConnection;
  eventlogHost: EventLogNodeConnection;
  eventlogNewOwner: EventLogNodeConnection;
  eventlogOrigin: EventLogNodeConnection;
  eventlogOwner: EventLogNodeConnection;
  exhibitionCurator: ExhibitionNodeConnection;
  exhibitionOrganizer: ExhibitionNodeConnection;
  exitAppointee: ExitNodeConnection;
  exitExitCompany: ExitNodeConnection;
  foundation?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  hostEntity: ExhibitionNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  invoiceAddress?: Maybe<Address>;
  isAnonymousTenantDefault?: Maybe<Scalars["Boolean"]>;
  isAuthor?: Maybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: Maybe<Scalars["Boolean"]>;
  isCompany: Scalars["Boolean"];
  isDepositationCompanyTenantDefault?: Maybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: Maybe<Scalars["Boolean"]>;
  isIrrelevantTenantDefault?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: Maybe<Scalars["Boolean"]>;
  linkedassetregistriesSet: LinkedAssetRegistryNodeConnection;
  logo?: Maybe<Scalars["String"]>;
  mainOfficeAddress?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  objectLocationEntity: PolicyAssetNodeConnection;
  objectLocationEntityHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeRegistry: OfficeNodeConnection;
  officesCount?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  pickupdetailsSender: PickupDetailsNodeConnection;
  policyAssetLender: PolicyAssetNodeConnection;
  policyAssetLenderHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyAssetShipper: PolicyAssetNodeConnection;
  policyAssetShipperHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyBroker: PolicyNodeConnection;
  policyContractingParty: PolicyNodeConnection;
  policyEventEntity: PolicyNodeConnection;
  policyInsuranceCompany: PolicyNodeConnection;
  printCode?: Maybe<Scalars["String"]>;
  printName?: Maybe<Scalars["String"]>;
  registryCompany: RegistryNodeConnection;
  relatedCompany: CompanyContactNodeConnection;
  restorationCompany: RestorationNodeConnection;
  restorationDirector: RestorationNodeConnection;
  role?: Maybe<Scalars["String"]>;
  searchString?: Maybe<Scalars["String"]>;
  secondaryEmail?: Maybe<Scalars["String"]>;
  secondaryMobilePhone?: Maybe<Scalars["String"]>;
  secondaryPhone?: Maybe<Scalars["String"]>;
  taxId?: Maybe<Scalars["String"]>;
  tertiaryEmail?: Maybe<Scalars["String"]>;
  tertiaryMobilePhone?: Maybe<Scalars["String"]>;
  tertiaryPhone?: Maybe<Scalars["String"]>;
  transportationBroker: TransportationNodeConnection;
  transportationContractingParty: TransportationNodeConnection;
  transportationCustomer: TransportationNodeConnection;
  transportationInsuranceCompany: TransportationNodeConnection;
  transportationShipper: TransportationNodeConnection;
  transportationVector: TransportationNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  webSite?: Maybe<Scalars["String"]>;
};

export type RegistryAssetCsvImportNodeAppraisalEvaluatorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetManufacturerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetPresentValueAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetPrinterArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetProducerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeAssetVideomakerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeCompanyContactArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportExhibitionCuratorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportExhibitionHostEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportExhibitionLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportExhibitionPlannerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportLogisticsCarrierArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportLogisticsRecipientArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportLogisticsSenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportReportCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeConditionstatusreportReportExaminerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeDeliverydetailsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationCourierArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationCustomerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeDepositationassetsAppointeeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type RegistryAssetCsvImportNodeEventlogBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeEventlogHostArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeEventlogNewOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeEventlogOriginArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeEventlogOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeExhibitionCuratorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeExhibitionOrganizerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeExitAppointeeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeExitExitCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodeHostEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeLinkedassetregistriesSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeObjectLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeObjectLocationEntityHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeOfficeRegistryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePickupdetailsSenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryAssetCsvImportNodePolicyAssetLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyAssetLenderHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyAssetShipperArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyAssetShipperHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyEventEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodePolicyInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeRegistryCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeRelatedCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeRestorationCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeRestorationDirectorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryAssetCsvImportNodeTransportationBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryAssetCsvImportNodeTransportationContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryAssetCsvImportNodeTransportationCustomerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryAssetCsvImportNodeTransportationInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryAssetCsvImportNodeTransportationShipperArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryAssetCsvImportNodeTransportationVectorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryAssetsNode = {
  __typename?: "RegistryAssetsNode";
  asset?: Maybe<AssetNode>;
  id?: Maybe<Scalars["ID"]>;
  linkedActualId?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  type?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type RegistryInput = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["String"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categoriesCompany?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categoriesPerson?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  deathDate?: InputMaybe<Scalars["String"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName: Scalars["String"];
  invoiceAddress?: InputMaybe<AddressInput>;
  isAnonymousTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isIrrelevantTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  mainContact?: InputMaybe<Scalars["Boolean"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  officeAddress?: InputMaybe<AddressInput>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  secondaryEmail?: InputMaybe<Scalars["String"]>;
  secondaryMobilePhone?: InputMaybe<Scalars["String"]>;
  secondaryPhone?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  tertiaryEmail?: InputMaybe<Scalars["String"]>;
  tertiaryMobilePhone?: InputMaybe<Scalars["String"]>;
  tertiaryPhone?: InputMaybe<Scalars["String"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type RegistryNode = Node & {
  __typename?: "RegistryNode";
  activityPeriod?: Maybe<Scalars["String"]>;
  activityPlace?: Maybe<Scalars["String"]>;
  actualCompanyContactsNumber?: Maybe<Scalars["String"]>;
  alias?: Maybe<Scalars["String"]>;
  appraisalEvaluator: AppraisalNodeConnection;
  artisticMovement?: Maybe<Scalars["String"]>;
  assetAuthor: AssetNodeConnection;
  assetManufacturer: AssetNodeConnection;
  assetOwner: AssetNodeConnection;
  assetPresentValueAuthor: AssetPresentValueNodeConnection;
  assetPrinter: AssetNodeConnection;
  assetProducer: AssetNodeConnection;
  assetSet: AssetNodeConnection;
  assetVideomaker: AssetNodeConnection;
  authorType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  authoredAssetsCount?: Maybe<Scalars["Int"]>;
  billingAddress?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["String"]>;
  birthPlace?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  code?: Maybe<Scalars["String"]>;
  company?: Maybe<RegistryNode>;
  companyContact: CompanyContactNodeConnection;
  conditionstatusreportExhibitionCurator: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionHostEntity: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionLender: ConditionStatusReportNodeConnection;
  conditionstatusreportExhibitionPlanner: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsCarrier: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsRecipient: ConditionStatusReportNodeConnection;
  conditionstatusreportLogisticsSender: ConditionStatusReportNodeConnection;
  conditionstatusreportReportCompany: ConditionStatusReportNodeConnection;
  conditionstatusreportReportExaminer: ConditionStatusReportNodeConnection;
  confidentialNotes?: Maybe<Scalars["String"]>;
  contactDescription?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deathDate?: Maybe<Scalars["String"]>;
  deathPlace?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliverydetailsSet: DeliveryDetailsNodeConnection;
  depositationBroker: DepositationNodeConnection;
  depositationCompany: DepositationNodeConnection;
  depositationContractingParty: DepositationNodeConnection;
  depositationCourier: DepositationNodeConnection;
  depositationCustomer: DepositationNodeConnection;
  depositationInsuranceCompany: DepositationNodeConnection;
  depositationassetsAppointee: DepositationAssetNodeConnection;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  eventlogBroker: EventLogNodeConnection;
  eventlogHost: EventLogNodeConnection;
  eventlogNewOwner: EventLogNodeConnection;
  eventlogOrigin: EventLogNodeConnection;
  eventlogOwner: EventLogNodeConnection;
  exhibitionCurator: ExhibitionNodeConnection;
  exhibitionOrganizer: ExhibitionNodeConnection;
  exitAppointee: ExitNodeConnection;
  exitExitCompany: ExitNodeConnection;
  foundation?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  hostEntity: ExhibitionNodeConnection;
  /** The ID of the object */
  id: Scalars["ID"];
  invoiceAddress?: Maybe<Address>;
  isAnonymousTenantDefault?: Maybe<Scalars["Boolean"]>;
  isAuthor?: Maybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: Maybe<Scalars["Boolean"]>;
  isCompany: Scalars["Boolean"];
  isDepositationCompanyTenantDefault?: Maybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: Maybe<Scalars["Boolean"]>;
  isIrrelevantTenantDefault?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  isUnknownTenantDefault?: Maybe<Scalars["Boolean"]>;
  linkedassetregistriesSet: LinkedAssetRegistryNodeConnection;
  logo?: Maybe<Scalars["String"]>;
  mainOfficeAddress?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  objectLocationEntity: PolicyAssetNodeConnection;
  objectLocationEntityHistoric: PolicyAssetBasketHistoryNodeConnection;
  officeRegistry: OfficeNodeConnection;
  officesCount?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  pickupdetailsSender: PickupDetailsNodeConnection;
  policyAssetLender: PolicyAssetNodeConnection;
  policyAssetLenderHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyAssetShipper: PolicyAssetNodeConnection;
  policyAssetShipperHistoric: PolicyAssetBasketHistoryNodeConnection;
  policyBroker: PolicyNodeConnection;
  policyContractingParty: PolicyNodeConnection;
  policyEventEntity: PolicyNodeConnection;
  policyInsuranceCompany: PolicyNodeConnection;
  printCode?: Maybe<Scalars["String"]>;
  printName?: Maybe<Scalars["String"]>;
  registryAssets?: Maybe<Array<Maybe<RegistryAssetsNode>>>;
  registryCompany: RegistryNodeConnection;
  relatedCompany: CompanyContactNodeConnection;
  restorationCompany: RestorationNodeConnection;
  restorationDirector: RestorationNodeConnection;
  role?: Maybe<Scalars["String"]>;
  searchString?: Maybe<Scalars["String"]>;
  secondaryEmail?: Maybe<Scalars["String"]>;
  secondaryMobilePhone?: Maybe<Scalars["String"]>;
  secondaryPhone?: Maybe<Scalars["String"]>;
  taxId?: Maybe<Scalars["String"]>;
  tertiaryEmail?: Maybe<Scalars["String"]>;
  tertiaryMobilePhone?: Maybe<Scalars["String"]>;
  tertiaryPhone?: Maybe<Scalars["String"]>;
  transportationBroker: TransportationNodeConnection;
  transportationContractingParty: TransportationNodeConnection;
  transportationCustomer: TransportationNodeConnection;
  transportationInsuranceCompany: TransportationNodeConnection;
  transportationShipper: TransportationNodeConnection;
  transportationVector: TransportationNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  webSite?: Maybe<Scalars["String"]>;
};

export type RegistryNodeAppraisalEvaluatorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetManufacturerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetPresentValueAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetPrinterArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetProducerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetVideomakerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeCompanyContactArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionCuratorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionHostEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportExhibitionPlannerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportLogisticsCarrierArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportLogisticsRecipientArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportLogisticsSenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportReportCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConditionstatusreportReportExaminerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeDeliverydetailsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationCourierArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationCustomerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeDepositationassetsAppointeeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type RegistryNodeEventlogBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogHostArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogNewOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogOriginArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeEventlogOwnerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeExhibitionCuratorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeExhibitionOrganizerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeExitAppointeeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeExitExitCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodeHostEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeLinkedassetregistriesSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeObjectLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeObjectLocationEntityHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeOfficeRegistryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePickupdetailsSenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type RegistryNodePolicyAssetLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetLenderHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetShipperArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetShipperHistoricArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyEventEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRegistryAssetsArgs = {
  type?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type RegistryNodeRegistryCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRelatedCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRestorationCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRestorationDirectorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeTransportationBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryNodeTransportationContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryNodeTransportationCustomerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryNodeTransportationInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryNodeTransportationShipperArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryNodeTransportationVectorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type RegistryNodeConnection = {
  __typename?: "RegistryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RegistryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `RegistryNode` and its cursor. */
export type RegistryNodeEdge = {
  __typename?: "RegistryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RegistryNode>;
};

export type RemoveTransportationAssetsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  transportationAssets?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  transportationId: Scalars["ID"];
};

export type RemoveTransportationAssetsPayload = {
  __typename?: "RemoveTransportationAssetsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type RemoveUserObjectPermissionInput = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type RemoveUserObjectPermissionPayload = {
  __typename?: "RemoveUserObjectPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type RenewPolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  totalInsuredPremium?: InputMaybe<MoneyInput>;
};

export type RenewPolicyPayload = {
  __typename?: "RenewPolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type RenewUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type RenewUserPayload = {
  __typename?: "RenewUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type RequestUserResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type RequestUserResetPasswordPayload = {
  __typename?: "RequestUserResetPasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ResetClientLoginInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type ResetClientLoginPayload = {
  __typename?: "ResetClientLoginPayload";
  client?: Maybe<ClientNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ResetUserPasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  confirmPassword?: InputMaybe<Scalars["String"]>;
  newPassword?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
};

export type ResetUserPasswordPayload = {
  __typename?: "ResetUserPasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type RestorationInput = {
  conservativeDataAssetAlterations?: InputMaybe<Scalars["String"]>;
  conservativeDataConservationStatus?: InputMaybe<Scalars["String"]>;
  conservativeDataDeteriorationCauses?: InputMaybe<Scalars["String"]>;
  conservativeDataExaminationMethod?: InputMaybe<Scalars["String"]>;
  conservativeDataHistoryInterventions?: InputMaybe<Scalars["String"]>;
  conservativeDataIntervention?: InputMaybe<Scalars["String"]>;
  conservativeDataInterventionMaterials?: InputMaybe<Scalars["String"]>;
  conservativeDataInterventionProposal?: InputMaybe<Scalars["String"]>;
  conservativeDataMaintenancePreparationAssembly?: InputMaybe<Scalars["String"]>;
  conservativeDataNotesRestorationSheet?: InputMaybe<Scalars["String"]>;
  conservativeDataPreliminaryOperations?: InputMaybe<Scalars["String"]>;
  conservativeDataRestorationBibliography?: InputMaybe<Scalars["String"]>;
  conservativeDataTechnicalMethodologicalConsiderations?: InputMaybe<Scalars["String"]>;
  restorationAsset?: InputMaybe<Scalars["ID"]>;
  restorationCollocationFeatures?: InputMaybe<Scalars["String"]>;
  restorationCompany?: InputMaybe<Scalars["ID"]>;
  restorationCost?: InputMaybe<MoneyInput>;
  restorationDirector: Scalars["ID"];
  restorationDocumentDate?: InputMaybe<Scalars["DateTime"]>;
  restorationEndDate?: InputMaybe<Scalars["DateTime"]>;
  restorationInventoryNumber?: InputMaybe<Scalars["String"]>;
  restorationLocation?: InputMaybe<Scalars["String"]>;
  restorationRestoredBy?: InputMaybe<Scalars["String"]>;
  restorationStartDate?: InputMaybe<Scalars["DateTime"]>;
  restorationStatus: Scalars["String"];
};

export type RestorationNode = Node & {
  __typename?: "RestorationNode";
  actualMediaStatus?: Maybe<Scalars["String"]>;
  conservativeDataAssetAlterations?: Maybe<Scalars["String"]>;
  conservativeDataConservationStatus?: Maybe<Scalars["String"]>;
  conservativeDataDeteriorationCauses?: Maybe<Scalars["String"]>;
  conservativeDataExaminationMethod?: Maybe<Scalars["String"]>;
  conservativeDataHistoryInterventions?: Maybe<Scalars["String"]>;
  conservativeDataIntervention?: Maybe<Scalars["String"]>;
  conservativeDataInterventionMaterials?: Maybe<Scalars["String"]>;
  conservativeDataInterventionProposal?: Maybe<Scalars["String"]>;
  conservativeDataMaintenancePreparationAssembly?: Maybe<Scalars["String"]>;
  conservativeDataNotesRestorationSheet?: Maybe<Scalars["String"]>;
  conservativeDataPreliminaryOperations?: Maybe<Scalars["String"]>;
  conservativeDataRestorationBibliography?: Maybe<Scalars["String"]>;
  conservativeDataTechnicalMethodologicalConsiderations?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  exportedDocument?: Maybe<ExportDocumentsNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  isSigned?: Maybe<Scalars["Boolean"]>;
  media: MediaNodeConnection;
  mediaNumber?: Maybe<Scalars["Int"]>;
  restorationAsset: AssetNode;
  restorationCollocationFeatures?: Maybe<Scalars["String"]>;
  restorationCompany?: Maybe<RegistryNode>;
  restorationCost?: Maybe<Money>;
  restorationCostCurrency: ManagementRestorationRestorationCostCurrencyChoices;
  restorationDirector: RegistryNode;
  restorationDocumentDate?: Maybe<Scalars["DateTime"]>;
  restorationEndDate?: Maybe<Scalars["DateTime"]>;
  restorationInventoryNumber?: Maybe<Scalars["String"]>;
  restorationLocation?: Maybe<Scalars["String"]>;
  restorationRestoredBy?: Maybe<Scalars["String"]>;
  restorationStartDate?: Maybe<Scalars["DateTime"]>;
  restorationStatus?: Maybe<Scalars["String"]>;
  signatureFile?: Maybe<Scalars["String"]>;
  signedBy?: Maybe<UserType>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type RestorationNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RestorationNodeConnection = {
  __typename?: "RestorationNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RestorationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `RestorationNode` and its cursor. */
export type RestorationNodeEdge = {
  __typename?: "RestorationNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RestorationNode>;
};

export type RevertPolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  historyId?: InputMaybe<Scalars["Int"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type RevertPolicyPayload = {
  __typename?: "RevertPolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  phrase?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type Revoke = {
  __typename?: "Revoke";
  revoked: Scalars["Int"];
};

export type SetMainSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  signatureId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type SetMainSignaturePayload = {
  __typename?: "SetMainSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSignature?: Maybe<UserSignatureType>;
};

export type SetPrivateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type SetPrivateRegistryPayload = {
  __typename?: "SetPrivateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type SetPublicRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type SetPublicRegistryPayload = {
  __typename?: "SetPublicRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type SharePolicyByEmailInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emails: Array<InputMaybe<Scalars["String"]>>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  notes?: InputMaybe<Scalars["String"]>;
  policyId: Scalars["ID"];
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type SharePolicyByEmailPayload = {
  __typename?: "SharePolicyByEmailPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type SharePolicyByUsersInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyId: Scalars["ID"];
  usersIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type SharePolicyByUsersPayload = {
  __typename?: "SharePolicyByUsersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type SignAppraisalInput = {
  appraisalId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type SignAppraisalPayload = {
  __typename?: "SignAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SignCertificateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type SignCertificatePayload = {
  __typename?: "SignCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type SignConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  crsrId: Scalars["ID"];
};

export type SignConditionStatusReportPayload = {
  __typename?: "SignConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  crsr?: Maybe<ConditionStatusReportNode>;
};

export type SignRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  restorationId: Scalars["ID"];
};

export type SignRestorationPayload = {
  __typename?: "SignRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type SignTransportationBillInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  transportationId?: InputMaybe<Scalars["ID"]>;
};

export type SignTransportationBillPayload = {
  __typename?: "SignTransportationBillPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportationBill?: Maybe<ExportDocumentsNode>;
};

export enum SlogDeliveryDetailsDeliveryTypeChoices {
  /** loading_on_street_level */
  LoadingOnStreetLevel = "LOADING_ON_STREET_LEVEL",
  /** load_at_the_door */
  LoadAtTheDoor = "LOAD_AT_THE_DOOR",
  /** Other */
  Other = "OTHER",
  /** white_gloves */
  WhiteGloves = "WHITE_GLOVES",
}

export enum SlogDepositationAreaTypeChoices {
  /** Other */
  Other = "OTHER",
  /** private */
  Private = "PRIVATE",
  /** private_closet */
  PrivateCloset = "PRIVATE_CLOSET",
  /** Appraisal date */
  PrivateCrate = "PRIVATE_CRATE",
  /** shared */
  Shared = "SHARED",
  /** shared_closet */
  SharedCloset = "SHARED_CLOSET",
  /** shared_crate */
  SharedCrate = "SHARED_CRATE",
}

export enum SlogDepositationAreaTypeDescriptionChoices {
  /** Good */
  AirConditioned = "AIR_CONDITIONED",
  /** alarmed */
  Alarmed = "ALARMED",
  /** controlled_light_and humidity */
  ControlledLightAndHumidity = "CONTROLLED_LIGHT_AND_HUMIDITY",
  /** Status Report */
  FreePort = "FREE_PORT",
  /** manned */
  Manned = "MANNED",
  /** under_video_surveillance */
  UnderVideoSurveillance = "UNDER_VIDEO_SURVEILLANCE",
}

export enum SlogDepositationCoverTypeChoices {
  /** Nail to nail */
  NailToNail = "NAIL_TO_NAIL",
  /** Stay Risk */
  StayRisk = "STAY_RISK",
  /** Travel Risk */
  TravelRisk = "TRAVEL_RISK",
}

export enum SlogDepositationEvaluationTypeChoices {
  /** Agreed value */
  AgreedValue = "AGREED_VALUE",
  /** Declared value */
  DeclaredValue = "DECLARED_VALUE",
}

export enum SlogDepositationInsuranceStatusChoices {
  /** Measured */
  Insured = "INSURED",
  /** Not measured */
  NotInsured = "NOT_INSURED",
}

export enum SlogDepositationRiskTypeChoices {
  /** All Risk */
  AllRisk = "ALL_RISK",
  /** First Risk */
  FirstRisk = "FIRST_RISK",
}

export enum SlogDepositationStatusChoices {
  /** Closed */
  Closed = "CLOSED",
  /** Draft */
  Draft = "DRAFT",
}

export enum SlogDepositationTypeChoices {
  /** long term */
  LongTerm = "LONG_TERM",
  /** short term */
  ShortTerm = "SHORT_TERM",
}

export enum SlogPackagesHardPackagingTypeChoices {
  /** cage */
  Cage = "CAGE",
  /** crate_with_guides */
  CrateWithGuides = "CRATE_WITH_GUIDES",
  /** crate_with_shaping */
  CrateWithShaping = "CRATE_WITH_SHAPING",
  /** double_crate */
  DoubleCrate = "DOUBLE_CRATE",
  /** Multiple no. */
  MultipleCrate = "MULTIPLE_CRATE",
  /** museum_air_conditioned_crate */
  MuseumAirConditionedCrate = "MUSEUM_AIR_CONDITIONED_CRATE",
  /** Other */
  Other = "OTHER",
  /** safe_box */
  SafeBox = "SAFE_BOX",
  /** simple_crate */
  SimpleCrate = "SIMPLE_CRATE",
  /** special_crate */
  SpecialCrate = "SPECIAL_CRATE",
  /** travel_frame_crate */
  TravelFrameCrate = "TRAVEL_FRAME_CRATE",
}

export enum SlogPackagesPackagingChoices {
  /** already_packed */
  AlreadyPacked = "ALREADY_PACKED",
  /** to_pack */
  ToPack = "TO_PACK",
  /** unfit_packaging_to_redo */
  UnfitPackagingToRedo = "UNFIT_PACKAGING_TO_REDO",
}

export enum SlogPickupDetailsPickupTypeChoices {
  /** loading_on_street_level */
  LoadingOnStreetLevel = "LOADING_ON_STREET_LEVEL",
  /** load_at_the_door */
  LoadAtTheDoor = "LOAD_AT_THE_DOOR",
  /** Other */
  Other = "OTHER",
  /** white_gloves */
  WhiteGloves = "WHITE_GLOVES",
}

export enum SlogTransportationCoverTypeChoices {
  /** Nail to nail */
  NailToNail = "NAIL_TO_NAIL",
  /** Stay Risk */
  StayRisk = "STAY_RISK",
  /** Travel Risk */
  TravelRisk = "TRAVEL_RISK",
}

export enum SlogTransportationEvaluationTypeChoices {
  /** Agreed value */
  AgreedValue = "AGREED_VALUE",
  /** Declared value */
  DeclaredValue = "DECLARED_VALUE",
}

export enum SlogTransportationInsuranceStatusChoices {
  /** Measured */
  Insured = "INSURED",
  /** Not measured */
  NotInsured = "NOT_INSURED",
}

export enum SlogTransportationRiskTypeChoices {
  /** All Risk */
  AllRisk = "ALL_RISK",
  /** First Risk */
  FirstRisk = "FIRST_RISK",
}

export enum SlogTransportationStatusChoices {
  /** Closed */
  Closed = "CLOSED",
  /** Draft */
  Draft = "DRAFT",
}

export enum SlogTransportationTransportationTypeChoices {
  /** Painter */
  Air = "AIR",
  /** Other */
  Other = "OTHER",
  /** rail */
  Rail = "RAIL",
  /** road */
  Road = "ROAD",
  /** sea */
  Sea = "SEA",
}

export enum SpinCertificateCertificateTypeChoices {
  /** Permanent Collection */
  PermanentCollection = "PERMANENT_COLLECTION",
  /** Temporary Exhibition */
  TemporaryExhibition = "TEMPORARY_EXHIBITION",
}

export enum SpinCertificateGroupingChoices {
  /** grouped */
  Grouped = "GROUPED",
  /** partial */
  Partial = "PARTIAL",
  /** splitted */
  Splitted = "SPLITTED",
  /** total */
  Total = "TOTAL",
}

export enum SpinPolicyAssetBasketHistoryCoverTypeChoices {
  /** Nail to nail */
  NailToNail = "NAIL_TO_NAIL",
  /** Stay Risk */
  StayRisk = "STAY_RISK",
  /** Travel Risk */
  TravelRisk = "TRAVEL_RISK",
}

export enum SpinPolicyAssetBasketHistoryCoveredRiskTypeChoices {
  /** All Risk */
  AllRisk = "ALL_RISK",
  /** First Risk */
  FirstRisk = "FIRST_RISK",
}

export enum SpinPolicyAssetBasketHistoryEvaluationTypeChoices {
  /** Agreed value */
  AgreedValue = "AGREED_VALUE",
  /** Declared value */
  DeclaredValue = "DECLARED_VALUE",
}

export enum SpinPolicyAssetBasketHistoryInsuredValueCurrencyChoices {
  /** Swiss Franc */
  Chf = "CHF",
  /** Chinese Yuan */
  Cny = "CNY",
  /** Euro */
  Eur = "EUR",
  /** British Pound */
  Gbp = "GBP",
  /** Japanese Yen */
  Jpy = "JPY",
  /** US Dollar */
  Usd = "USD",
}

export enum SpinPolicyAssetBasketHistoryStatusChoices {
  /** Added */
  Added = "ADDED",
  /** Initial */
  Initial = "INITIAL",
  /** Other */
  Other = "OTHER",
  /** Unchanged */
  Unchanged = "UNCHANGED",
  /** Updated */
  Updated = "UPDATED",
}

export enum SpinPolicyBasketHistoryStatusChoices {
  /** Add */
  Add = "ADD",
  /** Delete */
  Delete = "DELETE",
  /** Initial */
  Initial = "INITIAL",
  /** Other */
  Other = "OTHER",
  /** Update */
  Update = "UPDATE",
}

export enum SpinPolicyEventsActionChoices {
  /** add policy_asset */
  AddPolicyAsset = "ADD_POLICY_ASSET",
  /** certificate generation */
  CertificateGeneration = "CERTIFICATE_GENERATION",
  /** extension */
  Extension = "EXTENSION",
  /** manual invalidation certificate */
  ManualInvalidationCertificate = "MANUAL_INVALIDATION_CERTIFICATE",
  /** manual termination policy */
  ManualTerminationPolicy = "MANUAL_TERMINATION_POLICY",
  /** modified driver Policy */
  ModifiedDriverPolicy = "MODIFIED_DRIVER_POLICY",
  /** modified driver policy_asset */
  ModifiedDriverPolicyAsset = "MODIFIED_DRIVER_POLICY_ASSET",
  /** remove policy_asset */
  RemovePolicyAsset = "REMOVE_POLICY_ASSET",
  /** termination */
  Termination = "TERMINATION",
}

export type StartAssetPredictionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type StartAssetPredictionPayload = {
  __typename?: "StartAssetPredictionPayload";
  assetPrediction?: Maybe<AssetPredictionNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type StateNode = Node & {
  __typename?: "StateNode";
  code: Scalars["String"];
  country?: Maybe<CountryNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  localities: LocalityNodeConnection;
  name: Scalars["String"];
};

export type StateNodeLocalitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["ID"]>;
};

export type StateNodeConnection = {
  __typename?: "StateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StateNode` and its cursor. */
export type StateNodeEdge = {
  __typename?: "StateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<StateNode>;
};

export type StateTechniqueMatrixObject = {
  __typename?: "StateTechniqueMatrixObject";
  assetsNumber?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  sumAssetsValue?: Maybe<Money>;
  technique?: Maybe<Scalars["String"]>;
};

export type TerminatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
  totalInsuredPremium?: InputMaybe<MoneyInput>;
};

export type TerminatePolicyPayload = {
  __typename?: "TerminatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type ToggleUserIsActiveInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type ToggleUserIsActivePayload = {
  __typename?: "ToggleUserIsActivePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ToggleUserUserTypeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiration?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
};

export type ToggleUserUserTypePayload = {
  __typename?: "ToggleUserUserTypePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type TransportationAssetInput = {
  assetId?: InputMaybe<Scalars["ID"]>;
  package?: InputMaybe<PackagesInput>;
};

export type TransportationAssetNode = Node & {
  __typename?: "TransportationAssetNode";
  asset?: Maybe<AssetNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  /** The ID of the object */
  id: Scalars["ID"];
  package?: Maybe<PackagesNode>;
  parentType?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type TransportationAssetNodeConnection = {
  __typename?: "TransportationAssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransportationAssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `TransportationAssetNode` and its cursor. */
export type TransportationAssetNodeEdge = {
  __typename?: "TransportationAssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<TransportationAssetNode>;
};

export type TransportationInput = {
  assetSet?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  customerId?: InputMaybe<Scalars["ID"]>;
  deliveryCellularnumber?: InputMaybe<Scalars["String"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryDetails?: InputMaybe<DeliveryDetailsInput>;
  deliveryEmail?: InputMaybe<Scalars["String"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryTelephonenumber?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupCellularnumber?: InputMaybe<Scalars["String"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupDetails?: InputMaybe<PickupDetailsInput>;
  pickupEmail?: InputMaybe<Scalars["String"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupTelephonenumber?: InputMaybe<Scalars["String"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  shipperId?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationAssets?: InputMaybe<Array<InputMaybe<TransportationAssetInput>>>;
  transportationId?: InputMaybe<Scalars["String"]>;
  vectorId?: InputMaybe<Scalars["String"]>;
};

export type TransportationNode = Node & {
  __typename?: "TransportationNode";
  broker?: Maybe<RegistryNode>;
  contractingParty?: Maybe<RegistryNode>;
  controlledTemperature?: Maybe<Scalars["Boolean"]>;
  coverType?: Maybe<SlogTransportationCoverTypeChoices>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  customer?: Maybe<RegistryNode>;
  deliveryDatetime?: Maybe<Scalars["String"]>;
  deliveryDetails?: Maybe<DeliveryDetailsNode>;
  description?: Maybe<Scalars["String"]>;
  evaluationType?: Maybe<SlogTransportationEvaluationTypeChoices>;
  exportedDocument?: Maybe<ExportDocumentsNode>;
  filingNumber?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  instructions?: Maybe<Scalars["String"]>;
  insuranceCompany?: Maybe<RegistryNode>;
  insuranceDetails?: Maybe<Scalars["String"]>;
  insuranceNotes?: Maybe<Scalars["String"]>;
  insuranceStatus?: Maybe<SlogTransportationInsuranceStatusChoices>;
  insuredFrom?: Maybe<Scalars["DateTime"]>;
  insuredTo?: Maybe<Scalars["DateTime"]>;
  isConfirmed: Scalars["Boolean"];
  isInsured?: Maybe<Scalars["Boolean"]>;
  isModifiable?: Maybe<Scalars["Boolean"]>;
  media: MediaNodeConnection;
  notes?: Maybe<Scalars["String"]>;
  numberOfAssets?: Maybe<Scalars["Int"]>;
  numberOfMedia?: Maybe<Scalars["Int"]>;
  numberOfPackages?: Maybe<Scalars["Int"]>;
  otherCharacteristics?: Maybe<Scalars["String"]>;
  packageDescription?: Maybe<Scalars["String"]>;
  packageVolume?: Maybe<Scalars["Float"]>;
  packageVolumeInfo?: Maybe<Scalars["String"]>;
  packageVolumeUnit?: Maybe<Scalars["String"]>;
  packageWeight?: Maybe<Scalars["Float"]>;
  packageWeightInfo?: Maybe<Scalars["String"]>;
  packageWeightUnit?: Maybe<Scalars["String"]>;
  packagesSet: PackagesNodeConnection;
  pickupDatetime?: Maybe<Scalars["String"]>;
  pickupDetails?: Maybe<PickupDetailsNode>;
  requiredTemperature?: Maybe<Scalars["Float"]>;
  requiredTemperatureUnit?: Maybe<Scalars["String"]>;
  riskType?: Maybe<SlogTransportationRiskTypeChoices>;
  searchString: Scalars["String"];
  shipper?: Maybe<RegistryNode>;
  status?: Maybe<SlogTransportationStatusChoices>;
  temperatureInfo?: Maybe<Scalars["String"]>;
  transportationId?: Maybe<Scalars["String"]>;
  transportationRequiredPersonnel?: Maybe<Scalars["String"]>;
  transportationType?: Maybe<SlogTransportationTransportationTypeChoices>;
  transportationassetsSet: TransportationAssetNodeConnection;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  uuid: Scalars["UUID"];
  vector?: Maybe<RegistryNode>;
  vehicleSpecifics?: Maybe<Scalars["String"]>;
};

export type TransportationNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type TransportationNodePackagesSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  hardPackagingDescription?: InputMaybe<Scalars["String"]>;
  hardPackagingNotes?: InputMaybe<Scalars["String"]>;
  hardPackagingType?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packaging?: InputMaybe<Scalars["String"]>;
  packagingNotes?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  packingUnpackingInstructions?: InputMaybe<Scalars["String"]>;
  softPackagingDescription?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  volume?: InputMaybe<Scalars["Float"]>;
  volumeUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type TransportationNodeTransportationassetsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type TransportationNodeConnection = {
  __typename?: "TransportationNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransportationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `TransportationNode` and its cursor. */
export type TransportationNodeEdge = {
  __typename?: "TransportationNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<TransportationNode>;
};

export type UnlinkRegistryWithAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkedAssetRegistriesId: Scalars["Int"];
};

export type UnlinkRegistryWithAssetPayload = {
  __typename?: "UnlinkRegistryWithAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAppraisalInput = {
  appraisalData?: InputMaybe<AppraisalInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAppraisalPayload = {
  __typename?: "UpdateAppraisalPayload";
  appraisal?: Maybe<AppraisalNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetDocumentAttachmentInput = {
  assetDocumentAttachmentData?: InputMaybe<AssetDocumentAttachmentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetDocumentAttachmentPayload = {
  __typename?: "UpdateAssetDocumentAttachmentPayload";
  assetDocumentAttachment?: Maybe<AssetDocumentAttachmentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetDocumentInput = {
  assetDocumentData?: InputMaybe<AssetDocumentInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetDocumentPayload = {
  __typename?: "UpdateAssetDocumentPayload";
  assetDocument?: Maybe<AssetDocumentNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetElementInput = {
  assetElementData?: InputMaybe<AssetElementInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetElementPayload = {
  __typename?: "UpdateAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetInput = {
  assetData?: InputMaybe<AssetInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetPayload = {
  __typename?: "UpdateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetPresentValueInput = {
  assetPresentValueData?: InputMaybe<AssetPresentValueInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetPresentValuePayload = {
  __typename?: "UpdateAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateCertificateInput = {
  certificateData?: InputMaybe<CertificateInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type UpdateCertificatePayload = {
  __typename?: "UpdateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateClientBackgroundColorInput = {
  backgroundColor: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type UpdateClientBackgroundColorPayload = {
  __typename?: "UpdateClientBackgroundColorPayload";
  client?: Maybe<ClientNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateClientInput = {
  clientData: ClientInput;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type UpdateClientLogoInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  logo: Scalars["Upload"];
};

export type UpdateClientLogoPayload = {
  __typename?: "UpdateClientLogoPayload";
  client?: Maybe<ClientNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateClientPayload = {
  __typename?: "UpdateClientPayload";
  client?: Maybe<ClientNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateCollectionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  collectionData?: InputMaybe<CollectionInput>;
  id: Scalars["ID"];
};

export type UpdateCollectionPayload = {
  __typename?: "UpdateCollectionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  collection?: Maybe<CollectionNode>;
};

export type UpdateCompanyContactInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyContactData?: InputMaybe<CompanyContactInput>;
  id: Scalars["ID"];
};

export type UpdateCompanyContactPayload = {
  __typename?: "UpdateCompanyContactPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  companyContact?: Maybe<CompanyContactType>;
};

export type UpdateConditionStatusReportInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  conditionStatusReportData?: InputMaybe<ConditionStatusReportInput>;
  id: Scalars["ID"];
};

export type UpdateConditionStatusReportPayload = {
  __typename?: "UpdateConditionStatusReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  conditionStatusReport?: Maybe<ConditionStatusReportNode>;
};

export type UpdateDeliveryDetailsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  deliveryDetailsData?: InputMaybe<DeliveryDetailsInput>;
  id: Scalars["ID"];
};

export type UpdateDeliveryDetailsPayload = {
  __typename?: "UpdateDeliveryDetailsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deliveryDetails?: Maybe<DeliveryDetailsNode>;
};

export type UpdateDepositationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  depositationData: DepositationInput;
  id: Scalars["ID"];
};

export type UpdateDepositationPayload = {
  __typename?: "UpdateDepositationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type UpdateDepositationStatusInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateDepositationStatusPayload = {
  __typename?: "UpdateDepositationStatusPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  depositation?: Maybe<DepositationNode>;
};

export type UpdateEventLogInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  eventLogData?: InputMaybe<EventLogInput>;
  id: Scalars["ID"];
};

export type UpdateEventLogPayload = {
  __typename?: "UpdateEventLogPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  eventLog?: Maybe<EventLogNode>;
};

export type UpdateExchangeRatesInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exchangeRateValue: Scalars["Float"];
  fromCurrency: Scalars["String"];
  policyId: Scalars["ID"];
  toCurrency: Scalars["String"];
};

export type UpdateExchangeRatesPayload = {
  __typename?: "UpdateExchangeRatesPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exchangeRate?: Maybe<ExchangeRatesNode>;
};

export type UpdateExhibitionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  exhibitionData?: InputMaybe<ExhibitionInput>;
  id: Scalars["ID"];
};

export type UpdateExhibitionPayload = {
  __typename?: "UpdateExhibitionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exhibition?: Maybe<ExhibitionNode>;
};

export type UpdateExitInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type UpdateExitPayload = {
  __typename?: "UpdateExitPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  exit?: Maybe<ExitNode>;
};

export type UpdateInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  insuranceData?: InputMaybe<InsuranceInput>;
};

export type UpdateInsurancePayload = {
  __typename?: "UpdateInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type UpdateLinkedRegistryAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  linkedAssetRegistriesId: Scalars["Int"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type UpdateLinkedRegistryAssetPayload = {
  __typename?: "UpdateLinkedRegistryAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  linkedAssetRegistry?: Maybe<LinkedAssetRegistryNode>;
};

export type UpdateMediaInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  mediaData?: InputMaybe<MediaInput>;
};

export type UpdateMediaPayload = {
  __typename?: "UpdateMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
};

export type UpdateOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  officeData?: InputMaybe<OfficeInput>;
};

export type UpdateOfficePayload = {
  __typename?: "UpdateOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeType>;
};

export type UpdatePickupDetailsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  pickupDetailsData?: InputMaybe<PickupDetailsInput>;
};

export type UpdatePickupDetailsPayload = {
  __typename?: "UpdatePickupDetailsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  pickupDetails?: Maybe<PickupDetailsNode>;
};

export type UpdatePolicyAssetGroupInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  overrideChangeDate?: InputMaybe<Scalars["String"]>;
  policyAssetsData?: InputMaybe<Array<InputMaybe<GroupedPolicyAssetInput>>>;
};

export type UpdatePolicyAssetGroupPayload = {
  __typename?: "UpdatePolicyAssetGroupPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAssets?: Maybe<Array<Maybe<PolicyAssetNode>>>;
};

export type UpdatePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyAssetData?: InputMaybe<PolicyAssetInput>;
};

export type UpdatePolicyAssetPayload = {
  __typename?: "UpdatePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type UpdatePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyDocumentData?: InputMaybe<PolicyDocumentInput>;
};

export type UpdatePolicyDocumentPayload = {
  __typename?: "UpdatePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
};

export type UpdatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyData?: InputMaybe<UpdatePolicyInputData>;
};

export type UpdatePolicyInputData = {
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  brokerId?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  contractingPartyId: Scalars["ID"];
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntityId: Scalars["ID"];
  eventFrom?: InputMaybe<Scalars["String"]>;
  eventLocationEntityId: Scalars["ID"];
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["String"]>;
  exchangeRates?: InputMaybe<Array<InputMaybe<ExchangeRatesPartialInput>>>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt: Scalars["DateTime"];
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompanyId: Scalars["ID"];
  issuedAt: Scalars["DateTime"];
  offerCode: Scalars["String"];
  policyCode: Scalars["String"];
  policyStatus: Scalars["String"];
  totalInsuredPremium?: InputMaybe<MoneyInput>;
  totalInsuredValue?: InputMaybe<MoneyInput>;
};

export type UpdatePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyNoteData?: InputMaybe<PolicyNoteInput>;
};

export type UpdatePolicyNotePayload = {
  __typename?: "UpdatePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type UpdatePolicyPayload = {
  __typename?: "UpdatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type UpdateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  id: Scalars["ID"];
  registryData?: InputMaybe<RegistryInput>;
};

export type UpdateRegistryPayload = {
  __typename?: "UpdateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type UpdateRestorationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  restorationData?: InputMaybe<RestorationInput>;
};

export type UpdateRestorationPayload = {
  __typename?: "UpdateRestorationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  restoration?: Maybe<RestorationNode>;
};

export type UpdateTransportationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  transportationData?: InputMaybe<TransportationInput>;
};

export type UpdateTransportationPayload = {
  __typename?: "UpdateTransportationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type UpdateTransportationStatusInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateTransportationStatusPayload = {
  __typename?: "UpdateTransportationStatusPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  transportation?: Maybe<TransportationNode>;
};

export type UpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  userData: UserInput;
};

export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UpdateUserPermissionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UpdateUserPermissionPayload = {
  __typename?: "UpdateUserPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UserActionsNode = Node & {
  __typename?: "UserActionsNode";
  actionTime: Scalars["DateTime"];
  actionType: UsersUserActionsActionTypeChoices;
  contentObject?: Maybe<GenericForeignKeyType>;
  contentType?: Maybe<ContentTypeNode>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  /** The ID of the object */
  id: Scalars["ID"];
  meta?: Maybe<Scalars["JSONString"]>;
  notes?: Maybe<Scalars["String"]>;
  objectId?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  user?: Maybe<UserType>;
  uuid: Scalars["UUID"];
};

export type UserActionsNodeConnection = {
  __typename?: "UserActionsNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserActionsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserActionsNode` and its cursor. */
export type UserActionsNodeEdge = {
  __typename?: "UserActionsNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserActionsNode>;
};

export type UserInput = {
  country?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  language?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  userType?: InputMaybe<Scalars["String"]>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type UserNode = Node & {
  __typename?: "UserNode";
  appraisalSet: AppraisalNodeConnection;
  appraisalsCreatedCount?: Maybe<Scalars["Int"]>;
  assetsCreatedCount?: Maybe<Scalars["Int"]>;
  certificateSet: CertificateNodeConnection;
  collectionsCreatedCount?: Maybe<Scalars["Int"]>;
  conditionstatusreportSet: ConditionStatusReportNodeConnection;
  conditionstatusreportsCreatedCount?: Maybe<Scalars["Int"]>;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  createdbaseCompanycontactRelated: CompanyContactNodeConnection;
  createdbaseEventlogRelated: EventLogNodeConnection;
  createdbaseExchangeratesRelated: ExchangeRatesNodeConnection;
  createdbaseExporteddocumentsRelated: ExportDocumentsNodeConnection;
  createdbaseMediaRelated: MediaNodeConnection;
  createdbaseOfficeRelated: OfficeNodeConnection;
  createdbaseRegistryRelated: RegistryNodeConnection;
  createdmanagementAppraisalRelated: AppraisalNodeConnection;
  createdmanagementAssetRelated: AssetNodeConnection;
  createdmanagementAssetdatingRelated: AssetDatingNodeConnection;
  createdmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  createdmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  createdmanagementAssetelementRelated: AssetElementNodeConnection;
  createdmanagementAssetpredictionRelated: AssetPredictionNodeConnection;
  createdmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  createdmanagementCollectionRelated: CollectionNodeConnection;
  createdmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  createdmanagementExhibitionRelated: ExhibitionNodeConnection;
  createdmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  createdmanagementRestorationRelated: RestorationNodeConnection;
  createdslogDeliverydetailsRelated: DeliveryDetailsNodeConnection;
  createdslogDepositationRelated: DepositationNodeConnection;
  createdslogDepositationassetsRelated: DepositationAssetNodeConnection;
  createdslogExitRelated: ExitNodeConnection;
  createdslogPackagesRelated: PackagesNodeConnection;
  createdslogPickupdetailsRelated: PickupDetailsNodeConnection;
  createdslogTransportationRelated: TransportationNodeConnection;
  createdslogTransportationassetsRelated: TransportationAssetNodeConnection;
  createdspinCertificateRelated: CertificateNodeConnection;
  createdspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  createdspinPolicyRelated: PolicyNodeConnection;
  createdspinPolicyassetRelated: PolicyAssetNodeConnection;
  createdspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  createdspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  createdspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  createdspinPolicyeventsRelated: PolicyEventsNodeConnection;
  createdusersUserRelated: Array<UserType>;
  createdusersUseractionsRelated: UserActionsNodeConnection;
  createdusersUsersignatureRelated: UserSignatureNodeConnection;
  currency?: Maybe<Scalars["String"]>;
  dateJoined: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  email: Scalars["String"];
  exhibitionsCreatedCount?: Maybe<Scalars["Int"]>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"];
  isExpired?: Maybe<Scalars["Boolean"]>;
  isMaster?: Maybe<Scalars["Boolean"]>;
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"];
  isSuperuser?: Maybe<Scalars["Boolean"]>;
  language?: Maybe<Scalars["String"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  policiesCreatedCountPc?: Maybe<Scalars["Int"]>;
  policiesCreatedCountTe?: Maybe<Scalars["Int"]>;
  policyNotesUser: PolicyNoteNodeConnection;
  resetPasswordToken?: Maybe<Scalars["String"]>;
  resetTokenExpiry?: Maybe<Scalars["DateTime"]>;
  restorationSet: RestorationNodeConnection;
  restorationsCreatedCount?: Maybe<Scalars["Int"]>;
  timesRenewed?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  updatedbaseCompanycontactRelated: CompanyContactNodeConnection;
  updatedbaseEventlogRelated: EventLogNodeConnection;
  updatedbaseExchangeratesRelated: ExchangeRatesNodeConnection;
  updatedbaseExporteddocumentsRelated: ExportDocumentsNodeConnection;
  updatedbaseMediaRelated: MediaNodeConnection;
  updatedbaseOfficeRelated: OfficeNodeConnection;
  updatedbaseRegistryRelated: RegistryNodeConnection;
  updatedmanagementAppraisalRelated: AppraisalNodeConnection;
  updatedmanagementAssetRelated: AssetNodeConnection;
  updatedmanagementAssetdatingRelated: AssetDatingNodeConnection;
  updatedmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  updatedmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  updatedmanagementAssetelementRelated: AssetElementNodeConnection;
  updatedmanagementAssetpredictionRelated: AssetPredictionNodeConnection;
  updatedmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  updatedmanagementCollectionRelated: CollectionNodeConnection;
  updatedmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  updatedmanagementExhibitionRelated: ExhibitionNodeConnection;
  updatedmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  updatedmanagementRestorationRelated: RestorationNodeConnection;
  updatedslogDeliverydetailsRelated: DeliveryDetailsNodeConnection;
  updatedslogDepositationRelated: DepositationNodeConnection;
  updatedslogDepositationassetsRelated: DepositationAssetNodeConnection;
  updatedslogExitRelated: ExitNodeConnection;
  updatedslogPackagesRelated: PackagesNodeConnection;
  updatedslogPickupdetailsRelated: PickupDetailsNodeConnection;
  updatedslogTransportationRelated: TransportationNodeConnection;
  updatedslogTransportationassetsRelated: TransportationAssetNodeConnection;
  updatedspinCertificateRelated: CertificateNodeConnection;
  updatedspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  updatedspinPolicyRelated: PolicyNodeConnection;
  updatedspinPolicyassetRelated: PolicyAssetNodeConnection;
  updatedspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  updatedspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  updatedspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  updatedspinPolicyeventsRelated: PolicyEventsNodeConnection;
  updatedusersUserRelated: Array<UserType>;
  updatedusersUseractionsRelated: UserActionsNodeConnection;
  updatedusersUsersignatureRelated: UserSignatureNodeConnection;
  usedInvites: Scalars["Int"];
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  userType?: Maybe<UsersUserUserTypeChoices>;
  useractionsSet: UserActionsNodeConnection;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"];
  usersCreatedCount?: Maybe<Scalars["Int"]>;
  usersignatureSet: UserSignatureNodeConnection;
  uuid: Scalars["UUID"];
  validFrom?: Maybe<Scalars["DateTime"]>;
};

export type UserNodeAppraisalSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeConditionstatusreportSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseExchangeratesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseExporteddocumentsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetdatingRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetpredictionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  jobUuid?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserNodeCreatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedslogDeliverydetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeCreatedslogDepositationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeCreatedslogDepositationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserNodeCreatedslogExitRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeCreatedslogPackagesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  hardPackagingDescription?: InputMaybe<Scalars["String"]>;
  hardPackagingNotes?: InputMaybe<Scalars["String"]>;
  hardPackagingType?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packaging?: InputMaybe<Scalars["String"]>;
  packagingNotes?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  packingUnpackingInstructions?: InputMaybe<Scalars["String"]>;
  softPackagingDescription?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  volume?: InputMaybe<Scalars["Float"]>;
  volumeUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserNodeCreatedslogPickupdetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeCreatedslogTransportationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type UserNodeCreatedslogTransportationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeCreatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedusersUseractionsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodePolicyNotesUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeRestorationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseExchangeratesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseExporteddocumentsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetdatingRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetpredictionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  jobUuid?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserNodeUpdatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedslogDeliverydetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeUpdatedslogDepositationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeUpdatedslogDepositationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserNodeUpdatedslogExitRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeUpdatedslogPackagesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  hardPackagingDescription?: InputMaybe<Scalars["String"]>;
  hardPackagingNotes?: InputMaybe<Scalars["String"]>;
  hardPackagingType?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packaging?: InputMaybe<Scalars["String"]>;
  packagingNotes?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  packingUnpackingInstructions?: InputMaybe<Scalars["String"]>;
  softPackagingDescription?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  volume?: InputMaybe<Scalars["Float"]>;
  volumeUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserNodeUpdatedslogPickupdetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeUpdatedslogTransportationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type UserNodeUpdatedslogTransportationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserNodeUpdatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedusersUseractionsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUseractionsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUsersignatureSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeConnection = {
  __typename?: "UserNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: "UserNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type UserSignatureNode = Node & {
  __typename?: "UserSignatureNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isMain?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  user: UserType;
  uuid: Scalars["UUID"];
};

export type UserSignatureNodeConnection = {
  __typename?: "UserSignatureNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserSignatureNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserSignatureNode` and its cursor. */
export type UserSignatureNodeEdge = {
  __typename?: "UserSignatureNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserSignatureNode>;
};

export type UserSignatureType = {
  __typename?: "UserSignatureType";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isMain?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  user: UserType;
  uuid: Scalars["UUID"];
};

export type UserType = {
  __typename?: "UserType";
  appraisalSet: AppraisalNodeConnection;
  certificateSet: CertificateNodeConnection;
  conditionstatusreportSet: ConditionStatusReportNodeConnection;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserType>;
  createdbaseCompanycontactRelated: CompanyContactNodeConnection;
  createdbaseEventlogRelated: EventLogNodeConnection;
  createdbaseExchangeratesRelated: ExchangeRatesNodeConnection;
  createdbaseExporteddocumentsRelated: ExportDocumentsNodeConnection;
  createdbaseMediaRelated: MediaNodeConnection;
  createdbaseOfficeRelated: OfficeNodeConnection;
  createdbaseRegistryRelated: RegistryNodeConnection;
  createdmanagementAppraisalRelated: AppraisalNodeConnection;
  createdmanagementAssetRelated: AssetNodeConnection;
  createdmanagementAssetdatingRelated: AssetDatingNodeConnection;
  createdmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  createdmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  createdmanagementAssetelementRelated: AssetElementNodeConnection;
  createdmanagementAssetpredictionRelated: AssetPredictionNodeConnection;
  createdmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  createdmanagementCollectionRelated: CollectionNodeConnection;
  createdmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  createdmanagementExhibitionRelated: ExhibitionNodeConnection;
  createdmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  createdmanagementRestorationRelated: RestorationNodeConnection;
  createdslogDeliverydetailsRelated: DeliveryDetailsNodeConnection;
  createdslogDepositationRelated: DepositationNodeConnection;
  createdslogDepositationassetsRelated: DepositationAssetNodeConnection;
  createdslogExitRelated: ExitNodeConnection;
  createdslogPackagesRelated: PackagesNodeConnection;
  createdslogPickupdetailsRelated: PickupDetailsNodeConnection;
  createdslogTransportationRelated: TransportationNodeConnection;
  createdslogTransportationassetsRelated: TransportationAssetNodeConnection;
  createdspinCertificateRelated: CertificateNodeConnection;
  createdspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  createdspinPolicyRelated: PolicyNodeConnection;
  createdspinPolicyassetRelated: PolicyAssetNodeConnection;
  createdspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  createdspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  createdspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  createdspinPolicyeventsRelated: PolicyEventsNodeConnection;
  createdusersUserRelated: Array<UserType>;
  createdusersUseractionsRelated: UserActionsNodeConnection;
  createdusersUsersignatureRelated: UserSignatureNodeConnection;
  currency?: Maybe<Scalars["String"]>;
  dateJoined: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"];
  isMaster?: Maybe<Scalars["Boolean"]>;
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"];
  isSuperuser?: Maybe<Scalars["Boolean"]>;
  language?: Maybe<Scalars["String"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  policyNotesUser: PolicyNoteNodeConnection;
  resetPasswordToken?: Maybe<Scalars["String"]>;
  resetTokenExpiry?: Maybe<Scalars["DateTime"]>;
  restorationSet: RestorationNodeConnection;
  timesRenewed?: Maybe<Scalars["Int"]>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserType>;
  updatedbaseCompanycontactRelated: CompanyContactNodeConnection;
  updatedbaseEventlogRelated: EventLogNodeConnection;
  updatedbaseExchangeratesRelated: ExchangeRatesNodeConnection;
  updatedbaseExporteddocumentsRelated: ExportDocumentsNodeConnection;
  updatedbaseMediaRelated: MediaNodeConnection;
  updatedbaseOfficeRelated: OfficeNodeConnection;
  updatedbaseRegistryRelated: RegistryNodeConnection;
  updatedmanagementAppraisalRelated: AppraisalNodeConnection;
  updatedmanagementAssetRelated: AssetNodeConnection;
  updatedmanagementAssetdatingRelated: AssetDatingNodeConnection;
  updatedmanagementAssetdocumentRelated: AssetDocumentNodeConnection;
  updatedmanagementAssetdocumentattachmentRelated: AssetDocumentAttachmentNodeConnection;
  updatedmanagementAssetelementRelated: AssetElementNodeConnection;
  updatedmanagementAssetpredictionRelated: AssetPredictionNodeConnection;
  updatedmanagementAssetpresentvalueRelated: AssetPresentValueNodeConnection;
  updatedmanagementCollectionRelated: CollectionNodeConnection;
  updatedmanagementConditionstatusreportRelated: ConditionStatusReportNodeConnection;
  updatedmanagementExhibitionRelated: ExhibitionNodeConnection;
  updatedmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  updatedmanagementRestorationRelated: RestorationNodeConnection;
  updatedslogDeliverydetailsRelated: DeliveryDetailsNodeConnection;
  updatedslogDepositationRelated: DepositationNodeConnection;
  updatedslogDepositationassetsRelated: DepositationAssetNodeConnection;
  updatedslogExitRelated: ExitNodeConnection;
  updatedslogPackagesRelated: PackagesNodeConnection;
  updatedslogPickupdetailsRelated: PickupDetailsNodeConnection;
  updatedslogTransportationRelated: TransportationNodeConnection;
  updatedslogTransportationassetsRelated: TransportationAssetNodeConnection;
  updatedspinCertificateRelated: CertificateNodeConnection;
  updatedspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  updatedspinPolicyRelated: PolicyNodeConnection;
  updatedspinPolicyassetRelated: PolicyAssetNodeConnection;
  updatedspinPolicyassetbaskethistoryRelated: PolicyAssetBasketHistoryNodeConnection;
  updatedspinPolicybaskethistoryRelated: PolicyBasketHistoryNodeConnection;
  updatedspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  updatedspinPolicyeventsRelated: PolicyEventsNodeConnection;
  updatedusersUserRelated: Array<UserType>;
  updatedusersUseractionsRelated: UserActionsNodeConnection;
  updatedusersUsersignatureRelated: UserSignatureNodeConnection;
  usedInvites: Scalars["Int"];
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  userType?: Maybe<UsersUserUserTypeChoices>;
  useractionsSet: UserActionsNodeConnection;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"];
  usersignatureSet: UserSignatureNodeConnection;
  uuid: Scalars["UUID"];
  validFrom?: Maybe<Scalars["DateTime"]>;
};

export type UserTypeAppraisalSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeConditionstatusreportSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseExchangeratesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseExporteddocumentsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetdatingRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetpredictionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  jobUuid?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserTypeCreatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedslogDeliverydetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeCreatedslogDepositationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeCreatedslogDepositationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserTypeCreatedslogExitRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeCreatedslogPackagesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  hardPackagingDescription?: InputMaybe<Scalars["String"]>;
  hardPackagingNotes?: InputMaybe<Scalars["String"]>;
  hardPackagingType?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packaging?: InputMaybe<Scalars["String"]>;
  packagingNotes?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  packingUnpackingInstructions?: InputMaybe<Scalars["String"]>;
  softPackagingDescription?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  volume?: InputMaybe<Scalars["Float"]>;
  volumeUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserTypeCreatedslogPickupdetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeCreatedslogTransportationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type UserTypeCreatedslogTransportationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeCreatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedusersUseractionsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypePolicyNotesUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeRestorationSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseCompanycontactRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseEventlogRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseExchangeratesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseExporteddocumentsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAppraisalRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetdatingRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetdocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetdocumentattachmentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetelementRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetpredictionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  jobUuid?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetpresentvalueRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementConditionstatusreportRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserTypeUpdatedmanagementRestorationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedslogDeliverydetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deliveryDatetime?: InputMaybe<Scalars["DateTime"]>;
  deliveryLocation?: InputMaybe<Scalars["ID"]>;
  deliveryType?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeUpdatedslogDepositationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  areaDescription?: InputMaybe<Scalars["String"]>;
  areaNotes?: InputMaybe<Scalars["String"]>;
  areaType?: InputMaybe<Scalars["String"]>;
  areaTypeDescription?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  company?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  courier?: InputMaybe<Scalars["ID"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  depositationId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  driver?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  info?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["ID"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  methodAndAreaOfStorage?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["DateTime"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeUpdatedslogDepositationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  container?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositation?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  diameter?: InputMaybe<Scalars["Float"]>;
  exit?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packageWeight?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  weightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserTypeUpdatedslogExitRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appointee?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["ID"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depositationData?: InputMaybe<Scalars["String"]>;
  depositationNumber?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  exitDriver?: InputMaybe<Scalars["String"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeUpdatedslogPackagesRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  depth?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  grossWeight?: InputMaybe<Scalars["Float"]>;
  grossWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  hardPackagingDescription?: InputMaybe<Scalars["String"]>;
  hardPackagingNotes?: InputMaybe<Scalars["String"]>;
  hardPackagingType?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  last?: InputMaybe<Scalars["Int"]>;
  linearUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Float"]>;
  netWeightUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  number?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  packaging?: InputMaybe<Scalars["String"]>;
  packagingNotes?: InputMaybe<Scalars["String"]>;
  packagingType?: InputMaybe<Scalars["String"]>;
  packingUnpackingInstructions?: InputMaybe<Scalars["String"]>;
  softPackagingDescription?: InputMaybe<Scalars["String"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  volume?: InputMaybe<Scalars["Float"]>;
  volumeUnitOfMeasure?: InputMaybe<Scalars["String"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type UserTypeUpdatedslogPickupdetailsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cellularNumber?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  pickupDatetime?: InputMaybe<Scalars["DateTime"]>;
  pickupLocation?: InputMaybe<Scalars["ID"]>;
  pickupType?: InputMaybe<Scalars["String"]>;
  sender?: InputMaybe<Scalars["ID"]>;
  telephoneNumber?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeUpdatedslogTransportationRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  controlledTemperature?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  deliveryDetails?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exportedDocument?: InputMaybe<Scalars["ID"]>;
  filingNumber?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  insuranceDetails?: InputMaybe<Scalars["String"]>;
  insuranceNotes?: InputMaybe<Scalars["String"]>;
  insuranceStatus?: InputMaybe<Scalars["String"]>;
  insuredFrom?: InputMaybe<Scalars["DateTime"]>;
  insuredTo?: InputMaybe<Scalars["DateTime"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  isInsured?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  media?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  notes?: InputMaybe<Scalars["String"]>;
  numberOfPackages?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  otherCharacteristics?: InputMaybe<Scalars["String"]>;
  packageDescription?: InputMaybe<Scalars["String"]>;
  packageVolume?: InputMaybe<Scalars["Float"]>;
  packageVolumeUnit?: InputMaybe<Scalars["String"]>;
  packageWeight?: InputMaybe<Scalars["Float"]>;
  packageWeightUnit?: InputMaybe<Scalars["String"]>;
  pickupDetails?: InputMaybe<Scalars["ID"]>;
  requiredTemperature?: InputMaybe<Scalars["Float"]>;
  requiredTemperatureUnit?: InputMaybe<Scalars["String"]>;
  riskType?: InputMaybe<Scalars["String"]>;
  searchString?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  transportationId?: InputMaybe<Scalars["String"]>;
  transportationRequiredPersonnel?: InputMaybe<Scalars["String"]>;
  transportationType?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
  vector?: InputMaybe<Scalars["ID"]>;
  vehicleSpecifics?: InputMaybe<Scalars["String"]>;
};

export type UserTypeUpdatedslogTransportationassetsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  package?: InputMaybe<Scalars["ID"]>;
  transportation?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type UserTypeUpdatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyassetbaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicybaskethistoryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyeventsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedusersUseractionsRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUseractionsSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUsersignatureSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export enum UsersUserActionsActionTypeChoices {
  /** CREATE */
  Create = "CREATE",
  /** DELETE */
  Delete = "DELETE",
  /** EXTEND */
  Extend = "EXTEND",
  /** RENEW */
  Renew = "RENEW",
  /** TERMINATE */
  Terminate = "TERMINATE",
  /** UPDATE */
  Update = "UPDATE",
}

export enum UsersUserUserTypeChoices {
  /** Default */
  Default = "DEFAULT",
  /** External */
  External = "EXTERNAL",
  /** Internal */
  Internal = "INTERNAL",
}

export type Verify = {
  __typename?: "Verify";
  payload: Scalars["GenericScalar"];
};

export type VerifyShareableTokenInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type VerifyShareableTokenPayload = {
  __typename?: "VerifyShareableTokenPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  user?: Maybe<UserType>;
};

export type AddresFieldsFragment = {
  __typename: "AddressNode";
  id: string;
  formatted: string;
  raw: string;
  latitude?: number | null;
  longitude?: number | null;
  route: string;
  streetNumber: string;
  assetNumber?: number | null;
};

export type GetAllAssetsLocationQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAssetsLocationQuery = {
  __typename?: "Query";
  allAddressesAssetLocation?: {
    __typename?: "AddressNodeConnection";
    edges: Array<{
      __typename?: "AddressNodeEdge";
      node?: {
        __typename: "AddressNode";
        id: string;
        formatted: string;
        raw: string;
        latitude?: number | null;
        longitude?: number | null;
        route: string;
        streetNumber: string;
        assetNumber?: number | null;
        locality?: {
          __typename?: "LocalityNode";
          name: string;
          postalCode: string;
          state?: {
            __typename?: "StateNode";
            name: string;
            code: string;
            country?: { __typename?: "CountryNode"; id: string; name: string } | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAddressByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAddressByIdQuery = {
  __typename?: "Query";
  address?: {
    __typename: "AddressNode";
    id: string;
    formatted: string;
    raw: string;
    latitude?: number | null;
    longitude?: number | null;
    route: string;
    streetNumber: string;
    assetNumber?: number | null;
    locality?: {
      __typename?: "LocalityNode";
      name: string;
      postalCode: string;
      state?: {
        __typename?: "StateNode";
        name: string;
        code: string;
        country?: { __typename?: "CountryNode"; id: string; name: string } | null;
      } | null;
    } | null;
    assetLocation: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename: "AssetNode";
          id: string;
          title: string;
          inventoryNumber?: string | null;
          technique?: string | null;
          genericDimensions?: string | null;
          actualDateAndPeriod?: string | null;
          category?: string | null;
          mainImage?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AppraisalFindingFieldsFragment = {
  __typename?: "AppraisalNode";
  id: string;
  appraisalStatus?: string | null;
  appraisalDate?: string | null;
  isSigned: boolean;
  appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  appraisalAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
};

export type AppraisalAnagraphicFieldsFragment = {
  __typename?: "AppraisalNode";
  id: string;
  appraisalStatus?: string | null;
  appraisalConservationStatus?: string | null;
  appraisalMotivation?: string | null;
  appraisalLocation?: string | null;
  appraisalDate?: string | null;
  valueInformationAuthorCoefficient?: string | null;
  valueInformationInfo?: string | null;
  valueInformationQuotesSimilarAssets?: string | null;
  valueInformationNote?: string | null;
  isSigned: boolean;
  appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  appraisalAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
  valueCarryingValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  valueEstimatedValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllAppraisalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAppraisalsQuery = {
  __typename?: "Query";
  allAppraisals?: {
    __typename?: "AppraisalNodeConnection";
    edges: Array<{
      __typename?: "AppraisalNodeEdge";
      node?: {
        __typename?: "AppraisalNode";
        mediaNumber?: number | null;
        createdAt: string;
        actualMediaStatus?: string | null;
        id: string;
        appraisalStatus?: string | null;
        appraisalDate?: string | null;
        isSigned: boolean;
        valueEstimatedValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
        appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
        appraisalAsset: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type GetAppraisalByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAppraisalByIdQuery = {
  __typename?: "Query";
  appraisal?: {
    __typename?: "AppraisalNode";
    mediaNumber?: number | null;
    id: string;
    appraisalStatus?: string | null;
    appraisalConservationStatus?: string | null;
    appraisalMotivation?: string | null;
    appraisalLocation?: string | null;
    appraisalDate?: string | null;
    valueInformationAuthorCoefficient?: string | null;
    valueInformationInfo?: string | null;
    valueInformationQuotesSimilarAssets?: string | null;
    valueInformationNote?: string | null;
    isSigned: boolean;
    appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    appraisalAsset: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      actualDateAndPeriod?: string | null;
      authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    };
    valueCarryingValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    valueEstimatedValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateAppraisalMutationVariables = Exact<{
  input: CreateAppraisalInput;
}>;

export type CreateAppraisalMutation = {
  __typename?: "Mutation";
  createAppraisal?: {
    __typename?: "CreateAppraisalPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      appraisalStatus?: string | null;
      appraisalConservationStatus?: string | null;
      appraisalMotivation?: string | null;
      appraisalLocation?: string | null;
      appraisalDate?: string | null;
      valueInformationAuthorCoefficient?: string | null;
      valueInformationInfo?: string | null;
      valueInformationQuotesSimilarAssets?: string | null;
      valueInformationNote?: string | null;
      appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      appraisalAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      valueCarryingValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateAppraisalMutationVariables = Exact<{
  input: UpdateAppraisalInput;
}>;

export type UpdateAppraisalMutation = {
  __typename?: "Mutation";
  updateAppraisal?: {
    __typename?: "UpdateAppraisalPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      appraisalStatus?: string | null;
      appraisalConservationStatus?: string | null;
      appraisalMotivation?: string | null;
      appraisalLocation?: string | null;
      appraisalDate?: string | null;
      valueInformationAuthorCoefficient?: string | null;
      valueInformationInfo?: string | null;
      valueInformationQuotesSimilarAssets?: string | null;
      valueInformationNote?: string | null;
      appraisalEvaluator: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      appraisalAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      valueCarryingValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAppraisalMutationVariables = Exact<{
  input: DeleteAppraisalInput;
}>;

export type DeleteAppraisalMutation = {
  __typename?: "Mutation";
  deleteAppraisal?: {
    __typename?: "DeleteAppraisalPayload";
    deleteAppraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type CloseAppraisalMutationVariables = Exact<{
  input: CloseAppraisalInput;
}>;

export type CloseAppraisalMutation = {
  __typename?: "Mutation";
  closeAppraisal?: {
    __typename?: "CloseAppraisalPayload";
    closeAppraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromAppraisalMutationVariables = Exact<{
  input: DetachAssetFromAppraisalInput;
}>;

export type DetachAssetFromAppraisalMutation = {
  __typename?: "Mutation";
  detachAssetFromAppraisal?: {
    __typename?: "DetachAssetFromAppraisalPayload";
    appraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type GetMediaAppraisalByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaAppraisalByIdQuery = {
  __typename?: "Query";
  appraisal?: {
    __typename?: "AppraisalNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AddAppraisalMediaMutationVariables = Exact<{
  input: AddAppraisalMediaInput;
}>;

export type AddAppraisalMediaMutation = {
  __typename?: "Mutation";
  addAppraisalMedia?: {
    __typename?: "AddAppraisalMediaPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteAppraisalMediaMutationVariables = Exact<{
  input: DeleteAppraisalMediaInput;
}>;

export type DeleteAppraisalMediaMutation = {
  __typename?: "Mutation";
  deleteAppraisalMedia?: {
    __typename?: "DeleteAppraisalMediaPayload";
    appraisal?: {
      __typename?: "AppraisalNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportAppraisalPdfMutationVariables = Exact<{
  input: ExportAppraisalPdfInput;
}>;

export type ExportAppraisalPdfMutation = {
  __typename?: "Mutation";
  exportAppraisalPdf?: {
    __typename?: "ExportAppraisalPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type DuplicateAppraisalMutationVariables = Exact<{
  input: DuplicateAppraisalInput;
}>;

export type DuplicateAppraisalMutation = {
  __typename?: "Mutation";
  duplicateAppraisal?: {
    __typename?: "DuplicateAppraisalPayload";
    appraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type SignAppraisalMutationVariables = Exact<{
  input: SignAppraisalInput;
}>;

export type SignAppraisalMutation = {
  __typename?: "Mutation";
  signAppraisal?: {
    __typename?: "SignAppraisalPayload";
    appraisal?: { __typename?: "AppraisalNode"; id: string } | null;
  } | null;
};

export type MediaFieldsFragment = {
  __typename?: "MediaNode";
  id: string;
  name?: string | null;
  file: string;
  description?: string | null;
  mediaType?: string | null;
  isMainImage?: boolean | null;
  imageType?: string | null;
  notes?: string | null;
  audioVideoFileType?: string | null;
  creationDate?: any | null;
  fileAuthor?: string | null;
  fileSize?: string | null;
  photographicEquipment?: string | null;
  matrixType?: string | null;
  isGlassPlexyAcetate?: boolean | null;
  createdAt: string;
  actualImageDimensions?: string | null;
};

export type AssetFieldsFragment = {
  __typename: "AssetNode";
  id: string;
  deleted?: string | null;
  deletedByCascade: boolean;
  createdAt: string;
  updatedAt: string;
  availability: string;
  uuid: string;
  collectionName?: string | null;
  assetPlacement?: string | null;
  netWeightUnit?: string | null;
  grossWeightUnit?: string | null;
  netLengthUnit?: string | null;
  grossLengthUnit?: string | null;
  netHeight?: number | null;
  grossHeight?: number | null;
  netWidth?: number | null;
  grossWidth?: number | null;
  netDepth?: number | null;
  grossDepth?: number | null;
  netDiameter?: number | null;
  grossDiameter?: number | null;
  netWeight?: number | null;
  grossWeight?: number | null;
  netDimensionsAcquisition?: string | null;
  grossDimensionsAcquisition?: string | null;
  dimensionsNotes?: string | null;
  audioVideoLengthUnit?: string | null;
  audioVideoLength?: number | null;
  fileSizeUnit?: string | null;
  fileSize?: number | null;
  resolutionUnit?: string | null;
  resolution?: number | null;
  optimalLightLevelIntensity?: number | null;
  lightUnit?: string | null;
  optimalTemperature?: number | null;
  temperatureUnit?: string | null;
  optimalHumidity?: number | null;
  enviromentalConditionsNotes?: string | null;
  isNotifiedCulturalProperty?: boolean | null;
  notifiedPropertyNote?: string | null;
  legalStatus?: string | null;
  copyrightAndPropertyRestrictions?: string | null;
  hasBarriers?: boolean | null;
  barriersDetails?: string | null;
  handlingRequirements?: string | null;
  injuryRisk?: string | null;
  injuryRiskDescription?: string | null;
  mouldingSupportBase?: string | null;
  hardwareToolsInstallation?: string | null;
  conservation?: string | null;
  lenderRequests?: string | null;
  settingNotes?: string | null;
  infoNotes?: string | null;
  isArtistProof?: boolean | null;
  artistProofNotes?: string | null;
  isFirstDraft?: boolean | null;
  firstDraftNotes?: string | null;
  isEdition?: boolean | null;
  editionNotes?: string | null;
  isMaster?: boolean | null;
  masterLocation?: string | null;
  isArtMultiple?: boolean | null;
  multipleNumber?: string | null;
  isSignedObject?: boolean | null;
  signedObjectNotes?: string | null;
  isTitledObject?: boolean | null;
  titledObjectNotes?: string | null;
  isDatedObject?: boolean | null;
  datedObjectNotes?: string | null;
  hasLabelsStamps?: boolean | null;
  labelsStampsNotes?: string | null;
  hasOtherAutographed?: boolean | null;
  otherAutographedNotes?: string | null;
  relevantAwards?: string | null;
  mechanism?: string | null;
  carat?: string | null;
  isDynamic?: boolean | null;
  dynamicNotes?: string | null;
  hasHumanAnimalPresence?: boolean | null;
  humanAnimalPresenceNotes?: string | null;
  isAudio?: boolean | null;
  isVideo?: boolean | null;
  hasLoop?: boolean | null;
  coloring?: string | null;
  coinMintIssuer?: string | null;
  coinPlacement?: string | null;
  coinPeriod?: string | null;
  coinPlacementNotes?: string | null;
  coinIssueReason?: string | null;
  coinCirculationPeriod?: string | null;
  coinComposition?: string | null;
  coinIdentificationCode?: string | null;
  classificationUsa?: string | null;
  classificationIta?: string | null;
  classificationEng?: string | null;
  classificationFra?: string | null;
  classificationSpa?: string | null;
  classificationGer?: string | null;
  classificationPhilatelic?: string | null;
  rarityDegree?: string | null;
  rarityDegreePhilatelic?: string | null;
  conservationState?: string | null;
  rarityDegreeNotes?: string | null;
  variants?: string | null;
  nominalValue?: string | null;
  fiscalValue?: string | null;
  currentValue?: number | null;
  currency?: string | null;
  score?: string | null;
  circulation?: string | null;
  manufactoringTechniques?: string | null;
  mintingLastDate?: string | null;
  mintingLastDateNotes?: string | null;
  reminting?: string | null;
  printingLastDate?: string | null;
  printingLastDateNotes?: string | null;
  reprinting?: string | null;
  stateOfConservation?: string | null;
  isComposed?: boolean | null;
  numberOfElements?: number | null;
  surfaceCharacteristics?: string | null;
  hasBase?: boolean | null;
  baseDetails?: string | null;
  hasWoodFrame?: boolean | null;
  woodFrameDetails?: string | null;
  hasFrame?: boolean | null;
  hasOriginalFrame?: boolean | null;
  frameMaterial?: string | null;
  isValuableFrame?: boolean | null;
  frameManufacturer?: string | null;
  frameDescription?: string | null;
  compositionDescription?: string | null;
  usage?: string | null;
  finishing?: string | null;
  hasContainer?: boolean | null;
  containerDetails?: string | null;
  replacedParts?: string | null;
  methodOfUse?: string | null;
  relatedPhysicalWork?: string | null;
  bookContainer?: string | null;
  dustJacket?: string | null;
  frontCover?: string | null;
  rearCover?: string | null;
  topEdge?: string | null;
  foreEdge?: string | null;
  footEdge?: string | null;
  bookbinding?: string | null;
  interiorDecorations?: string | null;
  externalDecorations?: string | null;
  flyleaf?: string | null;
  glueing?: string | null;
  headband?: string | null;
  headcap?: string | null;
  corner?: string | null;
  square?: string | null;
  slots?: string | null;
  spine?: string | null;
  clips?: string | null;
  raisedBands?: string | null;
  label?: string | null;
  clothCovering?: string | null;
  distribution?: string | null;
  edge?: string | null;
  orientation?: string | null;
  shape?: string | null;
  rim?: string | null;
  rotationAxes?: string | null;
  legend?: string | null;
  fineness?: string | null;
  coinTitle?: string | null;
  mintMadeErrors?: string | null;
  signings?: string | null;
  relief?: string | null;
  watermark?: string | null;
  holographicStripe?: string | null;
  securityThread?: string | null;
  printingErrors?: string | null;
  conservationMethod?: string | null;
  color?: string | null;
  postageMark?: string | null;
  rubber?: string | null;
  stampPrintingTechnique?: string | null;
  stampRealization?: string | null;
  stampIndentationType?: string | null;
  stampCenteringAndMargins?: string | null;
  stampFormat?: string | null;
  discoveryPlace?: string | null;
  discoverySite?: string | null;
  discoveryDate?: any | null;
  discoveryNotes?: string | null;
  discoveryAuthor?: string | null;
  catalogingAuthor?: string | null;
  objectSetupAuthor?: string | null;
  originNotes?: string | null;
  bibliographyNotes?: string | null;
  carryingValueOrigin?: string | null;
  carryingValueAcquisition?: string | null;
  carryingValueNotes?: string | null;
  carryingValueLastAcquisitionDate?: any | null;
  carryingValueAmountCurrency: string;
  carryingValueFileTitle?: string | null;
  carryingValueFile?: string | null;
  title: string;
  technique?: string | null;
  genericDimensions?: string | null;
  actualDateAndPeriod?: string | null;
  creationDate?: string | null;
  creationDateNotes?: string | null;
  category?: string | null;
  subcategory?: string | null;
  otherModel?: string | null;
  banknotePrinter?: string | null;
  paperType?: string | null;
  coverType?: string | null;
  printingType?: string | null;
  productionMode?: string | null;
  placement?: string | null;
  sku?: string | null;
  series?: string | null;
  seriesDetails?: string | null;
  commonName?: string | null;
  scientificName?: string | null;
  material?: string | null;
  internationalCode?: string | null;
  taxonomyDomain?: string | null;
  taxonomyKingdom?: string | null;
  taxonomyPhylum?: string | null;
  taxonomyClass?: string | null;
  taxonomyOrder?: string | null;
  taxonomyFamily?: string | null;
  taxonomyTribe?: string | null;
  taxonomyGenus?: string | null;
  taxonomyNotes?: string | null;
  hasPeriod?: boolean | null;
  hasYear?: boolean | null;
  hasFullDatetime?: boolean | null;
  datingType?: string | null;
  creationPeriod?: string | null;
  isDateInterval?: boolean | null;
  dateStartDate?: any | null;
  dateEndDate?: any | null;
  isDateStartDateBc?: boolean | null;
  isDateEndDateBc?: boolean | null;
  isDateApprox?: boolean | null;
  isYearInterval?: boolean | null;
  yearStartDate?: number | null;
  yearEndDate?: number | null;
  isYearStartDateBc?: boolean | null;
  isYearEndDateBc?: boolean | null;
  isYearApprox?: boolean | null;
  isCenturyInterval?: boolean | null;
  centuryStartDate?: number | null;
  centuryEndDate?: number | null;
  isCenturyStartDateBc?: boolean | null;
  isCenturyEndDateBc?: boolean | null;
  isCenturyApprox?: boolean | null;
  centuryStartDateWindow?: string | null;
  centuryEndDateWindow?: string | null;
  nft?: boolean | null;
  nftNotes?: string | null;
  description?: string | null;
  inventoryNumber?: string | null;
  archiveNumber?: string | null;
  catalogNumber?: string | null;
  criticalCatalogue?: boolean | null;
  validated?: boolean | null;
  mainImage?: string | null;
  createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  assetLocation?: {
    __typename?: "Address";
    raw?: string | null;
    locality?: string | null;
    state?: string | null;
    country?: string | null;
    streetNumber?: string | null;
    route?: string | null;
  } | null;
  carryingValueAmount?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  printer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  videomaker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  manufacturer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  producer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  collection: {
    __typename?: "CollectionNodeConnection";
    edges: Array<{
      __typename?: "CollectionNodeEdge";
      node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
    } | null>;
  };
  exhibition: {
    __typename?: "ExhibitionNodeConnection";
    edges: Array<{
      __typename?: "ExhibitionNodeEdge";
      node?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
    } | null>;
  };
  documents: {
    __typename?: "AssetDocumentNodeConnection";
    edges: Array<{
      __typename?: "AssetDocumentNodeEdge";
      node?: {
        __typename?: "AssetDocumentNode";
        id: string;
        title?: string | null;
        description?: string | null;
        documentType?: string | null;
        coaType?: string | null;
        issuer?: string | null;
        recipient?: string | null;
        isClassified?: boolean | null;
        releaseDate?: any | null;
        expirationDate?: any | null;
        notes?: string | null;
        archiveNumber?: string | null;
        fineArtsIssueType?: string | null;
        isTemporaryExport?: boolean | null;
        isTemporaryImport?: boolean | null;
        isCriticalCatalog?: boolean | null;
        editor?: string | null;
        curator?: string | null;
        subscriptionDate?: any | null;
        contractSubscriber?: string | null;
        publishingDate?: any | null;
        otherDocumentType?: string | null;
        attachments: {
          __typename?: "AssetDocumentAttachmentNodeConnection";
          edges: Array<{
            __typename?: "AssetDocumentAttachmentNodeEdge";
            node?: {
              __typename?: "AssetDocumentAttachmentNode";
              id: string;
              name?: string | null;
              file: string;
            } | null;
          } | null>;
        };
        createdBy?: { __typename?: "UserType"; name: string } | null;
      } | null;
    } | null>;
  };
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
        createdAt: string;
        actualImageDimensions?: string | null;
      } | null;
    } | null>;
  };
  physicalFeaturesElements: {
    __typename?: "AssetElementNodeConnection";
    edges: Array<{
      __typename?: "AssetElementNodeEdge";
      node?: {
        __typename?: "AssetElementNode";
        id: string;
        name: string;
        materials?: string | null;
        description?: string | null;
        notes?: string | null;
      } | null;
    } | null>;
  };
  presentValues: {
    __typename?: "AssetPresentValueNodeConnection";
    edges: Array<{
      __typename?: "AssetPresentValueNodeEdge";
      node?: {
        __typename?: "AssetPresentValueNode";
        id: string;
        estimateDate?: any | null;
        estimateReason?: string | null;
        notes?: string | null;
        file?: string | null;
        fileTitle?: string | null;
        appraisalId?: string | null;
        author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        amount: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        };
      } | null;
    } | null>;
  };
  policyAsset: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: { __typename?: "PolicyAssetNode"; id: string } | null;
    } | null>;
  };
};

export type AssetFieldsCreationReviewFragment = {
  __typename: "AssetNode";
  id: string;
  title: string;
  inventoryNumber?: string | null;
  technique?: string | null;
  genericDimensions?: string | null;
  actualDateAndPeriod?: string | null;
  category?: string | null;
  mainImage?: string | null;
  authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type GetAllAssetsByTitleQueryVariables = Exact<{
  title_Contains?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllAssetsByTitleQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        author?: string | null;
        creationDate?: string | null;
        actualDateAndPeriod?: string | null;
        genericDimensions?: string | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetByIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename: "AssetNode";
    id: string;
    deleted?: string | null;
    deletedByCascade: boolean;
    createdAt: string;
    updatedAt: string;
    availability: string;
    uuid: string;
    collectionName?: string | null;
    assetPlacement?: string | null;
    netWeightUnit?: string | null;
    grossWeightUnit?: string | null;
    netLengthUnit?: string | null;
    grossLengthUnit?: string | null;
    netHeight?: number | null;
    grossHeight?: number | null;
    netWidth?: number | null;
    grossWidth?: number | null;
    netDepth?: number | null;
    grossDepth?: number | null;
    netDiameter?: number | null;
    grossDiameter?: number | null;
    netWeight?: number | null;
    grossWeight?: number | null;
    netDimensionsAcquisition?: string | null;
    grossDimensionsAcquisition?: string | null;
    dimensionsNotes?: string | null;
    audioVideoLengthUnit?: string | null;
    audioVideoLength?: number | null;
    fileSizeUnit?: string | null;
    fileSize?: number | null;
    resolutionUnit?: string | null;
    resolution?: number | null;
    optimalLightLevelIntensity?: number | null;
    lightUnit?: string | null;
    optimalTemperature?: number | null;
    temperatureUnit?: string | null;
    optimalHumidity?: number | null;
    enviromentalConditionsNotes?: string | null;
    isNotifiedCulturalProperty?: boolean | null;
    notifiedPropertyNote?: string | null;
    legalStatus?: string | null;
    copyrightAndPropertyRestrictions?: string | null;
    hasBarriers?: boolean | null;
    barriersDetails?: string | null;
    handlingRequirements?: string | null;
    injuryRisk?: string | null;
    injuryRiskDescription?: string | null;
    mouldingSupportBase?: string | null;
    hardwareToolsInstallation?: string | null;
    conservation?: string | null;
    lenderRequests?: string | null;
    settingNotes?: string | null;
    infoNotes?: string | null;
    isArtistProof?: boolean | null;
    artistProofNotes?: string | null;
    isFirstDraft?: boolean | null;
    firstDraftNotes?: string | null;
    isEdition?: boolean | null;
    editionNotes?: string | null;
    isMaster?: boolean | null;
    masterLocation?: string | null;
    isArtMultiple?: boolean | null;
    multipleNumber?: string | null;
    isSignedObject?: boolean | null;
    signedObjectNotes?: string | null;
    isTitledObject?: boolean | null;
    titledObjectNotes?: string | null;
    isDatedObject?: boolean | null;
    datedObjectNotes?: string | null;
    hasLabelsStamps?: boolean | null;
    labelsStampsNotes?: string | null;
    hasOtherAutographed?: boolean | null;
    otherAutographedNotes?: string | null;
    relevantAwards?: string | null;
    mechanism?: string | null;
    carat?: string | null;
    isDynamic?: boolean | null;
    dynamicNotes?: string | null;
    hasHumanAnimalPresence?: boolean | null;
    humanAnimalPresenceNotes?: string | null;
    isAudio?: boolean | null;
    isVideo?: boolean | null;
    hasLoop?: boolean | null;
    coloring?: string | null;
    coinMintIssuer?: string | null;
    coinPlacement?: string | null;
    coinPeriod?: string | null;
    coinPlacementNotes?: string | null;
    coinIssueReason?: string | null;
    coinCirculationPeriod?: string | null;
    coinComposition?: string | null;
    coinIdentificationCode?: string | null;
    classificationUsa?: string | null;
    classificationIta?: string | null;
    classificationEng?: string | null;
    classificationFra?: string | null;
    classificationSpa?: string | null;
    classificationGer?: string | null;
    classificationPhilatelic?: string | null;
    rarityDegree?: string | null;
    rarityDegreePhilatelic?: string | null;
    conservationState?: string | null;
    rarityDegreeNotes?: string | null;
    variants?: string | null;
    nominalValue?: string | null;
    fiscalValue?: string | null;
    currentValue?: number | null;
    currency?: string | null;
    score?: string | null;
    circulation?: string | null;
    manufactoringTechniques?: string | null;
    mintingLastDate?: string | null;
    mintingLastDateNotes?: string | null;
    reminting?: string | null;
    printingLastDate?: string | null;
    printingLastDateNotes?: string | null;
    reprinting?: string | null;
    stateOfConservation?: string | null;
    isComposed?: boolean | null;
    numberOfElements?: number | null;
    surfaceCharacteristics?: string | null;
    hasBase?: boolean | null;
    baseDetails?: string | null;
    hasWoodFrame?: boolean | null;
    woodFrameDetails?: string | null;
    hasFrame?: boolean | null;
    hasOriginalFrame?: boolean | null;
    frameMaterial?: string | null;
    isValuableFrame?: boolean | null;
    frameManufacturer?: string | null;
    frameDescription?: string | null;
    compositionDescription?: string | null;
    usage?: string | null;
    finishing?: string | null;
    hasContainer?: boolean | null;
    containerDetails?: string | null;
    replacedParts?: string | null;
    methodOfUse?: string | null;
    relatedPhysicalWork?: string | null;
    bookContainer?: string | null;
    dustJacket?: string | null;
    frontCover?: string | null;
    rearCover?: string | null;
    topEdge?: string | null;
    foreEdge?: string | null;
    footEdge?: string | null;
    bookbinding?: string | null;
    interiorDecorations?: string | null;
    externalDecorations?: string | null;
    flyleaf?: string | null;
    glueing?: string | null;
    headband?: string | null;
    headcap?: string | null;
    corner?: string | null;
    square?: string | null;
    slots?: string | null;
    spine?: string | null;
    clips?: string | null;
    raisedBands?: string | null;
    label?: string | null;
    clothCovering?: string | null;
    distribution?: string | null;
    edge?: string | null;
    orientation?: string | null;
    shape?: string | null;
    rim?: string | null;
    rotationAxes?: string | null;
    legend?: string | null;
    fineness?: string | null;
    coinTitle?: string | null;
    mintMadeErrors?: string | null;
    signings?: string | null;
    relief?: string | null;
    watermark?: string | null;
    holographicStripe?: string | null;
    securityThread?: string | null;
    printingErrors?: string | null;
    conservationMethod?: string | null;
    color?: string | null;
    postageMark?: string | null;
    rubber?: string | null;
    stampPrintingTechnique?: string | null;
    stampRealization?: string | null;
    stampIndentationType?: string | null;
    stampCenteringAndMargins?: string | null;
    stampFormat?: string | null;
    discoveryPlace?: string | null;
    discoverySite?: string | null;
    discoveryDate?: any | null;
    discoveryNotes?: string | null;
    discoveryAuthor?: string | null;
    catalogingAuthor?: string | null;
    objectSetupAuthor?: string | null;
    originNotes?: string | null;
    bibliographyNotes?: string | null;
    carryingValueOrigin?: string | null;
    carryingValueAcquisition?: string | null;
    carryingValueNotes?: string | null;
    carryingValueLastAcquisitionDate?: any | null;
    carryingValueAmountCurrency: string;
    carryingValueFileTitle?: string | null;
    carryingValueFile?: string | null;
    title: string;
    technique?: string | null;
    genericDimensions?: string | null;
    actualDateAndPeriod?: string | null;
    creationDate?: string | null;
    creationDateNotes?: string | null;
    category?: string | null;
    subcategory?: string | null;
    otherModel?: string | null;
    banknotePrinter?: string | null;
    paperType?: string | null;
    coverType?: string | null;
    printingType?: string | null;
    productionMode?: string | null;
    placement?: string | null;
    sku?: string | null;
    series?: string | null;
    seriesDetails?: string | null;
    commonName?: string | null;
    scientificName?: string | null;
    material?: string | null;
    internationalCode?: string | null;
    taxonomyDomain?: string | null;
    taxonomyKingdom?: string | null;
    taxonomyPhylum?: string | null;
    taxonomyClass?: string | null;
    taxonomyOrder?: string | null;
    taxonomyFamily?: string | null;
    taxonomyTribe?: string | null;
    taxonomyGenus?: string | null;
    taxonomyNotes?: string | null;
    hasPeriod?: boolean | null;
    hasYear?: boolean | null;
    hasFullDatetime?: boolean | null;
    datingType?: string | null;
    creationPeriod?: string | null;
    isDateInterval?: boolean | null;
    dateStartDate?: any | null;
    dateEndDate?: any | null;
    isDateStartDateBc?: boolean | null;
    isDateEndDateBc?: boolean | null;
    isDateApprox?: boolean | null;
    isYearInterval?: boolean | null;
    yearStartDate?: number | null;
    yearEndDate?: number | null;
    isYearStartDateBc?: boolean | null;
    isYearEndDateBc?: boolean | null;
    isYearApprox?: boolean | null;
    isCenturyInterval?: boolean | null;
    centuryStartDate?: number | null;
    centuryEndDate?: number | null;
    isCenturyStartDateBc?: boolean | null;
    isCenturyEndDateBc?: boolean | null;
    isCenturyApprox?: boolean | null;
    centuryStartDateWindow?: string | null;
    centuryEndDateWindow?: string | null;
    nft?: boolean | null;
    nftNotes?: string | null;
    description?: string | null;
    inventoryNumber?: string | null;
    archiveNumber?: string | null;
    catalogNumber?: string | null;
    criticalCatalogue?: boolean | null;
    validated?: boolean | null;
    mainImage?: string | null;
    assetPredictions?: {
      __typename?: "AssetPredictionNode";
      id: string;
      jobUuid?: string | null;
      aiReplacmentValue?: number | null;
      aiLiquidityValue?: number | null;
      aiIntrinsicValue?: number | null;
      type?: ManagementAssetPredictionTypeChoices | null;
    } | null;
    createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    assetLocation?: {
      __typename?: "Address";
      raw?: string | null;
      locality?: string | null;
      state?: string | null;
      country?: string | null;
      streetNumber?: string | null;
      route?: string | null;
    } | null;
    carryingValueAmount?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    printer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    videomaker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    manufacturer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    producer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    collection: {
      __typename?: "CollectionNodeConnection";
      edges: Array<{
        __typename?: "CollectionNodeEdge";
        node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
      } | null>;
    };
    exhibition: {
      __typename?: "ExhibitionNodeConnection";
      edges: Array<{
        __typename?: "ExhibitionNodeEdge";
        node?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
      } | null>;
    };
    documents: {
      __typename?: "AssetDocumentNodeConnection";
      edges: Array<{
        __typename?: "AssetDocumentNodeEdge";
        node?: {
          __typename?: "AssetDocumentNode";
          id: string;
          title?: string | null;
          description?: string | null;
          documentType?: string | null;
          coaType?: string | null;
          issuer?: string | null;
          recipient?: string | null;
          isClassified?: boolean | null;
          releaseDate?: any | null;
          expirationDate?: any | null;
          notes?: string | null;
          archiveNumber?: string | null;
          fineArtsIssueType?: string | null;
          isTemporaryExport?: boolean | null;
          isTemporaryImport?: boolean | null;
          isCriticalCatalog?: boolean | null;
          editor?: string | null;
          curator?: string | null;
          subscriptionDate?: any | null;
          contractSubscriber?: string | null;
          publishingDate?: any | null;
          otherDocumentType?: string | null;
          attachments: {
            __typename?: "AssetDocumentAttachmentNodeConnection";
            edges: Array<{
              __typename?: "AssetDocumentAttachmentNodeEdge";
              node?: {
                __typename?: "AssetDocumentAttachmentNode";
                id: string;
                name?: string | null;
                file: string;
              } | null;
            } | null>;
          };
          createdBy?: { __typename?: "UserType"; name: string } | null;
        } | null;
      } | null>;
    };
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
          createdAt: string;
          actualImageDimensions?: string | null;
        } | null;
      } | null>;
    };
    physicalFeaturesElements: {
      __typename?: "AssetElementNodeConnection";
      edges: Array<{
        __typename?: "AssetElementNodeEdge";
        node?: {
          __typename?: "AssetElementNode";
          id: string;
          name: string;
          materials?: string | null;
          description?: string | null;
          notes?: string | null;
        } | null;
      } | null>;
    };
    presentValues: {
      __typename?: "AssetPresentValueNodeConnection";
      edges: Array<{
        __typename?: "AssetPresentValueNodeEdge";
        node?: {
          __typename?: "AssetPresentValueNode";
          id: string;
          estimateDate?: any | null;
          estimateReason?: string | null;
          notes?: string | null;
          file?: string | null;
          fileTitle?: string | null;
          appraisalId?: string | null;
          author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          amount: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          };
        } | null;
      } | null>;
    };
    policyAsset: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: { __typename?: "PolicyAssetNode"; id: string } | null;
      } | null>;
    };
  } | null;
};

export type AssetPredictionQueryVariables = Exact<{
  id: Scalars["ID"];
  jobUuid: Scalars["String"];
}>;

export type AssetPredictionQuery = {
  __typename?: "Query";
  assetPrediction?: {
    __typename?: "AssetPredictionNode";
    id: string;
    jobUuid?: string | null;
    aiReplacmentValue?: number | null;
    aiLiquidityValue?: number | null;
    aiIntrinsicValue?: number | null;
    type?: ManagementAssetPredictionTypeChoices | null;
  } | null;
};

export type GetAssetByIdForReviewQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetByIdForReviewQuery = {
  __typename?: "Query";
  asset?: {
    __typename: "AssetNode";
    id: string;
    title: string;
    inventoryNumber?: string | null;
    technique?: string | null;
    genericDimensions?: string | null;
    actualDateAndPeriod?: string | null;
    category?: string | null;
    mainImage?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
};

export type GetMediaAssetByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaAssetByIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAllAssetsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  validated?: InputMaybe<Scalars["Boolean"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  category_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  subcategory_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  location?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllAssetsQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        availability: string;
        title: string;
        category?: string | null;
        subcategory?: string | null;
        author?: string | null;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated?: boolean | null;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        createdBy?: {
          __typename?: "UserType";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        assetPredictions?: {
          __typename?: "AssetPredictionNode";
          id: string;
          jobUuid?: string | null;
          aiReplacmentValue?: number | null;
          aiLiquidityValue?: number | null;
          aiIntrinsicValue?: number | null;
          type?: ManagementAssetPredictionTypeChoices | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllAssetsForUserQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetAllAssetsForUserQuery = {
  __typename?: "Query";
  allAssetsForUser?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category?: string | null;
        subcategory?: string | null;
        author?: string | null;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated?: boolean | null;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllAssetExceptUserQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetAllAssetExceptUserQuery = {
  __typename?: "Query";
  notUserAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category?: string | null;
        subcategory?: string | null;
        author?: string | null;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated?: boolean | null;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        collection: {
          __typename?: "CollectionNodeConnection";
          edges: Array<{
            __typename?: "CollectionNodeEdge";
            node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetMutationVariables = Exact<{
  input: CreateAssetInput;
}>;

export type CreateAssetMutation = {
  __typename?: "Mutation";
  createAsset?: {
    __typename?: "CreateAssetPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string | null;
      creationPeriod?: string | null;
      carryingValueFile?: string | null;
      actualDateAndPeriod?: string | null;
      category?: string | null;
      genericDimensions?: string | null;
      inventoryNumber?: string | null;
      technique?: string | null;
    } | null;
  } | null;
};

export type UpdateAssetMutationVariables = Exact<{
  input: UpdateAssetInput;
}>;

export type UpdateAssetMutation = {
  __typename?: "Mutation";
  updateAsset?: {
    __typename?: "UpdateAssetPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string | null;
      creationPeriod?: string | null;
      carryingValueFile?: string | null;
    } | null;
  } | null;
};

export type ChangeAssetCategoryMutationVariables = Exact<{
  input: ChangeAssetCategoryInput;
}>;

export type ChangeAssetCategoryMutation = {
  __typename?: "Mutation";
  changeAssetCategory?: {
    __typename?: "ChangeAssetCategoryPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      category?: string | null;
      subcategory?: string | null;
    } | null;
  } | null;
};

export type DeleteAssetMutationVariables = Exact<{
  input: DeleteAssetInput;
}>;

export type DeleteAssetMutation = {
  __typename?: "Mutation";
  deleteAsset?: {
    __typename?: "DeleteAssetPayload";
    asset?: { __typename?: "AssetNode"; id: string } | null;
  } | null;
};

export type GetAssetElementsByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetElementsByAssetIdQuery = {
  __typename?: "Query";
  allAssetElements?: {
    __typename?: "AssetElementNodeConnection";
    edges: Array<{
      __typename?: "AssetElementNodeEdge";
      node?: {
        __typename?: "AssetElementNode";
        id: string;
        name: string;
        description?: string | null;
        notes?: string | null;
        materials?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetPresentValuesByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetPresentValuesByAssetIdQuery = {
  __typename?: "Query";
  allAssetPresentValues?: {
    __typename?: "AssetPresentValueNodeConnection";
    edges: Array<{
      __typename?: "AssetPresentValueNodeEdge";
      node?: {
        __typename?: "AssetPresentValueNode";
        id: string;
        estimateDate?: any | null;
        estimateReason?: string | null;
        notes?: string | null;
        fileTitle?: string | null;
        file?: string | null;
        amount: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        };
        author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetElementMutationVariables = Exact<{
  input: CreateAssetElementInput;
}>;

export type CreateAssetElementMutation = {
  __typename?: "Mutation";
  createAssetElement?: {
    __typename?: "CreateAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type CreateAssetPresentValueMutationVariables = Exact<{
  input: CreateAssetPresentValueInput;
}>;

export type CreateAssetPresentValueMutation = {
  __typename?: "Mutation";
  createAssetPresentValue?: {
    __typename?: "CreateAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type UpdateAssetElementMutationVariables = Exact<{
  input: UpdateAssetElementInput;
}>;

export type UpdateAssetElementMutation = {
  __typename?: "Mutation";
  updateAssetElement?: {
    __typename?: "UpdateAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type UpdateAssetPresentValueMutationVariables = Exact<{
  input: UpdateAssetPresentValueInput;
}>;

export type UpdateAssetPresentValueMutation = {
  __typename?: "Mutation";
  updateAssetPresentValue?: {
    __typename?: "UpdateAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type DeleteAssetElementMutationVariables = Exact<{
  input: DeleteAssetElementInput;
}>;

export type DeleteAssetElementMutation = {
  __typename?: "Mutation";
  deleteAssetElement?: {
    __typename?: "DeleteAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type DeleteAssetPresentValueMutationVariables = Exact<{
  input: DeleteAssetPresentValueInput;
}>;

export type DeleteAssetPresentValueMutation = {
  __typename?: "Mutation";
  deleteAssetPresentValue?: {
    __typename?: "DeleteAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type AddAssetMediaMutationVariables = Exact<{
  input: AddAssetMediaInput;
}>;

export type AddAssetMediaMutation = {
  __typename?: "Mutation";
  addAssetMedia?: {
    __typename?: "AddAssetMediaPayload";
    media?: { __typename?: "MediaNode"; id: string; file: string } | null;
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AddMultipleAssetMediaMutationVariables = Exact<{
  input: AddMultipleAssetMediaInput;
}>;

export type AddMultipleAssetMediaMutation = {
  __typename?: "Mutation";
  addMultipleAssetMedia?: {
    __typename?: "AddMultipleAssetMediaPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteAssetMediaMutationVariables = Exact<{
  input: DeleteAssetMediaInput;
}>;

export type DeleteAssetMediaMutation = {
  __typename?: "Mutation";
  deleteAssetMedia?: {
    __typename?: "DeleteAssetMediaPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GetAssetDocumentsByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetDocumentsByAssetIdQuery = {
  __typename?: "Query";
  allAssetDocuments?: {
    __typename?: "AssetDocumentNodeConnection";
    edges: Array<{
      __typename?: "AssetDocumentNodeEdge";
      node?: {
        __typename?: "AssetDocumentNode";
        id: string;
        title?: string | null;
        description?: string | null;
        documentType?: string | null;
        coaType?: string | null;
        issuer?: string | null;
        recipient?: string | null;
        isClassified?: boolean | null;
        releaseDate?: any | null;
        expirationDate?: any | null;
        notes?: string | null;
        archiveNumber?: string | null;
        fineArtsIssueType?: string | null;
        isTemporaryExport?: boolean | null;
        isTemporaryImport?: boolean | null;
        isCriticalCatalog?: boolean | null;
        editor?: string | null;
        curator?: string | null;
        subscriptionDate?: any | null;
        contractSubscriber?: string | null;
        publishingDate?: any | null;
        otherDocumentType?: string | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
        asset?: { __typename?: "AssetNode"; id: string } | null;
        attachments: {
          __typename?: "AssetDocumentAttachmentNodeConnection";
          edges: Array<{
            __typename?: "AssetDocumentAttachmentNodeEdge";
            node?: {
              __typename?: "AssetDocumentAttachmentNode";
              id: string;
              name?: string | null;
              file: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetDocumentMutationVariables = Exact<{
  input: CreateAssetDocumentInput;
}>;

export type CreateAssetDocumentMutation = {
  __typename?: "Mutation";
  createAssetDocument?: {
    __typename?: "CreateAssetDocumentPayload";
    assetDocument?: {
      __typename?: "AssetDocumentNode";
      id: string;
      title?: string | null;
      description?: string | null;
      documentType?: string | null;
      coaType?: string | null;
      issuer?: string | null;
      recipient?: string | null;
      isClassified?: boolean | null;
      releaseDate?: any | null;
      expirationDate?: any | null;
      notes?: string | null;
      archiveNumber?: string | null;
      fineArtsIssueType?: string | null;
      isTemporaryExport?: boolean | null;
      isTemporaryImport?: boolean | null;
      isCriticalCatalog?: boolean | null;
      editor?: string | null;
      curator?: string | null;
      subscriptionDate?: any | null;
      contractSubscriber?: string | null;
      publishingDate?: any | null;
      otherDocumentType?: string | null;
      asset?: { __typename?: "AssetNode"; id: string } | null;
    } | null;
  } | null;
};

export type UpdateAssetDocumentMutationVariables = Exact<{
  input: UpdateAssetDocumentInput;
}>;

export type UpdateAssetDocumentMutation = {
  __typename?: "Mutation";
  updateAssetDocument?: {
    __typename?: "UpdateAssetDocumentPayload";
    assetDocument?: {
      __typename?: "AssetDocumentNode";
      id: string;
      title?: string | null;
      description?: string | null;
      documentType?: string | null;
      coaType?: string | null;
      issuer?: string | null;
      recipient?: string | null;
      isClassified?: boolean | null;
      releaseDate?: any | null;
      expirationDate?: any | null;
      notes?: string | null;
      archiveNumber?: string | null;
      fineArtsIssueType?: string | null;
      isTemporaryExport?: boolean | null;
      isTemporaryImport?: boolean | null;
      isCriticalCatalog?: boolean | null;
      editor?: string | null;
      curator?: string | null;
      subscriptionDate?: any | null;
      contractSubscriber?: string | null;
      publishingDate?: any | null;
      otherDocumentType?: string | null;
      asset?: { __typename?: "AssetNode"; id: string } | null;
    } | null;
  } | null;
};

export type DeleteAssetDocumentMutationVariables = Exact<{
  input: DeleteAssetDocumentInput;
}>;

export type DeleteAssetDocumentMutation = {
  __typename?: "Mutation";
  deleteAssetDocument?: {
    __typename?: "DeleteAssetDocumentPayload";
    assetDocument?: {
      __typename?: "AssetDocumentNode";
      id: string;
      title?: string | null;
      description?: string | null;
      documentType?: string | null;
      coaType?: string | null;
      issuer?: string | null;
      recipient?: string | null;
      isClassified?: boolean | null;
      releaseDate?: any | null;
      expirationDate?: any | null;
      notes?: string | null;
      archiveNumber?: string | null;
      fineArtsIssueType?: string | null;
      isTemporaryExport?: boolean | null;
      isTemporaryImport?: boolean | null;
      isCriticalCatalog?: boolean | null;
      editor?: string | null;
      curator?: string | null;
      subscriptionDate?: any | null;
      contractSubscriber?: string | null;
      publishingDate?: any | null;
      otherDocumentType?: string | null;
      asset?: { __typename?: "AssetNode"; id: string } | null;
    } | null;
  } | null;
};

export type GetAssetDocumentAttachmentsByDocumentIdQueryVariables = Exact<{
  documentId: Scalars["ID"];
}>;

export type GetAssetDocumentAttachmentsByDocumentIdQuery = {
  __typename?: "Query";
  allAssetDocumentAttachments?: {
    __typename?: "AssetDocumentAttachmentNodeConnection";
    edges: Array<{
      __typename?: "AssetDocumentAttachmentNodeEdge";
      node?: {
        __typename?: "AssetDocumentAttachmentNode";
        id: string;
        name?: string | null;
        file: string;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetDocumentAttachmentMutationVariables = Exact<{
  input: CreateAssetDocumentAttachmentInput;
}>;

export type CreateAssetDocumentAttachmentMutation = {
  __typename?: "Mutation";
  createAssetDocumentAttachment?: {
    __typename?: "CreateAssetDocumentAttachmentPayload";
    assetDocumentAttachment?: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string | null;
      file: string;
    } | null;
  } | null;
};

export type UpdateAssetDocumentAttachmentMutationVariables = Exact<{
  input: UpdateAssetDocumentAttachmentInput;
}>;

export type UpdateAssetDocumentAttachmentMutation = {
  __typename?: "Mutation";
  updateAssetDocumentAttachment?: {
    __typename?: "UpdateAssetDocumentAttachmentPayload";
    assetDocumentAttachment?: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string | null;
      file: string;
    } | null;
  } | null;
};

export type DeleteAssetDocumentAttachmentMutationVariables = Exact<{
  input: DeleteAssetDocumentAttachmentInput;
}>;

export type DeleteAssetDocumentAttachmentMutation = {
  __typename?: "Mutation";
  deleteAssetDocumentAttachment?: {
    __typename?: "DeleteAssetDocumentAttachmentPayload";
    assetDocumentAttachment?: {
      __typename?: "AssetDocumentAttachmentNode";
      id: string;
      name?: string | null;
      file: string;
    } | null;
  } | null;
};

export type DeleteAssetCarryingValueFileMutationVariables = Exact<{
  input: DeleteAssetCarryingValueFileInput;
}>;

export type DeleteAssetCarryingValueFileMutation = {
  __typename?: "Mutation";
  deleteAssetCarryingValueFile?: {
    __typename?: "DeleteAssetCarryingValueFilePayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author?: string | null;
      creationPeriod?: string | null;
      carryingValueFile?: string | null;
    } | null;
  } | null;
};

export type ExportAssetPdfMutationVariables = Exact<{
  input: ExportAssetPdfInput;
}>;

export type ExportAssetPdfMutation = {
  __typename?: "Mutation";
  exportAssetPdf?: {
    __typename?: "ExportAssetPDFPayload";
    url?: string | null;
    asset?: { __typename?: "AssetNode"; id: string } | null;
  } | null;
};

export type StartAssetPredictionMutationVariables = Exact<{
  input: StartAssetPredictionInput;
}>;

export type StartAssetPredictionMutation = {
  __typename?: "Mutation";
  startAssetPrediction?: {
    __typename?: "StartAssetPredictionPayload";
    assetPrediction?: {
      __typename?: "AssetPredictionNode";
      jobUuid?: string | null;
      id: string;
    } | null;
  } | null;
};

export type GetAssetPredictionMutationVariables = Exact<{
  input: GetAssetPredictionInput;
}>;

export type GetAssetPredictionMutation = {
  __typename?: "Mutation";
  getAssetPrediction?: {
    __typename?: "GetAssetPredictionPayload";
    assetPrediction?: {
      __typename?: "AssetPredictionNode";
      id: string;
      jobUuid?: string | null;
      aiReplacmentValue?: number | null;
      aiLiquidityValue?: number | null;
      aiIntrinsicValue?: number | null;
      type?: ManagementAssetPredictionTypeChoices | null;
    } | null;
  } | null;
};

export type DuplicateAssetMutationVariables = Exact<{
  input: DuplicateAssetInput;
}>;

export type DuplicateAssetMutation = {
  __typename?: "Mutation";
  duplicateAsset?: {
    __typename?: "DuplicateAssetPayload";
    asset?: { __typename?: "AssetNode"; id: string } | null;
  } | null;
};

export type CsvImportCreateAssetMutationVariables = Exact<{
  input: CsvImportCreateAssetInput;
}>;

export type CsvImportCreateAssetMutation = {
  __typename?: "Mutation";
  csvImportCreateAsset?: {
    __typename?: "CsvImportCreateAssetPayload";
    importCsvResponse?: Array<{
      __typename?: "CsvImportAssetNode";
      parsedRowIndex?: string | null;
      asset?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        author?: string | null;
        creationPeriod?: string | null;
        carryingValueFile?: string | null;
        actualDateAndPeriod?: string | null;
        category?: string | null;
        genericDimensions?: string | null;
        inventoryNumber?: string | null;
        technique?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetAllAssetsMediaBySearchQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  validated?: InputMaybe<Scalars["Boolean"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  category_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  subcategory_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  location?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllAssetsMediaBySearchQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        author?: string | null;
        mainImage?: string | null;
        media: {
          __typename?: "MediaNodeConnection";
          edges: Array<{
            __typename?: "MediaNodeEdge";
            node?: {
              __typename?: "MediaNode";
              id: string;
              name?: string | null;
              file: string;
              createdAt: string;
              createdBy?: { __typename?: "UserType"; name: string } | null;
            } | null;
          } | null>;
        };
        createdBy?: { __typename?: "UserType"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type BillFieldsFragment = {
  __typename?: "ExportDocumentsNode";
  id: string;
  modelName?: string | null;
  isSigned?: boolean | null;
  file: string;
  externalId?: number | null;
  type: BaseExportedDocumentsTypeChoices;
  createdAt: string;
  updatedAt: string;
};

export type GetAllBillsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllBillsQuery = {
  __typename?: "Query";
  allExportedDocuments?: {
    __typename?: "ExportDocumentsNodeConnection";
    edges: Array<{
      __typename?: "ExportDocumentsNodeEdge";
      node?: {
        __typename?: "ExportDocumentsNode";
        id: string;
        modelName?: string | null;
        isSigned?: boolean | null;
        file: string;
        externalId?: number | null;
        type: BaseExportedDocumentsTypeChoices;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null>;
  } | null;
};

export type GetAllTransportationsBillsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllTransportationsBillsQuery = {
  __typename?: "Query";
  allExportedDocuments?: {
    __typename?: "ExportDocumentsNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "ExportDocumentsNodeEdge";
      node?: {
        __typename?: "ExportDocumentsNode";
        id: string;
        isSigned?: boolean | null;
        file: string;
        createdAt: string;
        updatedAt: string;
        createdBy?: { __typename?: "UserType"; email: string; name: string } | null;
        transportation?: {
          __typename?: "TransportationNode";
          id: string;
          numberOfAssets?: number | null;
          transportationId?: string | null;
          createdAt: string;
          deliveryDetails?: {
            __typename?: "DeliveryDetailsNode";
            recipient?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          } | null;
          customer?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          shipper?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          vector?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          createdBy?: { __typename?: "UserType"; name: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllDepositationsBillsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  typeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetAllDepositationsBillsQuery = {
  __typename?: "Query";
  allExportedDocuments?: {
    __typename?: "ExportDocumentsNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "ExportDocumentsNodeEdge";
      node?: {
        __typename?: "ExportDocumentsNode";
        id: string;
        modelName?: string | null;
        isSigned?: boolean | null;
        file: string;
        externalId?: number | null;
        type: BaseExportedDocumentsTypeChoices;
        createdAt: string;
        updatedAt: string;
        createdBy?: { __typename?: "UserType"; email: string; name: string } | null;
        depositation?: {
          __typename?: "DepositationNode";
          id: string;
          numberOfAssets?: number | null;
          createdAt: string;
          customer?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          company?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          createdBy?: { __typename?: "UserType"; name: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type DeleteDepositationBillMutationVariables = Exact<{
  input: DeleteDepositationBillInput;
}>;

export type DeleteDepositationBillMutation = {
  __typename?: "Mutation";
  deleteDepositationBill?: {
    __typename?: "DeleteDepositationBillPayload";
    depositationBill?: { __typename?: "ExitNode"; id: string } | null;
  } | null;
};

export type DeleteTransportationBillMutationVariables = Exact<{
  input: DeleteTransportationBillInput;
}>;

export type DeleteTransportationBillMutation = {
  __typename?: "Mutation";
  deleteTransportationBill?: {
    __typename?: "DeleteTransportationBillPayload";
    transportationBill?: { __typename?: "ExportDocumentsNode"; id: string } | null;
  } | null;
};

export type GetCurrentClientQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCurrentClientQuery = {
  __typename?: "Query";
  client?: {
    __typename?: "ClientNode";
    createdOn: any;
    customerType: string;
    currency: string;
    dashboardBaseUrl: string;
    id: string;
    language: string;
    displayName?: string | null;
    usedInvites: number;
    location: string;
    logo?: string | null;
    backgroundColor?: string | null;
    name: string;
    onTrial?: boolean | null;
    paidUntil: any;
    schemaName: string;
    storageName: string;
    tier: string;
    isInMaintenance?: boolean | null;
    timezone: string;
    tierSpecs?: {
      __typename?: "ClientTierNode";
      maxInvites?: number | null;
      maxAssets?: number | null;
      maxProjects?: number | null;
      maxStorage?: number | null;
      maxStoragePerUpload?: number | null;
      maxStoragePerProject?: number | null;
      maxStoragePerUser?: number | null;
      maxUploadsPerProject?: number | null;
      maxUploads?: number | null;
      maxUsers?: number | null;
      name?: string | null;
      price?: number | null;
    } | null;
    defaultInsuranceCompany?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    defaultBroker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    depositationCompanyTenantDefault?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
  } | null;
};

export type GetCurrentClientsByHostnameQueryVariables = Exact<{
  dashboardBaseUrl: Scalars["String"];
}>;

export type GetCurrentClientsByHostnameQuery = {
  __typename?: "Query";
  allClient?: {
    __typename?: "ClientNodeConnection";
    edges: Array<{
      __typename?: "ClientNodeEdge";
      node?: {
        __typename?: "ClientNode";
        createdOn: any;
        customerType: string;
        currency: string;
        dashboardBaseUrl: string;
        id: string;
        language: string;
        displayName?: string | null;
        usedInvites: number;
        location: string;
        logo?: string | null;
        backgroundColor?: string | null;
        name: string;
        onTrial?: boolean | null;
        paidUntil: any;
        schemaName: string;
        storageName: string;
        tier: string;
        isInMaintenance?: boolean | null;
        timezone: string;
        tierSpecs?: {
          __typename?: "ClientTierNode";
          maxInvites?: number | null;
          maxAssets?: number | null;
          maxProjects?: number | null;
          maxStorage?: number | null;
          maxStoragePerUpload?: number | null;
          maxStoragePerProject?: number | null;
          maxStoragePerUser?: number | null;
          maxUploadsPerProject?: number | null;
          maxUploads?: number | null;
          maxUsers?: number | null;
          name?: string | null;
          price?: number | null;
        } | null;
        defaultInsuranceCompany?: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
        } | null;
        defaultBroker?: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
        } | null;
        depositationCompanyTenantDefault?: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCurrentClientUsageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCurrentClientUsageQuery = {
  __typename?: "Query";
  client?: {
    __typename?: "ClientNode";
    currentAssets?: number | null;
    currentInvites?: number | null;
    currentProjects?: number | null;
    currentUsers?: number | null;
    currentStorage?: number | null;
  } | null;
};

export type UpdateClientLogoMutationVariables = Exact<{
  input: UpdateClientLogoInput;
}>;

export type UpdateClientLogoMutation = {
  __typename?: "Mutation";
  updateClientLogo?: {
    __typename?: "UpdateClientLogoPayload";
    client?: { __typename?: "ClientNode"; id: string } | null;
  } | null;
};

export type UpdateClientMutationVariables = Exact<{
  input: UpdateClientInput;
}>;

export type UpdateClientMutation = {
  __typename?: "Mutation";
  updateClient?: {
    __typename?: "UpdateClientPayload";
    client?: { __typename?: "ClientNode"; id: string } | null;
  } | null;
};

export type UpdateClientBackgroundColorMutationVariables = Exact<{
  input: UpdateClientBackgroundColorInput;
}>;

export type UpdateClientBackgroundColorMutation = {
  __typename?: "Mutation";
  updateClientBackgroundColor?: {
    __typename?: "UpdateClientBackgroundColorPayload";
    client?: { __typename?: "ClientNode"; id: string } | null;
  } | null;
};

export type ResetClientLoginMutationVariables = Exact<{
  input: ResetClientLoginInput;
}>;

export type ResetClientLoginMutation = {
  __typename?: "Mutation";
  resetClientLogin?: {
    __typename?: "ResetClientLoginPayload";
    client?: { __typename?: "ClientNode"; id: string } | null;
  } | null;
};

export type MediaFragment = {
  __typename?: "MediaNode";
  id: string;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  deleted?: string | null;
  deletedByCascade: boolean;
  name?: string | null;
  file: string;
  description?: string | null;
  mediaType?: string | null;
  isMainImage?: boolean | null;
  imageType?: string | null;
  notes?: string | null;
  audioVideoFileType?: string | null;
  creationDate?: any | null;
  fileAuthor?: string | null;
  fileSize?: string | null;
  photographicEquipment?: string | null;
  matrixType?: string | null;
  isGlassPlexyAcetate?: boolean | null;
  fileWidth?: number | null;
  fileHeight?: number | null;
  fileDuration?: string | null;
  createdFrom?: string | null;
  createdBy?: { __typename?: "UserType"; name: string } | null;
  updatedBy?: { __typename?: "UserType"; name: string } | null;
};

export type CollectionAnagraphicFieldsFragment = {
  __typename?: "CollectionNode";
  id: string;
  name: string;
  description?: string | null;
  notes?: string | null;
  image?: string | null;
  totalAssetsNumber?: number | null;
  validatedAssetsNumber?: number | null;
  createdFrom?: string | null;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  deletedByCascade: boolean;
  createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  assetSet: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        description?: string | null;
        author?: string | null;
        category?: string | null;
        genericDimensions?: string | null;
        creationDate?: string | null;
        mainImage?: string | null;
        technique?: string | null;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  };
};

export type GetCollectionByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCollectionByIdQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "CollectionNode";
    id: string;
    name: string;
    description?: string | null;
    notes?: string | null;
    image?: string | null;
    totalAssetsNumber?: number | null;
    validatedAssetsNumber?: number | null;
    createdFrom?: string | null;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    deletedByCascade: boolean;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
    createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category?: string | null;
          genericDimensions?: string | null;
          creationDate?: string | null;
          mainImage?: string | null;
          technique?: string | null;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAllCollectionImagesByIdQueryVariables = Exact<{
  id: Scalars["ID"];
  token?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllCollectionImagesByIdQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "CollectionNode";
    id: string;
    name: string;
    description?: string | null;
    notes?: string | null;
    image?: string | null;
    totalAssetsNumber?: number | null;
    validatedAssetsNumber?: number | null;
    createdFrom?: string | null;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    deletedByCascade: boolean;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          mainImage?: string | null;
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category?: string | null;
          genericDimensions?: string | null;
          creationDate?: string | null;
          technique?: string | null;
          actualDateAndPeriod?: string | null;
          media: {
            __typename?: "MediaNodeConnection";
            edges: Array<{
              __typename?: "MediaNodeEdge";
              node?: {
                __typename?: "MediaNode";
                id: string;
                createdAt: string;
                updatedAt: string;
                uuid: string;
                deleted?: string | null;
                deletedByCascade: boolean;
                name?: string | null;
                file: string;
                description?: string | null;
                mediaType?: string | null;
                isMainImage?: boolean | null;
                imageType?: string | null;
                notes?: string | null;
                audioVideoFileType?: string | null;
                creationDate?: any | null;
                fileAuthor?: string | null;
                fileSize?: string | null;
                photographicEquipment?: string | null;
                matrixType?: string | null;
                isGlassPlexyAcetate?: boolean | null;
                fileWidth?: number | null;
                fileHeight?: number | null;
                fileDuration?: string | null;
                createdFrom?: string | null;
                createdBy?: { __typename?: "UserType"; name: string } | null;
                updatedBy?: { __typename?: "UserType"; name: string } | null;
              } | null;
            } | null>;
          };
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
      } | null>;
    };
    createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  } | null;
};

export type CollectionFindingFieldsFragment = {
  __typename?: "CollectionNode";
  id: string;
  name: string;
  description?: string | null;
  notes?: string | null;
};

export type GetAllCollectionsQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  createdFrom?: InputMaybe<Scalars["String"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllCollectionsQuery = {
  __typename?: "Query";
  allCollections?: {
    __typename?: "CollectionNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "CollectionNodeEdge";
      node?: {
        __typename?: "CollectionNode";
        id: string;
        name: string;
        description?: string | null;
        image?: string | null;
        notes?: string | null;
        totalAssetsNumber?: number | null;
        validatedAssetsNumber?: number | null;
        createdFrom?: string | null;
        actualStatusObjects?: string | null;
        createdAt: string;
        createdBy?: { __typename?: "UserType"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetMediaCollectionByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaCollectionByIdQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "CollectionNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateCollectionMutationVariables = Exact<{
  input: CreateCollectionInput;
}>;

export type CreateCollectionMutation = {
  __typename?: "Mutation";
  createCollection?: {
    __typename?: "CreateCollectionPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      name: string;
      description?: string | null;
      notes?: string | null;
    } | null;
  } | null;
};

export type UpdateCollectionMutationVariables = Exact<{
  input: UpdateCollectionInput;
}>;

export type UpdateCollectionMutation = {
  __typename?: "Mutation";
  updateCollection?: {
    __typename?: "UpdateCollectionPayload";
    collection?: { __typename?: "CollectionNode"; id: string } | null;
  } | null;
};

export type DeleteCollectionMutationVariables = Exact<{
  input: DeleteCollectionInput;
}>;

export type DeleteCollectionMutation = {
  __typename?: "Mutation";
  deleteCollection?: {
    __typename?: "DeleteCollectionPayload";
    collection?: { __typename?: "CollectionNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromCollectionMutationVariables = Exact<{
  input: DetachAssetFromCollectionInput;
}>;

export type DetachAssetFromCollectionMutation = {
  __typename?: "Mutation";
  detachAssetFromCollection?: {
    __typename?: "DetachAssetFromCollectionPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: { __typename?: "AssetNode"; id: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DetachAssetsFromCollectionMutationVariables = Exact<{
  input: DetachAssetsFromCollectionInput;
}>;

export type DetachAssetsFromCollectionMutation = {
  __typename?: "Mutation";
  detachAssetsFromCollection?: {
    __typename?: "DetachAssetsFromCollectionPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: { __typename?: "AssetNode"; id: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportCollectionPdfMutationVariables = Exact<{
  input: ExportCollectionPdfInput;
}>;

export type ExportCollectionPdfMutation = {
  __typename?: "Mutation";
  exportCollectionPdf?: {
    __typename?: "ExportCollectionPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type AddCollectionMediaMutationVariables = Exact<{
  input: AddCollectionMediaInput;
}>;

export type AddCollectionMediaMutation = {
  __typename?: "Mutation";
  addCollectionMedia?: {
    __typename?: "AddCollectionMediaPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteCollectionMediaMutationVariables = Exact<{
  input: DeleteCollectionMediaInput;
}>;

export type DeleteCollectionMediaMutation = {
  __typename?: "Mutation";
  deleteCollectionMedia?: {
    __typename?: "DeleteCollectionMediaPayload";
    collection?: {
      __typename?: "CollectionNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteCollectionImageMutationVariables = Exact<{
  input: DeleteCollectionImageInput;
}>;

export type DeleteCollectionImageMutation = {
  __typename?: "Mutation";
  deleteCollectionImage?: {
    __typename?: "DeleteCollectionImagePayload";
    collection?: { __typename?: "CollectionNode"; id: string; name: string } | null;
  } | null;
};

export type ConditionStatusReportFindingFieldsFragment = {
  __typename?: "ConditionStatusReportNode";
  id: string;
  reportStatus?: string | null;
  reportDate?: string | null;
  reportType?: string | null;
  isSigned?: boolean | null;
  reportMotivation?: string | null;
  reportMotivationDescription?: string | null;
  reportAsset?: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
  reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type ConditionStatusReportAnagraphicFieldsFragment = {
  __typename?: "ConditionStatusReportNode";
  id: string;
  reportStatus?: string | null;
  environmentalConditionsAbnormalHumidityEvent?: string | null;
  environmentalConditionsAbnormalLightEvent?: string | null;
  environmentalConditionsAbnormalTemperatureEvent?: string | null;
  environmentalConditionsLightUnit?: string | null;
  environmentalConditionsOptimalHumidity?: number | null;
  environmentalConditionsOptimalLightLevelIntensity?: number | null;
  environmentalConditionsOptimalTemperature?: number | null;
  environmentalConditionsTemperatureUnit?: string | null;
  isSigned?: boolean | null;
  exhibitionEventFrom?: string | null;
  exhibitionEventTo?: string | null;
  exhibitionLoanFrom?: string | null;
  exhibitionLoanTo?: string | null;
  exhibitionLocation?: string | null;
  collectionPublicName?: string | null;
  exhibitionNotes?: string | null;
  exhibitionPermanent?: boolean | null;
  exhibitionTitle?: string | null;
  logisticsAirConditioningTransportConditions?: string | null;
  logisticsDeliveryAddress?: string | null;
  logisticsDeliveryCity?: string | null;
  logisticsDeliveryCountry?: string | null;
  logisticsDeliveryDate?: string | null;
  logisticsDeliveryState?: string | null;
  logisticsDeliveryZip?: string | null;
  logisticsId?: string | null;
  logisticsNumberOfPackages?: number | null;
  logisticsPickupAddress?: string | null;
  logisticsPickupCity?: string | null;
  logisticsPickupCountry?: string | null;
  logisticsPickupDate?: string | null;
  logisticsPickupState?: string | null;
  logisticsPickupZip?: string | null;
  reportDate?: string | null;
  reportDetailsAssetCondition?: string | null;
  reportDetailsAssetDamageDescription?: string | null;
  reportDetailsExamConditions?: string | null;
  reportDetailsExamConditionsNote?: string | null;
  reportDetailsExamLocation?: string | null;
  reportDetailsExhaminationTimeTechnicalDescription?: string | null;
  reportDetailsPackagingGeneralConditions?: string | null;
  reportDetailsExaminedPackages?: string | null;
  reportDetailsPackagingStatusDescription?: string | null;
  reportDetailsProtectionsSupportsCondition?: string | null;
  reportDetailsProtectionsSupportsDamageDescription?: string | null;
  reportExaminationType?: string | null;
  reportMotivation?: string | null;
  reportMotivationDescription?: string | null;
  reportType?: string | null;
  settingsConservation?: string | null;
  settingsExaminerComments?: string | null;
  settingsHandlingRequirements?: string | null;
  settingsHasBarriers?: boolean | null;
  settingsInjuryRisk?: string | null;
  settingsLenderRequests?: string | null;
  settingsNotes?: string | null;
  valuePercentageDepreciation?: number | null;
  exhibitionCurator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  exhibitionHostEntity?: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
  } | null;
  exhibitionLender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  exhibitionPlanner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  logisticsCarrier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  logisticsDeliveryLocation?: {
    __typename?: "Address";
    raw?: string | null;
    locality?: string | null;
    state?: string | null;
    country?: string | null;
    streetNumber?: string | null;
    route?: string | null;
  } | null;
  logisticsPickupLocation?: {
    __typename?: "Address";
    raw?: string | null;
    locality?: string | null;
    state?: string | null;
    country?: string | null;
    streetNumber?: string | null;
    route?: string | null;
  } | null;
  logisticsRecipient?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  logisticsSender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  reportAsset?: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    hasBarriers?: boolean | null;
    injuryRisk?: string | null;
    injuryRiskDescription?: string | null;
    conservation?: string | null;
    lenderRequests?: string | null;
    optimalLightLevelIntensity?: number | null;
    lightUnit?: string | null;
    optimalTemperature?: number | null;
    temperatureUnit?: string | null;
    optimalHumidity?: number | null;
    hardwareToolsInstallation?: string | null;
    settingNotes?: string | null;
    barriersDetails?: string | null;
    mouldingSupportBase?: string | null;
    handlingRequirements?: string | null;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
  valueEstimatedValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  valueEstimatedValueExaminationTime?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
        createdAt: string;
      } | null;
    } | null>;
  };
};

export type GetAllConditionStatusReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllConditionStatusReportsQuery = {
  __typename?: "Query";
  allConditionStatusReports?: {
    __typename?: "ConditionStatusReportNodeConnection";
    edges: Array<{
      __typename?: "ConditionStatusReportNodeEdge";
      node?: {
        __typename?: "ConditionStatusReportNode";
        createdAt: string;
        id: string;
        reportStatus?: string | null;
        reportDate?: string | null;
        reportType?: string | null;
        isSigned?: boolean | null;
        reportMotivation?: string | null;
        reportMotivationDescription?: string | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
        reportAsset?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
        reportExaminer?: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetConditionStatusReportByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetConditionStatusReportByIdQuery = {
  __typename?: "Query";
  conditionStatusReport?: {
    __typename?: "ConditionStatusReportNode";
    id: string;
    reportStatus?: string | null;
    environmentalConditionsAbnormalHumidityEvent?: string | null;
    environmentalConditionsAbnormalLightEvent?: string | null;
    environmentalConditionsAbnormalTemperatureEvent?: string | null;
    environmentalConditionsLightUnit?: string | null;
    environmentalConditionsOptimalHumidity?: number | null;
    environmentalConditionsOptimalLightLevelIntensity?: number | null;
    environmentalConditionsOptimalTemperature?: number | null;
    environmentalConditionsTemperatureUnit?: string | null;
    isSigned?: boolean | null;
    exhibitionEventFrom?: string | null;
    exhibitionEventTo?: string | null;
    exhibitionLoanFrom?: string | null;
    exhibitionLoanTo?: string | null;
    exhibitionLocation?: string | null;
    collectionPublicName?: string | null;
    exhibitionNotes?: string | null;
    exhibitionPermanent?: boolean | null;
    exhibitionTitle?: string | null;
    logisticsAirConditioningTransportConditions?: string | null;
    logisticsDeliveryAddress?: string | null;
    logisticsDeliveryCity?: string | null;
    logisticsDeliveryCountry?: string | null;
    logisticsDeliveryDate?: string | null;
    logisticsDeliveryState?: string | null;
    logisticsDeliveryZip?: string | null;
    logisticsId?: string | null;
    logisticsNumberOfPackages?: number | null;
    logisticsPickupAddress?: string | null;
    logisticsPickupCity?: string | null;
    logisticsPickupCountry?: string | null;
    logisticsPickupDate?: string | null;
    logisticsPickupState?: string | null;
    logisticsPickupZip?: string | null;
    reportDate?: string | null;
    reportDetailsAssetCondition?: string | null;
    reportDetailsAssetDamageDescription?: string | null;
    reportDetailsExamConditions?: string | null;
    reportDetailsExamConditionsNote?: string | null;
    reportDetailsExamLocation?: string | null;
    reportDetailsExhaminationTimeTechnicalDescription?: string | null;
    reportDetailsPackagingGeneralConditions?: string | null;
    reportDetailsExaminedPackages?: string | null;
    reportDetailsPackagingStatusDescription?: string | null;
    reportDetailsProtectionsSupportsCondition?: string | null;
    reportDetailsProtectionsSupportsDamageDescription?: string | null;
    reportExaminationType?: string | null;
    reportMotivation?: string | null;
    reportMotivationDescription?: string | null;
    reportType?: string | null;
    settingsConservation?: string | null;
    settingsExaminerComments?: string | null;
    settingsHandlingRequirements?: string | null;
    settingsHasBarriers?: boolean | null;
    settingsInjuryRisk?: string | null;
    settingsLenderRequests?: string | null;
    settingsNotes?: string | null;
    valuePercentageDepreciation?: number | null;
    exhibitionCurator?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    exhibitionHostEntity?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    exhibitionLender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    exhibitionPlanner?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    logisticsCarrier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    logisticsDeliveryLocation?: {
      __typename?: "Address";
      raw?: string | null;
      locality?: string | null;
      state?: string | null;
      country?: string | null;
      streetNumber?: string | null;
      route?: string | null;
    } | null;
    logisticsPickupLocation?: {
      __typename?: "Address";
      raw?: string | null;
      locality?: string | null;
      state?: string | null;
      country?: string | null;
      streetNumber?: string | null;
      route?: string | null;
    } | null;
    logisticsRecipient?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
    } | null;
    logisticsSender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    reportAsset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      hasBarriers?: boolean | null;
      injuryRisk?: string | null;
      injuryRiskDescription?: string | null;
      conservation?: string | null;
      lenderRequests?: string | null;
      optimalLightLevelIntensity?: number | null;
      lightUnit?: string | null;
      optimalTemperature?: number | null;
      temperatureUnit?: string | null;
      optimalHumidity?: number | null;
      hardwareToolsInstallation?: string | null;
      settingNotes?: string | null;
      barriersDetails?: string | null;
      mouldingSupportBase?: string | null;
      handlingRequirements?: string | null;
      actualDateAndPeriod?: string | null;
      authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null;
    valueEstimatedValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    valueEstimatedValueExaminationTime?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
          createdAt: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateConditionStatusReportMutationVariables = Exact<{
  input: CreateConditionStatusReportInput;
}>;

export type CreateConditionStatusReportMutation = {
  __typename?: "Mutation";
  createConditionStatusReport?: {
    __typename?: "CreateConditionStatusReportPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      reportStatus?: string | null;
      environmentalConditionsAbnormalHumidityEvent?: string | null;
      environmentalConditionsAbnormalLightEvent?: string | null;
      environmentalConditionsAbnormalTemperatureEvent?: string | null;
      environmentalConditionsLightUnit?: string | null;
      environmentalConditionsOptimalHumidity?: number | null;
      environmentalConditionsOptimalLightLevelIntensity?: number | null;
      environmentalConditionsOptimalTemperature?: number | null;
      environmentalConditionsTemperatureUnit?: string | null;
      exhibitionEventFrom?: string | null;
      exhibitionEventTo?: string | null;
      exhibitionLoanFrom?: string | null;
      exhibitionLoanTo?: string | null;
      exhibitionLocation?: string | null;
      collectionPublicName?: string | null;
      exhibitionNotes?: string | null;
      exhibitionPermanent?: boolean | null;
      exhibitionTitle?: string | null;
      logisticsAirConditioningTransportConditions?: string | null;
      logisticsDeliveryAddress?: string | null;
      logisticsDeliveryCity?: string | null;
      logisticsDeliveryCountry?: string | null;
      logisticsDeliveryDate?: string | null;
      logisticsDeliveryState?: string | null;
      logisticsDeliveryZip?: string | null;
      logisticsId?: string | null;
      logisticsNumberOfPackages?: number | null;
      logisticsPickupAddress?: string | null;
      logisticsPickupCity?: string | null;
      logisticsPickupCountry?: string | null;
      logisticsPickupDate?: string | null;
      logisticsPickupState?: string | null;
      logisticsPickupZip?: string | null;
      reportDate?: string | null;
      reportDetailsAssetCondition?: string | null;
      reportDetailsAssetDamageDescription?: string | null;
      reportDetailsExamConditions?: string | null;
      reportDetailsExamConditionsNote?: string | null;
      reportDetailsExamLocation?: string | null;
      reportDetailsExhaminationTimeTechnicalDescription?: string | null;
      reportDetailsPackagingGeneralConditions?: string | null;
      reportDetailsExaminedPackages?: string | null;
      reportDetailsPackagingStatusDescription?: string | null;
      reportDetailsProtectionsSupportsCondition?: string | null;
      reportDetailsProtectionsSupportsDamageDescription?: string | null;
      reportExaminationType?: string | null;
      reportMotivation?: string | null;
      reportMotivationDescription?: string | null;
      reportType?: string | null;
      settingsConservation?: string | null;
      settingsExaminerComments?: string | null;
      settingsHandlingRequirements?: string | null;
      settingsHasBarriers?: boolean | null;
      settingsInjuryRisk?: string | null;
      settingsLenderRequests?: string | null;
      settingsNotes?: string | null;
      valuePercentageDepreciation?: number | null;
      exhibitionCurator?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionHostEntity?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionLender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionPlanner?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsCarrier?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsDeliveryLocation?: {
        __typename?: "Address";
        raw?: string | null;
        locality?: string | null;
        state?: string | null;
        country?: string | null;
        streetNumber?: string | null;
        route?: string | null;
      } | null;
      logisticsPickupLocation?: {
        __typename?: "Address";
        raw?: string | null;
        locality?: string | null;
        state?: string | null;
        country?: string | null;
        streetNumber?: string | null;
        route?: string | null;
      } | null;
      logisticsRecipient?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsSender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportAsset?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValueExaminationTime?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateConditionStatusReportMutationVariables = Exact<{
  input: UpdateConditionStatusReportInput;
}>;

export type UpdateConditionStatusReportMutation = {
  __typename?: "Mutation";
  updateConditionStatusReport?: {
    __typename?: "UpdateConditionStatusReportPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      reportStatus?: string | null;
      environmentalConditionsAbnormalHumidityEvent?: string | null;
      environmentalConditionsAbnormalLightEvent?: string | null;
      environmentalConditionsAbnormalTemperatureEvent?: string | null;
      environmentalConditionsLightUnit?: string | null;
      environmentalConditionsOptimalHumidity?: number | null;
      environmentalConditionsOptimalLightLevelIntensity?: number | null;
      environmentalConditionsOptimalTemperature?: number | null;
      environmentalConditionsTemperatureUnit?: string | null;
      exhibitionEventFrom?: string | null;
      exhibitionEventTo?: string | null;
      exhibitionLoanFrom?: string | null;
      exhibitionLoanTo?: string | null;
      exhibitionLocation?: string | null;
      collectionPublicName?: string | null;
      exhibitionNotes?: string | null;
      exhibitionPermanent?: boolean | null;
      exhibitionTitle?: string | null;
      logisticsAirConditioningTransportConditions?: string | null;
      logisticsDeliveryAddress?: string | null;
      logisticsDeliveryCity?: string | null;
      logisticsDeliveryCountry?: string | null;
      logisticsDeliveryDate?: string | null;
      logisticsDeliveryState?: string | null;
      logisticsDeliveryZip?: string | null;
      logisticsId?: string | null;
      logisticsNumberOfPackages?: number | null;
      logisticsPickupAddress?: string | null;
      logisticsPickupCity?: string | null;
      logisticsPickupCountry?: string | null;
      logisticsPickupDate?: string | null;
      logisticsPickupState?: string | null;
      logisticsPickupZip?: string | null;
      reportDate?: string | null;
      reportDetailsAssetCondition?: string | null;
      reportDetailsAssetDamageDescription?: string | null;
      reportDetailsExamConditions?: string | null;
      reportDetailsExamConditionsNote?: string | null;
      reportDetailsExamLocation?: string | null;
      reportDetailsExhaminationTimeTechnicalDescription?: string | null;
      reportDetailsPackagingGeneralConditions?: string | null;
      reportDetailsExaminedPackages?: string | null;
      reportDetailsPackagingStatusDescription?: string | null;
      reportDetailsProtectionsSupportsCondition?: string | null;
      reportDetailsProtectionsSupportsDamageDescription?: string | null;
      reportExaminationType?: string | null;
      reportMotivation?: string | null;
      reportMotivationDescription?: string | null;
      reportType?: string | null;
      settingsConservation?: string | null;
      settingsExaminerComments?: string | null;
      settingsHandlingRequirements?: string | null;
      settingsHasBarriers?: boolean | null;
      settingsInjuryRisk?: string | null;
      settingsLenderRequests?: string | null;
      settingsNotes?: string | null;
      valuePercentageDepreciation?: number | null;
      exhibitionCurator?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionHostEntity?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionLender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      exhibitionPlanner?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsCarrier?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsRecipient?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      logisticsSender?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
      } | null;
      reportCompany?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportExaminer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      reportAsset?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
      } | null;
      valueEstimatedValue?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      valueEstimatedValueExaminationTime?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteConditionStatusReportMutationVariables = Exact<{
  input: DeleteConditionStatusReportInput;
}>;

export type DeleteConditionStatusReportMutation = {
  __typename?: "Mutation";
  deleteConditionStatusReport?: {
    __typename?: "DeleteConditionStatusReportPayload";
    deleteConditionStatusReport?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type CloseConditionStatusReportMutationVariables = Exact<{
  input: CloseConditionStatusReportInput;
}>;

export type CloseConditionStatusReportMutation = {
  __typename?: "Mutation";
  closeConditionStatusReport?: {
    __typename?: "CloseConditionStatusReportPayload";
    closeConditionStatusReport?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromConditionStatusReportMutationVariables = Exact<{
  input: DetachAssetFromConditionStatusReportInput;
}>;

export type DetachAssetFromConditionStatusReportMutation = {
  __typename?: "Mutation";
  detachAssetFromConditionStatusReport?: {
    __typename?: "DetachAssetFromConditionStatusReportPayload";
    conditionStatusReport?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type GetMediaConditionStatusReportByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaConditionStatusReportByIdQuery = {
  __typename?: "Query";
  conditionStatusReport?: {
    __typename?: "ConditionStatusReportNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AddConditionStatusReportMediaMutationVariables = Exact<{
  input: AddConditionStatusReportMediaInput;
}>;

export type AddConditionStatusReportMediaMutation = {
  __typename?: "Mutation";
  addConditionStatusReportMedia?: {
    __typename?: "AddConditionStatusReportMediaPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AddMultipleCrsrMediaMutationVariables = Exact<{
  input: AddMultipleCrsrMediaInput;
}>;

export type AddMultipleCrsrMediaMutation = {
  __typename?: "Mutation";
  addMultipleCrsrMedia?: {
    __typename?: "AddMultipleCrsrMediaPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteConditionStatusReportMediaMutationVariables = Exact<{
  input: DeleteConditionStatusReportMediaInput;
}>;

export type DeleteConditionStatusReportMediaMutation = {
  __typename?: "Mutation";
  deleteConditionStatusReportMedia?: {
    __typename?: "DeleteConditionStatusReportMediaPayload";
    conditionStatusReport?: {
      __typename?: "ConditionStatusReportNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportConditionStatusReportPdfMutationVariables = Exact<{
  input: ExportConditionStatusReportPdfInput;
}>;

export type ExportConditionStatusReportPdfMutation = {
  __typename?: "Mutation";
  exportConditionStatusReportPdf?: {
    __typename?: "ExportConditionStatusReportPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
    lang?: string | null;
  } | null;
};

export type DuplicateConditionStatusReportMutationVariables = Exact<{
  input: DuplicateConditionStatusReportInput;
}>;

export type DuplicateConditionStatusReportMutation = {
  __typename?: "Mutation";
  duplicateConditionStatusReport?: {
    __typename?: "DuplicateConditionStatusReportPayload";
    crsr?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type SignConditionStatusReportMutationVariables = Exact<{
  input: SignConditionStatusReportInput;
}>;

export type SignConditionStatusReportMutation = {
  __typename?: "Mutation";
  signConditionStatusReport?: {
    __typename?: "SignConditionStatusReportPayload";
    crsr?: { __typename?: "ConditionStatusReportNode"; id: string } | null;
  } | null;
};

export type GetPortfolioDistributionQueryVariables = Exact<{ [key: string]: never }>;

export type GetPortfolioDistributionQuery = {
  __typename?: "Query";
  portfolioDistribution?: Array<{
    __typename?: "Distribution";
    category?: string | null;
    percentage?: number | null;
    count?: number | null;
    value?: number | null;
  } | null> | null;
};

export type GetLastXUserActionsQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetLastXUserActionsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "UserNode";
    useractionsSet: {
      __typename?: "UserActionsNodeConnection";
      edges: Array<{
        __typename?: "UserActionsNodeEdge";
        node?: {
          __typename?: "UserActionsNode";
          actionTime: string;
          actionType: UsersUserActionsActionTypeChoices;
          createdAt: string;
          meta?: any | null;
          contentObject?: {
            __typename?: "GenericForeignKeyType";
            modelName?: string | null;
            id?: string | null;
            appLabel?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssetsInsuranceStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssetsInsuranceStatusQuery = {
  __typename?: "Query";
  assetsInsuranceStatus?: Array<{
    __typename?: "AssetInsuranceStatus";
    count?: number | null;
    percentage?: number | null;
    status?: string | null;
    color?: string | null;
  } | null> | null;
};

export type GetPoliciesExpiringInXDaysQueryVariables = Exact<{
  expiresAt_Gt: Scalars["DateTime"];
  expiresAt_Lt: Scalars["DateTime"];
}>;

export type GetPoliciesExpiringInXDaysQuery = {
  __typename?: "Query";
  allPolicies?: { __typename?: "PolicyNodeConnection"; totalCount?: number | null } | null;
};

export type GetLastAssetsCreatedByQueryVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type GetLastAssetsCreatedByQuery = {
  __typename?: "Query";
  allAssetsForUser?: {
    __typename?: "AssetNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        availability: string;
        title: string;
        category?: string | null;
        subcategory?: string | null;
        author?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetLastPcPoliciesCreatedByQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetLastPcPoliciesCreatedByQuery = {
  __typename?: "Query";
  allPolicies?: { __typename?: "PolicyNodeConnection"; totalCount?: number | null } | null;
};

export type GetLastTePoliciesCreatedByQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetLastTePoliciesCreatedByQuery = {
  __typename?: "Query";
  allPolicies?: { __typename?: "PolicyNodeConnection"; totalCount?: number | null } | null;
};

export type GetInternalUsersCountQueryVariables = Exact<{
  userType?: InputMaybe<Scalars["String"]>;
}>;

export type GetInternalUsersCountQuery = {
  __typename?: "Query";
  allUsers?: { __typename?: "UserNodeConnection"; totalCount?: number | null } | null;
};

export type GetUsedInvitesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsedInvitesQuery = {
  __typename?: "Query";
  client?: {
    __typename?: "ClientNode";
    usedInvites: number;
    tierSpecs?: { __typename?: "ClientTierNode"; maxInvites?: number | null } | null;
  } | null;
};

export type GetCollectionsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetCollectionsCreatedByCountQuery = {
  __typename?: "Query";
  allCollections?: { __typename?: "CollectionNodeConnection"; totalCount?: number | null } | null;
};

export type GetExhibitionsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetExhibitionsCreatedByCountQuery = {
  __typename?: "Query";
  allExhibitions?: { __typename?: "ExhibitionNodeConnection"; totalCount?: number | null } | null;
};

export type GetCrsRsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetCrsRsCreatedByCountQuery = {
  __typename?: "Query";
  allConditionStatusReports?: {
    __typename?: "ConditionStatusReportNodeConnection";
    totalCount?: number | null;
  } | null;
};

export type GetAppraisalsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetAppraisalsCreatedByCountQuery = {
  __typename?: "Query";
  allAppraisals?: { __typename?: "AppraisalNodeConnection"; totalCount?: number | null } | null;
};

export type GetRestorationsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetRestorationsCreatedByCountQuery = {
  __typename?: "Query";
  allRestorations?: { __typename?: "RestorationNodeConnection"; totalCount?: number | null } | null;
};

export type GetDepositationsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetDepositationsCreatedByCountQuery = {
  __typename?: "Query";
  allDepositations?: {
    __typename?: "DepositationNodeConnection";
    totalCount?: number | null;
  } | null;
};

export type GetTransportationsCreatedByCountQueryVariables = Exact<{
  createdBy: Scalars["ID"];
}>;

export type GetTransportationsCreatedByCountQuery = {
  __typename?: "Query";
  allTransportations?: {
    __typename?: "TransportationNodeConnection";
    totalCount?: number | null;
  } | null;
};

export type GetAssetsSlogStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssetsSlogStatusQuery = {
  __typename?: "Query";
  assetsSlogStatus?: Array<{
    __typename?: "AssetSlogStatus";
    status?: string | null;
    percentage?: number | null;
    count?: number | null;
    color?: string | null;
  } | null> | null;
};

export type DepositationFieldsFragment = {
  __typename?: "DepositationNode";
  areaDescription?: string | null;
  areaNotes?: string | null;
  areaType?: SlogDepositationAreaTypeChoices | null;
  areaTypeDescription?: SlogDepositationAreaTypeDescriptionChoices | null;
  coverType?: SlogDepositationCoverTypeChoices | null;
  createdAt: string;
  depositationId?: string | null;
  description?: string | null;
  evaluationType?: SlogDepositationEvaluationTypeChoices | null;
  filingNumber: string;
  id: string;
  instructions?: string | null;
  insuranceNotes?: string | null;
  insuranceStatus: SlogDepositationInsuranceStatusChoices;
  insuredFrom?: string | null;
  insuredTo?: string | null;
  isConfirmed: boolean;
  isModifiable?: boolean | null;
  notes?: string | null;
  numberOfAssets?: number | null;
  numberOfMedia?: number | null;
  numberOfPackages?: number | null;
  status?: SlogDepositationStatusChoices | null;
  type?: SlogDepositationTypeChoices | null;
  info?: string | null;
  updatedAt: string;
  uuid: string;
  methodAndAreaOfStorage?: string | null;
  transportationRequiredPersonnel?: string | null;
  startDate?: string | null;
  driver?: string | null;
  customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  company?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  courier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  location?: {
    __typename?: "OfficeNode";
    id: string;
    name?: string | null;
    address?: string | null;
  } | null;
  createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
};

export type DepositationsAssetsFieldsFragment = {
  __typename?: "DepositationAssetNode";
  container?: string | null;
  createdAt: string;
  endDate?: string | null;
  packageWeightInfo?: string | null;
  packageDimensionsInfo?: string | null;
  packagingType?: string | null;
  exitAppointee?: string | null;
  height?: number | null;
  width?: number | null;
  depth?: number | null;
  linearUnitOfMeasure?: string | null;
  packageWeight?: string | null;
  weightUnitOfMeasure?: string | null;
  externalDepositationId?: string | null;
  id: string;
  position?: string | null;
  startDate?: string | null;
  updatedAt: string;
  uuid: string;
  appointee?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  exit?: {
    __typename?: "ExitNode";
    endDate?: string | null;
    exitDriver?: string | null;
    appointee?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  } | null;
  asset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    mainImage?: string | null;
    category?: string | null;
    author?: string | null;
    inventoryNumber?: string | null;
    technique?: string | null;
    genericDimensions?: string | null;
    creationDate?: string | null;
    actualDateAndPeriod?: string | null;
    netWeight?: number | null;
    netWeightUnit?: string | null;
    createdBy?: { __typename?: "UserType"; name: string } | null;
  };
};

export type ExitFieldsFragment = {
  __typename?: "ExitNode";
  uuid: string;
  updatedAt: string;
  transportationRequiredPersonnel?: string | null;
  notes?: string | null;
  id: string;
  filingNumber?: string | null;
  endDate?: string | null;
  depositationNumber?: string | null;
  depositationData?: string | null;
  createdAt: string;
  appointee?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type GetDepositationsByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetDepositationsByIdQuery = {
  __typename?: "Query";
  depositation?: {
    __typename?: "DepositationNode";
    areaDescription?: string | null;
    areaNotes?: string | null;
    areaType?: SlogDepositationAreaTypeChoices | null;
    areaTypeDescription?: SlogDepositationAreaTypeDescriptionChoices | null;
    coverType?: SlogDepositationCoverTypeChoices | null;
    createdAt: string;
    depositationId?: string | null;
    description?: string | null;
    evaluationType?: SlogDepositationEvaluationTypeChoices | null;
    filingNumber: string;
    id: string;
    instructions?: string | null;
    insuranceNotes?: string | null;
    insuranceStatus: SlogDepositationInsuranceStatusChoices;
    insuredFrom?: string | null;
    insuredTo?: string | null;
    isConfirmed: boolean;
    isModifiable?: boolean | null;
    notes?: string | null;
    numberOfAssets?: number | null;
    numberOfMedia?: number | null;
    numberOfPackages?: number | null;
    status?: SlogDepositationStatusChoices | null;
    type?: SlogDepositationTypeChoices | null;
    info?: string | null;
    updatedAt: string;
    uuid: string;
    methodAndAreaOfStorage?: string | null;
    transportationRequiredPersonnel?: string | null;
    startDate?: string | null;
    driver?: string | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
          createdAt: string;
        } | null;
      } | null>;
    };
    depositationassetsSet: {
      __typename?: "DepositationAssetNodeConnection";
      edges: Array<{
        __typename?: "DepositationAssetNodeEdge";
        node?: {
          __typename?: "DepositationAssetNode";
          container?: string | null;
          createdAt: string;
          endDate?: string | null;
          packageWeightInfo?: string | null;
          packageDimensionsInfo?: string | null;
          packagingType?: string | null;
          exitAppointee?: string | null;
          height?: number | null;
          width?: number | null;
          depth?: number | null;
          linearUnitOfMeasure?: string | null;
          packageWeight?: string | null;
          weightUnitOfMeasure?: string | null;
          externalDepositationId?: string | null;
          id: string;
          position?: string | null;
          startDate?: string | null;
          updatedAt: string;
          uuid: string;
          appointee?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          exit?: {
            __typename?: "ExitNode";
            endDate?: string | null;
            exitDriver?: string | null;
            appointee?: {
              __typename?: "RegistryNode";
              id: string;
              fullName?: string | null;
            } | null;
          } | null;
          asset: {
            __typename?: "AssetNode";
            id: string;
            title: string;
            mainImage?: string | null;
            category?: string | null;
            author?: string | null;
            inventoryNumber?: string | null;
            technique?: string | null;
            genericDimensions?: string | null;
            creationDate?: string | null;
            actualDateAndPeriod?: string | null;
            netWeight?: number | null;
            netWeightUnit?: string | null;
            createdBy?: { __typename?: "UserType"; name: string } | null;
          };
        } | null;
      } | null>;
    };
    customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    company?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    courier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    location?: {
      __typename?: "OfficeNode";
      id: string;
      name?: string | null;
      address?: string | null;
    } | null;
    createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  } | null;
};

export type GetAllDepositationsQueryVariables = Exact<{
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  depositationType?: InputMaybe<Scalars["String"]>;
  isDepositationExpired?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAllDepositationsQuery = {
  __typename?: "Query";
  allDepositations?: {
    __typename?: "DepositationNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "DepositationNodeEdge";
      node?: {
        __typename?: "DepositationNode";
        type?: SlogDepositationTypeChoices | null;
        id: string;
        depositationId?: string | null;
        numberOfAssets?: number | null;
        numberOfMedia?: number | null;
        isConfirmed: boolean;
        status?: SlogDepositationStatusChoices | null;
        startDate?: string | null;
        customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllDepositationsAssetsQueryVariables = Exact<{
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  depositationType?: InputMaybe<Scalars["String"]>;
  isDepositationAssetExpired?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  showDepositationInventory?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetAllDepositationsAssetsQuery = {
  __typename?: "Query";
  allDepositationAssets?: {
    __typename?: "DepositationAssetNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "DepositationAssetNodeEdge";
      node?: {
        __typename?: "DepositationAssetNode";
        uuid: string;
        updatedAt: string;
        startDate?: string | null;
        position?: string | null;
        id: string;
        externalDepositationId?: string | null;
        exitAppointee?: string | null;
        endDate?: string | null;
        container?: string | null;
        createdAt: string;
        packageWeightInfo?: string | null;
        packageDimensionsInfo?: string | null;
        packagingType?: string | null;
        parentType?: string | null;
        appointee?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        asset: {
          __typename?: "AssetNode";
          id: string;
          mainImage?: string | null;
          category?: string | null;
          title: string;
          creationDate?: string | null;
          technique?: string | null;
          netWeight?: number | null;
          genericDimensions?: string | null;
          inventoryNumber?: string | null;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
          createdBy?: { __typename?: "UserType"; name: string } | null;
        };
        depositation?: {
          __typename?: "DepositationNode";
          id: string;
          startDate?: string | null;
          type?: SlogDepositationTypeChoices | null;
          depositationId?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateDepositationMutationVariables = Exact<{
  input: CreateDepositationInput;
}>;

export type CreateDepositationMutation = {
  __typename?: "Mutation";
  createDepositation?: {
    __typename?: "CreateDepositationPayload";
    depositation?: {
      __typename?: "DepositationNode";
      areaDescription?: string | null;
      areaNotes?: string | null;
      areaType?: SlogDepositationAreaTypeChoices | null;
      areaTypeDescription?: SlogDepositationAreaTypeDescriptionChoices | null;
      coverType?: SlogDepositationCoverTypeChoices | null;
      createdAt: string;
      depositationId?: string | null;
      description?: string | null;
      evaluationType?: SlogDepositationEvaluationTypeChoices | null;
      filingNumber: string;
      id: string;
      instructions?: string | null;
      insuranceNotes?: string | null;
      insuranceStatus: SlogDepositationInsuranceStatusChoices;
      insuredFrom?: string | null;
      insuredTo?: string | null;
      isConfirmed: boolean;
      isModifiable?: boolean | null;
      notes?: string | null;
      numberOfAssets?: number | null;
      numberOfMedia?: number | null;
      numberOfPackages?: number | null;
      status?: SlogDepositationStatusChoices | null;
      type?: SlogDepositationTypeChoices | null;
      info?: string | null;
      updatedAt: string;
      uuid: string;
      methodAndAreaOfStorage?: string | null;
      transportationRequiredPersonnel?: string | null;
      startDate?: string | null;
      driver?: string | null;
      depositationassetsSet: {
        __typename?: "DepositationAssetNodeConnection";
        edges: Array<{
          __typename?: "DepositationAssetNodeEdge";
          node?: {
            __typename?: "DepositationAssetNode";
            container?: string | null;
            createdAt: string;
            endDate?: string | null;
            exitAppointee?: string | null;
            exit?: {
              __typename?: "ExitNode";
              createdAt: string;
              depositationData?: string | null;
              depositationNumber?: string | null;
              endDate?: string | null;
              filingNumber?: string | null;
              id: string;
              notes?: string | null;
              transportationRequiredPersonnel?: string | null;
              updatedAt: string;
              uuid: string;
            } | null;
          } | null;
        } | null>;
      };
      customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      company?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      courier?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      location?: {
        __typename?: "OfficeNode";
        id: string;
        name?: string | null;
        address?: string | null;
      } | null;
      createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
      updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    } | null;
  } | null;
};

export type UpdateDepositationMutationVariables = Exact<{
  input: UpdateDepositationInput;
}>;

export type UpdateDepositationMutation = {
  __typename?: "Mutation";
  updateDepositation?: {
    __typename?: "UpdateDepositationPayload";
    depositation?: { __typename?: "DepositationNode"; id: string } | null;
  } | null;
};

export type DeleteDepositationMutationVariables = Exact<{
  input: DeleteDepositationInput;
}>;

export type DeleteDepositationMutation = {
  __typename?: "Mutation";
  deleteDepositation?: {
    __typename?: "DeleteDepositationPayload";
    depositation?: { __typename?: "DepositationNode"; id: string } | null;
  } | null;
};

export type DetachAssetsFromDepositationMutationVariables = Exact<{
  input: DeleteDepositationAssetInput;
}>;

export type DetachAssetsFromDepositationMutation = {
  __typename?: "Mutation";
  deleteDepositationAsset?: {
    __typename?: "DeleteDepositationAssetPayload";
    depositation?: { __typename?: "DepositationNode"; id: string } | null;
  } | null;
};

export type AddDepositationMediaMutationVariables = Exact<{
  input: AddDepositationMediaInput;
}>;

export type AddDepositationMediaMutation = {
  __typename?: "Mutation";
  addDepositationMedia?: {
    __typename?: "AddDepositationMediaPayload";
    depositation?: {
      __typename?: "DepositationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteDepositationMediaMutationVariables = Exact<{
  input: DeleteDepositationMediaInput;
}>;

export type DeleteDepositationMediaMutation = {
  __typename?: "Mutation";
  deleteDepositationMedia?: {
    __typename?: "DeleteDepositationMediaPayload";
    depositation?: {
      __typename?: "DepositationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateDepositationStatusMutationVariables = Exact<{
  input: UpdateDepositationStatusInput;
}>;

export type UpdateDepositationStatusMutation = {
  __typename?: "Mutation";
  updateDepositationStatus?: {
    __typename?: "UpdateDepositationStatusPayload";
    depositation?: {
      __typename?: "DepositationNode";
      status?: SlogDepositationStatusChoices | null;
      id: string;
    } | null;
  } | null;
};

export type DuplicateDepositationMutationVariables = Exact<{
  input: DuplicateDepositationInput;
}>;

export type DuplicateDepositationMutation = {
  __typename?: "Mutation";
  duplicateDepositation?: {
    __typename?: "DuplicateDepositationPayload";
    depositation?: { __typename?: "DepositationNode"; id: string } | null;
  } | null;
};

export type ExportDepositationInboundBillsMutationVariables = Exact<{
  input: ExportDepositationInboundBillsInput;
}>;

export type ExportDepositationInboundBillsMutation = {
  __typename?: "Mutation";
  exportDepositationInboundBills?: {
    __typename?: "ExportDepositationInboundBillsPayload";
    url?: string | null;
  } | null;
};

export type ExportDepositationOutboundBillsMutationVariables = Exact<{
  input: ExportDepositationOutboundBillsInput;
}>;

export type ExportDepositationOutboundBillsMutation = {
  __typename?: "Mutation";
  exportDepositationOutboundBills?: {
    __typename?: "ExportDepositationOutboundBillsPayload";
    url?: string | null;
  } | null;
};

export type ExportDepositationPackingListMutationVariables = Exact<{
  input: ExportDepositationPackingListInput;
}>;

export type ExportDepositationPackingListMutation = {
  __typename?: "Mutation";
  exportDepositationPackingList?: {
    __typename?: "ExportDepositationPackingListPayload";
    url?: string | null;
  } | null;
};

export type ExportDepositationLabelsMutationVariables = Exact<{
  input: ExportDepositationLabelsInput;
}>;

export type ExportDepositationLabelsMutation = {
  __typename?: "Mutation";
  exportDepositationLabels?: {
    __typename?: "ExportDepositationLabelsPayload";
    url?: string | null;
  } | null;
};

export type ExportDepositationPdfMutationVariables = Exact<{
  input: ExportDepositationPdfInput;
}>;

export type ExportDepositationPdfMutation = {
  __typename?: "Mutation";
  exportDepositationPdf?: {
    __typename?: "ExportDepositationPdfPayload";
    url?: string | null;
  } | null;
};

export type GetLatestDepositationDocumentExportsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetLatestDepositationDocumentExportsQuery = {
  __typename?: "Query";
  latestDepositationDocumentsExports?: Array<{
    __typename?: "ExportDocumentsNode";
    createdAt: string;
    externalId?: number | null;
    file: string;
    isSigned?: boolean | null;
    modelName?: string | null;
    modelUpdatedAt?: string | null;
    type: BaseExportedDocumentsTypeChoices;
    updatedAt: string;
    uuid: string;
  } | null> | null;
};

export type ExportDepositationAssetLabelMutationVariables = Exact<{
  input: ExportDepositationAssetsLabelsInput;
}>;

export type ExportDepositationAssetLabelMutation = {
  __typename?: "Mutation";
  exportDepositationAssetLabel?: {
    __typename?: "ExportDepositationAssetsLabelsPayload";
    url?: string | null;
  } | null;
};

export type ExportDepositationAssetPackingListMutationVariables = Exact<{
  input: ExportDepositationAssetsPackingListInput;
}>;

export type ExportDepositationAssetPackingListMutation = {
  __typename?: "Mutation";
  exportDepositationAssetPackingList?: {
    __typename?: "ExportDepositationAssetsPackingListPayload";
    url?: string | null;
  } | null;
};

export type EventLogFindingFieldsFragment = {
  __typename?: "EventLogNode";
  id: string;
  eventType?: string | null;
  hideOwner?: boolean | null;
  commerciallyAvailable?: boolean | null;
  sold?: boolean | null;
  collectionPublicName?: string | null;
  newCollectionPublicName?: string | null;
  locationDescription?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
};

export type EventLogAnagraphicFieldsFragment = {
  __typename?: "EventLogNode";
  id: string;
  eventType?: string | null;
  hideOwner?: boolean | null;
  commerciallyAvailable?: boolean | null;
  sold?: boolean | null;
  collectionPublicName?: string | null;
  newCollectionPublicName?: string | null;
  locationDescription?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  note?: string | null;
  acquisitionType?: string | null;
  actionType?: string | null;
  commission?: string | null;
  auctionDescription?: string | null;
  lotNumber?: string | null;
  sellingLocation?: string | null;
  entityDocumentsNumber: number;
  entityCreatedAt?: string | null;
  externalId?: number | null;
  externalEntity: BaseEventLogExternalEntityChoices;
  document?: string | null;
  translatedEventType?: string | null;
  oldAddress?: { __typename?: "Address"; raw?: string | null } | null;
  newAddress?: { __typename?: "Address"; raw?: string | null } | null;
  value?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  minimumPrice?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  maximumPrice?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  reservePrice?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  auctionRights?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  assetSet: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        description?: string | null;
        author?: string | null;
        category?: string | null;
        genericDimensions?: string | null;
        creationDate?: string | null;
        mainImage?: string | null;
        actualDateAndPeriod?: string | null;
      } | null;
    } | null>;
  };
};

export type GetAllEventLogsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEventLogsQuery = {
  __typename?: "Query";
  allEventLogs?: {
    __typename?: "EventLogNodeConnection";
    edges: Array<{
      __typename?: "EventLogNodeEdge";
      node?: {
        __typename?: "EventLogNode";
        id: string;
        eventType?: string | null;
        hideOwner?: boolean | null;
        commerciallyAvailable?: boolean | null;
        sold?: boolean | null;
        collectionPublicName?: string | null;
        newCollectionPublicName?: string | null;
        locationDescription?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetEventLogByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetEventLogByIdQuery = {
  __typename?: "Query";
  eventLog?: {
    __typename?: "EventLogNode";
    eventName?: string | null;
    actualLocation?: string | null;
    actualCity?: string | null;
    externalEntityId?: string | null;
    id: string;
    eventType?: string | null;
    hideOwner?: boolean | null;
    commerciallyAvailable?: boolean | null;
    sold?: boolean | null;
    collectionPublicName?: string | null;
    newCollectionPublicName?: string | null;
    locationDescription?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    note?: string | null;
    acquisitionType?: string | null;
    actionType?: string | null;
    commission?: string | null;
    auctionDescription?: string | null;
    lotNumber?: string | null;
    sellingLocation?: string | null;
    entityDocumentsNumber: number;
    entityCreatedAt?: string | null;
    externalId?: number | null;
    externalEntity: BaseEventLogExternalEntityChoices;
    document?: string | null;
    translatedEventType?: string | null;
    host?: { __typename?: "RegistryNode"; fullName?: string | null; id: string } | null;
    broker?: { __typename?: "RegistryNode"; fullName?: string | null; id: string } | null;
    policy?: {
      __typename?: "PolicyNode";
      id: string;
      eventName?: string | null;
      eventFrom?: string | null;
      eventTo?: string | null;
      policyType: string;
      offerCode: string;
      policyCode: string;
      issuedAt?: string | null;
      expiresAt?: string | null;
      broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
      contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
      eventEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
      eventLocationEntity?: { __typename?: "OfficeNode"; name?: string | null } | null;
      insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
    } | null;
    collection?: {
      __typename?: "CollectionNode";
      totalAssetsNumber?: number | null;
      createdFrom?: string | null;
      id: string;
      name: string;
    } | null;
    oldAddress?: { __typename?: "Address"; raw?: string | null } | null;
    newAddress?: { __typename?: "Address"; raw?: string | null } | null;
    value?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    minimumPrice?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    maximumPrice?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    reservePrice?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    auctionRights?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category?: string | null;
          genericDimensions?: string | null;
          creationDate?: string | null;
          mainImage?: string | null;
          actualDateAndPeriod?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateEventLogMutationVariables = Exact<{
  input: CreateEventLogInput;
}>;

export type CreateEventLogMutation = {
  __typename?: "Mutation";
  createEventLog?: {
    __typename?: "CreateEventLogPayload";
    eventLog?: {
      __typename?: "EventLogNode";
      id: string;
      eventType?: string | null;
      hideOwner?: boolean | null;
      commerciallyAvailable?: boolean | null;
      sold?: boolean | null;
      collectionPublicName?: string | null;
      newCollectionPublicName?: string | null;
      locationDescription?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      note?: string | null;
      acquisitionType?: string | null;
      actionType?: string | null;
      commission?: string | null;
      auctionDescription?: string | null;
      lotNumber?: string | null;
      sellingLocation?: string | null;
      document?: string | null;
      value?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      minimumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      maximumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      reservePrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      auctionRights?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: {
            __typename?: "AssetNode";
            id: string;
            title: string;
            description?: string | null;
            author?: string | null;
            category?: string | null;
            genericDimensions?: string | null;
            creationDate?: string | null;
            mainImage?: string | null;
            actualDateAndPeriod?: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateEventLogMutationVariables = Exact<{
  input: UpdateEventLogInput;
}>;

export type UpdateEventLogMutation = {
  __typename?: "Mutation";
  updateEventLog?: {
    __typename?: "UpdateEventLogPayload";
    eventLog?: {
      __typename?: "EventLogNode";
      id: string;
      eventType?: string | null;
      hideOwner?: boolean | null;
      commerciallyAvailable?: boolean | null;
      sold?: boolean | null;
      collectionPublicName?: string | null;
      newCollectionPublicName?: string | null;
      locationDescription?: string | null;
      startDate?: string | null;
      endDate?: string | null;
      note?: string | null;
      acquisitionType?: string | null;
      actionType?: string | null;
      commission?: string | null;
      auctionDescription?: string | null;
      lotNumber?: string | null;
      sellingLocation?: string | null;
      externalId?: number | null;
      externalEntity: BaseEventLogExternalEntityChoices;
      document?: string | null;
      value?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      minimumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      maximumPrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      reservePrice?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      auctionRights?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      origin?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      host?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: {
            __typename?: "AssetNode";
            id: string;
            title: string;
            description?: string | null;
            author?: string | null;
            category?: string | null;
            genericDimensions?: string | null;
            creationDate?: string | null;
            mainImage?: string | null;
            actualDateAndPeriod?: string | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteEventLogMutationVariables = Exact<{
  input: DeleteEventLogInput;
}>;

export type DeleteEventLogMutation = {
  __typename?: "Mutation";
  deleteEventLog?: {
    __typename?: "DeleteEventLogPayload";
    deleteEventLog?: { __typename?: "EventLogNode"; id: string } | null;
  } | null;
};

export type GetEventLogByAssetIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetEventLogByAssetIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    eventLogs: {
      __typename?: "EventLogNodeConnection";
      edges: Array<{
        __typename?: "EventLogNodeEdge";
        node?: {
          __typename?: "EventLogNode";
          id: string;
          eventType?: string | null;
          hideOwner?: boolean | null;
          commerciallyAvailable?: boolean | null;
          sold?: boolean | null;
          collectionPublicName?: string | null;
          newCollectionPublicName?: string | null;
          locationDescription?: string | null;
          startDate?: string | null;
          endDate?: string | null;
          eventName?: string | null;
          actualLocation?: string | null;
          actualCity?: string | null;
          numberOfDocuments?: number | null;
          externalId?: number | null;
          externalEntity: BaseEventLogExternalEntityChoices;
          externalEntityId?: string | null;
          actionType?: string | null;
          translatedEventType?: string | null;
          createdAt: string;
          reason?: string | null;
          owner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          newOwner?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type ExhibitionFindingFieldsFragment = {
  __typename?: "ExhibitionNode";
  id: string;
  name: string;
  description?: string | null;
  eventFrom?: string | null;
  eventTo?: string | null;
  notes?: string | null;
  permanent?: boolean | null;
};

export type ExhibitionAnagraphicFieldsFragment = {
  __typename?: "ExhibitionNode";
  id: string;
  name: string;
  description?: string | null;
  eventFrom?: string | null;
  eventTo?: string | null;
  notes?: string | null;
  image?: string | null;
  permanent?: boolean | null;
  totalAssetsNumber?: number | null;
  createdAt: string;
  updatedAt: string;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
      } | null;
    } | null>;
  };
  createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
  assetSet: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        description?: string | null;
        author?: string | null;
        category?: string | null;
        genericDimensions?: string | null;
        creationDate?: string | null;
        mainImage?: string | null;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  };
};

export type GetAllExhibitionsQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  actualTimingStatus?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllExhibitionsQuery = {
  __typename?: "Query";
  allExhibitions?: {
    __typename?: "ExhibitionNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "ExhibitionNodeEdge";
      node?: {
        __typename?: "ExhibitionNode";
        id: string;
        name: string;
        eventFrom?: string | null;
        eventTo?: string | null;
        permanent?: boolean | null;
        totalAssetsNumber?: number | null;
        actualTimingStatus?: string | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
        hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        eventLocation?: { __typename?: "Address"; raw?: string | null } | null;
        eventLocationEntity?: {
          __typename?: "OfficeNode";
          id: string;
          name?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetExhibitionByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetExhibitionByIdQuery = {
  __typename?: "Query";
  exhibition?: {
    __typename?: "ExhibitionNode";
    id: string;
    name: string;
    description?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    notes?: string | null;
    image?: string | null;
    permanent?: boolean | null;
    totalAssetsNumber?: number | null;
    createdAt: string;
    updatedAt: string;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
    createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    assetSet: {
      __typename?: "AssetNodeConnection";
      edges: Array<{
        __typename?: "AssetNodeEdge";
        node?: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          description?: string | null;
          author?: string | null;
          category?: string | null;
          genericDimensions?: string | null;
          creationDate?: string | null;
          mainImage?: string | null;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetMediaExhibitionByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaExhibitionByIdQuery = {
  __typename?: "Query";
  exhibition?: {
    __typename?: "ExhibitionNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateExhibitionMutationVariables = Exact<{
  input: CreateExhibitionInput;
}>;

export type CreateExhibitionMutation = {
  __typename?: "Mutation";
  createExhibition?: {
    __typename?: "CreateExhibitionPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      name: string;
      description?: string | null;
      eventFrom?: string | null;
      eventTo?: string | null;
      notes?: string | null;
      permanent?: boolean | null;
      curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type UpdateExhibitionMutationVariables = Exact<{
  input: UpdateExhibitionInput;
}>;

export type UpdateExhibitionMutation = {
  __typename?: "Mutation";
  updateExhibition?: {
    __typename?: "UpdateExhibitionPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      name: string;
      description?: string | null;
      eventFrom?: string | null;
      eventTo?: string | null;
      notes?: string | null;
      permanent?: boolean | null;
      curator?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      organizer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      hostEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null;
  } | null;
};

export type DeleteExhibitionMutationVariables = Exact<{
  input: DeleteExhibitionInput;
}>;

export type DeleteExhibitionMutation = {
  __typename?: "Mutation";
  deleteExhibition?: {
    __typename?: "DeleteExhibitionPayload";
    exhibition?: { __typename?: "ExhibitionNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromExhibitionMutationVariables = Exact<{
  input: DetachAssetFromExhibitionInput;
}>;

export type DetachAssetFromExhibitionMutation = {
  __typename?: "Mutation";
  detachAssetFromExhibition?: {
    __typename?: "DetachAssetFromExhibitionPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      assetSet: {
        __typename?: "AssetNodeConnection";
        edges: Array<{
          __typename?: "AssetNodeEdge";
          node?: { __typename?: "AssetNode"; id: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportExhibitionPdfMutationVariables = Exact<{
  input: ExportExhibitionPdfInput;
}>;

export type ExportExhibitionPdfMutation = {
  __typename?: "Mutation";
  exportExhibitionPdf?: {
    __typename?: "ExportExhibitionPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type AddExhibitionMediaMutationVariables = Exact<{
  input: AddExhibitionMediaInput;
}>;

export type AddExhibitionMediaMutation = {
  __typename?: "Mutation";
  addExhibitionMedia?: {
    __typename?: "AddExhibitionMediaPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteExhibitionMediaMutationVariables = Exact<{
  input: DeleteExhibitionMediaInput;
}>;

export type DeleteExhibitionMediaMutation = {
  __typename?: "Mutation";
  deleteExhibitionMedia?: {
    __typename?: "DeleteExhibitionMediaPayload";
    exhibition?: {
      __typename?: "ExhibitionNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteExhibitionImageMutationVariables = Exact<{
  input: DeleteExhibitionImageInput;
}>;

export type DeleteExhibitionImageMutation = {
  __typename?: "Mutation";
  deleteExhibitionImage?: {
    __typename?: "DeleteExhibitionImagePayload";
    exhibition?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
  } | null;
};

export type CreateMediaMutationVariables = Exact<{
  input: CreateMediaInput;
}>;

export type CreateMediaMutation = {
  __typename?: "Mutation";
  createMedia?: {
    __typename?: "CreateMediaPayload";
    media?: {
      __typename?: "MediaNode";
      id: string;
      name?: string | null;
      file: string;
      description?: string | null;
      mediaType?: string | null;
      isMainImage?: boolean | null;
      imageType?: string | null;
      notes?: string | null;
      audioVideoFileType?: string | null;
      creationDate?: any | null;
      fileAuthor?: string | null;
      fileSize?: string | null;
      photographicEquipment?: string | null;
      matrixType?: string | null;
      isGlassPlexyAcetate?: boolean | null;
    } | null;
  } | null;
};

export type UpdateMediaMutationVariables = Exact<{
  input: UpdateMediaInput;
}>;

export type UpdateMediaMutation = {
  __typename?: "Mutation";
  updateMedia?: {
    __typename?: "UpdateMediaPayload";
    media?: {
      __typename?: "MediaNode";
      id: string;
      name?: string | null;
      file: string;
      description?: string | null;
      mediaType?: string | null;
      isMainImage?: boolean | null;
      imageType?: string | null;
      notes?: string | null;
      audioVideoFileType?: string | null;
      creationDate?: any | null;
      fileAuthor?: string | null;
      fileSize?: string | null;
      photographicEquipment?: string | null;
      matrixType?: string | null;
      isGlassPlexyAcetate?: boolean | null;
    } | null;
  } | null;
};

export type PolicyAssetFieldsFragment = {
  __typename?: "PolicyAssetNode";
  catNat?: boolean | null;
  coverTerrorism?: boolean | null;
  coverType: string;
  coveredRiskType: string;
  evaluationType: string;
  exemption?: boolean | null;
  id: string;
  lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
  deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
  lenderAddress?: {
    __typename?: "Address";
    raw?: string | null;
    locality?: string | null;
    state?: string | null;
    country?: string | null;
    streetNumber?: string | null;
    route?: string | null;
  } | null;
  shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  insuredValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
};

export type PolicyCoreFieldsFragment = {
  __typename?: "PolicyNode";
  id: string;
  offerCode: string;
  policyCode: string;
  policyStatus: string;
  issuedAt?: string | null;
  expiresAt?: string | null;
  policyType: string;
  contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
  insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
  broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
};

export type PolicyAssetOnPolicyFragment = {
  __typename?: "PolicyNode";
  policyAssets: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: { __typename?: "PolicyAssetNode"; id: string } | null;
    } | null>;
  };
};

export type PolicyMoneyFieldsFragment = {
  __typename?: "PolicyNode";
  evaluationType: string;
  totalInsuredPremium?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  totalInsuredValue?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
};

export type PolicyDriverFieldsFragment = {
  __typename?: "PolicyNode";
  catNat?: boolean | null;
  coverTerrorism?: boolean | null;
  coverType: string;
  coveredRiskType: string;
  exemption?: boolean | null;
  hasVariableBasket?: boolean | null;
};

export type GetStateTechniqueMatrixQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetStateTechniqueMatrixQuery = {
  __typename?: "Query";
  stateTechniqueMatrix?: Array<{
    __typename?: "StateTechniqueMatrixObject";
    assetsNumber?: number | null;
    country?: string | null;
    technique?: string | null;
    sumAssetsValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
  } | null> | null;
};

export type GetAllPoliciesQueryVariables = Exact<{
  orderBy?: InputMaybe<Scalars["String"]>;
  policyType?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  hasValidCertificate?: InputMaybe<Scalars["Boolean"]>;
  policyStatus?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  isExpired?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllPoliciesQuery = {
  __typename?: "Query";
  allPolicies?: {
    __typename?: "PolicyNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "PolicyNodeEdge";
      node?: {
        __typename?: "PolicyNode";
        isExpired?: boolean | null;
        hasValidCertificate?: boolean | null;
        assetsCount?: number | null;
        id: string;
        offerCode: string;
        policyCode: string;
        policyStatus: string;
        issuedAt?: string | null;
        expiresAt?: string | null;
        policyType: string;
        evaluationType: string;
        createdBy?: {
          __typename?: "UserType";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
        insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
        broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        totalInsuredPremium?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPoliciesByTypeQueryVariables = Exact<{
  policyType: Scalars["String"];
  orderBy?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  hasValidCertificate?: InputMaybe<Scalars["Boolean"]>;
  policyStatus?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  isExpired?: InputMaybe<Scalars["String"]>;
  isModifiedAfterDate?: InputMaybe<Scalars["String"]>;
  isExpiredAfterDate?: InputMaybe<Scalars["Date"]>;
}>;

export type GetAllPoliciesByTypeQuery = {
  __typename?: "Query";
  allPolicies?: {
    __typename?: "PolicyNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "PolicyNodeEdge";
      node?: {
        __typename?: "PolicyNode";
        isExpired?: boolean | null;
        assetsCount?: number | null;
        hasValidCertificate?: boolean | null;
        modifiedStatus?: string | null;
        id: string;
        offerCode: string;
        policyCode: string;
        policyStatus: string;
        issuedAt?: string | null;
        expiresAt?: string | null;
        policyType: string;
        evaluationType: string;
        createdBy?: {
          __typename?: "UserType";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        exhibition?: { __typename?: "ExhibitionNode"; name: string } | null;
        eventEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
        insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
        broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        totalInsuredPremium?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ViewPolicyByIdReducedQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ViewPolicyByIdReducedQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
    insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
    broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
  } | null;
};

export type ViewPolicyByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ViewPolicyByIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    autoRenewal?: boolean | null;
    eventName?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    catNat?: boolean | null;
    hasVariableBasket?: boolean | null;
    coverTerrorism?: boolean | null;
    coverType: string;
    coveredRiskType: string;
    exemption?: boolean | null;
    createdAt: string;
    updatedAt: string;
    isExpired?: boolean | null;
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    evaluationType: string;
    broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    insuranceCompany: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    contractingParty: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    eventEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    exchangeRates?: Array<{
      __typename?: "ExchangeRatesNode";
      id: string;
      baseCurrency: string;
      currency: string;
      value: number;
      createdAt: string;
      updatedAt: string;
      uuid: string;
    } | null> | null;
    createdBy?: {
      __typename?: "UserType";
      id: string;
      createdAt: string;
      name: string;
      email: string;
    } | null;
    updatedBy?: {
      __typename?: "UserType";
      id: string;
      createdAt: string;
      name: string;
      email: string;
    } | null;
    policyAssets: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: {
          __typename?: "PolicyAssetNode";
          certificateProgressiveNumber?: number | null;
          catNat?: boolean | null;
          coverTerrorism?: boolean | null;
          coverType: string;
          coveredRiskType: string;
          evaluationType: string;
          exemption?: boolean | null;
          id: string;
          asset: {
            __typename?: "AssetNode";
            id: string;
            creationPeriod?: string | null;
            title: string;
            category?: string | null;
            genericDimensions?: string | null;
            technique?: string | null;
            inventoryNumber?: string | null;
            validated?: boolean | null;
            actualDateAndPeriod?: string | null;
            mainImage?: string | null;
            infoNotes?: string | null;
            authorEntity?: {
              __typename?: "RegistryNode";
              id: string;
              fullName?: string | null;
            } | null;
            assetPredictions?: {
              __typename?: "AssetPredictionNode";
              id: string;
              jobUuid?: string | null;
              aiReplacmentValue?: number | null;
              aiLiquidityValue?: number | null;
              aiIntrinsicValue?: number | null;
              type?: ManagementAssetPredictionTypeChoices | null;
            } | null;
            createdBy?: { __typename?: "UserType"; name: string } | null;
          };
          objectLocationEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
          objectLocationOffice?: {
            __typename?: "OfficeNode";
            id: string;
            name?: string | null;
          } | null;
          lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
          deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
          lenderAddress?: {
            __typename?: "Address";
            raw?: string | null;
            locality?: string | null;
            state?: string | null;
            country?: string | null;
            streetNumber?: string | null;
            route?: string | null;
          } | null;
          shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          insuredValue?: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          } | null;
        } | null;
      } | null>;
    };
    policyNotes: {
      __typename?: "PolicyNoteNodeConnection";
      edges: Array<{
        __typename?: "PolicyNoteNodeEdge";
        node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
      } | null>;
    };
    policyDocuments: {
      __typename?: "PolicyDocumentNodeConnection";
      edges: Array<{
        __typename?: "PolicyDocumentNodeEdge";
        node?: {
          __typename?: "PolicyDocumentNode";
          id: string;
          uuid: string;
          createdAt: string;
          file?: string | null;
          createdBy?: { __typename?: "UserType"; id: string; name: string } | null;
        } | null;
      } | null>;
    };
    totalInsuredPremium?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    totalInsuredValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
  } | null;
};

export type GetPolicyByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPolicyByIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    autoRenewal?: boolean | null;
    eventName?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    catNat?: boolean | null;
    hasVariableBasket?: boolean | null;
    coverTerrorism?: boolean | null;
    coverType: string;
    coveredRiskType: string;
    exemption?: boolean | null;
    isExpired?: boolean | null;
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    evaluationType: string;
    broker?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    insuranceCompany: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    contractingParty: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    eventEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    exchangeRates?: Array<{
      __typename?: "ExchangeRatesNode";
      id: string;
      baseCurrency: string;
      currency: string;
      value: number;
      createdAt: string;
      updatedAt: string;
      uuid: string;
    } | null> | null;
    policyAssets: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: {
          __typename?: "PolicyAssetNode";
          certificateProgressiveNumber?: number | null;
          catNat?: boolean | null;
          coverTerrorism?: boolean | null;
          coverType: string;
          coveredRiskType: string;
          evaluationType: string;
          exemption?: boolean | null;
          id: string;
          asset: {
            __typename?: "AssetNode";
            id: string;
            creationPeriod?: string | null;
            infoNotes?: string | null;
            title: string;
            category?: string | null;
            genericDimensions?: string | null;
            technique?: string | null;
            inventoryNumber?: string | null;
            validated?: boolean | null;
            actualDateAndPeriod?: string | null;
            mainImage?: string | null;
            authorEntity?: {
              __typename?: "RegistryNode";
              id: string;
              fullName?: string | null;
            } | null;
            createdBy?: { __typename?: "UserType"; name: string } | null;
          };
          objectLocationEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
          objectLocationOffice?: {
            __typename?: "OfficeNode";
            id: string;
            name?: string | null;
          } | null;
          lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
          deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
          lenderAddress?: {
            __typename?: "Address";
            raw?: string | null;
            locality?: string | null;
            state?: string | null;
            country?: string | null;
            streetNumber?: string | null;
            route?: string | null;
          } | null;
          shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
          insuredValue?: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          } | null;
        } | null;
      } | null>;
    };
    policyNotes: {
      __typename?: "PolicyNoteNodeConnection";
      edges: Array<{
        __typename?: "PolicyNoteNodeEdge";
        node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
      } | null>;
    };
    policyDocuments: {
      __typename?: "PolicyDocumentNodeConnection";
      edges: Array<{
        __typename?: "PolicyDocumentNodeEdge";
        node?: {
          __typename?: "PolicyDocumentNode";
          id: string;
          uuid: string;
          file?: string | null;
          createdAt: string;
        } | null;
      } | null>;
    };
    totalInsuredPremium?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    totalInsuredValue?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
  } | null;
};

export type GetPolicyNotesByPolicyIdQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetPolicyNotesByPolicyIdQuery = {
  __typename?: "Query";
  allPolicyNotes?: {
    __typename?: "PolicyNoteNodeConnection";
    edges: Array<{
      __typename?: "PolicyNoteNodeEdge";
      node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
    } | null>;
  } | null;
};

export type GetPolicyDocumentsByPolicyIdQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetPolicyDocumentsByPolicyIdQuery = {
  __typename?: "Query";
  allPolicyDocuments?: {
    __typename?: "PolicyDocumentNodeConnection";
    edges: Array<{
      __typename?: "PolicyDocumentNodeEdge";
      node?: {
        __typename?: "PolicyDocumentNode";
        id: string;
        file?: string | null;
        uuid: string;
        createdAt: string;
        createdBy?: { __typename?: "UserType"; name: string; id: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetBasketHistoryByPolicyIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetBasketHistoryByPolicyIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    policybaskethistorySet: {
      __typename?: "PolicyBasketHistoryNodeConnection";
      edges: Array<{
        __typename?: "PolicyBasketHistoryNodeEdge";
        node?: {
          __typename?: "PolicyBasketHistoryNode";
          createdAt: string;
          reason: string;
          status: SpinPolicyBasketHistoryStatusChoices;
          uuid: string;
          overrideChangeDate?: string | null;
          createdBy?: { __typename?: "UserType"; name: string } | null;
          policyassetbaskethistorySet: {
            __typename?: "PolicyAssetBasketHistoryNodeConnection";
            edges: Array<{
              __typename?: "PolicyAssetBasketHistoryNodeEdge";
              node?: {
                __typename?: "PolicyAssetBasketHistoryNode";
                diff?: string | null;
                insuredValue?: {
                  __typename?: "Money";
                  amountStr?: string | null;
                  amount?: number | null;
                  currency?: { __typename?: "Currency"; code: string } | null;
                } | null;
                asset: {
                  __typename?: "AssetNode";
                  title: string;
                  author?: string | null;
                  actualDateAndPeriod?: string | null;
                  authorEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type CreatePolicyMutationVariables = Exact<{
  input: CreatePolicyInput;
}>;

export type CreatePolicyMutation = {
  __typename?: "Mutation";
  createPolicy?: {
    __typename?: "CreatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type CreatePolicyAssetMutationVariables = Exact<{
  input: CreatePolicyAssetInput;
}>;

export type CreatePolicyAssetMutation = {
  __typename?: "Mutation";
  createPolicyAsset?: {
    __typename?: "CreatePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type CreatePolicyNoteMutationVariables = Exact<{
  input: CreatePolicyNoteInput;
}>;

export type CreatePolicyNoteMutation = {
  __typename?: "Mutation";
  createPolicyNote?: {
    __typename?: "CreatePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type CreatePolicyDocumentMutationVariables = Exact<{
  input: CreatePolicyDocumentInput;
}>;

export type CreatePolicyDocumentMutation = {
  __typename?: "Mutation";
  createPolicyDocument?: {
    __typename?: "CreatePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyMutationVariables = Exact<{
  input: UpdatePolicyInput;
}>;

export type UpdatePolicyMutation = {
  __typename?: "Mutation";
  updatePolicy?: {
    __typename?: "UpdatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type RenewPolicyMutationVariables = Exact<{
  input: RenewPolicyInput;
}>;

export type RenewPolicyMutation = {
  __typename?: "Mutation";
  renewPolicy?: {
    __typename?: "RenewPolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type ExtendPolicyMutationVariables = Exact<{
  input: ExtendPolicyInput;
}>;

export type ExtendPolicyMutation = {
  __typename?: "Mutation";
  extendPolicy?: {
    __typename?: "ExtendPolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type TerminatePolicyMutationVariables = Exact<{
  input: TerminatePolicyInput;
}>;

export type TerminatePolicyMutation = {
  __typename?: "Mutation";
  terminatePolicy?: {
    __typename?: "TerminatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type DuplicatePolicyMutationVariables = Exact<{
  input: DuplicatePolicyInput;
}>;

export type DuplicatePolicyMutation = {
  __typename?: "Mutation";
  duplicatePolicy?: {
    __typename?: "DuplicatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyAssetMutationVariables = Exact<{
  input: UpdatePolicyAssetInput;
}>;

export type UpdatePolicyAssetMutation = {
  __typename?: "Mutation";
  updatePolicyAsset?: {
    __typename?: "UpdatePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyAssetGroupMutationVariables = Exact<{
  input: UpdatePolicyAssetGroupInput;
}>;

export type UpdatePolicyAssetGroupMutation = {
  __typename?: "Mutation";
  updatePolicyAssetGroup?: {
    __typename?: "UpdatePolicyAssetGroupPayload";
    policyAssets?: Array<{ __typename?: "PolicyAssetNode"; id: string } | null> | null;
  } | null;
};

export type UpdatePolicyNoteMutationVariables = Exact<{
  input: UpdatePolicyNoteInput;
}>;

export type UpdatePolicyNoteMutation = {
  __typename?: "Mutation";
  updatePolicyNote?: {
    __typename?: "UpdatePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyDocumentMutationVariables = Exact<{
  input: UpdatePolicyDocumentInput;
}>;

export type UpdatePolicyDocumentMutation = {
  __typename?: "Mutation";
  updatePolicyDocument?: {
    __typename?: "UpdatePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type DeletePolicyMutationVariables = Exact<{
  input: DeletePolicyInput;
}>;

export type DeletePolicyMutation = {
  __typename?: "Mutation";
  deletePolicy?: {
    __typename?: "DeletePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type DeletePolicyAssetMutationVariables = Exact<{
  input: DeletePolicyAssetInput;
}>;

export type DeletePolicyAssetMutation = {
  __typename?: "Mutation";
  deletePolicyAsset?: {
    __typename?: "DeletePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type DeletePolicyNoteMutationVariables = Exact<{
  input: DeletePolicyNoteInput;
}>;

export type DeletePolicyNoteMutation = {
  __typename?: "Mutation";
  deletePolicyNote?: {
    __typename?: "DeletePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type DeletePolicyDocumentMutationVariables = Exact<{
  input: DeletePolicyDocumentInput;
}>;

export type DeletePolicyDocumentMutation = {
  __typename?: "Mutation";
  deletePolicyDocument?: {
    __typename?: "DeletePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type GenerateCertificateMutationVariables = Exact<{
  input: GenerateCertificateInput;
}>;

export type GenerateCertificateMutation = {
  __typename?: "Mutation";
  generateCertificate?: {
    __typename?: "GenerateCertificatePayload";
    s3Load?: boolean | null;
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type DeleteCertificateMutationVariables = Exact<{
  input: DeleteCertificateInput;
}>;

export type DeleteCertificateMutation = {
  __typename?: "Mutation";
  deleteCertificate?: {
    __typename?: "DeleteCertificatePayload";
    certificate?: { __typename?: "CertificateNode"; id: string } | null;
  } | null;
};

export type SignCertificateMutationVariables = Exact<{
  input: SignCertificateInput;
}>;

export type SignCertificateMutation = {
  __typename?: "Mutation";
  signCertificate?: {
    __typename?: "SignCertificatePayload";
    certificate?: {
      __typename?: "CertificateNode";
      file?: string | null;
      signed?: boolean | null;
      signedAt?: string | null;
      signedBy?: { __typename?: "UserType"; name: string } | null;
    } | null;
  } | null;
};

export type GetAllCertificatesByPolicyIdAndIsActiveQueryVariables = Exact<{
  policy: Scalars["ID"];
  isValid: Scalars["Boolean"];
}>;

export type GetAllCertificatesByPolicyIdAndIsActiveQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        file?: string | null;
        uuid: string;
        creationDate: string;
        isValid?: boolean | null;
        language: string;
        progressiveNumber?: number | null;
        createdAt: string;
        signed?: boolean | null;
        metafields?: any | null;
        isSplitted?: boolean | null;
        signedAt?: string | null;
        lender?: string | null;
        lenderCountry?: string | null;
        policyAssetsCount?: number | null;
        createdBy?: { __typename?: "UserType"; id: string; name: string } | null;
        signedBy?: { __typename?: "UserType"; id: string; name: string } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificatesByPolicyIdQueryVariables = Exact<{
  policy: Scalars["ID"];
}>;

export type GetAllCertificatesByPolicyIdQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        file?: string | null;
        uuid: string;
        creationDate: string;
        isValid?: boolean | null;
        language: string;
        createdAt: string;
        signed?: boolean | null;
        progressiveNumber?: number | null;
        metafields?: any | null;
        isSplitted?: boolean | null;
        signedAt?: string | null;
        createdBy?: { __typename?: "UserType"; id: string; name: string } | null;
        signedBy?: { __typename?: "UserType"; id: string; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificatesByTypeQueryVariables = Exact<{
  policyType: Scalars["String"];
}>;

export type GetAllCertificatesByTypeQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        uuid: string;
        certificateType: SpinCertificateCertificateTypeChoices;
        file?: string | null;
        isValid?: boolean | null;
        language: string;
        updatedAt: string;
        createdAt: string;
        progressiveNumber?: number | null;
        creationDate: string;
        lenderCountry?: string | null;
        mainOfficeCountry?: string | null;
        lender?: string | null;
        shipper?: string | null;
        signed?: boolean | null;
        policy?: {
          __typename?: "PolicyNode";
          offerCode: string;
          policyCode: string;
          isExpired?: boolean | null;
          broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
          contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
          exhibition?: { __typename?: "ExhibitionNode"; name: string } | null;
          eventLocationEntity?: { __typename?: "OfficeNode"; name?: string | null } | null;
          totalInsuredValue?: {
            __typename?: "Money";
            amount?: number | null;
            currency?: { __typename?: "Currency"; code: string } | null;
          } | null;
        } | null;
        createdBy?: { __typename?: "UserType"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificateTemplatesByNameQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type GetAllCertificateTemplatesByNameQuery = {
  __typename?: "Query";
  allCertificateTemplates?: {
    __typename?: "CertificateTemplateNodeConnection";
    edges: Array<{
      __typename?: "CertificateTemplateNodeEdge";
      node?: {
        __typename?: "CertificateTemplateNode";
        id: string;
        file: string;
        name?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPolicyAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPolicyAssetsQuery = {
  __typename?: "Query";
  allPolicyAssets?: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: {
        __typename?: "PolicyAssetNode";
        catNat?: boolean | null;
        coverTerrorism?: boolean | null;
        coverType: string;
        coveredRiskType: string;
        evaluationType: string;
        exemption?: boolean | null;
        id: string;
        asset: {
          __typename?: "AssetNode";
          creationPeriod?: string | null;
          author?: string | null;
          title: string;
        };
        policy: { __typename?: "PolicyNode"; offerCode: string; policyCode: string };
        lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
        deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
        lenderAddress?: {
          __typename?: "Address";
          raw?: string | null;
          locality?: string | null;
          state?: string | null;
          country?: string | null;
          streetNumber?: string | null;
          route?: string | null;
        } | null;
        shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        insuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPolicyAssetsByPolicyTypeQueryVariables = Exact<{
  policy_PolicyType: Scalars["String"];
}>;

export type GetAllPolicyAssetsByPolicyTypeQuery = {
  __typename?: "Query";
  allPolicyAssets?: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: {
        __typename?: "PolicyAssetNode";
        catNat?: boolean | null;
        coverTerrorism?: boolean | null;
        coverType: string;
        coveredRiskType: string;
        evaluationType: string;
        exemption?: boolean | null;
        id: string;
        asset: {
          __typename?: "AssetNode";
          creationPeriod?: string | null;
          author?: string | null;
          title: string;
          category?: string | null;
          genericDimensions?: string | null;
          technique?: string | null;
          inventoryNumber?: string | null;
          validated?: boolean | null;
          actualDateAndPeriod?: string | null;
          mainImage?: string | null;
          createdBy?: { __typename?: "UserType"; name: string } | null;
        };
        policy: { __typename?: "PolicyNode"; id: string; offerCode: string; policyCode: string };
        objectLocationEntity?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        lender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
        deliveryAddress?: { __typename?: "Address"; raw?: string | null } | null;
        lenderAddress?: {
          __typename?: "Address";
          raw?: string | null;
          locality?: string | null;
          state?: string | null;
          country?: string | null;
          streetNumber?: string | null;
          route?: string | null;
        } | null;
        shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        insuredValue?: {
          __typename?: "Money";
          amount?: number | null;
          currency?: { __typename?: "Currency"; code: string } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetLastPresentValueByAssetIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetLastPresentValueByAssetIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    presentValues: {
      __typename?: "AssetPresentValueNodeConnection";
      edges: Array<{
        __typename?: "AssetPresentValueNodeEdge";
        node?: {
          __typename?: "AssetPresentValueNode";
          amountCurrency: ManagementAssetPresentValueAmountCurrencyChoices;
          amount: { __typename?: "Money"; amount?: number | null };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssetCarryingValueQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetCarryingValueQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    carryingValueAmountCurrency: string;
    carryingValueAmount?: { __typename?: "Money"; amount?: number | null } | null;
  } | null;
};

export type GenerateSplitCertificateMutationVariables = Exact<{
  input: GenerateSplitCertificateInput;
}>;

export type GenerateSplitCertificateMutation = {
  __typename?: "Mutation";
  generateSplitCertificate?: {
    __typename?: "GenerateSplitCertificatePayload";
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type GenerateGroupedCertificateMutationVariables = Exact<{
  input: GenerateGroupedCertificateInput;
}>;

export type GenerateGroupedCertificateMutation = {
  __typename?: "Mutation";
  generateGroupedCertificate?: {
    __typename?: "GenerateGroupedCertificatePayload";
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type GetAllCertificateTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCertificateTemplatesQuery = {
  __typename?: "Query";
  allCertificateTemplates?: {
    __typename?: "CertificateTemplateNodeConnection";
    edges: Array<{
      __typename?: "CertificateTemplateNodeEdge";
      node?: {
        __typename?: "CertificateTemplateNode";
        id: string;
        file: string;
        name?: string | null;
        uuid: string;
        createdAt: string;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificateTemplatesByTypeQueryVariables = Exact<{
  templateType?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllCertificateTemplatesByTypeQuery = {
  __typename?: "Query";
  allCertificateTemplates?: {
    __typename?: "CertificateTemplateNodeConnection";
    edges: Array<{
      __typename?: "CertificateTemplateNodeEdge";
      node?: {
        __typename?: "CertificateTemplateNode";
        id: string;
        file: string;
        name?: string | null;
        uuid: string;
        createdAt: string;
      } | null;
    } | null>;
  } | null;
};

export type CreateCertificateTemplateMutationVariables = Exact<{
  input: CreateCertificateTemplateInput;
}>;

export type CreateCertificateTemplateMutation = {
  __typename?: "Mutation";
  createCertificateTemplate?: {
    __typename?: "CreateCertificateTemplatePayload";
    certificateTemplate?: { __typename?: "CertificateTemplateNode"; id: string } | null;
  } | null;
};

export type DeleteCertificateTemplateMutationVariables = Exact<{
  input: DeleteCertificateTemplateInput;
}>;

export type DeleteCertificateTemplateMutation = {
  __typename?: "Mutation";
  deleteCertificateTemplate?: {
    __typename?: "DeleteCertificateTemplatePayload";
    certificateTemplate?: { __typename?: "CertificateTemplateNode"; id: string } | null;
  } | null;
};

export type GetPolicyDiffChangesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPolicyDiffChangesQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt?: string | null;
    expiresAt?: string | null;
    policyType: string;
    diffChanges?: Array<{
      __typename?: "DiffChangesNode";
      changeReason?: string | null;
      changedAt?: string | null;
      field?: string | null;
      old?: string | null;
      new?: string | null;
      historyId?: string | null;
      historyUser?: { __typename?: "UserNode"; name: string } | null;
    } | null> | null;
    history?: Array<{
      __typename?: "HistoryListItemNode";
      historyId?: string | null;
      historyChangeReason?: string | null;
      historyDate?: string | null;
    } | null> | null;
    contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
    insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
    broker?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
  } | null;
};

export type UpdateExchangeRatesMutationVariables = Exact<{
  input: UpdateExchangeRatesInput;
}>;

export type UpdateExchangeRatesMutation = {
  __typename?: "Mutation";
  updateExchangeRates?: {
    __typename?: "UpdateExchangeRatesPayload";
    exchangeRate?: { __typename?: "ExchangeRatesNode"; id: string } | null;
  } | null;
};

export type RegistryInfoFieldsFragment = {
  __typename?: "RegistryNode";
  createdAt: string;
  updatedAt: string;
  isPublic?: boolean | null;
  createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserType"; name: string; email: string } | null;
};

export type RegistryAnagraphicFieldsFragment = {
  __typename?: "RegistryNode";
  id: string;
  isCompany: boolean;
  fullName?: string | null;
  businessName?: string | null;
  alias?: string | null;
  role?: string | null;
  displayName?: string | null;
  webSite?: string | null;
  note?: string | null;
  countryCode?: string | null;
  taxId?: string | null;
  printName?: string | null;
  printCode?: string | null;
  phone?: string | null;
  secondaryPhone?: string | null;
  tertiaryPhone?: string | null;
  mobilePhone?: string | null;
  secondaryMobilePhone?: string | null;
  tertiaryMobilePhone?: string | null;
  email?: string | null;
  secondaryEmail?: string | null;
  tertiaryEmail?: string | null;
  isAuthor?: boolean | null;
  birthDate?: string | null;
  birthPlace?: string | null;
  deathDate?: string | null;
  deathPlace?: string | null;
  activityPeriod?: string | null;
  activityPlace?: string | null;
  artisticMovement?: string | null;
  foundation?: string | null;
  categories?: Array<string | null> | null;
  authorType?: Array<string | null> | null;
  logo?: string | null;
  invoiceAddress?: {
    __typename?: "Address";
    raw?: string | null;
    country?: string | null;
    countryCode?: string | null;
    locality?: string | null;
    route?: string | null;
    state?: string | null;
    streetNumber?: string | null;
    formatted?: string | null;
  } | null;
};

export type RegistryFindingFieldsFragment = {
  __typename?: "RegistryNode";
  id: string;
  categories?: Array<string | null> | null;
  fullName?: string | null;
  businessName?: string | null;
  email?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  officesCount?: number | null;
  createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
};

export type OfficeAnagraphicFieldsFragment = {
  __typename?: "OfficeNode";
  id: string;
  name?: string | null;
};

export type CompanyContactAnagraphicFieldsFragment = {
  __typename?: "CompanyContactNode";
  id: string;
  main?: boolean | null;
  companyContact: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
    alias?: string | null;
    role?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
  };
  relatedCompany: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
    alias?: string | null;
    role?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
  };
};

export type GetAllRegistriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  after?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  categories_In?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  orderBy?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  isPublic?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetAllRegistriesQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        mainOfficeAddress?: string | null;
        role?: string | null;
        actualCompanyContactsNumber?: string | null;
        isAuthor?: boolean | null;
        isPublic?: boolean | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        companyContact: {
          __typename?: "CompanyContactNodeConnection";
          edges: Array<{
            __typename?: "CompanyContactNodeEdge";
            node?: {
              __typename?: "CompanyContactNode";
              relatedCompany: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
            } | null;
          } | null>;
        };
        createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUniqueIrrelevantRegistryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUniqueIrrelevantRegistryQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null>;
  } | null;
};

export type GetUniqueAnonymousRegistryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUniqueAnonymousRegistryQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null>;
  } | null;
};

export type GetUniqueUnknownRegistryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUniqueUnknownRegistryQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    } | null>;
  } | null;
};

export type GetAllRegistriesAuthorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesAuthorsQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        alias?: string | null;
        birthDate?: string | null;
        deathDate?: string | null;
        authorType?: Array<string | null> | null;
        authoredAssetsCount?: number | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllRegistriesPeopleQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesPeopleQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        role?: string | null;
        mainOfficeAddress?: string | null;
        actualCompanyContactsNumber?: string | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllRegistriesCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesCompaniesQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        role?: string | null;
        mainOfficeAddress?: string | null;
        actualCompanyContactsNumber?: string | null;
        id: string;
        categories?: Array<string | null> | null;
        fullName?: string | null;
        businessName?: string | null;
        email?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        officesCount?: number | null;
        createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRegistriesBySearchQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GetRegistriesBySearchQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        categories?: Array<string | null> | null;
        isAuthor?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRegistriesByExactSearchQueryVariables = Exact<{
  exactSearch: Scalars["String"];
}>;

export type GetRegistriesByExactSearchQuery = {
  __typename?: "Query";
  registries?: Array<{
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    secondaryPhone?: string | null;
    tertiaryPhone?: string | null;
    mobilePhone?: string | null;
    secondaryMobilePhone?: string | null;
    tertiaryMobilePhone?: string | null;
    email?: string | null;
    secondaryEmail?: string | null;
    tertiaryEmail?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    logo?: string | null;
    createdAt: string;
    updatedAt: string;
    isPublic?: boolean | null;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      country?: string | null;
      countryCode?: string | null;
      locality?: string | null;
      route?: string | null;
      state?: string | null;
      streetNumber?: string | null;
      formatted?: string | null;
    } | null;
    createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; name: string; email: string } | null;
  } | null> | null;
};

export type GetRegistryByIdInFormQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRegistryByIdInFormQuery = {
  __typename?: "Query";
  registry?: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
    categories?: Array<string | null> | null;
    businessName?: string | null;
    isAuthor?: boolean | null;
    isCompany: boolean;
  } | null;
};

export type GetRegistryByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRegistryByIdQuery = {
  __typename?: "Query";
  registry?: {
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    secondaryPhone?: string | null;
    tertiaryPhone?: string | null;
    mobilePhone?: string | null;
    secondaryMobilePhone?: string | null;
    tertiaryMobilePhone?: string | null;
    email?: string | null;
    secondaryEmail?: string | null;
    tertiaryEmail?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    logo?: string | null;
    createdAt: string;
    updatedAt: string;
    isPublic?: boolean | null;
    officeRegistry: {
      __typename?: "OfficeNodeConnection";
      edges: Array<{
        __typename?: "OfficeNodeEdge";
        node?: {
          __typename?: "OfficeNode";
          id: string;
          name?: string | null;
          address?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
          country?: string | null;
          phone?: string | null;
          email?: string | null;
          main?: boolean | null;
          active?: boolean | null;
          officeAddress?: {
            __typename?: "Address";
            raw?: string | null;
            locality?: string | null;
            state?: string | null;
            country?: string | null;
            streetNumber?: string | null;
            route?: string | null;
          } | null;
        } | null;
      } | null>;
    };
    registryAssets?: Array<{
      __typename?: "RegistryAssetsNode";
      type?: Array<string | null> | null;
      id?: string | null;
      notes?: string | null;
      linkedActualId?: number | null;
      asset?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category?: string | null;
        subcategory?: string | null;
        author?: string | null;
        actualDateAndPeriod?: string | null;
        creationDate?: string | null;
        genericDimensions?: string | null;
        mainImage?: string | null;
        deleted?: string | null;
        validated?: boolean | null;
        technique?: string | null;
        inventoryNumber?: string | null;
        archiveNumber?: string | null;
        createdBy?: {
          __typename?: "UserType";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null> | null;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      country?: string | null;
      countryCode?: string | null;
      locality?: string | null;
      route?: string | null;
      state?: string | null;
      streetNumber?: string | null;
      formatted?: string | null;
    } | null;
    createdBy?: { __typename?: "UserType"; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; name: string; email: string } | null;
  } | null;
};

export type GetRegistryByIdToEditQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRegistryByIdToEditQuery = {
  __typename?: "Query";
  registry?: {
    __typename?: "RegistryNode";
    isPublic?: boolean | null;
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    role?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    secondaryPhone?: string | null;
    tertiaryPhone?: string | null;
    mobilePhone?: string | null;
    secondaryMobilePhone?: string | null;
    tertiaryMobilePhone?: string | null;
    email?: string | null;
    secondaryEmail?: string | null;
    tertiaryEmail?: string | null;
    isAuthor?: boolean | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    deathDate?: string | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
    logo?: string | null;
    invoiceAddress?: {
      __typename?: "Address";
      raw?: string | null;
      country?: string | null;
      countryCode?: string | null;
      locality?: string | null;
      route?: string | null;
      state?: string | null;
      streetNumber?: string | null;
      formatted?: string | null;
    } | null;
  } | null;
};

export type CreateRegistryMutationVariables = Exact<{
  input: CreateRegistryInput;
}>;

export type CreateRegistryMutation = {
  __typename?: "Mutation";
  createRegistry?: {
    __typename?: "CreateRegistryPayload";
    registry?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
      businessName?: string | null;
      alias?: string | null;
      displayName?: string | null;
    } | null;
  } | null;
};

export type UpdateRegistryMutationVariables = Exact<{
  input: UpdateRegistryInput;
}>;

export type UpdateRegistryMutation = {
  __typename?: "Mutation";
  updateRegistry?: {
    __typename?: "UpdateRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type DeleteRegistryMutationVariables = Exact<{
  input: DeleteRegistryInput;
}>;

export type DeleteRegistryMutation = {
  __typename?: "Mutation";
  deleteRegistry?: {
    __typename?: "DeleteRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type GetOfficesBySearchQueryVariables = Exact<{
  search: Scalars["String"];
  registryId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetOfficesBySearchQuery = {
  __typename?: "Query";
  offices?: Array<{ __typename?: "OfficeNode"; id: string; name?: string | null } | null> | null;
};

export type GetOfficeByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOfficeByIdQuery = {
  __typename?: "Query";
  office?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
};

export type CreateOfficeMutationVariables = Exact<{
  input: CreateOfficeInput;
}>;

export type CreateOfficeMutation = {
  __typename?: "Mutation";
  createOffice?: {
    __typename?: "CreateOfficePayload";
    office?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
  } | null;
};

export type UpdateOfficeMutationVariables = Exact<{
  input: UpdateOfficeInput;
}>;

export type UpdateOfficeMutation = {
  __typename?: "Mutation";
  updateOffice?: {
    __typename?: "UpdateOfficePayload";
    office?: {
      __typename?: "OfficeType";
      id: string;
      officeAddress?: {
        __typename?: "Address";
        raw?: string | null;
        locality?: string | null;
        state?: string | null;
        country?: string | null;
        streetNumber?: string | null;
        route?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteOfficeMutationVariables = Exact<{
  input: DeleteOfficeInput;
}>;

export type DeleteOfficeMutation = {
  __typename?: "Mutation";
  deleteOffice?: {
    __typename?: "DeleteOfficePayload";
    office?: { __typename?: "OfficeNode"; id: string } | null;
  } | null;
};

export type GetOfficesByRegistryIdQueryVariables = Exact<{
  registryId: Scalars["ID"];
}>;

export type GetOfficesByRegistryIdQuery = {
  __typename?: "Query";
  allOffices?: {
    __typename?: "OfficeNodeConnection";
    edges: Array<{
      __typename?: "OfficeNodeEdge";
      node?: {
        __typename?: "OfficeNode";
        id: string;
        name?: string | null;
        address?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
        country?: string | null;
        phone?: string | null;
        email?: string | null;
        main?: boolean | null;
        active?: boolean | null;
        officeAddress?: {
          __typename?: "Address";
          raw?: string | null;
          locality?: string | null;
          state?: string | null;
          country?: string | null;
          streetNumber?: string | null;
          route?: string | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCompanyContactsByRegistryIdQueryVariables = Exact<{
  registryId: Scalars["ID"];
}>;

export type GetCompanyContactsByRegistryIdQuery = {
  __typename?: "Query";
  allCompanyContacts?: {
    __typename?: "CompanyContactNodeConnection";
    edges: Array<{
      __typename?: "CompanyContactNodeEdge";
      node?: {
        __typename?: "CompanyContactNode";
        id: string;
        main?: boolean | null;
        companyContact: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
          alias?: string | null;
          role?: string | null;
          phone?: string | null;
          mobilePhone?: string | null;
          email?: string | null;
          note?: string | null;
          webSite?: string | null;
          printCode?: string | null;
          printName?: string | null;
          countryCode?: string | null;
          taxId?: string | null;
          billingAddress?: string | null;
          isPublic?: boolean | null;
          mainOfficeAddress?: string | null;
          secondaryMobilePhone?: string | null;
          tertiaryMobilePhone?: string | null;
          secondaryEmail?: string | null;
          tertiaryEmail?: string | null;
          secondaryPhone?: string | null;
          tertiaryPhone?: string | null;
        };
        relatedCompany: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
          alias?: string | null;
          role?: string | null;
          phone?: string | null;
          mobilePhone?: string | null;
          email?: string | null;
          note?: string | null;
          webSite?: string | null;
          printCode?: string | null;
          printName?: string | null;
          countryCode?: string | null;
          taxId?: string | null;
          billingAddress?: string | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type GetContactCompaniesByRegistryIdQueryVariables = Exact<{
  registryId: Scalars["ID"];
}>;

export type GetContactCompaniesByRegistryIdQuery = {
  __typename?: "Query";
  allCompanyContacts?: {
    __typename?: "CompanyContactNodeConnection";
    edges: Array<{
      __typename?: "CompanyContactNodeEdge";
      node?: {
        __typename?: "CompanyContactNode";
        id: string;
        main?: boolean | null;
        companyContact: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
          alias?: string | null;
          role?: string | null;
          phone?: string | null;
          mobilePhone?: string | null;
          email?: string | null;
          note?: string | null;
          webSite?: string | null;
          printCode?: string | null;
          printName?: string | null;
          countryCode?: string | null;
          taxId?: string | null;
          billingAddress?: string | null;
          secondaryEmail?: string | null;
          tertiaryEmail?: string | null;
          secondaryMobilePhone?: string | null;
          tertiaryMobilePhone?: string | null;
        };
        relatedCompany: {
          __typename?: "RegistryNode";
          id: string;
          fullName?: string | null;
          alias?: string | null;
          role?: string | null;
          phone?: string | null;
          mobilePhone?: string | null;
          email?: string | null;
          note?: string | null;
          webSite?: string | null;
          printCode?: string | null;
          printName?: string | null;
          countryCode?: string | null;
          taxId?: string | null;
          billingAddress?: string | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type DeleteCompanyContactMutationVariables = Exact<{
  input: DeleteCompanyContactInput;
}>;

export type DeleteCompanyContactMutation = {
  __typename?: "Mutation";
  deleteCompanyContact?: {
    __typename?: "DeleteCompanyContactPayload";
    companyContact?: { __typename?: "CompanyContactNode"; id: string } | null;
  } | null;
};

export type CreateCompanyContactMutationVariables = Exact<{
  input: CreateCompanyContactInput;
}>;

export type CreateCompanyContactMutation = {
  __typename?: "Mutation";
  createCompanyContact?: {
    __typename?: "CreateCompanyContactPayload";
    companyContact?: {
      __typename?: "CompanyContactNode";
      id: string;
      main?: boolean | null;
      companyContact: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
      };
      relatedCompany: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
      };
    } | null;
  } | null;
};

export type UpdateCompanyContactMutationVariables = Exact<{
  input: UpdateCompanyContactInput;
}>;

export type UpdateCompanyContactMutation = {
  __typename?: "Mutation";
  updateCompanyContact?: {
    __typename?: "UpdateCompanyContactPayload";
    companyContact?: {
      __typename?: "CompanyContactType";
      id: string;
      main?: boolean | null;
      companyContact: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
        note?: string | null;
        webSite?: string | null;
        printCode?: string | null;
        printName?: string | null;
        countryCode?: string | null;
        taxId?: string | null;
        billingAddress?: string | null;
      };
      relatedCompany: { __typename?: "RegistryNode"; id: string };
    } | null;
  } | null;
};

export type CreateRegistryWithCompanyContactMutationVariables = Exact<{
  input: CreateRegistryWithCompanyContactInput;
}>;

export type CreateRegistryWithCompanyContactMutation = {
  __typename?: "Mutation";
  createRegistryWithCompanyContact?: {
    __typename?: "CreateRegistryWithCompanyContactPayload";
    companyContact?: {
      __typename?: "CompanyContactNode";
      id: string;
      main?: boolean | null;
      companyContact: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
      };
      relatedCompany: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        alias?: string | null;
        role?: string | null;
        phone?: string | null;
        mobilePhone?: string | null;
        email?: string | null;
      };
    } | null;
  } | null;
};

export type LinkRegistryWithAssetMutationVariables = Exact<{
  input: LinkRegistryWithAssetInput;
}>;

export type LinkRegistryWithAssetMutation = {
  __typename?: "Mutation";
  linkRegistryWithAsset?: {
    __typename?: "LinkRegistryWithAssetPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type UnlinkRegistryWithAssetMutationVariables = Exact<{
  input: UnlinkRegistryWithAssetInput;
}>;

export type UnlinkRegistryWithAssetMutation = {
  __typename?: "Mutation";
  unlinkRegistryWithAsset?: {
    __typename?: "UnlinkRegistryWithAssetPayload";
    clientMutationId?: string | null;
  } | null;
};

export type SetPublicRegistryMutationVariables = Exact<{
  input: SetPublicRegistryInput;
}>;

export type SetPublicRegistryMutation = {
  __typename?: "Mutation";
  setPublicRegistry?: {
    __typename?: "SetPublicRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type SetPrivateRegistryMutationVariables = Exact<{
  input: SetPrivateRegistryInput;
}>;

export type SetPrivateRegistryMutation = {
  __typename?: "Mutation";
  setPrivateRegistry?: {
    __typename?: "SetPrivateRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type UpdateLinkedRegistryAssetMutationVariables = Exact<{
  input: UpdateLinkedRegistryAssetInput;
}>;

export type UpdateLinkedRegistryAssetMutation = {
  __typename?: "Mutation";
  updateLinkedRegistryAsset?: {
    __typename?: "UpdateLinkedRegistryAssetPayload";
    linkedAssetRegistry?: {
      __typename?: "LinkedAssetRegistryNode";
      id: string;
      notes?: string | null;
    } | null;
  } | null;
};

export type GetOfficesByRegistryIdFullQueryVariables = Exact<{
  office: Scalars["ID"];
}>;

export type GetOfficesByRegistryIdFullQuery = {
  __typename?: "Query";
  office?: {
    __typename?: "OfficeNode";
    id: string;
    name?: string | null;
    address?: string | null;
    city?: string | null;
    zip?: string | null;
    state?: string | null;
    country?: string | null;
    phone?: string | null;
    email?: string | null;
    main?: boolean | null;
    active?: boolean | null;
    officeAddress?: {
      __typename?: "Address";
      raw?: string | null;
      locality?: string | null;
      state?: string | null;
      country?: string | null;
      streetNumber?: string | null;
      route?: string | null;
    } | null;
  } | null;
};

export type DeleteRegistryLogoMutationVariables = Exact<{
  input: DeleteRegistryLogoInput;
}>;

export type DeleteRegistryLogoMutation = {
  __typename?: "Mutation";
  deleteRegistryLogo?: {
    __typename?: "DeleteRegistryLogoPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type RestorationFindingFieldsFragment = {
  __typename?: "RestorationNode";
  id: string;
  restorationStatus?: string | null;
  restorationDocumentDate?: string | null;
  restorationStartDate?: string | null;
  restorationEndDate?: string | null;
  isSigned?: boolean | null;
  restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  restorationAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
  createdBy?: { __typename?: "UserType"; name: string } | null;
};

export type RestorationAnagraphicFieldsFragment = {
  __typename?: "RestorationNode";
  id: string;
  restorationStatus?: string | null;
  restorationRestoredBy?: string | null;
  isSigned?: boolean | null;
  restorationInventoryNumber?: string | null;
  restorationStartDate?: string | null;
  restorationEndDate?: string | null;
  restorationLocation?: string | null;
  restorationCollocationFeatures?: string | null;
  restorationDocumentDate?: string | null;
  conservativeDataHistoryInterventions?: string | null;
  conservativeDataConservationStatus?: string | null;
  conservativeDataAssetAlterations?: string | null;
  conservativeDataDeteriorationCauses?: string | null;
  conservativeDataExaminationMethod?: string | null;
  conservativeDataInterventionProposal?: string | null;
  conservativeDataPreliminaryOperations?: string | null;
  conservativeDataIntervention?: string | null;
  conservativeDataInterventionMaterials?: string | null;
  conservativeDataTechnicalMethodologicalConsiderations?: string | null;
  conservativeDataMaintenancePreparationAssembly?: string | null;
  conservativeDataRestorationBibliography?: string | null;
  conservativeDataNotesRestorationSheet?: string | null;
  restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
  restorationCompany?: {
    __typename?: "RegistryNode";
    id: string;
    fullName?: string | null;
    businessName?: string | null;
  } | null;
  restorationAsset: {
    __typename?: "AssetNode";
    id: string;
    title: string;
    actualDateAndPeriod?: string | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  };
  restorationCost?: {
    __typename?: "Money";
    amount?: number | null;
    currency?: { __typename?: "Currency"; code: string } | null;
  } | null;
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: {
        __typename?: "MediaNode";
        id: string;
        name?: string | null;
        file: string;
        description?: string | null;
        mediaType?: string | null;
        isMainImage?: boolean | null;
        imageType?: string | null;
        notes?: string | null;
        audioVideoFileType?: string | null;
        creationDate?: any | null;
        fileAuthor?: string | null;
        fileSize?: string | null;
        photographicEquipment?: string | null;
        matrixType?: string | null;
        isGlassPlexyAcetate?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllRestorationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRestorationsQuery = {
  __typename?: "Query";
  allRestorations?: {
    __typename?: "RestorationNodeConnection";
    edges: Array<{
      __typename?: "RestorationNodeEdge";
      node?: {
        __typename?: "RestorationNode";
        createdAt: string;
        actualMediaStatus?: string | null;
        mediaNumber?: number | null;
        id: string;
        restorationStatus?: string | null;
        restorationDocumentDate?: string | null;
        restorationStartDate?: string | null;
        restorationEndDate?: string | null;
        isSigned?: boolean | null;
        restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
        restorationAsset: {
          __typename?: "AssetNode";
          id: string;
          title: string;
          actualDateAndPeriod?: string | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        };
        createdBy?: { __typename?: "UserType"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRestorationByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRestorationByIdQuery = {
  __typename?: "Query";
  restoration?: {
    __typename?: "RestorationNode";
    id: string;
    restorationStatus?: string | null;
    restorationRestoredBy?: string | null;
    isSigned?: boolean | null;
    restorationInventoryNumber?: string | null;
    restorationStartDate?: string | null;
    restorationEndDate?: string | null;
    restorationLocation?: string | null;
    restorationCollocationFeatures?: string | null;
    restorationDocumentDate?: string | null;
    conservativeDataHistoryInterventions?: string | null;
    conservativeDataConservationStatus?: string | null;
    conservativeDataAssetAlterations?: string | null;
    conservativeDataDeteriorationCauses?: string | null;
    conservativeDataExaminationMethod?: string | null;
    conservativeDataInterventionProposal?: string | null;
    conservativeDataPreliminaryOperations?: string | null;
    conservativeDataIntervention?: string | null;
    conservativeDataInterventionMaterials?: string | null;
    conservativeDataTechnicalMethodologicalConsiderations?: string | null;
    conservativeDataMaintenancePreparationAssembly?: string | null;
    conservativeDataRestorationBibliography?: string | null;
    conservativeDataNotesRestorationSheet?: string | null;
    restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    restorationCompany?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
      businessName?: string | null;
    } | null;
    restorationAsset: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      actualDateAndPeriod?: string | null;
      authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    };
    restorationCost?: {
      __typename?: "Money";
      amount?: number | null;
      currency?: { __typename?: "Currency"; code: string } | null;
    } | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateRestorationMutationVariables = Exact<{
  input: CreateRestorationInput;
}>;

export type CreateRestorationMutation = {
  __typename?: "Mutation";
  createRestoration?: {
    __typename?: "CreateRestorationPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      restorationStatus?: string | null;
      restorationRestoredBy?: string | null;
      restorationInventoryNumber?: string | null;
      restorationStartDate?: string | null;
      restorationEndDate?: string | null;
      restorationLocation?: string | null;
      restorationCollocationFeatures?: string | null;
      restorationDocumentDate?: string | null;
      conservativeDataHistoryInterventions?: string | null;
      conservativeDataConservationStatus?: string | null;
      conservativeDataAssetAlterations?: string | null;
      conservativeDataDeteriorationCauses?: string | null;
      conservativeDataExaminationMethod?: string | null;
      conservativeDataInterventionProposal?: string | null;
      conservativeDataPreliminaryOperations?: string | null;
      conservativeDataIntervention?: string | null;
      conservativeDataInterventionMaterials?: string | null;
      conservativeDataTechnicalMethodologicalConsiderations?: string | null;
      conservativeDataMaintenancePreparationAssembly?: string | null;
      conservativeDataRestorationBibliography?: string | null;
      conservativeDataNotesRestorationSheet?: string | null;
      restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      restorationCompany?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        businessName?: string | null;
      } | null;
      restorationAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      restorationCost?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: {
            __typename?: "MediaNode";
            id: string;
            name?: string | null;
            file: string;
            description?: string | null;
            mediaType?: string | null;
            isMainImage?: boolean | null;
            imageType?: string | null;
            notes?: string | null;
            audioVideoFileType?: string | null;
            creationDate?: any | null;
            fileAuthor?: string | null;
            fileSize?: string | null;
            photographicEquipment?: string | null;
            matrixType?: string | null;
            isGlassPlexyAcetate?: boolean | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateRestorationMutationVariables = Exact<{
  input: UpdateRestorationInput;
}>;

export type UpdateRestorationMutation = {
  __typename?: "Mutation";
  updateRestoration?: {
    __typename?: "UpdateRestorationPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      restorationStatus?: string | null;
      restorationRestoredBy?: string | null;
      restorationInventoryNumber?: string | null;
      restorationStartDate?: string | null;
      restorationEndDate?: string | null;
      restorationLocation?: string | null;
      restorationCollocationFeatures?: string | null;
      restorationDocumentDate?: string | null;
      conservativeDataHistoryInterventions?: string | null;
      conservativeDataConservationStatus?: string | null;
      conservativeDataAssetAlterations?: string | null;
      conservativeDataDeteriorationCauses?: string | null;
      conservativeDataExaminationMethod?: string | null;
      conservativeDataInterventionProposal?: string | null;
      conservativeDataPreliminaryOperations?: string | null;
      conservativeDataIntervention?: string | null;
      conservativeDataInterventionMaterials?: string | null;
      conservativeDataTechnicalMethodologicalConsiderations?: string | null;
      conservativeDataMaintenancePreparationAssembly?: string | null;
      conservativeDataRestorationBibliography?: string | null;
      conservativeDataNotesRestorationSheet?: string | null;
      restorationDirector: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
      restorationCompany?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        businessName?: string | null;
      } | null;
      restorationAsset: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        actualDateAndPeriod?: string | null;
        authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      };
      restorationCost?: {
        __typename?: "Money";
        amount?: number | null;
        currency?: { __typename?: "Currency"; code: string } | null;
      } | null;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: {
            __typename?: "MediaNode";
            id: string;
            name?: string | null;
            file: string;
            description?: string | null;
            mediaType?: string | null;
            isMainImage?: boolean | null;
            imageType?: string | null;
            notes?: string | null;
            audioVideoFileType?: string | null;
            creationDate?: any | null;
            fileAuthor?: string | null;
            fileSize?: string | null;
            photographicEquipment?: string | null;
            matrixType?: string | null;
            isGlassPlexyAcetate?: boolean | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteRestorationMutationVariables = Exact<{
  input: DeleteRestorationInput;
}>;

export type DeleteRestorationMutation = {
  __typename?: "Mutation";
  deleteRestoration?: {
    __typename?: "DeleteRestorationPayload";
    deleteRestoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type CloseRestorationMutationVariables = Exact<{
  input: CloseRestorationInput;
}>;

export type CloseRestorationMutation = {
  __typename?: "Mutation";
  closeRestoration?: {
    __typename?: "CloseRestorationPayload";
    closeRestoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type DetachAssetFromRestorationMutationVariables = Exact<{
  input: DetachAssetFromRestorationInput;
}>;

export type DetachAssetFromRestorationMutation = {
  __typename?: "Mutation";
  detachAssetFromRestoration?: {
    __typename?: "DetachAssetFromRestorationPayload";
    restoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type GetMediaRestorationByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMediaRestorationByIdQuery = {
  __typename?: "Query";
  restoration?: {
    __typename?: "RestorationNode";
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AddRestorationMediaMutationVariables = Exact<{
  input: AddRestorationMediaInput;
}>;

export type AddRestorationMediaMutation = {
  __typename?: "Mutation";
  addRestorationMedia?: {
    __typename?: "AddRestorationMediaPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AddMultipleRestorationMediaMutationVariables = Exact<{
  input: AddMultipleRestorationMediaInput;
}>;

export type AddMultipleRestorationMediaMutation = {
  __typename?: "Mutation";
  addMultipleRestorationMedia?: {
    __typename?: "AddMultipleRestorationMediaPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteRestorationMediaMutationVariables = Exact<{
  input: DeleteRestorationMediaInput;
}>;

export type DeleteRestorationMediaMutation = {
  __typename?: "Mutation";
  deleteRestorationMedia?: {
    __typename?: "DeleteRestorationMediaPayload";
    restoration?: {
      __typename?: "RestorationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportRestorationPdfMutationVariables = Exact<{
  input: ExportRestorationPdfInput;
}>;

export type ExportRestorationPdfMutation = {
  __typename?: "Mutation";
  exportRestorationPdf?: {
    __typename?: "ExportRestorationPDFPayload";
    clientMutationId?: string | null;
    url?: string | null;
  } | null;
};

export type DuplicateRestorationMutationVariables = Exact<{
  input: DuplicateRestorationInput;
}>;

export type DuplicateRestorationMutation = {
  __typename?: "Mutation";
  duplicateRestoration?: {
    __typename?: "DuplicateRestorationPayload";
    restoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type SignRestorationMutationVariables = Exact<{
  input: SignRestorationInput;
}>;

export type SignRestorationMutation = {
  __typename?: "Mutation";
  signRestoration?: {
    __typename?: "SignRestorationPayload";
    restoration?: { __typename?: "RestorationNode"; id: string } | null;
  } | null;
};

export type TokenAuthMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type TokenAuthMutation = {
  __typename?: "Mutation";
  tokenAuth?: {
    __typename?: "ObtainJSONWebToken";
    token: string;
    payload: any;
    refreshToken: string;
    refreshExpiresIn: number;
    algoliaUserKey?: string | null;
    user?: {
      __typename?: "UserType";
      name: string;
      currency?: string | null;
      dateJoined: string;
      email: string;
      id: string;
      isActive: boolean;
      isStaff: boolean;
      isSuperuser?: boolean | null;
      language?: string | null;
      lastLogin?: string | null;
      phone?: string | null;
      timezone?: string | null;
      userType?: UsersUserUserTypeChoices | null;
      username: string;
      uuid: string;
      country?: { __typename?: "Country"; code?: string | null } | null;
      userPermissions: {
        __typename?: "PermissionNodeConnection";
        edges: Array<{
          __typename?: "PermissionNodeEdge";
          node?: {
            __typename?: "PermissionNode";
            id: string;
            codename: string;
            name: string;
            contentType: { __typename?: "ContentTypeNode"; model: string; id: string };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars["String"];
}>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken?: {
    __typename?: "Refresh";
    token: string;
    payload: any;
    refreshToken: string;
    refreshExpiresIn: number;
  } | null;
};

export type VerifyShareableTokenMutationVariables = Exact<{
  input: VerifyShareableTokenInput;
}>;

export type VerifyShareableTokenMutation = {
  __typename?: "Mutation";
  verifyShareableToken?: {
    __typename?: "VerifyShareableTokenPayload";
    token?: string | null;
    user?: { __typename?: "UserType"; name: string; email: string } | null;
  } | null;
};

export type ObtainShareableTokenMutationVariables = Exact<{
  input: ObtainShareableTokenInput;
}>;

export type ObtainShareableTokenMutation = {
  __typename?: "Mutation";
  shareableToken?: { __typename?: "ObtainShareableTokenPayload"; token?: string | null } | null;
};

export type TransportationFieldsFragment = {
  __typename?: "TransportationNode";
  id: string;
  coverType?: SlogTransportationCoverTypeChoices | null;
  createdAt: string;
  vehicleSpecifics?: string | null;
  uuid: string;
  updatedAt: string;
  transportationType?: SlogTransportationTransportationTypeChoices | null;
  transportationRequiredPersonnel?: string | null;
  transportationId?: string | null;
  status?: SlogTransportationStatusChoices | null;
  riskType?: SlogTransportationRiskTypeChoices | null;
  packageWeightInfo?: string | null;
  packageWeightUnit?: string | null;
  packageWeight?: number | null;
  packageVolume?: number | null;
  packageVolumeInfo?: string | null;
  packageVolumeUnit?: string | null;
  packageDescription?: string | null;
  otherCharacteristics?: string | null;
  numberOfPackages?: number | null;
  numberOfMedia?: number | null;
  numberOfAssets?: number | null;
  notes?: string | null;
  isModifiable?: boolean | null;
  isConfirmed: boolean;
  insuredTo?: string | null;
  insuredFrom?: string | null;
  insuranceStatus?: SlogTransportationInsuranceStatusChoices | null;
  insuranceNotes?: string | null;
  insuranceDetails?: string | null;
  instructions?: string | null;
  controlledTemperature?: boolean | null;
  isInsured?: boolean | null;
  filingNumber?: string | null;
  evaluationType?: SlogTransportationEvaluationTypeChoices | null;
  description?: string | null;
  requiredTemperature?: number | null;
  requiredTemperatureUnit?: string | null;
  temperatureInfo?: string | null;
  createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
  customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  vector?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  pickupDetails?: {
    __typename?: "PickupDetailsNode";
    cellularNumber?: string | null;
    createdAt: string;
    email?: string | null;
    id: string;
    notes?: string | null;
    pickupDatetime?: string | null;
    pickupType?: SlogPickupDetailsPickupTypeChoices | null;
    telephoneNumber?: string | null;
    updatedAt: string;
    uuid: string;
    sender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    pickupLocation?: {
      __typename?: "OfficeNode";
      name?: string | null;
      id: string;
      address?: string | null;
      officeAddress?: { __typename?: "Address"; raw?: string | null } | null;
    } | null;
  } | null;
  deliveryDetails?: {
    __typename?: "DeliveryDetailsNode";
    cellularNumber?: string | null;
    createdAt: string;
    deliveryDatetime?: string | null;
    deliveryType?: SlogDeliveryDetailsDeliveryTypeChoices | null;
    email?: string | null;
    id: string;
    notes?: string | null;
    telephoneNumber?: string | null;
    updatedAt: string;
    uuid: string;
    recipient?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    deliveryLocation?: {
      __typename?: "OfficeNode";
      name?: string | null;
      id: string;
      address?: string | null;
      officeAddress?: { __typename?: "Address"; raw?: string | null } | null;
    } | null;
  } | null;
};

export type PickupDetailsFieldsFragment = {
  __typename?: "PickupDetailsNode";
  telephoneNumber?: string | null;
  cellularNumber?: string | null;
  email?: string | null;
  pickupDatetime?: string | null;
  pickupType?: SlogPickupDetailsPickupTypeChoices | null;
  notes?: string | null;
  sender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  pickupLocation?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
};

export type DeliveryDetailsFieldsFragment = {
  __typename?: "DeliveryDetailsNode";
  telephoneNumber?: string | null;
  cellularNumber?: string | null;
  email?: string | null;
  deliveryDatetime?: string | null;
  deliveryType?: SlogDeliveryDetailsDeliveryTypeChoices | null;
  notes?: string | null;
  recipient?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  deliveryLocation?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
};

export type PackageFieldsFragment = {
  __typename?: "PackagesNode";
  id: string;
  createdAt: string;
  description?: string | null;
  dimensionsNotes?: string | null;
  grossWeight?: number | null;
  grossWeightUnitOfMeasure?: string | null;
  netWeight?: number | null;
  netWeightUnitOfMeasure?: string | null;
  hardPackagingDescription?: string | null;
  hardPackagingNotes?: string | null;
  hardPackagingType?: SlogPackagesHardPackagingTypeChoices | null;
  height?: number | null;
  width?: number | null;
  depth?: number | null;
  linearUnitOfMeasure?: string | null;
  number?: string | null;
  packaging?: SlogPackagesPackagingChoices | null;
  packagingNotes?: string | null;
  packagingType?: string | null;
  packingUnpackingInstructions?: string | null;
  softPackagingDescription?: string | null;
  updatedAt: string;
  uuid: string;
  volume?: number | null;
  volumeUnitOfMeasure?: string | null;
  packageVolumeInfo?: string | null;
  packageNetWeightInfo?: string | null;
  packageGrossWeightInfo?: string | null;
  packageDimensionsInfo?: string | null;
};

export type GetTransportationsByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTransportationsByIdQuery = {
  __typename?: "Query";
  transportation?: {
    __typename?: "TransportationNode";
    id: string;
    coverType?: SlogTransportationCoverTypeChoices | null;
    createdAt: string;
    vehicleSpecifics?: string | null;
    uuid: string;
    updatedAt: string;
    transportationType?: SlogTransportationTransportationTypeChoices | null;
    transportationRequiredPersonnel?: string | null;
    transportationId?: string | null;
    status?: SlogTransportationStatusChoices | null;
    riskType?: SlogTransportationRiskTypeChoices | null;
    packageWeightInfo?: string | null;
    packageWeightUnit?: string | null;
    packageWeight?: number | null;
    packageVolume?: number | null;
    packageVolumeInfo?: string | null;
    packageVolumeUnit?: string | null;
    packageDescription?: string | null;
    otherCharacteristics?: string | null;
    numberOfPackages?: number | null;
    numberOfMedia?: number | null;
    numberOfAssets?: number | null;
    notes?: string | null;
    isModifiable?: boolean | null;
    isConfirmed: boolean;
    insuredTo?: string | null;
    insuredFrom?: string | null;
    insuranceStatus?: SlogTransportationInsuranceStatusChoices | null;
    insuranceNotes?: string | null;
    insuranceDetails?: string | null;
    instructions?: string | null;
    controlledTemperature?: boolean | null;
    isInsured?: boolean | null;
    filingNumber?: string | null;
    evaluationType?: SlogTransportationEvaluationTypeChoices | null;
    description?: string | null;
    requiredTemperature?: number | null;
    requiredTemperatureUnit?: string | null;
    temperatureInfo?: string | null;
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: {
          __typename?: "MediaNode";
          id: string;
          name?: string | null;
          file: string;
          description?: string | null;
          mediaType?: string | null;
          isMainImage?: boolean | null;
          imageType?: string | null;
          notes?: string | null;
          audioVideoFileType?: string | null;
          creationDate?: any | null;
          fileAuthor?: string | null;
          fileSize?: string | null;
          photographicEquipment?: string | null;
          matrixType?: string | null;
          isGlassPlexyAcetate?: boolean | null;
          createdAt: string;
        } | null;
      } | null>;
    };
    transportationassetsSet: {
      __typename?: "TransportationAssetNodeConnection";
      edges: Array<{
        __typename?: "TransportationAssetNodeEdge";
        node?: {
          __typename?: "TransportationAssetNode";
          id: string;
          asset?: {
            __typename?: "AssetNode";
            id: string;
            mainImage?: string | null;
            category?: string | null;
            author?: string | null;
            title: string;
            actualDateAndPeriod?: string | null;
            genericDimensions?: string | null;
            technique?: string | null;
            inventoryNumber?: string | null;
            netWeight?: number | null;
            netWeightUnit?: string | null;
            grossWeight?: number | null;
            authorEntity?: {
              __typename?: "RegistryNode";
              id: string;
              fullName?: string | null;
            } | null;
            createdBy?: {
              __typename?: "UserType";
              name: string;
              userType?: UsersUserUserTypeChoices | null;
            } | null;
          } | null;
          package?: {
            __typename?: "PackagesNode";
            grossWeight?: number | null;
            grossWeightUnitOfMeasure?: string | null;
            netWeight?: number | null;
            netWeightUnitOfMeasure?: string | null;
            height?: number | null;
            width?: number | null;
            depth?: number | null;
            linearUnitOfMeasure?: string | null;
            packagingType?: string | null;
            packageGrossWeightInfo?: string | null;
            packageNetWeightInfo?: string | null;
            packageDimensionsInfo?: string | null;
          } | null;
        } | null;
      } | null>;
    };
    createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
    customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    vector?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    pickupDetails?: {
      __typename?: "PickupDetailsNode";
      cellularNumber?: string | null;
      createdAt: string;
      email?: string | null;
      id: string;
      notes?: string | null;
      pickupDatetime?: string | null;
      pickupType?: SlogPickupDetailsPickupTypeChoices | null;
      telephoneNumber?: string | null;
      updatedAt: string;
      uuid: string;
      sender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      pickupLocation?: {
        __typename?: "OfficeNode";
        name?: string | null;
        id: string;
        address?: string | null;
        officeAddress?: { __typename?: "Address"; raw?: string | null } | null;
      } | null;
    } | null;
    deliveryDetails?: {
      __typename?: "DeliveryDetailsNode";
      cellularNumber?: string | null;
      createdAt: string;
      deliveryDatetime?: string | null;
      deliveryType?: SlogDeliveryDetailsDeliveryTypeChoices | null;
      email?: string | null;
      id: string;
      notes?: string | null;
      telephoneNumber?: string | null;
      updatedAt: string;
      uuid: string;
      recipient?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      deliveryLocation?: {
        __typename?: "OfficeNode";
        name?: string | null;
        id: string;
        address?: string | null;
        officeAddress?: { __typename?: "Address"; raw?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type GetAllTransportationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  isTransportationExpired?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  isConfirmed?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllTransportationsQuery = {
  __typename?: "Query";
  allTransportations?: {
    __typename?: "TransportationNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "TransportationNodeEdge";
      node?: {
        __typename?: "TransportationNode";
        id: string;
        transportationId?: string | null;
        numberOfAssets?: number | null;
        numberOfMedia?: number | null;
        isConfirmed: boolean;
        status?: SlogTransportationStatusChoices | null;
        deliveryDatetime?: string | null;
        pickupDatetime?: string | null;
        shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllTransportationsAssetsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  createdByUserGroup?: InputMaybe<Scalars["String"]>;
  isTransportationAssetExpired?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  showTransportationInventory?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetAllTransportationsAssetsQuery = {
  __typename?: "Query";
  allTransportationAssets?: {
    __typename?: "TransportationAssetNodeConnection";
    totalCount?: number | null;
    edges: Array<{
      __typename?: "TransportationAssetNodeEdge";
      node?: {
        __typename?: "TransportationAssetNode";
        id: string;
        createdAt: string;
        updatedAt: string;
        uuid: string;
        parentType?: string | null;
        asset?: {
          __typename?: "AssetNode";
          id: string;
          mainImage?: string | null;
          category?: string | null;
          title: string;
          creationDate?: string | null;
          actualDateAndPeriod?: string | null;
          technique?: string | null;
          netWeight?: number | null;
          netWeightUnit?: string | null;
          genericDimensions?: string | null;
          inventoryNumber?: string | null;
          createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
          authorEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
        } | null;
        transportation?: {
          __typename?: "TransportationNode";
          id: string;
          deliveryDatetime?: string | null;
          pickupDatetime?: string | null;
          transportationId?: string | null;
        } | null;
        package?: {
          __typename?: "PackagesNode";
          id: string;
          createdAt: string;
          description?: string | null;
          dimensionsNotes?: string | null;
          grossWeight?: number | null;
          grossWeightUnitOfMeasure?: string | null;
          netWeight?: number | null;
          netWeightUnitOfMeasure?: string | null;
          hardPackagingDescription?: string | null;
          hardPackagingNotes?: string | null;
          hardPackagingType?: SlogPackagesHardPackagingTypeChoices | null;
          height?: number | null;
          width?: number | null;
          depth?: number | null;
          linearUnitOfMeasure?: string | null;
          number?: string | null;
          packaging?: SlogPackagesPackagingChoices | null;
          packagingNotes?: string | null;
          packagingType?: string | null;
          packingUnpackingInstructions?: string | null;
          softPackagingDescription?: string | null;
          updatedAt: string;
          uuid: string;
          volume?: number | null;
          volumeUnitOfMeasure?: string | null;
          packageVolumeInfo?: string | null;
          packageNetWeightInfo?: string | null;
          packageGrossWeightInfo?: string | null;
          packageDimensionsInfo?: string | null;
        } | null;
        createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateTransportationMutationVariables = Exact<{
  input: CreateTransportationInput;
}>;

export type CreateTransportationMutation = {
  __typename?: "Mutation";
  createTransportation?: {
    __typename?: "CreateTransportationPayload";
    transportation?: {
      __typename?: "TransportationNode";
      id: string;
      coverType?: SlogTransportationCoverTypeChoices | null;
      createdAt: string;
      vehicleSpecifics?: string | null;
      uuid: string;
      updatedAt: string;
      transportationType?: SlogTransportationTransportationTypeChoices | null;
      transportationRequiredPersonnel?: string | null;
      transportationId?: string | null;
      status?: SlogTransportationStatusChoices | null;
      riskType?: SlogTransportationRiskTypeChoices | null;
      packageWeightInfo?: string | null;
      packageWeightUnit?: string | null;
      packageWeight?: number | null;
      packageVolume?: number | null;
      packageVolumeInfo?: string | null;
      packageVolumeUnit?: string | null;
      packageDescription?: string | null;
      otherCharacteristics?: string | null;
      numberOfPackages?: number | null;
      numberOfMedia?: number | null;
      numberOfAssets?: number | null;
      notes?: string | null;
      isModifiable?: boolean | null;
      isConfirmed: boolean;
      insuredTo?: string | null;
      insuredFrom?: string | null;
      insuranceStatus?: SlogTransportationInsuranceStatusChoices | null;
      insuranceNotes?: string | null;
      insuranceDetails?: string | null;
      instructions?: string | null;
      controlledTemperature?: boolean | null;
      isInsured?: boolean | null;
      filingNumber?: string | null;
      evaluationType?: SlogTransportationEvaluationTypeChoices | null;
      description?: string | null;
      requiredTemperature?: number | null;
      requiredTemperatureUnit?: string | null;
      temperatureInfo?: string | null;
      createdBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
      updatedBy?: { __typename?: "UserType"; id: string; name: string; email: string } | null;
      customer?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      vector?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      shipper?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      pickupDetails?: {
        __typename?: "PickupDetailsNode";
        cellularNumber?: string | null;
        createdAt: string;
        email?: string | null;
        id: string;
        notes?: string | null;
        pickupDatetime?: string | null;
        pickupType?: SlogPickupDetailsPickupTypeChoices | null;
        telephoneNumber?: string | null;
        updatedAt: string;
        uuid: string;
        sender?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        pickupLocation?: {
          __typename?: "OfficeNode";
          name?: string | null;
          id: string;
          address?: string | null;
          officeAddress?: { __typename?: "Address"; raw?: string | null } | null;
        } | null;
      } | null;
      deliveryDetails?: {
        __typename?: "DeliveryDetailsNode";
        cellularNumber?: string | null;
        createdAt: string;
        deliveryDatetime?: string | null;
        deliveryType?: SlogDeliveryDetailsDeliveryTypeChoices | null;
        email?: string | null;
        id: string;
        notes?: string | null;
        telephoneNumber?: string | null;
        updatedAt: string;
        uuid: string;
        recipient?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
        deliveryLocation?: {
          __typename?: "OfficeNode";
          name?: string | null;
          id: string;
          address?: string | null;
          officeAddress?: { __typename?: "Address"; raw?: string | null } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateTransportationMutationVariables = Exact<{
  input: UpdateTransportationInput;
}>;

export type UpdateTransportationMutation = {
  __typename?: "Mutation";
  updateTransportation?: {
    __typename?: "UpdateTransportationPayload";
    transportation?: { __typename?: "TransportationNode"; id: string } | null;
  } | null;
};

export type DeleteTransportationMutationVariables = Exact<{
  input: DeleteTransportationInput;
}>;

export type DeleteTransportationMutation = {
  __typename?: "Mutation";
  deleteTransportation?: {
    __typename?: "DeleteTransportationPayload";
    transportation?: { __typename?: "TransportationNode"; id: string } | null;
  } | null;
};

export type DetachAssetsFromTransportationMutationVariables = Exact<{
  input: RemoveTransportationAssetsInput;
}>;

export type DetachAssetsFromTransportationMutation = {
  __typename?: "Mutation";
  removeTransportationAssets?: {
    __typename?: "RemoveTransportationAssetsPayload";
    transportation?: {
      __typename?: "TransportationNode";
      id: string;
      transportationassetsSet: {
        __typename?: "TransportationAssetNodeConnection";
        edges: Array<{
          __typename?: "TransportationAssetNodeEdge";
          node?: { __typename?: "TransportationAssetNode"; id: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AddTransportationMediaMutationVariables = Exact<{
  input: AddTransportationMediaInput;
}>;

export type AddTransportationMediaMutation = {
  __typename?: "Mutation";
  addTransportationMedia?: {
    __typename?: "AddTransportationMediaPayload";
    transportation?: {
      __typename?: "TransportationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteTransportationMediaMutationVariables = Exact<{
  input: DeleteTransportationMediaInput;
}>;

export type DeleteTransportationMediaMutation = {
  __typename?: "Mutation";
  deleteTransportationMedia?: {
    __typename?: "DeleteTransportationMediaPayload";
    transportation?: {
      __typename?: "TransportationNode";
      id: string;
      media: {
        __typename?: "MediaNodeConnection";
        edges: Array<{
          __typename?: "MediaNodeEdge";
          node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ExportTransportationLabelsMutationVariables = Exact<{
  input: ExportTransportationLabelsInput;
}>;

export type ExportTransportationLabelsMutation = {
  __typename?: "Mutation";
  exportTransportationLabels?: {
    __typename?: "ExportTransportationLabelsPayload";
    url?: string | null;
  } | null;
};

export type UpdateTransportationStatusMutationVariables = Exact<{
  input: UpdateTransportationStatusInput;
}>;

export type UpdateTransportationStatusMutation = {
  __typename?: "Mutation";
  updateTransportationStatus?: {
    __typename?: "UpdateTransportationStatusPayload";
    transportation?: {
      __typename?: "TransportationNode";
      id: string;
      status?: SlogTransportationStatusChoices | null;
    } | null;
  } | null;
};

export type DuplicateTransportationMutationVariables = Exact<{
  input: DuplicateTransportationInput;
}>;

export type DuplicateTransportationMutation = {
  __typename?: "Mutation";
  duplicateTransportation?: {
    __typename?: "DuplicateTransportationPayload";
    transportation?: { __typename?: "TransportationNode"; id: string } | null;
  } | null;
};

export type ExportTransportationBillsMutationVariables = Exact<{
  input: ExportTransportationBillsInput;
}>;

export type ExportTransportationBillsMutation = {
  __typename?: "Mutation";
  exportTransportationBills?: {
    __typename?: "ExportTransportationBillsPayload";
    url?: string | null;
  } | null;
};

export type SignTransportationBillMutationVariables = Exact<{
  input: SignTransportationBillInput;
}>;

export type SignTransportationBillMutation = {
  __typename?: "Mutation";
  signTransportationBill?: {
    __typename?: "SignTransportationBillPayload";
    transportationBill?: {
      __typename?: "ExportDocumentsNode";
      id: string;
      modelName?: string | null;
      isSigned?: boolean | null;
      file: string;
      externalId?: number | null;
      type: BaseExportedDocumentsTypeChoices;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null;
};

export type ExportTransportationPackingListMutationVariables = Exact<{
  input: ExportTransportationPackingListInput;
}>;

export type ExportTransportationPackingListMutation = {
  __typename?: "Mutation";
  exportTransportationPackingList?: {
    __typename?: "ExportTransportationPackingListPayload";
    url?: string | null;
  } | null;
};

export type ExportTransportationPdfMutationVariables = Exact<{
  input: ExportTransportationPdfInput;
}>;

export type ExportTransportationPdfMutation = {
  __typename?: "Mutation";
  exportTransportationPdf?: {
    __typename?: "ExportTransportationPDFPayload";
    url?: string | null;
  } | null;
};

export type GetLatestTransportationDocumentExportsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetLatestTransportationDocumentExportsQuery = {
  __typename?: "Query";
  latestTransportationDocumentsExports?: Array<{
    __typename?: "ExportDocumentsNode";
    createdAt: string;
    externalId?: number | null;
    file: string;
    isSigned?: boolean | null;
    modelName?: string | null;
    modelUpdatedAt?: string | null;
    type: BaseExportedDocumentsTypeChoices;
    updatedAt: string;
    uuid: string;
  } | null> | null;
};

export type ExportTransportationAssetLabelMutationVariables = Exact<{
  input: ExportTransportationAssetLabelInput;
}>;

export type ExportTransportationAssetLabelMutation = {
  __typename?: "Mutation";
  exportTransportationAssetLabel?: {
    __typename?: "ExportTransportationAssetLabelPayload";
    url?: string | null;
  } | null;
};

export type ExportTransportationAssetPackingListMutationVariables = Exact<{
  input: ExportTransportationAssetPackingListInput;
}>;

export type ExportTransportationAssetPackingListMutation = {
  __typename?: "Mutation";
  exportTransportationAssetPackingList?: {
    __typename?: "ExportTransportationAssetPackingListPayload";
    url?: string | null;
  } | null;
};

export type UserSignatureFieldsFragment = {
  __typename?: "UserNode";
  usersignatureSet: {
    __typename?: "UserSignatureNodeConnection";
    edges: Array<{
      __typename?: "UserSignatureNodeEdge";
      node?: {
        __typename?: "UserSignatureNode";
        createdAt: string;
        deleted?: string | null;
        deletedByCascade: boolean;
        file?: string | null;
        id: string;
        name?: string | null;
        updatedAt: string;
        uuid: string;
        isMain?: boolean | null;
      } | null;
    } | null>;
  };
};

export type GetAllUsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllUsersQuery = {
  __typename?: "Query";
  allUsers?: {
    __typename?: "UserNodeConnection";
    edges: Array<{
      __typename?: "UserNodeEdge";
      node?: {
        __typename?: "UserNode";
        id: string;
        name: string;
        email: string;
        phone?: string | null;
        userType?: UsersUserUserTypeChoices | null;
        isActive: boolean;
        validFrom?: string | null;
        expiresAt?: string | null;
        country?: { __typename?: "Country"; code?: string | null } | null;
        createdBy?: {
          __typename?: "UserType";
          id: string;
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "UserNode";
    id: string;
    lastLogin?: string | null;
    dateJoined: string;
    name: string;
    isActive: boolean;
    email: string;
    userType?: UsersUserUserTypeChoices | null;
    phone?: string | null;
    language?: string | null;
    timezone?: string | null;
    currency?: string | null;
    validFrom?: string | null;
    expiresAt?: string | null;
    isExpired?: boolean | null;
    timesRenewed?: number | null;
    country?: { __typename?: "Country"; code?: string | null } | null;
    userPermissions: {
      __typename?: "PermissionNodeConnection";
      edges: Array<{
        __typename?: "PermissionNodeEdge";
        node?: {
          __typename?: "PermissionNode";
          id: string;
          codename: string;
          name: string;
          contentType: { __typename?: "ContentTypeNode"; model: string; id: string };
        } | null;
      } | null>;
    };
    usersignatureSet: {
      __typename?: "UserSignatureNodeConnection";
      edges: Array<{
        __typename?: "UserSignatureNodeEdge";
        node?: {
          __typename?: "UserSignatureNode";
          createdAt: string;
          deleted?: string | null;
          deletedByCascade: boolean;
          file?: string | null;
          id: string;
          name?: string | null;
          updatedAt: string;
          uuid: string;
          isMain?: boolean | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type GetUserOperationsByIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserOperationsByIdQuery = {
  __typename?: "Query";
  allUsers?: {
    __typename?: "UserNodeConnection";
    edges: Array<{
      __typename?: "UserNodeEdge";
      node?: {
        __typename?: "UserNode";
        name: string;
        email: string;
        assetsCreatedCount?: number | null;
        collectionsCreatedCount?: number | null;
        exhibitionsCreatedCount?: number | null;
        appraisalsCreatedCount?: number | null;
        restorationsCreatedCount?: number | null;
        conditionstatusreportsCreatedCount?: number | null;
        policiesCreatedCountTe?: number | null;
        policiesCreatedCountPc?: number | null;
        usersCreatedCount?: number | null;
        usedInvites: number;
      } | null;
    } | null>;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword?: {
    __typename?: "ChangePasswordPayload";
    user?: { __typename?: "UserNode"; email: string } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "CreateUserPayload";
    user?: { __typename?: "UserNode"; id: string; email: string; name: string } | null;
  } | null;
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUser?: {
    __typename?: "InviteUserPayload";
    user?: { __typename?: "UserNode"; id: string; email: string; name: string } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "UpdateUserPayload";
    user?: {
      __typename?: "UserNode";
      id: string;
      password: string;
      lastLogin?: string | null;
      username: string;
      dateJoined: string;
      name: string;
      email: string;
      phone?: string | null;
      language?: string | null;
      timezone?: string | null;
      currency?: string | null;
      userType?: UsersUserUserTypeChoices | null;
      isActive: boolean;
      country?: { __typename?: "Country"; code?: string | null } | null;
    } | null;
  } | null;
};

export type RequestUserResetPasswordMutationVariables = Exact<{
  input: RequestUserResetPasswordInput;
}>;

export type RequestUserResetPasswordMutation = {
  __typename?: "Mutation";
  requestUserResetPassword?: {
    __typename?: "RequestUserResetPasswordPayload";
    user?: { __typename?: "UserNode"; resetPasswordToken?: string | null } | null;
  } | null;
};

export type ResetUserPasswordMutationVariables = Exact<{
  input: ResetUserPasswordInput;
}>;

export type ResetUserPasswordMutation = {
  __typename?: "Mutation";
  resetUserPassword?: {
    __typename?: "ResetUserPasswordPayload";
    user?: { __typename?: "UserNode"; email: string } | null;
  } | null;
};

export type GetAllPermissionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPermissionQuery = {
  __typename?: "Query";
  allPermissions?: {
    __typename?: "PermissionNodeConnection";
    edges: Array<{
      __typename?: "PermissionNodeEdge";
      node?: {
        __typename?: "PermissionNode";
        codename: string;
        id: string;
        name: string;
        contentType: { __typename?: "ContentTypeNode"; model: string };
      } | null;
    } | null>;
  } | null;
};

export type UpdateUserPermissionsMutationVariables = Exact<{
  input: UpdateUserPermissionInput;
}>;

export type UpdateUserPermissionsMutation = {
  __typename?: "Mutation";
  updateUserPermissions?: {
    __typename?: "UpdateUserPermissionPayload";
    user?: {
      __typename?: "UserNode";
      userPermissions: {
        __typename?: "PermissionNodeConnection";
        edges: Array<{
          __typename?: "PermissionNodeEdge";
          node?: { __typename?: "PermissionNode"; codename: string; name: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ToggleUserIsActiveMutationVariables = Exact<{
  input: ToggleUserIsActiveInput;
}>;

export type ToggleUserIsActiveMutation = {
  __typename?: "Mutation";
  toggleUserIsactive?: {
    __typename?: "ToggleUserIsActivePayload";
    user?: { __typename?: "UserNode"; isActive: boolean } | null;
  } | null;
};

export type ToggleUserUserTypeMutationVariables = Exact<{
  input: ToggleUserUserTypeInput;
}>;

export type ToggleUserUserTypeMutation = {
  __typename?: "Mutation";
  toggleUserUserType?: {
    __typename?: "ToggleUserUserTypePayload";
    user?: { __typename?: "UserNode"; userType?: UsersUserUserTypeChoices | null } | null;
  } | null;
};

export type RenewUserMutationVariables = Exact<{
  input: RenewUserInput;
}>;

export type RenewUserMutation = {
  __typename?: "Mutation";
  renewUser?: {
    __typename?: "RenewUserPayload";
    user?: { __typename?: "UserNode"; validFrom?: string | null; expiresAt?: string | null } | null;
  } | null;
};

export type AddUserSignatureMutationVariables = Exact<{
  input: AddUserSignatureInput;
}>;

export type AddUserSignatureMutation = {
  __typename?: "Mutation";
  addUserSignature?: {
    __typename?: "AddUserSignaturePayload";
    success?: boolean | null;
    userSignature?: { __typename?: "UserSignatureType"; file?: string | null } | null;
  } | null;
};

export type DeleteUserSignatureMutationVariables = Exact<{
  input: DeleteUserSignatureInput;
}>;

export type DeleteUserSignatureMutation = {
  __typename?: "Mutation";
  deleteUserSignature?: {
    __typename?: "DeleteUserSignaturePayload";
    userSignature?: { __typename?: "UserSignatureNode"; id: string } | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser?: {
    __typename?: "DeleteUserPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type SetMainSignatureMutationVariables = Exact<{
  input: SetMainSignatureInput;
}>;

export type SetMainSignatureMutation = {
  __typename?: "Mutation";
  setMainSignature?: {
    __typename?: "SetMainSignaturePayload";
    userSignature?: { __typename?: "UserSignatureType"; id: string } | null;
  } | null;
};

export type AddUserObjectPermissionMutationVariables = Exact<{
  input: AddUserObjectPermissionInput;
}>;

export type AddUserObjectPermissionMutation = {
  __typename?: "Mutation";
  addUserObjectPermission?: {
    __typename?: "AddUserObjectPermissionPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type AddUserObjectPermissionFromCollectionMutationVariables = Exact<{
  input: AddUserObjectPermissionFromCollectionInput;
}>;

export type AddUserObjectPermissionFromCollectionMutation = {
  __typename?: "Mutation";
  addUserObjectPermissionFromCollection?: {
    __typename?: "AddUserObjectPermissionFromCollectionPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type RemoveUserObjectPermissionMutationVariables = Exact<{
  input: RemoveUserObjectPermissionInput;
}>;

export type RemoveUserObjectPermissionMutation = {
  __typename?: "Mutation";
  removeUserObjectPermission?: {
    __typename?: "RemoveUserObjectPermissionPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export type SharePolicyByEmailMutationVariables = Exact<{
  input: SharePolicyByEmailInput;
}>;

export type SharePolicyByEmailMutation = {
  __typename?: "Mutation";
  sharePolicyByEmail?: {
    __typename?: "SharePolicyByEmailPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type SharePolicyByUsersMutationVariables = Exact<{
  input: SharePolicyByUsersInput;
}>;

export type SharePolicyByUsersMutation = {
  __typename?: "Mutation";
  sharePolicyByUsers?: {
    __typename?: "SharePolicyByUsersPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export const AddresFieldsFragmentDoc = gql`
  fragment AddresFields on AddressNode {
    id
    __typename
    formatted
    raw
    latitude
    longitude
    route
    streetNumber
    assetNumber
  }
`;
export const AppraisalFindingFieldsFragmentDoc = gql`
  fragment AppraisalFindingFields on AppraisalNode {
    id
    appraisalStatus
    appraisalEvaluator {
      id
      fullName
    }
    appraisalAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    appraisalDate
    isSigned
  }
`;
export const AppraisalAnagraphicFieldsFragmentDoc = gql`
  fragment AppraisalAnagraphicFields on AppraisalNode {
    id
    appraisalStatus
    appraisalEvaluator {
      id
      fullName
    }
    appraisalAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    appraisalConservationStatus
    appraisalMotivation
    appraisalLocation
    appraisalDate
    valueInformationAuthorCoefficient
    valueInformationInfo
    valueInformationQuotesSimilarAssets
    valueCarryingValue {
      amount
      currency {
        code
      }
    }
    valueEstimatedValue {
      amount
      currency {
        code
      }
    }
    valueInformationNote
    isSigned
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
        }
      }
    }
  }
`;
export const MediaFieldsFragmentDoc = gql`
  fragment MediaFields on MediaNode {
    id
    name
    file
    description
    mediaType
    isMainImage
    imageType
    notes
    audioVideoFileType
    creationDate
    fileAuthor
    fileSize
    photographicEquipment
    matrixType
    isGlassPlexyAcetate
    createdAt
    actualImageDimensions
  }
`;
export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on AssetNode {
    id
    deleted
    deletedByCascade
    createdAt
    updatedAt
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    availability
    uuid
    collectionName
    assetLocation {
      raw
      locality
      state
      country
      streetNumber
      route
    }
    assetPlacement
    netWeightUnit
    grossWeightUnit
    netLengthUnit
    grossLengthUnit
    netHeight
    grossHeight
    netWidth
    grossWidth
    netDepth
    grossDepth
    netDiameter
    grossDiameter
    netWeight
    grossWeight
    netDimensionsAcquisition
    grossDimensionsAcquisition
    dimensionsNotes
    audioVideoLengthUnit
    audioVideoLength
    fileSizeUnit
    fileSize
    resolutionUnit
    resolution
    optimalLightLevelIntensity
    lightUnit
    optimalTemperature
    temperatureUnit
    optimalHumidity
    enviromentalConditionsNotes
    isNotifiedCulturalProperty
    notifiedPropertyNote
    legalStatus
    copyrightAndPropertyRestrictions
    hasBarriers
    barriersDetails
    handlingRequirements
    injuryRisk
    injuryRiskDescription
    mouldingSupportBase
    hardwareToolsInstallation
    conservation
    lenderRequests
    settingNotes
    infoNotes
    isArtistProof
    artistProofNotes
    isFirstDraft
    firstDraftNotes
    isEdition
    editionNotes
    isMaster
    masterLocation
    isArtMultiple
    multipleNumber
    isSignedObject
    signedObjectNotes
    isTitledObject
    titledObjectNotes
    isDatedObject
    datedObjectNotes
    hasLabelsStamps
    labelsStampsNotes
    hasOtherAutographed
    otherAutographedNotes
    relevantAwards
    mechanism
    carat
    isDynamic
    dynamicNotes
    hasHumanAnimalPresence
    humanAnimalPresenceNotes
    isAudio
    isVideo
    hasLoop
    coloring
    coinMintIssuer
    coinPlacement
    coinPeriod
    coinPlacementNotes
    coinIssueReason
    coinCirculationPeriod
    coinComposition
    coinIdentificationCode
    classificationUsa
    classificationIta
    classificationEng
    classificationFra
    classificationSpa
    classificationGer
    classificationPhilatelic
    rarityDegree
    rarityDegreePhilatelic
    conservationState
    rarityDegreeNotes
    variants
    nominalValue
    fiscalValue
    currentValue
    currency
    score
    circulation
    manufactoringTechniques
    mintingLastDate
    mintingLastDateNotes
    reminting
    printingLastDate
    printingLastDateNotes
    reprinting
    stateOfConservation
    isComposed
    numberOfElements
    surfaceCharacteristics
    hasBase
    baseDetails
    hasWoodFrame
    woodFrameDetails
    hasFrame
    hasOriginalFrame
    frameMaterial
    isValuableFrame
    frameManufacturer
    frameDescription
    compositionDescription
    usage
    finishing
    hasContainer
    containerDetails
    replacedParts
    methodOfUse
    relatedPhysicalWork
    bookContainer
    dustJacket
    frontCover
    rearCover
    topEdge
    foreEdge
    footEdge
    bookbinding
    interiorDecorations
    externalDecorations
    flyleaf
    glueing
    headband
    headcap
    corner
    square
    slots
    spine
    clips
    raisedBands
    label
    clothCovering
    distribution
    edge
    orientation
    shape
    rim
    rotationAxes
    legend
    fineness
    coinTitle
    mintMadeErrors
    signings
    relief
    watermark
    holographicStripe
    securityThread
    printingErrors
    conservationMethod
    color
    postageMark
    rubber
    stampPrintingTechnique
    stampRealization
    stampIndentationType
    stampCenteringAndMargins
    stampFormat
    discoveryPlace
    discoverySite
    discoveryDate
    discoveryNotes
    discoveryAuthor
    catalogingAuthor
    objectSetupAuthor
    originNotes
    bibliographyNotes
    carryingValueOrigin
    carryingValueAcquisition
    carryingValueNotes
    carryingValueLastAcquisitionDate
    carryingValueAmountCurrency
    carryingValueAmount {
      amount
      currency {
        code
      }
    }
    carryingValueFileTitle
    carryingValueFile
    title
    technique
    genericDimensions
    actualDateAndPeriod
    creationDate
    creationDateNotes
    category
    subcategory
    authorEntity {
      id
      fullName
    }
    printer {
      id
      fullName
    }
    videomaker {
      id
      fullName
    }
    manufacturer {
      id
      fullName
    }
    producer {
      id
      fullName
    }
    owner {
      id
      fullName
    }
    otherModel
    banknotePrinter
    paperType
    coverType
    printingType
    productionMode
    placement
    sku
    series
    seriesDetails
    commonName
    scientificName
    material
    internationalCode
    taxonomyDomain
    taxonomyKingdom
    taxonomyPhylum
    taxonomyClass
    taxonomyOrder
    taxonomyFamily
    taxonomyTribe
    taxonomyGenus
    taxonomyNotes
    hasPeriod
    hasYear
    hasFullDatetime
    datingType
    creationPeriod
    isDateInterval
    dateStartDate
    dateEndDate
    isDateStartDateBc
    isDateEndDateBc
    isDateApprox
    isYearInterval
    yearStartDate
    yearEndDate
    isYearStartDateBc
    isYearEndDateBc
    isYearApprox
    isCenturyInterval
    centuryStartDate
    centuryEndDate
    isCenturyStartDateBc
    isCenturyEndDateBc
    isCenturyApprox
    centuryStartDateWindow
    centuryEndDateWindow
    nft
    nftNotes
    description
    inventoryNumber
    archiveNumber
    catalogNumber
    collection {
      edges {
        node {
          id
          name
        }
      }
    }
    exhibition {
      edges {
        node {
          id
          name
        }
      }
    }
    documents {
      edges {
        node {
          id
          title
          description
          documentType
          coaType
          issuer
          recipient
          isClassified
          releaseDate
          expirationDate
          notes
          archiveNumber
          fineArtsIssueType
          isTemporaryExport
          isTemporaryImport
          isCriticalCatalog
          editor
          curator
          subscriptionDate
          contractSubscriber
          publishingDate
          otherDocumentType
          attachments {
            edges {
              node {
                id
                name
                file
              }
            }
          }
          createdBy {
            name
          }
        }
      }
    }
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
          createdAt
          actualImageDimensions
        }
      }
    }
    criticalCatalogue
    technique
    validated
    physicalFeaturesElements {
      edges {
        node {
          id
          name
          materials
          description
          notes
        }
      }
    }
    presentValues {
      edges {
        node {
          id
          author {
            id
            fullName
          }
          estimateDate
          estimateReason
          notes
          file
          fileTitle
          appraisalId
          amount {
            amount
            currency {
              code
            }
          }
        }
      }
    }
    policyAsset {
      edges {
        node {
          id
        }
      }
    }
    mainImage
    __typename
  }
`;
export const AssetFieldsCreationReviewFragmentDoc = gql`
  fragment AssetFieldsCreationReview on AssetNode {
    id
    title
    authorEntity {
      id
      fullName
    }
    inventoryNumber
    technique
    genericDimensions
    actualDateAndPeriod
    category
    mainImage
    __typename
  }
`;
export const BillFieldsFragmentDoc = gql`
  fragment BillFields on ExportDocumentsNode {
    id
    modelName
    isSigned
    file
    externalId
    type
    createdAt
    updatedAt
  }
`;
export const MediaFragmentDoc = gql`
  fragment Media on MediaNode {
    id
    createdAt
    updatedAt
    createdBy {
      name
    }
    updatedBy {
      name
    }
    uuid
    deleted
    deletedByCascade
    name
    file
    description
    mediaType
    isMainImage
    imageType
    notes
    audioVideoFileType
    creationDate
    fileAuthor
    fileSize
    photographicEquipment
    matrixType
    isGlassPlexyAcetate
    fileWidth
    fileHeight
    fileDuration
    createdFrom
  }
`;
export const CollectionAnagraphicFieldsFragmentDoc = gql`
  fragment CollectionAnagraphicFields on CollectionNode {
    id
    name
    description
    notes
    image
    totalAssetsNumber
    validatedAssetsNumber
    createdFrom
    createdAt
    updatedAt
    uuid
    deletedByCascade
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    assetSet {
      edges {
        node {
          id
          title
          description
          author
          authorEntity {
            id
            fullName
          }
          category
          genericDimensions
          creationDate
          mainImage
          technique
          actualDateAndPeriod
        }
      }
    }
  }
`;
export const CollectionFindingFieldsFragmentDoc = gql`
  fragment CollectionFindingFields on CollectionNode {
    id
    name
    description
    notes
  }
`;
export const ConditionStatusReportFindingFieldsFragmentDoc = gql`
  fragment ConditionStatusReportFindingFields on ConditionStatusReportNode {
    id
    reportStatus
    reportDate
    reportType
    reportAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    isSigned
    reportMotivation
    reportMotivationDescription
    reportExaminer {
      id
      fullName
    }
  }
`;
export const ConditionStatusReportAnagraphicFieldsFragmentDoc = gql`
  fragment ConditionStatusReportAnagraphicFields on ConditionStatusReportNode {
    id
    reportStatus
    environmentalConditionsAbnormalHumidityEvent
    environmentalConditionsAbnormalLightEvent
    environmentalConditionsAbnormalTemperatureEvent
    environmentalConditionsLightUnit
    environmentalConditionsOptimalHumidity
    environmentalConditionsOptimalLightLevelIntensity
    environmentalConditionsOptimalTemperature
    environmentalConditionsTemperatureUnit
    isSigned
    exhibitionCurator {
      id
      fullName
    }
    exhibitionEventFrom
    exhibitionEventTo
    exhibitionHostEntity {
      id
      fullName
    }
    exhibitionLender {
      id
      fullName
    }
    exhibitionLoanFrom
    exhibitionLoanTo
    exhibitionLocation
    collectionPublicName
    exhibitionNotes
    exhibitionPlanner {
      id
      fullName
    }
    exhibitionPermanent
    exhibitionTitle
    logisticsAirConditioningTransportConditions
    logisticsCarrier {
      id
      fullName
    }
    logisticsDeliveryLocation {
      raw
      locality
      state
      country
      streetNumber
      route
    }
    logisticsDeliveryAddress
    logisticsDeliveryCity
    logisticsDeliveryCountry
    logisticsDeliveryDate
    logisticsDeliveryState
    logisticsDeliveryZip
    logisticsId
    logisticsNumberOfPackages
    logisticsPickupLocation {
      raw
      locality
      state
      country
      streetNumber
      route
    }
    logisticsPickupAddress
    logisticsPickupCity
    logisticsPickupCountry
    logisticsPickupDate
    logisticsPickupState
    logisticsPickupZip
    logisticsRecipient {
      id
      fullName
    }
    logisticsSender {
      id
      fullName
    }
    reportCompany {
      id
      fullName
    }
    reportDate
    reportDetailsAssetCondition
    reportDetailsAssetDamageDescription
    reportDetailsExamConditions
    reportDetailsExamConditionsNote
    reportDetailsExamLocation
    reportDetailsExhaminationTimeTechnicalDescription
    reportDetailsPackagingGeneralConditions
    reportDetailsExaminedPackages
    reportDetailsPackagingStatusDescription
    reportDetailsProtectionsSupportsCondition
    reportDetailsProtectionsSupportsDamageDescription
    reportExaminationType
    reportExaminer {
      id
      fullName
    }
    reportMotivation
    reportMotivationDescription
    reportType
    reportAsset {
      id
      title
      hasBarriers
      injuryRisk
      injuryRiskDescription
      conservation
      lenderRequests
      optimalLightLevelIntensity
      lightUnit
      optimalTemperature
      temperatureUnit
      optimalHumidity
      hardwareToolsInstallation
      settingNotes
      hasBarriers
      barriersDetails
      mouldingSupportBase
      handlingRequirements
      injuryRisk
      injuryRiskDescription
      conservation
      lenderRequests
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    settingsConservation
    settingsExaminerComments
    settingsHandlingRequirements
    settingsHasBarriers
    settingsInjuryRisk
    settingsLenderRequests
    settingsNotes
    valueEstimatedValue {
      amount
      currency {
        code
      }
    }
    valueEstimatedValueExaminationTime {
      amount
      currency {
        code
      }
    }
    valuePercentageDepreciation
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
          createdAt
        }
      }
    }
  }
`;
export const DepositationFieldsFragmentDoc = gql`
  fragment DepositationFields on DepositationNode {
    areaDescription
    areaNotes
    areaType
    areaTypeDescription
    coverType
    createdAt
    depositationId
    description
    evaluationType
    filingNumber
    id
    instructions
    insuranceNotes
    insuranceStatus
    insuredFrom
    insuredTo
    isConfirmed
    isModifiable
    notes
    numberOfAssets
    numberOfMedia
    numberOfPackages
    status
    type
    info
    updatedAt
    uuid
    methodAndAreaOfStorage
    transportationRequiredPersonnel
    startDate
    driver
    customer {
      id
      fullName
    }
    company {
      id
      fullName
    }
    courier {
      id
      fullName
    }
    location {
      id
      name
      address
    }
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
  }
`;
export const DepositationsAssetsFieldsFragmentDoc = gql`
  fragment DepositationsAssetsFields on DepositationAssetNode {
    container
    createdAt
    endDate
    packageWeightInfo
    packageDimensionsInfo
    packagingType
    exitAppointee
    height
    width
    depth
    linearUnitOfMeasure
    packageWeight
    weightUnitOfMeasure
    externalDepositationId
    id
    appointee {
      id
      fullName
    }
    position
    startDate
    updatedAt
    uuid
    exit {
      appointee {
        id
        fullName
      }
      endDate
      exitDriver
    }
    asset {
      id
      title
      mainImage
      category
      author
      inventoryNumber
      technique
      genericDimensions
      creationDate
      actualDateAndPeriod
      netWeight
      netWeightUnit
      createdBy {
        name
      }
    }
  }
`;
export const ExitFieldsFragmentDoc = gql`
  fragment ExitFields on ExitNode {
    uuid
    updatedAt
    transportationRequiredPersonnel
    notes
    id
    filingNumber
    endDate
    depositationNumber
    depositationData
    createdAt
    appointee {
      id
      fullName
    }
  }
`;
export const EventLogFindingFieldsFragmentDoc = gql`
  fragment EventLogFindingFields on EventLogNode {
    id
    eventType
    hideOwner
    commerciallyAvailable
    sold
    collectionPublicName
    newCollectionPublicName
    locationDescription
    startDate
    endDate
    owner {
      id
      fullName
    }
  }
`;
export const EventLogAnagraphicFieldsFragmentDoc = gql`
  fragment EventLogAnagraphicFields on EventLogNode {
    id
    eventType
    hideOwner
    commerciallyAvailable
    sold
    collectionPublicName
    newCollectionPublicName
    locationDescription
    startDate
    endDate
    note
    acquisitionType
    actionType
    commission
    auctionDescription
    lotNumber
    sellingLocation
    entityDocumentsNumber
    entityCreatedAt
    oldAddress {
      raw
    }
    newAddress {
      raw
    }
    value {
      amount
      currency {
        code
      }
    }
    minimumPrice {
      amount
      currency {
        code
      }
    }
    maximumPrice {
      amount
      currency {
        code
      }
    }
    reservePrice {
      amount
      currency {
        code
      }
    }
    auctionRights {
      amount
      currency {
        code
      }
    }
    externalId
    externalEntity
    owner {
      id
      fullName
    }
    origin {
      id
      fullName
    }
    host {
      id
      fullName
    }
    broker {
      id
      fullName
    }
    newOwner {
      id
      fullName
    }
    assetSet {
      edges {
        node {
          id
          title
          description
          author
          category
          genericDimensions
          creationDate
          mainImage
          actualDateAndPeriod
        }
      }
    }
    document
    translatedEventType
  }
`;
export const ExhibitionFindingFieldsFragmentDoc = gql`
  fragment ExhibitionFindingFields on ExhibitionNode {
    id
    name
    description
    eventFrom
    eventTo
    notes
    permanent
  }
`;
export const ExhibitionAnagraphicFieldsFragmentDoc = gql`
  fragment ExhibitionAnagraphicFields on ExhibitionNode {
    id
    name
    description
    eventFrom
    eventTo
    notes
    image
    permanent
    totalAssetsNumber
    createdAt
    updatedAt
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
        }
      }
    }
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    curator {
      id
      fullName
    }
    organizer {
      id
      fullName
    }
    hostEntity {
      id
      fullName
    }
    eventLocationEntity {
      id
      name
    }
    assetSet {
      edges {
        node {
          id
          title
          description
          author
          authorEntity {
            id
            fullName
          }
          category
          genericDimensions
          creationDate
          mainImage
          actualDateAndPeriod
        }
      }
    }
  }
`;
export const PolicyAssetFieldsFragmentDoc = gql`
  fragment policyAssetFields on PolicyAssetNode {
    catNat
    coverTerrorism
    coverType
    coveredRiskType
    evaluationType
    exemption
    id
    lender {
      id
      fullName
    }
    pickupAddress {
      raw
    }
    deliveryAddress {
      raw
    }
    lenderAddress {
      raw
      locality
      state
      country
      streetNumber
      route
    }
    shipper {
      id
      fullName
    }
    insuredValue {
      currency {
        code
      }
      amount
    }
  }
`;
export const PolicyCoreFieldsFragmentDoc = gql`
  fragment policyCoreFields on PolicyNode {
    id
    offerCode
    policyCode
    policyStatus
    contractingParty {
      fullName
    }
    insuranceCompany {
      fullName
    }
    broker {
      fullName
    }
    issuedAt
    expiresAt
    policyType
  }
`;
export const PolicyAssetOnPolicyFragmentDoc = gql`
  fragment policyAssetOnPolicy on PolicyNode {
    policyAssets {
      edges {
        node {
          id
        }
      }
    }
  }
`;
export const PolicyMoneyFieldsFragmentDoc = gql`
  fragment policyMoneyFields on PolicyNode {
    totalInsuredPremium {
      currency {
        code
      }
      amount
    }
    totalInsuredValue {
      currency {
        code
      }
      amount
    }
    evaluationType
  }
`;
export const PolicyDriverFieldsFragmentDoc = gql`
  fragment policyDriverFields on PolicyNode {
    catNat
    coverTerrorism
    coverType
    coveredRiskType
    exemption
    hasVariableBasket
  }
`;
export const RegistryInfoFieldsFragmentDoc = gql`
  fragment RegistryInfoFields on RegistryNode {
    createdAt
    createdBy {
      name
      email
    }
    updatedAt
    updatedBy {
      name
      email
    }
    isPublic
  }
`;
export const RegistryAnagraphicFieldsFragmentDoc = gql`
  fragment RegistryAnagraphicFields on RegistryNode {
    id
    isCompany
    fullName
    businessName
    alias
    role
    displayName
    webSite
    note
    countryCode
    taxId
    invoiceAddress {
      raw
      country
      countryCode
      locality
      route
      state
      streetNumber
      formatted
    }
    printName
    printCode
    phone
    secondaryPhone
    tertiaryPhone
    mobilePhone
    secondaryMobilePhone
    tertiaryMobilePhone
    email
    secondaryEmail
    tertiaryEmail
    isAuthor
    birthDate
    birthPlace
    deathDate
    deathPlace
    activityPeriod
    activityPlace
    artisticMovement
    foundation
    categories
    authorType
    logo
  }
`;
export const RegistryFindingFieldsFragmentDoc = gql`
  fragment RegistryFindingFields on RegistryNode {
    id
    categories
    fullName
    businessName
    email
    phone
    mobilePhone
    officesCount
    createdBy {
      name
      email
    }
  }
`;
export const OfficeAnagraphicFieldsFragmentDoc = gql`
  fragment OfficeAnagraphicFields on OfficeNode {
    id
    name
  }
`;
export const CompanyContactAnagraphicFieldsFragmentDoc = gql`
  fragment CompanyContactAnagraphicFields on CompanyContactNode {
    id
    main
    companyContact {
      id
      fullName
      alias
      role
      phone
      mobilePhone
      email
    }
    relatedCompany {
      id
      id
      fullName
      alias
      role
      phone
      mobilePhone
      email
    }
  }
`;
export const RestorationFindingFieldsFragmentDoc = gql`
  fragment RestorationFindingFields on RestorationNode {
    id
    restorationStatus
    restorationDirector {
      id
      fullName
    }
    restorationAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    createdBy {
      name
    }
    restorationDocumentDate
    restorationStartDate
    restorationEndDate
    isSigned
  }
`;
export const RestorationAnagraphicFieldsFragmentDoc = gql`
  fragment RestorationAnagraphicFields on RestorationNode {
    id
    restorationStatus
    restorationDirector {
      id
      fullName
    }
    restorationCompany {
      id
      fullName
      businessName
    }
    restorationAsset {
      id
      title
      authorEntity {
        id
        fullName
      }
      actualDateAndPeriod
    }
    restorationRestoredBy
    restorationCost {
      amount
      currency {
        code
      }
    }
    isSigned
    restorationInventoryNumber
    restorationStartDate
    restorationEndDate
    restorationLocation
    restorationCollocationFeatures
    restorationDocumentDate
    conservativeDataHistoryInterventions
    conservativeDataConservationStatus
    conservativeDataAssetAlterations
    conservativeDataDeteriorationCauses
    conservativeDataExaminationMethod
    conservativeDataInterventionProposal
    conservativeDataPreliminaryOperations
    conservativeDataIntervention
    conservativeDataInterventionMaterials
    conservativeDataTechnicalMethodologicalConsiderations
    conservativeDataMaintenancePreparationAssembly
    conservativeDataRestorationBibliography
    conservativeDataNotesRestorationSheet
    media {
      edges {
        node {
          id
          name
          file
          description
          mediaType
          isMainImage
          imageType
          notes
          audioVideoFileType
          creationDate
          fileAuthor
          fileSize
          photographicEquipment
          matrixType
          isGlassPlexyAcetate
        }
      }
    }
  }
`;
export const TransportationFieldsFragmentDoc = gql`
  fragment TransportationFields on TransportationNode {
    id
    coverType
    createdAt
    vehicleSpecifics
    uuid
    updatedAt
    transportationType
    transportationRequiredPersonnel
    transportationId
    status
    riskType
    packageWeightInfo
    packageWeightUnit
    packageWeight
    packageVolume
    packageVolumeInfo
    packageVolumeUnit
    packageDescription
    otherCharacteristics
    numberOfPackages
    numberOfMedia
    numberOfAssets
    notes
    isModifiable
    isConfirmed
    insuredTo
    insuredFrom
    insuranceStatus
    insuranceNotes
    insuranceDetails
    instructions
    controlledTemperature
    isInsured
    filingNumber
    evaluationType
    description
    numberOfAssets
    numberOfMedia
    requiredTemperature
    requiredTemperatureUnit
    temperatureInfo
    createdBy {
      id
      name
      email
    }
    updatedBy {
      id
      name
      email
    }
    createdAt
    updatedAt
    customer {
      id
      fullName
    }
    vector {
      id
      fullName
    }
    shipper {
      id
      fullName
    }
    pickupDetails {
      cellularNumber
      createdAt
      email
      id
      notes
      pickupDatetime
      pickupType
      telephoneNumber
      updatedAt
      uuid
      sender {
        id
        fullName
      }
      pickupLocation {
        name
        id
        address
        officeAddress {
          raw
        }
      }
    }
    deliveryDetails {
      cellularNumber
      createdAt
      deliveryDatetime
      deliveryType
      email
      id
      notes
      telephoneNumber
      updatedAt
      uuid
      recipient {
        id
        fullName
      }
      deliveryLocation {
        name
        id
        address
        officeAddress {
          raw
        }
      }
    }
  }
`;
export const PickupDetailsFieldsFragmentDoc = gql`
  fragment PickupDetailsFields on PickupDetailsNode {
    sender {
      id
      fullName
    }
    pickupLocation {
      id
      name
    }
    telephoneNumber
    cellularNumber
    email
    pickupDatetime
    pickupType
    notes
  }
`;
export const DeliveryDetailsFieldsFragmentDoc = gql`
  fragment DeliveryDetailsFields on DeliveryDetailsNode {
    recipient {
      id
      fullName
    }
    deliveryLocation {
      id
      name
    }
    telephoneNumber
    cellularNumber
    email
    deliveryDatetime
    deliveryType
    notes
  }
`;
export const PackageFieldsFragmentDoc = gql`
  fragment PackageFields on PackagesNode {
    id
    createdAt
    description
    dimensionsNotes
    grossWeight
    grossWeightUnitOfMeasure
    netWeight
    netWeightUnitOfMeasure
    hardPackagingDescription
    hardPackagingNotes
    hardPackagingType
    height
    width
    depth
    linearUnitOfMeasure
    netWeight
    netWeightUnitOfMeasure
    number
    packaging
    packagingNotes
    packagingType
    packingUnpackingInstructions
    softPackagingDescription
    updatedAt
    uuid
    volume
    volumeUnitOfMeasure
    packageVolumeInfo
    packageNetWeightInfo
    packageGrossWeightInfo
    packageDimensionsInfo
    packageNetWeightInfo
  }
`;
export const UserSignatureFieldsFragmentDoc = gql`
  fragment UserSignatureFields on UserNode {
    usersignatureSet {
      edges {
        node {
          createdAt
          deleted
          deletedByCascade
          file
          id
          name
          updatedAt
          uuid
          isMain
        }
      }
    }
  }
`;
export const GetAllAssetsLocationDocument = gql`
  query GetAllAssetsLocation {
    allAddressesAssetLocation(orderBy: "id") {
      edges {
        node {
          ...AddresFields
          locality {
            name
            postalCode
            state {
              name
              code
              country {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${AddresFieldsFragmentDoc}
`;

/**
 * __useGetAllAssetsLocationQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAssetsLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllAssetsLocationQuery,
    GetAllAssetsLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsLocationQuery, GetAllAssetsLocationQueryVariables>(
    GetAllAssetsLocationDocument,
    options
  );
}
export function useGetAllAssetsLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsLocationQuery,
    GetAllAssetsLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsLocationQuery, GetAllAssetsLocationQueryVariables>(
    GetAllAssetsLocationDocument,
    options
  );
}
export type GetAllAssetsLocationQueryHookResult = ReturnType<typeof useGetAllAssetsLocationQuery>;
export type GetAllAssetsLocationLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsLocationLazyQuery
>;
export type GetAllAssetsLocationQueryResult = Apollo.QueryResult<
  GetAllAssetsLocationQuery,
  GetAllAssetsLocationQueryVariables
>;
export const GetAddressByIdDocument = gql`
  query GetAddressById($id: ID!) {
    address(id: $id) {
      ...AddresFields
      locality {
        name
        postalCode
        state {
          name
          code
          country {
            id
            name
          }
        }
      }
      assetLocation {
        edges {
          node {
            id
            title
            authorEntity {
              id
              fullName
            }
            inventoryNumber
            technique
            genericDimensions
            actualDateAndPeriod
            category
            mainImage
            __typename
          }
        }
      }
    }
  }
  ${AddresFieldsFragmentDoc}
`;

/**
 * __useGetAddressByIdQuery__
 *
 * To run a query within a React component, call `useGetAddressByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAddressByIdQuery, GetAddressByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressByIdQuery, GetAddressByIdQueryVariables>(
    GetAddressByIdDocument,
    options
  );
}
export function useGetAddressByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAddressByIdQuery, GetAddressByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressByIdQuery, GetAddressByIdQueryVariables>(
    GetAddressByIdDocument,
    options
  );
}
export type GetAddressByIdQueryHookResult = ReturnType<typeof useGetAddressByIdQuery>;
export type GetAddressByIdLazyQueryHookResult = ReturnType<typeof useGetAddressByIdLazyQuery>;
export type GetAddressByIdQueryResult = Apollo.QueryResult<
  GetAddressByIdQuery,
  GetAddressByIdQueryVariables
>;
export const GetAllAppraisalsDocument = gql`
  query GetAllAppraisals {
    allAppraisals(orderBy: "-created_at") {
      edges {
        node {
          ...AppraisalFindingFields
          mediaNumber
          createdAt
          actualMediaStatus
          valueEstimatedValue {
            amount
            currency {
              code
            }
          }
          createdBy {
            name
          }
        }
      }
    }
  }
  ${AppraisalFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllAppraisalsQuery__
 *
 * To run a query within a React component, call `useGetAllAppraisalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAppraisalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAppraisalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAppraisalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>(
    GetAllAppraisalsDocument,
    options
  );
}
export function useGetAllAppraisalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAppraisalsQuery, GetAllAppraisalsQueryVariables>(
    GetAllAppraisalsDocument,
    options
  );
}
export type GetAllAppraisalsQueryHookResult = ReturnType<typeof useGetAllAppraisalsQuery>;
export type GetAllAppraisalsLazyQueryHookResult = ReturnType<typeof useGetAllAppraisalsLazyQuery>;
export type GetAllAppraisalsQueryResult = Apollo.QueryResult<
  GetAllAppraisalsQuery,
  GetAllAppraisalsQueryVariables
>;
export const GetAppraisalByIdDocument = gql`
  query GetAppraisalById($id: ID!) {
    appraisal(id: $id) {
      ...AppraisalAnagraphicFields
      mediaNumber
    }
  }
  ${AppraisalAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetAppraisalByIdQuery__
 *
 * To run a query within a React component, call `useGetAppraisalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppraisalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppraisalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppraisalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>(
    GetAppraisalByIdDocument,
    options
  );
}
export function useGetAppraisalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppraisalByIdQuery, GetAppraisalByIdQueryVariables>(
    GetAppraisalByIdDocument,
    options
  );
}
export type GetAppraisalByIdQueryHookResult = ReturnType<typeof useGetAppraisalByIdQuery>;
export type GetAppraisalByIdLazyQueryHookResult = ReturnType<typeof useGetAppraisalByIdLazyQuery>;
export type GetAppraisalByIdQueryResult = Apollo.QueryResult<
  GetAppraisalByIdQuery,
  GetAppraisalByIdQueryVariables
>;
export const CreateAppraisalDocument = gql`
  mutation CreateAppraisal($input: CreateAppraisalInput!) {
    createAppraisal(input: $input) {
      appraisal {
        id
        appraisalStatus
        appraisalEvaluator {
          id
          fullName
        }
        appraisalAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        appraisalConservationStatus
        appraisalMotivation
        appraisalLocation
        appraisalDate
        valueInformationAuthorCoefficient
        valueInformationInfo
        valueInformationQuotesSimilarAssets
        valueCarryingValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueInformationNote
      }
    }
  }
`;
export type CreateAppraisalMutationFn = Apollo.MutationFunction<
  CreateAppraisalMutation,
  CreateAppraisalMutationVariables
>;

/**
 * __useCreateAppraisalMutation__
 *
 * To run a mutation, you first call `useCreateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppraisalMutation, { data, loading, error }] = useCreateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppraisalMutation,
    CreateAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppraisalMutation, CreateAppraisalMutationVariables>(
    CreateAppraisalDocument,
    options
  );
}
export type CreateAppraisalMutationHookResult = ReturnType<typeof useCreateAppraisalMutation>;
export type CreateAppraisalMutationResult = Apollo.MutationResult<CreateAppraisalMutation>;
export type CreateAppraisalMutationOptions = Apollo.BaseMutationOptions<
  CreateAppraisalMutation,
  CreateAppraisalMutationVariables
>;
export const UpdateAppraisalDocument = gql`
  mutation UpdateAppraisal($input: UpdateAppraisalInput!) {
    updateAppraisal(input: $input) {
      appraisal {
        id
        appraisalStatus
        appraisalEvaluator {
          id
          fullName
        }
        appraisalAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        appraisalConservationStatus
        appraisalMotivation
        appraisalLocation
        appraisalDate
        valueInformationAuthorCoefficient
        valueInformationInfo
        valueInformationQuotesSimilarAssets
        valueCarryingValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueInformationNote
      }
    }
  }
`;
export type UpdateAppraisalMutationFn = Apollo.MutationFunction<
  UpdateAppraisalMutation,
  UpdateAppraisalMutationVariables
>;

/**
 * __useUpdateAppraisalMutation__
 *
 * To run a mutation, you first call `useUpdateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppraisalMutation, { data, loading, error }] = useUpdateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppraisalMutation,
    UpdateAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>(
    UpdateAppraisalDocument,
    options
  );
}
export type UpdateAppraisalMutationHookResult = ReturnType<typeof useUpdateAppraisalMutation>;
export type UpdateAppraisalMutationResult = Apollo.MutationResult<UpdateAppraisalMutation>;
export type UpdateAppraisalMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppraisalMutation,
  UpdateAppraisalMutationVariables
>;
export const DeleteAppraisalDocument = gql`
  mutation DeleteAppraisal($input: DeleteAppraisalInput!) {
    deleteAppraisal(input: $input) {
      deleteAppraisal {
        id
      }
    }
  }
`;
export type DeleteAppraisalMutationFn = Apollo.MutationFunction<
  DeleteAppraisalMutation,
  DeleteAppraisalMutationVariables
>;

/**
 * __useDeleteAppraisalMutation__
 *
 * To run a mutation, you first call `useDeleteAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppraisalMutation, { data, loading, error }] = useDeleteAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppraisalMutation,
    DeleteAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppraisalMutation, DeleteAppraisalMutationVariables>(
    DeleteAppraisalDocument,
    options
  );
}
export type DeleteAppraisalMutationHookResult = ReturnType<typeof useDeleteAppraisalMutation>;
export type DeleteAppraisalMutationResult = Apollo.MutationResult<DeleteAppraisalMutation>;
export type DeleteAppraisalMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppraisalMutation,
  DeleteAppraisalMutationVariables
>;
export const CloseAppraisalDocument = gql`
  mutation CloseAppraisal($input: CloseAppraisalInput!) {
    closeAppraisal(input: $input) {
      closeAppraisal {
        id
      }
    }
  }
`;
export type CloseAppraisalMutationFn = Apollo.MutationFunction<
  CloseAppraisalMutation,
  CloseAppraisalMutationVariables
>;

/**
 * __useCloseAppraisalMutation__
 *
 * To run a mutation, you first call `useCloseAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAppraisalMutation, { data, loading, error }] = useCloseAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<CloseAppraisalMutation, CloseAppraisalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseAppraisalMutation, CloseAppraisalMutationVariables>(
    CloseAppraisalDocument,
    options
  );
}
export type CloseAppraisalMutationHookResult = ReturnType<typeof useCloseAppraisalMutation>;
export type CloseAppraisalMutationResult = Apollo.MutationResult<CloseAppraisalMutation>;
export type CloseAppraisalMutationOptions = Apollo.BaseMutationOptions<
  CloseAppraisalMutation,
  CloseAppraisalMutationVariables
>;
export const DetachAssetFromAppraisalDocument = gql`
  mutation DetachAssetFromAppraisal($input: DetachAssetFromAppraisalInput!) {
    detachAssetFromAppraisal(input: $input) {
      appraisal {
        id
      }
    }
  }
`;
export type DetachAssetFromAppraisalMutationFn = Apollo.MutationFunction<
  DetachAssetFromAppraisalMutation,
  DetachAssetFromAppraisalMutationVariables
>;

/**
 * __useDetachAssetFromAppraisalMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromAppraisalMutation, { data, loading, error }] = useDetachAssetFromAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromAppraisalMutation,
    DetachAssetFromAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromAppraisalMutation,
    DetachAssetFromAppraisalMutationVariables
  >(DetachAssetFromAppraisalDocument, options);
}
export type DetachAssetFromAppraisalMutationHookResult = ReturnType<
  typeof useDetachAssetFromAppraisalMutation
>;
export type DetachAssetFromAppraisalMutationResult =
  Apollo.MutationResult<DetachAssetFromAppraisalMutation>;
export type DetachAssetFromAppraisalMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromAppraisalMutation,
  DetachAssetFromAppraisalMutationVariables
>;
export const GetMediaAppraisalByIdDocument = gql`
  query GetMediaAppraisalById($id: ID!) {
    appraisal(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaAppraisalByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaAppraisalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaAppraisalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaAppraisalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaAppraisalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaAppraisalByIdQuery,
    GetMediaAppraisalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaAppraisalByIdQuery, GetMediaAppraisalByIdQueryVariables>(
    GetMediaAppraisalByIdDocument,
    options
  );
}
export function useGetMediaAppraisalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaAppraisalByIdQuery,
    GetMediaAppraisalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaAppraisalByIdQuery, GetMediaAppraisalByIdQueryVariables>(
    GetMediaAppraisalByIdDocument,
    options
  );
}
export type GetMediaAppraisalByIdQueryHookResult = ReturnType<typeof useGetMediaAppraisalByIdQuery>;
export type GetMediaAppraisalByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaAppraisalByIdLazyQuery
>;
export type GetMediaAppraisalByIdQueryResult = Apollo.QueryResult<
  GetMediaAppraisalByIdQuery,
  GetMediaAppraisalByIdQueryVariables
>;
export const AddAppraisalMediaDocument = gql`
  mutation AddAppraisalMedia($input: AddAppraisalMediaInput!) {
    addAppraisalMedia(input: $input) {
      appraisal {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddAppraisalMediaMutationFn = Apollo.MutationFunction<
  AddAppraisalMediaMutation,
  AddAppraisalMediaMutationVariables
>;

/**
 * __useAddAppraisalMediaMutation__
 *
 * To run a mutation, you first call `useAddAppraisalMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAppraisalMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAppraisalMediaMutation, { data, loading, error }] = useAddAppraisalMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAppraisalMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAppraisalMediaMutation,
    AddAppraisalMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAppraisalMediaMutation, AddAppraisalMediaMutationVariables>(
    AddAppraisalMediaDocument,
    options
  );
}
export type AddAppraisalMediaMutationHookResult = ReturnType<typeof useAddAppraisalMediaMutation>;
export type AddAppraisalMediaMutationResult = Apollo.MutationResult<AddAppraisalMediaMutation>;
export type AddAppraisalMediaMutationOptions = Apollo.BaseMutationOptions<
  AddAppraisalMediaMutation,
  AddAppraisalMediaMutationVariables
>;
export const DeleteAppraisalMediaDocument = gql`
  mutation DeleteAppraisalMedia($input: DeleteAppraisalMediaInput!) {
    deleteAppraisalMedia(input: $input) {
      appraisal {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteAppraisalMediaMutationFn = Apollo.MutationFunction<
  DeleteAppraisalMediaMutation,
  DeleteAppraisalMediaMutationVariables
>;

/**
 * __useDeleteAppraisalMediaMutation__
 *
 * To run a mutation, you first call `useDeleteAppraisalMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppraisalMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppraisalMediaMutation, { data, loading, error }] = useDeleteAppraisalMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppraisalMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppraisalMediaMutation,
    DeleteAppraisalMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppraisalMediaMutation, DeleteAppraisalMediaMutationVariables>(
    DeleteAppraisalMediaDocument,
    options
  );
}
export type DeleteAppraisalMediaMutationHookResult = ReturnType<
  typeof useDeleteAppraisalMediaMutation
>;
export type DeleteAppraisalMediaMutationResult =
  Apollo.MutationResult<DeleteAppraisalMediaMutation>;
export type DeleteAppraisalMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppraisalMediaMutation,
  DeleteAppraisalMediaMutationVariables
>;
export const ExportAppraisalPdfDocument = gql`
  mutation ExportAppraisalPDF($input: ExportAppraisalPDFInput!) {
    exportAppraisalPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportAppraisalPdfMutationFn = Apollo.MutationFunction<
  ExportAppraisalPdfMutation,
  ExportAppraisalPdfMutationVariables
>;

/**
 * __useExportAppraisalPdfMutation__
 *
 * To run a mutation, you first call `useExportAppraisalPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAppraisalPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAppraisalPdfMutation, { data, loading, error }] = useExportAppraisalPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAppraisalPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportAppraisalPdfMutation,
    ExportAppraisalPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportAppraisalPdfMutation, ExportAppraisalPdfMutationVariables>(
    ExportAppraisalPdfDocument,
    options
  );
}
export type ExportAppraisalPdfMutationHookResult = ReturnType<typeof useExportAppraisalPdfMutation>;
export type ExportAppraisalPdfMutationResult = Apollo.MutationResult<ExportAppraisalPdfMutation>;
export type ExportAppraisalPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportAppraisalPdfMutation,
  ExportAppraisalPdfMutationVariables
>;
export const DuplicateAppraisalDocument = gql`
  mutation DuplicateAppraisal($input: DuplicateAppraisalInput!) {
    duplicateAppraisal(input: $input) {
      appraisal {
        id
      }
    }
  }
`;
export type DuplicateAppraisalMutationFn = Apollo.MutationFunction<
  DuplicateAppraisalMutation,
  DuplicateAppraisalMutationVariables
>;

/**
 * __useDuplicateAppraisalMutation__
 *
 * To run a mutation, you first call `useDuplicateAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateAppraisalMutation, { data, loading, error }] = useDuplicateAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateAppraisalMutation,
    DuplicateAppraisalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateAppraisalMutation, DuplicateAppraisalMutationVariables>(
    DuplicateAppraisalDocument,
    options
  );
}
export type DuplicateAppraisalMutationHookResult = ReturnType<typeof useDuplicateAppraisalMutation>;
export type DuplicateAppraisalMutationResult = Apollo.MutationResult<DuplicateAppraisalMutation>;
export type DuplicateAppraisalMutationOptions = Apollo.BaseMutationOptions<
  DuplicateAppraisalMutation,
  DuplicateAppraisalMutationVariables
>;
export const SignAppraisalDocument = gql`
  mutation SignAppraisal($input: SignAppraisalInput!) {
    signAppraisal(input: $input) {
      appraisal {
        id
      }
    }
  }
`;
export type SignAppraisalMutationFn = Apollo.MutationFunction<
  SignAppraisalMutation,
  SignAppraisalMutationVariables
>;

/**
 * __useSignAppraisalMutation__
 *
 * To run a mutation, you first call `useSignAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signAppraisalMutation, { data, loading, error }] = useSignAppraisalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignAppraisalMutation(
  baseOptions?: Apollo.MutationHookOptions<SignAppraisalMutation, SignAppraisalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignAppraisalMutation, SignAppraisalMutationVariables>(
    SignAppraisalDocument,
    options
  );
}
export type SignAppraisalMutationHookResult = ReturnType<typeof useSignAppraisalMutation>;
export type SignAppraisalMutationResult = Apollo.MutationResult<SignAppraisalMutation>;
export type SignAppraisalMutationOptions = Apollo.BaseMutationOptions<
  SignAppraisalMutation,
  SignAppraisalMutationVariables
>;
export const GetAllAssetsByTitleDocument = gql`
  query GetAllAssetsByTitle($title_Contains: String) {
    allAssets(title_Contains: $title_Contains, orderBy: "-created_at") {
      edges {
        node {
          id
          title
          author
          creationDate
          actualDateAndPeriod
          genericDimensions
          createdBy {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsByTitleQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsByTitleQuery({
 *   variables: {
 *      title_Contains: // value for 'title_Contains'
 *   },
 * });
 */
export function useGetAllAssetsByTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>(
    GetAllAssetsByTitleDocument,
    options
  );
}
export function useGetAllAssetsByTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsByTitleQuery,
    GetAllAssetsByTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>(
    GetAllAssetsByTitleDocument,
    options
  );
}
export type GetAllAssetsByTitleQueryHookResult = ReturnType<typeof useGetAllAssetsByTitleQuery>;
export type GetAllAssetsByTitleLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsByTitleLazyQuery
>;
export type GetAllAssetsByTitleQueryResult = Apollo.QueryResult<
  GetAllAssetsByTitleQuery,
  GetAllAssetsByTitleQueryVariables
>;
export const GetAssetByIdDocument = gql`
  query GetAssetById($id: ID!) {
    asset(id: $id) {
      ...AssetFields
      assetPredictions {
        id
        jobUuid
        aiReplacmentValue
        aiLiquidityValue
        aiIntrinsicValue
        type
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`;

/**
 * __useGetAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetByIdQuery, GetAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export function useGetAssetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssetByIdQuery, GetAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export type GetAssetByIdQueryHookResult = ReturnType<typeof useGetAssetByIdQuery>;
export type GetAssetByIdLazyQueryHookResult = ReturnType<typeof useGetAssetByIdLazyQuery>;
export type GetAssetByIdQueryResult = Apollo.QueryResult<
  GetAssetByIdQuery,
  GetAssetByIdQueryVariables
>;
export const AssetPredictionDocument = gql`
  query AssetPrediction($id: ID!, $jobUuid: String!) {
    assetPrediction(id: $id, jobUuid: $jobUuid) {
      id
      jobUuid
      aiReplacmentValue
      aiLiquidityValue
      aiIntrinsicValue
      type
    }
  }
`;

/**
 * __useAssetPredictionQuery__
 *
 * To run a query within a React component, call `useAssetPredictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetPredictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetPredictionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      jobUuid: // value for 'jobUuid'
 *   },
 * });
 */
export function useAssetPredictionQuery(
  baseOptions: Apollo.QueryHookOptions<AssetPredictionQuery, AssetPredictionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetPredictionQuery, AssetPredictionQueryVariables>(
    AssetPredictionDocument,
    options
  );
}
export function useAssetPredictionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssetPredictionQuery, AssetPredictionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetPredictionQuery, AssetPredictionQueryVariables>(
    AssetPredictionDocument,
    options
  );
}
export type AssetPredictionQueryHookResult = ReturnType<typeof useAssetPredictionQuery>;
export type AssetPredictionLazyQueryHookResult = ReturnType<typeof useAssetPredictionLazyQuery>;
export type AssetPredictionQueryResult = Apollo.QueryResult<
  AssetPredictionQuery,
  AssetPredictionQueryVariables
>;
export const GetAssetByIdForReviewDocument = gql`
  query GetAssetByIdForReview($id: ID!) {
    asset(id: $id) {
      ...AssetFieldsCreationReview
    }
  }
  ${AssetFieldsCreationReviewFragmentDoc}
`;

/**
 * __useGetAssetByIdForReviewQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdForReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetByIdForReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetByIdForReviewQuery,
    GetAssetByIdForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetByIdForReviewQuery, GetAssetByIdForReviewQueryVariables>(
    GetAssetByIdForReviewDocument,
    options
  );
}
export function useGetAssetByIdForReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetByIdForReviewQuery,
    GetAssetByIdForReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetByIdForReviewQuery, GetAssetByIdForReviewQueryVariables>(
    GetAssetByIdForReviewDocument,
    options
  );
}
export type GetAssetByIdForReviewQueryHookResult = ReturnType<typeof useGetAssetByIdForReviewQuery>;
export type GetAssetByIdForReviewLazyQueryHookResult = ReturnType<
  typeof useGetAssetByIdForReviewLazyQuery
>;
export type GetAssetByIdForReviewQueryResult = Apollo.QueryResult<
  GetAssetByIdForReviewQuery,
  GetAssetByIdForReviewQueryVariables
>;
export const GetMediaAssetByIdDocument = gql`
  query GetMediaAssetById($id: ID!) {
    asset(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaAssetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>(
    GetMediaAssetByIdDocument,
    options
  );
}
export function useGetMediaAssetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaAssetByIdQuery, GetMediaAssetByIdQueryVariables>(
    GetMediaAssetByIdDocument,
    options
  );
}
export type GetMediaAssetByIdQueryHookResult = ReturnType<typeof useGetMediaAssetByIdQuery>;
export type GetMediaAssetByIdLazyQueryHookResult = ReturnType<typeof useGetMediaAssetByIdLazyQuery>;
export type GetMediaAssetByIdQueryResult = Apollo.QueryResult<
  GetMediaAssetByIdQuery,
  GetMediaAssetByIdQueryVariables
>;
export const GetAllAssetsDocument = gql`
  query GetAllAssets(
    $first: Int
    $after: String
    $offset: Int
    $validated: Boolean
    $createdByUserGroup: String
    $orderBy: String
    $search: String
    $category_In: [String]
    $subcategory_In: [String]
    $location: String
  ) {
    allAssets(
      first: $first
      after: $after
      offset: $offset
      validated: $validated
      createdByUserGroup: $createdByUserGroup
      orderBy: $orderBy
      search: $search
      category_In: $category_In
      subcategory_In: $subcategory_In
      location: $location
    ) {
      edges {
        node {
          id
          availability
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          genericDimensions
          sku
          nft
          netLengthUnit
          mainImage
          deleted
          validated
          technique
          actualDateAndPeriod
          createdBy {
            name
            userType
          }
          authorEntity {
            id
            fullName
          }
          assetPredictions {
            id
            jobUuid
            aiReplacmentValue
            aiLiquidityValue
            aiIntrinsicValue
            type
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      offset: // value for 'offset'
 *      validated: // value for 'validated'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      category_In: // value for 'category_In'
 *      subcategory_In: // value for 'subcategory_In'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetAllAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  );
}
export function useGetAllAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  );
}
export type GetAllAssetsQueryHookResult = ReturnType<typeof useGetAllAssetsQuery>;
export type GetAllAssetsLazyQueryHookResult = ReturnType<typeof useGetAllAssetsLazyQuery>;
export type GetAllAssetsQueryResult = Apollo.QueryResult<
  GetAllAssetsQuery,
  GetAllAssetsQueryVariables
>;
export const GetAllAssetsForUserDocument = gql`
  query GetAllAssetsForUser($userId: ID!) {
    allAssetsForUser(orderBy: "-created_at", userId: $userId) {
      edges {
        node {
          id
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          genericDimensions
          sku
          nft
          netLengthUnit
          mainImage
          deleted
          validated
          technique
          actualDateAndPeriod
          createdBy {
            name
          }
          authorEntity {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllAssetsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllAssetsForUserQuery, GetAllAssetsForUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsForUserQuery, GetAllAssetsForUserQueryVariables>(
    GetAllAssetsForUserDocument,
    options
  );
}
export function useGetAllAssetsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsForUserQuery,
    GetAllAssetsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsForUserQuery, GetAllAssetsForUserQueryVariables>(
    GetAllAssetsForUserDocument,
    options
  );
}
export type GetAllAssetsForUserQueryHookResult = ReturnType<typeof useGetAllAssetsForUserQuery>;
export type GetAllAssetsForUserLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsForUserLazyQuery
>;
export type GetAllAssetsForUserQueryResult = Apollo.QueryResult<
  GetAllAssetsForUserQuery,
  GetAllAssetsForUserQueryVariables
>;
export const GetAllAssetExceptUserDocument = gql`
  query GetAllAssetExceptUser($userId: ID!) {
    notUserAssets(orderBy: "-created_at", userId: $userId) {
      edges {
        node {
          id
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          genericDimensions
          sku
          nft
          netLengthUnit
          mainImage
          deleted
          validated
          technique
          actualDateAndPeriod
          createdBy {
            name
          }
          authorEntity {
            id
            fullName
          }
          collection {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetExceptUserQuery__
 *
 * To run a query within a React component, call `useGetAllAssetExceptUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetExceptUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetExceptUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAllAssetExceptUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllAssetExceptUserQuery,
    GetAllAssetExceptUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetExceptUserQuery, GetAllAssetExceptUserQueryVariables>(
    GetAllAssetExceptUserDocument,
    options
  );
}
export function useGetAllAssetExceptUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetExceptUserQuery,
    GetAllAssetExceptUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetExceptUserQuery, GetAllAssetExceptUserQueryVariables>(
    GetAllAssetExceptUserDocument,
    options
  );
}
export type GetAllAssetExceptUserQueryHookResult = ReturnType<typeof useGetAllAssetExceptUserQuery>;
export type GetAllAssetExceptUserLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetExceptUserLazyQuery
>;
export type GetAllAssetExceptUserQueryResult = Apollo.QueryResult<
  GetAllAssetExceptUserQuery,
  GetAllAssetExceptUserQueryVariables
>;
export const CreateAssetDocument = gql`
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
        carryingValueFile
        actualDateAndPeriod
        category
        genericDimensions
        inventoryNumber
        technique
      }
    }
  }
`;
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options
  );
}
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
        carryingValueFile
      }
    }
  }
`;
export type UpdateAssetMutationFn = Apollo.MutationFunction<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(
    UpdateAssetDocument,
    options
  );
}
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;
export const ChangeAssetCategoryDocument = gql`
  mutation ChangeAssetCategory($input: ChangeAssetCategoryInput!) {
    changeAssetCategory(input: $input) {
      asset {
        id
        category
        subcategory
      }
    }
  }
`;
export type ChangeAssetCategoryMutationFn = Apollo.MutationFunction<
  ChangeAssetCategoryMutation,
  ChangeAssetCategoryMutationVariables
>;

/**
 * __useChangeAssetCategoryMutation__
 *
 * To run a mutation, you first call `useChangeAssetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAssetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAssetCategoryMutation, { data, loading, error }] = useChangeAssetCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeAssetCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeAssetCategoryMutation,
    ChangeAssetCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeAssetCategoryMutation, ChangeAssetCategoryMutationVariables>(
    ChangeAssetCategoryDocument,
    options
  );
}
export type ChangeAssetCategoryMutationHookResult = ReturnType<
  typeof useChangeAssetCategoryMutation
>;
export type ChangeAssetCategoryMutationResult = Apollo.MutationResult<ChangeAssetCategoryMutation>;
export type ChangeAssetCategoryMutationOptions = Apollo.BaseMutationOptions<
  ChangeAssetCategoryMutation,
  ChangeAssetCategoryMutationVariables
>;
export const DeleteAssetDocument = gql`
  mutation DeleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      asset {
        id
      }
    }
  }
`;
export type DeleteAssetMutationFn = Apollo.MutationFunction<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(
    DeleteAssetDocument,
    options
  );
}
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>;
export type DeleteAssetMutationResult = Apollo.MutationResult<DeleteAssetMutation>;
export type DeleteAssetMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>;
export const GetAssetElementsByAssetIdDocument = gql`
  query GetAssetElementsByAssetId($assetId: ID!) {
    allAssetElements(asset: $assetId, orderBy: "-created_at") {
      edges {
        node {
          id
          name
          description
          notes
          materials
        }
      }
    }
  }
`;

/**
 * __useGetAssetElementsByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetElementsByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetElementsByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetElementsByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetElementsByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetElementsByAssetIdQuery, GetAssetElementsByAssetIdQueryVariables>(
    GetAssetElementsByAssetIdDocument,
    options
  );
}
export function useGetAssetElementsByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >(GetAssetElementsByAssetIdDocument, options);
}
export type GetAssetElementsByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetElementsByAssetIdQuery
>;
export type GetAssetElementsByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetElementsByAssetIdLazyQuery
>;
export type GetAssetElementsByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetElementsByAssetIdQuery,
  GetAssetElementsByAssetIdQueryVariables
>;
export const GetAssetPresentValuesByAssetIdDocument = gql`
  query GetAssetPresentValuesByAssetId($assetId: ID!) {
    allAssetPresentValues(asset: $assetId, orderBy: "-created_at") {
      edges {
        node {
          id
          amount {
            amount
            currency {
              code
            }
          }
          author {
            id
            fullName
          }
          estimateDate
          estimateReason
          notes
          fileTitle
          file
        }
      }
    }
  }
`;

/**
 * __useGetAssetPresentValuesByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetPresentValuesByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPresentValuesByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetPresentValuesByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetPresentValuesByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >(GetAssetPresentValuesByAssetIdDocument, options);
}
export function useGetAssetPresentValuesByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >(GetAssetPresentValuesByAssetIdDocument, options);
}
export type GetAssetPresentValuesByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetPresentValuesByAssetIdQuery
>;
export type GetAssetPresentValuesByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetPresentValuesByAssetIdLazyQuery
>;
export type GetAssetPresentValuesByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetPresentValuesByAssetIdQuery,
  GetAssetPresentValuesByAssetIdQueryVariables
>;
export const CreateAssetElementDocument = gql`
  mutation CreateAssetElement($input: CreateAssetElementInput!) {
    createAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type CreateAssetElementMutationFn = Apollo.MutationFunction<
  CreateAssetElementMutation,
  CreateAssetElementMutationVariables
>;

/**
 * __useCreateAssetElementMutation__
 *
 * To run a mutation, you first call `useCreateAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetElementMutation, { data, loading, error }] = useCreateAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetElementMutation,
    CreateAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetElementMutation, CreateAssetElementMutationVariables>(
    CreateAssetElementDocument,
    options
  );
}
export type CreateAssetElementMutationHookResult = ReturnType<typeof useCreateAssetElementMutation>;
export type CreateAssetElementMutationResult = Apollo.MutationResult<CreateAssetElementMutation>;
export type CreateAssetElementMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetElementMutation,
  CreateAssetElementMutationVariables
>;
export const CreateAssetPresentValueDocument = gql`
  mutation CreateAssetPresentValue($input: CreateAssetPresentValueInput!) {
    createAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type CreateAssetPresentValueMutationFn = Apollo.MutationFunction<
  CreateAssetPresentValueMutation,
  CreateAssetPresentValueMutationVariables
>;

/**
 * __useCreateAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useCreateAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetPresentValueMutation, { data, loading, error }] = useCreateAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetPresentValueMutation,
    CreateAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssetPresentValueMutation,
    CreateAssetPresentValueMutationVariables
  >(CreateAssetPresentValueDocument, options);
}
export type CreateAssetPresentValueMutationHookResult = ReturnType<
  typeof useCreateAssetPresentValueMutation
>;
export type CreateAssetPresentValueMutationResult =
  Apollo.MutationResult<CreateAssetPresentValueMutation>;
export type CreateAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetPresentValueMutation,
  CreateAssetPresentValueMutationVariables
>;
export const UpdateAssetElementDocument = gql`
  mutation UpdateAssetElement($input: UpdateAssetElementInput!) {
    updateAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type UpdateAssetElementMutationFn = Apollo.MutationFunction<
  UpdateAssetElementMutation,
  UpdateAssetElementMutationVariables
>;

/**
 * __useUpdateAssetElementMutation__
 *
 * To run a mutation, you first call `useUpdateAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetElementMutation, { data, loading, error }] = useUpdateAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetElementMutation,
    UpdateAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetElementMutation, UpdateAssetElementMutationVariables>(
    UpdateAssetElementDocument,
    options
  );
}
export type UpdateAssetElementMutationHookResult = ReturnType<typeof useUpdateAssetElementMutation>;
export type UpdateAssetElementMutationResult = Apollo.MutationResult<UpdateAssetElementMutation>;
export type UpdateAssetElementMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetElementMutation,
  UpdateAssetElementMutationVariables
>;
export const UpdateAssetPresentValueDocument = gql`
  mutation UpdateAssetPresentValue($input: UpdateAssetPresentValueInput!) {
    updateAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type UpdateAssetPresentValueMutationFn = Apollo.MutationFunction<
  UpdateAssetPresentValueMutation,
  UpdateAssetPresentValueMutationVariables
>;

/**
 * __useUpdateAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useUpdateAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetPresentValueMutation, { data, loading, error }] = useUpdateAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetPresentValueMutation,
    UpdateAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssetPresentValueMutation,
    UpdateAssetPresentValueMutationVariables
  >(UpdateAssetPresentValueDocument, options);
}
export type UpdateAssetPresentValueMutationHookResult = ReturnType<
  typeof useUpdateAssetPresentValueMutation
>;
export type UpdateAssetPresentValueMutationResult =
  Apollo.MutationResult<UpdateAssetPresentValueMutation>;
export type UpdateAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetPresentValueMutation,
  UpdateAssetPresentValueMutationVariables
>;
export const DeleteAssetElementDocument = gql`
  mutation DeleteAssetElement($input: DeleteAssetElementInput!) {
    deleteAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type DeleteAssetElementMutationFn = Apollo.MutationFunction<
  DeleteAssetElementMutation,
  DeleteAssetElementMutationVariables
>;

/**
 * __useDeleteAssetElementMutation__
 *
 * To run a mutation, you first call `useDeleteAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetElementMutation, { data, loading, error }] = useDeleteAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetElementMutation,
    DeleteAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetElementMutation, DeleteAssetElementMutationVariables>(
    DeleteAssetElementDocument,
    options
  );
}
export type DeleteAssetElementMutationHookResult = ReturnType<typeof useDeleteAssetElementMutation>;
export type DeleteAssetElementMutationResult = Apollo.MutationResult<DeleteAssetElementMutation>;
export type DeleteAssetElementMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetElementMutation,
  DeleteAssetElementMutationVariables
>;
export const DeleteAssetPresentValueDocument = gql`
  mutation DeleteAssetPresentValue($input: DeleteAssetPresentValueInput!) {
    deleteAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type DeleteAssetPresentValueMutationFn = Apollo.MutationFunction<
  DeleteAssetPresentValueMutation,
  DeleteAssetPresentValueMutationVariables
>;

/**
 * __useDeleteAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useDeleteAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetPresentValueMutation, { data, loading, error }] = useDeleteAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetPresentValueMutation,
    DeleteAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetPresentValueMutation,
    DeleteAssetPresentValueMutationVariables
  >(DeleteAssetPresentValueDocument, options);
}
export type DeleteAssetPresentValueMutationHookResult = ReturnType<
  typeof useDeleteAssetPresentValueMutation
>;
export type DeleteAssetPresentValueMutationResult =
  Apollo.MutationResult<DeleteAssetPresentValueMutation>;
export type DeleteAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetPresentValueMutation,
  DeleteAssetPresentValueMutationVariables
>;
export const AddAssetMediaDocument = gql`
  mutation AddAssetMedia($input: AddAssetMediaInput!) {
    addAssetMedia(input: $input) {
      media {
        id
        file
      }
      asset {
        id
        title
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddAssetMediaMutationFn = Apollo.MutationFunction<
  AddAssetMediaMutation,
  AddAssetMediaMutationVariables
>;

/**
 * __useAddAssetMediaMutation__
 *
 * To run a mutation, you first call `useAddAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetMediaMutation, { data, loading, error }] = useAddAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAssetMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssetMediaMutation, AddAssetMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssetMediaMutation, AddAssetMediaMutationVariables>(
    AddAssetMediaDocument,
    options
  );
}
export type AddAssetMediaMutationHookResult = ReturnType<typeof useAddAssetMediaMutation>;
export type AddAssetMediaMutationResult = Apollo.MutationResult<AddAssetMediaMutation>;
export type AddAssetMediaMutationOptions = Apollo.BaseMutationOptions<
  AddAssetMediaMutation,
  AddAssetMediaMutationVariables
>;
export const AddMultipleAssetMediaDocument = gql`
  mutation AddMultipleAssetMedia($input: AddMultipleAssetMediaInput!) {
    addMultipleAssetMedia(input: $input) {
      asset {
        id
        title
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddMultipleAssetMediaMutationFn = Apollo.MutationFunction<
  AddMultipleAssetMediaMutation,
  AddMultipleAssetMediaMutationVariables
>;

/**
 * __useAddMultipleAssetMediaMutation__
 *
 * To run a mutation, you first call `useAddMultipleAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultipleAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultipleAssetMediaMutation, { data, loading, error }] = useAddMultipleAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMultipleAssetMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMultipleAssetMediaMutation,
    AddMultipleAssetMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMultipleAssetMediaMutation, AddMultipleAssetMediaMutationVariables>(
    AddMultipleAssetMediaDocument,
    options
  );
}
export type AddMultipleAssetMediaMutationHookResult = ReturnType<
  typeof useAddMultipleAssetMediaMutation
>;
export type AddMultipleAssetMediaMutationResult =
  Apollo.MutationResult<AddMultipleAssetMediaMutation>;
export type AddMultipleAssetMediaMutationOptions = Apollo.BaseMutationOptions<
  AddMultipleAssetMediaMutation,
  AddMultipleAssetMediaMutationVariables
>;
export const DeleteAssetMediaDocument = gql`
  mutation DeleteAssetMedia($input: DeleteAssetMediaInput!) {
    deleteAssetMedia(input: $input) {
      asset {
        id
        title
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteAssetMediaMutationFn = Apollo.MutationFunction<
  DeleteAssetMediaMutation,
  DeleteAssetMediaMutationVariables
>;

/**
 * __useDeleteAssetMediaMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMediaMutation, { data, loading, error }] = useDeleteAssetMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetMediaMutation,
    DeleteAssetMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetMediaMutation, DeleteAssetMediaMutationVariables>(
    DeleteAssetMediaDocument,
    options
  );
}
export type DeleteAssetMediaMutationHookResult = ReturnType<typeof useDeleteAssetMediaMutation>;
export type DeleteAssetMediaMutationResult = Apollo.MutationResult<DeleteAssetMediaMutation>;
export type DeleteAssetMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetMediaMutation,
  DeleteAssetMediaMutationVariables
>;
export const GetAssetDocumentsByAssetIdDocument = gql`
  query GetAssetDocumentsByAssetId($assetId: ID!) {
    allAssetDocuments(asset: $assetId, orderBy: "-created_at") {
      edges {
        node {
          id
          title
          description
          documentType
          coaType
          issuer
          recipient
          isClassified
          releaseDate
          expirationDate
          notes
          archiveNumber
          fineArtsIssueType
          isTemporaryExport
          isTemporaryImport
          isCriticalCatalog
          editor
          curator
          subscriptionDate
          contractSubscriber
          publishingDate
          otherDocumentType
          createdBy {
            name
          }
          asset {
            id
          }
          attachments {
            edges {
              node {
                id
                name
                file
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAssetDocumentsByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetDocumentsByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDocumentsByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDocumentsByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetDocumentsByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetDocumentsByAssetIdQuery,
    GetAssetDocumentsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetDocumentsByAssetIdQuery, GetAssetDocumentsByAssetIdQueryVariables>(
    GetAssetDocumentsByAssetIdDocument,
    options
  );
}
export function useGetAssetDocumentsByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetDocumentsByAssetIdQuery,
    GetAssetDocumentsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetDocumentsByAssetIdQuery,
    GetAssetDocumentsByAssetIdQueryVariables
  >(GetAssetDocumentsByAssetIdDocument, options);
}
export type GetAssetDocumentsByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetDocumentsByAssetIdQuery
>;
export type GetAssetDocumentsByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetDocumentsByAssetIdLazyQuery
>;
export type GetAssetDocumentsByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetDocumentsByAssetIdQuery,
  GetAssetDocumentsByAssetIdQueryVariables
>;
export const CreateAssetDocumentDocument = gql`
  mutation CreateAssetDocument($input: CreateAssetDocumentInput!) {
    createAssetDocument(input: $input) {
      assetDocument {
        id
        title
        description
        documentType
        coaType
        issuer
        recipient
        isClassified
        releaseDate
        expirationDate
        notes
        archiveNumber
        fineArtsIssueType
        isTemporaryExport
        isTemporaryImport
        isCriticalCatalog
        editor
        curator
        subscriptionDate
        contractSubscriber
        publishingDate
        otherDocumentType
        asset {
          id
        }
      }
    }
  }
`;
export type CreateAssetDocumentMutationFn = Apollo.MutationFunction<
  CreateAssetDocumentMutation,
  CreateAssetDocumentMutationVariables
>;

/**
 * __useCreateAssetDocumentMutation__
 *
 * To run a mutation, you first call `useCreateAssetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetDocumentMutation, { data, loading, error }] = useCreateAssetDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetDocumentMutation,
    CreateAssetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetDocumentMutation, CreateAssetDocumentMutationVariables>(
    CreateAssetDocumentDocument,
    options
  );
}
export type CreateAssetDocumentMutationHookResult = ReturnType<
  typeof useCreateAssetDocumentMutation
>;
export type CreateAssetDocumentMutationResult = Apollo.MutationResult<CreateAssetDocumentMutation>;
export type CreateAssetDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetDocumentMutation,
  CreateAssetDocumentMutationVariables
>;
export const UpdateAssetDocumentDocument = gql`
  mutation UpdateAssetDocument($input: UpdateAssetDocumentInput!) {
    updateAssetDocument(input: $input) {
      assetDocument {
        id
        title
        description
        documentType
        coaType
        issuer
        recipient
        isClassified
        releaseDate
        expirationDate
        notes
        archiveNumber
        fineArtsIssueType
        isTemporaryExport
        isTemporaryImport
        isCriticalCatalog
        editor
        curator
        subscriptionDate
        contractSubscriber
        publishingDate
        otherDocumentType
        asset {
          id
        }
      }
    }
  }
`;
export type UpdateAssetDocumentMutationFn = Apollo.MutationFunction<
  UpdateAssetDocumentMutation,
  UpdateAssetDocumentMutationVariables
>;

/**
 * __useUpdateAssetDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateAssetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetDocumentMutation, { data, loading, error }] = useUpdateAssetDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetDocumentMutation,
    UpdateAssetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetDocumentMutation, UpdateAssetDocumentMutationVariables>(
    UpdateAssetDocumentDocument,
    options
  );
}
export type UpdateAssetDocumentMutationHookResult = ReturnType<
  typeof useUpdateAssetDocumentMutation
>;
export type UpdateAssetDocumentMutationResult = Apollo.MutationResult<UpdateAssetDocumentMutation>;
export type UpdateAssetDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetDocumentMutation,
  UpdateAssetDocumentMutationVariables
>;
export const DeleteAssetDocumentDocument = gql`
  mutation DeleteAssetDocument($input: DeleteAssetDocumentInput!) {
    deleteAssetDocument(input: $input) {
      assetDocument {
        id
        title
        description
        documentType
        coaType
        issuer
        recipient
        isClassified
        releaseDate
        expirationDate
        notes
        archiveNumber
        fineArtsIssueType
        isTemporaryExport
        isTemporaryImport
        isCriticalCatalog
        editor
        curator
        subscriptionDate
        contractSubscriber
        publishingDate
        otherDocumentType
        asset {
          id
        }
      }
    }
  }
`;
export type DeleteAssetDocumentMutationFn = Apollo.MutationFunction<
  DeleteAssetDocumentMutation,
  DeleteAssetDocumentMutationVariables
>;

/**
 * __useDeleteAssetDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteAssetDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetDocumentMutation, { data, loading, error }] = useDeleteAssetDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetDocumentMutation,
    DeleteAssetDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetDocumentMutation, DeleteAssetDocumentMutationVariables>(
    DeleteAssetDocumentDocument,
    options
  );
}
export type DeleteAssetDocumentMutationHookResult = ReturnType<
  typeof useDeleteAssetDocumentMutation
>;
export type DeleteAssetDocumentMutationResult = Apollo.MutationResult<DeleteAssetDocumentMutation>;
export type DeleteAssetDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetDocumentMutation,
  DeleteAssetDocumentMutationVariables
>;
export const GetAssetDocumentAttachmentsByDocumentIdDocument = gql`
  query GetAssetDocumentAttachmentsByDocumentId($documentId: ID!) {
    allAssetDocumentAttachments(document: $documentId, orderBy: "-created_at") {
      edges {
        node {
          id
          name
          file
        }
      }
    }
  }
`;

/**
 * __useGetAssetDocumentAttachmentsByDocumentIdQuery__
 *
 * To run a query within a React component, call `useGetAssetDocumentAttachmentsByDocumentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetDocumentAttachmentsByDocumentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetDocumentAttachmentsByDocumentIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetAssetDocumentAttachmentsByDocumentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >(GetAssetDocumentAttachmentsByDocumentIdDocument, options);
}
export function useGetAssetDocumentAttachmentsByDocumentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetDocumentAttachmentsByDocumentIdQuery,
    GetAssetDocumentAttachmentsByDocumentIdQueryVariables
  >(GetAssetDocumentAttachmentsByDocumentIdDocument, options);
}
export type GetAssetDocumentAttachmentsByDocumentIdQueryHookResult = ReturnType<
  typeof useGetAssetDocumentAttachmentsByDocumentIdQuery
>;
export type GetAssetDocumentAttachmentsByDocumentIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetDocumentAttachmentsByDocumentIdLazyQuery
>;
export type GetAssetDocumentAttachmentsByDocumentIdQueryResult = Apollo.QueryResult<
  GetAssetDocumentAttachmentsByDocumentIdQuery,
  GetAssetDocumentAttachmentsByDocumentIdQueryVariables
>;
export const CreateAssetDocumentAttachmentDocument = gql`
  mutation CreateAssetDocumentAttachment($input: CreateAssetDocumentAttachmentInput!) {
    createAssetDocumentAttachment(input: $input) {
      assetDocumentAttachment {
        id
        name
        file
      }
    }
  }
`;
export type CreateAssetDocumentAttachmentMutationFn = Apollo.MutationFunction<
  CreateAssetDocumentAttachmentMutation,
  CreateAssetDocumentAttachmentMutationVariables
>;

/**
 * __useCreateAssetDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAssetDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetDocumentAttachmentMutation, { data, loading, error }] = useCreateAssetDocumentAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetDocumentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetDocumentAttachmentMutation,
    CreateAssetDocumentAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssetDocumentAttachmentMutation,
    CreateAssetDocumentAttachmentMutationVariables
  >(CreateAssetDocumentAttachmentDocument, options);
}
export type CreateAssetDocumentAttachmentMutationHookResult = ReturnType<
  typeof useCreateAssetDocumentAttachmentMutation
>;
export type CreateAssetDocumentAttachmentMutationResult =
  Apollo.MutationResult<CreateAssetDocumentAttachmentMutation>;
export type CreateAssetDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetDocumentAttachmentMutation,
  CreateAssetDocumentAttachmentMutationVariables
>;
export const UpdateAssetDocumentAttachmentDocument = gql`
  mutation UpdateAssetDocumentAttachment($input: UpdateAssetDocumentAttachmentInput!) {
    updateAssetDocumentAttachment(input: $input) {
      assetDocumentAttachment {
        id
        name
        file
      }
    }
  }
`;
export type UpdateAssetDocumentAttachmentMutationFn = Apollo.MutationFunction<
  UpdateAssetDocumentAttachmentMutation,
  UpdateAssetDocumentAttachmentMutationVariables
>;

/**
 * __useUpdateAssetDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssetDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetDocumentAttachmentMutation, { data, loading, error }] = useUpdateAssetDocumentAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetDocumentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetDocumentAttachmentMutation,
    UpdateAssetDocumentAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssetDocumentAttachmentMutation,
    UpdateAssetDocumentAttachmentMutationVariables
  >(UpdateAssetDocumentAttachmentDocument, options);
}
export type UpdateAssetDocumentAttachmentMutationHookResult = ReturnType<
  typeof useUpdateAssetDocumentAttachmentMutation
>;
export type UpdateAssetDocumentAttachmentMutationResult =
  Apollo.MutationResult<UpdateAssetDocumentAttachmentMutation>;
export type UpdateAssetDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetDocumentAttachmentMutation,
  UpdateAssetDocumentAttachmentMutationVariables
>;
export const DeleteAssetDocumentAttachmentDocument = gql`
  mutation DeleteAssetDocumentAttachment($input: DeleteAssetDocumentAttachmentInput!) {
    deleteAssetDocumentAttachment(input: $input) {
      assetDocumentAttachment {
        id
        name
        file
      }
    }
  }
`;
export type DeleteAssetDocumentAttachmentMutationFn = Apollo.MutationFunction<
  DeleteAssetDocumentAttachmentMutation,
  DeleteAssetDocumentAttachmentMutationVariables
>;

/**
 * __useDeleteAssetDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssetDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetDocumentAttachmentMutation, { data, loading, error }] = useDeleteAssetDocumentAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetDocumentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetDocumentAttachmentMutation,
    DeleteAssetDocumentAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetDocumentAttachmentMutation,
    DeleteAssetDocumentAttachmentMutationVariables
  >(DeleteAssetDocumentAttachmentDocument, options);
}
export type DeleteAssetDocumentAttachmentMutationHookResult = ReturnType<
  typeof useDeleteAssetDocumentAttachmentMutation
>;
export type DeleteAssetDocumentAttachmentMutationResult =
  Apollo.MutationResult<DeleteAssetDocumentAttachmentMutation>;
export type DeleteAssetDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetDocumentAttachmentMutation,
  DeleteAssetDocumentAttachmentMutationVariables
>;
export const DeleteAssetCarryingValueFileDocument = gql`
  mutation DeleteAssetCarryingValueFile($input: DeleteAssetCarryingValueFileInput!) {
    deleteAssetCarryingValueFile(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
        carryingValueFile
      }
    }
  }
`;
export type DeleteAssetCarryingValueFileMutationFn = Apollo.MutationFunction<
  DeleteAssetCarryingValueFileMutation,
  DeleteAssetCarryingValueFileMutationVariables
>;

/**
 * __useDeleteAssetCarryingValueFileMutation__
 *
 * To run a mutation, you first call `useDeleteAssetCarryingValueFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetCarryingValueFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetCarryingValueFileMutation, { data, loading, error }] = useDeleteAssetCarryingValueFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetCarryingValueFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetCarryingValueFileMutation,
    DeleteAssetCarryingValueFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetCarryingValueFileMutation,
    DeleteAssetCarryingValueFileMutationVariables
  >(DeleteAssetCarryingValueFileDocument, options);
}
export type DeleteAssetCarryingValueFileMutationHookResult = ReturnType<
  typeof useDeleteAssetCarryingValueFileMutation
>;
export type DeleteAssetCarryingValueFileMutationResult =
  Apollo.MutationResult<DeleteAssetCarryingValueFileMutation>;
export type DeleteAssetCarryingValueFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetCarryingValueFileMutation,
  DeleteAssetCarryingValueFileMutationVariables
>;
export const ExportAssetPdfDocument = gql`
  mutation ExportAssetPDF($input: ExportAssetPDFInput!) {
    exportAssetPdf(input: $input) {
      asset {
        id
      }
      url
    }
  }
`;
export type ExportAssetPdfMutationFn = Apollo.MutationFunction<
  ExportAssetPdfMutation,
  ExportAssetPdfMutationVariables
>;

/**
 * __useExportAssetPdfMutation__
 *
 * To run a mutation, you first call `useExportAssetPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssetPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssetPdfMutation, { data, loading, error }] = useExportAssetPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAssetPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<ExportAssetPdfMutation, ExportAssetPdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportAssetPdfMutation, ExportAssetPdfMutationVariables>(
    ExportAssetPdfDocument,
    options
  );
}
export type ExportAssetPdfMutationHookResult = ReturnType<typeof useExportAssetPdfMutation>;
export type ExportAssetPdfMutationResult = Apollo.MutationResult<ExportAssetPdfMutation>;
export type ExportAssetPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportAssetPdfMutation,
  ExportAssetPdfMutationVariables
>;
export const StartAssetPredictionDocument = gql`
  mutation StartAssetPrediction($input: StartAssetPredictionInput!) {
    startAssetPrediction(input: $input) {
      assetPrediction {
        jobUuid
        id
      }
    }
  }
`;
export type StartAssetPredictionMutationFn = Apollo.MutationFunction<
  StartAssetPredictionMutation,
  StartAssetPredictionMutationVariables
>;

/**
 * __useStartAssetPredictionMutation__
 *
 * To run a mutation, you first call `useStartAssetPredictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAssetPredictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAssetPredictionMutation, { data, loading, error }] = useStartAssetPredictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAssetPredictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartAssetPredictionMutation,
    StartAssetPredictionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartAssetPredictionMutation, StartAssetPredictionMutationVariables>(
    StartAssetPredictionDocument,
    options
  );
}
export type StartAssetPredictionMutationHookResult = ReturnType<
  typeof useStartAssetPredictionMutation
>;
export type StartAssetPredictionMutationResult =
  Apollo.MutationResult<StartAssetPredictionMutation>;
export type StartAssetPredictionMutationOptions = Apollo.BaseMutationOptions<
  StartAssetPredictionMutation,
  StartAssetPredictionMutationVariables
>;
export const GetAssetPredictionDocument = gql`
  mutation GetAssetPrediction($input: GetAssetPredictionInput!) {
    getAssetPrediction(input: $input) {
      assetPrediction {
        id
        jobUuid
        aiReplacmentValue
        aiLiquidityValue
        aiIntrinsicValue
        type
      }
    }
  }
`;
export type GetAssetPredictionMutationFn = Apollo.MutationFunction<
  GetAssetPredictionMutation,
  GetAssetPredictionMutationVariables
>;

/**
 * __useGetAssetPredictionMutation__
 *
 * To run a mutation, you first call `useGetAssetPredictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPredictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAssetPredictionMutation, { data, loading, error }] = useGetAssetPredictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssetPredictionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetAssetPredictionMutation,
    GetAssetPredictionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetAssetPredictionMutation, GetAssetPredictionMutationVariables>(
    GetAssetPredictionDocument,
    options
  );
}
export type GetAssetPredictionMutationHookResult = ReturnType<typeof useGetAssetPredictionMutation>;
export type GetAssetPredictionMutationResult = Apollo.MutationResult<GetAssetPredictionMutation>;
export type GetAssetPredictionMutationOptions = Apollo.BaseMutationOptions<
  GetAssetPredictionMutation,
  GetAssetPredictionMutationVariables
>;
export const DuplicateAssetDocument = gql`
  mutation DuplicateAsset($input: DuplicateAssetInput!) {
    duplicateAsset(input: $input) {
      asset {
        id
      }
    }
  }
`;
export type DuplicateAssetMutationFn = Apollo.MutationFunction<
  DuplicateAssetMutation,
  DuplicateAssetMutationVariables
>;

/**
 * __useDuplicateAssetMutation__
 *
 * To run a mutation, you first call `useDuplicateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateAssetMutation, { data, loading, error }] = useDuplicateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateAssetMutation, DuplicateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateAssetMutation, DuplicateAssetMutationVariables>(
    DuplicateAssetDocument,
    options
  );
}
export type DuplicateAssetMutationHookResult = ReturnType<typeof useDuplicateAssetMutation>;
export type DuplicateAssetMutationResult = Apollo.MutationResult<DuplicateAssetMutation>;
export type DuplicateAssetMutationOptions = Apollo.BaseMutationOptions<
  DuplicateAssetMutation,
  DuplicateAssetMutationVariables
>;
export const CsvImportCreateAssetDocument = gql`
  mutation CsvImportCreateAsset($input: CsvImportCreateAssetInput!) {
    csvImportCreateAsset(input: $input) {
      importCsvResponse {
        asset {
          id
          title
          author
          creationPeriod
          carryingValueFile
          actualDateAndPeriod
          category
          genericDimensions
          inventoryNumber
          technique
        }
        parsedRowIndex
      }
    }
  }
`;
export type CsvImportCreateAssetMutationFn = Apollo.MutationFunction<
  CsvImportCreateAssetMutation,
  CsvImportCreateAssetMutationVariables
>;

/**
 * __useCsvImportCreateAssetMutation__
 *
 * To run a mutation, you first call `useCsvImportCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCsvImportCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [csvImportCreateAssetMutation, { data, loading, error }] = useCsvImportCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCsvImportCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CsvImportCreateAssetMutation,
    CsvImportCreateAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CsvImportCreateAssetMutation, CsvImportCreateAssetMutationVariables>(
    CsvImportCreateAssetDocument,
    options
  );
}
export type CsvImportCreateAssetMutationHookResult = ReturnType<
  typeof useCsvImportCreateAssetMutation
>;
export type CsvImportCreateAssetMutationResult =
  Apollo.MutationResult<CsvImportCreateAssetMutation>;
export type CsvImportCreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CsvImportCreateAssetMutation,
  CsvImportCreateAssetMutationVariables
>;
export const GetAllAssetsMediaBySearchDocument = gql`
  query GetAllAssetsMediaBySearch(
    $first: Int
    $after: String
    $offset: Int
    $validated: Boolean
    $createdByUserGroup: String
    $orderBy: String
    $search: String
    $category_In: [String]
    $subcategory_In: [String]
    $location: String
    $id: String
  ) {
    allAssets(
      first: $first
      after: $after
      offset: $offset
      validated: $validated
      createdByUserGroup: $createdByUserGroup
      orderBy: $orderBy
      search: $search
      category_In: $category_In
      subcategory_In: $subcategory_In
      location: $location
      id: $id
    ) {
      edges {
        node {
          id
          title
          author
          mainImage
          media {
            edges {
              node {
                id
                name
                file
                createdAt
                createdBy {
                  name
                }
              }
            }
          }
          createdBy {
            name
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllAssetsMediaBySearchQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsMediaBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsMediaBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsMediaBySearchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      offset: // value for 'offset'
 *      validated: // value for 'validated'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      category_In: // value for 'category_In'
 *      subcategory_In: // value for 'subcategory_In'
 *      location: // value for 'location'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllAssetsMediaBySearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllAssetsMediaBySearchQuery,
    GetAllAssetsMediaBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsMediaBySearchQuery, GetAllAssetsMediaBySearchQueryVariables>(
    GetAllAssetsMediaBySearchDocument,
    options
  );
}
export function useGetAllAssetsMediaBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsMediaBySearchQuery,
    GetAllAssetsMediaBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllAssetsMediaBySearchQuery,
    GetAllAssetsMediaBySearchQueryVariables
  >(GetAllAssetsMediaBySearchDocument, options);
}
export type GetAllAssetsMediaBySearchQueryHookResult = ReturnType<
  typeof useGetAllAssetsMediaBySearchQuery
>;
export type GetAllAssetsMediaBySearchLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsMediaBySearchLazyQuery
>;
export type GetAllAssetsMediaBySearchQueryResult = Apollo.QueryResult<
  GetAllAssetsMediaBySearchQuery,
  GetAllAssetsMediaBySearchQueryVariables
>;
export const GetAllBillsDocument = gql`
  query getAllBills {
    allExportedDocuments(type: "bill") {
      edges {
        node {
          id
          modelName
          isSigned
          file
          externalId
          type
          createdAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetAllBillsQuery__
 *
 * To run a query within a React component, call `useGetAllBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBillsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllBillsQuery, GetAllBillsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllBillsQuery, GetAllBillsQueryVariables>(GetAllBillsDocument, options);
}
export function useGetAllBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllBillsQuery, GetAllBillsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllBillsQuery, GetAllBillsQueryVariables>(
    GetAllBillsDocument,
    options
  );
}
export type GetAllBillsQueryHookResult = ReturnType<typeof useGetAllBillsQuery>;
export type GetAllBillsLazyQueryHookResult = ReturnType<typeof useGetAllBillsLazyQuery>;
export type GetAllBillsQueryResult = Apollo.QueryResult<
  GetAllBillsQuery,
  GetAllBillsQueryVariables
>;
export const GetAllTransportationsBillsDocument = gql`
  query getAllTransportationsBills(
    $offset: Int
    $first: Int
    $orderBy: String
    $search: String
    $createdByUserGroup: String
  ) {
    allExportedDocuments(
      type: "bill"
      offset: $offset
      first: $first
      orderBy: $orderBy
      modelName: "transportation"
      search: $search
      createdByUserGroup: $createdByUserGroup
    ) {
      edges {
        node {
          id
          isSigned
          file
          createdAt
          updatedAt
          createdBy {
            email
            name
          }
          transportation {
            deliveryDetails {
              recipient {
                fullName
              }
            }
            customer {
              fullName
            }
            shipper {
              fullName
            }
            id
            vector {
              fullName
            }
            numberOfAssets
            transportationId
            createdBy {
              name
            }
            createdAt
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllTransportationsBillsQuery__
 *
 * To run a query within a React component, call `useGetAllTransportationsBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransportationsBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransportationsBillsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *   },
 * });
 */
export function useGetAllTransportationsBillsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTransportationsBillsQuery,
    GetAllTransportationsBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTransportationsBillsQuery, GetAllTransportationsBillsQueryVariables>(
    GetAllTransportationsBillsDocument,
    options
  );
}
export function useGetAllTransportationsBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTransportationsBillsQuery,
    GetAllTransportationsBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllTransportationsBillsQuery,
    GetAllTransportationsBillsQueryVariables
  >(GetAllTransportationsBillsDocument, options);
}
export type GetAllTransportationsBillsQueryHookResult = ReturnType<
  typeof useGetAllTransportationsBillsQuery
>;
export type GetAllTransportationsBillsLazyQueryHookResult = ReturnType<
  typeof useGetAllTransportationsBillsLazyQuery
>;
export type GetAllTransportationsBillsQueryResult = Apollo.QueryResult<
  GetAllTransportationsBillsQuery,
  GetAllTransportationsBillsQueryVariables
>;
export const GetAllDepositationsBillsDocument = gql`
  query getAllDepositationsBills(
    $offset: Int
    $first: Int
    $orderBy: String
    $search: String
    $createdByUserGroup: String
    $typeIn: [String]
  ) {
    allExportedDocuments(
      offset: $offset
      first: $first
      orderBy: $orderBy
      search: $search
      createdByUserGroup: $createdByUserGroup
      modelName: "depositation"
      typeIn: $typeIn
    ) {
      totalCount
      edges {
        node {
          id
          modelName
          isSigned
          file
          externalId
          type
          createdAt
          updatedAt
          createdBy {
            email
            name
          }
          depositation {
            customer {
              fullName
            }
            company {
              fullName
            }
            id
            numberOfAssets
            createdBy {
              name
            }
            createdAt
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllDepositationsBillsQuery__
 *
 * To run a query within a React component, call `useGetAllDepositationsBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepositationsBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepositationsBillsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      typeIn: // value for 'typeIn'
 *   },
 * });
 */
export function useGetAllDepositationsBillsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllDepositationsBillsQuery,
    GetAllDepositationsBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDepositationsBillsQuery, GetAllDepositationsBillsQueryVariables>(
    GetAllDepositationsBillsDocument,
    options
  );
}
export function useGetAllDepositationsBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDepositationsBillsQuery,
    GetAllDepositationsBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllDepositationsBillsQuery, GetAllDepositationsBillsQueryVariables>(
    GetAllDepositationsBillsDocument,
    options
  );
}
export type GetAllDepositationsBillsQueryHookResult = ReturnType<
  typeof useGetAllDepositationsBillsQuery
>;
export type GetAllDepositationsBillsLazyQueryHookResult = ReturnType<
  typeof useGetAllDepositationsBillsLazyQuery
>;
export type GetAllDepositationsBillsQueryResult = Apollo.QueryResult<
  GetAllDepositationsBillsQuery,
  GetAllDepositationsBillsQueryVariables
>;
export const DeleteDepositationBillDocument = gql`
  mutation deleteDepositationBill($input: DeleteDepositationBillInput!) {
    deleteDepositationBill(input: $input) {
      depositationBill {
        id
      }
    }
  }
`;
export type DeleteDepositationBillMutationFn = Apollo.MutationFunction<
  DeleteDepositationBillMutation,
  DeleteDepositationBillMutationVariables
>;

/**
 * __useDeleteDepositationBillMutation__
 *
 * To run a mutation, you first call `useDeleteDepositationBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepositationBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepositationBillMutation, { data, loading, error }] = useDeleteDepositationBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDepositationBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDepositationBillMutation,
    DeleteDepositationBillMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDepositationBillMutation,
    DeleteDepositationBillMutationVariables
  >(DeleteDepositationBillDocument, options);
}
export type DeleteDepositationBillMutationHookResult = ReturnType<
  typeof useDeleteDepositationBillMutation
>;
export type DeleteDepositationBillMutationResult =
  Apollo.MutationResult<DeleteDepositationBillMutation>;
export type DeleteDepositationBillMutationOptions = Apollo.BaseMutationOptions<
  DeleteDepositationBillMutation,
  DeleteDepositationBillMutationVariables
>;
export const DeleteTransportationBillDocument = gql`
  mutation deleteTransportationBill($input: DeleteTransportationBillInput!) {
    deleteTransportationBill(input: $input) {
      transportationBill {
        id
      }
    }
  }
`;
export type DeleteTransportationBillMutationFn = Apollo.MutationFunction<
  DeleteTransportationBillMutation,
  DeleteTransportationBillMutationVariables
>;

/**
 * __useDeleteTransportationBillMutation__
 *
 * To run a mutation, you first call `useDeleteTransportationBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransportationBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransportationBillMutation, { data, loading, error }] = useDeleteTransportationBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTransportationBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTransportationBillMutation,
    DeleteTransportationBillMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTransportationBillMutation,
    DeleteTransportationBillMutationVariables
  >(DeleteTransportationBillDocument, options);
}
export type DeleteTransportationBillMutationHookResult = ReturnType<
  typeof useDeleteTransportationBillMutation
>;
export type DeleteTransportationBillMutationResult =
  Apollo.MutationResult<DeleteTransportationBillMutation>;
export type DeleteTransportationBillMutationOptions = Apollo.BaseMutationOptions<
  DeleteTransportationBillMutation,
  DeleteTransportationBillMutationVariables
>;
export const GetCurrentClientDocument = gql`
  query GetCurrentClient($id: ID!) {
    client(id: $id) {
      createdOn
      customerType
      currency
      dashboardBaseUrl
      id
      language
      displayName
      usedInvites
      usedInvites
      location
      language
      logo
      backgroundColor
      name
      onTrial
      paidUntil
      schemaName
      storageName
      tier
      isInMaintenance
      tierSpecs {
        maxInvites
        maxAssets
        maxProjects
        maxStorage
        maxStoragePerUpload
        maxStoragePerProject
        maxStoragePerUser
        maxUploadsPerProject
        maxUploads
        maxUsers
        name
        price
      }
      timezone
      defaultInsuranceCompany {
        id
        fullName
      }
      defaultBroker {
        id
        fullName
      }
      depositationCompanyTenantDefault {
        id
        fullName
      }
    }
  }
`;

/**
 * __useGetCurrentClientQuery__
 *
 * To run a query within a React component, call `useGetCurrentClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentClientQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentClientQuery, GetCurrentClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export function useGetCurrentClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentClientQuery, GetCurrentClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export type GetCurrentClientQueryHookResult = ReturnType<typeof useGetCurrentClientQuery>;
export type GetCurrentClientLazyQueryHookResult = ReturnType<typeof useGetCurrentClientLazyQuery>;
export type GetCurrentClientQueryResult = Apollo.QueryResult<
  GetCurrentClientQuery,
  GetCurrentClientQueryVariables
>;
export const GetCurrentClientsByHostnameDocument = gql`
  query GetCurrentClientsByHostname($dashboardBaseUrl: String!) {
    allClient(dashboardBaseUrl: $dashboardBaseUrl) {
      edges {
        node {
          createdOn
          customerType
          currency
          dashboardBaseUrl
          id
          language
          displayName
          usedInvites
          usedInvites
          location
          language
          logo
          backgroundColor
          name
          onTrial
          paidUntil
          schemaName
          storageName
          tier
          isInMaintenance
          tierSpecs {
            maxInvites
            maxAssets
            maxProjects
            maxStorage
            maxStoragePerUpload
            maxStoragePerProject
            maxStoragePerUser
            maxUploadsPerProject
            maxUploads
            maxUsers
            name
            price
          }
          timezone
          defaultInsuranceCompany {
            id
            fullName
          }
          defaultBroker {
            id
            fullName
          }
          depositationCompanyTenantDefault {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetCurrentClientsByHostnameQuery__
 *
 * To run a query within a React component, call `useGetCurrentClientsByHostnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClientsByHostnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClientsByHostnameQuery({
 *   variables: {
 *      dashboardBaseUrl: // value for 'dashboardBaseUrl'
 *   },
 * });
 */
export function useGetCurrentClientsByHostnameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentClientsByHostnameQuery,
    GetCurrentClientsByHostnameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentClientsByHostnameQuery,
    GetCurrentClientsByHostnameQueryVariables
  >(GetCurrentClientsByHostnameDocument, options);
}
export function useGetCurrentClientsByHostnameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentClientsByHostnameQuery,
    GetCurrentClientsByHostnameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentClientsByHostnameQuery,
    GetCurrentClientsByHostnameQueryVariables
  >(GetCurrentClientsByHostnameDocument, options);
}
export type GetCurrentClientsByHostnameQueryHookResult = ReturnType<
  typeof useGetCurrentClientsByHostnameQuery
>;
export type GetCurrentClientsByHostnameLazyQueryHookResult = ReturnType<
  typeof useGetCurrentClientsByHostnameLazyQuery
>;
export type GetCurrentClientsByHostnameQueryResult = Apollo.QueryResult<
  GetCurrentClientsByHostnameQuery,
  GetCurrentClientsByHostnameQueryVariables
>;
export const GetCurrentClientUsageDocument = gql`
  query GetCurrentClientUsage($id: ID!) {
    client(id: $id) {
      currentAssets
      currentInvites
      currentProjects
      currentUsers
      currentStorage
    }
  }
`;

/**
 * __useGetCurrentClientUsageQuery__
 *
 * To run a query within a React component, call `useGetCurrentClientUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClientUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClientUsageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentClientUsageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentClientUsageQuery,
    GetCurrentClientUsageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentClientUsageQuery, GetCurrentClientUsageQueryVariables>(
    GetCurrentClientUsageDocument,
    options
  );
}
export function useGetCurrentClientUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentClientUsageQuery,
    GetCurrentClientUsageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentClientUsageQuery, GetCurrentClientUsageQueryVariables>(
    GetCurrentClientUsageDocument,
    options
  );
}
export type GetCurrentClientUsageQueryHookResult = ReturnType<typeof useGetCurrentClientUsageQuery>;
export type GetCurrentClientUsageLazyQueryHookResult = ReturnType<
  typeof useGetCurrentClientUsageLazyQuery
>;
export type GetCurrentClientUsageQueryResult = Apollo.QueryResult<
  GetCurrentClientUsageQuery,
  GetCurrentClientUsageQueryVariables
>;
export const UpdateClientLogoDocument = gql`
  mutation UpdateClientLogo($input: UpdateClientLogoInput!) {
    updateClientLogo(input: $input) {
      client {
        id
      }
    }
  }
`;
export type UpdateClientLogoMutationFn = Apollo.MutationFunction<
  UpdateClientLogoMutation,
  UpdateClientLogoMutationVariables
>;

/**
 * __useUpdateClientLogoMutation__
 *
 * To run a mutation, you first call `useUpdateClientLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientLogoMutation, { data, loading, error }] = useUpdateClientLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientLogoMutation,
    UpdateClientLogoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientLogoMutation, UpdateClientLogoMutationVariables>(
    UpdateClientLogoDocument,
    options
  );
}
export type UpdateClientLogoMutationHookResult = ReturnType<typeof useUpdateClientLogoMutation>;
export type UpdateClientLogoMutationResult = Apollo.MutationResult<UpdateClientLogoMutation>;
export type UpdateClientLogoMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientLogoMutation,
  UpdateClientLogoMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      client {
        id
      }
    }
  }
`;
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(
    UpdateClientDocument,
    options
  );
}
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpdateClientBackgroundColorDocument = gql`
  mutation UpdateClientBackgroundColor($input: UpdateClientBackgroundColorInput!) {
    updateClientBackgroundColor(input: $input) {
      client {
        id
      }
    }
  }
`;
export type UpdateClientBackgroundColorMutationFn = Apollo.MutationFunction<
  UpdateClientBackgroundColorMutation,
  UpdateClientBackgroundColorMutationVariables
>;

/**
 * __useUpdateClientBackgroundColorMutation__
 *
 * To run a mutation, you first call `useUpdateClientBackgroundColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientBackgroundColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientBackgroundColorMutation, { data, loading, error }] = useUpdateClientBackgroundColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientBackgroundColorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientBackgroundColorMutation,
    UpdateClientBackgroundColorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientBackgroundColorMutation,
    UpdateClientBackgroundColorMutationVariables
  >(UpdateClientBackgroundColorDocument, options);
}
export type UpdateClientBackgroundColorMutationHookResult = ReturnType<
  typeof useUpdateClientBackgroundColorMutation
>;
export type UpdateClientBackgroundColorMutationResult =
  Apollo.MutationResult<UpdateClientBackgroundColorMutation>;
export type UpdateClientBackgroundColorMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientBackgroundColorMutation,
  UpdateClientBackgroundColorMutationVariables
>;
export const ResetClientLoginDocument = gql`
  mutation ResetClientLogin($input: ResetClientLoginInput!) {
    resetClientLogin(input: $input) {
      client {
        id
      }
    }
  }
`;
export type ResetClientLoginMutationFn = Apollo.MutationFunction<
  ResetClientLoginMutation,
  ResetClientLoginMutationVariables
>;

/**
 * __useResetClientLoginMutation__
 *
 * To run a mutation, you first call `useResetClientLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetClientLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetClientLoginMutation, { data, loading, error }] = useResetClientLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetClientLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetClientLoginMutation,
    ResetClientLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetClientLoginMutation, ResetClientLoginMutationVariables>(
    ResetClientLoginDocument,
    options
  );
}
export type ResetClientLoginMutationHookResult = ReturnType<typeof useResetClientLoginMutation>;
export type ResetClientLoginMutationResult = Apollo.MutationResult<ResetClientLoginMutation>;
export type ResetClientLoginMutationOptions = Apollo.BaseMutationOptions<
  ResetClientLoginMutation,
  ResetClientLoginMutationVariables
>;
export const GetCollectionByIdDocument = gql`
  query GetCollectionById($id: ID!) {
    collection(id: $id) {
      ...CollectionAnagraphicFields
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
  ${CollectionAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>(
    GetCollectionByIdDocument,
    options
  );
}
export function useGetCollectionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>(
    GetCollectionByIdDocument,
    options
  );
}
export type GetCollectionByIdQueryHookResult = ReturnType<typeof useGetCollectionByIdQuery>;
export type GetCollectionByIdLazyQueryHookResult = ReturnType<typeof useGetCollectionByIdLazyQuery>;
export type GetCollectionByIdQueryResult = Apollo.QueryResult<
  GetCollectionByIdQuery,
  GetCollectionByIdQueryVariables
>;
export const GetAllCollectionImagesByIdDocument = gql`
  query GetAllCollectionImagesById($id: ID!, $token: String) {
    collection(id: $id, token: $token) {
      ...CollectionAnagraphicFields
      assetSet {
        edges {
          node {
            mainImage
            media {
              edges {
                node {
                  ...Media
                }
              }
            }
          }
        }
      }
    }
  }
  ${CollectionAnagraphicFieldsFragmentDoc}
  ${MediaFragmentDoc}
`;

/**
 * __useGetAllCollectionImagesByIdQuery__
 *
 * To run a query within a React component, call `useGetAllCollectionImagesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollectionImagesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollectionImagesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetAllCollectionImagesByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCollectionImagesByIdQuery,
    GetAllCollectionImagesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCollectionImagesByIdQuery, GetAllCollectionImagesByIdQueryVariables>(
    GetAllCollectionImagesByIdDocument,
    options
  );
}
export function useGetAllCollectionImagesByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCollectionImagesByIdQuery,
    GetAllCollectionImagesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCollectionImagesByIdQuery,
    GetAllCollectionImagesByIdQueryVariables
  >(GetAllCollectionImagesByIdDocument, options);
}
export type GetAllCollectionImagesByIdQueryHookResult = ReturnType<
  typeof useGetAllCollectionImagesByIdQuery
>;
export type GetAllCollectionImagesByIdLazyQueryHookResult = ReturnType<
  typeof useGetAllCollectionImagesByIdLazyQuery
>;
export type GetAllCollectionImagesByIdQueryResult = Apollo.QueryResult<
  GetAllCollectionImagesByIdQuery,
  GetAllCollectionImagesByIdQueryVariables
>;
export const GetAllCollectionsDocument = gql`
  query GetAllCollections(
    $orderBy: String
    $search: String
    $first: Int
    $offset: Int
    $createdFrom: String
    $createdByUserGroup: String
  ) {
    allCollections(
      orderBy: $orderBy
      search: $search
      first: $first
      offset: $offset
      createdFrom: $createdFrom
      createdByUserGroup: $createdByUserGroup
    ) {
      edges {
        node {
          id
          name
          description
          image
          notes
          totalAssetsNumber
          validatedAssetsNumber
          createdFrom
          actualStatusObjects
          createdAt
          createdBy {
            name
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllCollectionsQuery__
 *
 * To run a query within a React component, call `useGetAllCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollectionsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      createdFrom: // value for 'createdFrom'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *   },
 * });
 */
export function useGetAllCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>(
    GetAllCollectionsDocument,
    options
  );
}
export function useGetAllCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>(
    GetAllCollectionsDocument,
    options
  );
}
export type GetAllCollectionsQueryHookResult = ReturnType<typeof useGetAllCollectionsQuery>;
export type GetAllCollectionsLazyQueryHookResult = ReturnType<typeof useGetAllCollectionsLazyQuery>;
export type GetAllCollectionsQueryResult = Apollo.QueryResult<
  GetAllCollectionsQuery,
  GetAllCollectionsQueryVariables
>;
export const GetMediaCollectionByIdDocument = gql`
  query GetMediaCollectionById($id: ID!) {
    collection(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaCollectionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaCollectionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaCollectionByIdQuery,
    GetMediaCollectionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaCollectionByIdQuery, GetMediaCollectionByIdQueryVariables>(
    GetMediaCollectionByIdDocument,
    options
  );
}
export function useGetMediaCollectionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaCollectionByIdQuery,
    GetMediaCollectionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaCollectionByIdQuery, GetMediaCollectionByIdQueryVariables>(
    GetMediaCollectionByIdDocument,
    options
  );
}
export type GetMediaCollectionByIdQueryHookResult = ReturnType<
  typeof useGetMediaCollectionByIdQuery
>;
export type GetMediaCollectionByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaCollectionByIdLazyQuery
>;
export type GetMediaCollectionByIdQueryResult = Apollo.QueryResult<
  GetMediaCollectionByIdQuery,
  GetMediaCollectionByIdQueryVariables
>;
export const CreateCollectionDocument = gql`
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      collection {
        id
        name
        description
        notes
      }
    }
  }
`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectionMutation,
    CreateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(
    CreateCollectionDocument,
    options
  );
}
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>;
export const UpdateCollectionDocument = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectionMutation,
    UpdateCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(
    UpdateCollectionDocument,
    options
  );
}
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>;
export const DeleteCollectionDocument = gql`
  mutation DeleteCollection($input: DeleteCollectionInput!) {
    deleteCollection(input: $input) {
      collection {
        id
      }
    }
  }
`;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollectionMutation,
    DeleteCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(
    DeleteCollectionDocument,
    options
  );
}
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;
export const DetachAssetFromCollectionDocument = gql`
  mutation DetachAssetFromCollection($input: DetachAssetFromCollectionInput!) {
    detachAssetFromCollection(input: $input) {
      collection {
        id
        assetSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DetachAssetFromCollectionMutationFn = Apollo.MutationFunction<
  DetachAssetFromCollectionMutation,
  DetachAssetFromCollectionMutationVariables
>;

/**
 * __useDetachAssetFromCollectionMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromCollectionMutation, { data, loading, error }] = useDetachAssetFromCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromCollectionMutation,
    DetachAssetFromCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromCollectionMutation,
    DetachAssetFromCollectionMutationVariables
  >(DetachAssetFromCollectionDocument, options);
}
export type DetachAssetFromCollectionMutationHookResult = ReturnType<
  typeof useDetachAssetFromCollectionMutation
>;
export type DetachAssetFromCollectionMutationResult =
  Apollo.MutationResult<DetachAssetFromCollectionMutation>;
export type DetachAssetFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromCollectionMutation,
  DetachAssetFromCollectionMutationVariables
>;
export const DetachAssetsFromCollectionDocument = gql`
  mutation DetachAssetsFromCollection($input: DetachAssetsFromCollectionInput!) {
    detachAssetsFromCollection(input: $input) {
      collection {
        id
        assetSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DetachAssetsFromCollectionMutationFn = Apollo.MutationFunction<
  DetachAssetsFromCollectionMutation,
  DetachAssetsFromCollectionMutationVariables
>;

/**
 * __useDetachAssetsFromCollectionMutation__
 *
 * To run a mutation, you first call `useDetachAssetsFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetsFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetsFromCollectionMutation, { data, loading, error }] = useDetachAssetsFromCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetsFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetsFromCollectionMutation,
    DetachAssetsFromCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetsFromCollectionMutation,
    DetachAssetsFromCollectionMutationVariables
  >(DetachAssetsFromCollectionDocument, options);
}
export type DetachAssetsFromCollectionMutationHookResult = ReturnType<
  typeof useDetachAssetsFromCollectionMutation
>;
export type DetachAssetsFromCollectionMutationResult =
  Apollo.MutationResult<DetachAssetsFromCollectionMutation>;
export type DetachAssetsFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetsFromCollectionMutation,
  DetachAssetsFromCollectionMutationVariables
>;
export const ExportCollectionPdfDocument = gql`
  mutation ExportCollectionPdf($input: ExportCollectionPDFInput!) {
    exportCollectionPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportCollectionPdfMutationFn = Apollo.MutationFunction<
  ExportCollectionPdfMutation,
  ExportCollectionPdfMutationVariables
>;

/**
 * __useExportCollectionPdfMutation__
 *
 * To run a mutation, you first call `useExportCollectionPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCollectionPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCollectionPdfMutation, { data, loading, error }] = useExportCollectionPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportCollectionPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportCollectionPdfMutation,
    ExportCollectionPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportCollectionPdfMutation, ExportCollectionPdfMutationVariables>(
    ExportCollectionPdfDocument,
    options
  );
}
export type ExportCollectionPdfMutationHookResult = ReturnType<
  typeof useExportCollectionPdfMutation
>;
export type ExportCollectionPdfMutationResult = Apollo.MutationResult<ExportCollectionPdfMutation>;
export type ExportCollectionPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportCollectionPdfMutation,
  ExportCollectionPdfMutationVariables
>;
export const AddCollectionMediaDocument = gql`
  mutation AddCollectionMedia($input: AddCollectionMediaInput!) {
    addCollectionMedia(input: $input) {
      collection {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddCollectionMediaMutationFn = Apollo.MutationFunction<
  AddCollectionMediaMutation,
  AddCollectionMediaMutationVariables
>;

/**
 * __useAddCollectionMediaMutation__
 *
 * To run a mutation, you first call `useAddCollectionMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollectionMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollectionMediaMutation, { data, loading, error }] = useAddCollectionMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCollectionMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCollectionMediaMutation,
    AddCollectionMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCollectionMediaMutation, AddCollectionMediaMutationVariables>(
    AddCollectionMediaDocument,
    options
  );
}
export type AddCollectionMediaMutationHookResult = ReturnType<typeof useAddCollectionMediaMutation>;
export type AddCollectionMediaMutationResult = Apollo.MutationResult<AddCollectionMediaMutation>;
export type AddCollectionMediaMutationOptions = Apollo.BaseMutationOptions<
  AddCollectionMediaMutation,
  AddCollectionMediaMutationVariables
>;
export const DeleteCollectionMediaDocument = gql`
  mutation DeleteCollectionMedia($input: DeleteCollectionMediaInput!) {
    deleteCollectionMedia(input: $input) {
      collection {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteCollectionMediaMutationFn = Apollo.MutationFunction<
  DeleteCollectionMediaMutation,
  DeleteCollectionMediaMutationVariables
>;

/**
 * __useDeleteCollectionMediaMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMediaMutation, { data, loading, error }] = useDeleteCollectionMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollectionMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollectionMediaMutation,
    DeleteCollectionMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCollectionMediaMutation, DeleteCollectionMediaMutationVariables>(
    DeleteCollectionMediaDocument,
    options
  );
}
export type DeleteCollectionMediaMutationHookResult = ReturnType<
  typeof useDeleteCollectionMediaMutation
>;
export type DeleteCollectionMediaMutationResult =
  Apollo.MutationResult<DeleteCollectionMediaMutation>;
export type DeleteCollectionMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollectionMediaMutation,
  DeleteCollectionMediaMutationVariables
>;
export const DeleteCollectionImageDocument = gql`
  mutation DeleteCollectionImage($input: DeleteCollectionImageInput!) {
    deleteCollectionImage(input: $input) {
      collection {
        id
        name
      }
    }
  }
`;
export type DeleteCollectionImageMutationFn = Apollo.MutationFunction<
  DeleteCollectionImageMutation,
  DeleteCollectionImageMutationVariables
>;

/**
 * __useDeleteCollectionImageMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionImageMutation, { data, loading, error }] = useDeleteCollectionImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCollectionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollectionImageMutation,
    DeleteCollectionImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCollectionImageMutation, DeleteCollectionImageMutationVariables>(
    DeleteCollectionImageDocument,
    options
  );
}
export type DeleteCollectionImageMutationHookResult = ReturnType<
  typeof useDeleteCollectionImageMutation
>;
export type DeleteCollectionImageMutationResult =
  Apollo.MutationResult<DeleteCollectionImageMutation>;
export type DeleteCollectionImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollectionImageMutation,
  DeleteCollectionImageMutationVariables
>;
export const GetAllConditionStatusReportsDocument = gql`
  query GetAllConditionStatusReports {
    allConditionStatusReports(orderBy: "-created_at") {
      edges {
        node {
          ...ConditionStatusReportFindingFields
          createdAt
          createdBy {
            name
          }
        }
      }
    }
  }
  ${ConditionStatusReportFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllConditionStatusReportsQuery__
 *
 * To run a query within a React component, call `useGetAllConditionStatusReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllConditionStatusReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllConditionStatusReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllConditionStatusReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >(GetAllConditionStatusReportsDocument, options);
}
export function useGetAllConditionStatusReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllConditionStatusReportsQuery,
    GetAllConditionStatusReportsQueryVariables
  >(GetAllConditionStatusReportsDocument, options);
}
export type GetAllConditionStatusReportsQueryHookResult = ReturnType<
  typeof useGetAllConditionStatusReportsQuery
>;
export type GetAllConditionStatusReportsLazyQueryHookResult = ReturnType<
  typeof useGetAllConditionStatusReportsLazyQuery
>;
export type GetAllConditionStatusReportsQueryResult = Apollo.QueryResult<
  GetAllConditionStatusReportsQuery,
  GetAllConditionStatusReportsQueryVariables
>;
export const GetConditionStatusReportByIdDocument = gql`
  query GetConditionStatusReportById($id: ID!) {
    conditionStatusReport(id: $id) {
      ...ConditionStatusReportAnagraphicFields
    }
  }
  ${ConditionStatusReportAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetConditionStatusReportByIdQuery__
 *
 * To run a query within a React component, call `useGetConditionStatusReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConditionStatusReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConditionStatusReportByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConditionStatusReportByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >(GetConditionStatusReportByIdDocument, options);
}
export function useGetConditionStatusReportByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConditionStatusReportByIdQuery,
    GetConditionStatusReportByIdQueryVariables
  >(GetConditionStatusReportByIdDocument, options);
}
export type GetConditionStatusReportByIdQueryHookResult = ReturnType<
  typeof useGetConditionStatusReportByIdQuery
>;
export type GetConditionStatusReportByIdLazyQueryHookResult = ReturnType<
  typeof useGetConditionStatusReportByIdLazyQuery
>;
export type GetConditionStatusReportByIdQueryResult = Apollo.QueryResult<
  GetConditionStatusReportByIdQuery,
  GetConditionStatusReportByIdQueryVariables
>;
export const CreateConditionStatusReportDocument = gql`
  mutation CreateConditionStatusReport($input: CreateConditionStatusReportInput!) {
    createConditionStatusReport(input: $input) {
      conditionStatusReport {
        id
        reportStatus
        environmentalConditionsAbnormalHumidityEvent
        environmentalConditionsAbnormalLightEvent
        environmentalConditionsAbnormalTemperatureEvent
        environmentalConditionsLightUnit
        environmentalConditionsOptimalHumidity
        environmentalConditionsOptimalLightLevelIntensity
        environmentalConditionsOptimalTemperature
        environmentalConditionsTemperatureUnit
        exhibitionCurator {
          id
          fullName
        }
        exhibitionEventFrom
        exhibitionEventTo
        exhibitionHostEntity {
          id
          fullName
        }
        exhibitionLender {
          id
          fullName
        }
        exhibitionLoanFrom
        exhibitionLoanTo
        exhibitionLocation
        collectionPublicName
        exhibitionNotes
        exhibitionPlanner {
          id
          fullName
        }
        exhibitionPermanent
        exhibitionTitle
        logisticsAirConditioningTransportConditions
        logisticsCarrier {
          id
          fullName
        }
        logisticsDeliveryLocation {
          raw
          locality
          state
          country
          streetNumber
          route
        }
        logisticsDeliveryAddress
        logisticsDeliveryCity
        logisticsDeliveryCountry
        logisticsDeliveryDate
        logisticsDeliveryState
        logisticsDeliveryZip
        logisticsId
        logisticsNumberOfPackages
        logisticsPickupLocation {
          raw
          locality
          state
          country
          streetNumber
          route
        }
        logisticsPickupAddress
        logisticsPickupCity
        logisticsPickupCountry
        logisticsPickupDate
        logisticsPickupState
        logisticsPickupZip
        logisticsRecipient {
          id
          fullName
        }
        logisticsSender {
          id
          fullName
        }
        reportCompany {
          id
          fullName
        }
        reportDate
        reportDetailsAssetCondition
        reportDetailsAssetDamageDescription
        reportDetailsExamConditions
        reportDetailsExamConditionsNote
        reportDetailsExamLocation
        reportDetailsExhaminationTimeTechnicalDescription
        reportDetailsPackagingGeneralConditions
        reportDetailsExaminedPackages
        reportDetailsPackagingStatusDescription
        reportDetailsProtectionsSupportsCondition
        reportDetailsProtectionsSupportsDamageDescription
        reportExaminationType
        reportExaminer {
          id
          fullName
        }
        reportMotivation
        reportMotivationDescription
        reportType
        reportAsset {
          id
          title
          actualDateAndPeriod
        }
        settingsConservation
        settingsExaminerComments
        settingsHandlingRequirements
        settingsHasBarriers
        settingsInjuryRisk
        settingsLenderRequests
        settingsNotes
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValueExaminationTime {
          amount
          currency {
            code
          }
        }
        valuePercentageDepreciation
      }
    }
  }
`;
export type CreateConditionStatusReportMutationFn = Apollo.MutationFunction<
  CreateConditionStatusReportMutation,
  CreateConditionStatusReportMutationVariables
>;

/**
 * __useCreateConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useCreateConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConditionStatusReportMutation, { data, loading, error }] = useCreateConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConditionStatusReportMutation,
    CreateConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConditionStatusReportMutation,
    CreateConditionStatusReportMutationVariables
  >(CreateConditionStatusReportDocument, options);
}
export type CreateConditionStatusReportMutationHookResult = ReturnType<
  typeof useCreateConditionStatusReportMutation
>;
export type CreateConditionStatusReportMutationResult =
  Apollo.MutationResult<CreateConditionStatusReportMutation>;
export type CreateConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  CreateConditionStatusReportMutation,
  CreateConditionStatusReportMutationVariables
>;
export const UpdateConditionStatusReportDocument = gql`
  mutation UpdateConditionStatusReport($input: UpdateConditionStatusReportInput!) {
    updateConditionStatusReport(input: $input) {
      conditionStatusReport {
        id
        reportStatus
        environmentalConditionsAbnormalHumidityEvent
        environmentalConditionsAbnormalLightEvent
        environmentalConditionsAbnormalTemperatureEvent
        environmentalConditionsLightUnit
        environmentalConditionsOptimalHumidity
        environmentalConditionsOptimalLightLevelIntensity
        environmentalConditionsOptimalTemperature
        environmentalConditionsTemperatureUnit
        exhibitionCurator {
          id
          fullName
        }
        exhibitionEventFrom
        exhibitionEventTo
        exhibitionHostEntity {
          id
          fullName
        }
        exhibitionLender {
          id
          fullName
        }
        exhibitionLoanFrom
        exhibitionLoanTo
        exhibitionLocation
        collectionPublicName
        exhibitionNotes
        exhibitionPlanner {
          id
          fullName
        }
        exhibitionPermanent
        exhibitionTitle
        logisticsAirConditioningTransportConditions
        logisticsCarrier {
          id
          fullName
        }
        logisticsDeliveryAddress
        logisticsDeliveryCity
        logisticsDeliveryCountry
        logisticsDeliveryDate
        logisticsDeliveryState
        logisticsDeliveryZip
        logisticsId
        logisticsNumberOfPackages
        logisticsPickupAddress
        logisticsPickupCity
        logisticsPickupCountry
        logisticsPickupDate
        logisticsPickupState
        logisticsPickupZip
        logisticsRecipient {
          id
          fullName
        }
        logisticsSender {
          id
          fullName
        }
        reportCompany {
          id
          fullName
        }
        reportDate
        reportDetailsAssetCondition
        reportDetailsAssetDamageDescription
        reportDetailsExamConditions
        reportDetailsExamConditionsNote
        reportDetailsExamLocation
        reportDetailsExhaminationTimeTechnicalDescription
        reportDetailsPackagingGeneralConditions
        reportDetailsExaminedPackages
        reportDetailsPackagingStatusDescription
        reportDetailsProtectionsSupportsCondition
        reportDetailsProtectionsSupportsDamageDescription
        reportExaminationType
        reportExaminer {
          id
          fullName
        }
        reportMotivation
        reportMotivationDescription
        reportType
        reportAsset {
          id
          title
          actualDateAndPeriod
        }
        settingsConservation
        settingsExaminerComments
        settingsHandlingRequirements
        settingsHasBarriers
        settingsInjuryRisk
        settingsLenderRequests
        settingsNotes
        valueEstimatedValue {
          amount
          currency {
            code
          }
        }
        valueEstimatedValueExaminationTime {
          amount
          currency {
            code
          }
        }
        valuePercentageDepreciation
      }
    }
  }
`;
export type UpdateConditionStatusReportMutationFn = Apollo.MutationFunction<
  UpdateConditionStatusReportMutation,
  UpdateConditionStatusReportMutationVariables
>;

/**
 * __useUpdateConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useUpdateConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConditionStatusReportMutation, { data, loading, error }] = useUpdateConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConditionStatusReportMutation,
    UpdateConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConditionStatusReportMutation,
    UpdateConditionStatusReportMutationVariables
  >(UpdateConditionStatusReportDocument, options);
}
export type UpdateConditionStatusReportMutationHookResult = ReturnType<
  typeof useUpdateConditionStatusReportMutation
>;
export type UpdateConditionStatusReportMutationResult =
  Apollo.MutationResult<UpdateConditionStatusReportMutation>;
export type UpdateConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateConditionStatusReportMutation,
  UpdateConditionStatusReportMutationVariables
>;
export const DeleteConditionStatusReportDocument = gql`
  mutation DeleteConditionStatusReport($input: DeleteConditionStatusReportInput!) {
    deleteConditionStatusReport(input: $input) {
      deleteConditionStatusReport {
        id
      }
    }
  }
`;
export type DeleteConditionStatusReportMutationFn = Apollo.MutationFunction<
  DeleteConditionStatusReportMutation,
  DeleteConditionStatusReportMutationVariables
>;

/**
 * __useDeleteConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useDeleteConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionStatusReportMutation, { data, loading, error }] = useDeleteConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConditionStatusReportMutation,
    DeleteConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConditionStatusReportMutation,
    DeleteConditionStatusReportMutationVariables
  >(DeleteConditionStatusReportDocument, options);
}
export type DeleteConditionStatusReportMutationHookResult = ReturnType<
  typeof useDeleteConditionStatusReportMutation
>;
export type DeleteConditionStatusReportMutationResult =
  Apollo.MutationResult<DeleteConditionStatusReportMutation>;
export type DeleteConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteConditionStatusReportMutation,
  DeleteConditionStatusReportMutationVariables
>;
export const CloseConditionStatusReportDocument = gql`
  mutation CloseConditionStatusReport($input: CloseConditionStatusReportInput!) {
    closeConditionStatusReport(input: $input) {
      closeConditionStatusReport {
        id
      }
    }
  }
`;
export type CloseConditionStatusReportMutationFn = Apollo.MutationFunction<
  CloseConditionStatusReportMutation,
  CloseConditionStatusReportMutationVariables
>;

/**
 * __useCloseConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useCloseConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeConditionStatusReportMutation, { data, loading, error }] = useCloseConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseConditionStatusReportMutation,
    CloseConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseConditionStatusReportMutation,
    CloseConditionStatusReportMutationVariables
  >(CloseConditionStatusReportDocument, options);
}
export type CloseConditionStatusReportMutationHookResult = ReturnType<
  typeof useCloseConditionStatusReportMutation
>;
export type CloseConditionStatusReportMutationResult =
  Apollo.MutationResult<CloseConditionStatusReportMutation>;
export type CloseConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  CloseConditionStatusReportMutation,
  CloseConditionStatusReportMutationVariables
>;
export const DetachAssetFromConditionStatusReportDocument = gql`
  mutation DetachAssetFromConditionStatusReport(
    $input: DetachAssetFromConditionStatusReportInput!
  ) {
    detachAssetFromConditionStatusReport(input: $input) {
      conditionStatusReport {
        id
      }
    }
  }
`;
export type DetachAssetFromConditionStatusReportMutationFn = Apollo.MutationFunction<
  DetachAssetFromConditionStatusReportMutation,
  DetachAssetFromConditionStatusReportMutationVariables
>;

/**
 * __useDetachAssetFromConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromConditionStatusReportMutation, { data, loading, error }] = useDetachAssetFromConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromConditionStatusReportMutation,
    DetachAssetFromConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromConditionStatusReportMutation,
    DetachAssetFromConditionStatusReportMutationVariables
  >(DetachAssetFromConditionStatusReportDocument, options);
}
export type DetachAssetFromConditionStatusReportMutationHookResult = ReturnType<
  typeof useDetachAssetFromConditionStatusReportMutation
>;
export type DetachAssetFromConditionStatusReportMutationResult =
  Apollo.MutationResult<DetachAssetFromConditionStatusReportMutation>;
export type DetachAssetFromConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromConditionStatusReportMutation,
  DetachAssetFromConditionStatusReportMutationVariables
>;
export const GetMediaConditionStatusReportByIdDocument = gql`
  query GetMediaConditionStatusReportById($id: ID!) {
    conditionStatusReport(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaConditionStatusReportByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaConditionStatusReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaConditionStatusReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaConditionStatusReportByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaConditionStatusReportByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >(GetMediaConditionStatusReportByIdDocument, options);
}
export function useGetMediaConditionStatusReportByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaConditionStatusReportByIdQuery,
    GetMediaConditionStatusReportByIdQueryVariables
  >(GetMediaConditionStatusReportByIdDocument, options);
}
export type GetMediaConditionStatusReportByIdQueryHookResult = ReturnType<
  typeof useGetMediaConditionStatusReportByIdQuery
>;
export type GetMediaConditionStatusReportByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaConditionStatusReportByIdLazyQuery
>;
export type GetMediaConditionStatusReportByIdQueryResult = Apollo.QueryResult<
  GetMediaConditionStatusReportByIdQuery,
  GetMediaConditionStatusReportByIdQueryVariables
>;
export const AddConditionStatusReportMediaDocument = gql`
  mutation AddConditionStatusReportMedia($input: AddConditionStatusReportMediaInput!) {
    addConditionStatusReportMedia(input: $input) {
      conditionStatusReport {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddConditionStatusReportMediaMutationFn = Apollo.MutationFunction<
  AddConditionStatusReportMediaMutation,
  AddConditionStatusReportMediaMutationVariables
>;

/**
 * __useAddConditionStatusReportMediaMutation__
 *
 * To run a mutation, you first call `useAddConditionStatusReportMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConditionStatusReportMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConditionStatusReportMediaMutation, { data, loading, error }] = useAddConditionStatusReportMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConditionStatusReportMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddConditionStatusReportMediaMutation,
    AddConditionStatusReportMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddConditionStatusReportMediaMutation,
    AddConditionStatusReportMediaMutationVariables
  >(AddConditionStatusReportMediaDocument, options);
}
export type AddConditionStatusReportMediaMutationHookResult = ReturnType<
  typeof useAddConditionStatusReportMediaMutation
>;
export type AddConditionStatusReportMediaMutationResult =
  Apollo.MutationResult<AddConditionStatusReportMediaMutation>;
export type AddConditionStatusReportMediaMutationOptions = Apollo.BaseMutationOptions<
  AddConditionStatusReportMediaMutation,
  AddConditionStatusReportMediaMutationVariables
>;
export const AddMultipleCrsrMediaDocument = gql`
  mutation AddMultipleCrsrMedia($input: AddMultipleCrsrMediaInput!) {
    addMultipleCrsrMedia(input: $input) {
      conditionStatusReport {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddMultipleCrsrMediaMutationFn = Apollo.MutationFunction<
  AddMultipleCrsrMediaMutation,
  AddMultipleCrsrMediaMutationVariables
>;

/**
 * __useAddMultipleCrsrMediaMutation__
 *
 * To run a mutation, you first call `useAddMultipleCrsrMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultipleCrsrMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultipleCrsrMediaMutation, { data, loading, error }] = useAddMultipleCrsrMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMultipleCrsrMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMultipleCrsrMediaMutation,
    AddMultipleCrsrMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMultipleCrsrMediaMutation, AddMultipleCrsrMediaMutationVariables>(
    AddMultipleCrsrMediaDocument,
    options
  );
}
export type AddMultipleCrsrMediaMutationHookResult = ReturnType<
  typeof useAddMultipleCrsrMediaMutation
>;
export type AddMultipleCrsrMediaMutationResult =
  Apollo.MutationResult<AddMultipleCrsrMediaMutation>;
export type AddMultipleCrsrMediaMutationOptions = Apollo.BaseMutationOptions<
  AddMultipleCrsrMediaMutation,
  AddMultipleCrsrMediaMutationVariables
>;
export const DeleteConditionStatusReportMediaDocument = gql`
  mutation DeleteConditionStatusReportMedia($input: DeleteConditionStatusReportMediaInput!) {
    deleteConditionStatusReportMedia(input: $input) {
      conditionStatusReport {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteConditionStatusReportMediaMutationFn = Apollo.MutationFunction<
  DeleteConditionStatusReportMediaMutation,
  DeleteConditionStatusReportMediaMutationVariables
>;

/**
 * __useDeleteConditionStatusReportMediaMutation__
 *
 * To run a mutation, you first call `useDeleteConditionStatusReportMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConditionStatusReportMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConditionStatusReportMediaMutation, { data, loading, error }] = useDeleteConditionStatusReportMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConditionStatusReportMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConditionStatusReportMediaMutation,
    DeleteConditionStatusReportMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConditionStatusReportMediaMutation,
    DeleteConditionStatusReportMediaMutationVariables
  >(DeleteConditionStatusReportMediaDocument, options);
}
export type DeleteConditionStatusReportMediaMutationHookResult = ReturnType<
  typeof useDeleteConditionStatusReportMediaMutation
>;
export type DeleteConditionStatusReportMediaMutationResult =
  Apollo.MutationResult<DeleteConditionStatusReportMediaMutation>;
export type DeleteConditionStatusReportMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteConditionStatusReportMediaMutation,
  DeleteConditionStatusReportMediaMutationVariables
>;
export const ExportConditionStatusReportPdfDocument = gql`
  mutation ExportConditionStatusReportPdf($input: ExportConditionStatusReportPDFInput!) {
    exportConditionStatusReportPdf(input: $input) {
      clientMutationId
      url
      lang
    }
  }
`;
export type ExportConditionStatusReportPdfMutationFn = Apollo.MutationFunction<
  ExportConditionStatusReportPdfMutation,
  ExportConditionStatusReportPdfMutationVariables
>;

/**
 * __useExportConditionStatusReportPdfMutation__
 *
 * To run a mutation, you first call `useExportConditionStatusReportPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportConditionStatusReportPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportConditionStatusReportPdfMutation, { data, loading, error }] = useExportConditionStatusReportPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportConditionStatusReportPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportConditionStatusReportPdfMutation,
    ExportConditionStatusReportPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportConditionStatusReportPdfMutation,
    ExportConditionStatusReportPdfMutationVariables
  >(ExportConditionStatusReportPdfDocument, options);
}
export type ExportConditionStatusReportPdfMutationHookResult = ReturnType<
  typeof useExportConditionStatusReportPdfMutation
>;
export type ExportConditionStatusReportPdfMutationResult =
  Apollo.MutationResult<ExportConditionStatusReportPdfMutation>;
export type ExportConditionStatusReportPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportConditionStatusReportPdfMutation,
  ExportConditionStatusReportPdfMutationVariables
>;
export const DuplicateConditionStatusReportDocument = gql`
  mutation DuplicateConditionStatusReport($input: DuplicateConditionStatusReportInput!) {
    duplicateConditionStatusReport(input: $input) {
      crsr {
        id
      }
    }
  }
`;
export type DuplicateConditionStatusReportMutationFn = Apollo.MutationFunction<
  DuplicateConditionStatusReportMutation,
  DuplicateConditionStatusReportMutationVariables
>;

/**
 * __useDuplicateConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useDuplicateConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateConditionStatusReportMutation, { data, loading, error }] = useDuplicateConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateConditionStatusReportMutation,
    DuplicateConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateConditionStatusReportMutation,
    DuplicateConditionStatusReportMutationVariables
  >(DuplicateConditionStatusReportDocument, options);
}
export type DuplicateConditionStatusReportMutationHookResult = ReturnType<
  typeof useDuplicateConditionStatusReportMutation
>;
export type DuplicateConditionStatusReportMutationResult =
  Apollo.MutationResult<DuplicateConditionStatusReportMutation>;
export type DuplicateConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  DuplicateConditionStatusReportMutation,
  DuplicateConditionStatusReportMutationVariables
>;
export const SignConditionStatusReportDocument = gql`
  mutation SignConditionStatusReport($input: SignConditionStatusReportInput!) {
    signConditionStatusReport(input: $input) {
      crsr {
        id
      }
    }
  }
`;
export type SignConditionStatusReportMutationFn = Apollo.MutationFunction<
  SignConditionStatusReportMutation,
  SignConditionStatusReportMutationVariables
>;

/**
 * __useSignConditionStatusReportMutation__
 *
 * To run a mutation, you first call `useSignConditionStatusReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignConditionStatusReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signConditionStatusReportMutation, { data, loading, error }] = useSignConditionStatusReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignConditionStatusReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignConditionStatusReportMutation,
    SignConditionStatusReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignConditionStatusReportMutation,
    SignConditionStatusReportMutationVariables
  >(SignConditionStatusReportDocument, options);
}
export type SignConditionStatusReportMutationHookResult = ReturnType<
  typeof useSignConditionStatusReportMutation
>;
export type SignConditionStatusReportMutationResult =
  Apollo.MutationResult<SignConditionStatusReportMutation>;
export type SignConditionStatusReportMutationOptions = Apollo.BaseMutationOptions<
  SignConditionStatusReportMutation,
  SignConditionStatusReportMutationVariables
>;
export const GetPortfolioDistributionDocument = gql`
  query GetPortfolioDistribution {
    portfolioDistribution {
      category
      percentage
      count
      value
    }
  }
`;

/**
 * __useGetPortfolioDistributionQuery__
 *
 * To run a query within a React component, call `useGetPortfolioDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioDistributionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPortfolioDistributionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPortfolioDistributionQuery,
    GetPortfolioDistributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPortfolioDistributionQuery, GetPortfolioDistributionQueryVariables>(
    GetPortfolioDistributionDocument,
    options
  );
}
export function useGetPortfolioDistributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioDistributionQuery,
    GetPortfolioDistributionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPortfolioDistributionQuery, GetPortfolioDistributionQueryVariables>(
    GetPortfolioDistributionDocument,
    options
  );
}
export type GetPortfolioDistributionQueryHookResult = ReturnType<
  typeof useGetPortfolioDistributionQuery
>;
export type GetPortfolioDistributionLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioDistributionLazyQuery
>;
export type GetPortfolioDistributionQueryResult = Apollo.QueryResult<
  GetPortfolioDistributionQuery,
  GetPortfolioDistributionQueryVariables
>;
export const GetLastXUserActionsDocument = gql`
  query GetLastXUserActions($userId: ID!) {
    user(id: $userId) {
      useractionsSet(last: 5) {
        edges {
          node {
            actionTime
            actionType
            createdAt
            contentObject {
              modelName
              id
              appLabel
            }
            createdAt
            meta
          }
        }
      }
    }
  }
`;

/**
 * __useGetLastXUserActionsQuery__
 *
 * To run a query within a React component, call `useGetLastXUserActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastXUserActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastXUserActionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLastXUserActionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLastXUserActionsQuery, GetLastXUserActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastXUserActionsQuery, GetLastXUserActionsQueryVariables>(
    GetLastXUserActionsDocument,
    options
  );
}
export function useGetLastXUserActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastXUserActionsQuery,
    GetLastXUserActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastXUserActionsQuery, GetLastXUserActionsQueryVariables>(
    GetLastXUserActionsDocument,
    options
  );
}
export type GetLastXUserActionsQueryHookResult = ReturnType<typeof useGetLastXUserActionsQuery>;
export type GetLastXUserActionsLazyQueryHookResult = ReturnType<
  typeof useGetLastXUserActionsLazyQuery
>;
export type GetLastXUserActionsQueryResult = Apollo.QueryResult<
  GetLastXUserActionsQuery,
  GetLastXUserActionsQueryVariables
>;
export const GetAssetsInsuranceStatusDocument = gql`
  query GetAssetsInsuranceStatus {
    assetsInsuranceStatus {
      count
      percentage
      status
      color
    }
  }
`;

/**
 * __useGetAssetsInsuranceStatusQuery__
 *
 * To run a query within a React component, call `useGetAssetsInsuranceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsInsuranceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsInsuranceStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssetsInsuranceStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAssetsInsuranceStatusQuery,
    GetAssetsInsuranceStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetsInsuranceStatusQuery, GetAssetsInsuranceStatusQueryVariables>(
    GetAssetsInsuranceStatusDocument,
    options
  );
}
export function useGetAssetsInsuranceStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetsInsuranceStatusQuery,
    GetAssetsInsuranceStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetsInsuranceStatusQuery, GetAssetsInsuranceStatusQueryVariables>(
    GetAssetsInsuranceStatusDocument,
    options
  );
}
export type GetAssetsInsuranceStatusQueryHookResult = ReturnType<
  typeof useGetAssetsInsuranceStatusQuery
>;
export type GetAssetsInsuranceStatusLazyQueryHookResult = ReturnType<
  typeof useGetAssetsInsuranceStatusLazyQuery
>;
export type GetAssetsInsuranceStatusQueryResult = Apollo.QueryResult<
  GetAssetsInsuranceStatusQuery,
  GetAssetsInsuranceStatusQueryVariables
>;
export const GetPoliciesExpiringInXDaysDocument = gql`
  query GetPoliciesExpiringInXDays($expiresAt_Gt: DateTime!, $expiresAt_Lt: DateTime!) {
    allPolicies(first: 0, expiresAt_Gt: $expiresAt_Gt, expiresAt_Lt: $expiresAt_Lt) {
      totalCount
    }
  }
`;

/**
 * __useGetPoliciesExpiringInXDaysQuery__
 *
 * To run a query within a React component, call `useGetPoliciesExpiringInXDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoliciesExpiringInXDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoliciesExpiringInXDaysQuery({
 *   variables: {
 *      expiresAt_Gt: // value for 'expiresAt_Gt'
 *      expiresAt_Lt: // value for 'expiresAt_Lt'
 *   },
 * });
 */
export function useGetPoliciesExpiringInXDaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPoliciesExpiringInXDaysQuery,
    GetPoliciesExpiringInXDaysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPoliciesExpiringInXDaysQuery, GetPoliciesExpiringInXDaysQueryVariables>(
    GetPoliciesExpiringInXDaysDocument,
    options
  );
}
export function useGetPoliciesExpiringInXDaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPoliciesExpiringInXDaysQuery,
    GetPoliciesExpiringInXDaysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPoliciesExpiringInXDaysQuery,
    GetPoliciesExpiringInXDaysQueryVariables
  >(GetPoliciesExpiringInXDaysDocument, options);
}
export type GetPoliciesExpiringInXDaysQueryHookResult = ReturnType<
  typeof useGetPoliciesExpiringInXDaysQuery
>;
export type GetPoliciesExpiringInXDaysLazyQueryHookResult = ReturnType<
  typeof useGetPoliciesExpiringInXDaysLazyQuery
>;
export type GetPoliciesExpiringInXDaysQueryResult = Apollo.QueryResult<
  GetPoliciesExpiringInXDaysQuery,
  GetPoliciesExpiringInXDaysQueryVariables
>;
export const GetLastAssetsCreatedByDocument = gql`
  query GetLastAssetsCreatedBy($userId: ID!) {
    allAssetsForUser(first: 5, orderBy: "-created_at", userId: $userId) {
      edges {
        node {
          id
          availability
          title
          category
          subcategory
          author
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetLastAssetsCreatedByQuery__
 *
 * To run a query within a React component, call `useGetLastAssetsCreatedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastAssetsCreatedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastAssetsCreatedByQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetLastAssetsCreatedByQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastAssetsCreatedByQuery,
    GetLastAssetsCreatedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastAssetsCreatedByQuery, GetLastAssetsCreatedByQueryVariables>(
    GetLastAssetsCreatedByDocument,
    options
  );
}
export function useGetLastAssetsCreatedByLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastAssetsCreatedByQuery,
    GetLastAssetsCreatedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastAssetsCreatedByQuery, GetLastAssetsCreatedByQueryVariables>(
    GetLastAssetsCreatedByDocument,
    options
  );
}
export type GetLastAssetsCreatedByQueryHookResult = ReturnType<
  typeof useGetLastAssetsCreatedByQuery
>;
export type GetLastAssetsCreatedByLazyQueryHookResult = ReturnType<
  typeof useGetLastAssetsCreatedByLazyQuery
>;
export type GetLastAssetsCreatedByQueryResult = Apollo.QueryResult<
  GetLastAssetsCreatedByQuery,
  GetLastAssetsCreatedByQueryVariables
>;
export const GetLastPcPoliciesCreatedByDocument = gql`
  query GetLastPCPoliciesCreatedBy($createdBy: ID!) {
    allPolicies(createdBy: $createdBy, policyType: "PERMANENT_COLLECTION") {
      totalCount
    }
  }
`;

/**
 * __useGetLastPcPoliciesCreatedByQuery__
 *
 * To run a query within a React component, call `useGetLastPcPoliciesCreatedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPcPoliciesCreatedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPcPoliciesCreatedByQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetLastPcPoliciesCreatedByQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastPcPoliciesCreatedByQuery,
    GetLastPcPoliciesCreatedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastPcPoliciesCreatedByQuery, GetLastPcPoliciesCreatedByQueryVariables>(
    GetLastPcPoliciesCreatedByDocument,
    options
  );
}
export function useGetLastPcPoliciesCreatedByLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastPcPoliciesCreatedByQuery,
    GetLastPcPoliciesCreatedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastPcPoliciesCreatedByQuery,
    GetLastPcPoliciesCreatedByQueryVariables
  >(GetLastPcPoliciesCreatedByDocument, options);
}
export type GetLastPcPoliciesCreatedByQueryHookResult = ReturnType<
  typeof useGetLastPcPoliciesCreatedByQuery
>;
export type GetLastPcPoliciesCreatedByLazyQueryHookResult = ReturnType<
  typeof useGetLastPcPoliciesCreatedByLazyQuery
>;
export type GetLastPcPoliciesCreatedByQueryResult = Apollo.QueryResult<
  GetLastPcPoliciesCreatedByQuery,
  GetLastPcPoliciesCreatedByQueryVariables
>;
export const GetLastTePoliciesCreatedByDocument = gql`
  query GetLastTEPoliciesCreatedBy($createdBy: ID!) {
    allPolicies(createdBy: $createdBy, policyType: "TEMPORARY_EXHIBITION") {
      totalCount
    }
  }
`;

/**
 * __useGetLastTePoliciesCreatedByQuery__
 *
 * To run a query within a React component, call `useGetLastTePoliciesCreatedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastTePoliciesCreatedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastTePoliciesCreatedByQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetLastTePoliciesCreatedByQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastTePoliciesCreatedByQuery,
    GetLastTePoliciesCreatedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastTePoliciesCreatedByQuery, GetLastTePoliciesCreatedByQueryVariables>(
    GetLastTePoliciesCreatedByDocument,
    options
  );
}
export function useGetLastTePoliciesCreatedByLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastTePoliciesCreatedByQuery,
    GetLastTePoliciesCreatedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastTePoliciesCreatedByQuery,
    GetLastTePoliciesCreatedByQueryVariables
  >(GetLastTePoliciesCreatedByDocument, options);
}
export type GetLastTePoliciesCreatedByQueryHookResult = ReturnType<
  typeof useGetLastTePoliciesCreatedByQuery
>;
export type GetLastTePoliciesCreatedByLazyQueryHookResult = ReturnType<
  typeof useGetLastTePoliciesCreatedByLazyQuery
>;
export type GetLastTePoliciesCreatedByQueryResult = Apollo.QueryResult<
  GetLastTePoliciesCreatedByQuery,
  GetLastTePoliciesCreatedByQueryVariables
>;
export const GetInternalUsersCountDocument = gql`
  query GetInternalUsersCount($userType: String) {
    allUsers(userType: $userType, isStaff: false, isActive: true) {
      totalCount
    }
  }
`;

/**
 * __useGetInternalUsersCountQuery__
 *
 * To run a query within a React component, call `useGetInternalUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalUsersCountQuery({
 *   variables: {
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useGetInternalUsersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInternalUsersCountQuery,
    GetInternalUsersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInternalUsersCountQuery, GetInternalUsersCountQueryVariables>(
    GetInternalUsersCountDocument,
    options
  );
}
export function useGetInternalUsersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInternalUsersCountQuery,
    GetInternalUsersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInternalUsersCountQuery, GetInternalUsersCountQueryVariables>(
    GetInternalUsersCountDocument,
    options
  );
}
export type GetInternalUsersCountQueryHookResult = ReturnType<typeof useGetInternalUsersCountQuery>;
export type GetInternalUsersCountLazyQueryHookResult = ReturnType<
  typeof useGetInternalUsersCountLazyQuery
>;
export type GetInternalUsersCountQueryResult = Apollo.QueryResult<
  GetInternalUsersCountQuery,
  GetInternalUsersCountQueryVariables
>;
export const GetUsedInvitesDocument = gql`
  query GetUsedInvites {
    client(id: "") {
      usedInvites
      tierSpecs {
        maxInvites
      }
    }
  }
`;

/**
 * __useGetUsedInvitesQuery__
 *
 * To run a query within a React component, call `useGetUsedInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsedInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsedInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsedInvitesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsedInvitesQuery, GetUsedInvitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsedInvitesQuery, GetUsedInvitesQueryVariables>(
    GetUsedInvitesDocument,
    options
  );
}
export function useGetUsedInvitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsedInvitesQuery, GetUsedInvitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsedInvitesQuery, GetUsedInvitesQueryVariables>(
    GetUsedInvitesDocument,
    options
  );
}
export type GetUsedInvitesQueryHookResult = ReturnType<typeof useGetUsedInvitesQuery>;
export type GetUsedInvitesLazyQueryHookResult = ReturnType<typeof useGetUsedInvitesLazyQuery>;
export type GetUsedInvitesQueryResult = Apollo.QueryResult<
  GetUsedInvitesQuery,
  GetUsedInvitesQueryVariables
>;
export const GetCollectionsCreatedByCountDocument = gql`
  query GetCollectionsCreatedByCount($createdBy: ID!) {
    allCollections(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetCollectionsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetCollectionsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetCollectionsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollectionsCreatedByCountQuery,
    GetCollectionsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollectionsCreatedByCountQuery,
    GetCollectionsCreatedByCountQueryVariables
  >(GetCollectionsCreatedByCountDocument, options);
}
export function useGetCollectionsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollectionsCreatedByCountQuery,
    GetCollectionsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollectionsCreatedByCountQuery,
    GetCollectionsCreatedByCountQueryVariables
  >(GetCollectionsCreatedByCountDocument, options);
}
export type GetCollectionsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetCollectionsCreatedByCountQuery
>;
export type GetCollectionsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetCollectionsCreatedByCountLazyQuery
>;
export type GetCollectionsCreatedByCountQueryResult = Apollo.QueryResult<
  GetCollectionsCreatedByCountQuery,
  GetCollectionsCreatedByCountQueryVariables
>;
export const GetExhibitionsCreatedByCountDocument = gql`
  query GetExhibitionsCreatedByCount($createdBy: ID!) {
    allExhibitions(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetExhibitionsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetExhibitionsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitionsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitionsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetExhibitionsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExhibitionsCreatedByCountQuery,
    GetExhibitionsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExhibitionsCreatedByCountQuery,
    GetExhibitionsCreatedByCountQueryVariables
  >(GetExhibitionsCreatedByCountDocument, options);
}
export function useGetExhibitionsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExhibitionsCreatedByCountQuery,
    GetExhibitionsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExhibitionsCreatedByCountQuery,
    GetExhibitionsCreatedByCountQueryVariables
  >(GetExhibitionsCreatedByCountDocument, options);
}
export type GetExhibitionsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetExhibitionsCreatedByCountQuery
>;
export type GetExhibitionsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetExhibitionsCreatedByCountLazyQuery
>;
export type GetExhibitionsCreatedByCountQueryResult = Apollo.QueryResult<
  GetExhibitionsCreatedByCountQuery,
  GetExhibitionsCreatedByCountQueryVariables
>;
export const GetCrsRsCreatedByCountDocument = gql`
  query GetCRSRsCreatedByCount($createdBy: ID!) {
    allConditionStatusReports(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetCrsRsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetCrsRsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrsRsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrsRsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetCrsRsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCrsRsCreatedByCountQuery,
    GetCrsRsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCrsRsCreatedByCountQuery, GetCrsRsCreatedByCountQueryVariables>(
    GetCrsRsCreatedByCountDocument,
    options
  );
}
export function useGetCrsRsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrsRsCreatedByCountQuery,
    GetCrsRsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCrsRsCreatedByCountQuery, GetCrsRsCreatedByCountQueryVariables>(
    GetCrsRsCreatedByCountDocument,
    options
  );
}
export type GetCrsRsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetCrsRsCreatedByCountQuery
>;
export type GetCrsRsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetCrsRsCreatedByCountLazyQuery
>;
export type GetCrsRsCreatedByCountQueryResult = Apollo.QueryResult<
  GetCrsRsCreatedByCountQuery,
  GetCrsRsCreatedByCountQueryVariables
>;
export const GetAppraisalsCreatedByCountDocument = gql`
  query GetAppraisalsCreatedByCount($createdBy: ID!) {
    allAppraisals(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetAppraisalsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetAppraisalsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppraisalsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppraisalsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetAppraisalsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppraisalsCreatedByCountQuery,
    GetAppraisalsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppraisalsCreatedByCountQuery,
    GetAppraisalsCreatedByCountQueryVariables
  >(GetAppraisalsCreatedByCountDocument, options);
}
export function useGetAppraisalsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppraisalsCreatedByCountQuery,
    GetAppraisalsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppraisalsCreatedByCountQuery,
    GetAppraisalsCreatedByCountQueryVariables
  >(GetAppraisalsCreatedByCountDocument, options);
}
export type GetAppraisalsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetAppraisalsCreatedByCountQuery
>;
export type GetAppraisalsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetAppraisalsCreatedByCountLazyQuery
>;
export type GetAppraisalsCreatedByCountQueryResult = Apollo.QueryResult<
  GetAppraisalsCreatedByCountQuery,
  GetAppraisalsCreatedByCountQueryVariables
>;
export const GetRestorationsCreatedByCountDocument = gql`
  query GetRestorationsCreatedByCount($createdBy: ID!) {
    allRestorations(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetRestorationsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetRestorationsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestorationsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestorationsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetRestorationsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRestorationsCreatedByCountQuery,
    GetRestorationsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRestorationsCreatedByCountQuery,
    GetRestorationsCreatedByCountQueryVariables
  >(GetRestorationsCreatedByCountDocument, options);
}
export function useGetRestorationsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestorationsCreatedByCountQuery,
    GetRestorationsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRestorationsCreatedByCountQuery,
    GetRestorationsCreatedByCountQueryVariables
  >(GetRestorationsCreatedByCountDocument, options);
}
export type GetRestorationsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetRestorationsCreatedByCountQuery
>;
export type GetRestorationsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetRestorationsCreatedByCountLazyQuery
>;
export type GetRestorationsCreatedByCountQueryResult = Apollo.QueryResult<
  GetRestorationsCreatedByCountQuery,
  GetRestorationsCreatedByCountQueryVariables
>;
export const GetDepositationsCreatedByCountDocument = gql`
  query GetDepositationsCreatedByCount($createdBy: ID!) {
    allDepositations(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetDepositationsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetDepositationsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositationsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositationsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetDepositationsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDepositationsCreatedByCountQuery,
    GetDepositationsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDepositationsCreatedByCountQuery,
    GetDepositationsCreatedByCountQueryVariables
  >(GetDepositationsCreatedByCountDocument, options);
}
export function useGetDepositationsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepositationsCreatedByCountQuery,
    GetDepositationsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDepositationsCreatedByCountQuery,
    GetDepositationsCreatedByCountQueryVariables
  >(GetDepositationsCreatedByCountDocument, options);
}
export type GetDepositationsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetDepositationsCreatedByCountQuery
>;
export type GetDepositationsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetDepositationsCreatedByCountLazyQuery
>;
export type GetDepositationsCreatedByCountQueryResult = Apollo.QueryResult<
  GetDepositationsCreatedByCountQuery,
  GetDepositationsCreatedByCountQueryVariables
>;
export const GetTransportationsCreatedByCountDocument = gql`
  query GetTransportationsCreatedByCount($createdBy: ID!) {
    allTransportations(createdBy: $createdBy) {
      totalCount
    }
  }
`;

/**
 * __useGetTransportationsCreatedByCountQuery__
 *
 * To run a query within a React component, call `useGetTransportationsCreatedByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportationsCreatedByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportationsCreatedByCountQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useGetTransportationsCreatedByCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransportationsCreatedByCountQuery,
    GetTransportationsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransportationsCreatedByCountQuery,
    GetTransportationsCreatedByCountQueryVariables
  >(GetTransportationsCreatedByCountDocument, options);
}
export function useGetTransportationsCreatedByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransportationsCreatedByCountQuery,
    GetTransportationsCreatedByCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransportationsCreatedByCountQuery,
    GetTransportationsCreatedByCountQueryVariables
  >(GetTransportationsCreatedByCountDocument, options);
}
export type GetTransportationsCreatedByCountQueryHookResult = ReturnType<
  typeof useGetTransportationsCreatedByCountQuery
>;
export type GetTransportationsCreatedByCountLazyQueryHookResult = ReturnType<
  typeof useGetTransportationsCreatedByCountLazyQuery
>;
export type GetTransportationsCreatedByCountQueryResult = Apollo.QueryResult<
  GetTransportationsCreatedByCountQuery,
  GetTransportationsCreatedByCountQueryVariables
>;
export const GetAssetsSlogStatusDocument = gql`
  query GetAssetsSlogStatus {
    assetsSlogStatus {
      status
      percentage
      count
      color
    }
  }
`;

/**
 * __useGetAssetsSlogStatusQuery__
 *
 * To run a query within a React component, call `useGetAssetsSlogStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsSlogStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsSlogStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssetsSlogStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAssetsSlogStatusQuery, GetAssetsSlogStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetsSlogStatusQuery, GetAssetsSlogStatusQueryVariables>(
    GetAssetsSlogStatusDocument,
    options
  );
}
export function useGetAssetsSlogStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetsSlogStatusQuery,
    GetAssetsSlogStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetsSlogStatusQuery, GetAssetsSlogStatusQueryVariables>(
    GetAssetsSlogStatusDocument,
    options
  );
}
export type GetAssetsSlogStatusQueryHookResult = ReturnType<typeof useGetAssetsSlogStatusQuery>;
export type GetAssetsSlogStatusLazyQueryHookResult = ReturnType<
  typeof useGetAssetsSlogStatusLazyQuery
>;
export type GetAssetsSlogStatusQueryResult = Apollo.QueryResult<
  GetAssetsSlogStatusQuery,
  GetAssetsSlogStatusQueryVariables
>;
export const GetDepositationsByIdDocument = gql`
  query GetDepositationsById($id: ID!) {
    depositation(id: $id) {
      ...DepositationFields
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
            createdAt
          }
        }
      }
      depositationassetsSet {
        edges {
          node {
            ...DepositationsAssetsFields
          }
        }
      }
    }
  }
  ${DepositationFieldsFragmentDoc}
  ${DepositationsAssetsFieldsFragmentDoc}
`;

/**
 * __useGetDepositationsByIdQuery__
 *
 * To run a query within a React component, call `useGetDepositationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositationsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDepositationsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDepositationsByIdQuery,
    GetDepositationsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepositationsByIdQuery, GetDepositationsByIdQueryVariables>(
    GetDepositationsByIdDocument,
    options
  );
}
export function useGetDepositationsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepositationsByIdQuery,
    GetDepositationsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepositationsByIdQuery, GetDepositationsByIdQueryVariables>(
    GetDepositationsByIdDocument,
    options
  );
}
export type GetDepositationsByIdQueryHookResult = ReturnType<typeof useGetDepositationsByIdQuery>;
export type GetDepositationsByIdLazyQueryHookResult = ReturnType<
  typeof useGetDepositationsByIdLazyQuery
>;
export type GetDepositationsByIdQueryResult = Apollo.QueryResult<
  GetDepositationsByIdQuery,
  GetDepositationsByIdQueryVariables
>;
export const GetAllDepositationsDocument = gql`
  query GetAllDepositations(
    $createdByUserGroup: String
    $status: String
    $depositationType: String
    $isDepositationExpired: String
    $search: String
    $isConfirmed: Boolean
    $orderBy: String
    $first: Int
    $offset: Int
  ) {
    allDepositations(
      createdByUserGroup: $createdByUserGroup
      status: $status
      depositationType: $depositationType
      isDepositationExpired: $isDepositationExpired
      search: $search
      isConfirmed: $isConfirmed
      orderBy: $orderBy
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          type
          id
          customer {
            id
            fullName
          }
          depositationId
          numberOfAssets
          numberOfMedia
          isConfirmed
          status
          startDate
          createdBy {
            id
            name
            email
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllDepositationsQuery__
 *
 * To run a query within a React component, call `useGetAllDepositationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepositationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepositationsQuery({
 *   variables: {
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      status: // value for 'status'
 *      depositationType: // value for 'depositationType'
 *      isDepositationExpired: // value for 'isDepositationExpired'
 *      search: // value for 'search'
 *      isConfirmed: // value for 'isConfirmed'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllDepositationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllDepositationsQuery, GetAllDepositationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDepositationsQuery, GetAllDepositationsQueryVariables>(
    GetAllDepositationsDocument,
    options
  );
}
export function useGetAllDepositationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDepositationsQuery,
    GetAllDepositationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllDepositationsQuery, GetAllDepositationsQueryVariables>(
    GetAllDepositationsDocument,
    options
  );
}
export type GetAllDepositationsQueryHookResult = ReturnType<typeof useGetAllDepositationsQuery>;
export type GetAllDepositationsLazyQueryHookResult = ReturnType<
  typeof useGetAllDepositationsLazyQuery
>;
export type GetAllDepositationsQueryResult = Apollo.QueryResult<
  GetAllDepositationsQuery,
  GetAllDepositationsQueryVariables
>;
export const GetAllDepositationsAssetsDocument = gql`
  query GetAllDepositationsAssets(
    $createdByUserGroup: String
    $depositationType: String
    $isDepositationAssetExpired: String
    $search: String
    $first: Int
    $offset: Int
    $orderBy: String
    $showDepositationInventory: Boolean
  ) {
    allDepositationAssets(
      createdByUserGroup: $createdByUserGroup
      depositationType: $depositationType
      isDepositationAssetExpired: $isDepositationAssetExpired
      search: $search
      first: $first
      offset: $offset
      orderBy: $orderBy
      showDepositationInventory: $showDepositationInventory
    ) {
      edges {
        node {
          uuid
          updatedAt
          startDate
          position
          id
          externalDepositationId
          exitAppointee
          endDate
          position
          container
          createdAt
          container
          packageWeightInfo
          packageDimensionsInfo
          packagingType
          parentType
          appointee {
            id
            fullName
          }
          asset {
            id
            mainImage
            category
            title
            creationDate
            technique
            netWeight
            genericDimensions
            creationDate
            inventoryNumber
            actualDateAndPeriod
            authorEntity {
              id
              fullName
            }
            createdBy {
              name
            }
          }
          depositation {
            id
            startDate
            type
            depositationId
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllDepositationsAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllDepositationsAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDepositationsAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDepositationsAssetsQuery({
 *   variables: {
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      depositationType: // value for 'depositationType'
 *      isDepositationAssetExpired: // value for 'isDepositationAssetExpired'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      showDepositationInventory: // value for 'showDepositationInventory'
 *   },
 * });
 */
export function useGetAllDepositationsAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllDepositationsAssetsQuery,
    GetAllDepositationsAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDepositationsAssetsQuery, GetAllDepositationsAssetsQueryVariables>(
    GetAllDepositationsAssetsDocument,
    options
  );
}
export function useGetAllDepositationsAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllDepositationsAssetsQuery,
    GetAllDepositationsAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllDepositationsAssetsQuery,
    GetAllDepositationsAssetsQueryVariables
  >(GetAllDepositationsAssetsDocument, options);
}
export type GetAllDepositationsAssetsQueryHookResult = ReturnType<
  typeof useGetAllDepositationsAssetsQuery
>;
export type GetAllDepositationsAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAllDepositationsAssetsLazyQuery
>;
export type GetAllDepositationsAssetsQueryResult = Apollo.QueryResult<
  GetAllDepositationsAssetsQuery,
  GetAllDepositationsAssetsQueryVariables
>;
export const CreateDepositationDocument = gql`
  mutation CreateDepositation($input: CreateDepositationInput!) {
    createDepositation(input: $input) {
      depositation {
        ...DepositationFields
        depositationassetsSet {
          edges {
            node {
              container
              createdAt
              endDate
              exitAppointee
              exit {
                createdAt
                depositationData
                depositationNumber
                endDate
                filingNumber
                id
                notes
                transportationRequiredPersonnel
                updatedAt
                uuid
              }
            }
          }
        }
      }
    }
  }
  ${DepositationFieldsFragmentDoc}
`;
export type CreateDepositationMutationFn = Apollo.MutationFunction<
  CreateDepositationMutation,
  CreateDepositationMutationVariables
>;

/**
 * __useCreateDepositationMutation__
 *
 * To run a mutation, you first call `useCreateDepositationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepositationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepositationMutation, { data, loading, error }] = useCreateDepositationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDepositationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDepositationMutation,
    CreateDepositationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDepositationMutation, CreateDepositationMutationVariables>(
    CreateDepositationDocument,
    options
  );
}
export type CreateDepositationMutationHookResult = ReturnType<typeof useCreateDepositationMutation>;
export type CreateDepositationMutationResult = Apollo.MutationResult<CreateDepositationMutation>;
export type CreateDepositationMutationOptions = Apollo.BaseMutationOptions<
  CreateDepositationMutation,
  CreateDepositationMutationVariables
>;
export const UpdateDepositationDocument = gql`
  mutation UpdateDepositation($input: UpdateDepositationInput!) {
    updateDepositation(input: $input) {
      depositation {
        id
      }
    }
  }
`;
export type UpdateDepositationMutationFn = Apollo.MutationFunction<
  UpdateDepositationMutation,
  UpdateDepositationMutationVariables
>;

/**
 * __useUpdateDepositationMutation__
 *
 * To run a mutation, you first call `useUpdateDepositationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepositationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepositationMutation, { data, loading, error }] = useUpdateDepositationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDepositationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDepositationMutation,
    UpdateDepositationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDepositationMutation, UpdateDepositationMutationVariables>(
    UpdateDepositationDocument,
    options
  );
}
export type UpdateDepositationMutationHookResult = ReturnType<typeof useUpdateDepositationMutation>;
export type UpdateDepositationMutationResult = Apollo.MutationResult<UpdateDepositationMutation>;
export type UpdateDepositationMutationOptions = Apollo.BaseMutationOptions<
  UpdateDepositationMutation,
  UpdateDepositationMutationVariables
>;
export const DeleteDepositationDocument = gql`
  mutation DeleteDepositation($input: DeleteDepositationInput!) {
    deleteDepositation(input: $input) {
      depositation {
        id
      }
    }
  }
`;
export type DeleteDepositationMutationFn = Apollo.MutationFunction<
  DeleteDepositationMutation,
  DeleteDepositationMutationVariables
>;

/**
 * __useDeleteDepositationMutation__
 *
 * To run a mutation, you first call `useDeleteDepositationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepositationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepositationMutation, { data, loading, error }] = useDeleteDepositationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDepositationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDepositationMutation,
    DeleteDepositationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDepositationMutation, DeleteDepositationMutationVariables>(
    DeleteDepositationDocument,
    options
  );
}
export type DeleteDepositationMutationHookResult = ReturnType<typeof useDeleteDepositationMutation>;
export type DeleteDepositationMutationResult = Apollo.MutationResult<DeleteDepositationMutation>;
export type DeleteDepositationMutationOptions = Apollo.BaseMutationOptions<
  DeleteDepositationMutation,
  DeleteDepositationMutationVariables
>;
export const DetachAssetsFromDepositationDocument = gql`
  mutation DetachAssetsFromDepositation($input: DeleteDepositationAssetInput!) {
    deleteDepositationAsset(input: $input) {
      depositation {
        id
      }
    }
  }
`;
export type DetachAssetsFromDepositationMutationFn = Apollo.MutationFunction<
  DetachAssetsFromDepositationMutation,
  DetachAssetsFromDepositationMutationVariables
>;

/**
 * __useDetachAssetsFromDepositationMutation__
 *
 * To run a mutation, you first call `useDetachAssetsFromDepositationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetsFromDepositationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetsFromDepositationMutation, { data, loading, error }] = useDetachAssetsFromDepositationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetsFromDepositationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetsFromDepositationMutation,
    DetachAssetsFromDepositationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetsFromDepositationMutation,
    DetachAssetsFromDepositationMutationVariables
  >(DetachAssetsFromDepositationDocument, options);
}
export type DetachAssetsFromDepositationMutationHookResult = ReturnType<
  typeof useDetachAssetsFromDepositationMutation
>;
export type DetachAssetsFromDepositationMutationResult =
  Apollo.MutationResult<DetachAssetsFromDepositationMutation>;
export type DetachAssetsFromDepositationMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetsFromDepositationMutation,
  DetachAssetsFromDepositationMutationVariables
>;
export const AddDepositationMediaDocument = gql`
  mutation AddDepositationMedia($input: AddDepositationMediaInput!) {
    addDepositationMedia(input: $input) {
      depositation {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddDepositationMediaMutationFn = Apollo.MutationFunction<
  AddDepositationMediaMutation,
  AddDepositationMediaMutationVariables
>;

/**
 * __useAddDepositationMediaMutation__
 *
 * To run a mutation, you first call `useAddDepositationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDepositationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDepositationMediaMutation, { data, loading, error }] = useAddDepositationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDepositationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDepositationMediaMutation,
    AddDepositationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDepositationMediaMutation, AddDepositationMediaMutationVariables>(
    AddDepositationMediaDocument,
    options
  );
}
export type AddDepositationMediaMutationHookResult = ReturnType<
  typeof useAddDepositationMediaMutation
>;
export type AddDepositationMediaMutationResult =
  Apollo.MutationResult<AddDepositationMediaMutation>;
export type AddDepositationMediaMutationOptions = Apollo.BaseMutationOptions<
  AddDepositationMediaMutation,
  AddDepositationMediaMutationVariables
>;
export const DeleteDepositationMediaDocument = gql`
  mutation DeleteDepositationMedia($input: DeleteDepositationMediaInput!) {
    deleteDepositationMedia(input: $input) {
      depositation {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteDepositationMediaMutationFn = Apollo.MutationFunction<
  DeleteDepositationMediaMutation,
  DeleteDepositationMediaMutationVariables
>;

/**
 * __useDeleteDepositationMediaMutation__
 *
 * To run a mutation, you first call `useDeleteDepositationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDepositationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDepositationMediaMutation, { data, loading, error }] = useDeleteDepositationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDepositationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDepositationMediaMutation,
    DeleteDepositationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDepositationMediaMutation,
    DeleteDepositationMediaMutationVariables
  >(DeleteDepositationMediaDocument, options);
}
export type DeleteDepositationMediaMutationHookResult = ReturnType<
  typeof useDeleteDepositationMediaMutation
>;
export type DeleteDepositationMediaMutationResult =
  Apollo.MutationResult<DeleteDepositationMediaMutation>;
export type DeleteDepositationMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteDepositationMediaMutation,
  DeleteDepositationMediaMutationVariables
>;
export const UpdateDepositationStatusDocument = gql`
  mutation UpdateDepositationStatus($input: UpdateDepositationStatusInput!) {
    updateDepositationStatus(input: $input) {
      depositation {
        status
        id
      }
    }
  }
`;
export type UpdateDepositationStatusMutationFn = Apollo.MutationFunction<
  UpdateDepositationStatusMutation,
  UpdateDepositationStatusMutationVariables
>;

/**
 * __useUpdateDepositationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDepositationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepositationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepositationStatusMutation, { data, loading, error }] = useUpdateDepositationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDepositationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDepositationStatusMutation,
    UpdateDepositationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDepositationStatusMutation,
    UpdateDepositationStatusMutationVariables
  >(UpdateDepositationStatusDocument, options);
}
export type UpdateDepositationStatusMutationHookResult = ReturnType<
  typeof useUpdateDepositationStatusMutation
>;
export type UpdateDepositationStatusMutationResult =
  Apollo.MutationResult<UpdateDepositationStatusMutation>;
export type UpdateDepositationStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateDepositationStatusMutation,
  UpdateDepositationStatusMutationVariables
>;
export const DuplicateDepositationDocument = gql`
  mutation DuplicateDepositation($input: DuplicateDepositationInput!) {
    duplicateDepositation(input: $input) {
      depositation {
        id
      }
    }
  }
`;
export type DuplicateDepositationMutationFn = Apollo.MutationFunction<
  DuplicateDepositationMutation,
  DuplicateDepositationMutationVariables
>;

/**
 * __useDuplicateDepositationMutation__
 *
 * To run a mutation, you first call `useDuplicateDepositationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDepositationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDepositationMutation, { data, loading, error }] = useDuplicateDepositationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateDepositationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateDepositationMutation,
    DuplicateDepositationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateDepositationMutation, DuplicateDepositationMutationVariables>(
    DuplicateDepositationDocument,
    options
  );
}
export type DuplicateDepositationMutationHookResult = ReturnType<
  typeof useDuplicateDepositationMutation
>;
export type DuplicateDepositationMutationResult =
  Apollo.MutationResult<DuplicateDepositationMutation>;
export type DuplicateDepositationMutationOptions = Apollo.BaseMutationOptions<
  DuplicateDepositationMutation,
  DuplicateDepositationMutationVariables
>;
export const ExportDepositationInboundBillsDocument = gql`
  mutation ExportDepositationInboundBills($input: ExportDepositationInboundBillsInput!) {
    exportDepositationInboundBills(input: $input) {
      url
    }
  }
`;
export type ExportDepositationInboundBillsMutationFn = Apollo.MutationFunction<
  ExportDepositationInboundBillsMutation,
  ExportDepositationInboundBillsMutationVariables
>;

/**
 * __useExportDepositationInboundBillsMutation__
 *
 * To run a mutation, you first call `useExportDepositationInboundBillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationInboundBillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationInboundBillsMutation, { data, loading, error }] = useExportDepositationInboundBillsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationInboundBillsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationInboundBillsMutation,
    ExportDepositationInboundBillsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDepositationInboundBillsMutation,
    ExportDepositationInboundBillsMutationVariables
  >(ExportDepositationInboundBillsDocument, options);
}
export type ExportDepositationInboundBillsMutationHookResult = ReturnType<
  typeof useExportDepositationInboundBillsMutation
>;
export type ExportDepositationInboundBillsMutationResult =
  Apollo.MutationResult<ExportDepositationInboundBillsMutation>;
export type ExportDepositationInboundBillsMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationInboundBillsMutation,
  ExportDepositationInboundBillsMutationVariables
>;
export const ExportDepositationOutboundBillsDocument = gql`
  mutation ExportDepositationOutboundBills($input: ExportDepositationOutboundBillsInput!) {
    exportDepositationOutboundBills(input: $input) {
      url
    }
  }
`;
export type ExportDepositationOutboundBillsMutationFn = Apollo.MutationFunction<
  ExportDepositationOutboundBillsMutation,
  ExportDepositationOutboundBillsMutationVariables
>;

/**
 * __useExportDepositationOutboundBillsMutation__
 *
 * To run a mutation, you first call `useExportDepositationOutboundBillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationOutboundBillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationOutboundBillsMutation, { data, loading, error }] = useExportDepositationOutboundBillsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationOutboundBillsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationOutboundBillsMutation,
    ExportDepositationOutboundBillsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDepositationOutboundBillsMutation,
    ExportDepositationOutboundBillsMutationVariables
  >(ExportDepositationOutboundBillsDocument, options);
}
export type ExportDepositationOutboundBillsMutationHookResult = ReturnType<
  typeof useExportDepositationOutboundBillsMutation
>;
export type ExportDepositationOutboundBillsMutationResult =
  Apollo.MutationResult<ExportDepositationOutboundBillsMutation>;
export type ExportDepositationOutboundBillsMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationOutboundBillsMutation,
  ExportDepositationOutboundBillsMutationVariables
>;
export const ExportDepositationPackingListDocument = gql`
  mutation ExportDepositationPackingList($input: ExportDepositationPackingListInput!) {
    exportDepositationPackingList(input: $input) {
      url
    }
  }
`;
export type ExportDepositationPackingListMutationFn = Apollo.MutationFunction<
  ExportDepositationPackingListMutation,
  ExportDepositationPackingListMutationVariables
>;

/**
 * __useExportDepositationPackingListMutation__
 *
 * To run a mutation, you first call `useExportDepositationPackingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationPackingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationPackingListMutation, { data, loading, error }] = useExportDepositationPackingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationPackingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationPackingListMutation,
    ExportDepositationPackingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDepositationPackingListMutation,
    ExportDepositationPackingListMutationVariables
  >(ExportDepositationPackingListDocument, options);
}
export type ExportDepositationPackingListMutationHookResult = ReturnType<
  typeof useExportDepositationPackingListMutation
>;
export type ExportDepositationPackingListMutationResult =
  Apollo.MutationResult<ExportDepositationPackingListMutation>;
export type ExportDepositationPackingListMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationPackingListMutation,
  ExportDepositationPackingListMutationVariables
>;
export const ExportDepositationLabelsDocument = gql`
  mutation ExportDepositationLabels($input: ExportDepositationLabelsInput!) {
    exportDepositationLabels(input: $input) {
      url
    }
  }
`;
export type ExportDepositationLabelsMutationFn = Apollo.MutationFunction<
  ExportDepositationLabelsMutation,
  ExportDepositationLabelsMutationVariables
>;

/**
 * __useExportDepositationLabelsMutation__
 *
 * To run a mutation, you first call `useExportDepositationLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationLabelsMutation, { data, loading, error }] = useExportDepositationLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationLabelsMutation,
    ExportDepositationLabelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDepositationLabelsMutation,
    ExportDepositationLabelsMutationVariables
  >(ExportDepositationLabelsDocument, options);
}
export type ExportDepositationLabelsMutationHookResult = ReturnType<
  typeof useExportDepositationLabelsMutation
>;
export type ExportDepositationLabelsMutationResult =
  Apollo.MutationResult<ExportDepositationLabelsMutation>;
export type ExportDepositationLabelsMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationLabelsMutation,
  ExportDepositationLabelsMutationVariables
>;
export const ExportDepositationPdfDocument = gql`
  mutation ExportDepositationPDF($input: ExportDepositationPdfInput!) {
    exportDepositationPdf(input: $input) {
      url
    }
  }
`;
export type ExportDepositationPdfMutationFn = Apollo.MutationFunction<
  ExportDepositationPdfMutation,
  ExportDepositationPdfMutationVariables
>;

/**
 * __useExportDepositationPdfMutation__
 *
 * To run a mutation, you first call `useExportDepositationPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationPdfMutation, { data, loading, error }] = useExportDepositationPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationPdfMutation,
    ExportDepositationPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportDepositationPdfMutation, ExportDepositationPdfMutationVariables>(
    ExportDepositationPdfDocument,
    options
  );
}
export type ExportDepositationPdfMutationHookResult = ReturnType<
  typeof useExportDepositationPdfMutation
>;
export type ExportDepositationPdfMutationResult =
  Apollo.MutationResult<ExportDepositationPdfMutation>;
export type ExportDepositationPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationPdfMutation,
  ExportDepositationPdfMutationVariables
>;
export const GetLatestDepositationDocumentExportsDocument = gql`
  query GetLatestDepositationDocumentExports($id: ID!) {
    latestDepositationDocumentsExports(id: $id) {
      createdAt
      externalId
      file
      isSigned
      modelName
      modelUpdatedAt
      type
      updatedAt
      uuid
    }
  }
`;

/**
 * __useGetLatestDepositationDocumentExportsQuery__
 *
 * To run a query within a React component, call `useGetLatestDepositationDocumentExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDepositationDocumentExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDepositationDocumentExportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLatestDepositationDocumentExportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestDepositationDocumentExportsQuery,
    GetLatestDepositationDocumentExportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestDepositationDocumentExportsQuery,
    GetLatestDepositationDocumentExportsQueryVariables
  >(GetLatestDepositationDocumentExportsDocument, options);
}
export function useGetLatestDepositationDocumentExportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestDepositationDocumentExportsQuery,
    GetLatestDepositationDocumentExportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestDepositationDocumentExportsQuery,
    GetLatestDepositationDocumentExportsQueryVariables
  >(GetLatestDepositationDocumentExportsDocument, options);
}
export type GetLatestDepositationDocumentExportsQueryHookResult = ReturnType<
  typeof useGetLatestDepositationDocumentExportsQuery
>;
export type GetLatestDepositationDocumentExportsLazyQueryHookResult = ReturnType<
  typeof useGetLatestDepositationDocumentExportsLazyQuery
>;
export type GetLatestDepositationDocumentExportsQueryResult = Apollo.QueryResult<
  GetLatestDepositationDocumentExportsQuery,
  GetLatestDepositationDocumentExportsQueryVariables
>;
export const ExportDepositationAssetLabelDocument = gql`
  mutation ExportDepositationAssetLabel($input: ExportDepositationAssetsLabelsInput!) {
    exportDepositationAssetLabel(input: $input) {
      url
    }
  }
`;
export type ExportDepositationAssetLabelMutationFn = Apollo.MutationFunction<
  ExportDepositationAssetLabelMutation,
  ExportDepositationAssetLabelMutationVariables
>;

/**
 * __useExportDepositationAssetLabelMutation__
 *
 * To run a mutation, you first call `useExportDepositationAssetLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationAssetLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationAssetLabelMutation, { data, loading, error }] = useExportDepositationAssetLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationAssetLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationAssetLabelMutation,
    ExportDepositationAssetLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDepositationAssetLabelMutation,
    ExportDepositationAssetLabelMutationVariables
  >(ExportDepositationAssetLabelDocument, options);
}
export type ExportDepositationAssetLabelMutationHookResult = ReturnType<
  typeof useExportDepositationAssetLabelMutation
>;
export type ExportDepositationAssetLabelMutationResult =
  Apollo.MutationResult<ExportDepositationAssetLabelMutation>;
export type ExportDepositationAssetLabelMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationAssetLabelMutation,
  ExportDepositationAssetLabelMutationVariables
>;
export const ExportDepositationAssetPackingListDocument = gql`
  mutation ExportDepositationAssetPackingList($input: ExportDepositationAssetsPackingListInput!) {
    exportDepositationAssetPackingList(input: $input) {
      url
    }
  }
`;
export type ExportDepositationAssetPackingListMutationFn = Apollo.MutationFunction<
  ExportDepositationAssetPackingListMutation,
  ExportDepositationAssetPackingListMutationVariables
>;

/**
 * __useExportDepositationAssetPackingListMutation__
 *
 * To run a mutation, you first call `useExportDepositationAssetPackingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportDepositationAssetPackingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportDepositationAssetPackingListMutation, { data, loading, error }] = useExportDepositationAssetPackingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportDepositationAssetPackingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportDepositationAssetPackingListMutation,
    ExportDepositationAssetPackingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportDepositationAssetPackingListMutation,
    ExportDepositationAssetPackingListMutationVariables
  >(ExportDepositationAssetPackingListDocument, options);
}
export type ExportDepositationAssetPackingListMutationHookResult = ReturnType<
  typeof useExportDepositationAssetPackingListMutation
>;
export type ExportDepositationAssetPackingListMutationResult =
  Apollo.MutationResult<ExportDepositationAssetPackingListMutation>;
export type ExportDepositationAssetPackingListMutationOptions = Apollo.BaseMutationOptions<
  ExportDepositationAssetPackingListMutation,
  ExportDepositationAssetPackingListMutationVariables
>;
export const GetAllEventLogsDocument = gql`
  query GetAllEventLogs {
    allEventLogs(orderBy: "-created_at") {
      edges {
        node {
          id
          eventType
          hideOwner
          commerciallyAvailable
          sold
          collectionPublicName
          newCollectionPublicName
          locationDescription
          startDate
          endDate
          owner {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllEventLogsQuery__
 *
 * To run a query within a React component, call `useGetAllEventLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>(
    GetAllEventLogsDocument,
    options
  );
}
export function useGetAllEventLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllEventLogsQuery, GetAllEventLogsQueryVariables>(
    GetAllEventLogsDocument,
    options
  );
}
export type GetAllEventLogsQueryHookResult = ReturnType<typeof useGetAllEventLogsQuery>;
export type GetAllEventLogsLazyQueryHookResult = ReturnType<typeof useGetAllEventLogsLazyQuery>;
export type GetAllEventLogsQueryResult = Apollo.QueryResult<
  GetAllEventLogsQuery,
  GetAllEventLogsQueryVariables
>;
export const GetEventLogByIdDocument = gql`
  query GetEventLogById($id: ID!) {
    eventLog(id: $id) {
      ...EventLogAnagraphicFields
      eventName
      actualLocation
      actualCity
      externalEntityId
      host {
        fullName
      }
      broker {
        fullName
      }
      policy {
        id
        broker {
          fullName
        }
        contractingParty {
          fullName
        }
        eventName
        eventFrom
        eventTo
        policyType
        offerCode
        policyCode
        eventEntity {
          fullName
        }
        eventLocationEntity {
          name
        }
        insuranceCompany {
          fullName
        }
      }
      collection {
        totalAssetsNumber
        createdFrom
        id
        name
      }
      policy {
        id
        policyCode
        offerCode
        eventFrom
        eventTo
        issuedAt
        expiresAt
        insuranceCompany {
          fullName
        }
        broker {
          fullName
        }
        contractingParty {
          fullName
        }
        eventEntity {
          fullName
        }
        eventLocationEntity {
          name
        }
        eventName
      }
    }
  }
  ${EventLogAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetEventLogByIdQuery__
 *
 * To run a query within a React component, call `useGetEventLogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventLogByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>(
    GetEventLogByIdDocument,
    options
  );
}
export function useGetEventLogByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventLogByIdQuery, GetEventLogByIdQueryVariables>(
    GetEventLogByIdDocument,
    options
  );
}
export type GetEventLogByIdQueryHookResult = ReturnType<typeof useGetEventLogByIdQuery>;
export type GetEventLogByIdLazyQueryHookResult = ReturnType<typeof useGetEventLogByIdLazyQuery>;
export type GetEventLogByIdQueryResult = Apollo.QueryResult<
  GetEventLogByIdQuery,
  GetEventLogByIdQueryVariables
>;
export const CreateEventLogDocument = gql`
  mutation CreateEventLog($input: CreateEventLogInput!) {
    createEventLog(input: $input) {
      eventLog {
        id
        eventType
        hideOwner
        commerciallyAvailable
        sold
        collectionPublicName
        newCollectionPublicName
        locationDescription
        startDate
        endDate
        note
        acquisitionType
        actionType
        commission
        auctionDescription
        lotNumber
        sellingLocation
        value {
          amount
          currency {
            code
          }
        }
        minimumPrice {
          amount
          currency {
            code
          }
        }
        maximumPrice {
          amount
          currency {
            code
          }
        }
        reservePrice {
          amount
          currency {
            code
          }
        }
        auctionRights {
          amount
          currency {
            code
          }
        }
        owner {
          id
          fullName
        }
        origin {
          id
          fullName
        }
        host {
          id
          fullName
        }
        broker {
          id
          fullName
        }
        newOwner {
          id
          fullName
        }
        assetSet {
          edges {
            node {
              id
              title
              description
              author
              category
              genericDimensions
              creationDate
              mainImage
              actualDateAndPeriod
            }
          }
        }
        document
      }
    }
  }
`;
export type CreateEventLogMutationFn = Apollo.MutationFunction<
  CreateEventLogMutation,
  CreateEventLogMutationVariables
>;

/**
 * __useCreateEventLogMutation__
 *
 * To run a mutation, you first call `useCreateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventLogMutation, { data, loading, error }] = useCreateEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventLogMutation, CreateEventLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventLogMutation, CreateEventLogMutationVariables>(
    CreateEventLogDocument,
    options
  );
}
export type CreateEventLogMutationHookResult = ReturnType<typeof useCreateEventLogMutation>;
export type CreateEventLogMutationResult = Apollo.MutationResult<CreateEventLogMutation>;
export type CreateEventLogMutationOptions = Apollo.BaseMutationOptions<
  CreateEventLogMutation,
  CreateEventLogMutationVariables
>;
export const UpdateEventLogDocument = gql`
  mutation UpdateEventLog($input: UpdateEventLogInput!) {
    updateEventLog(input: $input) {
      eventLog {
        id
        eventType
        hideOwner
        commerciallyAvailable
        sold
        collectionPublicName
        newCollectionPublicName
        locationDescription
        startDate
        endDate
        note
        acquisitionType
        actionType
        commission
        auctionDescription
        lotNumber
        sellingLocation
        value {
          amount
          currency {
            code
          }
        }
        minimumPrice {
          amount
          currency {
            code
          }
        }
        maximumPrice {
          amount
          currency {
            code
          }
        }
        reservePrice {
          amount
          currency {
            code
          }
        }
        auctionRights {
          amount
          currency {
            code
          }
        }
        externalId
        externalEntity
        owner {
          id
          fullName
        }
        origin {
          id
          fullName
        }
        host {
          id
          fullName
        }
        broker {
          id
          fullName
        }
        newOwner {
          id
          fullName
        }
        assetSet {
          edges {
            node {
              id
              title
              description
              author
              category
              genericDimensions
              creationDate
              mainImage
              actualDateAndPeriod
            }
          }
        }
        document
      }
    }
  }
`;
export type UpdateEventLogMutationFn = Apollo.MutationFunction<
  UpdateEventLogMutation,
  UpdateEventLogMutationVariables
>;

/**
 * __useUpdateEventLogMutation__
 *
 * To run a mutation, you first call `useUpdateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventLogMutation, { data, loading, error }] = useUpdateEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventLogMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventLogMutation, UpdateEventLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventLogMutation, UpdateEventLogMutationVariables>(
    UpdateEventLogDocument,
    options
  );
}
export type UpdateEventLogMutationHookResult = ReturnType<typeof useUpdateEventLogMutation>;
export type UpdateEventLogMutationResult = Apollo.MutationResult<UpdateEventLogMutation>;
export type UpdateEventLogMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventLogMutation,
  UpdateEventLogMutationVariables
>;
export const DeleteEventLogDocument = gql`
  mutation DeleteEventLog($input: DeleteEventLogInput!) {
    deleteEventLog(input: $input) {
      deleteEventLog {
        id
      }
    }
  }
`;
export type DeleteEventLogMutationFn = Apollo.MutationFunction<
  DeleteEventLogMutation,
  DeleteEventLogMutationVariables
>;

/**
 * __useDeleteEventLogMutation__
 *
 * To run a mutation, you first call `useDeleteEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventLogMutation, { data, loading, error }] = useDeleteEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventLogMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEventLogMutation, DeleteEventLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventLogMutation, DeleteEventLogMutationVariables>(
    DeleteEventLogDocument,
    options
  );
}
export type DeleteEventLogMutationHookResult = ReturnType<typeof useDeleteEventLogMutation>;
export type DeleteEventLogMutationResult = Apollo.MutationResult<DeleteEventLogMutation>;
export type DeleteEventLogMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventLogMutation,
  DeleteEventLogMutationVariables
>;
export const GetEventLogByAssetIdDocument = gql`
  query GetEventLogByAssetId($id: ID!) {
    asset(id: $id) {
      eventLogs {
        edges {
          node {
            id
            eventType
            hideOwner
            commerciallyAvailable
            sold
            collectionPublicName
            newCollectionPublicName
            locationDescription
            startDate
            endDate
            eventName
            actualLocation
            actualCity
            numberOfDocuments
            owner {
              id
              fullName
            }
            newOwner {
              id
              fullName
            }
            externalId
            externalEntity
            externalEntityId
            actionType
            translatedEventType
            createdAt
            reason
          }
        }
      }
    }
  }
`;

/**
 * __useGetEventLogByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetEventLogByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventLogByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventLogByAssetIdQuery,
    GetEventLogByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventLogByAssetIdQuery, GetEventLogByAssetIdQueryVariables>(
    GetEventLogByAssetIdDocument,
    options
  );
}
export function useGetEventLogByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventLogByAssetIdQuery,
    GetEventLogByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventLogByAssetIdQuery, GetEventLogByAssetIdQueryVariables>(
    GetEventLogByAssetIdDocument,
    options
  );
}
export type GetEventLogByAssetIdQueryHookResult = ReturnType<typeof useGetEventLogByAssetIdQuery>;
export type GetEventLogByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetEventLogByAssetIdLazyQuery
>;
export type GetEventLogByAssetIdQueryResult = Apollo.QueryResult<
  GetEventLogByAssetIdQuery,
  GetEventLogByAssetIdQueryVariables
>;
export const GetAllExhibitionsDocument = gql`
  query GetAllExhibitions(
    $orderBy: String
    $search: String
    $first: Int
    $offset: Int
    $createdByUserGroup: String
    $actualTimingStatus: String
  ) {
    allExhibitions(
      orderBy: $orderBy
      search: $search
      first: $first
      offset: $offset
      createdByUserGroup: $createdByUserGroup
      actualTimingStatus: $actualTimingStatus
    ) {
      edges {
        node {
          id
          name
          eventFrom
          eventTo
          permanent
          totalAssetsNumber
          actualTimingStatus
          createdBy {
            name
          }
          hostEntity {
            id
            fullName
          }
          eventLocation {
            raw
          }
          eventLocationEntity {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllExhibitionsQuery__
 *
 * To run a query within a React component, call `useGetAllExhibitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExhibitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExhibitionsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      actualTimingStatus: // value for 'actualTimingStatus'
 *   },
 * });
 */
export function useGetAllExhibitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>(
    GetAllExhibitionsDocument,
    options
  );
}
export function useGetAllExhibitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllExhibitionsQuery, GetAllExhibitionsQueryVariables>(
    GetAllExhibitionsDocument,
    options
  );
}
export type GetAllExhibitionsQueryHookResult = ReturnType<typeof useGetAllExhibitionsQuery>;
export type GetAllExhibitionsLazyQueryHookResult = ReturnType<typeof useGetAllExhibitionsLazyQuery>;
export type GetAllExhibitionsQueryResult = Apollo.QueryResult<
  GetAllExhibitionsQuery,
  GetAllExhibitionsQueryVariables
>;
export const GetExhibitionByIdDocument = gql`
  query GetExhibitionById($id: ID!) {
    exhibition(id: $id) {
      ...ExhibitionAnagraphicFields
    }
  }
  ${ExhibitionAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetExhibitionByIdQuery__
 *
 * To run a query within a React component, call `useGetExhibitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExhibitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExhibitionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExhibitionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>(
    GetExhibitionByIdDocument,
    options
  );
}
export function useGetExhibitionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExhibitionByIdQuery, GetExhibitionByIdQueryVariables>(
    GetExhibitionByIdDocument,
    options
  );
}
export type GetExhibitionByIdQueryHookResult = ReturnType<typeof useGetExhibitionByIdQuery>;
export type GetExhibitionByIdLazyQueryHookResult = ReturnType<typeof useGetExhibitionByIdLazyQuery>;
export type GetExhibitionByIdQueryResult = Apollo.QueryResult<
  GetExhibitionByIdQuery,
  GetExhibitionByIdQueryVariables
>;
export const GetMediaExhibitionByIdDocument = gql`
  query GetMediaExhibitionById($id: ID!) {
    exhibition(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaExhibitionByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaExhibitionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaExhibitionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaExhibitionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaExhibitionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaExhibitionByIdQuery,
    GetMediaExhibitionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaExhibitionByIdQuery, GetMediaExhibitionByIdQueryVariables>(
    GetMediaExhibitionByIdDocument,
    options
  );
}
export function useGetMediaExhibitionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaExhibitionByIdQuery,
    GetMediaExhibitionByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaExhibitionByIdQuery, GetMediaExhibitionByIdQueryVariables>(
    GetMediaExhibitionByIdDocument,
    options
  );
}
export type GetMediaExhibitionByIdQueryHookResult = ReturnType<
  typeof useGetMediaExhibitionByIdQuery
>;
export type GetMediaExhibitionByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaExhibitionByIdLazyQuery
>;
export type GetMediaExhibitionByIdQueryResult = Apollo.QueryResult<
  GetMediaExhibitionByIdQuery,
  GetMediaExhibitionByIdQueryVariables
>;
export const CreateExhibitionDocument = gql`
  mutation CreateExhibition($input: CreateExhibitionInput!) {
    createExhibition(input: $input) {
      exhibition {
        id
        name
        description
        eventFrom
        eventTo
        notes
        permanent
        curator {
          id
          fullName
        }
        organizer {
          id
          fullName
        }
        hostEntity {
          id
          fullName
        }
      }
    }
  }
`;
export type CreateExhibitionMutationFn = Apollo.MutationFunction<
  CreateExhibitionMutation,
  CreateExhibitionMutationVariables
>;

/**
 * __useCreateExhibitionMutation__
 *
 * To run a mutation, you first call `useCreateExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExhibitionMutation, { data, loading, error }] = useCreateExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExhibitionMutation,
    CreateExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExhibitionMutation, CreateExhibitionMutationVariables>(
    CreateExhibitionDocument,
    options
  );
}
export type CreateExhibitionMutationHookResult = ReturnType<typeof useCreateExhibitionMutation>;
export type CreateExhibitionMutationResult = Apollo.MutationResult<CreateExhibitionMutation>;
export type CreateExhibitionMutationOptions = Apollo.BaseMutationOptions<
  CreateExhibitionMutation,
  CreateExhibitionMutationVariables
>;
export const UpdateExhibitionDocument = gql`
  mutation UpdateExhibition($input: UpdateExhibitionInput!) {
    updateExhibition(input: $input) {
      exhibition {
        id
        name
        description
        eventFrom
        eventTo
        notes
        permanent
        curator {
          id
          fullName
        }
        organizer {
          id
          fullName
        }
        hostEntity {
          id
          fullName
        }
      }
    }
  }
`;
export type UpdateExhibitionMutationFn = Apollo.MutationFunction<
  UpdateExhibitionMutation,
  UpdateExhibitionMutationVariables
>;

/**
 * __useUpdateExhibitionMutation__
 *
 * To run a mutation, you first call `useUpdateExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExhibitionMutation, { data, loading, error }] = useUpdateExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExhibitionMutation,
    UpdateExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExhibitionMutation, UpdateExhibitionMutationVariables>(
    UpdateExhibitionDocument,
    options
  );
}
export type UpdateExhibitionMutationHookResult = ReturnType<typeof useUpdateExhibitionMutation>;
export type UpdateExhibitionMutationResult = Apollo.MutationResult<UpdateExhibitionMutation>;
export type UpdateExhibitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateExhibitionMutation,
  UpdateExhibitionMutationVariables
>;
export const DeleteExhibitionDocument = gql`
  mutation DeleteExhibition($input: DeleteExhibitionInput!) {
    deleteExhibition(input: $input) {
      exhibition {
        id
      }
    }
  }
`;
export type DeleteExhibitionMutationFn = Apollo.MutationFunction<
  DeleteExhibitionMutation,
  DeleteExhibitionMutationVariables
>;

/**
 * __useDeleteExhibitionMutation__
 *
 * To run a mutation, you first call `useDeleteExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExhibitionMutation, { data, loading, error }] = useDeleteExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExhibitionMutation,
    DeleteExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExhibitionMutation, DeleteExhibitionMutationVariables>(
    DeleteExhibitionDocument,
    options
  );
}
export type DeleteExhibitionMutationHookResult = ReturnType<typeof useDeleteExhibitionMutation>;
export type DeleteExhibitionMutationResult = Apollo.MutationResult<DeleteExhibitionMutation>;
export type DeleteExhibitionMutationOptions = Apollo.BaseMutationOptions<
  DeleteExhibitionMutation,
  DeleteExhibitionMutationVariables
>;
export const DetachAssetFromExhibitionDocument = gql`
  mutation DetachAssetFromExhibition($input: DetachAssetFromExhibitionInput!) {
    detachAssetFromExhibition(input: $input) {
      exhibition {
        id
        assetSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DetachAssetFromExhibitionMutationFn = Apollo.MutationFunction<
  DetachAssetFromExhibitionMutation,
  DetachAssetFromExhibitionMutationVariables
>;

/**
 * __useDetachAssetFromExhibitionMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromExhibitionMutation, { data, loading, error }] = useDetachAssetFromExhibitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromExhibitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromExhibitionMutation,
    DetachAssetFromExhibitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromExhibitionMutation,
    DetachAssetFromExhibitionMutationVariables
  >(DetachAssetFromExhibitionDocument, options);
}
export type DetachAssetFromExhibitionMutationHookResult = ReturnType<
  typeof useDetachAssetFromExhibitionMutation
>;
export type DetachAssetFromExhibitionMutationResult =
  Apollo.MutationResult<DetachAssetFromExhibitionMutation>;
export type DetachAssetFromExhibitionMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromExhibitionMutation,
  DetachAssetFromExhibitionMutationVariables
>;
export const ExportExhibitionPdfDocument = gql`
  mutation ExportExhibitionPdf($input: ExportExhibitionPDFInput!) {
    exportExhibitionPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportExhibitionPdfMutationFn = Apollo.MutationFunction<
  ExportExhibitionPdfMutation,
  ExportExhibitionPdfMutationVariables
>;

/**
 * __useExportExhibitionPdfMutation__
 *
 * To run a mutation, you first call `useExportExhibitionPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportExhibitionPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportExhibitionPdfMutation, { data, loading, error }] = useExportExhibitionPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportExhibitionPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportExhibitionPdfMutation,
    ExportExhibitionPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportExhibitionPdfMutation, ExportExhibitionPdfMutationVariables>(
    ExportExhibitionPdfDocument,
    options
  );
}
export type ExportExhibitionPdfMutationHookResult = ReturnType<
  typeof useExportExhibitionPdfMutation
>;
export type ExportExhibitionPdfMutationResult = Apollo.MutationResult<ExportExhibitionPdfMutation>;
export type ExportExhibitionPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportExhibitionPdfMutation,
  ExportExhibitionPdfMutationVariables
>;
export const AddExhibitionMediaDocument = gql`
  mutation AddExhibitionMedia($input: AddExhibitionMediaInput!) {
    addExhibitionMedia(input: $input) {
      exhibition {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddExhibitionMediaMutationFn = Apollo.MutationFunction<
  AddExhibitionMediaMutation,
  AddExhibitionMediaMutationVariables
>;

/**
 * __useAddExhibitionMediaMutation__
 *
 * To run a mutation, you first call `useAddExhibitionMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExhibitionMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExhibitionMediaMutation, { data, loading, error }] = useAddExhibitionMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddExhibitionMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddExhibitionMediaMutation,
    AddExhibitionMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddExhibitionMediaMutation, AddExhibitionMediaMutationVariables>(
    AddExhibitionMediaDocument,
    options
  );
}
export type AddExhibitionMediaMutationHookResult = ReturnType<typeof useAddExhibitionMediaMutation>;
export type AddExhibitionMediaMutationResult = Apollo.MutationResult<AddExhibitionMediaMutation>;
export type AddExhibitionMediaMutationOptions = Apollo.BaseMutationOptions<
  AddExhibitionMediaMutation,
  AddExhibitionMediaMutationVariables
>;
export const DeleteExhibitionMediaDocument = gql`
  mutation DeleteExhibitionMedia($input: DeleteExhibitionMediaInput!) {
    deleteExhibitionMedia(input: $input) {
      exhibition {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteExhibitionMediaMutationFn = Apollo.MutationFunction<
  DeleteExhibitionMediaMutation,
  DeleteExhibitionMediaMutationVariables
>;

/**
 * __useDeleteExhibitionMediaMutation__
 *
 * To run a mutation, you first call `useDeleteExhibitionMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExhibitionMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExhibitionMediaMutation, { data, loading, error }] = useDeleteExhibitionMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExhibitionMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExhibitionMediaMutation,
    DeleteExhibitionMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExhibitionMediaMutation, DeleteExhibitionMediaMutationVariables>(
    DeleteExhibitionMediaDocument,
    options
  );
}
export type DeleteExhibitionMediaMutationHookResult = ReturnType<
  typeof useDeleteExhibitionMediaMutation
>;
export type DeleteExhibitionMediaMutationResult =
  Apollo.MutationResult<DeleteExhibitionMediaMutation>;
export type DeleteExhibitionMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteExhibitionMediaMutation,
  DeleteExhibitionMediaMutationVariables
>;
export const DeleteExhibitionImageDocument = gql`
  mutation DeleteExhibitionImage($input: DeleteExhibitionImageInput!) {
    deleteExhibitionImage(input: $input) {
      exhibition {
        id
        name
      }
    }
  }
`;
export type DeleteExhibitionImageMutationFn = Apollo.MutationFunction<
  DeleteExhibitionImageMutation,
  DeleteExhibitionImageMutationVariables
>;

/**
 * __useDeleteExhibitionImageMutation__
 *
 * To run a mutation, you first call `useDeleteExhibitionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExhibitionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExhibitionImageMutation, { data, loading, error }] = useDeleteExhibitionImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExhibitionImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteExhibitionImageMutation,
    DeleteExhibitionImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExhibitionImageMutation, DeleteExhibitionImageMutationVariables>(
    DeleteExhibitionImageDocument,
    options
  );
}
export type DeleteExhibitionImageMutationHookResult = ReturnType<
  typeof useDeleteExhibitionImageMutation
>;
export type DeleteExhibitionImageMutationResult =
  Apollo.MutationResult<DeleteExhibitionImageMutation>;
export type DeleteExhibitionImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteExhibitionImageMutation,
  DeleteExhibitionImageMutationVariables
>;
export const CreateMediaDocument = gql`
  mutation CreateMedia($input: CreateMediaInput!) {
    createMedia(input: $input) {
      media {
        id
        name
        file
        description
        mediaType
        isMainImage
        imageType
        notes
        audioVideoFileType
        creationDate
        fileAuthor
        fileSize
        photographicEquipment
        matrixType
        isGlassPlexyAcetate
      }
    }
  }
`;
export type CreateMediaMutationFn = Apollo.MutationFunction<
  CreateMediaMutation,
  CreateMediaMutationVariables
>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(
    CreateMediaDocument,
    options
  );
}
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaMutation,
  CreateMediaMutationVariables
>;
export const UpdateMediaDocument = gql`
  mutation UpdateMedia($input: UpdateMediaInput!) {
    updateMedia(input: $input) {
      media {
        id
        name
        file
        description
        mediaType
        isMainImage
        imageType
        notes
        audioVideoFileType
        creationDate
        fileAuthor
        fileSize
        photographicEquipment
        matrixType
        isGlassPlexyAcetate
      }
    }
  }
`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(
    UpdateMediaDocument,
    options
  );
}
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;
export const GetStateTechniqueMatrixDocument = gql`
  query GetStateTechniqueMatrix($policyId: ID!) {
    stateTechniqueMatrix(policyId: $policyId) {
      assetsNumber
      country
      sumAssetsValue {
        currency {
          code
        }
        amount
      }
      technique
    }
  }
`;

/**
 * __useGetStateTechniqueMatrixQuery__
 *
 * To run a query within a React component, call `useGetStateTechniqueMatrixQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateTechniqueMatrixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateTechniqueMatrixQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetStateTechniqueMatrixQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStateTechniqueMatrixQuery,
    GetStateTechniqueMatrixQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStateTechniqueMatrixQuery, GetStateTechniqueMatrixQueryVariables>(
    GetStateTechniqueMatrixDocument,
    options
  );
}
export function useGetStateTechniqueMatrixLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStateTechniqueMatrixQuery,
    GetStateTechniqueMatrixQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStateTechniqueMatrixQuery, GetStateTechniqueMatrixQueryVariables>(
    GetStateTechniqueMatrixDocument,
    options
  );
}
export type GetStateTechniqueMatrixQueryHookResult = ReturnType<
  typeof useGetStateTechniqueMatrixQuery
>;
export type GetStateTechniqueMatrixLazyQueryHookResult = ReturnType<
  typeof useGetStateTechniqueMatrixLazyQuery
>;
export type GetStateTechniqueMatrixQueryResult = Apollo.QueryResult<
  GetStateTechniqueMatrixQuery,
  GetStateTechniqueMatrixQueryVariables
>;
export const GetAllPoliciesDocument = gql`
  query GetAllPolicies(
    $orderBy: String
    $policyType: String
    $first: Int
    $offset: Int
    $hasValidCertificate: Boolean
    $policyStatus: String
    $search: String
    $createdByUserGroup: String
    $isExpired: String
  ) {
    allPolicies(
      orderBy: $orderBy
      policyType: $policyType
      policyStatus: $policyStatus
      first: $first
      offset: $offset
      hasValidCertificate: $hasValidCertificate
      search: $search
      isExpired: $isExpired
      createdByUserGroup: $createdByUserGroup
    ) {
      edges {
        node {
          ...policyCoreFields
          ...policyMoneyFields
          isExpired
          hasValidCertificate
          assetsCount
          createdBy {
            name
            userType
          }
        }
      }
      totalCount
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
`;

/**
 * __useGetAllPoliciesQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      policyType: // value for 'policyType'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      hasValidCertificate: // value for 'hasValidCertificate'
 *      policyStatus: // value for 'policyStatus'
 *      search: // value for 'search'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      isExpired: // value for 'isExpired'
 *   },
 * });
 */
export function useGetAllPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>(
    GetAllPoliciesDocument,
    options
  );
}
export function useGetAllPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>(
    GetAllPoliciesDocument,
    options
  );
}
export type GetAllPoliciesQueryHookResult = ReturnType<typeof useGetAllPoliciesQuery>;
export type GetAllPoliciesLazyQueryHookResult = ReturnType<typeof useGetAllPoliciesLazyQuery>;
export type GetAllPoliciesQueryResult = Apollo.QueryResult<
  GetAllPoliciesQuery,
  GetAllPoliciesQueryVariables
>;
export const GetAllPoliciesByTypeDocument = gql`
  query GetAllPoliciesByType(
    $policyType: String!
    $orderBy: String
    $first: Int
    $offset: Int
    $hasValidCertificate: Boolean
    $policyStatus: String
    $search: String
    $createdByUserGroup: String
    $isExpired: String
    $isModifiedAfterDate: String
    $isExpiredAfterDate: Date
  ) {
    allPolicies(
      policyType: $policyType
      orderBy: $orderBy
      policyStatus: $policyStatus
      first: $first
      offset: $offset
      hasValidCertificate: $hasValidCertificate
      search: $search
      isExpired: $isExpired
      createdByUserGroup: $createdByUserGroup
      isModifiedAfterDate: $isModifiedAfterDate
      isExpiredAfterDate: $isExpiredAfterDate
    ) {
      totalCount
      edges {
        node {
          ...policyCoreFields
          ...policyMoneyFields
          createdBy {
            name
            userType
          }
          isExpired
          assetsCount
          hasValidCertificate
          exhibition {
            name
          }
          eventEntity {
            fullName
          }
          modifiedStatus
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
`;

/**
 * __useGetAllPoliciesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesByTypeQuery({
 *   variables: {
 *      policyType: // value for 'policyType'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      hasValidCertificate: // value for 'hasValidCertificate'
 *      policyStatus: // value for 'policyStatus'
 *      search: // value for 'search'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      isExpired: // value for 'isExpired'
 *      isModifiedAfterDate: // value for 'isModifiedAfterDate'
 *      isExpiredAfterDate: // value for 'isExpiredAfterDate'
 *   },
 * });
 */
export function useGetAllPoliciesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPoliciesByTypeQuery,
    GetAllPoliciesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPoliciesByTypeQuery, GetAllPoliciesByTypeQueryVariables>(
    GetAllPoliciesByTypeDocument,
    options
  );
}
export function useGetAllPoliciesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPoliciesByTypeQuery,
    GetAllPoliciesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPoliciesByTypeQuery, GetAllPoliciesByTypeQueryVariables>(
    GetAllPoliciesByTypeDocument,
    options
  );
}
export type GetAllPoliciesByTypeQueryHookResult = ReturnType<typeof useGetAllPoliciesByTypeQuery>;
export type GetAllPoliciesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllPoliciesByTypeLazyQuery
>;
export type GetAllPoliciesByTypeQueryResult = Apollo.QueryResult<
  GetAllPoliciesByTypeQuery,
  GetAllPoliciesByTypeQueryVariables
>;
export const ViewPolicyByIdReducedDocument = gql`
  query ViewPolicyByIdReduced($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
`;

/**
 * __useViewPolicyByIdReducedQuery__
 *
 * To run a query within a React component, call `useViewPolicyByIdReducedQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPolicyByIdReducedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPolicyByIdReducedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewPolicyByIdReducedQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewPolicyByIdReducedQuery,
    ViewPolicyByIdReducedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewPolicyByIdReducedQuery, ViewPolicyByIdReducedQueryVariables>(
    ViewPolicyByIdReducedDocument,
    options
  );
}
export function useViewPolicyByIdReducedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewPolicyByIdReducedQuery,
    ViewPolicyByIdReducedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewPolicyByIdReducedQuery, ViewPolicyByIdReducedQueryVariables>(
    ViewPolicyByIdReducedDocument,
    options
  );
}
export type ViewPolicyByIdReducedQueryHookResult = ReturnType<typeof useViewPolicyByIdReducedQuery>;
export type ViewPolicyByIdReducedLazyQueryHookResult = ReturnType<
  typeof useViewPolicyByIdReducedLazyQuery
>;
export type ViewPolicyByIdReducedQueryResult = Apollo.QueryResult<
  ViewPolicyByIdReducedQuery,
  ViewPolicyByIdReducedQueryVariables
>;
export const ViewPolicyByIdDocument = gql`
  query ViewPolicyById($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
      ...policyMoneyFields
      autoRenewal
      broker {
        id
        fullName
      }
      insuranceCompany {
        id
        fullName
      }
      contractingParty {
        id
        fullName
      }
      eventName
      eventFrom
      eventTo
      eventEntity {
        id
        fullName
      }
      eventLocationEntity {
        id
        name
      }
      catNat
      hasVariableBasket
      coverTerrorism
      coverType
      coveredRiskType
      exemption
      createdAt
      updatedAt
      isExpired
      exchangeRates {
        id
        baseCurrency
        currency
        value
        createdAt
        updatedAt
        uuid
      }
      createdBy {
        id
        createdAt
        name
        email
      }
      updatedBy {
        id
        createdAt
        name
        email
      }
      policyAssets {
        edges {
          node {
            asset {
              id
              creationPeriod
              authorEntity {
                id
                fullName
              }
              assetPredictions {
                id
                jobUuid
                aiReplacmentValue
                aiLiquidityValue
                aiIntrinsicValue
                type
              }
              title
              category
              genericDimensions
              technique
              inventoryNumber
              validated
              actualDateAndPeriod
              mainImage
              createdBy {
                name
              }
              infoNotes
            }
            ...policyAssetFields
            certificateProgressiveNumber
            objectLocationEntity {
              id
              fullName
            }
            objectLocationOffice {
              id
              name
            }
          }
        }
      }
      policyNotes {
        edges {
          node {
            id
            content
          }
        }
      }
      policyDocuments {
        edges {
          node {
            id
            uuid
            createdAt
            createdBy {
              id
              name
            }
            file
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useViewPolicyByIdQuery__
 *
 * To run a query within a React component, call `useViewPolicyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPolicyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPolicyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewPolicyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>(
    ViewPolicyByIdDocument,
    options
  );
}
export function useViewPolicyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewPolicyByIdQuery, ViewPolicyByIdQueryVariables>(
    ViewPolicyByIdDocument,
    options
  );
}
export type ViewPolicyByIdQueryHookResult = ReturnType<typeof useViewPolicyByIdQuery>;
export type ViewPolicyByIdLazyQueryHookResult = ReturnType<typeof useViewPolicyByIdLazyQuery>;
export type ViewPolicyByIdQueryResult = Apollo.QueryResult<
  ViewPolicyByIdQuery,
  ViewPolicyByIdQueryVariables
>;
export const GetPolicyByIdDocument = gql`
  query GetPolicyById($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
      ...policyMoneyFields
      autoRenewal
      broker {
        id
        fullName
      }
      insuranceCompany {
        id
        fullName
      }
      contractingParty {
        id
        fullName
      }
      eventName
      eventFrom
      eventTo
      eventEntity {
        id
        fullName
      }
      eventLocationEntity {
        id
        name
      }
      catNat
      hasVariableBasket
      coverTerrorism
      coverType
      coveredRiskType
      exemption
      isExpired
      exchangeRates {
        id
        baseCurrency
        currency
        value
        createdAt
        updatedAt
        uuid
      }
      policyAssets {
        edges {
          node {
            asset {
              id
              creationPeriod
              authorEntity {
                id
                fullName
              }
              infoNotes
              title
              category
              genericDimensions
              technique
              inventoryNumber
              validated
              actualDateAndPeriod
              mainImage
              createdBy {
                name
              }
            }
            certificateProgressiveNumber
            ...policyAssetFields
            objectLocationEntity {
              id
              fullName
            }
            objectLocationOffice {
              id
              name
            }
          }
        }
      }
      policyNotes {
        edges {
          node {
            id
            content
          }
        }
      }
      policyDocuments {
        edges {
          node {
            id
            uuid
            file
            createdAt
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetPolicyByIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>(
    GetPolicyByIdDocument,
    options
  );
}
export function useGetPolicyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>(
    GetPolicyByIdDocument,
    options
  );
}
export type GetPolicyByIdQueryHookResult = ReturnType<typeof useGetPolicyByIdQuery>;
export type GetPolicyByIdLazyQueryHookResult = ReturnType<typeof useGetPolicyByIdLazyQuery>;
export type GetPolicyByIdQueryResult = Apollo.QueryResult<
  GetPolicyByIdQuery,
  GetPolicyByIdQueryVariables
>;
export const GetPolicyNotesByPolicyIdDocument = gql`
  query GetPolicyNotesByPolicyId($policyId: ID!) {
    allPolicyNotes(policy: $policyId) {
      edges {
        node {
          id
          content
        }
      }
    }
  }
`;

/**
 * __useGetPolicyNotesByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyNotesByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyNotesByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyNotesByPolicyIdQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyNotesByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyNotesByPolicyIdQuery,
    GetPolicyNotesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyNotesByPolicyIdQuery, GetPolicyNotesByPolicyIdQueryVariables>(
    GetPolicyNotesByPolicyIdDocument,
    options
  );
}
export function useGetPolicyNotesByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyNotesByPolicyIdQuery,
    GetPolicyNotesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyNotesByPolicyIdQuery, GetPolicyNotesByPolicyIdQueryVariables>(
    GetPolicyNotesByPolicyIdDocument,
    options
  );
}
export type GetPolicyNotesByPolicyIdQueryHookResult = ReturnType<
  typeof useGetPolicyNotesByPolicyIdQuery
>;
export type GetPolicyNotesByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetPolicyNotesByPolicyIdLazyQuery
>;
export type GetPolicyNotesByPolicyIdQueryResult = Apollo.QueryResult<
  GetPolicyNotesByPolicyIdQuery,
  GetPolicyNotesByPolicyIdQueryVariables
>;
export const GetPolicyDocumentsByPolicyIdDocument = gql`
  query GetPolicyDocumentsByPolicyId($policyId: ID!) {
    allPolicyDocuments(policy: $policyId) {
      edges {
        node {
          id
          file
          uuid
          createdBy {
            name
            id
          }
          createdAt
        }
      }
    }
  }
`;

/**
 * __useGetPolicyDocumentsByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyDocumentsByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDocumentsByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDocumentsByPolicyIdQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyDocumentsByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >(GetPolicyDocumentsByPolicyIdDocument, options);
}
export function useGetPolicyDocumentsByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >(GetPolicyDocumentsByPolicyIdDocument, options);
}
export type GetPolicyDocumentsByPolicyIdQueryHookResult = ReturnType<
  typeof useGetPolicyDocumentsByPolicyIdQuery
>;
export type GetPolicyDocumentsByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetPolicyDocumentsByPolicyIdLazyQuery
>;
export type GetPolicyDocumentsByPolicyIdQueryResult = Apollo.QueryResult<
  GetPolicyDocumentsByPolicyIdQuery,
  GetPolicyDocumentsByPolicyIdQueryVariables
>;
export const GetBasketHistoryByPolicyIdDocument = gql`
  query GetBasketHistoryByPolicyId($id: ID!) {
    policy(id: $id) {
      policybaskethistorySet {
        edges {
          node {
            createdAt
            createdBy {
              name
            }
            reason
            status
            uuid
            overrideChangeDate
            policyassetbaskethistorySet {
              edges {
                node {
                  insuredValue {
                    amountStr
                    amount
                    currency {
                      code
                    }
                  }
                  asset {
                    title
                    author
                    authorEntity {
                      fullName
                    }
                    actualDateAndPeriod
                  }
                  diff
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBasketHistoryByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetBasketHistoryByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasketHistoryByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasketHistoryByPolicyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasketHistoryByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBasketHistoryByPolicyIdQuery,
    GetBasketHistoryByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBasketHistoryByPolicyIdQuery, GetBasketHistoryByPolicyIdQueryVariables>(
    GetBasketHistoryByPolicyIdDocument,
    options
  );
}
export function useGetBasketHistoryByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasketHistoryByPolicyIdQuery,
    GetBasketHistoryByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBasketHistoryByPolicyIdQuery,
    GetBasketHistoryByPolicyIdQueryVariables
  >(GetBasketHistoryByPolicyIdDocument, options);
}
export type GetBasketHistoryByPolicyIdQueryHookResult = ReturnType<
  typeof useGetBasketHistoryByPolicyIdQuery
>;
export type GetBasketHistoryByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetBasketHistoryByPolicyIdLazyQuery
>;
export type GetBasketHistoryByPolicyIdQueryResult = Apollo.QueryResult<
  GetBasketHistoryByPolicyIdQuery,
  GetBasketHistoryByPolicyIdQueryVariables
>;
export const CreatePolicyDocument = gql`
  mutation CreatePolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type CreatePolicyMutationFn = Apollo.MutationFunction<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;

/**
 * __useCreatePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMutation, { data, loading, error }] = useCreatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePolicyMutation, CreatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyMutation, CreatePolicyMutationVariables>(
    CreatePolicyDocument,
    options
  );
}
export type CreatePolicyMutationHookResult = ReturnType<typeof useCreatePolicyMutation>;
export type CreatePolicyMutationResult = Apollo.MutationResult<CreatePolicyMutation>;
export type CreatePolicyMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;
export const CreatePolicyAssetDocument = gql`
  mutation CreatePolicyAsset($input: CreatePolicyAssetInput!) {
    createPolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type CreatePolicyAssetMutationFn = Apollo.MutationFunction<
  CreatePolicyAssetMutation,
  CreatePolicyAssetMutationVariables
>;

/**
 * __useCreatePolicyAssetMutation__
 *
 * To run a mutation, you first call `useCreatePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyAssetMutation, { data, loading, error }] = useCreatePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyAssetMutation,
    CreatePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyAssetMutation, CreatePolicyAssetMutationVariables>(
    CreatePolicyAssetDocument,
    options
  );
}
export type CreatePolicyAssetMutationHookResult = ReturnType<typeof useCreatePolicyAssetMutation>;
export type CreatePolicyAssetMutationResult = Apollo.MutationResult<CreatePolicyAssetMutation>;
export type CreatePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyAssetMutation,
  CreatePolicyAssetMutationVariables
>;
export const CreatePolicyNoteDocument = gql`
  mutation CreatePolicyNote($input: CreatePolicyNoteInput!) {
    createPolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type CreatePolicyNoteMutationFn = Apollo.MutationFunction<
  CreatePolicyNoteMutation,
  CreatePolicyNoteMutationVariables
>;

/**
 * __useCreatePolicyNoteMutation__
 *
 * To run a mutation, you first call `useCreatePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyNoteMutation, { data, loading, error }] = useCreatePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyNoteMutation,
    CreatePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyNoteMutation, CreatePolicyNoteMutationVariables>(
    CreatePolicyNoteDocument,
    options
  );
}
export type CreatePolicyNoteMutationHookResult = ReturnType<typeof useCreatePolicyNoteMutation>;
export type CreatePolicyNoteMutationResult = Apollo.MutationResult<CreatePolicyNoteMutation>;
export type CreatePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyNoteMutation,
  CreatePolicyNoteMutationVariables
>;
export const CreatePolicyDocumentDocument = gql`
  mutation CreatePolicyDocument($input: CreatePolicyDocumentInput!) {
    createPolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type CreatePolicyDocumentMutationFn = Apollo.MutationFunction<
  CreatePolicyDocumentMutation,
  CreatePolicyDocumentMutationVariables
>;

/**
 * __useCreatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyDocumentMutation, { data, loading, error }] = useCreatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyDocumentMutation,
    CreatePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyDocumentMutation, CreatePolicyDocumentMutationVariables>(
    CreatePolicyDocumentDocument,
    options
  );
}
export type CreatePolicyDocumentMutationHookResult = ReturnType<
  typeof useCreatePolicyDocumentMutation
>;
export type CreatePolicyDocumentMutationResult =
  Apollo.MutationResult<CreatePolicyDocumentMutation>;
export type CreatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyDocumentMutation,
  CreatePolicyDocumentMutationVariables
>;
export const UpdatePolicyDocument = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type UpdatePolicyMutationFn = Apollo.MutationFunction<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;

/**
 * __useUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyMutation, { data, loading, error }] = useUpdatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePolicyMutation, UpdatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyMutation, UpdatePolicyMutationVariables>(
    UpdatePolicyDocument,
    options
  );
}
export type UpdatePolicyMutationHookResult = ReturnType<typeof useUpdatePolicyMutation>;
export type UpdatePolicyMutationResult = Apollo.MutationResult<UpdatePolicyMutation>;
export type UpdatePolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;
export const RenewPolicyDocument = gql`
  mutation RenewPolicy($input: RenewPolicyInput!) {
    renewPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type RenewPolicyMutationFn = Apollo.MutationFunction<
  RenewPolicyMutation,
  RenewPolicyMutationVariables
>;

/**
 * __useRenewPolicyMutation__
 *
 * To run a mutation, you first call `useRenewPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewPolicyMutation, { data, loading, error }] = useRenewPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenewPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<RenewPolicyMutation, RenewPolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenewPolicyMutation, RenewPolicyMutationVariables>(
    RenewPolicyDocument,
    options
  );
}
export type RenewPolicyMutationHookResult = ReturnType<typeof useRenewPolicyMutation>;
export type RenewPolicyMutationResult = Apollo.MutationResult<RenewPolicyMutation>;
export type RenewPolicyMutationOptions = Apollo.BaseMutationOptions<
  RenewPolicyMutation,
  RenewPolicyMutationVariables
>;
export const ExtendPolicyDocument = gql`
  mutation ExtendPolicy($input: ExtendPolicyInput!) {
    extendPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type ExtendPolicyMutationFn = Apollo.MutationFunction<
  ExtendPolicyMutation,
  ExtendPolicyMutationVariables
>;

/**
 * __useExtendPolicyMutation__
 *
 * To run a mutation, you first call `useExtendPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendPolicyMutation, { data, loading, error }] = useExtendPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtendPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<ExtendPolicyMutation, ExtendPolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtendPolicyMutation, ExtendPolicyMutationVariables>(
    ExtendPolicyDocument,
    options
  );
}
export type ExtendPolicyMutationHookResult = ReturnType<typeof useExtendPolicyMutation>;
export type ExtendPolicyMutationResult = Apollo.MutationResult<ExtendPolicyMutation>;
export type ExtendPolicyMutationOptions = Apollo.BaseMutationOptions<
  ExtendPolicyMutation,
  ExtendPolicyMutationVariables
>;
export const TerminatePolicyDocument = gql`
  mutation TerminatePolicy($input: TerminatePolicyInput!) {
    terminatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type TerminatePolicyMutationFn = Apollo.MutationFunction<
  TerminatePolicyMutation,
  TerminatePolicyMutationVariables
>;

/**
 * __useTerminatePolicyMutation__
 *
 * To run a mutation, you first call `useTerminatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminatePolicyMutation, { data, loading, error }] = useTerminatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTerminatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminatePolicyMutation,
    TerminatePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TerminatePolicyMutation, TerminatePolicyMutationVariables>(
    TerminatePolicyDocument,
    options
  );
}
export type TerminatePolicyMutationHookResult = ReturnType<typeof useTerminatePolicyMutation>;
export type TerminatePolicyMutationResult = Apollo.MutationResult<TerminatePolicyMutation>;
export type TerminatePolicyMutationOptions = Apollo.BaseMutationOptions<
  TerminatePolicyMutation,
  TerminatePolicyMutationVariables
>;
export const DuplicatePolicyDocument = gql`
  mutation DuplicatePolicy($input: DuplicatePolicyInput!) {
    duplicatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type DuplicatePolicyMutationFn = Apollo.MutationFunction<
  DuplicatePolicyMutation,
  DuplicatePolicyMutationVariables
>;

/**
 * __useDuplicatePolicyMutation__
 *
 * To run a mutation, you first call `useDuplicatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePolicyMutation, { data, loading, error }] = useDuplicatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicatePolicyMutation,
    DuplicatePolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicatePolicyMutation, DuplicatePolicyMutationVariables>(
    DuplicatePolicyDocument,
    options
  );
}
export type DuplicatePolicyMutationHookResult = ReturnType<typeof useDuplicatePolicyMutation>;
export type DuplicatePolicyMutationResult = Apollo.MutationResult<DuplicatePolicyMutation>;
export type DuplicatePolicyMutationOptions = Apollo.BaseMutationOptions<
  DuplicatePolicyMutation,
  DuplicatePolicyMutationVariables
>;
export const UpdatePolicyAssetDocument = gql`
  mutation UpdatePolicyAsset($input: UpdatePolicyAssetInput!) {
    updatePolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type UpdatePolicyAssetMutationFn = Apollo.MutationFunction<
  UpdatePolicyAssetMutation,
  UpdatePolicyAssetMutationVariables
>;

/**
 * __useUpdatePolicyAssetMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAssetMutation, { data, loading, error }] = useUpdatePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyAssetMutation,
    UpdatePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyAssetMutation, UpdatePolicyAssetMutationVariables>(
    UpdatePolicyAssetDocument,
    options
  );
}
export type UpdatePolicyAssetMutationHookResult = ReturnType<typeof useUpdatePolicyAssetMutation>;
export type UpdatePolicyAssetMutationResult = Apollo.MutationResult<UpdatePolicyAssetMutation>;
export type UpdatePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyAssetMutation,
  UpdatePolicyAssetMutationVariables
>;
export const UpdatePolicyAssetGroupDocument = gql`
  mutation UpdatePolicyAssetGroup($input: UpdatePolicyAssetGroupInput!) {
    updatePolicyAssetGroup(input: $input) {
      policyAssets {
        id
      }
    }
  }
`;
export type UpdatePolicyAssetGroupMutationFn = Apollo.MutationFunction<
  UpdatePolicyAssetGroupMutation,
  UpdatePolicyAssetGroupMutationVariables
>;

/**
 * __useUpdatePolicyAssetGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAssetGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAssetGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAssetGroupMutation, { data, loading, error }] = useUpdatePolicyAssetGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyAssetGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyAssetGroupMutation,
    UpdatePolicyAssetGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePolicyAssetGroupMutation,
    UpdatePolicyAssetGroupMutationVariables
  >(UpdatePolicyAssetGroupDocument, options);
}
export type UpdatePolicyAssetGroupMutationHookResult = ReturnType<
  typeof useUpdatePolicyAssetGroupMutation
>;
export type UpdatePolicyAssetGroupMutationResult =
  Apollo.MutationResult<UpdatePolicyAssetGroupMutation>;
export type UpdatePolicyAssetGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyAssetGroupMutation,
  UpdatePolicyAssetGroupMutationVariables
>;
export const UpdatePolicyNoteDocument = gql`
  mutation UpdatePolicyNote($input: UpdatePolicyNoteInput!) {
    updatePolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type UpdatePolicyNoteMutationFn = Apollo.MutationFunction<
  UpdatePolicyNoteMutation,
  UpdatePolicyNoteMutationVariables
>;

/**
 * __useUpdatePolicyNoteMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyNoteMutation, { data, loading, error }] = useUpdatePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyNoteMutation,
    UpdatePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyNoteMutation, UpdatePolicyNoteMutationVariables>(
    UpdatePolicyNoteDocument,
    options
  );
}
export type UpdatePolicyNoteMutationHookResult = ReturnType<typeof useUpdatePolicyNoteMutation>;
export type UpdatePolicyNoteMutationResult = Apollo.MutationResult<UpdatePolicyNoteMutation>;
export type UpdatePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyNoteMutation,
  UpdatePolicyNoteMutationVariables
>;
export const UpdatePolicyDocumentDocument = gql`
  mutation UpdatePolicyDocument($input: UpdatePolicyDocumentInput!) {
    updatePolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type UpdatePolicyDocumentMutationFn = Apollo.MutationFunction<
  UpdatePolicyDocumentMutation,
  UpdatePolicyDocumentMutationVariables
>;

/**
 * __useUpdatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyDocumentMutation, { data, loading, error }] = useUpdatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyDocumentMutation,
    UpdatePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyDocumentMutation, UpdatePolicyDocumentMutationVariables>(
    UpdatePolicyDocumentDocument,
    options
  );
}
export type UpdatePolicyDocumentMutationHookResult = ReturnType<
  typeof useUpdatePolicyDocumentMutation
>;
export type UpdatePolicyDocumentMutationResult =
  Apollo.MutationResult<UpdatePolicyDocumentMutation>;
export type UpdatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyDocumentMutation,
  UpdatePolicyDocumentMutationVariables
>;
export const DeletePolicyDocument = gql`
  mutation DeletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type DeletePolicyMutationFn = Apollo.MutationFunction<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;

/**
 * __useDeletePolicyMutation__
 *
 * To run a mutation, you first call `useDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyMutation, { data, loading, error }] = useDeletePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePolicyMutation, DeletePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyMutation, DeletePolicyMutationVariables>(
    DeletePolicyDocument,
    options
  );
}
export type DeletePolicyMutationHookResult = ReturnType<typeof useDeletePolicyMutation>;
export type DeletePolicyMutationResult = Apollo.MutationResult<DeletePolicyMutation>;
export type DeletePolicyMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;
export const DeletePolicyAssetDocument = gql`
  mutation DeletePolicyAsset($input: DeletePolicyAssetInput!) {
    deletePolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type DeletePolicyAssetMutationFn = Apollo.MutationFunction<
  DeletePolicyAssetMutation,
  DeletePolicyAssetMutationVariables
>;

/**
 * __useDeletePolicyAssetMutation__
 *
 * To run a mutation, you first call `useDeletePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyAssetMutation, { data, loading, error }] = useDeletePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyAssetMutation,
    DeletePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyAssetMutation, DeletePolicyAssetMutationVariables>(
    DeletePolicyAssetDocument,
    options
  );
}
export type DeletePolicyAssetMutationHookResult = ReturnType<typeof useDeletePolicyAssetMutation>;
export type DeletePolicyAssetMutationResult = Apollo.MutationResult<DeletePolicyAssetMutation>;
export type DeletePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyAssetMutation,
  DeletePolicyAssetMutationVariables
>;
export const DeletePolicyNoteDocument = gql`
  mutation DeletePolicyNote($input: DeletePolicyNoteInput!) {
    deletePolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type DeletePolicyNoteMutationFn = Apollo.MutationFunction<
  DeletePolicyNoteMutation,
  DeletePolicyNoteMutationVariables
>;

/**
 * __useDeletePolicyNoteMutation__
 *
 * To run a mutation, you first call `useDeletePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyNoteMutation, { data, loading, error }] = useDeletePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyNoteMutation,
    DeletePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyNoteMutation, DeletePolicyNoteMutationVariables>(
    DeletePolicyNoteDocument,
    options
  );
}
export type DeletePolicyNoteMutationHookResult = ReturnType<typeof useDeletePolicyNoteMutation>;
export type DeletePolicyNoteMutationResult = Apollo.MutationResult<DeletePolicyNoteMutation>;
export type DeletePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyNoteMutation,
  DeletePolicyNoteMutationVariables
>;
export const DeletePolicyDocumentDocument = gql`
  mutation DeletePolicyDocument($input: DeletePolicyDocumentInput!) {
    deletePolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type DeletePolicyDocumentMutationFn = Apollo.MutationFunction<
  DeletePolicyDocumentMutation,
  DeletePolicyDocumentMutationVariables
>;

/**
 * __useDeletePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useDeletePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyDocumentMutation, { data, loading, error }] = useDeletePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyDocumentMutation,
    DeletePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyDocumentMutation, DeletePolicyDocumentMutationVariables>(
    DeletePolicyDocumentDocument,
    options
  );
}
export type DeletePolicyDocumentMutationHookResult = ReturnType<
  typeof useDeletePolicyDocumentMutation
>;
export type DeletePolicyDocumentMutationResult =
  Apollo.MutationResult<DeletePolicyDocumentMutation>;
export type DeletePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyDocumentMutation,
  DeletePolicyDocumentMutationVariables
>;
export const GenerateCertificateDocument = gql`
  mutation GenerateCertificate($input: GenerateCertificateInput!) {
    generateCertificate(input: $input) {
      s3Load
      filesUrl
    }
  }
`;
export type GenerateCertificateMutationFn = Apollo.MutationFunction<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;

/**
 * __useGenerateCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCertificateMutation, { data, loading, error }] = useGenerateCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCertificateMutation,
    GenerateCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateCertificateMutation, GenerateCertificateMutationVariables>(
    GenerateCertificateDocument,
    options
  );
}
export type GenerateCertificateMutationHookResult = ReturnType<
  typeof useGenerateCertificateMutation
>;
export type GenerateCertificateMutationResult = Apollo.MutationResult<GenerateCertificateMutation>;
export type GenerateCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;
export const DeleteCertificateDocument = gql`
  mutation DeleteCertificate($input: DeleteCertificateInput!) {
    deleteCertificate(input: $input) {
      certificate {
        id
      }
    }
  }
`;
export type DeleteCertificateMutationFn = Apollo.MutationFunction<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;

/**
 * __useDeleteCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateMutation, { data, loading, error }] = useDeleteCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateMutation,
    DeleteCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCertificateMutation, DeleteCertificateMutationVariables>(
    DeleteCertificateDocument,
    options
  );
}
export type DeleteCertificateMutationHookResult = ReturnType<typeof useDeleteCertificateMutation>;
export type DeleteCertificateMutationResult = Apollo.MutationResult<DeleteCertificateMutation>;
export type DeleteCertificateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;
export const SignCertificateDocument = gql`
  mutation SignCertificate($input: SignCertificateInput!) {
    signCertificate(input: $input) {
      certificate {
        file
        signed
        signedAt
        signedBy {
          name
        }
      }
    }
  }
`;
export type SignCertificateMutationFn = Apollo.MutationFunction<
  SignCertificateMutation,
  SignCertificateMutationVariables
>;

/**
 * __useSignCertificateMutation__
 *
 * To run a mutation, you first call `useSignCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signCertificateMutation, { data, loading, error }] = useSignCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignCertificateMutation,
    SignCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignCertificateMutation, SignCertificateMutationVariables>(
    SignCertificateDocument,
    options
  );
}
export type SignCertificateMutationHookResult = ReturnType<typeof useSignCertificateMutation>;
export type SignCertificateMutationResult = Apollo.MutationResult<SignCertificateMutation>;
export type SignCertificateMutationOptions = Apollo.BaseMutationOptions<
  SignCertificateMutation,
  SignCertificateMutationVariables
>;
export const GetAllCertificatesByPolicyIdAndIsActiveDocument = gql`
  query GetAllCertificatesByPolicyIdAndIsActive($policy: ID!, $isValid: Boolean!) {
    allCertificates(policy: $policy, isValid: $isValid, orderBy: "-created_at") {
      edges {
        node {
          id
          file
          uuid
          creationDate
          isValid
          language
          progressiveNumber
          createdBy {
            id
            name
          }
          createdAt
          signed
          signedBy {
            id
            name
          }
          metafields
          isSplitted
          signedAt
          lender
          lenderCountry
          policyAssetsCount
          totalInsuredValue {
            amount
            currency {
              code
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByPolicyIdAndIsActiveQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByPolicyIdAndIsActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByPolicyIdAndIsActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByPolicyIdAndIsActiveQuery({
 *   variables: {
 *      policy: // value for 'policy'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useGetAllCertificatesByPolicyIdAndIsActiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >(GetAllCertificatesByPolicyIdAndIsActiveDocument, options);
}
export function useGetAllCertificatesByPolicyIdAndIsActiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >(GetAllCertificatesByPolicyIdAndIsActiveDocument, options);
}
export type GetAllCertificatesByPolicyIdAndIsActiveQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdAndIsActiveQuery
>;
export type GetAllCertificatesByPolicyIdAndIsActiveLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdAndIsActiveLazyQuery
>;
export type GetAllCertificatesByPolicyIdAndIsActiveQueryResult = Apollo.QueryResult<
  GetAllCertificatesByPolicyIdAndIsActiveQuery,
  GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
>;
export const GetAllCertificatesByPolicyIdDocument = gql`
  query GetAllCertificatesByPolicyId($policy: ID!) {
    allCertificates(policy: $policy, orderBy: "-created_at") {
      edges {
        node {
          id
          file
          uuid
          creationDate
          isValid
          language
          createdBy {
            id
            name
          }
          createdAt
          signed
          signedBy {
            id
            name
          }
          progressiveNumber
          metafields
          isSplitted
          signedAt
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByPolicyIdQuery({
 *   variables: {
 *      policy: // value for 'policy'
 *   },
 * });
 */
export function useGetAllCertificatesByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >(GetAllCertificatesByPolicyIdDocument, options);
}
export function useGetAllCertificatesByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificatesByPolicyIdQuery,
    GetAllCertificatesByPolicyIdQueryVariables
  >(GetAllCertificatesByPolicyIdDocument, options);
}
export type GetAllCertificatesByPolicyIdQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdQuery
>;
export type GetAllCertificatesByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdLazyQuery
>;
export type GetAllCertificatesByPolicyIdQueryResult = Apollo.QueryResult<
  GetAllCertificatesByPolicyIdQuery,
  GetAllCertificatesByPolicyIdQueryVariables
>;
export const GetAllCertificatesByTypeDocument = gql`
  query GetAllCertificatesByType($policyType: String!) {
    allCertificates(certificateType: $policyType, orderBy: "-created_at") {
      edges {
        node {
          id
          uuid
          certificateType
          file
          isValid
          language
          updatedAt
          createdAt
          progressiveNumber
          creationDate
          lenderCountry
          mainOfficeCountry
          policy {
            offerCode
            policyCode
            broker {
              fullName
            }
            isExpired
            insuranceCompany {
              fullName
            }
            contractingParty {
              fullName
            }
            exhibition {
              name
            }
            eventLocationEntity {
              name
            }
            totalInsuredValue {
              amount
              currency {
                code
              }
            }
          }
          lender
          shipper
          signed
          isValid
          createdBy {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByTypeQuery({
 *   variables: {
 *      policyType: // value for 'policyType'
 *   },
 * });
 */
export function useGetAllCertificatesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByTypeQuery,
    GetAllCertificatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCertificatesByTypeQuery, GetAllCertificatesByTypeQueryVariables>(
    GetAllCertificatesByTypeDocument,
    options
  );
}
export function useGetAllCertificatesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByTypeQuery,
    GetAllCertificatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCertificatesByTypeQuery, GetAllCertificatesByTypeQueryVariables>(
    GetAllCertificatesByTypeDocument,
    options
  );
}
export type GetAllCertificatesByTypeQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByTypeQuery
>;
export type GetAllCertificatesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByTypeLazyQuery
>;
export type GetAllCertificatesByTypeQueryResult = Apollo.QueryResult<
  GetAllCertificatesByTypeQuery,
  GetAllCertificatesByTypeQueryVariables
>;
export const GetAllCertificateTemplatesByNameDocument = gql`
  query GetAllCertificateTemplatesByName($name: String!) {
    allCertificateTemplates(name: $name, orderBy: "-created_at") {
      edges {
        node {
          id
          file
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificateTemplatesByNameQuery__
 *
 * To run a query within a React component, call `useGetAllCertificateTemplatesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificateTemplatesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificateTemplatesByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAllCertificateTemplatesByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >(GetAllCertificateTemplatesByNameDocument, options);
}
export function useGetAllCertificateTemplatesByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >(GetAllCertificateTemplatesByNameDocument, options);
}
export type GetAllCertificateTemplatesByNameQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByNameQuery
>;
export type GetAllCertificateTemplatesByNameLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByNameLazyQuery
>;
export type GetAllCertificateTemplatesByNameQueryResult = Apollo.QueryResult<
  GetAllCertificateTemplatesByNameQuery,
  GetAllCertificateTemplatesByNameQueryVariables
>;
export const GetAllPolicyAssetsDocument = gql`
  query GetAllPolicyAssets {
    allPolicyAssets(orderBy: "-created_at") {
      edges {
        node {
          asset {
            creationPeriod
            author
            title
          }
          ...policyAssetFields
          policy {
            offerCode
            policyCode
          }
        }
      }
    }
  }
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetAllPolicyAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllPolicyAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPolicyAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPolicyAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPolicyAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>(
    GetAllPolicyAssetsDocument,
    options
  );
}
export function useGetAllPolicyAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPolicyAssetsQuery,
    GetAllPolicyAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>(
    GetAllPolicyAssetsDocument,
    options
  );
}
export type GetAllPolicyAssetsQueryHookResult = ReturnType<typeof useGetAllPolicyAssetsQuery>;
export type GetAllPolicyAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsLazyQuery
>;
export type GetAllPolicyAssetsQueryResult = Apollo.QueryResult<
  GetAllPolicyAssetsQuery,
  GetAllPolicyAssetsQueryVariables
>;
export const GetAllPolicyAssetsByPolicyTypeDocument = gql`
  query GetAllPolicyAssetsByPolicyType($policy_PolicyType: String!) {
    allPolicyAssets(policy_PolicyType: $policy_PolicyType, orderBy: "-created_at") {
      edges {
        node {
          asset {
            creationPeriod
            author
            title
            category
            genericDimensions
            technique
            inventoryNumber
            validated
            actualDateAndPeriod
            mainImage
            createdBy {
              name
            }
          }
          ...policyAssetFields
          policy {
            id
            offerCode
            policyCode
          }
          objectLocationEntity {
            fullName
          }
        }
      }
    }
  }
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetAllPolicyAssetsByPolicyTypeQuery__
 *
 * To run a query within a React component, call `useGetAllPolicyAssetsByPolicyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPolicyAssetsByPolicyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPolicyAssetsByPolicyTypeQuery({
 *   variables: {
 *      policy_PolicyType: // value for 'policy_PolicyType'
 *   },
 * });
 */
export function useGetAllPolicyAssetsByPolicyTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >(GetAllPolicyAssetsByPolicyTypeDocument, options);
}
export function useGetAllPolicyAssetsByPolicyTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >(GetAllPolicyAssetsByPolicyTypeDocument, options);
}
export type GetAllPolicyAssetsByPolicyTypeQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsByPolicyTypeQuery
>;
export type GetAllPolicyAssetsByPolicyTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsByPolicyTypeLazyQuery
>;
export type GetAllPolicyAssetsByPolicyTypeQueryResult = Apollo.QueryResult<
  GetAllPolicyAssetsByPolicyTypeQuery,
  GetAllPolicyAssetsByPolicyTypeQueryVariables
>;
export const GetLastPresentValueByAssetIdDocument = gql`
  query GetLastPresentValueByAssetId($id: ID!) {
    asset(id: $id) {
      presentValues(last: 1) {
        edges {
          node {
            amountCurrency
            amount {
              amount
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLastPresentValueByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetLastPresentValueByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPresentValueByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPresentValueByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLastPresentValueByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >(GetLastPresentValueByAssetIdDocument, options);
}
export function useGetLastPresentValueByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >(GetLastPresentValueByAssetIdDocument, options);
}
export type GetLastPresentValueByAssetIdQueryHookResult = ReturnType<
  typeof useGetLastPresentValueByAssetIdQuery
>;
export type GetLastPresentValueByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetLastPresentValueByAssetIdLazyQuery
>;
export type GetLastPresentValueByAssetIdQueryResult = Apollo.QueryResult<
  GetLastPresentValueByAssetIdQuery,
  GetLastPresentValueByAssetIdQueryVariables
>;
export const GetAssetCarryingValueDocument = gql`
  query GetAssetCarryingValue($id: ID!) {
    asset(id: $id) {
      carryingValueAmount {
        amount
      }
      carryingValueAmountCurrency
    }
  }
`;

/**
 * __useGetAssetCarryingValueQuery__
 *
 * To run a query within a React component, call `useGetAssetCarryingValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetCarryingValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetCarryingValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetCarryingValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetCarryingValueQuery,
    GetAssetCarryingValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetCarryingValueQuery, GetAssetCarryingValueQueryVariables>(
    GetAssetCarryingValueDocument,
    options
  );
}
export function useGetAssetCarryingValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetCarryingValueQuery,
    GetAssetCarryingValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetCarryingValueQuery, GetAssetCarryingValueQueryVariables>(
    GetAssetCarryingValueDocument,
    options
  );
}
export type GetAssetCarryingValueQueryHookResult = ReturnType<typeof useGetAssetCarryingValueQuery>;
export type GetAssetCarryingValueLazyQueryHookResult = ReturnType<
  typeof useGetAssetCarryingValueLazyQuery
>;
export type GetAssetCarryingValueQueryResult = Apollo.QueryResult<
  GetAssetCarryingValueQuery,
  GetAssetCarryingValueQueryVariables
>;
export const GenerateSplitCertificateDocument = gql`
  mutation GenerateSplitCertificate($input: GenerateSplitCertificateInput!) {
    generateSplitCertificate(input: $input) {
      filesUrl
    }
  }
`;
export type GenerateSplitCertificateMutationFn = Apollo.MutationFunction<
  GenerateSplitCertificateMutation,
  GenerateSplitCertificateMutationVariables
>;

/**
 * __useGenerateSplitCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateSplitCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSplitCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSplitCertificateMutation, { data, loading, error }] = useGenerateSplitCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSplitCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSplitCertificateMutation,
    GenerateSplitCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSplitCertificateMutation,
    GenerateSplitCertificateMutationVariables
  >(GenerateSplitCertificateDocument, options);
}
export type GenerateSplitCertificateMutationHookResult = ReturnType<
  typeof useGenerateSplitCertificateMutation
>;
export type GenerateSplitCertificateMutationResult =
  Apollo.MutationResult<GenerateSplitCertificateMutation>;
export type GenerateSplitCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateSplitCertificateMutation,
  GenerateSplitCertificateMutationVariables
>;
export const GenerateGroupedCertificateDocument = gql`
  mutation GenerateGroupedCertificate($input: GenerateGroupedCertificateInput!) {
    generateGroupedCertificate(input: $input) {
      filesUrl
    }
  }
`;
export type GenerateGroupedCertificateMutationFn = Apollo.MutationFunction<
  GenerateGroupedCertificateMutation,
  GenerateGroupedCertificateMutationVariables
>;

/**
 * __useGenerateGroupedCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateGroupedCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateGroupedCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateGroupedCertificateMutation, { data, loading, error }] = useGenerateGroupedCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateGroupedCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateGroupedCertificateMutation,
    GenerateGroupedCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateGroupedCertificateMutation,
    GenerateGroupedCertificateMutationVariables
  >(GenerateGroupedCertificateDocument, options);
}
export type GenerateGroupedCertificateMutationHookResult = ReturnType<
  typeof useGenerateGroupedCertificateMutation
>;
export type GenerateGroupedCertificateMutationResult =
  Apollo.MutationResult<GenerateGroupedCertificateMutation>;
export type GenerateGroupedCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateGroupedCertificateMutation,
  GenerateGroupedCertificateMutationVariables
>;
export const GetAllCertificateTemplatesDocument = gql`
  query GetAllCertificateTemplates {
    allCertificateTemplates(orderBy: "-created_at") {
      edges {
        node {
          id
          file
          name
          uuid
          createdAt
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificateTemplatesQuery__
 *
 * To run a query within a React component, call `useGetAllCertificateTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificateTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificateTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCertificateTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCertificateTemplatesQuery,
    GetAllCertificateTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCertificateTemplatesQuery, GetAllCertificateTemplatesQueryVariables>(
    GetAllCertificateTemplatesDocument,
    options
  );
}
export function useGetAllCertificateTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificateTemplatesQuery,
    GetAllCertificateTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificateTemplatesQuery,
    GetAllCertificateTemplatesQueryVariables
  >(GetAllCertificateTemplatesDocument, options);
}
export type GetAllCertificateTemplatesQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesQuery
>;
export type GetAllCertificateTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesLazyQuery
>;
export type GetAllCertificateTemplatesQueryResult = Apollo.QueryResult<
  GetAllCertificateTemplatesQuery,
  GetAllCertificateTemplatesQueryVariables
>;
export const GetAllCertificateTemplatesByTypeDocument = gql`
  query GetAllCertificateTemplatesByType($templateType: String) {
    allCertificateTemplates(orderBy: "-is_default", templateType: $templateType) {
      edges {
        node {
          id
          file
          name
          uuid
          createdAt
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificateTemplatesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllCertificateTemplatesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificateTemplatesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificateTemplatesByTypeQuery({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useGetAllCertificateTemplatesByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCertificateTemplatesByTypeQuery,
    GetAllCertificateTemplatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificateTemplatesByTypeQuery,
    GetAllCertificateTemplatesByTypeQueryVariables
  >(GetAllCertificateTemplatesByTypeDocument, options);
}
export function useGetAllCertificateTemplatesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificateTemplatesByTypeQuery,
    GetAllCertificateTemplatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificateTemplatesByTypeQuery,
    GetAllCertificateTemplatesByTypeQueryVariables
  >(GetAllCertificateTemplatesByTypeDocument, options);
}
export type GetAllCertificateTemplatesByTypeQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByTypeQuery
>;
export type GetAllCertificateTemplatesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByTypeLazyQuery
>;
export type GetAllCertificateTemplatesByTypeQueryResult = Apollo.QueryResult<
  GetAllCertificateTemplatesByTypeQuery,
  GetAllCertificateTemplatesByTypeQueryVariables
>;
export const CreateCertificateTemplateDocument = gql`
  mutation CreateCertificateTemplate($input: CreateCertificateTemplateInput!) {
    createCertificateTemplate(input: $input) {
      certificateTemplate {
        id
      }
    }
  }
`;
export type CreateCertificateTemplateMutationFn = Apollo.MutationFunction<
  CreateCertificateTemplateMutation,
  CreateCertificateTemplateMutationVariables
>;

/**
 * __useCreateCertificateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateCertificateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificateTemplateMutation, { data, loading, error }] = useCreateCertificateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertificateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCertificateTemplateMutation,
    CreateCertificateTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCertificateTemplateMutation,
    CreateCertificateTemplateMutationVariables
  >(CreateCertificateTemplateDocument, options);
}
export type CreateCertificateTemplateMutationHookResult = ReturnType<
  typeof useCreateCertificateTemplateMutation
>;
export type CreateCertificateTemplateMutationResult =
  Apollo.MutationResult<CreateCertificateTemplateMutation>;
export type CreateCertificateTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateCertificateTemplateMutation,
  CreateCertificateTemplateMutationVariables
>;
export const DeleteCertificateTemplateDocument = gql`
  mutation DeleteCertificateTemplate($input: DeleteCertificateTemplateInput!) {
    deleteCertificateTemplate(input: $input) {
      certificateTemplate {
        id
      }
    }
  }
`;
export type DeleteCertificateTemplateMutationFn = Apollo.MutationFunction<
  DeleteCertificateTemplateMutation,
  DeleteCertificateTemplateMutationVariables
>;

/**
 * __useDeleteCertificateTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateTemplateMutation, { data, loading, error }] = useDeleteCertificateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateTemplateMutation,
    DeleteCertificateTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCertificateTemplateMutation,
    DeleteCertificateTemplateMutationVariables
  >(DeleteCertificateTemplateDocument, options);
}
export type DeleteCertificateTemplateMutationHookResult = ReturnType<
  typeof useDeleteCertificateTemplateMutation
>;
export type DeleteCertificateTemplateMutationResult =
  Apollo.MutationResult<DeleteCertificateTemplateMutation>;
export type DeleteCertificateTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateTemplateMutation,
  DeleteCertificateTemplateMutationVariables
>;
export const GetPolicyDiffChangesDocument = gql`
  query GetPolicyDiffChanges($id: ID!) {
    policy(id: $id) {
      diffChanges {
        changeReason
        changedAt
        field
        old
        new
        historyId
        historyUser {
          name
        }
      }
      history {
        historyId
        historyChangeReason
        historyDate
      }
      ...policyCoreFields
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
`;

/**
 * __useGetPolicyDiffChangesQuery__
 *
 * To run a query within a React component, call `useGetPolicyDiffChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDiffChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDiffChangesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyDiffChangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyDiffChangesQuery,
    GetPolicyDiffChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyDiffChangesQuery, GetPolicyDiffChangesQueryVariables>(
    GetPolicyDiffChangesDocument,
    options
  );
}
export function useGetPolicyDiffChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyDiffChangesQuery,
    GetPolicyDiffChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyDiffChangesQuery, GetPolicyDiffChangesQueryVariables>(
    GetPolicyDiffChangesDocument,
    options
  );
}
export type GetPolicyDiffChangesQueryHookResult = ReturnType<typeof useGetPolicyDiffChangesQuery>;
export type GetPolicyDiffChangesLazyQueryHookResult = ReturnType<
  typeof useGetPolicyDiffChangesLazyQuery
>;
export type GetPolicyDiffChangesQueryResult = Apollo.QueryResult<
  GetPolicyDiffChangesQuery,
  GetPolicyDiffChangesQueryVariables
>;
export const UpdateExchangeRatesDocument = gql`
  mutation UpdateExchangeRates($input: UpdateExchangeRatesInput!) {
    updateExchangeRates(input: $input) {
      exchangeRate {
        id
      }
    }
  }
`;
export type UpdateExchangeRatesMutationFn = Apollo.MutationFunction<
  UpdateExchangeRatesMutation,
  UpdateExchangeRatesMutationVariables
>;

/**
 * __useUpdateExchangeRatesMutation__
 *
 * To run a mutation, you first call `useUpdateExchangeRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExchangeRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExchangeRatesMutation, { data, loading, error }] = useUpdateExchangeRatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExchangeRatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateExchangeRatesMutation,
    UpdateExchangeRatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExchangeRatesMutation, UpdateExchangeRatesMutationVariables>(
    UpdateExchangeRatesDocument,
    options
  );
}
export type UpdateExchangeRatesMutationHookResult = ReturnType<
  typeof useUpdateExchangeRatesMutation
>;
export type UpdateExchangeRatesMutationResult = Apollo.MutationResult<UpdateExchangeRatesMutation>;
export type UpdateExchangeRatesMutationOptions = Apollo.BaseMutationOptions<
  UpdateExchangeRatesMutation,
  UpdateExchangeRatesMutationVariables
>;
export const GetAllRegistriesDocument = gql`
  query GetAllRegistries(
    $first: Int
    $after: String
    $offset: Int
    $createdByUserGroup: String
    $categories_In: [String]
    $orderBy: String
    $search: String
    $isPublic: Boolean
  ) {
    allRegistries(
      first: $first
      after: $after
      offset: $offset
      createdByUserGroup: $createdByUserGroup
      categories_In: $categories_In
      orderBy: $orderBy
      search: $search
      isPublic: $isPublic
    ) {
      edges {
        node {
          ...RegistryFindingFields
          mainOfficeAddress
          role
          actualCompanyContactsNumber
          isAuthor
          isPublic
          companyContact {
            edges {
              node {
                relatedCompany {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      offset: // value for 'offset'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      categories_In: // value for 'categories_In'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useGetAllRegistriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>(
    GetAllRegistriesDocument,
    options
  );
}
export function useGetAllRegistriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>(
    GetAllRegistriesDocument,
    options
  );
}
export type GetAllRegistriesQueryHookResult = ReturnType<typeof useGetAllRegistriesQuery>;
export type GetAllRegistriesLazyQueryHookResult = ReturnType<typeof useGetAllRegistriesLazyQuery>;
export type GetAllRegistriesQueryResult = Apollo.QueryResult<
  GetAllRegistriesQuery,
  GetAllRegistriesQueryVariables
>;
export const GetUniqueIrrelevantRegistryDocument = gql`
  query GetUniqueIrrelevantRegistry {
    allRegistries(isIrrelevantTenantDefault: true) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetUniqueIrrelevantRegistryQuery__
 *
 * To run a query within a React component, call `useGetUniqueIrrelevantRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueIrrelevantRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueIrrelevantRegistryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueIrrelevantRegistryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUniqueIrrelevantRegistryQuery,
    GetUniqueIrrelevantRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUniqueIrrelevantRegistryQuery,
    GetUniqueIrrelevantRegistryQueryVariables
  >(GetUniqueIrrelevantRegistryDocument, options);
}
export function useGetUniqueIrrelevantRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUniqueIrrelevantRegistryQuery,
    GetUniqueIrrelevantRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUniqueIrrelevantRegistryQuery,
    GetUniqueIrrelevantRegistryQueryVariables
  >(GetUniqueIrrelevantRegistryDocument, options);
}
export type GetUniqueIrrelevantRegistryQueryHookResult = ReturnType<
  typeof useGetUniqueIrrelevantRegistryQuery
>;
export type GetUniqueIrrelevantRegistryLazyQueryHookResult = ReturnType<
  typeof useGetUniqueIrrelevantRegistryLazyQuery
>;
export type GetUniqueIrrelevantRegistryQueryResult = Apollo.QueryResult<
  GetUniqueIrrelevantRegistryQuery,
  GetUniqueIrrelevantRegistryQueryVariables
>;
export const GetUniqueAnonymousRegistryDocument = gql`
  query GetUniqueAnonymousRegistry {
    allRegistries(isAnonymousTenantDefault: true) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetUniqueAnonymousRegistryQuery__
 *
 * To run a query within a React component, call `useGetUniqueAnonymousRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueAnonymousRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueAnonymousRegistryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueAnonymousRegistryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUniqueAnonymousRegistryQuery,
    GetUniqueAnonymousRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUniqueAnonymousRegistryQuery, GetUniqueAnonymousRegistryQueryVariables>(
    GetUniqueAnonymousRegistryDocument,
    options
  );
}
export function useGetUniqueAnonymousRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUniqueAnonymousRegistryQuery,
    GetUniqueAnonymousRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUniqueAnonymousRegistryQuery,
    GetUniqueAnonymousRegistryQueryVariables
  >(GetUniqueAnonymousRegistryDocument, options);
}
export type GetUniqueAnonymousRegistryQueryHookResult = ReturnType<
  typeof useGetUniqueAnonymousRegistryQuery
>;
export type GetUniqueAnonymousRegistryLazyQueryHookResult = ReturnType<
  typeof useGetUniqueAnonymousRegistryLazyQuery
>;
export type GetUniqueAnonymousRegistryQueryResult = Apollo.QueryResult<
  GetUniqueAnonymousRegistryQuery,
  GetUniqueAnonymousRegistryQueryVariables
>;
export const GetUniqueUnknownRegistryDocument = gql`
  query GetUniqueUnknownRegistry {
    allRegistries(isUnknownTenantDefault: true) {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetUniqueUnknownRegistryQuery__
 *
 * To run a query within a React component, call `useGetUniqueUnknownRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniqueUnknownRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniqueUnknownRegistryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUniqueUnknownRegistryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUniqueUnknownRegistryQuery,
    GetUniqueUnknownRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUniqueUnknownRegistryQuery, GetUniqueUnknownRegistryQueryVariables>(
    GetUniqueUnknownRegistryDocument,
    options
  );
}
export function useGetUniqueUnknownRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUniqueUnknownRegistryQuery,
    GetUniqueUnknownRegistryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUniqueUnknownRegistryQuery, GetUniqueUnknownRegistryQueryVariables>(
    GetUniqueUnknownRegistryDocument,
    options
  );
}
export type GetUniqueUnknownRegistryQueryHookResult = ReturnType<
  typeof useGetUniqueUnknownRegistryQuery
>;
export type GetUniqueUnknownRegistryLazyQueryHookResult = ReturnType<
  typeof useGetUniqueUnknownRegistryLazyQuery
>;
export type GetUniqueUnknownRegistryQueryResult = Apollo.QueryResult<
  GetUniqueUnknownRegistryQuery,
  GetUniqueUnknownRegistryQueryVariables
>;
export const GetAllRegistriesAuthorsDocument = gql`
  query GetAllRegistriesAuthors {
    allRegistries(orderBy: "-created_at", isAuthor: true) {
      edges {
        node {
          ...RegistryFindingFields
          alias
          birthDate
          deathDate
          authorType
          authoredAssetsCount
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesAuthorsQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesAuthorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesAuthorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRegistriesAuthorsQuery,
    GetAllRegistriesAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesAuthorsQuery, GetAllRegistriesAuthorsQueryVariables>(
    GetAllRegistriesAuthorsDocument,
    options
  );
}
export function useGetAllRegistriesAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRegistriesAuthorsQuery,
    GetAllRegistriesAuthorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesAuthorsQuery, GetAllRegistriesAuthorsQueryVariables>(
    GetAllRegistriesAuthorsDocument,
    options
  );
}
export type GetAllRegistriesAuthorsQueryHookResult = ReturnType<
  typeof useGetAllRegistriesAuthorsQuery
>;
export type GetAllRegistriesAuthorsLazyQueryHookResult = ReturnType<
  typeof useGetAllRegistriesAuthorsLazyQuery
>;
export type GetAllRegistriesAuthorsQueryResult = Apollo.QueryResult<
  GetAllRegistriesAuthorsQuery,
  GetAllRegistriesAuthorsQueryVariables
>;
export const GetAllRegistriesPeopleDocument = gql`
  query GetAllRegistriesPeople {
    allRegistries(orderBy: "-created_at", isCompany: false) {
      edges {
        node {
          ...RegistryFindingFields
          role
          mainOfficeAddress
          actualCompanyContactsNumber
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesPeopleQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesPeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesPeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRegistriesPeopleQuery,
    GetAllRegistriesPeopleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesPeopleQuery, GetAllRegistriesPeopleQueryVariables>(
    GetAllRegistriesPeopleDocument,
    options
  );
}
export function useGetAllRegistriesPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRegistriesPeopleQuery,
    GetAllRegistriesPeopleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesPeopleQuery, GetAllRegistriesPeopleQueryVariables>(
    GetAllRegistriesPeopleDocument,
    options
  );
}
export type GetAllRegistriesPeopleQueryHookResult = ReturnType<
  typeof useGetAllRegistriesPeopleQuery
>;
export type GetAllRegistriesPeopleLazyQueryHookResult = ReturnType<
  typeof useGetAllRegistriesPeopleLazyQuery
>;
export type GetAllRegistriesPeopleQueryResult = Apollo.QueryResult<
  GetAllRegistriesPeopleQuery,
  GetAllRegistriesPeopleQueryVariables
>;
export const GetAllRegistriesCompaniesDocument = gql`
  query GetAllRegistriesCompanies {
    allRegistries(orderBy: "-created_at", isCompany: true) {
      edges {
        node {
          ...RegistryFindingFields
          role
          mainOfficeAddress
          actualCompanyContactsNumber
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRegistriesCompaniesQuery,
    GetAllRegistriesCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesCompaniesQuery, GetAllRegistriesCompaniesQueryVariables>(
    GetAllRegistriesCompaniesDocument,
    options
  );
}
export function useGetAllRegistriesCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRegistriesCompaniesQuery,
    GetAllRegistriesCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllRegistriesCompaniesQuery,
    GetAllRegistriesCompaniesQueryVariables
  >(GetAllRegistriesCompaniesDocument, options);
}
export type GetAllRegistriesCompaniesQueryHookResult = ReturnType<
  typeof useGetAllRegistriesCompaniesQuery
>;
export type GetAllRegistriesCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAllRegistriesCompaniesLazyQuery
>;
export type GetAllRegistriesCompaniesQueryResult = Apollo.QueryResult<
  GetAllRegistriesCompaniesQuery,
  GetAllRegistriesCompaniesQueryVariables
>;
export const GetRegistriesBySearchDocument = gql`
  query GetRegistriesBySearch($search: String!) {
    allRegistries(search: $search) {
      edges {
        node {
          id
          fullName
          categories
          isAuthor
        }
      }
    }
  }
`;

/**
 * __useGetRegistriesBySearchQuery__
 *
 * To run a query within a React component, call `useGetRegistriesBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetRegistriesBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistriesBySearchQuery,
    GetRegistriesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistriesBySearchQuery, GetRegistriesBySearchQueryVariables>(
    GetRegistriesBySearchDocument,
    options
  );
}
export function useGetRegistriesBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistriesBySearchQuery,
    GetRegistriesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistriesBySearchQuery, GetRegistriesBySearchQueryVariables>(
    GetRegistriesBySearchDocument,
    options
  );
}
export type GetRegistriesBySearchQueryHookResult = ReturnType<typeof useGetRegistriesBySearchQuery>;
export type GetRegistriesBySearchLazyQueryHookResult = ReturnType<
  typeof useGetRegistriesBySearchLazyQuery
>;
export type GetRegistriesBySearchQueryResult = Apollo.QueryResult<
  GetRegistriesBySearchQuery,
  GetRegistriesBySearchQueryVariables
>;
export const GetRegistriesByExactSearchDocument = gql`
  query GetRegistriesByExactSearch($exactSearch: String!) {
    registries(exactSearch: $exactSearch) {
      ...RegistryAnagraphicFields
      ...RegistryInfoFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
  ${RegistryInfoFieldsFragmentDoc}
`;

/**
 * __useGetRegistriesByExactSearchQuery__
 *
 * To run a query within a React component, call `useGetRegistriesByExactSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesByExactSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesByExactSearchQuery({
 *   variables: {
 *      exactSearch: // value for 'exactSearch'
 *   },
 * });
 */
export function useGetRegistriesByExactSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistriesByExactSearchQuery,
    GetRegistriesByExactSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistriesByExactSearchQuery, GetRegistriesByExactSearchQueryVariables>(
    GetRegistriesByExactSearchDocument,
    options
  );
}
export function useGetRegistriesByExactSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistriesByExactSearchQuery,
    GetRegistriesByExactSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegistriesByExactSearchQuery,
    GetRegistriesByExactSearchQueryVariables
  >(GetRegistriesByExactSearchDocument, options);
}
export type GetRegistriesByExactSearchQueryHookResult = ReturnType<
  typeof useGetRegistriesByExactSearchQuery
>;
export type GetRegistriesByExactSearchLazyQueryHookResult = ReturnType<
  typeof useGetRegistriesByExactSearchLazyQuery
>;
export type GetRegistriesByExactSearchQueryResult = Apollo.QueryResult<
  GetRegistriesByExactSearchQuery,
  GetRegistriesByExactSearchQueryVariables
>;
export const GetRegistryByIdInFormDocument = gql`
  query GetRegistryByIdInForm($id: ID!) {
    registry(id: $id) {
      id
      fullName
      categories
      businessName
      isAuthor
      isCompany
    }
  }
`;

/**
 * __useGetRegistryByIdInFormQuery__
 *
 * To run a query within a React component, call `useGetRegistryByIdInFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryByIdInFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryByIdInFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryByIdInFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistryByIdInFormQuery,
    GetRegistryByIdInFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryByIdInFormQuery, GetRegistryByIdInFormQueryVariables>(
    GetRegistryByIdInFormDocument,
    options
  );
}
export function useGetRegistryByIdInFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistryByIdInFormQuery,
    GetRegistryByIdInFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryByIdInFormQuery, GetRegistryByIdInFormQueryVariables>(
    GetRegistryByIdInFormDocument,
    options
  );
}
export type GetRegistryByIdInFormQueryHookResult = ReturnType<typeof useGetRegistryByIdInFormQuery>;
export type GetRegistryByIdInFormLazyQueryHookResult = ReturnType<
  typeof useGetRegistryByIdInFormLazyQuery
>;
export type GetRegistryByIdInFormQueryResult = Apollo.QueryResult<
  GetRegistryByIdInFormQuery,
  GetRegistryByIdInFormQueryVariables
>;
export const GetRegistryByIdDocument = gql`
  query GetRegistryById($id: ID!) {
    registry(id: $id) {
      officeRegistry {
        edges {
          node {
            id
            name
            address
            officeAddress {
              raw
              locality
              state
              country
              streetNumber
              route
            }
            city
            zip
            state
            country
            phone
            email
            main
            active
          }
        }
      }
      ...RegistryAnagraphicFields
      ...RegistryInfoFields
      registryAssets(
        type: [
          "restoration_director"
          "owner"
          "lender"
          "author"
          "report_company"
          "appraisal_evaluator"
          "report_examiner"
          "present_value_author"
        ]
      ) {
        asset {
          id
          title
          category
          subcategory
          author
          actualDateAndPeriod
          creationDate
          genericDimensions
          mainImage
          deleted
          validated
          technique
          inventoryNumber
          archiveNumber
          createdBy {
            name
            userType
          }
          authorEntity {
            id
            fullName
          }
        }
        type
        id
        notes
        linkedActualId
      }
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
  ${RegistryInfoFieldsFragmentDoc}
`;

/**
 * __useGetRegistryByIdQuery__
 *
 * To run a query within a React component, call `useGetRegistryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>(
    GetRegistryByIdDocument,
    options
  );
}
export function useGetRegistryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>(
    GetRegistryByIdDocument,
    options
  );
}
export type GetRegistryByIdQueryHookResult = ReturnType<typeof useGetRegistryByIdQuery>;
export type GetRegistryByIdLazyQueryHookResult = ReturnType<typeof useGetRegistryByIdLazyQuery>;
export type GetRegistryByIdQueryResult = Apollo.QueryResult<
  GetRegistryByIdQuery,
  GetRegistryByIdQueryVariables
>;
export const GetRegistryByIdToEditDocument = gql`
  query GetRegistryByIdToEdit($id: ID!) {
    registry(id: $id) {
      ...RegistryAnagraphicFields
      isPublic
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetRegistryByIdToEditQuery__
 *
 * To run a query within a React component, call `useGetRegistryByIdToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryByIdToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryByIdToEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryByIdToEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistryByIdToEditQuery,
    GetRegistryByIdToEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryByIdToEditQuery, GetRegistryByIdToEditQueryVariables>(
    GetRegistryByIdToEditDocument,
    options
  );
}
export function useGetRegistryByIdToEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistryByIdToEditQuery,
    GetRegistryByIdToEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryByIdToEditQuery, GetRegistryByIdToEditQueryVariables>(
    GetRegistryByIdToEditDocument,
    options
  );
}
export type GetRegistryByIdToEditQueryHookResult = ReturnType<typeof useGetRegistryByIdToEditQuery>;
export type GetRegistryByIdToEditLazyQueryHookResult = ReturnType<
  typeof useGetRegistryByIdToEditLazyQuery
>;
export type GetRegistryByIdToEditQueryResult = Apollo.QueryResult<
  GetRegistryByIdToEditQuery,
  GetRegistryByIdToEditQueryVariables
>;
export const CreateRegistryDocument = gql`
  mutation CreateRegistry($input: CreateRegistryInput!) {
    createRegistry(input: $input) {
      registry {
        id
        fullName
        businessName
        alias
        displayName
      }
    }
  }
`;
export type CreateRegistryMutationFn = Apollo.MutationFunction<
  CreateRegistryMutation,
  CreateRegistryMutationVariables
>;

/**
 * __useCreateRegistryMutation__
 *
 * To run a mutation, you first call `useCreateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryMutation, { data, loading, error }] = useCreateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRegistryMutation, CreateRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRegistryMutation, CreateRegistryMutationVariables>(
    CreateRegistryDocument,
    options
  );
}
export type CreateRegistryMutationHookResult = ReturnType<typeof useCreateRegistryMutation>;
export type CreateRegistryMutationResult = Apollo.MutationResult<CreateRegistryMutation>;
export type CreateRegistryMutationOptions = Apollo.BaseMutationOptions<
  CreateRegistryMutation,
  CreateRegistryMutationVariables
>;
export const UpdateRegistryDocument = gql`
  mutation UpdateRegistry($input: UpdateRegistryInput!) {
    updateRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type UpdateRegistryMutationFn = Apollo.MutationFunction<
  UpdateRegistryMutation,
  UpdateRegistryMutationVariables
>;

/**
 * __useUpdateRegistryMutation__
 *
 * To run a mutation, you first call `useUpdateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistryMutation, { data, loading, error }] = useUpdateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRegistryMutation, UpdateRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRegistryMutation, UpdateRegistryMutationVariables>(
    UpdateRegistryDocument,
    options
  );
}
export type UpdateRegistryMutationHookResult = ReturnType<typeof useUpdateRegistryMutation>;
export type UpdateRegistryMutationResult = Apollo.MutationResult<UpdateRegistryMutation>;
export type UpdateRegistryMutationOptions = Apollo.BaseMutationOptions<
  UpdateRegistryMutation,
  UpdateRegistryMutationVariables
>;
export const DeleteRegistryDocument = gql`
  mutation DeleteRegistry($input: DeleteRegistryInput!) {
    deleteRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type DeleteRegistryMutationFn = Apollo.MutationFunction<
  DeleteRegistryMutation,
  DeleteRegistryMutationVariables
>;

/**
 * __useDeleteRegistryMutation__
 *
 * To run a mutation, you first call `useDeleteRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistryMutation, { data, loading, error }] = useDeleteRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRegistryMutation, DeleteRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRegistryMutation, DeleteRegistryMutationVariables>(
    DeleteRegistryDocument,
    options
  );
}
export type DeleteRegistryMutationHookResult = ReturnType<typeof useDeleteRegistryMutation>;
export type DeleteRegistryMutationResult = Apollo.MutationResult<DeleteRegistryMutation>;
export type DeleteRegistryMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegistryMutation,
  DeleteRegistryMutationVariables
>;
export const GetOfficesBySearchDocument = gql`
  query GetOfficesBySearch($search: String!, $registryId: ID) {
    offices(search: $search, registryId: $registryId) {
      ...OfficeAnagraphicFields
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetOfficesBySearchQuery__
 *
 * To run a query within a React component, call `useGetOfficesBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetOfficesBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>(
    GetOfficesBySearchDocument,
    options
  );
}
export function useGetOfficesBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesBySearchQuery,
    GetOfficesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>(
    GetOfficesBySearchDocument,
    options
  );
}
export type GetOfficesBySearchQueryHookResult = ReturnType<typeof useGetOfficesBySearchQuery>;
export type GetOfficesBySearchLazyQueryHookResult = ReturnType<
  typeof useGetOfficesBySearchLazyQuery
>;
export type GetOfficesBySearchQueryResult = Apollo.QueryResult<
  GetOfficesBySearchQuery,
  GetOfficesBySearchQueryVariables
>;
export const GetOfficeByIdDocument = gql`
  query GetOfficeById($id: ID!) {
    office(id: $id) {
      ...OfficeAnagraphicFields
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetOfficeByIdQuery__
 *
 * To run a query within a React component, call `useGetOfficeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfficeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>(
    GetOfficeByIdDocument,
    options
  );
}
export function useGetOfficeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>(
    GetOfficeByIdDocument,
    options
  );
}
export type GetOfficeByIdQueryHookResult = ReturnType<typeof useGetOfficeByIdQuery>;
export type GetOfficeByIdLazyQueryHookResult = ReturnType<typeof useGetOfficeByIdLazyQuery>;
export type GetOfficeByIdQueryResult = Apollo.QueryResult<
  GetOfficeByIdQuery,
  GetOfficeByIdQueryVariables
>;
export const CreateOfficeDocument = gql`
  mutation CreateOffice($input: CreateOfficeInput!) {
    createOffice(input: $input) {
      office {
        ...OfficeAnagraphicFields
      }
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;
export type CreateOfficeMutationFn = Apollo.MutationFunction<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;

/**
 * __useCreateOfficeMutation__
 *
 * To run a mutation, you first call `useCreateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeMutation, { data, loading, error }] = useCreateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOfficeMutation, CreateOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfficeMutation, CreateOfficeMutationVariables>(
    CreateOfficeDocument,
    options
  );
}
export type CreateOfficeMutationHookResult = ReturnType<typeof useCreateOfficeMutation>;
export type CreateOfficeMutationResult = Apollo.MutationResult<CreateOfficeMutation>;
export type CreateOfficeMutationOptions = Apollo.BaseMutationOptions<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;
export const UpdateOfficeDocument = gql`
  mutation UpdateOffice($input: UpdateOfficeInput!) {
    updateOffice(input: $input) {
      office {
        id
        officeAddress {
          raw
          locality
          state
          country
          streetNumber
          route
        }
      }
    }
  }
`;
export type UpdateOfficeMutationFn = Apollo.MutationFunction<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;

/**
 * __useUpdateOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeMutation, { data, loading, error }] = useUpdateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOfficeMutation, UpdateOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOfficeMutation, UpdateOfficeMutationVariables>(
    UpdateOfficeDocument,
    options
  );
}
export type UpdateOfficeMutationHookResult = ReturnType<typeof useUpdateOfficeMutation>;
export type UpdateOfficeMutationResult = Apollo.MutationResult<UpdateOfficeMutation>;
export type UpdateOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;
export const DeleteOfficeDocument = gql`
  mutation DeleteOffice($input: DeleteOfficeInput!) {
    deleteOffice(input: $input) {
      office {
        id
      }
    }
  }
`;
export type DeleteOfficeMutationFn = Apollo.MutationFunction<
  DeleteOfficeMutation,
  DeleteOfficeMutationVariables
>;

/**
 * __useDeleteOfficeMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeMutation, { data, loading, error }] = useDeleteOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOfficeMutation, DeleteOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOfficeMutation, DeleteOfficeMutationVariables>(
    DeleteOfficeDocument,
    options
  );
}
export type DeleteOfficeMutationHookResult = ReturnType<typeof useDeleteOfficeMutation>;
export type DeleteOfficeMutationResult = Apollo.MutationResult<DeleteOfficeMutation>;
export type DeleteOfficeMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfficeMutation,
  DeleteOfficeMutationVariables
>;
export const GetOfficesByRegistryIdDocument = gql`
  query GetOfficesByRegistryId($registryId: ID!) {
    allOffices(registry: $registryId) {
      edges {
        node {
          id
          name
          address
          officeAddress {
            raw
            locality
            state
            country
            streetNumber
            route
          }
          city
          zip
          state
          country
          phone
          email
          main
          active
        }
      }
    }
  }
`;

/**
 * __useGetOfficesByRegistryIdQuery__
 *
 * To run a query within a React component, call `useGetOfficesByRegistryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesByRegistryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesByRegistryIdQuery({
 *   variables: {
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetOfficesByRegistryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfficesByRegistryIdQuery,
    GetOfficesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesByRegistryIdQuery, GetOfficesByRegistryIdQueryVariables>(
    GetOfficesByRegistryIdDocument,
    options
  );
}
export function useGetOfficesByRegistryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesByRegistryIdQuery,
    GetOfficesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficesByRegistryIdQuery, GetOfficesByRegistryIdQueryVariables>(
    GetOfficesByRegistryIdDocument,
    options
  );
}
export type GetOfficesByRegistryIdQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdQuery
>;
export type GetOfficesByRegistryIdLazyQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdLazyQuery
>;
export type GetOfficesByRegistryIdQueryResult = Apollo.QueryResult<
  GetOfficesByRegistryIdQuery,
  GetOfficesByRegistryIdQueryVariables
>;
export const GetCompanyContactsByRegistryIdDocument = gql`
  query GetCompanyContactsByRegistryId($registryId: ID!) {
    allCompanyContacts(relatedCompany: $registryId) {
      edges {
        node {
          id
          main
          companyContact {
            id
            fullName
            alias
            role
            phone
            mobilePhone
            email
            note
            webSite
            printCode
            printName
            countryCode
            taxId
            billingAddress
            isPublic
            mainOfficeAddress
            secondaryMobilePhone
            tertiaryMobilePhone
            secondaryEmail
            tertiaryEmail
            secondaryPhone
            tertiaryPhone
          }
          relatedCompany {
            id
            fullName
            alias
            role
            phone
            mobilePhone
            email
            note
            webSite
            printCode
            printName
            countryCode
            taxId
            billingAddress
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyContactsByRegistryIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyContactsByRegistryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyContactsByRegistryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyContactsByRegistryIdQuery({
 *   variables: {
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetCompanyContactsByRegistryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >(GetCompanyContactsByRegistryIdDocument, options);
}
export function useGetCompanyContactsByRegistryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyContactsByRegistryIdQuery,
    GetCompanyContactsByRegistryIdQueryVariables
  >(GetCompanyContactsByRegistryIdDocument, options);
}
export type GetCompanyContactsByRegistryIdQueryHookResult = ReturnType<
  typeof useGetCompanyContactsByRegistryIdQuery
>;
export type GetCompanyContactsByRegistryIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyContactsByRegistryIdLazyQuery
>;
export type GetCompanyContactsByRegistryIdQueryResult = Apollo.QueryResult<
  GetCompanyContactsByRegistryIdQuery,
  GetCompanyContactsByRegistryIdQueryVariables
>;
export const GetContactCompaniesByRegistryIdDocument = gql`
  query GetContactCompaniesByRegistryId($registryId: ID!) {
    allCompanyContacts(companyContact: $registryId) {
      edges {
        node {
          id
          main
          companyContact {
            id
            fullName
            alias
            role
            phone
            mobilePhone
            email
            note
            webSite
            printCode
            printName
            countryCode
            taxId
            billingAddress
            secondaryEmail
            tertiaryEmail
            secondaryMobilePhone
            tertiaryMobilePhone
          }
          relatedCompany {
            id
            fullName
            alias
            role
            phone
            mobilePhone
            email
            note
            webSite
            printCode
            printName
            countryCode
            taxId
            billingAddress
          }
        }
      }
    }
  }
`;

/**
 * __useGetContactCompaniesByRegistryIdQuery__
 *
 * To run a query within a React component, call `useGetContactCompaniesByRegistryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactCompaniesByRegistryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactCompaniesByRegistryIdQuery({
 *   variables: {
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetContactCompaniesByRegistryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactCompaniesByRegistryIdQuery,
    GetContactCompaniesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContactCompaniesByRegistryIdQuery,
    GetContactCompaniesByRegistryIdQueryVariables
  >(GetContactCompaniesByRegistryIdDocument, options);
}
export function useGetContactCompaniesByRegistryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactCompaniesByRegistryIdQuery,
    GetContactCompaniesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactCompaniesByRegistryIdQuery,
    GetContactCompaniesByRegistryIdQueryVariables
  >(GetContactCompaniesByRegistryIdDocument, options);
}
export type GetContactCompaniesByRegistryIdQueryHookResult = ReturnType<
  typeof useGetContactCompaniesByRegistryIdQuery
>;
export type GetContactCompaniesByRegistryIdLazyQueryHookResult = ReturnType<
  typeof useGetContactCompaniesByRegistryIdLazyQuery
>;
export type GetContactCompaniesByRegistryIdQueryResult = Apollo.QueryResult<
  GetContactCompaniesByRegistryIdQuery,
  GetContactCompaniesByRegistryIdQueryVariables
>;
export const DeleteCompanyContactDocument = gql`
  mutation DeleteCompanyContact($input: DeleteCompanyContactInput!) {
    deleteCompanyContact(input: $input) {
      companyContact {
        id
      }
    }
  }
`;
export type DeleteCompanyContactMutationFn = Apollo.MutationFunction<
  DeleteCompanyContactMutation,
  DeleteCompanyContactMutationVariables
>;

/**
 * __useDeleteCompanyContactMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyContactMutation, { data, loading, error }] = useDeleteCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyContactMutation,
    DeleteCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyContactMutation, DeleteCompanyContactMutationVariables>(
    DeleteCompanyContactDocument,
    options
  );
}
export type DeleteCompanyContactMutationHookResult = ReturnType<
  typeof useDeleteCompanyContactMutation
>;
export type DeleteCompanyContactMutationResult =
  Apollo.MutationResult<DeleteCompanyContactMutation>;
export type DeleteCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyContactMutation,
  DeleteCompanyContactMutationVariables
>;
export const CreateCompanyContactDocument = gql`
  mutation CreateCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      companyContact {
        ...CompanyContactAnagraphicFields
      }
    }
  }
  ${CompanyContactAnagraphicFieldsFragmentDoc}
`;
export type CreateCompanyContactMutationFn = Apollo.MutationFunction<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>;

/**
 * __useCreateCompanyContactMutation__
 *
 * To run a mutation, you first call `useCreateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyContactMutation, { data, loading, error }] = useCreateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyContactMutation,
    CreateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCompanyContactMutation, CreateCompanyContactMutationVariables>(
    CreateCompanyContactDocument,
    options
  );
}
export type CreateCompanyContactMutationHookResult = ReturnType<
  typeof useCreateCompanyContactMutation
>;
export type CreateCompanyContactMutationResult =
  Apollo.MutationResult<CreateCompanyContactMutation>;
export type CreateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>;
export const UpdateCompanyContactDocument = gql`
  mutation UpdateCompanyContact($input: UpdateCompanyContactInput!) {
    updateCompanyContact(input: $input) {
      companyContact {
        id
        main
        companyContact {
          id
          fullName
          alias
          role
          phone
          mobilePhone
          email
          note
          webSite
          printCode
          printName
          countryCode
          taxId
          billingAddress
        }
        relatedCompany {
          id
        }
      }
    }
  }
`;
export type UpdateCompanyContactMutationFn = Apollo.MutationFunction<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>;

/**
 * __useUpdateCompanyContactMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyContactMutation, { data, loading, error }] = useUpdateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyContactMutation,
    UpdateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>(
    UpdateCompanyContactDocument,
    options
  );
}
export type UpdateCompanyContactMutationHookResult = ReturnType<
  typeof useUpdateCompanyContactMutation
>;
export type UpdateCompanyContactMutationResult =
  Apollo.MutationResult<UpdateCompanyContactMutation>;
export type UpdateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>;
export const CreateRegistryWithCompanyContactDocument = gql`
  mutation CreateRegistryWithCompanyContact($input: CreateRegistryWithCompanyContactInput!) {
    createRegistryWithCompanyContact(input: $input) {
      companyContact {
        ...CompanyContactAnagraphicFields
      }
    }
  }
  ${CompanyContactAnagraphicFieldsFragmentDoc}
`;
export type CreateRegistryWithCompanyContactMutationFn = Apollo.MutationFunction<
  CreateRegistryWithCompanyContactMutation,
  CreateRegistryWithCompanyContactMutationVariables
>;

/**
 * __useCreateRegistryWithCompanyContactMutation__
 *
 * To run a mutation, you first call `useCreateRegistryWithCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryWithCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryWithCompanyContactMutation, { data, loading, error }] = useCreateRegistryWithCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegistryWithCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRegistryWithCompanyContactMutation,
    CreateRegistryWithCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRegistryWithCompanyContactMutation,
    CreateRegistryWithCompanyContactMutationVariables
  >(CreateRegistryWithCompanyContactDocument, options);
}
export type CreateRegistryWithCompanyContactMutationHookResult = ReturnType<
  typeof useCreateRegistryWithCompanyContactMutation
>;
export type CreateRegistryWithCompanyContactMutationResult =
  Apollo.MutationResult<CreateRegistryWithCompanyContactMutation>;
export type CreateRegistryWithCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateRegistryWithCompanyContactMutation,
  CreateRegistryWithCompanyContactMutationVariables
>;
export const LinkRegistryWithAssetDocument = gql`
  mutation LinkRegistryWithAsset($input: LinkRegistryWithAssetInput!) {
    linkRegistryWithAsset(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type LinkRegistryWithAssetMutationFn = Apollo.MutationFunction<
  LinkRegistryWithAssetMutation,
  LinkRegistryWithAssetMutationVariables
>;

/**
 * __useLinkRegistryWithAssetMutation__
 *
 * To run a mutation, you first call `useLinkRegistryWithAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkRegistryWithAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkRegistryWithAssetMutation, { data, loading, error }] = useLinkRegistryWithAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkRegistryWithAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkRegistryWithAssetMutation,
    LinkRegistryWithAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkRegistryWithAssetMutation, LinkRegistryWithAssetMutationVariables>(
    LinkRegistryWithAssetDocument,
    options
  );
}
export type LinkRegistryWithAssetMutationHookResult = ReturnType<
  typeof useLinkRegistryWithAssetMutation
>;
export type LinkRegistryWithAssetMutationResult =
  Apollo.MutationResult<LinkRegistryWithAssetMutation>;
export type LinkRegistryWithAssetMutationOptions = Apollo.BaseMutationOptions<
  LinkRegistryWithAssetMutation,
  LinkRegistryWithAssetMutationVariables
>;
export const UnlinkRegistryWithAssetDocument = gql`
  mutation UnlinkRegistryWithAsset($input: UnlinkRegistryWithAssetInput!) {
    unlinkRegistryWithAsset(input: $input) {
      clientMutationId
    }
  }
`;
export type UnlinkRegistryWithAssetMutationFn = Apollo.MutationFunction<
  UnlinkRegistryWithAssetMutation,
  UnlinkRegistryWithAssetMutationVariables
>;

/**
 * __useUnlinkRegistryWithAssetMutation__
 *
 * To run a mutation, you first call `useUnlinkRegistryWithAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkRegistryWithAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkRegistryWithAssetMutation, { data, loading, error }] = useUnlinkRegistryWithAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlinkRegistryWithAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkRegistryWithAssetMutation,
    UnlinkRegistryWithAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnlinkRegistryWithAssetMutation,
    UnlinkRegistryWithAssetMutationVariables
  >(UnlinkRegistryWithAssetDocument, options);
}
export type UnlinkRegistryWithAssetMutationHookResult = ReturnType<
  typeof useUnlinkRegistryWithAssetMutation
>;
export type UnlinkRegistryWithAssetMutationResult =
  Apollo.MutationResult<UnlinkRegistryWithAssetMutation>;
export type UnlinkRegistryWithAssetMutationOptions = Apollo.BaseMutationOptions<
  UnlinkRegistryWithAssetMutation,
  UnlinkRegistryWithAssetMutationVariables
>;
export const SetPublicRegistryDocument = gql`
  mutation SetPublicRegistry($input: SetPublicRegistryInput!) {
    setPublicRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type SetPublicRegistryMutationFn = Apollo.MutationFunction<
  SetPublicRegistryMutation,
  SetPublicRegistryMutationVariables
>;

/**
 * __useSetPublicRegistryMutation__
 *
 * To run a mutation, you first call `useSetPublicRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPublicRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPublicRegistryMutation, { data, loading, error }] = useSetPublicRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPublicRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPublicRegistryMutation,
    SetPublicRegistryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPublicRegistryMutation, SetPublicRegistryMutationVariables>(
    SetPublicRegistryDocument,
    options
  );
}
export type SetPublicRegistryMutationHookResult = ReturnType<typeof useSetPublicRegistryMutation>;
export type SetPublicRegistryMutationResult = Apollo.MutationResult<SetPublicRegistryMutation>;
export type SetPublicRegistryMutationOptions = Apollo.BaseMutationOptions<
  SetPublicRegistryMutation,
  SetPublicRegistryMutationVariables
>;
export const SetPrivateRegistryDocument = gql`
  mutation SetPrivateRegistry($input: SetPrivateRegistryInput!) {
    setPrivateRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type SetPrivateRegistryMutationFn = Apollo.MutationFunction<
  SetPrivateRegistryMutation,
  SetPrivateRegistryMutationVariables
>;

/**
 * __useSetPrivateRegistryMutation__
 *
 * To run a mutation, you first call `useSetPrivateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrivateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrivateRegistryMutation, { data, loading, error }] = useSetPrivateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPrivateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPrivateRegistryMutation,
    SetPrivateRegistryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPrivateRegistryMutation, SetPrivateRegistryMutationVariables>(
    SetPrivateRegistryDocument,
    options
  );
}
export type SetPrivateRegistryMutationHookResult = ReturnType<typeof useSetPrivateRegistryMutation>;
export type SetPrivateRegistryMutationResult = Apollo.MutationResult<SetPrivateRegistryMutation>;
export type SetPrivateRegistryMutationOptions = Apollo.BaseMutationOptions<
  SetPrivateRegistryMutation,
  SetPrivateRegistryMutationVariables
>;
export const UpdateLinkedRegistryAssetDocument = gql`
  mutation UpdateLinkedRegistryAsset($input: UpdateLinkedRegistryAssetInput!) {
    updateLinkedRegistryAsset(input: $input) {
      linkedAssetRegistry {
        id
        notes
      }
    }
  }
`;
export type UpdateLinkedRegistryAssetMutationFn = Apollo.MutationFunction<
  UpdateLinkedRegistryAssetMutation,
  UpdateLinkedRegistryAssetMutationVariables
>;

/**
 * __useUpdateLinkedRegistryAssetMutation__
 *
 * To run a mutation, you first call `useUpdateLinkedRegistryAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkedRegistryAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkedRegistryAssetMutation, { data, loading, error }] = useUpdateLinkedRegistryAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkedRegistryAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkedRegistryAssetMutation,
    UpdateLinkedRegistryAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLinkedRegistryAssetMutation,
    UpdateLinkedRegistryAssetMutationVariables
  >(UpdateLinkedRegistryAssetDocument, options);
}
export type UpdateLinkedRegistryAssetMutationHookResult = ReturnType<
  typeof useUpdateLinkedRegistryAssetMutation
>;
export type UpdateLinkedRegistryAssetMutationResult =
  Apollo.MutationResult<UpdateLinkedRegistryAssetMutation>;
export type UpdateLinkedRegistryAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdateLinkedRegistryAssetMutation,
  UpdateLinkedRegistryAssetMutationVariables
>;
export const GetOfficesByRegistryIdFullDocument = gql`
  query GetOfficesByRegistryIdFull($office: ID!) {
    office(id: $office) {
      id
      name
      address
      officeAddress {
        raw
        locality
        state
        country
        streetNumber
        route
      }
      city
      zip
      state
      country
      phone
      email
      main
      active
    }
  }
`;

/**
 * __useGetOfficesByRegistryIdFullQuery__
 *
 * To run a query within a React component, call `useGetOfficesByRegistryIdFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesByRegistryIdFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesByRegistryIdFullQuery({
 *   variables: {
 *      office: // value for 'office'
 *   },
 * });
 */
export function useGetOfficesByRegistryIdFullQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfficesByRegistryIdFullQuery,
    GetOfficesByRegistryIdFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesByRegistryIdFullQuery, GetOfficesByRegistryIdFullQueryVariables>(
    GetOfficesByRegistryIdFullDocument,
    options
  );
}
export function useGetOfficesByRegistryIdFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesByRegistryIdFullQuery,
    GetOfficesByRegistryIdFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOfficesByRegistryIdFullQuery,
    GetOfficesByRegistryIdFullQueryVariables
  >(GetOfficesByRegistryIdFullDocument, options);
}
export type GetOfficesByRegistryIdFullQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdFullQuery
>;
export type GetOfficesByRegistryIdFullLazyQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdFullLazyQuery
>;
export type GetOfficesByRegistryIdFullQueryResult = Apollo.QueryResult<
  GetOfficesByRegistryIdFullQuery,
  GetOfficesByRegistryIdFullQueryVariables
>;
export const DeleteRegistryLogoDocument = gql`
  mutation DeleteRegistryLogo($input: DeleteRegistryLogoInput!) {
    deleteRegistryLogo(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type DeleteRegistryLogoMutationFn = Apollo.MutationFunction<
  DeleteRegistryLogoMutation,
  DeleteRegistryLogoMutationVariables
>;

/**
 * __useDeleteRegistryLogoMutation__
 *
 * To run a mutation, you first call `useDeleteRegistryLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistryLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistryLogoMutation, { data, loading, error }] = useDeleteRegistryLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegistryLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRegistryLogoMutation,
    DeleteRegistryLogoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRegistryLogoMutation, DeleteRegistryLogoMutationVariables>(
    DeleteRegistryLogoDocument,
    options
  );
}
export type DeleteRegistryLogoMutationHookResult = ReturnType<typeof useDeleteRegistryLogoMutation>;
export type DeleteRegistryLogoMutationResult = Apollo.MutationResult<DeleteRegistryLogoMutation>;
export type DeleteRegistryLogoMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegistryLogoMutation,
  DeleteRegistryLogoMutationVariables
>;
export const GetAllRestorationsDocument = gql`
  query GetAllRestorations {
    allRestorations(orderBy: "-createdAt") {
      edges {
        node {
          ...RestorationFindingFields
          createdAt
          actualMediaStatus
          mediaNumber
        }
      }
    }
  }
  ${RestorationFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRestorationsQuery__
 *
 * To run a query within a React component, call `useGetAllRestorationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRestorationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRestorationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRestorationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRestorationsQuery, GetAllRestorationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRestorationsQuery, GetAllRestorationsQueryVariables>(
    GetAllRestorationsDocument,
    options
  );
}
export function useGetAllRestorationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRestorationsQuery,
    GetAllRestorationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRestorationsQuery, GetAllRestorationsQueryVariables>(
    GetAllRestorationsDocument,
    options
  );
}
export type GetAllRestorationsQueryHookResult = ReturnType<typeof useGetAllRestorationsQuery>;
export type GetAllRestorationsLazyQueryHookResult = ReturnType<
  typeof useGetAllRestorationsLazyQuery
>;
export type GetAllRestorationsQueryResult = Apollo.QueryResult<
  GetAllRestorationsQuery,
  GetAllRestorationsQueryVariables
>;
export const GetRestorationByIdDocument = gql`
  query GetRestorationById($id: ID!) {
    restoration(id: $id) {
      ...RestorationAnagraphicFields
    }
  }
  ${RestorationAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetRestorationByIdQuery__
 *
 * To run a query within a React component, call `useGetRestorationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestorationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestorationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRestorationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRestorationByIdQuery, GetRestorationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestorationByIdQuery, GetRestorationByIdQueryVariables>(
    GetRestorationByIdDocument,
    options
  );
}
export function useGetRestorationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestorationByIdQuery,
    GetRestorationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestorationByIdQuery, GetRestorationByIdQueryVariables>(
    GetRestorationByIdDocument,
    options
  );
}
export type GetRestorationByIdQueryHookResult = ReturnType<typeof useGetRestorationByIdQuery>;
export type GetRestorationByIdLazyQueryHookResult = ReturnType<
  typeof useGetRestorationByIdLazyQuery
>;
export type GetRestorationByIdQueryResult = Apollo.QueryResult<
  GetRestorationByIdQuery,
  GetRestorationByIdQueryVariables
>;
export const CreateRestorationDocument = gql`
  mutation CreateRestoration($input: CreateRestorationInput!) {
    createRestoration(input: $input) {
      restoration {
        id
        restorationStatus
        restorationDirector {
          id
          fullName
        }
        restorationCompany {
          id
          fullName
          businessName
        }
        restorationAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        restorationRestoredBy
        restorationCost {
          amount
          currency {
            code
          }
        }
        restorationInventoryNumber
        restorationStartDate
        restorationEndDate
        restorationLocation
        restorationCollocationFeatures
        restorationDocumentDate
        conservativeDataHistoryInterventions
        conservativeDataConservationStatus
        conservativeDataAssetAlterations
        conservativeDataDeteriorationCauses
        conservativeDataExaminationMethod
        conservativeDataInterventionProposal
        conservativeDataPreliminaryOperations
        conservativeDataIntervention
        conservativeDataInterventionMaterials
        conservativeDataTechnicalMethodologicalConsiderations
        conservativeDataMaintenancePreparationAssembly
        conservativeDataRestorationBibliography
        conservativeDataNotesRestorationSheet
        media {
          edges {
            node {
              id
              name
              file
              description
              mediaType
              isMainImage
              imageType
              notes
              audioVideoFileType
              creationDate
              fileAuthor
              fileSize
              photographicEquipment
              matrixType
              isGlassPlexyAcetate
            }
          }
        }
      }
    }
  }
`;
export type CreateRestorationMutationFn = Apollo.MutationFunction<
  CreateRestorationMutation,
  CreateRestorationMutationVariables
>;

/**
 * __useCreateRestorationMutation__
 *
 * To run a mutation, you first call `useCreateRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestorationMutation, { data, loading, error }] = useCreateRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRestorationMutation,
    CreateRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRestorationMutation, CreateRestorationMutationVariables>(
    CreateRestorationDocument,
    options
  );
}
export type CreateRestorationMutationHookResult = ReturnType<typeof useCreateRestorationMutation>;
export type CreateRestorationMutationResult = Apollo.MutationResult<CreateRestorationMutation>;
export type CreateRestorationMutationOptions = Apollo.BaseMutationOptions<
  CreateRestorationMutation,
  CreateRestorationMutationVariables
>;
export const UpdateRestorationDocument = gql`
  mutation UpdateRestoration($input: UpdateRestorationInput!) {
    updateRestoration(input: $input) {
      restoration {
        id
        restorationStatus
        restorationDirector {
          id
          fullName
        }
        restorationCompany {
          id
          fullName
          businessName
        }
        restorationAsset {
          id
          title
          authorEntity {
            id
            fullName
          }
          actualDateAndPeriod
        }
        restorationRestoredBy
        restorationCost {
          amount
          currency {
            code
          }
        }
        restorationInventoryNumber
        restorationStartDate
        restorationEndDate
        restorationLocation
        restorationCollocationFeatures
        restorationDocumentDate
        conservativeDataHistoryInterventions
        conservativeDataConservationStatus
        conservativeDataAssetAlterations
        conservativeDataDeteriorationCauses
        conservativeDataExaminationMethod
        conservativeDataInterventionProposal
        conservativeDataPreliminaryOperations
        conservativeDataIntervention
        conservativeDataInterventionMaterials
        conservativeDataTechnicalMethodologicalConsiderations
        conservativeDataMaintenancePreparationAssembly
        conservativeDataRestorationBibliography
        conservativeDataNotesRestorationSheet
        media {
          edges {
            node {
              id
              name
              file
              description
              mediaType
              isMainImage
              imageType
              notes
              audioVideoFileType
              creationDate
              fileAuthor
              fileSize
              photographicEquipment
              matrixType
              isGlassPlexyAcetate
            }
          }
        }
      }
    }
  }
`;
export type UpdateRestorationMutationFn = Apollo.MutationFunction<
  UpdateRestorationMutation,
  UpdateRestorationMutationVariables
>;

/**
 * __useUpdateRestorationMutation__
 *
 * To run a mutation, you first call `useUpdateRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestorationMutation, { data, loading, error }] = useUpdateRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRestorationMutation,
    UpdateRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRestorationMutation, UpdateRestorationMutationVariables>(
    UpdateRestorationDocument,
    options
  );
}
export type UpdateRestorationMutationHookResult = ReturnType<typeof useUpdateRestorationMutation>;
export type UpdateRestorationMutationResult = Apollo.MutationResult<UpdateRestorationMutation>;
export type UpdateRestorationMutationOptions = Apollo.BaseMutationOptions<
  UpdateRestorationMutation,
  UpdateRestorationMutationVariables
>;
export const DeleteRestorationDocument = gql`
  mutation DeleteRestoration($input: DeleteRestorationInput!) {
    deleteRestoration(input: $input) {
      deleteRestoration {
        id
      }
    }
  }
`;
export type DeleteRestorationMutationFn = Apollo.MutationFunction<
  DeleteRestorationMutation,
  DeleteRestorationMutationVariables
>;

/**
 * __useDeleteRestorationMutation__
 *
 * To run a mutation, you first call `useDeleteRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestorationMutation, { data, loading, error }] = useDeleteRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRestorationMutation,
    DeleteRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRestorationMutation, DeleteRestorationMutationVariables>(
    DeleteRestorationDocument,
    options
  );
}
export type DeleteRestorationMutationHookResult = ReturnType<typeof useDeleteRestorationMutation>;
export type DeleteRestorationMutationResult = Apollo.MutationResult<DeleteRestorationMutation>;
export type DeleteRestorationMutationOptions = Apollo.BaseMutationOptions<
  DeleteRestorationMutation,
  DeleteRestorationMutationVariables
>;
export const CloseRestorationDocument = gql`
  mutation CloseRestoration($input: CloseRestorationInput!) {
    closeRestoration(input: $input) {
      closeRestoration {
        id
      }
    }
  }
`;
export type CloseRestorationMutationFn = Apollo.MutationFunction<
  CloseRestorationMutation,
  CloseRestorationMutationVariables
>;

/**
 * __useCloseRestorationMutation__
 *
 * To run a mutation, you first call `useCloseRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeRestorationMutation, { data, loading, error }] = useCloseRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseRestorationMutation,
    CloseRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseRestorationMutation, CloseRestorationMutationVariables>(
    CloseRestorationDocument,
    options
  );
}
export type CloseRestorationMutationHookResult = ReturnType<typeof useCloseRestorationMutation>;
export type CloseRestorationMutationResult = Apollo.MutationResult<CloseRestorationMutation>;
export type CloseRestorationMutationOptions = Apollo.BaseMutationOptions<
  CloseRestorationMutation,
  CloseRestorationMutationVariables
>;
export const DetachAssetFromRestorationDocument = gql`
  mutation DetachAssetFromRestoration($input: DetachAssetFromRestorationInput!) {
    detachAssetFromRestoration(input: $input) {
      restoration {
        id
      }
    }
  }
`;
export type DetachAssetFromRestorationMutationFn = Apollo.MutationFunction<
  DetachAssetFromRestorationMutation,
  DetachAssetFromRestorationMutationVariables
>;

/**
 * __useDetachAssetFromRestorationMutation__
 *
 * To run a mutation, you first call `useDetachAssetFromRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetFromRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetFromRestorationMutation, { data, loading, error }] = useDetachAssetFromRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetFromRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetFromRestorationMutation,
    DetachAssetFromRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetFromRestorationMutation,
    DetachAssetFromRestorationMutationVariables
  >(DetachAssetFromRestorationDocument, options);
}
export type DetachAssetFromRestorationMutationHookResult = ReturnType<
  typeof useDetachAssetFromRestorationMutation
>;
export type DetachAssetFromRestorationMutationResult =
  Apollo.MutationResult<DetachAssetFromRestorationMutation>;
export type DetachAssetFromRestorationMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetFromRestorationMutation,
  DetachAssetFromRestorationMutationVariables
>;
export const GetMediaRestorationByIdDocument = gql`
  query GetMediaRestorationById($id: ID!) {
    restoration(id: $id) {
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
          }
        }
      }
    }
  }
`;

/**
 * __useGetMediaRestorationByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaRestorationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaRestorationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaRestorationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMediaRestorationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaRestorationByIdQuery,
    GetMediaRestorationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMediaRestorationByIdQuery, GetMediaRestorationByIdQueryVariables>(
    GetMediaRestorationByIdDocument,
    options
  );
}
export function useGetMediaRestorationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaRestorationByIdQuery,
    GetMediaRestorationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaRestorationByIdQuery, GetMediaRestorationByIdQueryVariables>(
    GetMediaRestorationByIdDocument,
    options
  );
}
export type GetMediaRestorationByIdQueryHookResult = ReturnType<
  typeof useGetMediaRestorationByIdQuery
>;
export type GetMediaRestorationByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaRestorationByIdLazyQuery
>;
export type GetMediaRestorationByIdQueryResult = Apollo.QueryResult<
  GetMediaRestorationByIdQuery,
  GetMediaRestorationByIdQueryVariables
>;
export const AddRestorationMediaDocument = gql`
  mutation AddRestorationMedia($input: AddRestorationMediaInput!) {
    addRestorationMedia(input: $input) {
      restoration {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddRestorationMediaMutationFn = Apollo.MutationFunction<
  AddRestorationMediaMutation,
  AddRestorationMediaMutationVariables
>;

/**
 * __useAddRestorationMediaMutation__
 *
 * To run a mutation, you first call `useAddRestorationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestorationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestorationMediaMutation, { data, loading, error }] = useAddRestorationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRestorationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRestorationMediaMutation,
    AddRestorationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRestorationMediaMutation, AddRestorationMediaMutationVariables>(
    AddRestorationMediaDocument,
    options
  );
}
export type AddRestorationMediaMutationHookResult = ReturnType<
  typeof useAddRestorationMediaMutation
>;
export type AddRestorationMediaMutationResult = Apollo.MutationResult<AddRestorationMediaMutation>;
export type AddRestorationMediaMutationOptions = Apollo.BaseMutationOptions<
  AddRestorationMediaMutation,
  AddRestorationMediaMutationVariables
>;
export const AddMultipleRestorationMediaDocument = gql`
  mutation AddMultipleRestorationMedia($input: AddMultipleRestorationMediaInput!) {
    addMultipleRestorationMedia(input: $input) {
      restoration {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddMultipleRestorationMediaMutationFn = Apollo.MutationFunction<
  AddMultipleRestorationMediaMutation,
  AddMultipleRestorationMediaMutationVariables
>;

/**
 * __useAddMultipleRestorationMediaMutation__
 *
 * To run a mutation, you first call `useAddMultipleRestorationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMultipleRestorationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMultipleRestorationMediaMutation, { data, loading, error }] = useAddMultipleRestorationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMultipleRestorationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMultipleRestorationMediaMutation,
    AddMultipleRestorationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMultipleRestorationMediaMutation,
    AddMultipleRestorationMediaMutationVariables
  >(AddMultipleRestorationMediaDocument, options);
}
export type AddMultipleRestorationMediaMutationHookResult = ReturnType<
  typeof useAddMultipleRestorationMediaMutation
>;
export type AddMultipleRestorationMediaMutationResult =
  Apollo.MutationResult<AddMultipleRestorationMediaMutation>;
export type AddMultipleRestorationMediaMutationOptions = Apollo.BaseMutationOptions<
  AddMultipleRestorationMediaMutation,
  AddMultipleRestorationMediaMutationVariables
>;
export const DeleteRestorationMediaDocument = gql`
  mutation DeleteRestorationMedia($input: DeleteRestorationMediaInput!) {
    deleteRestorationMedia(input: $input) {
      restoration {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteRestorationMediaMutationFn = Apollo.MutationFunction<
  DeleteRestorationMediaMutation,
  DeleteRestorationMediaMutationVariables
>;

/**
 * __useDeleteRestorationMediaMutation__
 *
 * To run a mutation, you first call `useDeleteRestorationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestorationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestorationMediaMutation, { data, loading, error }] = useDeleteRestorationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestorationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRestorationMediaMutation,
    DeleteRestorationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRestorationMediaMutation,
    DeleteRestorationMediaMutationVariables
  >(DeleteRestorationMediaDocument, options);
}
export type DeleteRestorationMediaMutationHookResult = ReturnType<
  typeof useDeleteRestorationMediaMutation
>;
export type DeleteRestorationMediaMutationResult =
  Apollo.MutationResult<DeleteRestorationMediaMutation>;
export type DeleteRestorationMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteRestorationMediaMutation,
  DeleteRestorationMediaMutationVariables
>;
export const ExportRestorationPdfDocument = gql`
  mutation ExportRestorationPdf($input: ExportRestorationPDFInput!) {
    exportRestorationPdf(input: $input) {
      clientMutationId
      url
    }
  }
`;
export type ExportRestorationPdfMutationFn = Apollo.MutationFunction<
  ExportRestorationPdfMutation,
  ExportRestorationPdfMutationVariables
>;

/**
 * __useExportRestorationPdfMutation__
 *
 * To run a mutation, you first call `useExportRestorationPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportRestorationPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportRestorationPdfMutation, { data, loading, error }] = useExportRestorationPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportRestorationPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportRestorationPdfMutation,
    ExportRestorationPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExportRestorationPdfMutation, ExportRestorationPdfMutationVariables>(
    ExportRestorationPdfDocument,
    options
  );
}
export type ExportRestorationPdfMutationHookResult = ReturnType<
  typeof useExportRestorationPdfMutation
>;
export type ExportRestorationPdfMutationResult =
  Apollo.MutationResult<ExportRestorationPdfMutation>;
export type ExportRestorationPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportRestorationPdfMutation,
  ExportRestorationPdfMutationVariables
>;
export const DuplicateRestorationDocument = gql`
  mutation DuplicateRestoration($input: DuplicateRestorationInput!) {
    duplicateRestoration(input: $input) {
      restoration {
        id
      }
    }
  }
`;
export type DuplicateRestorationMutationFn = Apollo.MutationFunction<
  DuplicateRestorationMutation,
  DuplicateRestorationMutationVariables
>;

/**
 * __useDuplicateRestorationMutation__
 *
 * To run a mutation, you first call `useDuplicateRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRestorationMutation, { data, loading, error }] = useDuplicateRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateRestorationMutation,
    DuplicateRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateRestorationMutation, DuplicateRestorationMutationVariables>(
    DuplicateRestorationDocument,
    options
  );
}
export type DuplicateRestorationMutationHookResult = ReturnType<
  typeof useDuplicateRestorationMutation
>;
export type DuplicateRestorationMutationResult =
  Apollo.MutationResult<DuplicateRestorationMutation>;
export type DuplicateRestorationMutationOptions = Apollo.BaseMutationOptions<
  DuplicateRestorationMutation,
  DuplicateRestorationMutationVariables
>;
export const SignRestorationDocument = gql`
  mutation SignRestoration($input: SignRestorationInput!) {
    signRestoration(input: $input) {
      restoration {
        id
      }
    }
  }
`;
export type SignRestorationMutationFn = Apollo.MutationFunction<
  SignRestorationMutation,
  SignRestorationMutationVariables
>;

/**
 * __useSignRestorationMutation__
 *
 * To run a mutation, you first call `useSignRestorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignRestorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signRestorationMutation, { data, loading, error }] = useSignRestorationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignRestorationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignRestorationMutation,
    SignRestorationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignRestorationMutation, SignRestorationMutationVariables>(
    SignRestorationDocument,
    options
  );
}
export type SignRestorationMutationHookResult = ReturnType<typeof useSignRestorationMutation>;
export type SignRestorationMutationResult = Apollo.MutationResult<SignRestorationMutation>;
export type SignRestorationMutationOptions = Apollo.BaseMutationOptions<
  SignRestorationMutation,
  SignRestorationMutationVariables
>;
export const TokenAuthDocument = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      payload
      refreshToken
      refreshExpiresIn
      user {
        name
        country {
          code
        }
        currency
        dateJoined
        email
        id
        isActive
        isStaff
        isSuperuser
        language
        lastLogin
        phone
        timezone
        userType
        username
        uuid
        userPermissions {
          edges {
            node {
              id
              codename
              name
              contentType {
                model
                id
              }
            }
          }
        }
      }
      algoliaUserKey
    }
  }
`;
export type TokenAuthMutationFn = Apollo.MutationFunction<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(
    TokenAuthDocument,
    options
  );
}
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const VerifyShareableTokenDocument = gql`
  mutation VerifyShareableToken($input: VerifyShareableTokenInput!) {
    verifyShareableToken(input: $input) {
      token
      user {
        name
        email
      }
    }
  }
`;
export type VerifyShareableTokenMutationFn = Apollo.MutationFunction<
  VerifyShareableTokenMutation,
  VerifyShareableTokenMutationVariables
>;

/**
 * __useVerifyShareableTokenMutation__
 *
 * To run a mutation, you first call `useVerifyShareableTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyShareableTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyShareableTokenMutation, { data, loading, error }] = useVerifyShareableTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyShareableTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyShareableTokenMutation,
    VerifyShareableTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyShareableTokenMutation, VerifyShareableTokenMutationVariables>(
    VerifyShareableTokenDocument,
    options
  );
}
export type VerifyShareableTokenMutationHookResult = ReturnType<
  typeof useVerifyShareableTokenMutation
>;
export type VerifyShareableTokenMutationResult =
  Apollo.MutationResult<VerifyShareableTokenMutation>;
export type VerifyShareableTokenMutationOptions = Apollo.BaseMutationOptions<
  VerifyShareableTokenMutation,
  VerifyShareableTokenMutationVariables
>;
export const ObtainShareableTokenDocument = gql`
  mutation ObtainShareableToken($input: ObtainShareableTokenInput!) {
    shareableToken(input: $input) {
      token
    }
  }
`;
export type ObtainShareableTokenMutationFn = Apollo.MutationFunction<
  ObtainShareableTokenMutation,
  ObtainShareableTokenMutationVariables
>;

/**
 * __useObtainShareableTokenMutation__
 *
 * To run a mutation, you first call `useObtainShareableTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useObtainShareableTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [obtainShareableTokenMutation, { data, loading, error }] = useObtainShareableTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useObtainShareableTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ObtainShareableTokenMutation,
    ObtainShareableTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ObtainShareableTokenMutation, ObtainShareableTokenMutationVariables>(
    ObtainShareableTokenDocument,
    options
  );
}
export type ObtainShareableTokenMutationHookResult = ReturnType<
  typeof useObtainShareableTokenMutation
>;
export type ObtainShareableTokenMutationResult =
  Apollo.MutationResult<ObtainShareableTokenMutation>;
export type ObtainShareableTokenMutationOptions = Apollo.BaseMutationOptions<
  ObtainShareableTokenMutation,
  ObtainShareableTokenMutationVariables
>;
export const GetTransportationsByIdDocument = gql`
  query GetTransportationsById($id: ID!) {
    transportation(id: $id) {
      ...TransportationFields
      media {
        edges {
          node {
            id
            name
            file
            description
            mediaType
            isMainImage
            imageType
            notes
            audioVideoFileType
            creationDate
            fileAuthor
            fileSize
            photographicEquipment
            matrixType
            isGlassPlexyAcetate
            createdAt
          }
        }
      }
      transportationassetsSet {
        edges {
          node {
            id
            asset {
              id
              mainImage
              category
              authorEntity {
                id
                fullName
              }
              author
              title
              actualDateAndPeriod
              genericDimensions
              technique
              inventoryNumber
              netWeight
              netWeightUnit
              grossWeight
              createdBy {
                name
                userType
              }
            }
            package {
              grossWeight
              grossWeightUnitOfMeasure
              netWeight
              netWeightUnitOfMeasure
              height
              width
              depth
              linearUnitOfMeasure
              packagingType
              packageGrossWeightInfo
              packageNetWeightInfo
              packageDimensionsInfo
              packageNetWeightInfo
            }
          }
        }
      }
    }
  }
  ${TransportationFieldsFragmentDoc}
`;

/**
 * __useGetTransportationsByIdQuery__
 *
 * To run a query within a React component, call `useGetTransportationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransportationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransportationsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransportationsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransportationsByIdQuery,
    GetTransportationsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransportationsByIdQuery, GetTransportationsByIdQueryVariables>(
    GetTransportationsByIdDocument,
    options
  );
}
export function useGetTransportationsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransportationsByIdQuery,
    GetTransportationsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransportationsByIdQuery, GetTransportationsByIdQueryVariables>(
    GetTransportationsByIdDocument,
    options
  );
}
export type GetTransportationsByIdQueryHookResult = ReturnType<
  typeof useGetTransportationsByIdQuery
>;
export type GetTransportationsByIdLazyQueryHookResult = ReturnType<
  typeof useGetTransportationsByIdLazyQuery
>;
export type GetTransportationsByIdQueryResult = Apollo.QueryResult<
  GetTransportationsByIdQuery,
  GetTransportationsByIdQueryVariables
>;
export const GetAllTransportationsDocument = gql`
  query GetAllTransportations(
    $first: Int
    $offset: Int
    $createdByUserGroup: String
    $status: String
    $isTransportationExpired: String
    $search: String
    $isConfirmed: Boolean
    $orderBy: String
  ) {
    allTransportations(
      first: $first
      offset: $offset
      createdByUserGroup: $createdByUserGroup
      status: $status
      isTransportationExpired: $isTransportationExpired
      search: $search
      isConfirmed: $isConfirmed
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          transportationId
          numberOfAssets
          numberOfMedia
          shipper {
            id
            fullName
          }
          createdBy {
            id
            name
            email
          }
          isConfirmed
          status
          deliveryDatetime
          pickupDatetime
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetAllTransportationsQuery__
 *
 * To run a query within a React component, call `useGetAllTransportationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransportationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransportationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      status: // value for 'status'
 *      isTransportationExpired: // value for 'isTransportationExpired'
 *      search: // value for 'search'
 *      isConfirmed: // value for 'isConfirmed'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllTransportationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTransportationsQuery,
    GetAllTransportationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTransportationsQuery, GetAllTransportationsQueryVariables>(
    GetAllTransportationsDocument,
    options
  );
}
export function useGetAllTransportationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTransportationsQuery,
    GetAllTransportationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTransportationsQuery, GetAllTransportationsQueryVariables>(
    GetAllTransportationsDocument,
    options
  );
}
export type GetAllTransportationsQueryHookResult = ReturnType<typeof useGetAllTransportationsQuery>;
export type GetAllTransportationsLazyQueryHookResult = ReturnType<
  typeof useGetAllTransportationsLazyQuery
>;
export type GetAllTransportationsQueryResult = Apollo.QueryResult<
  GetAllTransportationsQuery,
  GetAllTransportationsQueryVariables
>;
export const GetAllTransportationsAssetsDocument = gql`
  query GetAllTransportationsAssets(
    $first: Int
    $offset: Int
    $createdByUserGroup: String
    $isTransportationAssetExpired: String
    $search: String
    $orderBy: String
    $showTransportationInventory: Boolean
  ) {
    allTransportationAssets(
      first: $first
      offset: $offset
      createdByUserGroup: $createdByUserGroup
      isTransportationAssetExpired: $isTransportationAssetExpired
      search: $search
      orderBy: $orderBy
      showTransportationInventory: $showTransportationInventory
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          uuid
          parentType
          asset {
            id
            mainImage
            category
            title
            creationDate
            actualDateAndPeriod
            technique
            netWeight
            netWeightUnit
            genericDimensions
            creationDate
            inventoryNumber
            createdBy {
              id
              name
              email
            }
            authorEntity {
              id
              fullName
            }
          }
          transportation {
            id
            deliveryDatetime
            pickupDatetime
            transportationId
          }
          package {
            ...PackageFields
          }
          createdBy {
            id
            name
            email
          }
        }
      }
      totalCount
    }
  }
  ${PackageFieldsFragmentDoc}
`;

/**
 * __useGetAllTransportationsAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllTransportationsAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTransportationsAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTransportationsAssetsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      createdByUserGroup: // value for 'createdByUserGroup'
 *      isTransportationAssetExpired: // value for 'isTransportationAssetExpired'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      showTransportationInventory: // value for 'showTransportationInventory'
 *   },
 * });
 */
export function useGetAllTransportationsAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTransportationsAssetsQuery,
    GetAllTransportationsAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllTransportationsAssetsQuery,
    GetAllTransportationsAssetsQueryVariables
  >(GetAllTransportationsAssetsDocument, options);
}
export function useGetAllTransportationsAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTransportationsAssetsQuery,
    GetAllTransportationsAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllTransportationsAssetsQuery,
    GetAllTransportationsAssetsQueryVariables
  >(GetAllTransportationsAssetsDocument, options);
}
export type GetAllTransportationsAssetsQueryHookResult = ReturnType<
  typeof useGetAllTransportationsAssetsQuery
>;
export type GetAllTransportationsAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAllTransportationsAssetsLazyQuery
>;
export type GetAllTransportationsAssetsQueryResult = Apollo.QueryResult<
  GetAllTransportationsAssetsQuery,
  GetAllTransportationsAssetsQueryVariables
>;
export const CreateTransportationDocument = gql`
  mutation CreateTransportation($input: CreateTransportationInput!) {
    createTransportation(input: $input) {
      transportation {
        ...TransportationFields
      }
    }
  }
  ${TransportationFieldsFragmentDoc}
`;
export type CreateTransportationMutationFn = Apollo.MutationFunction<
  CreateTransportationMutation,
  CreateTransportationMutationVariables
>;

/**
 * __useCreateTransportationMutation__
 *
 * To run a mutation, you first call `useCreateTransportationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransportationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransportationMutation, { data, loading, error }] = useCreateTransportationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTransportationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTransportationMutation,
    CreateTransportationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTransportationMutation, CreateTransportationMutationVariables>(
    CreateTransportationDocument,
    options
  );
}
export type CreateTransportationMutationHookResult = ReturnType<
  typeof useCreateTransportationMutation
>;
export type CreateTransportationMutationResult =
  Apollo.MutationResult<CreateTransportationMutation>;
export type CreateTransportationMutationOptions = Apollo.BaseMutationOptions<
  CreateTransportationMutation,
  CreateTransportationMutationVariables
>;
export const UpdateTransportationDocument = gql`
  mutation UpdateTransportation($input: UpdateTransportationInput!) {
    updateTransportation(input: $input) {
      transportation {
        id
      }
    }
  }
`;
export type UpdateTransportationMutationFn = Apollo.MutationFunction<
  UpdateTransportationMutation,
  UpdateTransportationMutationVariables
>;

/**
 * __useUpdateTransportationMutation__
 *
 * To run a mutation, you first call `useUpdateTransportationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransportationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransportationMutation, { data, loading, error }] = useUpdateTransportationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransportationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransportationMutation,
    UpdateTransportationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTransportationMutation, UpdateTransportationMutationVariables>(
    UpdateTransportationDocument,
    options
  );
}
export type UpdateTransportationMutationHookResult = ReturnType<
  typeof useUpdateTransportationMutation
>;
export type UpdateTransportationMutationResult =
  Apollo.MutationResult<UpdateTransportationMutation>;
export type UpdateTransportationMutationOptions = Apollo.BaseMutationOptions<
  UpdateTransportationMutation,
  UpdateTransportationMutationVariables
>;
export const DeleteTransportationDocument = gql`
  mutation DeleteTransportation($input: DeleteTransportationInput!) {
    deleteTransportation(input: $input) {
      transportation {
        id
      }
    }
  }
`;
export type DeleteTransportationMutationFn = Apollo.MutationFunction<
  DeleteTransportationMutation,
  DeleteTransportationMutationVariables
>;

/**
 * __useDeleteTransportationMutation__
 *
 * To run a mutation, you first call `useDeleteTransportationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransportationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransportationMutation, { data, loading, error }] = useDeleteTransportationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTransportationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTransportationMutation,
    DeleteTransportationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTransportationMutation, DeleteTransportationMutationVariables>(
    DeleteTransportationDocument,
    options
  );
}
export type DeleteTransportationMutationHookResult = ReturnType<
  typeof useDeleteTransportationMutation
>;
export type DeleteTransportationMutationResult =
  Apollo.MutationResult<DeleteTransportationMutation>;
export type DeleteTransportationMutationOptions = Apollo.BaseMutationOptions<
  DeleteTransportationMutation,
  DeleteTransportationMutationVariables
>;
export const DetachAssetsFromTransportationDocument = gql`
  mutation DetachAssetsFromTransportation($input: RemoveTransportationAssetsInput!) {
    removeTransportationAssets(input: $input) {
      transportation {
        id
        transportationassetsSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DetachAssetsFromTransportationMutationFn = Apollo.MutationFunction<
  DetachAssetsFromTransportationMutation,
  DetachAssetsFromTransportationMutationVariables
>;

/**
 * __useDetachAssetsFromTransportationMutation__
 *
 * To run a mutation, you first call `useDetachAssetsFromTransportationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachAssetsFromTransportationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachAssetsFromTransportationMutation, { data, loading, error }] = useDetachAssetsFromTransportationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachAssetsFromTransportationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachAssetsFromTransportationMutation,
    DetachAssetsFromTransportationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachAssetsFromTransportationMutation,
    DetachAssetsFromTransportationMutationVariables
  >(DetachAssetsFromTransportationDocument, options);
}
export type DetachAssetsFromTransportationMutationHookResult = ReturnType<
  typeof useDetachAssetsFromTransportationMutation
>;
export type DetachAssetsFromTransportationMutationResult =
  Apollo.MutationResult<DetachAssetsFromTransportationMutation>;
export type DetachAssetsFromTransportationMutationOptions = Apollo.BaseMutationOptions<
  DetachAssetsFromTransportationMutation,
  DetachAssetsFromTransportationMutationVariables
>;
export const AddTransportationMediaDocument = gql`
  mutation AddTransportationMedia($input: AddTransportationMediaInput!) {
    addTransportationMedia(input: $input) {
      transportation {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type AddTransportationMediaMutationFn = Apollo.MutationFunction<
  AddTransportationMediaMutation,
  AddTransportationMediaMutationVariables
>;

/**
 * __useAddTransportationMediaMutation__
 *
 * To run a mutation, you first call `useAddTransportationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTransportationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTransportationMediaMutation, { data, loading, error }] = useAddTransportationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTransportationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTransportationMediaMutation,
    AddTransportationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTransportationMediaMutation,
    AddTransportationMediaMutationVariables
  >(AddTransportationMediaDocument, options);
}
export type AddTransportationMediaMutationHookResult = ReturnType<
  typeof useAddTransportationMediaMutation
>;
export type AddTransportationMediaMutationResult =
  Apollo.MutationResult<AddTransportationMediaMutation>;
export type AddTransportationMediaMutationOptions = Apollo.BaseMutationOptions<
  AddTransportationMediaMutation,
  AddTransportationMediaMutationVariables
>;
export const DeleteTransportationMediaDocument = gql`
  mutation DeleteTransportationMedia($input: DeleteTransportationMediaInput!) {
    deleteTransportationMedia(input: $input) {
      transportation {
        id
        media {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type DeleteTransportationMediaMutationFn = Apollo.MutationFunction<
  DeleteTransportationMediaMutation,
  DeleteTransportationMediaMutationVariables
>;

/**
 * __useDeleteTransportationMediaMutation__
 *
 * To run a mutation, you first call `useDeleteTransportationMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransportationMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransportationMediaMutation, { data, loading, error }] = useDeleteTransportationMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTransportationMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTransportationMediaMutation,
    DeleteTransportationMediaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTransportationMediaMutation,
    DeleteTransportationMediaMutationVariables
  >(DeleteTransportationMediaDocument, options);
}
export type DeleteTransportationMediaMutationHookResult = ReturnType<
  typeof useDeleteTransportationMediaMutation
>;
export type DeleteTransportationMediaMutationResult =
  Apollo.MutationResult<DeleteTransportationMediaMutation>;
export type DeleteTransportationMediaMutationOptions = Apollo.BaseMutationOptions<
  DeleteTransportationMediaMutation,
  DeleteTransportationMediaMutationVariables
>;
export const ExportTransportationLabelsDocument = gql`
  mutation ExportTransportationLabels($input: ExportTransportationLabelsInput!) {
    exportTransportationLabels(input: $input) {
      url
    }
  }
`;
export type ExportTransportationLabelsMutationFn = Apollo.MutationFunction<
  ExportTransportationLabelsMutation,
  ExportTransportationLabelsMutationVariables
>;

/**
 * __useExportTransportationLabelsMutation__
 *
 * To run a mutation, you first call `useExportTransportationLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTransportationLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTransportationLabelsMutation, { data, loading, error }] = useExportTransportationLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTransportationLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTransportationLabelsMutation,
    ExportTransportationLabelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTransportationLabelsMutation,
    ExportTransportationLabelsMutationVariables
  >(ExportTransportationLabelsDocument, options);
}
export type ExportTransportationLabelsMutationHookResult = ReturnType<
  typeof useExportTransportationLabelsMutation
>;
export type ExportTransportationLabelsMutationResult =
  Apollo.MutationResult<ExportTransportationLabelsMutation>;
export type ExportTransportationLabelsMutationOptions = Apollo.BaseMutationOptions<
  ExportTransportationLabelsMutation,
  ExportTransportationLabelsMutationVariables
>;
export const UpdateTransportationStatusDocument = gql`
  mutation UpdateTransportationStatus($input: UpdateTransportationStatusInput!) {
    updateTransportationStatus(input: $input) {
      transportation {
        id
        status
      }
    }
  }
`;
export type UpdateTransportationStatusMutationFn = Apollo.MutationFunction<
  UpdateTransportationStatusMutation,
  UpdateTransportationStatusMutationVariables
>;

/**
 * __useUpdateTransportationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTransportationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransportationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransportationStatusMutation, { data, loading, error }] = useUpdateTransportationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransportationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTransportationStatusMutation,
    UpdateTransportationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTransportationStatusMutation,
    UpdateTransportationStatusMutationVariables
  >(UpdateTransportationStatusDocument, options);
}
export type UpdateTransportationStatusMutationHookResult = ReturnType<
  typeof useUpdateTransportationStatusMutation
>;
export type UpdateTransportationStatusMutationResult =
  Apollo.MutationResult<UpdateTransportationStatusMutation>;
export type UpdateTransportationStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTransportationStatusMutation,
  UpdateTransportationStatusMutationVariables
>;
export const DuplicateTransportationDocument = gql`
  mutation DuplicateTransportation($input: DuplicateTransportationInput!) {
    duplicateTransportation(input: $input) {
      transportation {
        id
      }
    }
  }
`;
export type DuplicateTransportationMutationFn = Apollo.MutationFunction<
  DuplicateTransportationMutation,
  DuplicateTransportationMutationVariables
>;

/**
 * __useDuplicateTransportationMutation__
 *
 * To run a mutation, you first call `useDuplicateTransportationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTransportationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTransportationMutation, { data, loading, error }] = useDuplicateTransportationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateTransportationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateTransportationMutation,
    DuplicateTransportationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateTransportationMutation,
    DuplicateTransportationMutationVariables
  >(DuplicateTransportationDocument, options);
}
export type DuplicateTransportationMutationHookResult = ReturnType<
  typeof useDuplicateTransportationMutation
>;
export type DuplicateTransportationMutationResult =
  Apollo.MutationResult<DuplicateTransportationMutation>;
export type DuplicateTransportationMutationOptions = Apollo.BaseMutationOptions<
  DuplicateTransportationMutation,
  DuplicateTransportationMutationVariables
>;
export const ExportTransportationBillsDocument = gql`
  mutation ExportTransportationBills($input: ExportTransportationBillsInput!) {
    exportTransportationBills(input: $input) {
      url
    }
  }
`;
export type ExportTransportationBillsMutationFn = Apollo.MutationFunction<
  ExportTransportationBillsMutation,
  ExportTransportationBillsMutationVariables
>;

/**
 * __useExportTransportationBillsMutation__
 *
 * To run a mutation, you first call `useExportTransportationBillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTransportationBillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTransportationBillsMutation, { data, loading, error }] = useExportTransportationBillsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTransportationBillsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTransportationBillsMutation,
    ExportTransportationBillsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTransportationBillsMutation,
    ExportTransportationBillsMutationVariables
  >(ExportTransportationBillsDocument, options);
}
export type ExportTransportationBillsMutationHookResult = ReturnType<
  typeof useExportTransportationBillsMutation
>;
export type ExportTransportationBillsMutationResult =
  Apollo.MutationResult<ExportTransportationBillsMutation>;
export type ExportTransportationBillsMutationOptions = Apollo.BaseMutationOptions<
  ExportTransportationBillsMutation,
  ExportTransportationBillsMutationVariables
>;
export const SignTransportationBillDocument = gql`
  mutation SignTransportationBill($input: SignTransportationBillInput!) {
    signTransportationBill(input: $input) {
      transportationBill {
        id
        modelName
        isSigned
        file
        externalId
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export type SignTransportationBillMutationFn = Apollo.MutationFunction<
  SignTransportationBillMutation,
  SignTransportationBillMutationVariables
>;

/**
 * __useSignTransportationBillMutation__
 *
 * To run a mutation, you first call `useSignTransportationBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignTransportationBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signTransportationBillMutation, { data, loading, error }] = useSignTransportationBillMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignTransportationBillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignTransportationBillMutation,
    SignTransportationBillMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignTransportationBillMutation,
    SignTransportationBillMutationVariables
  >(SignTransportationBillDocument, options);
}
export type SignTransportationBillMutationHookResult = ReturnType<
  typeof useSignTransportationBillMutation
>;
export type SignTransportationBillMutationResult =
  Apollo.MutationResult<SignTransportationBillMutation>;
export type SignTransportationBillMutationOptions = Apollo.BaseMutationOptions<
  SignTransportationBillMutation,
  SignTransportationBillMutationVariables
>;
export const ExportTransportationPackingListDocument = gql`
  mutation ExportTransportationPackingList($input: ExportTransportationPackingListInput!) {
    exportTransportationPackingList(input: $input) {
      url
    }
  }
`;
export type ExportTransportationPackingListMutationFn = Apollo.MutationFunction<
  ExportTransportationPackingListMutation,
  ExportTransportationPackingListMutationVariables
>;

/**
 * __useExportTransportationPackingListMutation__
 *
 * To run a mutation, you first call `useExportTransportationPackingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTransportationPackingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTransportationPackingListMutation, { data, loading, error }] = useExportTransportationPackingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTransportationPackingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTransportationPackingListMutation,
    ExportTransportationPackingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTransportationPackingListMutation,
    ExportTransportationPackingListMutationVariables
  >(ExportTransportationPackingListDocument, options);
}
export type ExportTransportationPackingListMutationHookResult = ReturnType<
  typeof useExportTransportationPackingListMutation
>;
export type ExportTransportationPackingListMutationResult =
  Apollo.MutationResult<ExportTransportationPackingListMutation>;
export type ExportTransportationPackingListMutationOptions = Apollo.BaseMutationOptions<
  ExportTransportationPackingListMutation,
  ExportTransportationPackingListMutationVariables
>;
export const ExportTransportationPdfDocument = gql`
  mutation ExportTransportationPDF($input: ExportTransportationPDFInput!) {
    exportTransportationPdf(input: $input) {
      url
    }
  }
`;
export type ExportTransportationPdfMutationFn = Apollo.MutationFunction<
  ExportTransportationPdfMutation,
  ExportTransportationPdfMutationVariables
>;

/**
 * __useExportTransportationPdfMutation__
 *
 * To run a mutation, you first call `useExportTransportationPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTransportationPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTransportationPdfMutation, { data, loading, error }] = useExportTransportationPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTransportationPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTransportationPdfMutation,
    ExportTransportationPdfMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTransportationPdfMutation,
    ExportTransportationPdfMutationVariables
  >(ExportTransportationPdfDocument, options);
}
export type ExportTransportationPdfMutationHookResult = ReturnType<
  typeof useExportTransportationPdfMutation
>;
export type ExportTransportationPdfMutationResult =
  Apollo.MutationResult<ExportTransportationPdfMutation>;
export type ExportTransportationPdfMutationOptions = Apollo.BaseMutationOptions<
  ExportTransportationPdfMutation,
  ExportTransportationPdfMutationVariables
>;
export const GetLatestTransportationDocumentExportsDocument = gql`
  query GetLatestTransportationDocumentExports($id: ID!) {
    latestTransportationDocumentsExports(id: $id) {
      createdAt
      externalId
      file
      isSigned
      modelName
      modelUpdatedAt
      type
      updatedAt
      uuid
    }
  }
`;

/**
 * __useGetLatestTransportationDocumentExportsQuery__
 *
 * To run a query within a React component, call `useGetLatestTransportationDocumentExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTransportationDocumentExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTransportationDocumentExportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLatestTransportationDocumentExportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestTransportationDocumentExportsQuery,
    GetLatestTransportationDocumentExportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestTransportationDocumentExportsQuery,
    GetLatestTransportationDocumentExportsQueryVariables
  >(GetLatestTransportationDocumentExportsDocument, options);
}
export function useGetLatestTransportationDocumentExportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestTransportationDocumentExportsQuery,
    GetLatestTransportationDocumentExportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestTransportationDocumentExportsQuery,
    GetLatestTransportationDocumentExportsQueryVariables
  >(GetLatestTransportationDocumentExportsDocument, options);
}
export type GetLatestTransportationDocumentExportsQueryHookResult = ReturnType<
  typeof useGetLatestTransportationDocumentExportsQuery
>;
export type GetLatestTransportationDocumentExportsLazyQueryHookResult = ReturnType<
  typeof useGetLatestTransportationDocumentExportsLazyQuery
>;
export type GetLatestTransportationDocumentExportsQueryResult = Apollo.QueryResult<
  GetLatestTransportationDocumentExportsQuery,
  GetLatestTransportationDocumentExportsQueryVariables
>;
export const ExportTransportationAssetLabelDocument = gql`
  mutation ExportTransportationAssetLabel($input: ExportTransportationAssetLabelInput!) {
    exportTransportationAssetLabel(input: $input) {
      url
    }
  }
`;
export type ExportTransportationAssetLabelMutationFn = Apollo.MutationFunction<
  ExportTransportationAssetLabelMutation,
  ExportTransportationAssetLabelMutationVariables
>;

/**
 * __useExportTransportationAssetLabelMutation__
 *
 * To run a mutation, you first call `useExportTransportationAssetLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTransportationAssetLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTransportationAssetLabelMutation, { data, loading, error }] = useExportTransportationAssetLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTransportationAssetLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTransportationAssetLabelMutation,
    ExportTransportationAssetLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTransportationAssetLabelMutation,
    ExportTransportationAssetLabelMutationVariables
  >(ExportTransportationAssetLabelDocument, options);
}
export type ExportTransportationAssetLabelMutationHookResult = ReturnType<
  typeof useExportTransportationAssetLabelMutation
>;
export type ExportTransportationAssetLabelMutationResult =
  Apollo.MutationResult<ExportTransportationAssetLabelMutation>;
export type ExportTransportationAssetLabelMutationOptions = Apollo.BaseMutationOptions<
  ExportTransportationAssetLabelMutation,
  ExportTransportationAssetLabelMutationVariables
>;
export const ExportTransportationAssetPackingListDocument = gql`
  mutation ExportTransportationAssetPackingList(
    $input: ExportTransportationAssetPackingListInput!
  ) {
    exportTransportationAssetPackingList(input: $input) {
      url
    }
  }
`;
export type ExportTransportationAssetPackingListMutationFn = Apollo.MutationFunction<
  ExportTransportationAssetPackingListMutation,
  ExportTransportationAssetPackingListMutationVariables
>;

/**
 * __useExportTransportationAssetPackingListMutation__
 *
 * To run a mutation, you first call `useExportTransportationAssetPackingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTransportationAssetPackingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTransportationAssetPackingListMutation, { data, loading, error }] = useExportTransportationAssetPackingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportTransportationAssetPackingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportTransportationAssetPackingListMutation,
    ExportTransportationAssetPackingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportTransportationAssetPackingListMutation,
    ExportTransportationAssetPackingListMutationVariables
  >(ExportTransportationAssetPackingListDocument, options);
}
export type ExportTransportationAssetPackingListMutationHookResult = ReturnType<
  typeof useExportTransportationAssetPackingListMutation
>;
export type ExportTransportationAssetPackingListMutationResult =
  Apollo.MutationResult<ExportTransportationAssetPackingListMutation>;
export type ExportTransportationAssetPackingListMutationOptions = Apollo.BaseMutationOptions<
  ExportTransportationAssetPackingListMutation,
  ExportTransportationAssetPackingListMutationVariables
>;
export const GetAllUsersDocument = gql`
  query GetAllUsers($search: String) {
    allUsers(orderBy: "-created_at", search: $search) {
      edges {
        node {
          id
          name
          email
          phone
          country {
            code
          }
          userType
          isActive
          validFrom
          expiresAt
          createdBy {
            id
            name
            userType
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      lastLogin
      dateJoined
      name
      isActive
      email
      userType
      phone
      country {
        code
      }
      language
      timezone
      currency
      validFrom
      expiresAt
      isExpired
      timesRenewed
      ...UserSignatureFields
      userPermissions {
        edges {
          node {
            id
            codename
            name
            contentType {
              model
              id
            }
          }
        }
      }
    }
  }
  ${UserSignatureFieldsFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const GetUserOperationsByIdDocument = gql`
  query GetUserOperationsById {
    allUsers {
      edges {
        node {
          name
          email
          assetsCreatedCount
          collectionsCreatedCount
          exhibitionsCreatedCount
          appraisalsCreatedCount
          restorationsCreatedCount
          conditionstatusreportsCreatedCount
          policiesCreatedCountTe
          policiesCreatedCountPc
          usersCreatedCount
          usedInvites
        }
      }
    }
  }
`;

/**
 * __useGetUserOperationsByIdQuery__
 *
 * To run a query within a React component, call `useGetUserOperationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOperationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOperationsByIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOperationsByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOperationsByIdQuery,
    GetUserOperationsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserOperationsByIdQuery, GetUserOperationsByIdQueryVariables>(
    GetUserOperationsByIdDocument,
    options
  );
}
export function useGetUserOperationsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOperationsByIdQuery,
    GetUserOperationsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserOperationsByIdQuery, GetUserOperationsByIdQueryVariables>(
    GetUserOperationsByIdDocument,
    options
  );
}
export type GetUserOperationsByIdQueryHookResult = ReturnType<typeof useGetUserOperationsByIdQuery>;
export type GetUserOperationsByIdLazyQueryHookResult = ReturnType<
  typeof useGetUserOperationsByIdLazyQuery
>;
export type GetUserOperationsByIdQueryResult = Apollo.QueryResult<
  GetUserOperationsByIdQuery,
  GetUserOperationsByIdQueryVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        email
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        password
        lastLogin
        username
        dateJoined
        name
        email
        phone
        country {
          code
        }
        language
        timezone
        currency
        userType
        isActive
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RequestUserResetPasswordDocument = gql`
  mutation RequestUserResetPassword($input: RequestUserResetPasswordInput!) {
    requestUserResetPassword(input: $input) {
      user {
        resetPasswordToken
      }
    }
  }
`;
export type RequestUserResetPasswordMutationFn = Apollo.MutationFunction<
  RequestUserResetPasswordMutation,
  RequestUserResetPasswordMutationVariables
>;

/**
 * __useRequestUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUserResetPasswordMutation, { data, loading, error }] = useRequestUserResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestUserResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestUserResetPasswordMutation,
    RequestUserResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestUserResetPasswordMutation,
    RequestUserResetPasswordMutationVariables
  >(RequestUserResetPasswordDocument, options);
}
export type RequestUserResetPasswordMutationHookResult = ReturnType<
  typeof useRequestUserResetPasswordMutation
>;
export type RequestUserResetPasswordMutationResult =
  Apollo.MutationResult<RequestUserResetPasswordMutation>;
export type RequestUserResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestUserResetPasswordMutation,
  RequestUserResetPasswordMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      user {
        email
      }
    }
  }
`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(
    ResetUserPasswordDocument,
    options
  );
}
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const GetAllPermissionDocument = gql`
  query getAllPermission {
    allPermissions {
      edges {
        node {
          contentType {
            model
          }
          codename
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllPermissionQuery__
 *
 * To run a query within a React component, call `useGetAllPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(
    GetAllPermissionDocument,
    options
  );
}
export function useGetAllPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(
    GetAllPermissionDocument,
    options
  );
}
export type GetAllPermissionQueryHookResult = ReturnType<typeof useGetAllPermissionQuery>;
export type GetAllPermissionLazyQueryHookResult = ReturnType<typeof useGetAllPermissionLazyQuery>;
export type GetAllPermissionQueryResult = Apollo.QueryResult<
  GetAllPermissionQuery,
  GetAllPermissionQueryVariables
>;
export const UpdateUserPermissionsDocument = gql`
  mutation UpdateUserPermissions($input: UpdateUserPermissionInput!) {
    updateUserPermissions(input: $input) {
      user {
        userPermissions {
          edges {
            node {
              codename
              name
            }
          }
        }
      }
    }
  }
`;
export type UpdateUserPermissionsMutationFn = Apollo.MutationFunction<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>;

/**
 * __useUpdateUserPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPermissionsMutation, { data, loading, error }] = useUpdateUserPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPermissionsMutation,
    UpdateUserPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPermissionsMutation, UpdateUserPermissionsMutationVariables>(
    UpdateUserPermissionsDocument,
    options
  );
}
export type UpdateUserPermissionsMutationHookResult = ReturnType<
  typeof useUpdateUserPermissionsMutation
>;
export type UpdateUserPermissionsMutationResult =
  Apollo.MutationResult<UpdateUserPermissionsMutation>;
export type UpdateUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>;
export const ToggleUserIsActiveDocument = gql`
  mutation ToggleUserIsActive($input: ToggleUserIsActiveInput!) {
    toggleUserIsactive(input: $input) {
      user {
        isActive
      }
    }
  }
`;
export type ToggleUserIsActiveMutationFn = Apollo.MutationFunction<
  ToggleUserIsActiveMutation,
  ToggleUserIsActiveMutationVariables
>;

/**
 * __useToggleUserIsActiveMutation__
 *
 * To run a mutation, you first call `useToggleUserIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserIsActiveMutation, { data, loading, error }] = useToggleUserIsActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserIsActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserIsActiveMutation,
    ToggleUserIsActiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleUserIsActiveMutation, ToggleUserIsActiveMutationVariables>(
    ToggleUserIsActiveDocument,
    options
  );
}
export type ToggleUserIsActiveMutationHookResult = ReturnType<typeof useToggleUserIsActiveMutation>;
export type ToggleUserIsActiveMutationResult = Apollo.MutationResult<ToggleUserIsActiveMutation>;
export type ToggleUserIsActiveMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserIsActiveMutation,
  ToggleUserIsActiveMutationVariables
>;
export const ToggleUserUserTypeDocument = gql`
  mutation ToggleUserUserType($input: ToggleUserUserTypeInput!) {
    toggleUserUserType(input: $input) {
      user {
        userType
      }
    }
  }
`;
export type ToggleUserUserTypeMutationFn = Apollo.MutationFunction<
  ToggleUserUserTypeMutation,
  ToggleUserUserTypeMutationVariables
>;

/**
 * __useToggleUserUserTypeMutation__
 *
 * To run a mutation, you first call `useToggleUserUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserUserTypeMutation, { data, loading, error }] = useToggleUserUserTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserUserTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserUserTypeMutation,
    ToggleUserUserTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleUserUserTypeMutation, ToggleUserUserTypeMutationVariables>(
    ToggleUserUserTypeDocument,
    options
  );
}
export type ToggleUserUserTypeMutationHookResult = ReturnType<typeof useToggleUserUserTypeMutation>;
export type ToggleUserUserTypeMutationResult = Apollo.MutationResult<ToggleUserUserTypeMutation>;
export type ToggleUserUserTypeMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserUserTypeMutation,
  ToggleUserUserTypeMutationVariables
>;
export const RenewUserDocument = gql`
  mutation RenewUser($input: RenewUserInput!) {
    renewUser(input: $input) {
      user {
        validFrom
        expiresAt
      }
    }
  }
`;
export type RenewUserMutationFn = Apollo.MutationFunction<
  RenewUserMutation,
  RenewUserMutationVariables
>;

/**
 * __useRenewUserMutation__
 *
 * To run a mutation, you first call `useRenewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewUserMutation, { data, loading, error }] = useRenewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RenewUserMutation, RenewUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenewUserMutation, RenewUserMutationVariables>(
    RenewUserDocument,
    options
  );
}
export type RenewUserMutationHookResult = ReturnType<typeof useRenewUserMutation>;
export type RenewUserMutationResult = Apollo.MutationResult<RenewUserMutation>;
export type RenewUserMutationOptions = Apollo.BaseMutationOptions<
  RenewUserMutation,
  RenewUserMutationVariables
>;
export const AddUserSignatureDocument = gql`
  mutation AddUserSignature($input: AddUserSignatureInput!) {
    addUserSignature(input: $input) {
      success
      userSignature {
        file
      }
    }
  }
`;
export type AddUserSignatureMutationFn = Apollo.MutationFunction<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;

/**
 * __useAddUserSignatureMutation__
 *
 * To run a mutation, you first call `useAddUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSignatureMutation, { data, loading, error }] = useAddUserSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserSignatureMutation,
    AddUserSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserSignatureMutation, AddUserSignatureMutationVariables>(
    AddUserSignatureDocument,
    options
  );
}
export type AddUserSignatureMutationHookResult = ReturnType<typeof useAddUserSignatureMutation>;
export type AddUserSignatureMutationResult = Apollo.MutationResult<AddUserSignatureMutation>;
export type AddUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;
export const DeleteUserSignatureDocument = gql`
  mutation DeleteUserSignature($input: DeleteUserSignatureInput!) {
    deleteUserSignature(input: $input) {
      userSignature {
        id
      }
    }
  }
`;
export type DeleteUserSignatureMutationFn = Apollo.MutationFunction<
  DeleteUserSignatureMutation,
  DeleteUserSignatureMutationVariables
>;

/**
 * __useDeleteUserSignatureMutation__
 *
 * To run a mutation, you first call `useDeleteUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSignatureMutation, { data, loading, error }] = useDeleteUserSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSignatureMutation,
    DeleteUserSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserSignatureMutation, DeleteUserSignatureMutationVariables>(
    DeleteUserSignatureDocument,
    options
  );
}
export type DeleteUserSignatureMutationHookResult = ReturnType<
  typeof useDeleteUserSignatureMutation
>;
export type DeleteUserSignatureMutationResult = Apollo.MutationResult<DeleteUserSignatureMutation>;
export type DeleteUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSignatureMutation,
  DeleteUserSignatureMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const SetMainSignatureDocument = gql`
  mutation SetMainSignature($input: SetMainSignatureInput!) {
    setMainSignature(input: $input) {
      userSignature {
        id
      }
    }
  }
`;
export type SetMainSignatureMutationFn = Apollo.MutationFunction<
  SetMainSignatureMutation,
  SetMainSignatureMutationVariables
>;

/**
 * __useSetMainSignatureMutation__
 *
 * To run a mutation, you first call `useSetMainSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMainSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMainSignatureMutation, { data, loading, error }] = useSetMainSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMainSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMainSignatureMutation,
    SetMainSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetMainSignatureMutation, SetMainSignatureMutationVariables>(
    SetMainSignatureDocument,
    options
  );
}
export type SetMainSignatureMutationHookResult = ReturnType<typeof useSetMainSignatureMutation>;
export type SetMainSignatureMutationResult = Apollo.MutationResult<SetMainSignatureMutation>;
export type SetMainSignatureMutationOptions = Apollo.BaseMutationOptions<
  SetMainSignatureMutation,
  SetMainSignatureMutationVariables
>;
export const AddUserObjectPermissionDocument = gql`
  mutation AddUserObjectPermission($input: AddUserObjectPermissionInput!) {
    addUserObjectPermission(input: $input) {
      user {
        id
      }
    }
  }
`;
export type AddUserObjectPermissionMutationFn = Apollo.MutationFunction<
  AddUserObjectPermissionMutation,
  AddUserObjectPermissionMutationVariables
>;

/**
 * __useAddUserObjectPermissionMutation__
 *
 * To run a mutation, you first call `useAddUserObjectPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserObjectPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserObjectPermissionMutation, { data, loading, error }] = useAddUserObjectPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserObjectPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserObjectPermissionMutation,
    AddUserObjectPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserObjectPermissionMutation,
    AddUserObjectPermissionMutationVariables
  >(AddUserObjectPermissionDocument, options);
}
export type AddUserObjectPermissionMutationHookResult = ReturnType<
  typeof useAddUserObjectPermissionMutation
>;
export type AddUserObjectPermissionMutationResult =
  Apollo.MutationResult<AddUserObjectPermissionMutation>;
export type AddUserObjectPermissionMutationOptions = Apollo.BaseMutationOptions<
  AddUserObjectPermissionMutation,
  AddUserObjectPermissionMutationVariables
>;
export const AddUserObjectPermissionFromCollectionDocument = gql`
  mutation AddUserObjectPermissionFromCollection(
    $input: AddUserObjectPermissionFromCollectionInput!
  ) {
    addUserObjectPermissionFromCollection(input: $input) {
      user {
        id
      }
    }
  }
`;
export type AddUserObjectPermissionFromCollectionMutationFn = Apollo.MutationFunction<
  AddUserObjectPermissionFromCollectionMutation,
  AddUserObjectPermissionFromCollectionMutationVariables
>;

/**
 * __useAddUserObjectPermissionFromCollectionMutation__
 *
 * To run a mutation, you first call `useAddUserObjectPermissionFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserObjectPermissionFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserObjectPermissionFromCollectionMutation, { data, loading, error }] = useAddUserObjectPermissionFromCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserObjectPermissionFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserObjectPermissionFromCollectionMutation,
    AddUserObjectPermissionFromCollectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserObjectPermissionFromCollectionMutation,
    AddUserObjectPermissionFromCollectionMutationVariables
  >(AddUserObjectPermissionFromCollectionDocument, options);
}
export type AddUserObjectPermissionFromCollectionMutationHookResult = ReturnType<
  typeof useAddUserObjectPermissionFromCollectionMutation
>;
export type AddUserObjectPermissionFromCollectionMutationResult =
  Apollo.MutationResult<AddUserObjectPermissionFromCollectionMutation>;
export type AddUserObjectPermissionFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  AddUserObjectPermissionFromCollectionMutation,
  AddUserObjectPermissionFromCollectionMutationVariables
>;
export const RemoveUserObjectPermissionDocument = gql`
  mutation RemoveUserObjectPermission($input: RemoveUserObjectPermissionInput!) {
    removeUserObjectPermission(input: $input) {
      user {
        id
      }
    }
  }
`;
export type RemoveUserObjectPermissionMutationFn = Apollo.MutationFunction<
  RemoveUserObjectPermissionMutation,
  RemoveUserObjectPermissionMutationVariables
>;

/**
 * __useRemoveUserObjectPermissionMutation__
 *
 * To run a mutation, you first call `useRemoveUserObjectPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserObjectPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserObjectPermissionMutation, { data, loading, error }] = useRemoveUserObjectPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserObjectPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserObjectPermissionMutation,
    RemoveUserObjectPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUserObjectPermissionMutation,
    RemoveUserObjectPermissionMutationVariables
  >(RemoveUserObjectPermissionDocument, options);
}
export type RemoveUserObjectPermissionMutationHookResult = ReturnType<
  typeof useRemoveUserObjectPermissionMutation
>;
export type RemoveUserObjectPermissionMutationResult =
  Apollo.MutationResult<RemoveUserObjectPermissionMutation>;
export type RemoveUserObjectPermissionMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserObjectPermissionMutation,
  RemoveUserObjectPermissionMutationVariables
>;
export const SharePolicyByEmailDocument = gql`
  mutation SharePolicyByEmail($input: SharePolicyByEmailInput!) {
    sharePolicyByEmail(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type SharePolicyByEmailMutationFn = Apollo.MutationFunction<
  SharePolicyByEmailMutation,
  SharePolicyByEmailMutationVariables
>;

/**
 * __useSharePolicyByEmailMutation__
 *
 * To run a mutation, you first call `useSharePolicyByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharePolicyByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharePolicyByEmailMutation, { data, loading, error }] = useSharePolicyByEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSharePolicyByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SharePolicyByEmailMutation,
    SharePolicyByEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SharePolicyByEmailMutation, SharePolicyByEmailMutationVariables>(
    SharePolicyByEmailDocument,
    options
  );
}
export type SharePolicyByEmailMutationHookResult = ReturnType<typeof useSharePolicyByEmailMutation>;
export type SharePolicyByEmailMutationResult = Apollo.MutationResult<SharePolicyByEmailMutation>;
export type SharePolicyByEmailMutationOptions = Apollo.BaseMutationOptions<
  SharePolicyByEmailMutation,
  SharePolicyByEmailMutationVariables
>;
export const SharePolicyByUsersDocument = gql`
  mutation SharePolicyByUsers($input: SharePolicyByUsersInput!) {
    sharePolicyByUsers(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type SharePolicyByUsersMutationFn = Apollo.MutationFunction<
  SharePolicyByUsersMutation,
  SharePolicyByUsersMutationVariables
>;

/**
 * __useSharePolicyByUsersMutation__
 *
 * To run a mutation, you first call `useSharePolicyByUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharePolicyByUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharePolicyByUsersMutation, { data, loading, error }] = useSharePolicyByUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSharePolicyByUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SharePolicyByUsersMutation,
    SharePolicyByUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SharePolicyByUsersMutation, SharePolicyByUsersMutationVariables>(
    SharePolicyByUsersDocument,
    options
  );
}
export type SharePolicyByUsersMutationHookResult = ReturnType<typeof useSharePolicyByUsersMutation>;
export type SharePolicyByUsersMutationResult = Apollo.MutationResult<SharePolicyByUsersMutation>;
export type SharePolicyByUsersMutationOptions = Apollo.BaseMutationOptions<
  SharePolicyByUsersMutation,
  SharePolicyByUsersMutationVariables
>;
